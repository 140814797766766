import { useContext } from "react";
import Context from "../../context/Context";
import Styles from "./ProgressButton.module.scss";

const ProgressButton = (props) => {
  const { size = 76 } = props;
  const context = useContext(Context);
  const { videoInProgressPercent, theme, overrideTheme } = context
  const translateThemes = {"light" : "#fff", "dark" : "#000"}
  const radius = 37.5;
  const circumference = radius * 2 * Math.PI;

  const offset = circumference - (videoInProgressPercent || 0) * circumference;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 77 77"
    >
      <g
        transform="translate(0 76) rotate(-90)"
        fill="none"
        stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
        strokeWidth="1"
      >
        <circle cx="38" cy="38" r="38" stroke="none" opacity="0.175" />
        <circle cx="38" cy="38" r="37.5" fill="none" opacity="0.175" />
        <circle
          id="progress"
          cx="38"
          cy="38"
          r="37.5"
          fill="none"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          className={Styles.progress}
        />
      </g>

      <circle
        cx="32"
        cy="32"
        r="32"
        transform="translate(6 70) rotate(-90)"
        fill={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
        opacity="0.2"
      />
      <g id="lock_icon" transform="translate(-1139.039 -188.728)">
        <g
          transform="translate(1171 222.728)"
          fill="none"
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        >
          <rect width="12" height="11" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="10" rx="1.5" fill="none" />
        </g>
        <path
          d="M4.839,7.839V5.42A2.42,2.42,0,0,0,0,5.42v2.42"
          transform="translate(1174.629 215)"
          fill="none"
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        />
        <g
          transform="translate(1176 226.728)"
          fill="none"
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.5" fill="none" />
        </g>
        <line
          y2="2"
          transform="translate(1177 227.728)"
          fill="none"
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default ProgressButton;
