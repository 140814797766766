import React, {useContext} from 'react'
import clsx from 'clsx';
import { ReactSVG } from 'react-svg'
import Context from '../../../../context/Context'
import './InteractiveStep.scss'

export default function InteractiveStep({
  step, 
  index, 
  setPlayedSteps, 
  playedSteps,
  played,
  playMedia,
  activeStep,
  introVideoFinished,
  introAudioFinished,
  colorPicker,
}){
  const context = useContext(Context)
  const { language, muted } = context;
  
	function handleAddStep() {
    if (!introVideoFinished) return
    if (!playedSteps.includes(index)) {
      setPlayedSteps([...playedSteps, index]);
    }
    playMedia(step)
  }

  function determineStrokeColor() {
    if (index !== 5) return 'current'
    if (played) {
      return 'red'
    } else {
      return 'white'
    }
  }
  
  function renderStep() {
    const active = activeStep?.index === index 
    if (colorPicker) {
      return (
        <div 
          className="color-picker-interactive-step"
          onClick={handleAddStep}
        >
          <div 
            className={clsx('color-picker-color', active ? 'active' : '')}
            style={{background: step.bgColor}}
          >
          </div>
          <p className={clsx('text color-text', !active ? 'fade' : '')}>{language[step.text] || step.text}</p>
        </div>
      )
    } else {
      return (
        <div
          onClick={handleAddStep}
          className={clsx("interactive-step", played ? 'fill' : '', (muted && introVideoFinished) || (!muted && introAudioFinished) ? '' : 'block')} 
        >
        <div className={clsx("icon-wrapper", played ? 'fill' : '')}>
          <ReactSVG 
            className="icon" 
            style={{fill: played ? 'red' : 'white', stroke: determineStrokeColor() }} 
            src={process.env.PUBLIC_URL + step.icon} 
          />
        </div>
        <p className='text' dangerouslySetInnerHTML={{ __html: language[step.text]}} />
      </div>
      )
    }
  }

  return renderStep()
}
