import { useContext } from 'react'
import Context from '../../context/Context'
import styles from './AudioControl.module.scss'
import { TrackEvent } from '../../utils/track'

const AudioControl = (props) => {
  const { className } = props
  const context = useContext(Context)
  const { muted, setMuted, setForceDefaultCursor, ga4, theme, overrideTheme } = context
  const translateThemes = {"light" : "#fff", "dark" : "#000"}
  const toggleMuted = () => {
    TrackEvent(ga4, muted ? 'audio_on' : 'audio_off', 'click', 'user_action')
    setMuted(!muted)
  }

  const handleMouseEnter = () => {
    setForceDefaultCursor(true)
  }
  const handleMouseLeave = () => {
    setForceDefaultCursor(false)
  }

  const UnMutedIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.442" height="17.979" viewBox="0 0 24.442 17.979">
      <g transform="translate(-1205.608 -751.004)">
        <path
          d="M47.587,209.515a.738.738,0,0,0-.561-.26.726.726,0,0,0-.556,1.192,7.253,7.253,0,0,1,.088,9.329.727.727,0,0,0,.564,1.183.735.735,0,0,0,.569-.27,8.708,8.708,0,0,0-.1-11.174Z"
          transform="translate(1175.602 544.886)"
          fill= {overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="0.5"
        />
        <path
          d="M40.845,218.038a.723.723,0,0,0-.583-.3.732.732,0,0,0-.49.188.723.723,0,0,0-.1.966,3.3,3.3,0,0,1,.073,3.787.726.726,0,0,0,.136.963.736.736,0,0,0,.47.171.72.72,0,0,0,.6-.316,4.752,4.752,0,0,0-.1-5.464Z"
          transform="translate(1179.128 539.209)"
          fill={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="0.5"
        />
        <path
          d="M54.663,200.776a.72.72,0,0,0-.534-.237.728.728,0,0,0-.539,1.218,11.343,11.343,0,0,1,.092,15.049.728.728,0,0,0,.545,1.211.716.716,0,0,0,.54-.244,12.795,12.795,0,0,0-.1-17Z"
          transform="translate(1171.853 550.715)"
          fill={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="0.5"
        />
        <path
          d="M10.526,296.373a.333.333,0,0,1-.335.332.337.337,0,0,1-.238-.1l-4.6-4.011H2.865a1.339,1.339,0,0,1-1.344-1.335h0v-3.1a1.339,1.339,0,0,1,1.344-1.335H5.353l4.6-4.022a.336.336,0,0,1,.473,0,.331.331,0,0,1,.1.239Z"
          transform="translate(1204.587 470.285)"
          fill={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        />
      </g>
    </svg>
  )
  const MutedIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.484" height="16.219" viewBox="0 0 22.484 16.219">
      <g transform="translate(0.5)">
        <path
          d="M10.526,296.373a.333.333,0,0,1-.335.332.337.337,0,0,1-.238-.1l-4.6-4.011H2.865a1.339,1.339,0,0,1-1.344-1.335h0v-3.1a1.339,1.339,0,0,1,1.344-1.335H5.353l4.6-4.022a.336.336,0,0,1,.473,0,.331.331,0,0,1,.1.239Z"
          transform="translate(-1.521 -281.596)"
          fill={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        />
        <g transform="translate(0 1.109)">
          <line
            x2="5.311"
            y2="5.311"
            transform="translate(14.344 4.344)"
            fill="none"
            stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            x1="5.311"
            y2="5.311"
            transform="translate(14.344 4.344)"
            fill="none"
            stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  )

  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={toggleMuted}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {muted ? MutedIcon : UnMutedIcon}
    </button>
  )
}

export default AudioControl
