const COMPANIES = [
	{ code: 'MKTG', name: 'MKTG (Pulse Program) HQ' },
	{ code: 'APPLINT', name: 'APPLE INTERNAL' },
	{ code: '385898', name: 'RESPUESTAS OPTIMAS EN MAYOREO' },
	{ code: '266329', name: '101 BIZ INC' },
	{ code: '450059', name: 'ACCESS TELECOM INC' },
	{ code: '463140', name: 'ALLPLUS COMPUTER SYSTEMS CORP' },
	{ code: '388314', name: 'ATC' },
	{ code: '345472', name: 'AUDIO VIDEO COMMUNICATION STORE' },
	{ code: '412765', name: 'BEST SOLUTION INC' },
	{ code: '221001', name: 'BOOLCHANDS' },
	{ code: '1498098', name: 'BRIGHTSTAR' },
	{ code: '325187', name: 'COMPUWIZ GROUP OF SOUTH FLORIDA' },
	{ code: '265841', name: 'DUTY FREE ELECTRONICS LLC' },
	{ code: '1998172', name: 'F & E TRADING LLC' },
	{ code: '1098631', name: 'FACEY TELECOM INTERNATIONAL INC' },
	{ code: '220576', name: 'FAIR PRICE' },
	{ code: '1467661', name: 'GSM WHOLESALERS' },
	{ code: '408476', name: 'INGRAM MICRO' },
	{ code: '772303', name: 'INGRAM MICRO INC' },
	{ code: '325185', name: 'INNOCO TECHNOLOGY GROUP INC' },
	{ code: '211751', name: 'INNOVATION COMPUTERS' },
	{ code: '454452', name: 'INTEK COMPUTERS CORP' },
	{ code: '411175', name: 'MAPA' },
	{ code: '325186', name: 'MICRO INFORMATICA LLC' },
	{ code: '464581', name: 'NAPAN LLC' },
	{ code: '261235', name: 'NASA ELECTRONIC CORP' },
	{ code: '237770', name: 'NEKOR INC' },
	{ code: '1518420', name: 'ONE CLICK' },
	{ code: '224667', name: 'PC EXPORT HOUSE CORP' },
	{ code: '1358400', name: 'PCS WIRELESS LATAM' },
	{ code: '220569', name: 'PRICESMART INC' },
	{ code: '457336', name: 'SACO COMUNICATIONS LLC' },
	{ code: '520487', name: 'SUMA DISTRIBUTORS' },
	{ code: '207798', name: 'TECH DATA PRODUCT MANAGEMENT INC' },
	{ code: '523313', name: 'TM CELL' },
	{ code: '615683', name: 'Y 17' },
	{ code: '267568', name: 'COMPUTER SOLUTIONS' },
	{ code: '341132', name: 'ISMART' },
	{ code: '384972', name: 'COMPUMUNDO' },
	{ code: '262629', name: 'DUTY FREE SHOP PUERTO IGUAZU' },
	{ code: '496661', name: 'FRAVEGA' },
	{ code: '2249437', name: 'INFORMATION TECHNOLOGY SA' },
	{ code: '227720', name: 'IPOINT' },
	{ code: '1533113', name: 'MACSTATION' },
	{ code: '212304', name: 'MACSTATION' },
	{ code: '80162', name: 'MAXIM STORE' },
	{ code: '1580187', name: 'ONECLICK ARGENTINA' },
	{ code: '261854', name: 'ISTORE ARUBA NV' },
	{ code: '452162', name: 'SAMS STORE' },
	{ code: '923554', name: 'SETAR' },
	{ code: '305408', name: 'ELECTRO INTERNATIONAL LTD' },
	{ code: '557375', name: 'AF SMITH' },
	{ code: '215955', name: 'THE COMPLETE OFFICE LTD' },
	{ code: '455335', name: 'CITY SHOP NV' },
	{ code: '215961', name: 'COMUNICACION VISUAL' },
	{ code: '464275', name: 'I TIENDA' },
	{ code: '1635127', name: 'ITECH' },
	{ code: '815822', name: 'ITIENDA' },
	{ code: '481799', name: '4PARTNER' },
	{ code: '366599', name: 'AD DIGITAL COMERCIO DE ELECTRONICOS LTDA' },
	{ code: '632829', name: 'AGES' },
	{ code: '571992', name: 'ALCATEIA' },
	{ code: '1963750', name: 'ALLIED' },
	{ code: '1350551', name: 'ALLIED TECNOLOGIA SA' },
	{ code: '367301', name: 'ARIANE INFORMATICA' },
	{ code: '600723', name: 'ASINCORP' },
	{ code: '368778', name: 'AMERICANAS COM' },
	{ code: '368780', name: 'B2W COMPANHIA DIGITAL' },
	{ code: '554843', name: 'BEGNI ISTORE' },
	{ code: '378141', name: 'BRAZIL - NON AUTHORIZED' },
	{ code: '588161', name: 'BUKONE INFO STORE' },
	{ code: '387052', name: 'CBD' },
	{ code: '454355', name: 'CENTRAL DE NEGOCIOS' },
	{ code: '616302', name: 'COMBINA' },
	{ code: '485532', name: 'COMPUTECNICA' },
	{ code: '369415', name: 'FAST SHOP' },
	{ code: '605933', name: 'FASTECH' },
	{ code: '621079', name: 'FN TECH' },
	{ code: '598977', name: 'FORPRINT' },
	{ code: '624792', name: 'FRONTIERS' },
	{ code: '1064949', name: 'GAMING DO BRASIL COMERCIO DE JOGOS ELETRONICOS LTDA' },
	{ code: '623061', name: 'GIRAFA' },
	{ code: '389864', name: 'IPLACE' },
	{ code: '620272', name: 'IBYTE' },
	{ code: '614276', name: 'INGRAM MICRO BRASIL' },
	{ code: '606126', name: 'KADRI INFORMATICA' },
	{ code: '605905', name: 'LIVRARIAS CURITIBA' },
	{ code: '628393', name: 'LOJAS BEMOL' },
	{ code: '367287', name: 'MACSTORE' },
	{ code: '531816', name: 'MAGAZINE LUIZA HQ' },
	{ code: '630696', name: 'MAGICCOMP' },
	{ code: '1746623', name: 'MC CELULARES' },
	{ code: '366607', name: 'MIAMI STORE' },
	{ code: '929156', name: 'NEXTEL TELECOMUNICACOES LTDA' },
	{ code: '1551536', name: 'NIKE' },
	{ code: '367289', name: 'CNOVA COMERCIO ELETRONICO SA' },
	{ code: '479902', name: 'OMEGA BRASIL' },
	{ code: '613134', name: 'OMNI INFORMATICA' },
	{ code: '1491167', name: 'IONE' },
	{ code: '368735', name: 'FNAC BRASIL LTDA' },
	{ code: '728292', name: 'WALMART COM BR' },
	{ code: '606093', name: 'PRIMETEK' },
	{ code: '600839', name: 'RN INFORMATICA' },
	{ code: '368782', name: 'SARAIVA' },
	{ code: '604688', name: 'SILICONTECH' },
	{ code: '367285', name: 'SOL INFORMATICA LTDA' },
	{ code: '367292', name: 'SOLUCAO DIGITAL' },
	{ code: '617324', name: 'SUPERMARCAS' },
	{ code: '616587', name: 'SYMA' },
	{ code: '1797095', name: 'TELEFONICA CORP SP' },
	{ code: '617397', name: 'TI TECH SOLUTIONS' },
	{ code: '369314', name: 'VIA VAREJO SA' },
	{ code: '367295', name: 'XPRESS BARRA COMERCIO E SERVICOS' },
	{ code: '262607', name: 'INFINITE SOLUTIONS' },
	{ code: '209528', name: 'ALPHASOFT LTD' },
	{ code: '261216', name: 'KIRK OFFICE EQUIPMENT LTDA' },
	{ code: '1020299', name: 'ABCDIN' },
	{ code: '1428044', name: 'ASSURANT SERVICES DE CHILE SPA' },
	{ code: '2109784', name: 'BRIGHTSTAR CORP CHILE LTDA' },
	{ code: '468671', name: 'C AND C MARKETING' },
	{ code: '220125', name: 'CENCOSUD RETAIL SA' },
	{ code: '266324', name: 'CMAC' },
	{ code: '222683', name: 'COMERCIAL ECCSA SA' },
	{ code: '323373', name: 'COOPERATIVA DE CONSUMOS CARABINEROS DE CHILE LTDA' },
	{ code: '261965', name: 'DR. MAC LTDA' },
	{ code: '221434', name: 'DUTY FREE TC SA' },
	{ code: '274435', name: 'FALABELLA RETAIL SA' },
	{ code: '605676', name: 'FULL COMPUTER' },
	{ code: '216122', name: 'HIDRELEC LTDA.' },
	{ code: '615652', name: 'INGRAM MICRO CHILE' },
	{ code: '229558', name: 'LA POLAR' },
	{ code: '216125', name: 'MACONLINE' },
	{ code: '261926', name: 'PC FACTORY' },
	{ code: '752556', name: 'PC FACTORY SA' },
	{ code: '522923', name: 'PETA CL' },
	{ code: '80153', name: 'QUINTEC DISTRIBUCION SA' },
	{ code: '224631', name: 'REIFSCHNEIDER SA' },
	{ code: '1377588', name: 'SILIKOM TECH LIMITADA' },
	{ code: '229567', name: 'TECH SOLUTIONS' },
	{ code: '229562', name: 'WALMART CHILE COMERCIAL LTDA' },
	{ code: '267567', name: 'ALKOSTO SA' },
	{ code: '462970', name: 'ALMACENES FLAMINGO' },
	{ code: '216143', name: 'ARC SOFTWARE LTDA' },
	{ code: '331070', name: 'AVNET TECHNOLOGY SOLUTIONS COLOMBIA' },
	{ code: '1337228', name: 'BRIGHTSTAR COMERCIALIZADORA' },
	{ code: '450137', name: 'CABLETRONIK' },
	{ code: '549043', name: 'COLOMBIA MOVIL SA ESP' },
	{ code: '577998', name: 'COMPURENT SAS' },
	{ code: '462895', name: 'CORSA' },
	{ code: '231293', name: 'ALMACENES EXITO SA' },
	{ code: '281379', name: 'FALABELLA DE COLOMBIA' },
	{ code: '496013', name: 'GTI ALBERTO ALVAREZ' },
	{ code: '525404', name: 'INEXT TECNOLOGIA FACIL' },
	{ code: '537595', name: 'INFOTECH DE COLOMBIA LTDA' },
	{ code: '1026859', name: 'INGRAM MICRO SAS' },
	{ code: '387132', name: 'ISHOP COLOMBIA' },
	{ code: '580215', name: 'IZONE' },
	{ code: '1301901', name: 'JUMBO Y METRO' },
	{ code: '406613', name: 'MAC CENTER COLOMBIA SAS' },
	{ code: '2204777', name: 'MPS MAYORISTA DE COLOMBIA SA' },
	{ code: '339929', name: 'PANAMERICANA LIBRERIA Y PAPELERIA SA' },
	{ code: '695816', name: 'PRICESMART COLOMBIA SAS' },
	{ code: '478412', name: 'PROVEE SISTEMAS Y CIA LTDA' },
	{ code: '541356', name: 'SISCAD' },
	{ code: '548197', name: 'SISTEMAS Y ACCESORIOS LTDA' },
	{ code: '555126', name: 'SISTETRONICS LTDA' },
	{ code: '525471', name: 'TEKNOPOLIS' },
	{ code: '534599', name: 'VIRTUAL' },
	{ code: '540308', name: 'W AND C' },
	{ code: '485221', name: 'XSYSTEM LTDA' },
	{ code: '617337', name: 'EVOLUCION TECNOLOGICA ETL SA' },
	{ code: '80154', name: 'ICON SA' },
	{ code: '1278763', name: 'ISHOP COSTA RICA' },
	{ code: '536184', name: 'NORTEC CONSULTING' },
	{ code: '563598', name: 'ANTRACO CURACAO BV' },
	{ code: '209532', name: 'CECOMSA' },
	{ code: '535780', name: 'I ZONE' },
	{ code: '1673317', name: 'ONECLICK' },
	{ code: '1676970', name: 'ONECLICK DOMINICAN REPUBLIC' },
	{ code: '443333', name: 'ONMAK' },
	{ code: '266480', name: 'PUNTOMAC' },
	{ code: '216182', name: 'BITLOGIC S A' },
	{ code: '238908', name: 'CARTIMEX' },
	{ code: '453168', name: 'COMOHOGAR' },
	{ code: '2003330', name: 'DIGICENTER SA' },
	{ code: '220143', name: 'EC' },
	{ code: '349193', name: 'EXCELNET' },
	{ code: '451585', name: 'ICENTER' },
	{ code: '324435', name: 'ICESA S A' },
	{ code: '216183', name: 'ISHOP' },
	{ code: '216185', name: 'MACCENTER' },
	{ code: '224639', name: 'MACCENTRO' },
	{ code: '286554', name: 'MARTEC CIA LTDA' },
	{ code: '80161', name: 'MUNDOMAC' },
	{ code: '229577', name: 'PROYSEC' },
	{ code: '556339', name: 'SEPROTEICO CONECTA' },
	{ code: '1268081', name: 'STIMM CIA LTDA' },
	{ code: '450389', name: 'SUPER PA CO' },
	{ code: '564488', name: 'CATCO' },
	{ code: '211783', name: 'EQUIPOS ELECTRONICOS VALDES' },
	{ code: '1371921', name: 'ISHOP EL SALVADOR' },
	{ code: '558098', name: 'MAC STORE' },
	{ code: '216189', name: 'RAF SA DE CV' },
	{ code: '261921', name: 'DISTELSA' },
	{ code: '324275', name: 'ISTORE' },
	{ code: '216193', name: 'ITEMS SA' },
	{ code: '224638', name: 'SONIDO SEGURO - ISHOP' },
	{ code: '262651', name: 'VIVALDI SA' },
	{ code: '261863', name: 'CORPORACION LADY LEE' },
	{ code: '454726', name: 'DIUNSA' },
	{ code: '212930', name: 'JETSTEREO SA DE CV' },
	{ code: '1971132', name: 'MOTTA INTERNACIONAL SA' },
	{ code: '238077', name: 'INNOVATIVE SYSTEMS LIMITED' },
	{ code: '466943', name: 'ROYALE COMPUTERS AND ACCESSORIES' },
	{ code: '525891', name: '3MB' },
	{ code: '384773', name: 'ABACO COMPUTADORAS' },
	{ code: '1586260', name: 'ABSOLUT PC' },
	{ code: '385422', name: 'ACINET' },
	{ code: '539818', name: 'ADTIX' },
	{ code: '533523', name: 'APLICA COMPUTADORAS' },
	{ code: '385382', name: 'AREA MAC MEXICO' },
	{ code: '485641', name: 'ASICOM SYSTEMS' },
	{ code: '385376', name: 'ATENCION LOGICA' },
	{ code: '384805', name: 'AVANTE SOLUCIONES EN COMPUTACION' },
	{ code: '475359', name: 'AVANTEK' },
	{ code: '604681', name: 'BENITEZ PIOQUNTO HORTENCIA' },
	{ code: '385473', name: 'BEST BUY' },
	{ code: '385863', name: 'BODEGA AURRERA' },
	{ code: '385425', name: 'CANCUN VIRTUAL' },
	{ code: '477665', name: 'CARE TELECOM SA DE CV' },
	{ code: '1711712', name: 'CELULAR EXPRESS' },
	{ code: '605646', name: 'CENTRO ELECTRONICO DEL VALLE DE MEXICO SA DE CV' },
	{ code: '589179', name: 'COMPUCENTRO SA DE CV' },
	{ code: '554708', name: 'COMPUPARTES' },
	{ code: '649920', name: 'COMPUSOLUCIONES' },
	{ code: '384798', name: 'COMPUTADORAS DE CALIDAD' },
	{ code: '384793', name: 'COMPUTERLAND' },
	{ code: '385427', name: 'COMPUTOL SA DE CV' },
	{ code: '384753', name: 'COMSETEC SA DE CV' },
	{ code: '385432', name: 'CONSULTORES Y SOPORTE AMD SA DE CV' },
	{ code: '385880', name: 'COPPEL SA DE CV' },
	{ code: '383641', name: 'COSTCO WHOLESALE' },
	{ code: '617584', name: 'CRECE' },
	{ code: '384772', name: 'CYBERMAC SA DE CV' },
	{ code: '385347', name: 'DATAPOINT' },
	{ code: '543865', name: 'DIGITALIFE' },
	{ code: '385359', name: 'DIMAK' },
	{ code: '385435', name: 'DUSOF DE MEXICO SA DE CV' },
	{ code: '477518', name: 'ELIPSIS EN ORDENADORES SA DE CV' },
	{ code: '477583', name: 'EXACTO' },
	{ code: '534989', name: 'FLOWSOFT' },
	{ code: '477513', name: 'FT SOFTWARE' },
	{ code: '593932', name: 'FUSION INTELIGENTE' },
	{ code: '598975', name: 'GENAP TECNOLOGIA' },
	{ code: '532056', name: 'GENESYS' },
	{ code: '477627', name: 'GLARETUM SA DE CV' },
	{ code: '464593', name: 'GRUPO COMSOL' },
	{ code: '385356', name: 'GRUPO INTERLACE SA DE CV' },
	{ code: '464663', name: 'GRUPO SVENSKA' },
	{ code: '384776', name: 'HALL MICRO COMPUTADORAS' },
	{ code: '385429', name: 'HAYAKAWA SA DE CV' },
	{ code: '485709', name: 'HDS' },
	{ code: '477763', name: 'IC CAJEME' },
	{ code: '525403', name: 'IEISA' },
	{ code: '385366', name: 'IGNACIO GOMEZ FRAUSTO' },
	{ code: '589358', name: 'INFINICOM' },
	{ code: '414267', name: 'INGENIERIA EN SOFTWARE SA DE CV' },
	{ code: '386581', name: 'INGRAM MICRO MEXICO SA DE CV' },
	{ code: '385358', name: 'INTECNIA' },
	{ code: '531059', name: 'INTEGRA DE VICTORIA' },
	{ code: '522971', name: 'INTER ENTER' },
	{ code: '414476', name: 'INTERNET COMPUTADORAS ACCESORIOS' },
	{ code: '531075', name: 'IQ TARGET' },
	{ code: '383677', name: 'ISHOP' },
	{ code: '441499', name: 'KREASOFT' },
	{ code: '550300', name: 'LAMONT' },
	{ code: '384808', name: 'LDI ASSOCIATS SA DE CV' },
	{ code: '539932', name: 'LEOPOLDO CASTRO MERCADILLO' },
	{ code: '385360', name: 'LIBRA SISTEMAS' },
	{ code: '383642', name: 'DISTRIBUIDORA LIVERPOOL SA DE CV' },
	{ code: '385375', name: 'LUFAC COMPUTACION SA DE CV' },
	{ code: '426969', name: 'MACGIC STORE' },
	{ code: '540973', name: 'MACRO ELECTRONICA EMPRESARIAL' },
	{ code: '467003', name: 'MACROCARD COMPUTACION SA DE CV' },
	{ code: '385428', name: 'MACSHOP' },
	{ code: '385916', name: 'MACSTORE' },
	{ code: '485770', name: 'MAINBIT' },
	{ code: '580275', name: 'MC MICROCOMPUTACION' },
	{ code: '385345', name: 'MEGA AUDIO SA DE CV' },
	{ code: '385357', name: 'MICRO HOUSE SA DE CV' },
	{ code: '385367', name: 'MICROCENTRO DE SAN LUIS' },
	{ code: '384800', name: 'MICROSISTEMAS CALIFORNIANOS' },
	{ code: '466776', name: 'MM SMART TECHNOLOGY SA DE CV' },
	{ code: '1711058', name: 'MOBO' },
	{ code: '385337', name: 'MRD CONVERGENCIA TECNOLOGICA' },
	{ code: '384803', name: 'MULTISISTEMAS VALCER SA DE CV' },
	{ code: '385371', name: 'MULTIWARE' },
	{ code: '534991', name: 'NOVALAN COMPUTADORAS Y REDES' },
	{ code: '383681', name: 'NUEVA WALMART DE MEXICO S DE RL DE CV' },
	{ code: '383678', name: 'OFFICE DEPOT DE MEXICO' },
	{ code: '383676', name: 'OFFICE MAX DE MEXICO' },
	{ code: '544577', name: 'OPENTEC' },
	{ code: '385453', name: 'PALACIO DE HIERRO' },
	{ code: '554905', name: 'PANDORA BOX' },
	{ code: '436637', name: 'PAPELERIA LOZANO HERMANOS SA CV' },
	{ code: '581057', name: 'PC HOME' },
	{ code: '552165', name: 'PC SERVER' },
	{ code: '547921', name: 'PCDOMINO COM' },
	{ code: '385362', name: 'PROESBA SA DE CV' },
	{ code: '385372', name: 'PROFESIONALES EN COMPUTACION' },
	{ code: '586448', name: 'PROSTAR' },
	{ code: '573278', name: 'PUNTO ZIP' },
	{ code: '385346', name: 'SAI TEL SA DE CV' },
	{ code: '383680', name: 'SAMS CLUB' },
	{ code: '385893', name: 'SANBORN HERMANOS SA' },
	{ code: '477642', name: 'SDC IMPETEL' },
	{ code: '385908', name: 'SEARS' },
	{ code: '384752', name: 'SERVICIO TOTAL EN COMPUTACION' },
	{ code: '2082145', name: 'SICLO' },
	{ code: '384807', name: 'SINAPSIS SERVICIOS INTEGRALES' },
	{ code: '435192', name: 'SISTEMAS CONTINO' },
	{ code: '384962', name: 'SISTEMAS EXPERTOS EN INFORMATICA' },
	{ code: '479200', name: 'STE' },
	{ code: '386596', name: 'TECH DATA MEXICO S DE RL DE CV' },
	{ code: '547532', name: 'TECNIKA' },
	{ code: '385340', name: 'TECNOLOGIA MAC AND PC S A DE C V' },
	{ code: '557767', name: 'TEKSERVEPOS' },
	{ code: '385338', name: 'TEKSOL DE MEXICO SA DE CV' },
	{ code: '385937', name: 'TELEFONOS DE MEXICO SAB DE CV' },
	{ code: '585806', name: 'TENKER' },
	{ code: '763124', name: 'THE PHONE HOUSE' },
	{ code: '458187', name: 'VECTI' },
	{ code: '385344', name: 'VEGA SOFT SA DE CV' },
	{ code: '804813', name: 'WALMART COM MX' },
	{ code: '1801570', name: 'WHITE CORNER' },
	{ code: '478476', name: 'CASA PELLAS MICROTEC' },
	{ code: '1398008', name: 'ISHOP NICARAGUA' },
	{ code: '595818', name: 'SUMIN' },
	{ code: '2286117', name: 'ISHOP PANAMA' },
	{ code: '227400', name: 'MAC STORE CORP' },
	{ code: '251446', name: 'MOTTA INTERNACIONAL SA' },
	{ code: '440249', name: 'MULTIMAX RICARDO ALFARO' },
	{ code: '220543', name: 'PANAFOTO SA' },
	{ code: '268367', name: 'BIG MANZANA' },
	{ code: '237960', name: 'COMPUBRAS' },
	{ code: '1535486', name: 'ISHOP PARAGUAY' },
	{ code: '221313', name: 'SAGA FALABELLA' },
	{ code: '349177', name: 'INGRAM MICRO SAC' },
	{ code: '1369235', name: 'ISHOP PERU' },
	{ code: '2082095', name: 'MAC CENTER' },
	{ code: '585893', name: 'PLAZA VEA' },
	{ code: '216231', name: 'RIPLEY' },
	{ code: '615632', name: 'CLARO PUERTO RICO' },
	{ code: '454727', name: 'ICC' },
	{ code: '209517', name: 'MODERNICA' },
	{ code: '216234', name: 'NETSKY TECHNOLOGY STORE' },
	{ code: '388750', name: 'SAMS CLUB' },
	{ code: '222554', name: 'SCANNER OVERSEAS' },
	{ code: '352732', name: 'WALMART PUERTO RICO' },
	{ code: '571840', name: 'ISTORE' },
	{ code: '510313', name: 'COMPUTER WORLD' },
	{ code: '552200', name: 'COMPUTER SYNECTICS SALES AND SERVICES LTD' },
	{ code: '552660', name: 'DIAMOND SYSTEMS AND SUPPLIES LTDA' },
	{ code: '261896', name: 'MEMORY BANK COMPUTERS LTD' },
	{ code: '264802', name: 'ALEPH GRAPHICS URUGUAY' },
	{ code: '573386', name: 'HOME Y OFFICE' },
	{ code: '216254', name: 'MUNDO MAC' },
	{ code: '305537', name: 'NMI S R L' },
	{ code: '274429', name: 'INVERSIONES INTEGRALES WCC CA' },
	{ code: '216260', name: 'MACGLOBAL CONSULTORES SA' },
	{ code: '222553', name: 'OFICINA PRINCIPAL UNITRON' },
	{ code: '1540625', name: 'ABLE HOME & OFFICE' },
	{ code: '885288', name: 'AELIA DUTY FREE STORES' },
	{ code: '279328', name: 'ALDI STORES' },
	{ code: '2205737', name: 'AUSTRALIA POST' },
	{ code: '422695', name: 'BETTA COMPUTER SERVICES' },
	{ code: '1683411', name: 'BSR GROUP' },
	{ code: '830535', name: 'PALMERSTON BETTA HOME LIVING' },
	{ code: '1679364', name: 'BETTA HOME LIVING CHINCHILLA' },
	{ code: '1681609', name: 'PORT LINCOLN BETTA HOME LIVING' },
	{ code: '343260', name: 'KINGAROY BETTA ELECTRICAL' },
	{ code: '2551065', name: 'BILOELA BETTA HOME LIVING' },
	{ code: '1688868', name: 'BATEMANS BAY BETTA HOME LIVING' },
	{ code: '2035945', name: 'KENNEDYS BETTA HOME LIVING INNISFAIL' },
	{ code: '1679920', name: 'COOMA BETTA HOME LIVING' },
	{ code: '1588305', name: 'TOOWOOMBA BETTA HOME LIVING' },
	{ code: '2095144', name: 'THE BETTA GROUP' },
	{ code: '1355726', name: 'BEGA BETTA HOME LIVING' },
	{ code: '1229113', name: 'THE COMMUNITY CO OPERATIVE STORE NURIOOTPA LTD' },
	{ code: '1857217', name: 'BETTA HOME LIVING YEPPOON' },
	{ code: '1688864', name: 'ULLADULLA BETTA HOME LIVING' },
	{ code: '2406257', name: 'GOONDIWINDI BETTA HOME LIVING' },
	{ code: '1634617', name: 'GLADSTONE BETTA HOME LIVING' },
	{ code: '222308', name: 'BIG W' },
	{ code: '214147', name: 'BING LEE' },
	{ code: '1032682', name: 'BRIGHTSTAR LOGISTICS PTY LIMITED' },
	{ code: '1960913', name: 'CATCH.COM.AU' },
	{ code: '558791', name: 'CHRISCO HAMPERS' },
	{ code: '361742', name: 'COSTCO' },
	{ code: '114289', name: 'DAVID JONES' },
	{ code: '213352', name: 'DICK SMITH' },
	{ code: '1212981', name: 'ESPERANCE COMMUNICATIONS' },
	{ code: '215841', name: 'HARVEY NORMAN/DOMAYNE' },
	{ code: '212884', name: 'JB HI-FI AU' },
	{ code: '835902', name: 'JR DUTY FREE' },
	{ code: '222210', name: 'KMART AU' },
	{ code: '2092377', name: 'LOTTE DUTY FREE' },
	{ code: '212369', name: 'MYER' },
	{ code: '1071694', name: 'MYER - BEATS' },
	{ code: '208757', name: 'NUANCE' },
	{ code: '1184343', name: 'OFFICE NATIONAL ULLADULLA' },
	{ code: '352349', name: 'OFFICEWORKS' },
	{ code: '1032273', name: 'PRO AV SOLUTIONS' },
	{ code: '1458387', name: 'QANTAS AIRWAYS LIMITED' },
	{ code: '286609', name: 'RADIO RENTALS LTD' },
	{ code: '1032403', name: 'RETRAVISION KALGOORLIE' },
	{ code: '1575561', name: 'RETRAVISION SOUTH HEDLAND' },
	{ code: '1417166', name: 'RETRAVISION BUNBURY' },
	{ code: '1720509', name: 'RETRAVISION KUNUNURRA' },
	{ code: '1576332', name: 'RETRAVISION KARRATHA' },
	{ code: '1741256', name: 'RETRAVISION NEWMAN' },
	{ code: '1578229', name: 'RETRAVISION MANJIMUP' },
	{ code: '240826', name: 'RETRAVISION GERALDTON' },
	{ code: '1576668', name: 'RETRAVISION ALBANY' },
	{ code: '1666655', name: 'RETRAVISION ESPERANCE APPLIANCE CENTRE' },
	{ code: '2551025', name: 'RETRAVISION HARVEY' },
	{ code: '1747805', name: 'RETRAVISION KADINA ELECTRICAL' },
	{ code: '343005', name: 'SINGLETON HI-FI' },
	{ code: '212406', name: 'TARGET' },
	{ code: '1397413', name: 'TARGET - BEATS' },
	{ code: '548126', name: 'TED\'S CAMERA STORES (VIC.) PTY. LIMITED' },
	{ code: '1807468', name: 'THE GOOD GUYS' },
	{ code: '564002', name: 'THINK SOLUTIONS' },
	{ code: '1366743', name: 'WH SMITH' },
	{ code: '1065243', name: 'WILSONTON BETTA HOME LIVING' },
	{ code: '1152452', name: '100% HEATHCOTE APPLIANCES - HAMILTON' },
	{ code: '1261676', name: 'ARI AUCKLAND' },
	{ code: '672054', name: 'DENERAU DF ELECTRONICS' },
	{ code: '1080092', name: 'FOLDERS' },
	{ code: '432060', name: 'HARVEY NORMAN' },
	{ code: '348872', name: 'HEATHCOTES TRISTRAM STREET' },
	{ code: '278754', name: 'JB HI-FI NZ' },
	{ code: '367977', name: 'JR DUTY FREE' },
	{ code: '2079335', name: 'LOTTE DUTY FREE' },
	{ code: '2300860', name: 'MIGHTY APE LIMITED' },
	{ code: '439468', name: 'NOEL LEEMING HEAD OFFICE' },
	{ code: '360335', name: 'PB TECHNOLOGIES LTD' },
	{ code: '403269', name: 'SMITHS CITY' },
	{ code: '348733', name: 'THE WAREHOUSE LIMITED' },
	{ code: '811614', name: 'WAREHOUSE STATIONERY' },
	{ code: '64459', name: 'ADVANCE ELECTRONICS' },
	{ code: '320760', name: 'ANDRE\'S ELECTRONIC EXPERTS' },
	{ code: '112975', name: 'ANNEX PRO' },
	{ code: '103216', name: 'APPLE-EAST UNAUTHORIZED' },
	{ code: '830748', name: 'BACKSTAGE' },
	{ code: '203054', name: 'BC WEB' },
	{ code: '252584', name: 'BEST BUY CANADA LTD' },
	{ code: '1003351', name: 'BLUESTAR CANADA' },
	{ code: '1621935', name: 'BRIGHTSTAR CANADA' },
	{ code: '203048', name: 'BURNT STEW COMPUTER SOLUTIONS' },
	{ code: '229794', name: 'CANEX' },
	{ code: '64505', name: 'CARBON COMPUTING' },
	{ code: '202946', name: 'CENTRAL MICROSYSTEMS' },
	{ code: '1092767', name: 'CENTRAL OFFICE EQUIPMENT LIMITED' },
	{ code: '1667770', name: 'CESIUM TELECOM INCORPORATED' },
	{ code: '1050484', name: 'CINEMA SATELLITE' },
	{ code: '203031', name: 'CLICKON MAC SOLUTIONS' },
	{ code: '389589', name: 'CO-OP @ HOME' },
	{ code: '231148', name: 'COMPUTER DEPOT' },
	{ code: '221166', name: 'COOP UQAM' },
	{ code: '64498', name: 'CORE1' },
	{ code: '220130', name: 'COSTCO WHOLESALE CANADA LTD' },
	{ code: '1239900', name: 'DAVIDSON IMAGE ET SON' },
	{ code: '1131953', name: 'DIVERSIONS COMPUTER CENTRE' },
	{ code: '1005984', name: 'DONNY \'B\' THE WIZARD OF MUSIC INC' },
	{ code: '887318', name: 'ECHO SOUND LTD' },
	{ code: '300227', name: 'EUROTECH SYSTEMS' },
	{ code: '1288308', name: 'FREY COMMUNICATIONS INC' },
	{ code: '277772', name: 'FULL LINE ELECTRONICS INC' },
	{ code: '345412', name: 'GEMSBOK TECHNOLOGIES INC' },
	{ code: '962803', name: 'GLASSER\'S TV SERVICE LTD' },
	{ code: '1536884', name: 'HERMES CANADA' },
	{ code: '278897', name: 'HMV CANADA INC' },
	{ code: '203066', name: 'HORIZON' },
	{ code: '102921', name: 'IC SUPERCOMPUTERS' },
	{ code: '242981', name: 'ICONNECT - MILTON MALL' },
	{ code: '304197', name: 'IGALLERIA' },
	{ code: '216332', name: 'INDIGO BOOKS & MUSIC INC' },
	{ code: '92680', name: 'INGRAM MICRO LP' },
	{ code: '531739', name: 'ISTORE INC' },
	{ code: '607542', name: 'IWORLD CONNECT' },
	{ code: '564444', name: 'JUMP+' },
	{ code: '212812', name: 'KRAZY KRAZY' },
	{ code: '228768', name: 'LE MAC URBAIN' },
	{ code: '877244', name: 'LECTRIC AVENUE ELECTRONICS' },
	{ code: '102936', name: 'LES SERVICES INFORMATIQUES' },
	{ code: '64511', name: 'LONDON DRUGS' },
	{ code: '102938', name: 'LONG & MCQUADE LIMITED' },
	{ code: '227166', name: 'MAAR\'S MUSIC' },
	{ code: '202936', name: 'MAC OUTPOST' },
	{ code: '202974', name: 'MAC OUTPOST ST CATHARINES' },
	{ code: '212301', name: 'MACXPERT' },
	{ code: '1061149', name: 'MASKIMO CONSTRUCTION INC' },
	{ code: '830753', name: 'MCMURRAY TV CENTRE LTD' },
	{ code: '239102', name: 'MEADIASOLUTIONS' },
	{ code: '251496', name: 'MEMORY LANE COMPUTERS' },
	{ code: '64448', name: 'MICRO-BOUTIQUE' },
	{ code: '402914', name: 'MIDTOWN DIGITAL' },
	{ code: '2085410', name: 'MIDTOWN TECH' },
	{ code: '1390161', name: 'MONERIS' },
	{ code: '877240', name: 'MURLIN ELECTRONICS LTD' },
	{ code: '406695', name: 'MY TECH GUYS' },
	{ code: '877241', name: 'NEW AGE ELECTRONICS INC' },
	{ code: '1544728', name: 'NIKE CANADA' },
	{ code: '1242564', name: 'NORTHERN LIGHTS COMPUTING' },
	{ code: '388682', name: 'NORTHMART' },
	{ code: '102970', name: 'OFFICE SOLUTIONS' },
	{ code: '303037', name: 'ORDIPLUS INFORMATIQUE' },
	{ code: '1236247', name: 'OSHTUGON COMPUTERS INC' },
	{ code: '202927', name: 'PALANTIR COMPUTERS' },
	{ code: '237338', name: 'POWERLAND COMPUTERS' },
	{ code: '389153', name: 'PURELOGIC IT SOLUTIONS INC' },
	{ code: '203058', name: 'RESTART COMPUTER INC' },
	{ code: '223861', name: 'RIVERDALE MAC' },
	{ code: '320752', name: 'ROY\'S AUDIO VIDEO UNLIMITED' },
	{ code: '203024', name: 'ROYAL PHOTO' },
	{ code: '233221', name: 'SERVALL CANADA INCORPORATED' },
	{ code: '830756', name: 'SHANE\'S MUSIC - OTINEKA MALL' },
	{ code: '226833', name: 'SHOPPERS DRUG MART' },
	{ code: '212784', name: 'SIGHT AND SOUND' },
	{ code: '102999', name: 'SILICON COWBOYS' },
	{ code: '63770', name: 'SIMPLY COMPUTING INC' },
	{ code: '212802', name: 'SOFTWARE EMPORIUM' },
	{ code: '202975', name: 'SOHO COMPUTER SERVICES' },
	{ code: '324267', name: 'SPACEK\'S LIMITED' },
	{ code: '1613861', name: 'SPORT CHEK' },
	{ code: '240146', name: 'STAPLES' },
	{ code: '389954', name: 'SUPERIOR COMPUTERS INC' },
	{ code: '63743', name: 'SYNNEX CANADA LIMITED' },
	{ code: '1205965', name: 'TECH DATA CANADA CORPORATION' },
	{ code: '877245', name: 'TESKEY AUDIO VIDEO' },
	{ code: '452161', name: 'THE COMPUDOC INC' },
	{ code: '221928', name: 'THE SOURCE' },
	{ code: '304500', name: 'TOYS R US (CANADA) LTD' },
	{ code: '202938', name: 'TRG COMPUTER SOLUTIONS' },
	{ code: '351701', name: 'ULTIMAC TECHNOLOGIES INC' },
	{ code: '215426', name: 'VISIONS ELECTRONICS' },
	{ code: '230587', name: 'WALMART' },
	{ code: '1552556', name: 'WE ETOBICOKE 524 1214' },
	{ code: '64451', name: 'WESTWORLD COMPUTERS LTD' },
	{ code: '915810', name: 'WILSON\'S BUSINESS SOLUTIONS' },
	{ code: '879791', name: 'YUEN\'S CELLULAR CENTRE' },
	{ code: '369363', name: 'ZONE UNIVERSITE LAVAL' },
	{ code: '1236518', name: 'AKESU XINGUANG XUNTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1288697', name: 'AKESU YANGGUANG SHIJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1283929', name: 'ALA\'ER JINXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1998755', name: 'ALA\'ERSHI HUASHENG YANGGUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1338061', name: 'ALAERSHI FENGSHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1389510', name: 'ALASHAN ZUOQI BAYANHAOTEZHEN YIDA SHOUJI TIYANDIAN' },
	{ code: '1787912', name: 'ALASHANZUOQI BAYANHAOTEZHEN HONGWEI SHIJI TONGXUNDIAN' },
	{ code: '2035171', name: 'ALUKE\'ERQINQI TIANSHANZHEN XINLU TONGXUNCHENG' },
	{ code: '2333737', name: 'ANDASHI ZHENGYANG YANSHAN DIANXUN SHOUJI QIJIANDIAN' },
	{ code: '1969531', name: 'ANDINGQU KEXU TONGXUNCHENG' },
	{ code: '1220658', name: 'ANFUXIAN HONGXING SHOUJICHENG' },
	{ code: '2331717', name: 'ANGUO JINMUZHI TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1252281', name: 'ANGUOSHI HONGDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2398757', name: 'ANGUOSHI MAODA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '753519', name: 'ANHUI BAIDA DIANQI LIANSUO YOUXIAN GONGSI' },
	{ code: '2898925', name: 'ANHUI DAGANGLAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2464165', name: 'ANHUI DINGYU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2393528', name: 'ANHUI FENGXING SHENGDA TECHNOLOGY SERVICE CO., LTD' },
	{ code: '1966904', name: 'ANHUI GANGLIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1747540', name: 'ANHUI GUDUO QIYE XINXI ZIXUN YOUXIAN GONGSI' },
	{ code: '564588', name: 'ANHUI GUOSHENG DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '2114746', name: 'ANHUI HAOJIYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2488885', name: 'ANHUI HENGHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1171933', name: 'ANHUI HENGYI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1737120', name: 'ANHUI HONGDE SHENGYE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1235047', name: 'ANHUI KEJIE TONGXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1624456', name: 'ANHUI REDIAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1341036', name: 'ANHUI RUANNIAO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1310827', name: 'ANHUI SHUIJINGHAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1856047', name: 'ANHUI WENSHAN DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1287237', name: 'ANHUI XINHONGFA WANGLUO XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1306435', name: 'ANHUI XINTIANYI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1321831', name: 'ANHUI YIFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1775382', name: 'ANHUI YINGFUTE TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '925679', name: 'ANHUI YOUTUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1474981', name: 'ANHUI YUANYIN MAOYI YOUXIAN GONGSI' },
	{ code: '1337480', name: 'ANHUISHENG HAIXIA TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1240204', name: 'ANHUISHENG HUOSHANXIAN SHIJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1721771', name: 'ANJI JIAJIHUI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1300806', name: 'ANJI JINXIANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1966892', name: 'ANJI TIANYIN TONGXUN QICAIDIAN' },
	{ code: '1492952', name: 'ANKANG SHENLAN KEJI YOUXIAN GONGSI' },
	{ code: '1958030', name: 'ANKANGSHI HANBINQU HUAKANG TONGXUNDIAN' },
	{ code: '1331007', name: 'ANKANGSHI HENGFA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1638338', name: 'ANKANGSHI HUASHENG KEJI TONGXUN YOUXIAN GONGSI' },
	{ code: '1075430', name: 'ANKANGSHI JIANGFENG KEJI YOUXIAN GONGSI' },
	{ code: '1486748', name: 'ANKANGSHI QIULIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2189880', name: 'ANKANGSHI YANDONG TONGXIN SHEBEI KEJI YOUXIAN GONGSI' },
	{ code: '2008142', name: 'ANNING YISHANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1974925', name: 'ANNING YUANJING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1160282', name: 'ANPINGXIAN BAIHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2072347', name: 'ANPINGXIAN GUOXIN TONGXUN MENSHIBU' },
	{ code: '1762236', name: 'ANPINGXIAN YAAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1310842', name: 'ANQINGSHI ANZHI TONGXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1300776', name: 'ANQINGSHI JINTONG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '695355', name: 'ANQINGSHI LIXIN TONGXIN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1284475', name: 'ANQINGSHI XINLIAN TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '941011', name: 'ANQINGSHI ZHONGYOU TONGXIN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1969942', name: 'ANRENXIAN XINDELE TONGXUN' },
	{ code: '1390433', name: 'ANSHAN BEIFANG GEAN TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1770370', name: 'ANSHAN WANZHEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1527732', name: 'ANSHAN XINGFU TONGXUN YOUXIAN GONGSI' },
	{ code: '1263040', name: 'ANSHAN XINLIXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1269896', name: 'ANSHAN YIBANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1300795', name: 'ANSHANSHI XINCHUANGXIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1290293', name: 'ANSHANSHI XINHONGXIN TONGXUN DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '1718901', name: 'ANSHUN XINGYUNTONG DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1560597', name: 'ANXIANGXIAN YIKELONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1365297', name: 'ANXINXIAN KAIXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1786823', name: 'ANXIXIAN XUNTING TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '670157', name: 'ANYANG CHANGSHUN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1742333', name: 'ANYANG DEYI HENGTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1469202', name: 'ANYANG XINGHUA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '688665', name: 'ANYANGSHI DONGFANG CHENHUI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1134222', name: 'ANYANGSHI LITONG TONGXIN QICAI MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1252292', name: 'ANYANGSHI ZHONGBANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1968596', name: 'ANYANGXIAN SHUIYEZHEN HAOHANG TONGXUN MENSHIBU' },
	{ code: '1769735', name: 'ANYUANXIAN HEXUN TONGXIN YOUXIAN GONGSI' },
	{ code: '1329143', name: 'ANYUEXIAN XINWUYANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1516958', name: 'AOHANQI YILONGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1343053', name: 'ARONGQI GUANGXIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1817602', name: 'BADONGXIAN BEIXIAOJIANG SHOUJI JINGYINGBU' },
	{ code: '1535917', name: 'BADONGXIAN XINHUI TONGXUN YOUXIAN GONGSI' },
	{ code: '1397526', name: 'BAICHENGSHI HONGYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1787901', name: 'BAICHENGSHI LINGHANG TONGXUN QICAI SHANGDIAN' },
	{ code: '1284563', name: 'BAICHENGSHI TIANYUN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1991869', name: 'BAICHENGSHI ZHISHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1334200', name: 'BAIQUANXIAN TIANYUE MAOYI YOUXIAN GONGSI' },
	{ code: '1404617', name: 'BAISESHI FENGRUI MAOYI YOUXIAN GONGSI' },
	{ code: '1856087', name: 'BAISESHI FUXUN TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '2066461', name: 'BAISESHI JIARUI KEJI YOUXIAN GONGSI' },
	{ code: '1239538', name: 'BAISESHI XINLIAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '2486345', name: 'BAISHANSHI CHENGXIN CHAOPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2073173', name: 'BAISHANSHI QUANPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1046842', name: 'BAIYIN LICHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1810994', name: 'BAIYINQU LISHENG SHOUJI SHANGCHENG' },
	{ code: '1720939', name: 'BAIYINSHI PINGCHUANQU KAIYUE SHOUJI LIANSUO CHAOSHI' },
	{ code: '1395186', name: 'BAODING BAIGOU XINCHENG XINSHIDAI TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2035508', name: 'BAODING DONGCHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '687995', name: 'BAODING XUNTIANYOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1770378', name: 'BAODING YOUXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1314911', name: 'BAODING ZHANBO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1397509', name: 'BAODING ZHIHAI TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '799758', name: 'BAODINGSHI HUAYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1396785', name: 'BAODINGSHI LINSHUO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1393510', name: 'BAODINGSHI QINGYUANQU HONGLI TONGXUN QICAI JINGXIAOCHU' },
	{ code: '1097446', name: 'BAODINGSHI TIANXUN MAOYI YOUXIAN GONGSI' },
	{ code: '1319856', name: 'BAODINGSHI YILIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1771117', name: 'BAODINGSHI ZHONGSONGSHAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1968588', name: 'BAOFENGXIAN DONGFANG TONGXIN GUANGCHANG' },
	{ code: '1261771', name: 'BAOJI SHIDAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1310209', name: 'BAOJI WEIDE KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1521823', name: 'BAOJI XINSHENGLONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1479243', name: 'BAOJISHI JINZHENGKANG GONGMAO YOUXIAN GONGSI' },
	{ code: '1310774', name: 'BAOJISHI LONGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1515117', name: 'BAOJISHI XINCHANGTAI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1307140', name: 'BAOJISHI YUANFANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1307149', name: 'BAOJISHI ZHONGRENHE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1474456', name: 'BAOQINGXIAN JIANYUAN SHENGHE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1298042', name: 'BAOSHAN FENGLIN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '948268', name: 'BAOSHANSHI QIDI SHANGMAO YOUXIAN GONGSI' },
	{ code: '706872', name: 'BAOSHANSHI YONGCHENG SHANGWU YOUXIAN GONGSI' },
	{ code: '1372001', name: 'BAOSHANSHI ZEQUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1222965', name: 'BAOTOUSHI BAIGUANG MAOYI YOUXIAN GONGSI' },
	{ code: '1284007', name: 'BAOTOUSHI GUOYI ANFANG KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1280374', name: 'BAOTOUSHI TONGLI JIADIAN YOUXIAN ZEREN GONGSI' },
	{ code: '1341064', name: 'BAOTOUSHI XINGANXIAN TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '2036119', name: 'BAOYINGXIAN ZHANGJIANLI TONGXUN QICAI JINGYINGBU' },
	{ code: '1398054', name: 'BAYANNAOERSHI LINHE BAIHUO DALOU YOUXIAN ZEREN GONGSI' },
	{ code: '1027494', name: 'BAYANNAOERSHI RUIFENG GONGMAO YOUXIAN GONGSI' },
	{ code: '1372758', name: 'BAYANXIAN XINXIN HENGDA TONGXUN QICAI SHANGDIAN' },
	{ code: '927845', name: 'BAZHONGSHI FUQUN TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '965233', name: 'BAZHONGSHI YONGJI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1311974', name: 'BAZHOU JINTENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1532979', name: 'BAZHOU KAIBEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1392744', name: 'BAZHOU XIAOKEDOU WANGLUO KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1439563', name: 'BAZHOUSHI HANBIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1381243', name: 'BAZHOUSHI HANYUE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1050744', name: 'BAZHOUSHI RUIDE JIECHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1060812', name: 'BEATS CHINA DEALERS' },
	{ code: '1817560', name: 'BEIAN NONGKEN XUNKE NONGCHANG WANGPING DIANXUN SHANGDIAN' },
	{ code: '1337472', name: 'BEIHAI TIANRUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2515065', name: 'BEIHAI YITIANYU DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '753255', name: 'BEIHAISHI HAOPENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1475585', name: 'BEIJING AIPUHUA KEJI YOUXIAN GONGSI' },
	{ code: '1622316', name: 'BEIJING ANTONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '563912', name: 'BEIJING BAIWEI BORUI MAOYI YOUXIAN GONGSI' },
	{ code: '1302778', name: 'BEIJING BAONUOER SHANGWU YOUXIAN GONGSI' },
	{ code: '2059773', name: 'BEIJING BOYUAN TIANLI KEJI FAZHAN YOUXIAN ZEREN GONGSI' },
	{ code: '1280292', name: 'BEIJING CHANGCUN KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1294596', name: 'BEIJING CHANGHONG JIAHUA ZHINENG XITONG YOUXIAN GONGSI' },
	{ code: '1274974', name: 'BEIJING CHUNGUO DIANXUN QICAI YOUXIAN GONGSI' },
	{ code: '1576700', name: 'BEIJING DANGDANG KEWEN DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '2099072', name: 'BEIJING DANGDANGWANG XINXI JISHU YOUXIAN GONGSI' },
	{ code: '293788', name: 'BEIJING DAZHONG THE HOME APPLIANCES CATENA SALE CO.,LTD' },
	{ code: '244720', name: 'BEIJING DIGITAL CHINA LIMITED' },
	{ code: '358995', name: 'BEIJING DIGITAL CHINA SUPPLY CHAIN SERVICE COMPANY LIMITED' },
	{ code: '621368', name: 'BEIJING DIXINTONG SHANGMAO GUFEN YOUXIAN GONGSI' },
	{ code: '1705402', name: 'BEIJING DONGFANG AOLONG KEJI YOUXIAN GONGSI' },
	{ code: '1310201', name: 'BEIJING DONGRUN LIDA KEJI YOUXIAN GONGSI' },
	{ code: '2090552', name: 'BEIJING FANGZHENG APABI JISHU YOUXIAN GONGSI' },
	{ code: '1667965', name: 'BEIJING FANGZHENG HULIAN JISHU YOUXIAN GONGSI' },
	{ code: '695344', name: 'BEIJING FANGZHENG SAIYISI KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '620050', name: 'BEIJING FANGZHENG XINXI JISHU YOUXIAN GONGSI' },
	{ code: '2143477', name: 'BEIJING FANGZHENG XINXI JISHU YOUXIAN GONGSI' },
	{ code: '693465', name: 'BEIJING FANGZHENG YITONG KEJI YOUXIAN GONGSI' },
	{ code: '1439546', name: 'BEIJING FEIXIANG FENGYI KEJI YOUXIAN GONGSI' },
	{ code: '1710699', name: 'BEIJING FENGTENG HENGYE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '713846', name: 'BEIJING GUODA WEIYE DIANQI YOUXIAN GONGSI' },
	{ code: '1296508', name: 'BEIJING GUOTOU XINGYE XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1647460', name: 'BEIJING HAIDAFEI KEJI YOUXIAN GONGSI' },
	{ code: '899432', name: 'BEIJING HENGSHA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '543470', name: 'BEIJING HENGZHOU KEJI YOUXIAN GONGSI' },
	{ code: '1398186', name: 'BEIJING HONGFENG ZHIXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1958516', name: 'BEIJING HUAQING SHICHUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1273885', name: 'BEIJING HUATAI SHENGYING SHANGMAO YOUXIAN GONGSI' },
	{ code: '693392', name: 'BEIJING HUAXINTONG DIANXUN YOUXIAN GONGSI' },
	{ code: '1222982', name: 'BEIJING HUAYI XUNTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2393946', name: 'BEIJING HUAYUWEICHENG TECHNOLOGY SERVICE CO.,LTD' },
	{ code: '1063915', name: 'BEIJING JINGDONG SHIJI MAOYI YOUXIAN GONGSI' },
	{ code: '368017', name: 'BEIJING JINGDONG SHIJI MAOYI YOUXIAN GONGSI' },
	{ code: '2006164', name: 'BEIJING JINGDONG SHIJI MAOYI YOUXIAN GONGSI' },
	{ code: '1536829', name: 'BEIJING JINGDONG SHIJI XINXI JISHU YOUXIAN GONGSI' },
	{ code: '810186', name: 'BEIJING JIRIYU TONGXUN SHEBEI ZHONGXIN' },
	{ code: '2906825', name: 'BEIJING JURAN ZHIJIA DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1624359', name: 'BEIJING JURAN ZHIJIA ZHINENG KEJI YOUXIAN GONGSI' },
	{ code: '1583932', name: 'BEIJING KAIPULE KEJI YOUXIAN GONGSI' },
	{ code: '1594884', name: 'BEIJING KAIPULE KEJI YOUXIAN GONGSI' },
	{ code: '1550098', name: 'BEIJING LECHUANG JIYE KEJI YOUXIAN GONGSI' },
	{ code: '610936', name: 'BEIJING LEYU SHIJI KEJI JITUAN YOUXIAN GONGSI' },
	{ code: '632366', name: 'BEIJING LICHANGDA SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2396826', name: 'BEIJING LIXING CHUANGLIAN KEJI FUWU YOUXIAN GONGSI' },
	{ code: '1645559', name: 'BEIJING PINLEHUI KEJI YOUXIAN GONGSI' },
	{ code: '2106917', name: 'BEIJING QILI RUANJIAN JISHU YOUXIAN GONGSI' },
	{ code: '1263507', name: 'BEIJING RUIHUATONG TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1969511', name: 'BEIJING RUIQI HENGXIN KEJI YOUXIAN GONGSI' },
	{ code: '602938', name: 'BEIJING SHANGPAI ZHENGPIN KEJI YOUXIAN GONGSI' },
	{ code: '1745012', name: 'BEIJING SHENGDUO SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1321816', name: 'BEIJING SHENXINYE KEMAO YOUXIAN GONGSI' },
	{ code: '2068768', name: 'BEIJING SHENZHOU SHUMA ZHIHUI SHENGHUO KEJI YOUXIAN GONGSI' },
	{ code: '1147916', name: 'BEIJING SILEIKANG KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '2072700', name: 'BEIJING SIWEI TONGLIAN DIANXUN QICAI YOUXIAN GONGSI' },
	{ code: '529957', name: 'BEIJING SONGLIAN KEJI YOUXIAN GONGSI' },
	{ code: '1467883', name: 'BEIJING TAILIXIN KEJI YOUXIAN GONGSI' },
	{ code: '1963291', name: 'BEIJING TIANMA KESAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1540097', name: 'BEIJING TIANYAO XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1583931', name: 'BEIJING WEIDONG KEJI YOUXIAN GONGSI' },
	{ code: '1541373', name: 'BEIJING XINCHENG HULIANWANG KEJI YOUXIAN GONGSI' },
	{ code: '849792', name: 'BEIJING XINGQIU TONGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '548016', name: 'BEIJING XINTONG WANBAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '229258', name: 'BEIJING YANGGUANG ZHIBO KEJI YOUXIAN GONGSI' },
	{ code: '1632672', name: 'BEIJING YIKE SHIJI KEMAO YOUXIAN GONGSI' },
	{ code: '1282629', name: 'BEIJING YILIAN XUNDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '265275', name: 'BEIJING YINGLONG HUACHEN KEJI YOUXIAN GONGSI' },
	{ code: '366640', name: 'BEIJING YONGTE SANLIUWU SHANGWU YOUXIANGONGSI' },
	{ code: '2110569', name: 'BEIJING ZHIXIN CHUANGYE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '536236', name: 'BEIJING ZHONGFU DIANXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '2035552', name: 'BEIJING ZHONGGANG TONGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1857299', name: 'BEIJING ZHONGHANG ZHIBO KEJI YOUXIAN GONGSI' },
	{ code: '544151', name: 'BEIJING ZHONGHENG YIZHAN SHUMA XINXI JISHU YOUXIAN GONGSI' },
	{ code: '2114683', name: 'BEIJING ZHONGYI DECHENG KEJI YOUXIAN GONGSI' },
	{ code: '2073458', name: 'BEIJING ZIYAN\'GE WENHUA FAZHAN YOUXIAN GONGSI' },
	{ code: '1484702', name: 'BEILIUSHI DONGFANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1235897', name: 'BEIPIAOSHI NANSHAN JIAQI TONGXUN QICAI SHANGDIAN' },
	{ code: '2085956', name: 'BEISHENG HULIAN (JIANGSU) KEJI YOUXIAN GONGSI' },
	{ code: '2109045', name: 'BEIZHENSHI AOXUN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1981507', name: 'BEIZHENSHI JINMA TONGXUNHANG' },
	{ code: '1170369', name: 'BEIZHENSHI LIANXIN DIANZI YOUXIAN GONGSI' },
	{ code: '1307898', name: 'BENGBUSHI XINSHIJI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '835952', name: 'BENXI HONGYU HAIER JIADIAN YOUXIAN GONGSI' },
	{ code: '1310928', name: 'BENXI TENGLONG SHANGMAO KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1818746', name: 'BENXI YUANQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2441506', name: 'BENXISHI PINGSHANQU XINDI TONGXUN SHANGHANG' },
	{ code: '1770448', name: 'BENXISHI SHENZHOU HONGYU DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '1078852', name: 'BENXISHI XINBADA TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1318694', name: 'BENXISHI XINGBADA TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1205066', name: 'BINHAI WEIYE TONGXUN ZHONGDUAN SHEBEI YOUXIAN GONGSI' },
	{ code: '1809311', name: 'BINHAIXIAN DONGKANZHEN JIEHAO SHOUJI JINGYINGBU' },
	{ code: '2646745', name: 'BINXIAN BINZHOU YIXUN TONGXUN QICAI SHANGDIAN' },
	{ code: '1479244', name: 'BINXIAN JINSHI TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1994841', name: 'BINXIAN XINSHENGMING DIANXIN DAILI FUWU YOUXIAN GONGSI' },
	{ code: '1720903', name: 'BINYANGXIAN WANGZHE TONGXUN QICAI JINGYINGBU' },
	{ code: '1314813', name: 'BINZHOU JINPENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1512487', name: 'BINZHOU SHUNGUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1472879', name: 'BINZHOU YUANJIAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1269902', name: 'BINZHOUSHI BORUN GONGMAO YOUXIAN GONGSI' },
	{ code: '1269997', name: 'BINZHOUSHI GUANZHIXUN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2075839', name: 'BINZHOUSHI SHENGKAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1472873', name: 'BIYANGXIAN PANGU SHENGSHI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1248517', name: 'BOERTALA MENGGU ZIZHIZHOU XINQIAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1358684', name: 'BOXINGXIAN JINGDONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1988171', name: 'BOXINGXIAN ZHONGLIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '2000248', name: 'BOZHOUSHI FENGSHUO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1171169', name: 'BOZHOUSHI SHENGHENG DIANZI SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1302761', name: 'BOZHOUSHI TIANCHENG KEJI FUWU YOUXIAN GONGSI' },
	{ code: '1172757', name: 'BOZHOUSHI YUSHUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1748613', name: 'BRIGHTSTAR TELECOM TECHNOLOGY SERVICES (SHANGHAI) CO. LTD.' },
	{ code: '1697888', name: 'BUBUGAO SHANGYE LIANSUO GUFEN YOUXIAN GONGSI' },
	{ code: '1394627', name: 'CANGXIXIAN CHENGYUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1338019', name: 'CANGZHOU HONGTAIYANG GOUWU GUANGCHANG YOUXIAN GONGSI' },
	{ code: '1288554', name: 'CANGZHOU XIANGDE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2063373', name: 'CANGZHOU XUANYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1320532', name: 'CANGZHOU XUNLIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1790692', name: 'CAOXIAN BAITAI SHOUJI DAMAICHANG' },
	{ code: '1286057', name: 'CAOXIAN CHUANGTONG DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1222379', name: 'CENXISHI TENGFEI SHOUJI WEIXIUDIAN' },
	{ code: '2377330', name: 'CHAISANGQU KAITAI HONGQI SHOUJICHENG' },
	{ code: '1691935', name: 'CHALINGXIAN SHENZHOU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1542471', name: 'CHANGBAISHAN BAOHU KAIFAQU LIANZHAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1338060', name: 'CHANGCHUN HONGJI SHUMA KEJI YOUXIAN GONGSI GONGZHULING FENGONGSI' },
	{ code: '843705', name: 'CHANGCHUN HONGSHENG JINGMAO YOUXIAN GONGSI' },
	{ code: '229286', name: 'CHANGCHUN TAIBOCHAO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '549002', name: 'CHANGCHUN TIANTIAN SHOUJI JITUAN YOUXIAN GONGSI' },
	{ code: '1645654', name: 'CHANGCHUNSHI BOXIN KEJI YOUXIAN GONGSI' },
	{ code: '1293880', name: 'CHANGCHUNSHI CHENGFENG TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1293266', name: 'CHANGCHUNSHI FENGXING DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '671730', name: 'CHANGCHUNSHI HAITIAN WEIYE SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1364773', name: 'CHANGDE ZHANXIANG MAOYI YOUXIAN GONGSI' },
	{ code: '783327', name: 'CHANGDESHI TIANYIN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1777195', name: 'CHANGGESHI CHANGDE LIANLI SHANGMAO YOUXIAN GONGSI' },
	{ code: '707384', name: 'CHANGGESHI HUAYU LONGTENG KEJI YOUXIAN GONGSI' },
	{ code: '1019159', name: 'CHANGGESHI ZHONGYU DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '1149437', name: 'CHANGJISHI ZHONGYI SHENGJIA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1856359', name: 'CHANGKONG FEIYING SHANGMAO (YINGKOU) YOUXIAN GONGSI' },
	{ code: '1282108', name: 'CHANGLIXIAN TONGLI TONGXIN YOUXIAN GONGSI' },
	{ code: '1156151', name: 'CHANGSHA CHEN\'AO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2366397', name: 'CHANGSHA DIHAO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1005500', name: 'CHANGSHA HULIANTONG DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1311967', name: 'CHANGSHA JINHONG RUANJIAN KEJI YOUXIAN GONGSI' },
	{ code: '1150152', name: 'CHANGSHA LIANHONG DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1274972', name: 'CHANGSHA OUDISI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1311843', name: 'CHANGSHA QIXIN TONGXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1334189', name: 'CHANGSHA RUICHUANG ZIDONGHUA KEJI YOUXIAN GONGSI' },
	{ code: '608849', name: 'CHANGSHA TONGCHENG KONGGU GUFEN YOUXIAN GONGSI' },
	{ code: '671352', name: 'CHANGSHA XIANGFEI TONGXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '2371996', name: 'CHANGSHA YUNDING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1791344', name: 'CHANGSHANXIAN DAJIA TONGXIN SHANGHANG' },
	{ code: '1786815', name: 'CHANGSHASHI FURONGQU GUANGCHENG HELI TONGXUN SHEBEI SHANGHANG' },
	{ code: '1182480', name: 'CHANGSHASHI JIANZI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1273973', name: 'CHANGSHASHI JUNHENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1316170', name: 'CHANGSHASHI WANGCHENGQU RUIXIANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1157138', name: 'CHANGSHUSHI GUOXIN DIANZI CHANPIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1297827', name: 'CHANGSHUSHI HUIBO SHANGMAO YOUXIAN GONGSI' },
	{ code: '690360', name: 'CHANGSHUSHI JINDING TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1278994', name: 'CHANGSHUSHI SHIFENG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1269914', name: 'CHANGSHUSHI TIANFU TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2533365', name: 'CHANGSHUSHI XIANDAI TONGXIN SHIYE YOUXIAN GONGSI' },
	{ code: '1287435', name: 'CHANGTAI YITAI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1741295', name: 'CHANGXING TIANNUO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1280453', name: 'CHANGXING TIANZHI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1290268', name: 'CHANGXING XINTONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1790186', name: 'CHANGYUANXIAN ZHANPENG SHOUJI MENSHIBU' },
	{ code: '1183840', name: 'CHANGZHISHI BEI\'ER DIANXUN YOUXIAN GONGSI' },
	{ code: '840117', name: 'CHANGZHISHI CHAOYUAN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1476582', name: 'CHANGZHISHI DAYUN XIANGHE TONGXUN YOUXIAN GONGSI' },
	{ code: '1808159', name: 'CHANGZHISHI GUANGYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1261910', name: 'CHANGZHISHI JUNWEI SHIJI KEMAO YOUXIAN GONGSI' },
	{ code: '648478', name: 'CHANGZHISHI YUANDA TONGXUN YOUXIAN GONGSI' },
	{ code: '1769785', name: 'CHANGZHISHI ZIHENG XIANGHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '682639', name: 'CHANGZHOU LIXING TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1732384', name: 'CHANGZHOUSHI CHANGYI YIDONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '843150', name: 'CHANGZHOUSHI DONGSHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2059754', name: 'CHANGZHOUSHI TONGFENG TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '843940', name: 'CHAOANXIAN HEZHAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1718771', name: 'CHAOHU ANDELI GOUWU ZHONGXIN YOUXIAN GONGSI' },
	{ code: '1062966', name: 'CHAOHU GUOFENYING TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1808677', name: 'CHAOHUSHI YILIUBA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1709067', name: 'CHAOYANG LIHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1017090', name: 'CHAOYANG XINXIN TONGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1645644', name: 'CHAOYANG YANXING SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1484698', name: 'CHAOYANGSHI TIANSHENG XINXI YOUXIAN GONGSI' },
	{ code: '1969212', name: 'CHAOZHOUSHI CHAO\'ANQU LONGHUZHEN MENGSHENG DIANXUN YINGYEBU' },
	{ code: '835302', name: 'CHAOZHOUSHI JINHANG FUWU YOUXIAN GONGSI' },
	{ code: '1770390', name: 'CHENG\'ANXIAN HUCHENG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1720965', name: 'CHENGDE HAICHUAN SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '1768196', name: 'CHENGDE RUNYE TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '670415', name: 'CHENGDE TIANTONG SHUMA KEMAO YOUXIAN GONGSI' },
	{ code: '1706770', name: 'CHENGDE YUNDUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '900890', name: 'CHENGDESHI BODA TONGXUN QICAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '686237', name: 'CHENGDESHI TENGLONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1968700', name: 'CHENGDESHI XIANGRONG JULI SHANGMAO YOUXIAN GONGSI' },
	{ code: '575348', name: 'CHENGDU FENGYANG SHIYE YOUXIAN GONGSI' },
	{ code: '2456687', name: 'CHENGDU HANCHEN HAOYU KEJI YOUXIAN GONGSI' },
	{ code: '1513282', name: 'CHENGDU HECHUANG SHIDAI TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1506931', name: 'CHENGDU HENGXIN SHENGDA MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1560995', name: 'CHENGDU HUAMIN KEJI YOUXIAN GONGSI' },
	{ code: '1738883', name: 'CHENGDU HURONG YINGXIAO GUANLI YOUXIAN GONGSI' },
	{ code: '1195527', name: 'CHENGDU JIAYU TONGXIN YOUXIAN GONGSI' },
	{ code: '1232083', name: 'CHENGDU JINGYING SHIDAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1650331', name: 'CHENGDU JINXIANQI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1073984', name: 'CHENGDU JINXINTONG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1320381', name: 'CHENGDU JINZHONG KEJI YOUXIAN GONGSI' },
	{ code: '1341023', name: 'CHENGDU LESHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1738879', name: 'CHENGDU LIANHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1262340', name: 'CHENGDU LIQUN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '548013', name: 'CHENGDU LONGXIANG TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1266741', name: 'CHENGDU MINGXUN TIANCHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1572448', name: 'CHENGDU NANJIE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2393960', name: 'CHENGDU SHANGYI KEJI YOUXIAN GONGSI' },
	{ code: '274247', name: 'CHENGDU SHIDAI YINXIANG KEJI YOUXIAN GONGSI' },
	{ code: '229230', name: 'CHENGDU SHIDAITIANYI ELECTRONIC TECHNOLOGY CO., LTD' },
	{ code: '1071236', name: 'CHENGDU TIANYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1063474', name: 'CHENGDU WENLONG DIANXUN QICAI MAOYI YOUXIAN GONGSI' },
	{ code: '1963647', name: 'CHENGDU XINDIANFAN YINXIANG SHEBEI YOUXIAN GONGSI' },
	{ code: '623249', name: 'CHENGDU XINGHUAXUN SHIYE YOUXIAN GONGSI' },
	{ code: '1478335', name: 'CHENGDU XINHUALONG KEJI YOUXIAN GONGSI' },
	{ code: '265272', name: 'CHENGDU XINYA COMMUNICATION LTD' },
	{ code: '1320366', name: 'CHENGDU YISHENGLI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1284510', name: 'CHENGDU ZHIHUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2368940', name: 'CHENGDU ZHIPAI SHANGPIN KEJI YOUXIAN GONGSI' },
	{ code: '1389413', name: 'CHENGDU ZHUOYUE XUNCHI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1856327', name: 'CHENGDU ZIQIN ZHONGHE SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1268535', name: 'CHENGDUSHI DAYI JIAYU TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '670142', name: 'CHENGDUSHI HUAWEI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1209545', name: 'CHENGDUSHI JIAXINYU MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1280384', name: 'CHENGDUSHI PIDUQU YINGKE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '682663', name: 'CHENGDUSHI TONGCHUANG TONGXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1311817', name: 'CHENGDUSHI XINDUQU ANDEHE TONGXIN YOUXIAN GONGSI' },
	{ code: '2379051', name: 'CHENGDUSHI XINLIAN TIANDI KEJI YOUXIAN GONGSI' },
	{ code: '1969898', name: 'CHENGGUXIAN TENGFEI TONGXUN DIANZI FUWUBU' },
	{ code: '1519730', name: 'CHENGKOUXIAN ZHANGSHANG CHAOLIU TONGXIN SHEBEI LIANSUOXIAOSHOUYOUXIANZERENGONGSI' },
	{ code: '1513299', name: 'CHENGWUXIAN YIXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1341153', name: 'CHENGXIN TIANXIA (TIANJIN) SHANGMAO YOUXIAN GONGSI' },
	{ code: '1819235', name: 'CHENXING TAIHE (TIANJIN) KEJI YOUXIAN GONGSI' },
	{ code: '2660585', name: 'CHENZHOU DAOWEI\'ER KEJI YOUXIAN GONGSI' },
	{ code: '1392756', name: 'CHENZHOU HETIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1532982', name: 'CHENZHOU HEXING DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '2810625', name: 'CHENZHOUSHI HEHONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1995943', name: 'CHENZHOUSHI KUASHIJI TONGXUN YOUXIAN GONGSI' },
	{ code: '1398752', name: 'CHENZHOUSHI YIDIANTONG TONGXIN MAOYI YOUXIAN GONGSI' },
	{ code: '2114763', name: 'CHIBISHI YIQIANGLIAN KEJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '686248', name: 'CHIFENG HAIDA DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '1532983', name: 'CHIFENG HONGYANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1474449', name: 'CHIFENG QIANGUA KEJI YOUXIAN GONGSI' },
	{ code: '1731182', name: 'CHIFENG XINLU SHANGMAO YOUXIAN GONGSI' },
	{ code: '2471909', name: 'CHIFENGSHI DIYUE SHANGMAO YOUXIAN GONGSI' },
	{ code: '685946', name: 'CHINA PHONE CHANNEL ASD RSL' },
	{ code: '545704', name: 'CHINA PHONE CHANNEL RSL' },
	{ code: '1772583', name: 'CHINA PHONE CHANNEL RSL - XINJIAXIN' },
	{ code: '523144', name: 'CHINA TELLING TELECOM CO., LTD.' },
	{ code: '1393458', name: 'CHIPINGXIAN JUNLIN SHOUJI MAICHANG YOUXIAN GONGSI' },
	{ code: '1966512', name: 'CHIPINGXIAN JUNLIN TONGXUNDIAN' },
	{ code: '1426963', name: 'CHIZHOUSHI LIANXIN TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1493539', name: 'CHONGQING BAIYU KEJI YOUXIAN GONGSI' },
	{ code: '1856054', name: 'CHONGQING BAOZHEN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '540068', name: 'CHONGQING CHENGTAI TONGXIN LIANSUO YOUXIAN GONGSI' },
	{ code: '648483', name: 'CHONGQING DUOMAI KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1338053', name: 'CHONGQING FENGHUO DIANXIN YOUXIAN GONGSI' },
	{ code: '1802524', name: 'CHONGQING FUHUI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1307905', name: 'CHONGQING GANGPU KEJI YOUXIAN GONGSI' },
	{ code: '1302267', name: 'CHONGQING GUOYU KEJI YOUXIAN GONGSI' },
	{ code: '782841', name: 'CHONGQING HONGYU TONGXUN FAZHAN YOUXIAN GONGSI' },
	{ code: '1399375', name: 'CHONGQING JIEJIA SHANGMAO YOUXIAN GONGI' },
	{ code: '1270069', name: 'CHONGQING JUNYUE KEJI YOUXIAN GONGSI' },
	{ code: '1560994', name: 'CHONGQING LANGRONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1343092', name: 'CHONGQING LIANCHENG KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '850216', name: 'CHONGQING LUOKA KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1718445', name: 'CHONGQING MAONING DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '707375', name: 'CHONGQING QIANFENG KEJI YOUXIAN GONGSI' },
	{ code: '1790232', name: 'CHONGQING QUNSHUN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1294461', name: 'CHONGQING SAIBO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1768577', name: 'CHONGQING SHANGMATE KEJI YOUXIAN GONGSI' },
	{ code: '1316169', name: 'CHONGQING SHANGSHE DIANQI YOUXIAN GONGSI' },
	{ code: '1314316', name: 'CHONGQING SHUNLONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1294460', name: 'CHONGQING WANGZI SHIYE GUFEN YOUXIAN GONGSI' },
	{ code: '2065592', name: 'CHONGQING XINMUXI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1310170', name: 'CHONGQING YAOQILONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1294453', name: 'CHONGQING YICHENG HONGTONG KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1274966', name: 'CHONGQING YONGFENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1302313', name: 'CHONGQING YUDUAN KEJI YOUXIAN GONGSI' },
	{ code: '783178', name: 'CHONGQING YUGUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1393476', name: 'CHONGQING YUNJIANG TONGXIN YOUXIAN GONGSI' },
	{ code: '1301509', name: 'CHONGQING ZHENJUN KEJI YOUXIAN GONGSI' },
	{ code: '1305078', name: 'CHONGQING ZHONGEN TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1753072', name: 'CHONGQINGSHI BISHANQU XINGYUE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1328988', name: 'CHONGQINGSHI GUANGXUAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1055504', name: 'CHONGQINGSHI KAIZHOUQU JINXING TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1382370', name: 'CHONGQINGSHI LINZHOU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2463887', name: 'CHONGQINGSHI QIAOQI TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1417276', name: 'CHONGQINGSHI RONGCHANGQU JINXI TONGXUN QICAI JINGYINGBU' },
	{ code: '1258485', name: 'CHONGQINGSHI SAIMATE KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2265368', name: 'CHONGQINGSHI SHENGDEFANG KEJI YOUXIAN GONGSI' },
	{ code: '791158', name: 'CHONGQINGSHI TAIRAN MAOYI YOUXIAN GONGSI' },
	{ code: '1533541', name: 'CHONGYANGXIAN AIMEI TONGXIN YOUXIAN GONGSI' },
	{ code: '1965742', name: 'CHONGZHOU DACHENGTONG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1244436', name: 'CHONGZUOSHI HAIYANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2011760', name: 'CHUXIONG KUXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1337514', name: 'CHUXIONG YONGSHENG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1288705', name: 'CHUZHOUSHI BAISHENG GONGMAO YOUXIAN GONGSI' },
	{ code: '1389533', name: 'CHUZHOUSHI SHENGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1817605', name: 'CILIXIAN GUOMEI SHOUJICHENG' },
	{ code: '1342893', name: 'CIXIAN YIXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1721985', name: 'CIXISHI DINGXIN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1307841', name: 'CIXISHI LINGHANG TONGXIN SHEBEI SHANGDIAN' },
	{ code: '1960543', name: 'DA\'ANSHI YAOKUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1786958', name: 'DAANSHI FEIXIANG SHOUJI SHUMA YOUXIAN GONGSI' },
	{ code: '1288724', name: 'DAANSHI HAOSHIHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315474', name: 'DACHANG HUIZU ZIZHIXIAN XUERUI DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1381154', name: 'DACHENGXIAN JINBAO DONGLU HUAXIA SHOUJICHENG' },
	{ code: '1965705', name: 'DAHUA XINHUI TONGXUN YOUXIAN GONGSI' },
	{ code: '1302220', name: 'DALI CHAOQIANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1293274', name: 'DALI LIANXIN TONGXIN YOUXIAN GONGSI' },
	{ code: '1440301', name: 'DALI YONGXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1284467', name: 'DALI ZHIYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2533145', name: 'DALIAN AIMAIKE KEJI YOUXIAN GONGSI' },
	{ code: '1296533', name: 'DALIAN CHANGXIN KEJI KAIFA YOUXIAN GONGSI' },
	{ code: '1258437', name: 'DALIAN DEWEI TONGXIN GONGCHENG YOUXIAN GONGSI' },
	{ code: '1393565', name: 'DALIAN DINGXIN OUSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1316051', name: 'DALIAN HEXIN JIAHE TONGXIN YOUXIAN GONGSI' },
	{ code: '2114724', name: 'DALIAN HONGXU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1709060', name: 'DALIAN HUAYI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1332669', name: 'DALIAN HUIFU KEJI YOUXIAN GONGSI' },
	{ code: '1319873', name: 'DALIAN JINGJI JISHU KAIFAQU XINGSHENG SHOUJI MAICHANG' },
	{ code: '2454065', name: 'DALIAN JINGJI JISHU KAIFAQU XINGSHENGYUAN TONGXUN FUWUBU' },
	{ code: '1796763', name: 'DALIAN JINGJI JISHU KAIFAQU YUNXIANG TONGXUN QICAI FUWUBU' },
	{ code: '1070083', name: 'DALIAN JINGPIN SHUMA YOUXIAN GONGSI' },
	{ code: '799724', name: 'DALIAN LITIAN TONGXIN YOUXIAN GONGSI' },
	{ code: '349206', name: 'DALIAN TUOJIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1341029', name: 'DALIAN YING\'AO TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1707249', name: 'DALIAN YINGQI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1542557', name: 'DALIAN YUQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1990742', name: 'DALIAN ZHENGDE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1530583', name: 'DALISHI RUIHAOCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1267219', name: 'DALISHI RUIXIN CHANGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '684882', name: 'DALIZHOU YONGCHENG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '1530586', name: 'DANCHENGXIAN XINDA KEJI YOUXIAN GONGSI' },
	{ code: '1297105', name: 'DANDONG HAOLIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1314230', name: 'DANDONG HONGTUO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2368397', name: 'DANDONG QUANCHENG DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1148716', name: 'DANDONG SHENZHU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '896377', name: 'DANDONG TIANLONG DIANNAO YOUXIAN GONGSI' },
	{ code: '917699', name: 'DANDONG XIANFENG LIANQIANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1284437', name: 'DANDONGSHI WUXING SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1051971', name: 'DANDONGSHI XINGHUO YIDONG TONGXIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1180551', name: 'DANGTUXIAN HENGQING TONGXIN DIANZI SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1790688', name: 'DANGTUXIAN HENGSHENG TONGXUN QICAI JINGYINGBU' },
	{ code: '1279751', name: 'DANGYANGSHI CHENGHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1787919', name: 'DANGYANGSHI HONGTIAN TONGXUN QICAI JINGYINGBU' },
	{ code: '1397507', name: 'DANJIANGKOUSHI WEIDUOYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1549711', name: 'DANLINGXIAN AIHU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '631716', name: 'DANYANGSHI XINDONGYA TONGXUN YOUXIAN GONGSI' },
	{ code: '2071750', name: 'DANYANGSHI ZHONGDA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1290132', name: 'DAOXIAN SUIXING TONGXUN YOUXIAN GONGSI' },
	{ code: '1321965', name: 'DAQING JINLI JIAYUAN JINGMAO YOUXIAN GONGSI' },
	{ code: '563998', name: 'DAQINGSHI TIANTUO TONGXIN KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1476587', name: 'DAQINGSHI TUTENG JINGMAO YOUXIAN GONGSI' },
	{ code: '1469191', name: 'DAQINGSHI ZHUANGZHONG JINGMAO YOUXIAN GONGSI' },
	{ code: '1055381', name: 'DASHIQIAOSHI ZHENYU FUXIANG DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1791282', name: 'DASHIQIAOSHI ZHENYU TONGXUN QICAI SHANGDIAN' },
	{ code: '1305277', name: 'DATIANXIAN YITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1297702', name: 'DATONGSHI CHENGQU TAILIAN WEIDU SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1297231', name: 'DATONGSHI HENGYU XIANGYI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1282529', name: 'DATONGSHI LONGBO FEITENG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1299400', name: 'DATONGSHI PINGCHENGQU XINYIXIANG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1856400', name: 'DATONGSHI RONGDE SHANGMAO YOUXIAN GONGSI' },
	{ code: '2781845', name: 'DATONGSHI YUNZHOUQU HAOHAI DIANZI YOUXIAN GONGSI' },
	{ code: '1224725', name: 'DATONGSHI ZHONGTENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1573883', name: 'DATONGXIAN HAOHAI DIANZICHENG YOUXIAN ZEREN GONGSI' },
	{ code: '1373696', name: 'DAXING\'ANLING MINGREN SHOUJI YOUXIAN GONGSI' },
	{ code: '1406073', name: 'DAYESHI HENGFEI DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '2768445', name: 'DAYINGXIAN PENGLAIZHEN RONGTONG TONGXUN QICAI XIAOSHOU MENSHIBU' },
	{ code: '1290126', name: 'DAYUXIAN HUAXING KEJI YOUXIAN GONGSI' },
	{ code: '1815746', name: 'DAYUXIAN LANTAI SHIDAI SHOUJI CHAOSHI' },
	{ code: '1291913', name: 'DAZHOU BOTING SHIYE YOUXIAN GONGSI' },
	{ code: '1235046', name: 'DAZHOUSHI JINRUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '764785', name: 'DAZHOUSHI SHENGCAI SHANGWU YOUXIAN ZEREN GONGSI' },
	{ code: '963159', name: 'DAZHOUSHI TIANBO TONGXUN QICAI YOUXIAN GONGSI NANCHONG FENGONGSI' },
	{ code: '839748', name: 'DAZHOUSHI TIANYIN SHIYE YOUXIAN ZEREN GONGSI' },
	{ code: '1302971', name: 'DAZHOUSHI XINGGUANG YUHE TONGXUN YOUXIAN GONGSI' },
	{ code: '2071659', name: 'DE\'ANXIAN ZHONGHE SHIDAI SHOUJI LIANSUO WANXIANGDIAN' },
	{ code: '1368057', name: 'DEBAOXIAN JINBIN TONGXUN QICAI SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1306452', name: 'DEFUTONG (TIANJIN) SHANGMAO YOUXIAN GONGSI' },
	{ code: '1974165', name: 'DEHUA XUNYU TONGXIN YOUXIAN GONGSI' },
	{ code: '2549245', name: 'DEHUISHI JIANSHEJIE KUAIDA TIANTIAN SHOUJI MAICHANG' },
	{ code: '2397291', name: 'DEHUISHI JIANSHEJIE TIANQI SHOUJI SHANGCHENG' },
	{ code: '1337509', name: 'DEHUISHI YINDA TONGXUN YOUXIAN GONGSI' },
	{ code: '1166380', name: 'DELINGHASHI YULONG TONGXUN' },
	{ code: '2037784', name: 'DENGFENG YITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1549712', name: 'DENGKOUXIAN BAZHEN WANTONG DIANXUN' },
	{ code: '1811004', name: 'DENGKOUXIAN CHENGZHANHU SHOUJI MAICHANG' },
	{ code: '1740221', name: 'DENGZHOU QICHENG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1811072', name: 'DENGZHOUSHI GUOYOU SHOUJI LIANSUODIAN' },
	{ code: '1720663', name: 'DENGZHOUSHI XINHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1023978', name: 'DENGZHOUSHI XINYA TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1300890', name: 'DEQINGXIAN GUANGDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1816594', name: 'DEXINGSHI HUAJIAN TONGXUN' },
	{ code: '1278448', name: 'DEXINGSHI YINTAI KEJI YOUXIAN GONGSI' },
	{ code: '1076127', name: 'DEYANG AIKU TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1142639', name: 'DEYANG HELIXING SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '613173', name: 'DEYANG QIANCHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '835519', name: 'DEYANG ZHENGHE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1057996', name: 'DEYANGSHI ADAIER DIANZI YOUXIAN GONGSI' },
	{ code: '1478330', name: 'DEYANGSHI DAXINTONG SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1215033', name: 'DEYANGSHI WEITAI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1690180', name: 'DEZHOU HENGDU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1420145', name: 'DEZHOUSHI DECHENGQU LIANFA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1688879', name: 'DINGNAN HONGYUAN SHUMA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1300763', name: 'DINGTAO GUANGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '2718005', name: 'DINGXI PENG\'AN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1720631', name: 'DINGXINGXIAN JIATAI DIANZI CHANPIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1440044', name: 'DINGXINGXIAN SHOUZHENG DIANZI SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1791295', name: 'DINGXINGXIAN YUANKE DIANNAO XIAOSHOUBU' },
	{ code: '1294019', name: 'DINGXINGXIAN ZHONGKE DIANZI SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1076179', name: 'DINGXISHI LITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1581266', name: 'DINGYUANXIAN HUAZHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1786814', name: 'DINGYUANXIAN RUIMING YIDONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1369007', name: 'DIQINGZHOU YANGGUANG TONGXUN FUWU GUANLI YOUXIAN GONGSI' },
	{ code: '2000926', name: 'DISNEY STORE (SHANGHAI) LIMITED' },
	{ code: '2463765', name: 'DONG\'AN YINXING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2077661', name: 'DONGFENGXIAN DONGFENGZHEN JINXIANG YOUXUAN KEJI TIYANDIAN' },
	{ code: '1170457', name: 'DONGGANGSHI JINFENG SHUMA YOUXIAN GONGSI' },
	{ code: '1659742', name: 'DONGGUAN JIETONGDA DIANXUN YOUXIAN GONGSI' },
	{ code: '1330940', name: 'DONGGUANSHI CHANGHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1049841', name: 'DONGGUANSHI CHUANGKU TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1337465', name: 'DONGGUANSHI DONGCHENG DONGDI TONGXUN QICAIDIAN' },
	{ code: '1266658', name: 'DONGGUANSHI FENGJIA TONGXUN SHIYE YOUXIAN GONGSI' },
	{ code: '1592541', name: 'DONGGUANSHI FENGLIANHE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1266705', name: 'DONGGUANSHI GUANGXING TONGXUN SHUMA YOUXIAN GONGSI' },
	{ code: '1997705', name: 'DONGGUANSHI GUANGYUE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1314211', name: 'DONGGUANSHI HESHI DIANQI YOUXIAN GONGSI' },
	{ code: '1302738', name: 'DONGGUANSHI HUXIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1280262', name: 'DONGGUANSHI LINGGE TONGXUN MAOYI YOUXIAN GONGSI' },
	{ code: '1283189', name: 'DONGGUANSHI LONGYUE TONGXUN LIANSUO YOUXIAN GONGSI' },
	{ code: '1302235', name: 'DONGGUANSHI MINGGOU TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1073921', name: 'DONGGUANSHI SANZHI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '560705', name: 'DONGGUANSHI SHISHANG DIANQI YOUXIAN GONGSI' },
	{ code: '1400875', name: 'DONGGUANSHI TAICHENG DIANXUN YOUXIAN GONGSI' },
	{ code: '1779933', name: 'DONGGUANSHI TIANYING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2175317', name: 'DONGGUANSHI TIANZHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1296501', name: 'DONGGUANSHI XIANFENG DIANNAO KEJI YOUXIAN GONGSI' },
	{ code: '1283183', name: 'DONGGUANSHI YANXIN DIANZI YOUXIAN GONGSI' },
	{ code: '1416009', name: 'DONGGUANSHI YINGXUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1622615', name: 'DONGGUANSHI YINGXUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1439552', name: 'DONGGUANSHI YURAN MAOYI YOUXIAN GONGSI' },
	{ code: '1310187', name: 'DONGGUANSHI ZHUOYOU TONGXUN YOUXIAN GONGSI' },
	{ code: '1813146', name: 'DONGMING GUANZHIXUN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1318597', name: 'DONGMINGXIAN TIANDITONG SHOUJI YOUXIAN GONGSI' },
	{ code: '1789112', name: 'DONGPINGXIAN HUIZHI YOUCHENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2469905', name: 'DONGTAISHI BAIJUN DIANZI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1314202', name: 'DONGXINGSHI HUANGYANG TONGXUN MAOYI YOUXIAN GONGSI' },
	{ code: '1708789', name: 'DONGYANGSHI DINGHAO MEICHENG SHUMA YOUXIAN GONGSI' },
	{ code: '1006705', name: 'DONGYANGSHI WUNING DIXINTONG TONGXIN SHANGHANG' },
	{ code: '1737216', name: 'DONGYANGSHI XINAIQI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '723010', name: 'DONGYING XINYULOU BAIHUO YOUXIAN GONGSI' },
	{ code: '1427017', name: 'DONGYING YONGDA ZHUOLIAN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1672999', name: 'DONGYINGSHI GUANGYU TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1479233', name: 'DONGYINGSHI RUNYING DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1098189', name: 'DONGZHI BAIYUE TONGXUN YOUXIAN GONGSI' },
	{ code: '1296575', name: 'DUCHANGXIAN DONGFANG KEJI YOUXIAN GONGSI' },
	{ code: '1790747', name: 'DUCHANGXIAN FANGPING SHOUJI TONGXUN SHUMACHENG' },
	{ code: '1393946', name: 'DUERBOTE MENGGUZU ZIZHIXIAN BOYANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1293202', name: 'DUNHUASHI HONGXUN TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1121416', name: 'DUNHUASHI TIANHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1731908', name: 'E\'ERDUOSISHI HONGXINHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1527734', name: 'E\'ERDUOSISHI RUIZHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1731900', name: 'E\'ERDUOSISHI ZHENRUI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1315676', name: 'E\'ERGUNASHI HONGYANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1235139', name: 'EERDUOSISHI DONGSHENGQU TAIHE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1156145', name: 'EMEISHANSHI HUAXING TONGXUN YOUXIAN GONGSI' },
	{ code: '1811095', name: 'ENPINGSHI FEIECHENG DIANXUNBU' },
	{ code: '1310122', name: 'ENSHIZHOU TONGXIN GUANGCHANG YOUXIAN ZEREN GONGSI' },
	{ code: '1689269', name: 'ENSHIZHOU XUNLI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1278510', name: 'ENSHIZHOU XUNLITONG KEMAO YOUXIAN GONGSI' },
	{ code: '1291800', name: 'ERLIANSHI DAYANG JIAMEI MAOYI YOUXIAN GONGSI' },
	{ code: '889735', name: 'EZHOUSHI FENGYUN ZHIHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1026981', name: 'EZHOUSHI TIANLONGKE GONGMAO YOUXIAN GONGSI' },
	{ code: '1318670', name: 'FAKU YIHESHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1050667', name: 'FANGCHENG HONGXING TONGXUN SHEBEI QICAI JINGYINGBU' },
	{ code: '1691936', name: 'FANGCHENGGANGSHI FAJIA XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1249188', name: 'FANGCHENGXIAN SHIJI HENGSHENG DIANXUN YOUXIAN GONGSI' },
	{ code: '1359662', name: 'FANGZHENGXIAN HUIHUANG JIAYONG DIANQI YOUXIAN GONGSI' },
	{ code: '1969980', name: 'FANSHIXIAN GUANGSHI TONGXUN YOUXIAN GONGSI' },
	{ code: '2503245', name: 'FANSHIXIAN WANWEI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1759755', name: 'FANXIAN SHUOKE KEJI YOUXIAN GONGSI' },
	{ code: '1963594', name: 'FEICHENG XINYE SHANGSHA YOUXIAN GONGSI' },
	{ code: '1785947', name: 'FEIDONGXIAN SHENGLIAN TONGXUN QICAI JINGYINGBU' },
	{ code: '1420943', name: 'FEIXIANGXIAN YONGSHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1969619', name: 'FENGCHENGSHI HONGQI SHANGCHANG' },
	{ code: '1183015', name: 'FENGCHENGSHI MEIJI SHUMA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1782388', name: 'FENGDUXIAN HONGBO DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '886906', name: 'FENGFENG KUANGQU TAIHANG TONGXUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1284552', name: 'FENGJIEXIAN BAIXIN TONGXUN QICAI JINGYINGBU' },
	{ code: '849796', name: 'FENGJIEXIAN XIONGDI DIANQI YOUXIAN GONGSI' },
	{ code: '2003204', name: 'FENGNING MANZU ZIZHIXIAN JIEYIN SHOUJI DAMAICHANG' },
	{ code: '1279181', name: 'FENGQIUXIAN XINTONGDA TONGXUN YOUXIAN GONGSI' },
	{ code: '1968641', name: 'FENGQIUXIAN ZIQIANG SHOUJICHENG' },
	{ code: '1394031', name: 'FENGSHUNXIAN FENGYU SHUMA SHOUJIDIAN' },
	{ code: '1205123', name: 'FENGXIAN FANGYUAN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1739554', name: 'FENGXIAN YUNXIANG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1514551', name: 'FENGXIANG CHENGYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1215235', name: 'FENGXINXIAN ANQIN MAOYI YOUXIAN GONGSI' },
	{ code: '1980336', name: 'FENYANGSHI XINGUANGTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1314242', name: 'FOSHAN ZHONGSHI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1297695', name: 'FOSHANSHI CHANCHENGQU HENGMENG DIANXUN JINGYINGBU' },
	{ code: '1330941', name: 'FOSHANSHI CHANCHENGQU TIANHEXUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1252271', name: 'FOSHANSHI CHENGXI MAOYI YOUXIAN GONGSI' },
	{ code: '1330151', name: 'FOSHANSHI HAIGUO DIANXUN YOUXIAN GONGSI' },
	{ code: '1284454', name: 'FOSHANSHI HENGXUN LEGOU TONGXIN YOUXIAN GONGSI' },
	{ code: '1284461', name: 'FOSHANSHI HONGFEI KEJI YOUXIAN GONGSI' },
	{ code: '1305084', name: 'FOSHANSHI LONGCHANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1564178', name: 'FOSHANSHI LONGYU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '558788', name: 'FOSHANSHI NANHAI HEJI DIANQI DIANXUN YOUXIAN GONGSI' },
	{ code: '1358678', name: 'FOSHANSHI NANHAI PENGKE DIANXUN YOUXIAN GONGSI' },
	{ code: '1302223', name: 'FOSHANSHI NANHAI TIANZI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2377357', name: 'FOSHANSHI NANHAIQU CHANGTIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '682631', name: 'FOSHANSHI NANHAIQU GUANGJUN DIANXUN YOUXIAN GONGSI' },
	{ code: '1297106', name: 'FOSHANSHI NANHAIQU GUANGZHINENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1283210', name: 'FOSHANSHI NANHAIQU LINGCAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1632048', name: 'FOSHANSHI NANHAIQU XIQIAO GUANGYAN DIANXUNDIAN' },
	{ code: '1284430', name: 'FOSHANSHI SHUNDEQU LONGJIANGZHEN SHENGYIN TONGXUN QICAI JINGYINGBU' },
	{ code: '1355341', name: 'FOSHANSHI SHUNDEQU LONGJIANGZHEN ZHENCHAO DIANXUN JINGYINGBU' },
	{ code: '1280314', name: 'FOSHANSHI SHUNDEQU RIRIXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1291811', name: 'FOSHANSHI SHUNDEQU RUILONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '572052', name: 'FOSHANSHI SHUNDEQU SANHE TONGXUN FAZHAN YOUXIAN GONGSI' },
	{ code: '1355354', name: 'FOSHANSHI SHUNDEQU XIEXUN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1297903', name: 'FOSHANSHI SHUNDEQU ZHONGTAI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1280284', name: 'FOSHANSHI YIFANG YIDONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1739198', name: 'FU\'ANSHI HONGTONG DIANZI MAOYI YOUXIAN GONGSI' },
	{ code: '1817613', name: 'FUDINGSHI LANGXIN TONGXUN JINGYINGBU' },
	{ code: '1699404', name: 'FUDINGSHI YIYUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1648880', name: 'FUGOUXIAN JIANHUA TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1513285', name: 'FUGUXIAN QINYE KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1312898', name: 'FUJIAN BEICHEN DIANZI YOUXIAN GONGSI' },
	{ code: '1294434', name: 'FUJIAN CHAOTING MAOYI YOUXIAN GONGSI' },
	{ code: '1311836', name: 'FUJIAN DAYUEMENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1070579', name: 'FUJIAN DAYUEMENG DIANZI KEJI YOUXIAN GONGSI FUZHOU FENGONGSI' },
	{ code: '1235120', name: 'FUJIAN FUNONGYUAN SHIYE YOUXIAN GONGSI' },
	{ code: '1312797', name: 'FUJIAN GUOLE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1307127', name: 'FUJIAN JIANGLE JIABAILIE MAOYI YOUXIAN GONGSI' },
	{ code: '1323666', name: 'FUJIAN JIEJIAN SHIYE YOUXIAN GONGSI' },
	{ code: '1273857', name: 'FUJIAN JINCHENGXIN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '554422', name: 'FUJIAN QUANZHOUSHI HUAYUAN DIANXUN YOUXIAN GONGSI' },
	{ code: '1787495', name: 'FUJIAN SHIJI YUNDUAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1195514', name: 'FUJIAN TIANZE SHENGDE SHIYE YOUXIAN GONGSI' },
	{ code: '1321193', name: 'FUJIAN XINJIA TOUZI YOUXIAN GONGSI' },
	{ code: '1372728', name: 'FUJIAN XINTAOYUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1341146', name: 'FUJIAN ZHENGHE YUNSHANG JITUAN YOUXIAN GONGSI' },
	{ code: '1997963', name: 'FUJIAN ZHIQIANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1469210', name: 'FUJIAN ZHONGGUAN DADI SHUMA YOUXIAN GONGSI' },
	{ code: '1253395', name: 'FUJIAN ZHONGKAI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1975361', name: 'FUJIANSHENG BINHAO TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1291924', name: 'FUJIANSHENG DONGSHANXIAN LINFENG MAOYI YOUXIAN GONGSI' },
	{ code: '1785463', name: 'FUJIANSHENG HONGGUAN TONGXIN FUWU YOUXIAN GONGSI' },
	{ code: '1659749', name: 'FUJIANSHENG HONGGUAN TONGXIN GONGCHENG YOUXIAN GONGSI' },
	{ code: '1770481', name: 'FUJIANSHENG HUANQIU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1080889', name: 'FUJIANSHENG KAIXINGXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '686229', name: 'FUJIANSHENG LONGYAN YANGCAI TONGXUN YOUXIAN GONGSI' },
	{ code: '2283477', name: 'FUJIANSHENG MINSHEN MAOYI YOUXIAN GONGSI' },
	{ code: '1484257', name: 'FUJIANSHENG NAN\'ANSHI YUANDONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1354175', name: 'FUJIANSHENG SHIQIANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1399373', name: 'FUJIANSHENG XIANYOUXIAN GUOHUA MAOYI YOUXIAN GONGSI' },
	{ code: '1722083', name: 'FUJIANSHENG XIANYOUXIAN HONGDA MAOYI YOUXIAN GONGSI' },
	{ code: '1301016', name: 'FUJIANSHENG XUNLIAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1773936', name: 'FUJIANSHENG ZHENGFEI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1990749', name: 'FUNANXIAN HUARONG KEJI YOUXIAN GONGSI' },
	{ code: '1706707', name: 'FUNINGXIAN WENHUALU SHUMA SHIDAI TONGXUNDIAN' },
	{ code: '1779844', name: 'FUPINGXIAN SHIFANG TONGXUNBU' },
	{ code: '1283783', name: 'FUPINGXIAN SHITONG TONGXUN YOUXIAN GONGSI' },
	{ code: '693441', name: 'FUQINGSHI TIANMENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1965681', name: 'FUSHUNSHI TIANQIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1312883', name: 'FUSHUNSHI YIDA FANGHUA JINGMAO YOUXIAN GONGSI' },
	{ code: '1240038', name: 'FUSHUNXIAN TIANYI TONGXUN YOUXIAN GONGSI' },
	{ code: '1492944', name: 'FUSONGXIAN BEIDOU JINGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1966039', name: 'FUSONGXIAN FUSONGZHEN BEIDOU SHOUJI QIJIANDIAN' },
	{ code: '1689264', name: 'FUSUIXIAN YINGMING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1535844', name: 'FUTAIHUA GONGYE (SHENZHEN) YOUXIAN GONGSI' },
	{ code: '1469215', name: 'FUXIN ZHONGCHOU ZHONGXING SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2671265', name: 'FUXINSHI BOLIN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '696078', name: 'FUXINSHI BOQI DIANXIN SHANGWU YOUXIAN ZEREN GONGSI' },
	{ code: '1784572', name: 'FUYANG YINGZHIXUN TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1737140', name: 'FUYANG ZHISHENG MAOYI YOUXIAN GONGSI' },
	{ code: '672402', name: 'FUYANGSHI RONGCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1731916', name: 'FUYANGSHI XINHENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '794972', name: 'FUYANGSHI YINRUI SHANGWU GUANLI ZIXUN YOUXIAN GONGSI' },
	{ code: '1187296', name: 'FUYANGSHI ZHIYAN DIANZI YOUXIAN GONGSI' },
	{ code: '1482306', name: 'FUYUANXIAN ZHIHE TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1506956', name: 'FUYUSHI HUANQIU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2010238', name: 'FUYUSHI SANCHAHEZHEN HUANQIU SHOUJICHENG QIJIANDIAN' },
	{ code: '1292059', name: 'FUYUXIAN BOZHI DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1768063', name: 'FUZHOU BAIRUNDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1393517', name: 'FUZHOU CHENGDA SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '693438', name: 'FUZHOU GUANDI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1479245', name: 'FUZHOU HENGYU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1194737', name: 'FUZHOU NANTAI MAOYI YOUXIAN GONGSI' },
	{ code: '1484253', name: 'FUZHOU NIANYUERI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1287311', name: 'FUZHOU PINBO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1235114', name: 'FUZHOU RUILIAN TONGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1292053', name: 'FUZHOU SHUANGSHUN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1342245', name: 'FUZHOU WENSHAN FEILONG MAOYI YOUXIAN GONGSI' },
	{ code: '1394025', name: 'FUZHOU XINGHE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1286696', name: 'FUZHOU XINGYAO TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1023081', name: 'FUZHOU XINHAOHAN KEJI YOUXIAN GONGSI' },
	{ code: '790305', name: 'FUZHOU XINPUHUA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1469207', name: 'FUZHOU ZEHUA MAOYI YOUXIAN GONGSI' },
	{ code: '622960', name: 'FUZHOU ZHENGGUAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1016731', name: 'FUZHOU ZHENGJIE DIANZI YOUXIAN GONGSI' },
	{ code: '1486744', name: 'FUZHOU ZHIHUI TONGXUN YOUXIAN GONGSI' },
	{ code: '1319845', name: 'FUZHOU ZHONGGUAN LIANHE DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1307258', name: 'FUZHOU ZHUOHAO MAOYI YOUXIAN GONGSI' },
	{ code: '1542565', name: 'FUZHOUSHI HANDE DIANNAO YOUXIAN ZEREN GONGSI' },
	{ code: '1023092', name: 'FUZHOUSHI HUAMEI XINGYE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1982853', name: 'FUZHOUSHI JIATONG KEJI YOUXIAN GONGSI' },
	{ code: '1284517', name: 'FUZHOUSHI KUYUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1813156', name: 'FUZHOUSHI LINCHUANQU ZHONGGUAN SHOUJI GUANGCHANG' },
	{ code: '1812844', name: 'FUZHOUSHI YUESHENGCHANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1542787', name: 'FUZHOUSHI ZHONGWOQIAO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1600905', name: 'GANGU JIAXIN TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1977876', name: 'GANGUXIAN JIAFAN TONGXUN QICAIDIAN' },
	{ code: '1639406', name: 'GANNAN HAILUO TONGXUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2469565', name: 'GANNANXIAN HANGMINGYUAN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1291786', name: 'GANNANXIAN PUTIAN KEJI TONGXUN QICAI SHANGDIAN' },
	{ code: '1720952', name: 'GANSU DINGSHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2857266', name: 'GANSU GAOKE MIJIA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1332835', name: 'GANSU GAOKE WANDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1811046', name: 'GANSU GEZHENG DIANZI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2063256', name: 'GANSU HENGYAO DIANZI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1790681', name: 'GANSU YOUBIAO DIANZI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1799697', name: 'GANSU ZHONGDA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1319784', name: 'GANZHOU CHUANGYI MAOYI YOUXIAN GONGSI' },
	{ code: '1258424', name: 'GANZHOU JIANFENG SHIDAI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2710285', name: 'GANZHOU JIEYAO DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1584211', name: 'GANZHOU JIHUIYUAN KEJI YOUXIAN GONGSI' },
	{ code: '1341695', name: 'GANZHOU RUIFENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1775403', name: 'GANZHOU SIPING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1342257', name: 'GANZHOU XIANGSHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1235169', name: 'GAOANSHI KUNYU KEJI YOUXIAN GONGSI' },
	{ code: '1985456', name: 'GAOMISHI LIQUNLU LIANZHONG SHOUJIDIAN' },
	{ code: '925032', name: 'GAOMISHI MINGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '707387', name: 'GAOMISHI ZHONGXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1236929', name: 'GAOPINGSHI JINLEILEI TONGXIN QICAI XIAOSHOUBU' },
	{ code: '1467863', name: 'GAOTAIXIAN TIANDI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1246175', name: 'GAOTANGXIAN KEDA DIANZI YOUXIAN GONGSI' },
	{ code: '1546478', name: 'GAOXIAN TIANCHI TONGXUN MAOYI YOUXIAN GONGSI' },
	{ code: '1721075', name: 'GAOYANGXIAN JINSHENGSHI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1301588', name: 'GAOYANGXIAN LANHE TONGXUN QICAI JINGXIAOBU' },
	{ code: '1791479', name: 'GAOYANGXIAN LANTIAN TONGXUN QICAI JINGXIAOBU' },
	{ code: '1721420', name: 'GAOYOU JIAXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1760091', name: 'GAOYOUSHI DINGLI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1786819', name: 'GAOYOUSHI JIAYE TONGXUN QICAI JINGYINGBU' },
	{ code: '2397773', name: 'GAOYOUSHI SHIHAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1296593', name: 'GAOYOUSHI YOUTONG DIANZI YOUXIAN GONGSI' },
	{ code: '1160100', name: 'GAOZHOUSHI HENGFENG SHUMA TONGXIN YOUXIAN GONGSI' },
	{ code: '1395185', name: 'GAOZHOUSHI LIANHE ZIXUN YOUXIAN GONGSI' },
	{ code: '1404624', name: 'GAOZHOUSHI TIANBAO TONGXUN QICAI JINGYINGBU' },
	{ code: '1393530', name: 'GAOZHOUSHI XINGLONG KEJI YOUXIAN GONGSI' },
	{ code: '1707258', name: 'GAOZHOUSHI ZHINENG YONGNAN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2375797', name: 'GDV CES (FZ) - BEIJING JINGDONG SHIJI MAOYI YOUXIAN GONGSI' },
	{ code: '2375817', name: 'GDV CES (TL) - BEIJING JINGDONG SHIJI MAOYI YOUXIAN GONGSI' },
	{ code: '2081437', name: 'GDV CES-CHONGQING MAONING DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1718427', name: 'GDV MONO-BEIJING HENGSHA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1718385', name: 'GDV MONO-XIZANG KU\'AI TONGXIN YOUXIAN GONGSI' },
	{ code: '1718428', name: 'GDV OTC-SHENZHENSHI AISHIDE GUFEN YOUXIAN GONGSI' },
	{ code: '1786924', name: 'GEERMU GEFAN TONGXUNBU' },
	{ code: '1268546', name: 'GEERMU SHUOKE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1287332', name: 'GENHESHI LANYU TONGXUN QICAI JINGMAO YOUXIAN GONGSI' },
	{ code: '262476', name: 'GOME ELECTRICAL APPLIANCE COMPANY LIMITED' },
	{ code: '1267236', name: 'GONG\'ANXIAN CHANLING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1787827', name: 'GONG\'ANXIAN HESHENG TONGXUN JINGYINGBU' },
	{ code: '1341016', name: 'GONGQINGCHENG HENENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1785852', name: 'GONGYISHI CHENGQU SIFANG TONGXUN QICAIDIAN' },
	{ code: '1041701', name: 'GONGYISHI DONGFANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2003148', name: 'GONGZHULINGSHI HEIMA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1261851', name: 'GONGZHULINGSHI SHENGLI JIAODIAN YOUXIAN ZEREN GONGSI' },
	{ code: '1513302', name: 'GONGZHULINGSHI WEIHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1144257', name: 'GUANG\'ANSHI JINGMEI TONGXUN YOUXIAN GONGSI' },
	{ code: '1521826', name: 'GUANG\'ANSHI LISHENG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1021309', name: 'GUANG\'ANSHI ZHONGHUI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2200717', name: 'GUANGDE GUOXUAN MAOYI YOUXIAN GONGSI' },
	{ code: '2080133', name: 'GUANGDE XINPIN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1314354', name: 'GUANGDEXIAN HUIFENGYUAN TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1785864', name: 'GUANGDEXIAN SHIDAICHENG SHOUJIDIAN' },
	{ code: '540065', name: 'GUANGDONG DADI TONGXUN LIANSUO FUWU YOUXIAN GONGSI' },
	{ code: '1305190', name: 'GUANGDONG GUANGXIN TONGXIN FUWU YOUXIAN GONGSI YUEZHONG FENGONGSI' },
	{ code: '1302713', name: 'GUANGDONG GUANGXIONG DIANXUN YOUXIAN GONGSI' },
	{ code: '1805240', name: 'GUANGDONG HAOPAI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '839933', name: 'GUANGDONG JINNUO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '540085', name: 'GUANGDONG JUNHE TONGXIN SHEBEI LIANSUO XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1293280', name: 'GUANGDONG KUAITONG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1771039', name: 'GUANGDONG LIDE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1050301', name: 'GUANGDONG LONGYUE TONGXIN SHEBEI JITUAN YOUXIAN GONGSI' },
	{ code: '1803059', name: 'GUANGDONG MAIKEFENG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1745479', name: 'GUANGDONG SANDU XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1279037', name: 'GUANGDONG SHUMATONG KEJI YOUXIAN GONGSI' },
	{ code: '1303561', name: 'GUANGDONG TIANXUNDA ZIXUN KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '531040', name: 'GUANGDONG XINJIAXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1698591', name: 'GUANGDONG XINJIAXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '266187', name: 'GUANGDONG XINLIAN YIDONG TONGXIN YOUXIAN GONGSI' },
	{ code: '1180006', name: 'GUANGDONG XINXINGYUAN ZHINENG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1222425', name: 'GUANGDONG YANGFAN KEJI YOUXIAN GONGSI' },
	{ code: '1301515', name: 'GUANGDONG YAOXUN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1194681', name: 'GUANGDONG YIHAOTONG MAOYI YOUXIAN GONGSI' },
	{ code: '1996008', name: 'GUANGDONG YILIAN DIANXUN FUWU YOUXIAN GONGSI' },
	{ code: '2469505', name: 'GUANGDONG YUEBAIHUI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1455910', name: 'GUANGNANXIAN XINTAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1768563', name: 'GUANGSHANXIAN XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1785509', name: 'GUANGSHANXIAN ZHONGYI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1219482', name: 'GUANGXI AOBEN MAOYI YOUXIAN GONGSI' },
	{ code: '2501248', name: 'GUANGXI BAMA MINI SHANGMAO KAIFA YOUXIAN ZEREN GONGSI' },
	{ code: '1624446', name: 'GUANGXI BANGYANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1533207', name: 'GUANGXI CHAOLI MAOYI YOUXIAN GONGSI' },
	{ code: '1755411', name: 'GUANGXI CHENGXIN YIBAI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1244442', name: 'GUANGXI DAQIU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1685346', name: 'GUANGXI DINGBIN TOUZI YOUXIAN GONGSI' },
	{ code: '1286038', name: 'GUANGXI GUILIN XINKU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1745061', name: 'GUANGXI HECHISHI YIZHOU YOUBAI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1413973', name: 'GUANGXI HECHUANG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2363957', name: 'GUANGXI HENGDA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1259059', name: 'GUANGXI JINGUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1240111', name: 'GUANGXI JINMEI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1261817', name: 'GUANGXI KUSHANG YINGBO MAOYI YOUXIAN GONGSI' },
	{ code: '1341037', name: 'GUANGXI LECHAO WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1321699', name: 'GUANGXI LUZHAI SHENGSHI TONGXUN SHEBEI JINGYINGBU' },
	{ code: '1312059', name: 'GUANGXI MEIDA SHIYE YOUXIAN GONGSI' },
	{ code: '1296619', name: 'GUANGXI NANNING RUIGE TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1310330', name: 'GUANGXI NANNINGSHI DINGBIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1305219', name: 'GUANGXI NANNINGSHI YANGSHU MAOYI YOUXIAN GONGSI' },
	{ code: '1303001', name: 'GUANGXI RONGXIAN XUNLIAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1124695', name: 'GUANGXI SANCAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1720880', name: 'GUANGXI SENSAIRUI KEJI YOUXIAN GONGSI' },
	{ code: '632338', name: 'GUANGXI SHENGSHITONG DIANXUN QICAI YOUXIAN GONGSI' },
	{ code: '1235163', name: 'GUANGXI TIANSHUIMU XIAYAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1775393', name: 'GUANGXI WEICHENTONG TOUZI YOUXIAN GONGSI' },
	{ code: '1054476', name: 'GUANGXI WUZHOUSHI SHENGYU MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1311976', name: 'GUANGXI XIANGSHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1298031', name: 'GUANGXI XING\'ANXIAN ZHANZHUO FENGXING DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2369539', name: 'GUANGXI XINGAINIAN KEJI YOUXIAN GONGSI' },
	{ code: '1056450', name: 'GUANGXI XINGAINIAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '551976', name: 'GUANGXI XINHUI KEJI JITUAN YOUXIAN ZEREN GONGSI' },
	{ code: '1306444', name: 'GUANGXI YASU SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1217905', name: 'GUANGXI YUEDONG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1787842', name: 'GUANGXI YULIN GUIGUAN TONGXUN SHEBEI YOUXIAN GONGSI BOBAI FENGONGSI' },
	{ code: '1695977', name: 'GUANGXI ZHIZUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1283261', name: 'GUANGXI ZHIZUN TOUZI GUANLI YOUXIAN GONGSI' },
	{ code: '963595', name: 'GUANGYUAN ZHICHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1382384', name: 'GUANGYUANSHI LINGYUN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1273914', name: 'GUANGYUANSHI SANXIN XINXI JISHU YOUXIAN GONGSI' },
	{ code: '559201', name: 'GUANGZHOU CHANGHANG JISUANJI KEJI YOUXIAN GONGSI' },
	{ code: '917111', name: 'GUANGZHOU DATIANHANG TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1224536', name: 'GUANGZHOU DIXIN TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1397478', name: 'GUANGZHOU JINBODI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1399353', name: 'GUANGZHOU JINYU TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1799739', name: 'GUANGZHOU LONGRUN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '941056', name: 'GUANGZHOU MEILIN TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '2056003', name: 'GUANGZHOU MINRUI TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1307153', name: 'GUANGZHOU SANCHE YIDONG SHUMA TONGXIN SHEBEI JISHU YOUXIAN GONGSI' },
	{ code: '723021', name: 'GUANGZHOU XINYI HAOTONG DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1779860', name: 'GUANGZHOU XUANJIA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1072412', name: 'GUANGZHOU YIFANG DIANXIN YOUXIAN GONGSI' },
	{ code: '1296684', name: 'GUANGZHOU YUSHI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1332878', name: 'GUANGZHOU ZHENGCHI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '236873', name: 'GUANGZHOU ZHONGSHUN COMMERCIAL COMPANY' },
	{ code: '1303645', name: 'GUANGZHOUSHI BOTENG JIADIAN YOUXIAN GONGSI' },
	{ code: '1318570', name: 'GUANGZHOUSHI FENGYUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1305177', name: 'GUANGZHOUSHI JINGXIONG TONGXIN SHEBEI KEJI YOUXIAN GONGSI' },
	{ code: '543032', name: 'GUANGZHOUSHI JINJIAXIN TONGXIN CHANPIN FAZHAN YOUXIAN GONGSI' },
	{ code: '1647488', name: 'GUANGZHOUSHI KEJILAI KEJI YOUXIAN GONGSI' },
	{ code: '1310118', name: 'GUANGZHOUSHI LANXUNHANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1367446', name: 'GUANGZHOUSHI LEJING XINXI KEJI YOUXIAN GONGSI' },
	{ code: '656839', name: 'GUANGZHOUSHI LINGYI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1296687', name: 'GUANGZHOUSHI LUOGANGQU XINGXUN SHUMA JINGYINGBU' },
	{ code: '1642948', name: 'GUANGZHOUSHI MIHAI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1332675', name: 'GUANGZHOUSHI PANYUQU YINGKE TONGXIN YOUXIAN GONGSI' },
	{ code: '1323659', name: 'GUANGZHOUSHI SHANGZHIZHENG DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1029873', name: 'GUANGZHOUSHI SHIYING MAOYI YOUXIAN GONGSI' },
	{ code: '1249218', name: 'GUANGZHOUSHI XINSANYI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1249230', name: 'GUANGZHOUSHI YIMIN WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1312696', name: 'GUANGZHOUSHI YINGHANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1297685', name: 'GUANGZHOUSHI YINZHIXUN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1780294', name: 'GUANGZONGXIAN GUANRONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2380613', name: 'GUANTAOXIAN AILE TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1966865', name: 'GUANYUNXIAN JINDUODUO TONGXIN QICAI JINGYINGBU' },
	{ code: '2265461', name: 'GUAZHOUXIAN HAITUO DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1601263', name: 'GUCHENGXIAN HENGZHOU TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1790804', name: 'GUCHENGXIAN ZHENGKOU ZHONGYU SHOUJI SHANGDIAN' },
	{ code: '2469845', name: 'GUIGANGSHI GANGBEIQU FENGHUANGJIE HENGDA TONGXUN QICAI JINGYINGBU' },
	{ code: '1763008', name: 'GUILIN FENGYU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1731806', name: 'GUILIN LUSHI TONGXUN YOUXIAN GONGSI' },
	{ code: '948261', name: 'GUILINSHI SHUNHONG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '1310163', name: 'GUILINSHI WANHUA XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1693041', name: 'GUILINSHI YITENG TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1312703', name: 'GUILINSHI YUZHOU TONGXUN YINGXIANG QICAI YOUXIAN GONGSI' },
	{ code: '1302119', name: 'GUIYANG BAIYUN KAISHENG TONGXUN QICAI JINGYINGBU' },
	{ code: '1283286', name: 'GUIYANG HENGZHI TONGXUN YOUXIAN GONGSI' },
	{ code: '1315548', name: 'GUIYANG NANMING WENYU WANGLUO KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '885022', name: 'GUIYANG WEILONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1288730', name: 'GUIYANG YINGHE MAOYI YOUXIAN GONGSI' },
	{ code: '1420859', name: 'GUIYANG YIYUANCHUAN KEJI YOUXIAN GONGSI' },
	{ code: '1319904', name: 'GUIYANG YONGXIANG TONGXIN CHANPIN XIAOSHOUBU' },
	{ code: '1469212', name: 'GUIYANG YUXIN TONGXUN SHEBEI JINGYINGBU' },
	{ code: '2060873', name: 'GUIZHOU BAIRUIXING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1302319', name: 'GUIZHOU CHANGHONG WEIYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '631488', name: 'GUIZHOU CHENGXUN LIANSUO XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2066064', name: 'GUIZHOU DIANZAN JIUJI SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1290138', name: 'GUIZHOU FEILIDA KEJI YOUXIAN GONGSI' },
	{ code: '672368', name: 'GUIZHOU FEIMAOTUI TONGXUN YOUXIAN GONGSI' },
	{ code: '1319893', name: 'GUIZHOU HAOLONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1290145', name: 'GUIZHOU HAOTIANQI TONGXIN FAZHAN YOUXIAN GONGSI' },
	{ code: '1311837', name: 'GUIZHOU HEZHAN JIACHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1693412', name: 'GUIZHOU HUANXIN YIGOU KEJI YOUXIAN GONGSI' },
	{ code: '1293260', name: 'GUIZHOU HUAZHONG MINGYUAN DIANXUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1548471', name: 'GUIZHOU JINLINLI MAOYI YOUXIAN GONGSI' },
	{ code: '1533242', name: 'GUIZHOU JIUDING TONGCHUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '706902', name: 'GUIZHOU JIXIANGTONG MAOYI YOUXIAN GONGSI' },
	{ code: '2265420', name: 'GUIZHOU QIANNAN GUIDINGXIAN SHUANGLONG SHUMA YOUXIAN GONGSI' },
	{ code: '1561318', name: 'GUIZHOU RUIQI HENGYUAN KEJI YOUXIAN GONGSI' },
	{ code: '1983876', name: 'GUIZHOU RUIXINHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '889726', name: 'GUIZHOU SHIXIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1492949', name: 'GUIZHOU TAIGU MINGXUAN MAOYI YOUXIAN GONGSI' },
	{ code: '672400', name: 'GUIZHOU XINFENGXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1245161', name: 'GUIZHOU XINGXINDA TONGXUN MAOYI YOUXIAN GONGSI' },
	{ code: '1131597', name: 'GUIZHOU XINHENGTONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1593166', name: 'GUIZHOU XINLONGTENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1469211', name: 'GUIZHOU XINQIANFENG MAOYI YOUXIAN GONGSI' },
	{ code: '2280477', name: 'GUIZHOU YINJIA KEJI YOUXIAN GONGSI' },
	{ code: '1709084', name: 'GUIZHOU YITONGLI MAOYI YOUXIAN GONGSI' },
	{ code: '1301718', name: 'GUIZHOU YUANCHENG TONGCHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1323556', name: 'GUIZHOU ZHIHUA TONGCHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1307904', name: 'GUIZHOU ZHONGHETONG MAOYI YOUXIAN GONGSI' },
	{ code: '615408', name: 'GUIZHOU ZHONGYI DIANXUN LIANSUO XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1315545', name: 'GUIZHOU ZONGYUAN KEJI YOUXIAN GONGSI' },
	{ code: '1763454', name: 'GUIZHOUSHENG BAINIAN ANDA MAOYI YOUXIAN GONGSI' },
	{ code: '1645634', name: 'GUOMEI ZAIXIAN DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '2486285', name: 'GUSHI RONGSHENG DIANZI YOUXIAN ZEREN GONGSI' },
	{ code: '1294064', name: 'GUSHIXIAN HUIFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1232874', name: 'GUYUAN YINLUN GONGMAO YOUXIAN GONGSI' },
	{ code: '1464070', name: 'GUZHENXIAN XUNDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1963231', name: 'HA\'ERBINSHI TULAN KEJI YOUXIAN GONGSI' },
	{ code: '1316079', name: 'HAERBIN GUANGHONG TONGXUN SHEBEI XIAOSHOU YOUXIAN ZEREN GONGSI' },
	{ code: '1318738', name: 'HAERBIN HELIANSHENG TONGXUN QICAI JINGXIAO YOUXIAN GONGSI' },
	{ code: '622953', name: 'HAERBIN HUALIANFA SHANGMAO YOUXIAN GONGSI' },
	{ code: '615712', name: 'HAERBIN QIXING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1283115', name: 'HAERBIN XINLICHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1645566', name: 'HAERBINSHI TUOWEI CHENGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1286784', name: 'HAIANXIAN CHENGXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1549713', name: 'HAIDONGSHI HUANYU TONGXUN YOUXIAN GONGSI' },
	{ code: '1299240', name: 'HAIKOU BAIXINTONG MAOYI YOUXIAN GONGSI' },
	{ code: '1290243', name: 'HAIKOU DAREN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1777594', name: 'HAIKOU QINGYING DIANXUN FUWU YOUXIAN GONGSI' },
	{ code: '1306524', name: 'HAIKOU RUIKANG MAOYI YOUXIAN GONGSI' },
	{ code: '1759195', name: 'HAIKOU SHENGSE SHUMA YOUXIAN GONGSI' },
	{ code: '1323660', name: 'HAIKOU XINFENGYUE TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1330173', name: 'HAIKOU XINJUNMING GONGMAO YOUXIAN GONGSI' },
	{ code: '1274952', name: 'HAIKOU YIXUNDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2448725', name: 'HAIKOU YOUPIN TIANXIA KEJI YOUXIAN GONGSI' },
	{ code: '1991356', name: 'HAILINSHI CHENYU TONGXUN QICAIDIAN' },
	{ code: '1237006', name: 'HAILINSHI JINYIHENG TONGXUN QICAITING' },
	{ code: '1787853', name: 'HAILINSHI YIHENG TONGXUN KEHU FUWU ZHONGXIN' },
	{ code: '1180640', name: 'HAIMENSHI BAIXIN DIANZI QICAI YOUXIAN GONGSI' },
	{ code: '1785848', name: 'HAIMENSHI JIEFANG SHOUJI DAMAICHANG' },
	{ code: '982823', name: 'HAIMENSHI QINENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '706839', name: 'HAINAN FENGXING SHIDA DIANXUN YOUXIAN GONGSI' },
	{ code: '1306514', name: 'HAINAN FENGXING SHIDA TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1752822', name: 'HAINAN GUOMEI TAIDA WULIU YOUXIAN GONGSI' },
	{ code: '1629011', name: 'HAINAN HAIHANG XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1302105', name: 'HAINAN HENGFENG WEIYE TONGXIN SHUMA YOUXIAN GONGSI' },
	{ code: '1332845', name: 'HAINAN JINJIAXIN DIANXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '2475065', name: 'HAINAN LIANTUO TONGXIN YOUXIAN GONGSI' },
	{ code: '1286743', name: 'HAINAN MIJING TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '837959', name: 'HAINAN SHUANGLIAN SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '560490', name: 'HAINAN WANGZHE TIANCHUANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1301561', name: 'HAINAN WEIMEI SHANGXIANG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1752401', name: 'HAINAN XINGUANGMING TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1753193', name: 'HAINAN XINSANJIU ZHINENG KEJI YOUXIAN GONGSI' },
	{ code: '1220034', name: 'HAINAN XINXING DIANQI YOUXIAN GONGSI' },
	{ code: '2461006', name: 'HAINAN XINYAN HAIHUA TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1235797', name: 'HAINAN YINGXIN SHIYE YOUXIAN GONGSI' },
	{ code: '1396773', name: 'HAINAN YIWEI TIANCHENG KEJI YOUXIAN GONGSI' },
	{ code: '900897', name: 'HAINING WANJIATONG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1990845', name: 'HAIXINGXIAN XINGTONG TONGXUN QICAI JINGXIAOBU' },
	{ code: '1785909', name: 'HAIYANGSHI DAHUI SHOUJI JINGXIAOBU' },
	{ code: '1131546', name: 'HAIYANGSHI KECHUANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1174853', name: 'HAIYING TONGXUN QICAI (GAOBEIDIAN) YOUXIAN GONGSI' },
	{ code: '1176666', name: 'HAMI GUOYU KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2137797', name: 'HANCHENGSHI HONGTAILI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1760060', name: 'HANDAN CHANGSHI MAOYI YOUXIAN GONGSI' },
	{ code: '2363417', name: 'HANDAN XUNJIE RITONG MAOYI YOUXIAN GONGSI' },
	{ code: '1761966', name: 'HANDANSHI CHUANQI TONGXIN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1427028', name: 'HANDANSHI FENGFENG JIETONG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1381227', name: 'HANDANSHI GUOXUN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1319922', name: 'HANDANSHI HANSHAN TIANSHUN LIHONG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '2280120', name: 'HANDANSHI QUDAO MAOYI YOUXIAN GONGSI' },
	{ code: '1420162', name: 'HANDANSHI XINLAN MAOYI YOUXIAN GONGSI' },
	{ code: '1545831', name: 'HANDANSHI YAOOU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2599025', name: 'HANDANSHI YECHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1016784', name: 'HANGZHOU CHONGDA KEJI YOUXIAN GONGSI' },
	{ code: '2369754', name: 'HANGZHOU DABAO XINXI JISHU YOUXIAN GONGSI' },
	{ code: '229250', name: 'HANGZHOU DINGFENG DIANZI YOUXIAN GONGSI' },
	{ code: '2063263', name: 'HANGZHOU FEITU KEJI YOUXIAN GONGSI' },
	{ code: '1019119', name: 'HANGZHOU FUYANG WUXING SHUMA TONGXIN YOUXIAN GONGSI' },
	{ code: '1550103', name: 'HANGZHOU HAOYIGOU TONGXIN QICAI LIANSUO YOUXIAN GONGSI' },
	{ code: '1270012', name: 'HANGZHOU HUISHENG XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1631669', name: 'HANGZHOU JIARAN MAOYI YOUXIAN GONGSI' },
	{ code: '1816622', name: 'HANGZHOU JIEYU SHIXUAN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '686242', name: 'HANGZHOU JINGJIE TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1399372', name: 'HANGZHOU LIN\'AN HAICHUANG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '690373', name: 'HANGZHOU LIN\'AN JINDA SHUMA DIANZI YOUXIAN GONGSI' },
	{ code: '2408520', name: 'HANGZHOU LIN\'AN JINHU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1274865', name: 'HANGZHOU LINHAILIANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '780778', name: 'HANGZHOU LITONG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1966392', name: 'HANGZHOU LIUYUE WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1363644', name: 'HANGZHOU MEIYUE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2381107', name: 'HANGZHOU MOFANG SHUMA YOUXIAN GONGSI' },
	{ code: '1299258', name: 'HANGZHOU NUOQI TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '564761', name: 'HANGZHOU PINFENG KEJI YOUXIAN GONGSI' },
	{ code: '1737161', name: 'HANGZHOU RITIAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1306462', name: 'HANGZHOU SANRONG TONGXUN YOUXIAN GONGSI' },
	{ code: '2089790', name: 'HANGZHOU SONGXING KEJI YOUXIAN GONGSI' },
	{ code: '791040', name: 'HANGZHOU TIANSHUAI KEJI YOUXIAN GONGSI' },
	{ code: '1759068', name: 'HANGZHOU TIANXIANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '229252', name: 'HANGZHOU TONGHE TECHNOLOGY CO.,LTD.' },
	{ code: '1475883', name: 'HANGZHOU WEIXIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1286149', name: 'HANGZHOU WOSHANG KEJI YOUXIAN GONGSI' },
	{ code: '1720607', name: 'HANGZHOU YUANXIANG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1332806', name: 'HANGZHOU ZHISHI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1759722', name: 'HANGZHOU ZHIYING XINXI KEJI YOUXIAN GONGSI' },
	{ code: '616920', name: 'HANGZHOU ZHONGTAI SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '1299213', name: 'HANGZHOU ZHONGYI TONGXIN YOUXIAN GONGSI' },
	{ code: '1330157', name: 'HANSHOUXIAN HONGDA JIADIAN YOUXIAN GONGSI' },
	{ code: '2503045', name: 'HANSHOUXIAN YUHE DIANXUN JINGXIAODIAN' },
	{ code: '1973216', name: 'HANYIN CHENGGUAN TAILI SHOUJI MAICHANG' },
	{ code: '1662933', name: 'HANYINXIAN TAILI TONGXUN YOUXIAN GONGSI' },
	{ code: '1275456', name: 'HANYUANXIAN SAIGE TONGXUN SHEBEI XIAOSHOU YOUXIAN ZEREN GONGSI' },
	{ code: '2105550', name: 'HANYUANXIAN TUANTUAN HOUQIN FUWU YOUXIAN GONGSI' },
	{ code: '1301615', name: 'HANZHONG CHENGBANG SHIYE YOUXIAN GONGSI' },
	{ code: '1440204', name: 'HANZHONG JINBANG DIANXUN YOUXIAN GONGSI' },
	{ code: '1338052', name: 'HANZHONG JINSHI DIANXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1980324', name: 'HANZHONG NUOBAITE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1722461', name: 'HANZHONG TONGZHOU KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1372722', name: 'HANZHONGSHI JIASHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1393907', name: 'HAOYUNLAI SHIYE (HUNAN) YOUXIAN GONGSI' },
	{ code: '491249', name: 'HARBIN GUANGXIANKEJI TECHNOLOGY CO. LTD' },
	{ code: '1267194', name: 'HARBIN XIEHENG SHENGSHI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1363946', name: 'HEBEI AIKEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '543011', name: 'HEBEI AIPAI DIANZI JISHU YOUXIAN GONGSI' },
	{ code: '1691217', name: 'HEBEI GUANYAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1719318', name: 'HEBEI HAIYUAN CHENGCHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1396791', name: 'HEBEI JIALANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1294441', name: 'HEBEI JINCAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1507513', name: 'HEBEI JINGER DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1393541', name: 'HEBEI JINGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '564260', name: 'HEBEI JINLANG AILI MAOYI YOUXIAN GONGSI' },
	{ code: '1769762', name: 'HEBEI JIQIONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1522469', name: 'HEBEI JIWO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1719699', name: 'HEBEI LIANJING KEJI YOUXIAN GONGSI' },
	{ code: '964547', name: 'HEBEI SHIDAI DIANZI YOUXIAN GONGSI' },
	{ code: '1299228', name: 'HEBEI TIANCE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2469765', name: 'HEBEI WUQIANJUN TONGXIN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1486771', name: 'HEBEI XIAKE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1398180', name: 'HEBEI XIAOLIN SHUMA DIANQI YOUXIAN GONGSI' },
	{ code: '1046339', name: 'HEBEI XINDUAN SHUMA SHEBEI YOUXIAN GONGSI' },
	{ code: '1332698', name: 'HEBEI XINGZHIYING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1262933', name: 'HEBEI XINLIAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1381194', name: 'HEBEI XINSAI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1762229', name: 'HEBEI XUNBANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '556392', name: 'HEBEI YATAI TONGXIN KEJI JITUAN YOUXIAN GONGSI' },
	{ code: '1274871', name: 'HEBEI YINGJIE SHUMA DIANZI YOUXIAN GONGSI' },
	{ code: '573907', name: 'HEBEI YONGTONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1321942', name: 'HEBEI YUMINGHUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315030', name: 'HEBEI ZHONGHE YONGHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1024430', name: 'HEBISHI CHUANQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1991364', name: 'HEBISHI CHUANYIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1010223', name: 'HEBISHI HAIXIA TIANYI SHENDA TONGXUN GUANGCHANG' },
	{ code: '1740231', name: 'HEBISHI JIANGONG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '1961393', name: 'HEBISHI JIQIMAO TONGXUN QICAI SHANGHANG' },
	{ code: '1745620', name: 'HEBISHI YUANDONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1515118', name: 'HECHISHI JINJIE TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '840390', name: 'HECHISHI JINXIN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1651628', name: 'HECHISHI RONGHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1283774', name: 'HECHISHI WANGDA CHENGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1777234', name: 'HEFEI CHAOSHENG TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1082958', name: 'HEFEI FENGSHI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1305671', name: 'HEFEI FENGTAI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1233605', name: 'HEFEI GUOYING TONGXUN SHUMA JISHU YOUXIAN GONGSI' },
	{ code: '1522441', name: 'HEFEI HAIYIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1779538', name: 'HEFEI JINZUAN KEMAO YOUXIAN GONGSI' },
	{ code: '1071859', name: 'HEFEI MANYU MAOYI YOUXIAN GONGSI' },
	{ code: '1633297', name: 'HEFEI MENGMENGDA TONGXIN DIANZI YOUXIAN GONGSI' },
	{ code: '2396858', name: 'HEFEI TENGZHIYU MAOYI YOUXIAN GONGSI' },
	{ code: '1305158', name: 'HEFEI XICHEN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1737147', name: 'HEFEI YUEHUI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1024378', name: 'HEFEI YUNTIAN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1337671', name: 'HEFEI ZHANXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '843145', name: 'HEFEI ZHIZHAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '549016', name: 'HEFEISHI HAOTIAN TONGXIN JISHU FAZHAN YOUXIAN GONGSI' },
	{ code: '1759131', name: 'HEFEISHI JIAYU SHUMA DIANZI YOUXIAN GONGSI' },
	{ code: '1801429', name: 'HEFEISHI LINGYUN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1282682', name: 'HEFEISHI WEILAI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '672380', name: 'HEGANG ZHONGYOU SHOUJI LIANSUO XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1796635', name: 'HEGANGSHI GONGNONGQU LIANYING TONGXUN SHEBEI XIAOSHOUDIAN' },
	{ code: '1394626', name: 'HEIHESHI JINXIANDAI TONGXUN SHEBEI XIAOSHOU YOUXIAN ZEREN GONGSI' },
	{ code: '835576', name: 'HEILONGJIANG DAYU PUHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1711540', name: 'HEILONGJIANG HANXIANG JINGMAO YOUXIAN GONGSI' },
	{ code: '564000', name: 'HEILONGJIANG HUABIN HANGMU TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1476590', name: 'HEILONGJIANGSHENG BEIQIXING TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '440258', name: 'HEILONGJIANGSHENG LINGZHI SHUMA CHANPIN LIANSUO XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1258309', name: 'HEISHANXIAN SHENLAN SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '2380623', name: 'HEJIANSHI JIMEI TONGXINBU' },
	{ code: '1817543', name: 'HELIN\'GEERXIAN QINGXIU ZHIYUAN TONGXUN' },
	{ code: '1527737', name: 'HELIN\'GEERXIAN ZHITONG TONGXUN' },
	{ code: '1282050', name: 'HELONGSHI RISHENG TONGXUN QICAI SHANGDIAN' },
	{ code: '632367', name: 'HENAN AIGUOZHE TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1029425', name: 'HENAN BEIDOUXING LIANSUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1650327', name: 'HENAN BONA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1429132', name: 'HENAN BOTU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1343059', name: 'HENAN BOXIANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2474665', name: 'HENAN CHANGDE SHIJI SHUMA YOUXIAN GONGSI' },
	{ code: '1719653', name: 'HENAN CHUANGYA TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '752909', name: 'HENAN DAZHANG SHIYE YOUXIAN GONGSI' },
	{ code: '1263004', name: 'HENAN DINGTAIFENG KEJI YOUXIAN GONGSI' },
	{ code: '849812', name: 'HENAN GUANGCHENG SHIYE JITUAN YOUXIAN GONGSI' },
	{ code: '1578703', name: 'HENAN GUANZHIXUN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1314788', name: 'HENAN GUOYU TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '274653', name: 'HENAN HUDONG DIANZI YOUXIAN GONGSI' },
	{ code: '1354681', name: 'HENAN JINZUAN LIANHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1759163', name: 'HENAN JUWANG TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1283095', name: 'HENAN KUANGSHI CHUANGTIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1233004', name: 'HENAN LIANJIE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1306491', name: 'HENAN LIANXIANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1263047', name: 'HENAN MIAOJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2364037', name: 'HENAN RUNCHUAN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1994857', name: 'HENAN SAIDE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1777225', name: 'HENAN TONGZHEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1274879', name: 'HENAN XINRUI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '919965', name: 'HENAN XUANMING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1782378', name: 'HENAN YUMI XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1969965', name: 'HENAN YUTU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1274872', name: 'HENAN ZHONGXIN YIDONG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1739993', name: 'HENANSHENG CHANGYUAN DIANXUN YOUXIAN GONGSI' },
	{ code: '1980287', name: 'HENANSHENG DAXIN KEJI TONGXUN YOUXIAN GONGSI' },
	{ code: '1741570', name: 'HENANSHENG PAIQU SHUMA YOUXIAN GONGSI' },
	{ code: '1542511', name: 'HENANSHENG XINJIEXIN DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '2089739', name: 'HENANSHENG XINSHISHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1513303', name: 'HENGFENGXIAN PINGJING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1798237', name: 'HENGSHA (TIANJIN) DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '896264', name: 'HENGSHUI LEXUN TONGXIN QICAI XIAOSHOU YOUXIAN ZEREN GONGSI' },
	{ code: '1246162', name: 'HENGSHUI RUIWEI BAIHUO ZHONGXIN' },
	{ code: '1014136', name: 'HENGSHUI TIANYIN TONGXIN YOUXIAN GONGSI' },
	{ code: '693387', name: 'HENGSHUI XIGUANG TONGXIN YOUXIAN GONGSI' },
	{ code: '1427035', name: 'HENGSHUI XINTIANYU TONGXIN MAOYI YOUXIAN GONGSI' },
	{ code: '1771364', name: 'HENGSHUI YUNJITONG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '551978', name: 'HENGXIN DONGFANG WENHUA GUFEN YOUXIAN GONGSI' },
	{ code: '1293253', name: 'HENGYANG WEIWO DIANZI YOUXIAN GONGSI' },
	{ code: '1474450', name: 'HENGYANG WEIXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1535920', name: 'HENGYANG ZHONGHONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1507514', name: 'HENGYANGSHI HEJUN JIECHENG MAOYI YOUXIAN GONGSI' },
	{ code: '782546', name: 'HENGYANGSHI JIEXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '671733', name: 'HENGYANGSHI JINLIANHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1341147', name: 'HENGYANGSHI JUHONGYUAN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2849925', name: 'HENGYANGSHI RUIHONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1288120', name: 'HENGYANGSHI SHANGMEI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1293247', name: 'HENGYANGSHI XINJINXIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1343065', name: 'HENGYANGSHI ZHONGYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1805684', name: 'HENGZHOU (TIANJIN) DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1269908', name: 'HEPU XUXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1337455', name: 'HERMES (CHINA) CO., LTD' },
	{ code: '1363610', name: 'HERMES (CHINA) TRADING CO., LTD.' },
	{ code: '1787817', name: 'HESHANSHI SHAPING GUANGSHI DIANXUN SHANGDIAN' },
	{ code: '1737836', name: 'HESHANSHI TIANXIA DIANXUN ZHINENG SHOUJI YOUXIAN GONGSI' },
	{ code: '1339289', name: 'HESHANSHI XINHE YUNSHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1811103', name: 'HESHUNXIAN YIZHIDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1139764', name: 'HETIAN SANFU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1279148', name: 'HEXIAN SANDING DIANZI CHANPIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1486745', name: 'HEYANGXIAN SHIJI ZHONGTIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1342513', name: 'HEYUAN XINCHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1318644', name: 'HEYUANSHI FANGLE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1545834', name: 'HEYUANSHI XINDA WULIANWANG KEJI YOUXIAN GONGSI' },
	{ code: '1662987', name: 'HEYUANSHI YINGXIN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1974073', name: 'HEZESHI DINGTAOQU ZHUYONG SHOUJI DAMAICHANG' },
	{ code: '1779907', name: 'HEZESHI HAITONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1328996', name: 'HEZESHI XUNTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2008970', name: 'HEZHOUSHI BABUQU BOXUN TONGXIN SHOUJIDIAN' },
	{ code: '1651756', name: 'HEZHOUSHI ZHIYUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '276972', name: 'HONG TU-SAN BAO HI-TECH CO., LTD' },
	{ code: '1658605', name: 'HONGAN ZHILIAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1692734', name: 'HONGHEZHOU DAXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1227203', name: 'HONGHEZHOU HAINUO KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1283987', name: 'HONGHEZHOU HONGYU KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1393523', name: 'HONGHEZHOU LONGXINGDA SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1718808', name: 'HONGHEZHOU RUNFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '705693', name: 'HONGHEZHOU XINGYAO KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '843307', name: 'HONGHEZHOU XINXING TONGXUN YOUXIAN GONGSI' },
	{ code: '1417832', name: 'HONGHEZHOU YUNZHUO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1314329', name: 'HONGHEZHOU ZHISHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1154157', name: 'HONGHUSHI MINGRUI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2031305', name: 'HONGHUSHI MINGYE DIANZI YOUXIAN GONGSI' },
	{ code: '2114735', name: 'HONGTAQU HENGFENG TONGXUN JINGYINGBU' },
	{ code: '1294074', name: 'HONGTONGXIAN XINBAISHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1790811', name: 'HONGZEQU GAOLIANGJIAN XIANFENG SHOUJIDIAN' },
	{ code: '1267103', name: 'HOUMASHI HONGYA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1721376', name: 'HUA\'NANXIAN BAIRUN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1464077', name: 'HUACHUANXIAN SHENGDA YUANHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1273867', name: 'HUAI\'AN JIEYUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1732379', name: 'HUAI\'ANSHI BAIYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1329125', name: 'HUAIAN DONGWEI TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1197040', name: 'HUAIAN QIANYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1720561', name: 'HUAIANSHI MAOFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1790268', name: 'HUAIANXIAN CHAIGOUBUZHEN FEIHAO SHOUJIDIAN' },
	{ code: '1194114', name: 'HUAIBEI WANRUI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1260366', name: 'HUAIBEISHI QINGBO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1381195', name: 'HUAIBEISHI RONGCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1285993', name: 'HUAIBINXIAN XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1244448', name: 'HUAIHUA HAOYUAN DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '698021', name: 'HUAIHUA JINLIANHE KEJI YOUXIAN GONGSI' },
	{ code: '1651626', name: 'HUAIHUA XINGUANG DIANXIN KEJI YOUXIAN GONGSI' },
	{ code: '1581627', name: 'HUAIHUASHI BULV MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1398750', name: 'HUAIHUASHI MOFANG KEJI YOUXIAN GONGSI' },
	{ code: '1388694', name: 'HUAIHUASHI MOTE TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1662977', name: 'HUAIHUASHI RUISHIJIA WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1075987', name: 'HUAIHUASHI WEIXIN KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1518536', name: 'HUAILAIXIAN GUOHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1393501', name: 'HUAINANSHI HENGXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1235856', name: 'HUAINANSHI TAIBANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1244018', name: 'HUAINANSHI YUJIE TONGXUN KEMAO YOUXIAN GONGSI' },
	{ code: '1393503', name: 'HUAINANSHI ZHIHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1286814', name: 'HUAININGXIAN MEIYUE MAOYI YOUXIAN GONGSI' },
	{ code: '1983859', name: 'HUAIRENXIAN YUTONGLONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1320378', name: 'HUAIYANGXIAN FUHUI SHENGYE TONGXUN YOUXIAN GONGSI' },
	{ code: '1213633', name: 'HUAIYUANXIAN TIANYI DIANZI CHANPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1341051', name: 'HUANANXIAN CHENGXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1287385', name: 'HUANANXIAN XINHUA HUABIN SHOUJI HANGMU' },
	{ code: '1290299', name: 'HUANGGANGSHI YIQUAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1213122', name: 'HUANGHUASHI QINGLI KEJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1791376', name: 'HUANGHUASHI QINGLI TONGXUN SHUMA GUANGCHANG' },
	{ code: '671738', name: 'HUANGHUASHI SHITONG KEJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1975695', name: 'HUANGSHAN JIEYIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2221277', name: 'HUANGSHAN MINGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1777249', name: 'HUANGSHAN YIXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '993402', name: 'HUANGSHANSHI KANGSHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1513948', name: 'HUANGSHANSHI TUNXIQU LIANXIN TONGXUN SHANGHANG' },
	{ code: '1310181', name: 'HUANGSHANSHI TUNXIQU WODE ZHINENG SHUMACHENG' },
	{ code: '1263514', name: 'HUANGSHANSHI YUHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1235151', name: 'HUANGSHI TIANCHENG XINXI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1195443', name: 'HUANGSHI TIANTAI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '963192', name: 'HUANGSHIGANGQU XINBOYUAN TONGXUN SHEBEI JINGYINGBU' },
	{ code: '1316681', name: 'HUANGSHISHI TIANCHUANG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1510178', name: 'HUATINGXIAN HUARUI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1343000', name: 'HUAXIAN JIAOZHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1751173', name: 'HUAXIAN WEIGUO TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1816654', name: 'HUAYINGSHI ZHONGHUI TONGXIN MENSHI' },
	{ code: '1366838', name: 'HUAYINSHI GUOMING TONGXUN YOUXIAN GONGSI' },
	{ code: '1287215', name: 'HUAYUANXIAN DIXINDA NONGYE KEJI KAIFA YOUXIAN ZEREN GONGSI' },
	{ code: '1779833', name: 'HUBEI BAIHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1311786', name: 'HUBEI BOTIAN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '690210', name: 'HUBEI BOTONG DIANQI YOUXIAN GONGSI' },
	{ code: '1510385', name: 'HUBEI CHANGYUAN HONGWEI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2408569', name: 'HUBEI CHENLONG KEMAO YOUXIAN GONGSI' },
	{ code: '2074580', name: 'HUBEI HAISHIDA TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1309383', name: 'HUBEI HEHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1263087', name: 'HUBEI HONGSHENG SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1993857', name: 'HUBEI JIAQIAN ZHINENG KEJI YOUXIAN GONGSI' },
	{ code: '1395178', name: 'HUBEI JINLI QINGGONG SHEBEI YOUXIAN GONGSI' },
	{ code: '810217', name: 'HUBEI JIUDING TONGXIN YOUXIAN GONGSI' },
	{ code: '1593168', name: 'HUBEI JUYUN MAOYI YOUXIAN GONGSI' },
	{ code: '1294459', name: 'HUBEI KAIXIN TONGXIN YOUXIAN GONGSI' },
	{ code: '1662991', name: 'HUBEI KUHUA GONGMAO YOUXIAN GONGSI' },
	{ code: '946376', name: 'HUBEI KULE SHUMA YOUXIAN GONGSI' },
	{ code: '1417284', name: 'HUBEI MINGYUAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1622616', name: 'HUBEI XINQIAO DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '485367', name: 'HUBEI XINSHENG KEJI TOUZI YOUXIAN GONGSI' },
	{ code: '696067', name: 'HUBEI XUNHUA GENGTAI DIANXUN GUFEN YOUXIAN GONGSI' },
	{ code: '2266926', name: 'HUBEI YANGLAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1041694', name: 'HUBEI YUANFU KEJI YOUXIAN GONGSI' },
	{ code: '709387', name: 'HUBEI YUANYIFAN KEJI YOUXIAN GONGSI' },
	{ code: '1965735', name: 'HUBEI YUNTING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1112690', name: 'HUBEI ZEXU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1274948', name: 'HUBEI ZHONGBEI LIANHE TONGXIN YOUXIAN GONGSI' },
	{ code: '1759673', name: 'HUBEI ZHUOYUE ZHIHUI TONGXIN YOUXIAN GONGSI' },
	{ code: '1510382', name: 'HUHEHAOTESHI CAOYUANHUI DIANZI SHANGWU YOUXIAN ZEREN GONGSI' },
	{ code: '1731889', name: 'HUHEHAOTESHI SONGMAO SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1764698', name: 'HUICHANGXIAN PINSHANG KEJI YOUXIAN GONGSI' },
	{ code: '2475128', name: 'HUIDONGXIAN PINGSHAN LIUTONG TONGXUNDIAN' },
	{ code: '2474865', name: 'HUINANXIAN CHAOYANGZHEN HONGYI ZHINENG SHOUJI MAICHANG' },
	{ code: '1511034', name: 'HUINANXIAN KAIQI DIANZI KEJI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1439564', name: 'HUIXIANSHI LINGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1455754', name: 'HUIZE GUOHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '2070687', name: 'HUIZE TINGKAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1341044', name: 'HUIZHOU GUANGDIAN HUANYU TONGXIN YOUXIAN GONGSI' },
	{ code: '693426', name: 'HUIZHOUSHI CHUANGYU SHUMA SHIYE YOUXIAN GONGSI' },
	{ code: '2474965', name: 'HUIZHOUSHI FENGDA TONGXUN YOUXIAN GONGSI' },
	{ code: '1284503', name: 'HUIZHOUSHI GANGYI SHIYE YOUXIAN GONGSI' },
	{ code: '1293287', name: 'HUIZHOUSHI HONGKAI MAOYI YOUXIAN GONGSI' },
	{ code: '1307817', name: 'HUIZHOUSHI LONGYUE SHIDAI SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '563915', name: 'HUIZHOUSHI WANGDA TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1768932', name: 'HUIZHOUSHI XIAOYUZHOU SHIYE YOUXIAN GONGSI' },
	{ code: '1067184', name: 'HUIZHOUSHI YIFAN SHIYE YOUXIAN GONGSI' },
	{ code: '2009732', name: 'HUIZHOUSHI YIHE KEJI YOUXIAN GONGSI' },
	{ code: '1805278', name: 'HUIZHOUSHI ZHONGLANGXIN TONGXUN FUWU YOUXIAN GONGSI' },
	{ code: '1314360', name: 'HULINSHI DONGYAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2804065', name: 'HULINSHI XUNQIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '688044', name: 'HULUDAO CHENGTAI TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '616441', name: 'HULUDAOSHI AIBO TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1052319', name: 'HULUDAOSHI AIFENG DIANZI YOUXIAN ZEREN GONGSI' },
	{ code: '1164900', name: 'HULUDAOSHI JINFU SHANGMAO YOUXIAN GONGSI' },
	{ code: '2008899', name: 'HULUDAOSHI KANGDA AIBO TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1633265', name: 'HULUDAOSHI LUNCHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '885012', name: 'HULUDAOSHI SHANHE YIDONG TONGXIN XIAOSHOU WEIXIU YOUXIAN GONGSI' },
	{ code: '2379009', name: 'HULUNBEI\'ERSHI QIXING TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '709593', name: 'HULUNBEIER ZHONGYOU PUTAI TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '709379', name: 'HULUNBEIERSHI DONGTAI TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1396843', name: 'HULUNBEIERSHI FANGZHOU WEIYE KEJI KAIFA YOUXIAN ZEREN GONGSI' },
	{ code: '1745013', name: 'HUNAN BADA JIUYUN KEJI YOUXIAN GONGSI' },
	{ code: '1767795', name: 'HUNAN BAIYOU TONGXUN YOUXIAN GONGSI' },
	{ code: '709581', name: 'HUNAN BENWEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '671723', name: 'HUNAN DEQIANG TONGXIN SHEBEI FUWU YOUXIAN GONGSI' },
	{ code: '688039', name: 'HUNAN DERONG KEJI YOUXIAN GONGSI' },
	{ code: '753530', name: 'HUNAN FEIHONG DIANZI XINXI YOUXIAN GONGSI' },
	{ code: '1274959', name: 'HUNAN GEMEI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1991411', name: 'HUNAN GUANGHAO HUILONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1279592', name: 'HUNAN HENGBO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1767695', name: 'HUNAN HONGKAI DIANXUN FAZHAN YOUXIAN GONGSI' },
	{ code: '693371', name: 'HUNAN HUATAI SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1343125', name: 'HUNAN HUIXIN KEJI YOUXIAN GONGSI' },
	{ code: '1056442', name: 'HUNAN JISUTONG SHOUJI LIANSUO YOUXIAN ZEREN GONGSI' },
	{ code: '1770110', name: 'HUNAN JIUTONG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1550092', name: 'HUNAN KAILI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1120870', name: 'HUNAN LEIKE KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1745853', name: 'HUNAN LIANNING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1253525', name: 'HUNAN LIHE GONGMAO FAZHAN YOUXIAN GONGSI' },
	{ code: '1084112', name: 'HUNAN LONGWU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '840761', name: 'HUNAN NUOXING KEJI YOUXIAN GONGSI' },
	{ code: '2850145', name: 'HUNAN QIXIN SHANGWU FUWU YOUXIAN GONGSI' },
	{ code: '2850045', name: 'HUNAN QIXING TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '670422', name: 'HUNAN SANMING TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2698825', name: 'HUNAN SHANMING YUNSHANG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1300745', name: 'HUNAN WANGDAWEI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1341122', name: 'HUNAN XIAOLAJIAO TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1249236', name: 'HUNAN XINYATAI KEJI YOUXIAN GONGSI' },
	{ code: '1311830', name: 'HUNAN XINZHIXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1215162', name: 'HUNAN YAOTENG JISHU SHEBEI YOUXIAN GONGSI' },
	{ code: '1336669', name: 'HUNAN YINCHENG YONGCHUANG DIANZI MAOYI YOUXIAN GONGSI' },
	{ code: '947362', name: 'HUNAN YOUSHANG GONGMAO YOUXIAN GONGSI' },
	{ code: '1311798', name: 'HUNAN YUANDING KEJI YOUXIAN GONGSI' },
	{ code: '1270486', name: 'HUNAN YUANRUI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1624425', name: 'HUNAN ZHONGHE YIXUN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1302720', name: 'HUNAN ZITAI DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1769842', name: 'HUNANSHENG HAOSHENGDA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1314804', name: 'HUNANSHENG RUOMING TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1484952', name: 'HUNANSHENG SHENNAO KEJI WENHUA FAZHAN YOUXIAN GONGSI' },
	{ code: '791506', name: 'HUNANSHENG XINHUA SHUDIAN YOUXIAN ZEREN GONGSI CHENZHOUSHI FENGONGSI' },
	{ code: '2003828', name: 'HUNCHUNSHI JINMA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2064626', name: 'HUNCHUNSHI LIMIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1285967', name: 'HUNYUANXIAN XIANGYUAN KEMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1141038', name: 'HUOLINGUOLESHI MINGYANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1786920', name: 'HUOSHANXIAN RUIJIN KEJI JINGYINGBU' },
	{ code: '1755005', name: 'HUZHOU DAJIN TONGXUN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1342375', name: 'HUZHOU HUDIAN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1296696', name: 'HUZHOU LIANTUO DIANZI YOUXIAN GONGSI' },
	{ code: '1332721', name: 'HUZHOU NANXUN SHIDAI TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1771085', name: 'HUZHOU ZHILI TIANXIANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1287304', name: 'HUZHOU ZHILI ZHIKAI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '268563', name: 'INGRAM MICRO (CHINA) HOLDING & COMMERCIAL CO., LTD' },
	{ code: '649296', name: 'INGRAM MICRO TRADING (SHANGHAI) CO., LTD.' },
	{ code: '1215020', name: 'JI\'ANSHI JIZHOUQU GEILI KEJI YOUXIAN GONGSI' },
	{ code: '1193404', name: 'JI\'ANSHI MINGDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1244430', name: 'JI\'ANSHI TALANG DIANXUN QICAI YOUXIAN GONGSI' },
	{ code: '1307978', name: 'JI\'ANSHI YINGPING TONGXUN YOUXIAN GONGSI' },
	{ code: '1420915', name: 'JI\'ANXIAN ZHONGLANG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1278953', name: 'JI\'NAN GUOLI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1677187', name: 'JI\'NANSHI CHANGQINGQU JIANYU SHOUJI ZHUANMAI ZONGHUI' },
	{ code: '1813311', name: 'JIAJIANGXIAN ALI TONGXUN JINGYINGBU' },
	{ code: '1173438', name: 'JIAJIANGXIAN KAIDI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1975647', name: 'JIAMUSI BAIQIAN DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1044979', name: 'JIAMUSI HAOHUI TONGXUN YOUXIAN GONGSI' },
	{ code: '1484245', name: 'JIAMUSI QIXING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1153318', name: 'JIAMUSI RUIJIA DIANZI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2477325', name: 'JIAMUSI SHENGHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1818753', name: 'JIAN\'GEXIAN ZHUOYUE YIDONG SHANGCHENG' },
	{ code: '1974491', name: 'JIANCHANGXIAN AIBO TONGXUN RENMIN SHANGCHANGDIAN' },
	{ code: '1320403', name: 'JIANCHANGXIAN DINGHUA DIANZI SHANGWU ZHONGXIN' },
	{ code: '1396915', name: 'JIANCHANGXIAN XUNDA TONGXUNDIAN' },
	{ code: '1240125', name: 'JIANDE JINGJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1365274', name: 'JIANGCHUAN ZHENJIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1397587', name: 'JIANGEXIAN YUXIANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1513910', name: 'JIANGHUA YAOZU ZIZHIXIAN LONGXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1462264', name: 'JIANGLINGXIAN MINGYANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1769815', name: 'JIANGMENSHI CHAOGUANG TONGXIN YOUXIAN GONGSI' },
	{ code: '1310135', name: 'JIANGMENSHI PENGJIANGQU GUANGYUAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1330166', name: 'JIANGMENSHI SENHUAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1332689', name: 'JIANGSU BEITE XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1983853', name: 'JIANGSU BOSHANG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2319117', name: 'JIANGSU CHENGSHUN GONGYINGLIAN GUANLI YOUXIAN GONGSI' },
	{ code: '1235870', name: 'JIANGSU CHENGYE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1484262', name: 'JIANGSU CHUANTIANYU WANGLUO GUFEN YOUXIAN GONGSI' },
	{ code: '923782', name: 'JIANGSU DAQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '274345', name: 'JIANGSU FIVE STAR APPLIANCE CO.,LTD' },
	{ code: '2057701', name: 'JIANGSU GANGLONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '551241', name: 'JIANGSU GUOLI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '921042', name: 'JIANGSU JIAYUAN JINPINGGUO KEJI YOUXIAN GONGSI' },
	{ code: '678768', name: 'JIANGSU JINZHEN SHOUJI LIANSUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '2364137', name: 'JIANGSU LIANSHENG DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '670427', name: 'JIANGSU QILONGJI MAOYI YOUXIAN GONGSI' },
	{ code: '1332683', name: 'JIANGSU RUNHAN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '556048', name: 'JIANGSU WENFENG TONGXUN YOUXIAN GONGSI' },
	{ code: '843298', name: 'JIANGSU YAHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2699385', name: 'JIANGSU YIJIAYI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1767725', name: 'JIANGSU YITIAN SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1306476', name: 'JIANGSU YOULI KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1991915', name: 'JIANGSU ZHISHANG CHAOPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1332682', name: 'JIANGSU ZHONGBO TONGXIN YOUXIAN GONGSI' },
	{ code: '1158459', name: 'JIANGSU ZHONGYU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1307966', name: 'JIANGXI AIERXIN DIANXUN JISHU XINXI YOUXIAN GONGSI' },
	{ code: '1310116', name: 'JIANGXI CHENGSHANGHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1235834', name: 'JIANGXI DONGGAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1632052', name: 'JIANGXI FEIFAN LIHONG SHIYE YOUXIAN GONGSI' },
	{ code: '1775397', name: 'JIANGXI FEIXUN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1358092', name: 'JIANGXI GUANGCHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1753568', name: 'JIANGXI HENGZHITIAN TONGXUN GONGCHENG YOUXIAN GONGSI' },
	{ code: '1763020', name: 'JIANGXI HUANXIANG MAOYI YOUXIAN GONGSI' },
	{ code: '671717', name: 'JIANGXI KAITAI HONGQI KEJI YOUXIAN GONGSI' },
	{ code: '1413319', name: 'JIANGXI KEYI KEJI YOUXIAN GONGSI' },
	{ code: '1305283', name: 'JIANGXI LEHENG KEJI YOUXIAN GONGSI' },
	{ code: '2469785', name: 'JIANGXI MAIYI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '2060813', name: 'JIANGXI QUANMEI DIANQI YOUXIAN GONGSI' },
	{ code: '1506935', name: 'JIANGXI QUANMIN FUWU KEJI YOUXIAN GONGSI' },
	{ code: '1966127', name: 'JIANGXI SIXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1542568', name: 'JIANGXI TAILIFENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1287874', name: 'JIANGXI XINHUA SHOUJICHENG YOUXIAN GONGSI' },
	{ code: '686246', name: 'JIANGXI XINLIAN SHOUJI YOUXIAN GONGSI' },
	{ code: '835528', name: 'JIANGXI XINMING TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2408166', name: 'JIANGXI XUNDAO YUNYING GUANLI YOUXIAN GONGSI' },
	{ code: '2469228', name: 'JIANGXI YIDATONG KEJI YOUXIAN GONGSI' },
	{ code: '1121111', name: 'JIANGXI YONGDAFENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2353077', name: 'JIANGXI YUCHAO KEJI YOUXIAN GONGSI' },
	{ code: '1307972', name: 'JIANGXI YUNJIA MAOYI YOUXIAN GONGSI' },
	{ code: '1508734', name: 'JIANGXI ZHAOKUN SHIYE YOUXIAN GONGSI' },
	{ code: '1393482', name: 'JIANGXI ZHONGHE SHIDAI KEMAO YOUXIAN GONGSI' },
	{ code: '1513304', name: 'JIANGXISHENG BOKAI KEJI YOUXIAN GONGSI' },
	{ code: '1332691', name: 'JIANGXISHENG DINGSHUN DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1474956', name: 'JIANGXISHENG FEIBEN DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1181217', name: 'JIANGXISHENG FEISHUN KEJI YOUXIAN GONGSI' },
	{ code: '1261882', name: 'JIANGXISHENG HENGJIE SHIYE YOUXIAN GONGSI' },
	{ code: '1769825', name: 'JIANGXISHENG HUIZHICHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1236918', name: 'JIANGXISHENG MEITIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '551898', name: 'JIANGXISHENG SHUIJINGGONG TOUZI YOUXIAN ZEREN GONGSI' },
	{ code: '1278284', name: 'JIANGXISHENG YANGCAI TONGXUN YOUXIAN GONGSI' },
	{ code: '884686', name: 'JIANGXISHENG ZHANXUN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1278940', name: 'JIANGYINSHI GUOTONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1803910', name: 'JIANLIXIAN HUANGYONG TONGXUN QICAI JINGYINGBU' },
	{ code: '1267218', name: 'JIANLIXIAN YITONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1753131', name: 'JIANOUSHI XINHUAYUAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1306593', name: 'JIANSHIXIAN YUNSHITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2069223', name: 'JIANYANG JIUJIU TONGXUN YOUXIAN GONGSI' },
	{ code: '1066140', name: 'JIANYANGSHI DESHENG SHANGSHA YOUXIAN GONGSI' },
	{ code: '1135572', name: 'JIANYANGSHI HAOTIAN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1130996', name: 'JIAOHESHI BEIDOU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1786827', name: 'JIAOHESHI JINBEIDOU TONGXUN QICAI SHANGDIAN' },
	{ code: '1856015', name: 'JIAOHESHI XINGHUABEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1294511', name: 'JIAOLINGXIAN LINGMING KEJI YOUXIAN GONGSI' },
	{ code: '682670', name: 'JIAOZUOSHI BAIHUO DALOU YOUXIAN ZEREN GONGSI' },
	{ code: '1584207', name: 'JIAOZUOSHI JINFENG XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2059637', name: 'JIAOZUOSHI TIANCHENG SHIDAI MAOYI YOUXIAN GONGSI' },
	{ code: '1029539', name: 'JIAOZUOSHI YINGKE DINGTAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1396945', name: 'JIAOZUOSHI YINGXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1278971', name: 'JIAOZUOSHI YURUN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '631717', name: 'JIASHAN HUANGSIDAI TONGXUN YOUXIAN GONGSI' },
	{ code: '2056078', name: 'JIAXIAN HONGJIE DIANZI YOUXIAN GONGSI' },
	{ code: '1785863', name: 'JIAXIANGXIAN HUAXUN HAODI SHOUJI SHANGCHENG' },
	{ code: '1305151', name: 'JIAXING BOWANG MAOYI YOUXIAN GONGSI' },
	{ code: '1273908', name: 'JIAXING HAIXIA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1071811', name: 'JIAXING HUANGSIDAI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1302706', name: 'JIAXING SHIQI MAOYI YOUXIAN GONGSI' },
	{ code: '1548823', name: 'JIAXING YUJI TONGXUN YOUXIAN GONGSI' },
	{ code: '1296515', name: 'JIAXINGSHI CHENGXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '548999', name: 'JIAXINGSHI HEYI DIANZI YOUXIAN GONGSI' },
	{ code: '1752788', name: 'JIAXINGSHI HONGJIE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1578305', name: 'JIAXINGSHI HONGYANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '688021', name: 'JIAXINGSHI JIANUO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1988657', name: 'JIAXINGSHI NANHUQU CHENGXI KEYU TONGXUN QICAI SHANGDIAN' },
	{ code: '1332713', name: 'JIAXINGSHI WUTONG GUOWANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1283132', name: 'JIAXINGSHI XINKE TONGXUN YOUXIAN GONGSI' },
	{ code: '1312810', name: 'JIAYUGUAN HONGTAO TONGXUN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2008914', name: 'JIAYUGUAN TIANYITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1719634', name: 'JIDONGXIAN HELI HONGDA TONGXUN SHANGDIAN' },
	{ code: '1962718', name: 'JIDONGXIAN HONGDA KEJI DIANZI CHANPIN YINGYEBU' },
	{ code: '2265600', name: 'JIDONGXIAN SHENGSHI SHOUJI CHAOPINDIAN' },
	{ code: '1235813', name: 'JIESHOUSHI GUOXIN TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1336538', name: 'JIEXIUSHI SHIDAI KEMAO YOUXIAN GONGSI' },
	{ code: '2121861', name: 'JIEXIUSHI XINYITONG KEMAO YOUXIAN GONGSI' },
	{ code: '1223869', name: 'JIEXIXIAN WANTONG MAOYI YOUXIAN GONGSI' },
	{ code: '707419', name: 'JIEYANGSHI JIALIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1397520', name: 'JIEYANGSHI XINGYU DIANZI YOUXIAN GONGSI' },
	{ code: '657291', name: 'JILINSHENG DAQIN JINGMAO YOUXIAN GONGSI' },
	{ code: '264688', name: 'JILINSHENG FEIXIANG DIANXUN YOUXIAN GONGSI' },
	{ code: '1341022', name: 'JILINSHENG HANTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1025390', name: 'JILINSHENG HONGNUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '2409048', name: 'JILINSHENG HUA\'ANG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '686235', name: 'JILINSHENG HUASHENG JIAODIAN JITUAN YOUXIAN GONGSI' },
	{ code: '2081862', name: 'JILINSHENG KAILUOWEI KEJI YOUXIAN GONGSI' },
	{ code: '1984184', name: 'JILINSHENG SHUNTIAN WEIYE SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1376273', name: 'JILINSHENG XIANGYUN KEJI YOUXIAN GONGSI' },
	{ code: '2338657', name: 'JILINSHENG XINTIANTIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1302865', name: 'JILINSHENG ZHENGGE TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1140448', name: 'JILINSHENG ZHENYUAN HANTONG KEJI YOUXIAN GONGSI' },
	{ code: '1647487', name: 'JILINSHENG ZHONGHAO DIANZI YOUXIAN GONGSI' },
	{ code: '1330957', name: 'JILINSHI BOHUI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '833106', name: 'JILINSHI CHUANYINGQU HUAXIN SHOUJI SHANGCHANG' },
	{ code: '564001', name: 'JILINSHI HENGLIAN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1275430', name: 'JILINSHI HONGWOQUAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '835394', name: 'JILINSHI JIANCHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1765439', name: 'JILINSHI JICEN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1174865', name: 'JILINSHI LINGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '672388', name: 'JILINSHI SANJI HONGDA KEJI YOUXIAN GONGSI' },
	{ code: '1492953', name: 'JILINSHI SHIDAI XIANFENG KEJI YOUXIAN GONGSI' },
	{ code: '1324291', name: 'JILINSHI WEIQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1275423', name: 'JINAN DINGHUA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1246168', name: 'JINAN FANGHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1320535', name: 'JINAN HENGZHENG WEIYE KEJI YOUXIAN GONGSI' },
	{ code: '572007', name: 'JINAN HUATONG WEIYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1398751', name: 'JINAN PAIMI YOUPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1334138', name: 'JINAN TAINENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1076020', name: 'JINCHANG GAOKE WANDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1983869', name: 'JINCHENGSHI DONGLIAN WENHUA FAZHAN YOUXIAN GONGSI' },
	{ code: '622947', name: 'JINCHENGSHI GAOYUAN XINXI CHANYE YOUXIAN GONGSI' },
	{ code: '2169617', name: 'JINCHENGSHI HENGYU JIAHUA KEJI YOUXIAN GONGSI' },
	{ code: '799760', name: 'JINCHENGSHI XINXIN HENGYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321249', name: 'JINCHENGSHI XINXIN HONGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '948210', name: 'JINCHENGSHI ZONGHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1426448', name: 'JINGCHUANXIAN LONGKAI DIANZI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1288737', name: 'JINGDEZHENSHI SANXI KEJI MAOYI YOUXIAN GONGSI' },
	{ code: '1984119', name: 'JINGDEZHENSHI ZENGHUI KEJI MAOYI YOUXIAN GONGSI' },
	{ code: '1339425', name: 'JINGDONG SHIJI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '697947', name: 'JINGHONG JINFENG KEJI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1231314', name: 'JINGHONG QIANYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1270117', name: 'JINGJIANGSHI WENJIE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2221377', name: 'JINGMEN DINGSHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1710719', name: 'JINGMEN SHUAIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1235127', name: 'JINGMENSHI SIDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '925713', name: 'JINGMENSHI XINXINZHOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '670476', name: 'JINGMENSHI XINZHOU DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1960292', name: 'JINGNINGXIAN JUYOU TONGXUN YOUXIAN GONGSI' },
	{ code: '1491596', name: 'JINGXI XINGBO MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1293259', name: 'JINGXIAN HUAYUN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1310138', name: 'JINGXIAN KEJUN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2002264', name: 'JINGXIAN RONGXIN TONGXUN JINGYINGBU' },
	{ code: '2377907', name: 'JINGXISHI JIFENG SHUMA TONGXUN JINGYINGBU' },
	{ code: '2171999', name: 'JINGYUANXIAN XINSHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '2377862', name: 'JINGYUXIAN WEILAI TONGXUN KEJICHENG' },
	{ code: '1270045', name: 'JINGZHOU PUBO KEJI YOUXIAN GONGSI' },
	{ code: '1302117', name: 'JINGZHOUSHI JINGHONG DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1811091', name: 'JINGZHOUSHI JINGXUN WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1287326', name: 'JINGZHOUSHI LONGTENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1306442', name: 'JINGZHOUSHI TONGCHUANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1381203', name: 'JINGZHOUSHI XINLITIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1740288', name: 'JINHU YANGGUANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2377233', name: 'JINHUA MINGDA TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1025458', name: 'JINHUA SHIWEITONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1279002', name: 'JINHUASHI FEIYU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1299321', name: 'JINHUASHI FENGXING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '900920', name: 'JINHUASHI TIANZHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1337464', name: 'JINHUASHI ZHENHUA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1809316', name: 'JINHUXIAN HENGXIN SHOUJIDIAN' },
	{ code: '1338020', name: 'JINING DINGHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1341145', name: 'JINING HUANUOWEI JINGMAO YOUXIAN GONGSI' },
	{ code: '1235023', name: 'JINING JINYI XINXI KEJI YOUXIAN GONGSI' },
	{ code: '648163', name: 'JINING JIULONG GUIHE SHANGMAO JITUAN YOUXIAN GONGSI' },
	{ code: '1808963', name: 'JINING QIKEXING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1279769', name: 'JINING ZHONGLIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '833114', name: 'JININGSHI TIANCE DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1330167', name: 'JININGSHI XINSHENGCHUANG SHANGMAO GUFEN YOUXIAN GONGSI' },
	{ code: '1127473', name: 'JININGSHI YANZHOUQU DONGTENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '682666', name: 'JININGSHI YUANTAI MAOYI YOUXIAN GONGSI' },
	{ code: '1779889', name: 'JINJIANG TIANRI TONGXIN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '2283140', name: 'JINJIANGSHI CHENDAIZHEN ZHITONG TIANXIA SHOUJIDIAN' },
	{ code: '1339419', name: 'JINNING ZHENFENG DIANZI JINGMAO YOUXIAN GONGSI' },
	{ code: '1958557', name: 'JINTANGXIAN ZHAOZHEN TENGLONG TONGXUNDIAN' },
	{ code: '1046121', name: 'JINTANSHI TAIHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1341710', name: 'JINWANQU YUDA TONGXIN SHEBEIHANG' },
	{ code: '1192791', name: 'JINXIANGXIAN JINDIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1512494', name: 'JINZHAI YIXIN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1306664', name: 'JINZHONG SANMUFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '901118', name: 'JINZHOU FEINUO TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1217921', name: 'JINZHOU MAOKE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1791414', name: 'JINZHOU SHUOJIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2784305', name: 'JINZHOU TIANZHIHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1550095', name: 'JINZHOU TUODA XINYUE SHANGMAO YOUXIAN GONGSI' },
	{ code: '707382', name: 'JINZHOU XINJIN SHUODE JINGMAO YOUXIAN GONGSI' },
	{ code: '1634670', name: 'JINZHOU XINYULOU BAIHUO YOUXIAN GONGSI' },
	{ code: '1262998', name: 'JINZHOUSHI AIBO TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1314260', name: 'JISHOUSHI BOYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2712525', name: 'JISHOUSHI QIXING WEILAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2533085', name: 'JIUJIANG DAZHONGCHENG SHUMA TONGXIN YOUXIAN GONGSI' },
	{ code: '1249644', name: 'JIUJIANG DONGFANG YIDONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1413984', name: 'JIUJIANG DONGHE KEJI YOUXIAN GONGSI' },
	{ code: '1404590', name: 'JIUJIANG FEIXUN KEJI TONGXIN YOUXIAN GONGSI' },
	{ code: '1305654', name: 'JIUJIANG YITENG KEJI YOUXIAN GONGSI' },
	{ code: '1312072', name: 'JIUJIANG YONGHONG KEJI YOUXIAN GONGSI' },
	{ code: '1297916', name: 'JIUJIANGSHI DIXIN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1294528', name: 'JIUJIANGSHI JIAXIN KEJI YOUXIAN GONGSI' },
	{ code: '1283203', name: 'JIUJIANGSHI WOYUAN XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1264183', name: 'JIUJIANGSHI WUXING DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1417259', name: 'JIUJIANGSHI XINZHONGCHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '751849', name: 'JIUJIANGSHI YITONG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1078330', name: 'JIUQUAN BAIHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1263028', name: 'JIUQUANSHI CHENGXIN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1063909', name: 'JIUQUANSHI JINYI DIANZI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2079410', name: 'JIUTAIQU CHENGXI BEIDOU SHOUJI MAICHANG' },
	{ code: '1337471', name: 'JIUTAIQU LANTIAN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1542521', name: 'JIUTAIQU YIHAI LANTIAN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1311945', name: 'JIXI SANXING XINLINGZHI TONGXUN YOUXIAN GONGSI' },
	{ code: '1302963', name: 'JIXI ZHICHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2712128', name: 'JIXIANXIAN JIUXIN TONGXUN SHEBEI JINGXIAO YOUXIAN GONGSI' },
	{ code: '1240140', name: 'JIXIANXIAN XINSHENG TONGXUN QICAI JINGXIAO YOUXIAN ZEREN GONGSI' },
	{ code: '1342386', name: 'JIXISHI JIGUANQU GUANGYUAN PENGYU TONGXUN QICAI SHANGDIAN' },
	{ code: '1960566', name: 'JIXISHI JIGUANQU JINLIANG SHOUJI TIANTIAN TONGXUN QICAI SHANGDIAN' },
	{ code: '2126077', name: 'JIXISHI JIGUANQU XINGBO SHOUJIDIAN' },
	{ code: '1031812', name: 'JIXISHI JIGUANQU XINXIN TONGXUN QICAIDIAN' },
	{ code: '1786900', name: 'JIYUANSHI DONGCHENG WENDUO TONGXUN SHANGHANG' },
	{ code: '1252322', name: 'JIYUANSHI YUDA MAOYI YOUXIAN GONGSI' },
	{ code: '1798672', name: 'JIZEXIAN GUOXIN TONGXUN QICAI JINGYINGBU' },
	{ code: '2371943', name: 'JIZEXIAN HANGRUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2003241', name: 'JIZHOUQU YINGBIN DAJIE WENJIE TONGXUN QICAIDIAN' },
	{ code: '1207462', name: 'JIZHOUSHI WENJIE TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1290146', name: 'JUANCHENGXIAN HUIXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1614163', name: 'JULUXIAN JINSHANGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2003223', name: 'JULUXIAN TIANBAIYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1296139', name: 'JUNANXIAN HUAXUN DIANZI TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1813324', name: 'JUNLIANXIAN QINGYUN TONGXUNDIAN' },
	{ code: '1282091', name: 'JUNXIAN FEIHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1662940', name: 'JURONGSHI LONGFA DIANZI YOUXIAN GONGSI' },
	{ code: '1320387', name: 'JURONGSHI XINYIDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1366860', name: 'JUYEXIAN DONGFANG TONGXUN SHOUJI DAMAICHANG' },
	{ code: '1787872', name: 'JUYEXIAN WANGZHE TONGXUN SHOUJIDIAN' },
	{ code: '682645', name: 'KAIFENG CHANGYUAN MAOYI YOUXIAN GONGSI' },
	{ code: '2714605', name: 'KAIFENG DINGFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1284497', name: 'KAIFENG LIANXIN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1648908', name: 'KAIFENGSHI BEISITE WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1770120', name: 'KAIFENGSHI JIADEFU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1297835', name: 'KAIFENGSHI WEILAI YANGGUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1655005', name: 'KAILI JINGJI KAIFAQU YUXIN SHIJI MAOYI YOUXIAN GONGSI' },
	{ code: '1741215', name: 'KAILISHI XINHUANYA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1731188', name: 'KAIYANG HENGBO XINHANG MAOYI YOUXIAN GONGSI' },
	{ code: '2408080', name: 'KAIYANGXIAN CHENGYU TONGXUN QICAI JINGYINGBU' },
	{ code: '1819296', name: 'KAIYUANSHI KANGDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1790737', name: 'KAIYUANSHI YADONG SHOUJICHENG' },
	{ code: '2372389', name: 'KEDONGXIAN PUTAI SHOUJI MAICHANG' },
	{ code: '1492298', name: 'KEDONGXIAN ZHAOJIE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2371977', name: 'KEDONGXIAN ZHONGYOU PUTAI TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1314947', name: 'KEERQIN ZUOYI HOUQI QICAIHONG TONGXUN WANGLUO YOUXIAN GONGSI' },
	{ code: '1020521', name: 'KELAMAYI ZHIXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1488328', name: 'KESHIKETENGQI LEXUN KEJI YOUXIAN GONGSI' },
	{ code: '1819279', name: 'KEYOUZHONGQI HUALINGYIN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1816041', name: 'KEZUO ZHONGQI XINDA SHOUJI MAICHANG' },
	{ code: '1986738', name: 'KUAIYIGOU (BEIJING) KEJI YOUXIAN GONGSI' },
	{ code: '2377831', name: 'KUAKE (YICHANG) SHANGMAO YOUXIAN GONGSI' },
	{ code: '2072264', name: 'KUANCHENG KUANTONG TONGXUN QICAI JINGXIAOBU' },
	{ code: '1780627', name: 'KUANCHENG MANZU ZIZHIXIAN KUANTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1334264', name: 'KUCHE XUESONG DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '695302', name: 'KUERLE XINHONGYU TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1339450', name: 'KUNMING AOZHIPO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1269996', name: 'KUNMING BAILAOHUI DIANQI SHEBEI YOUXIAN GONGSI' },
	{ code: '1269979', name: 'KUNMING BIWEI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1286654', name: 'KUNMING DACAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1283308', name: 'KUNMING DIDING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1154787', name: 'KUNMING HAOZHIYANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1194189', name: 'KUNMING PUTAI XINXI CHANYE JISHU YOUXIAN GONGSI' },
	{ code: '1066187', name: 'KUNMING RUIERMA TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '896340', name: 'KUNMING SANMO KEJI YOUXIAN GONGSI' },
	{ code: '1312697', name: 'KUNMING TIANZENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1284545', name: 'KUNMING WEIXIN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1548824', name: 'KUNMING XINGLANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '682781', name: 'KUNMING XINGYAO GAOKEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1508454', name: 'KUNMING XINGYOUHUI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1476595', name: 'KUNMING XINHETONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1297858', name: 'KUNMING XUNMAO TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1382385', name: 'KUNMING YUANZHIXIANG KEJI YOUXIAN GONGSI' },
	{ code: '1595658', name: 'KUNMING YUEGAO KEJI YOUXIAN GONGSI' },
	{ code: '1486767', name: 'KUNMING ZHONGKE SHANGMAO YOUXIAN GONGSI' },
	{ code: '503246', name: 'KUNSHAN FUGANG DIANZI MAOYI YOUXIAN GONGSI' },
	{ code: '1771095', name: 'KUNSHAN LANYA SHIJIA TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1773989', name: 'KUNSHAN QUANTONG DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1812831', name: 'KUNSHAN XINTONGYU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1286640', name: 'KUNSHAN ZHIXING WANJIA TONGXUN YOUXIAN GONGSI' },
	{ code: '1305639', name: 'KUNSHANSHI HAOTIAN DIANZI YOUXIAN GONGSI' },
	{ code: '1578306', name: 'KUNSHANSHI JINSANJIAO JINGMAO YOUXIAN GONGSI' },
	{ code: '1301555', name: 'KUNSHANSHI XINGE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1695579', name: 'LAIBINSHI CHUANGYI TONGXUN YOUXIAN GONGSI' },
	{ code: '1779914', name: 'LAIBINSHI HAISHUO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1719559', name: 'LAIFENG HELI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2207740', name: 'LAISHUIXIAN RISHENGCHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1343085', name: 'LAIWU FENGCHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1243804', name: 'LAIWU XINYULOU BAIHUO YOUXIAN GONGSI' },
	{ code: '1719641', name: 'LAIWUSHI BEIDOU XINGTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1312051', name: 'LAIWUSHI JINFENGCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1811099', name: 'LAIWUSHI LAICHENGQU YUWEN SHOUJI GUANGCHANG' },
	{ code: '1688883', name: 'LAIYANG AITE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1785910', name: 'LAIYANGSHI CHENGXIANG YINUO SHOUJI DAMAICHANG' },
	{ code: '949460', name: 'LAIYANGSHI YINUO TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1393494', name: 'LAIYUANXIAN MEIXIN DIANZICHENG' },
	{ code: '1206133', name: 'LANE CRAWFORD DEPARTMENT STORE (CHENGDU) LTD.' },
	{ code: '1400803', name: 'LANGFANG TUYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1324290', name: 'LANGFANGSHI JIAYIN GUOTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '688033', name: 'LANGFANGSHI RUIDE SICHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1286724', name: 'LANGFANGSHI WANPENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1252312', name: 'LANGFANGSHI ZONGZHU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1510381', name: 'LANGXI JIANGLEI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1968548', name: 'LANGXIXIAN JIANPINGZHEN LEQI TONGXUN QICAIDIAN' },
	{ code: '1100293', name: 'LANGZHONG CHENGTAI TONGXUN YOUXIAN GONGSI' },
	{ code: '1816662', name: 'LANGZHONGSHI CHENGTAI TONGXUN QICAI JINGYINGBU' },
	{ code: '1288141', name: 'LANKAO JIEXUN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1261760', name: 'LANKAOXIAN WANHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1980591', name: 'LANLINGXIAN HENGTONG TONGXUN QICAI JINGYINGBU' },
	{ code: '1245753', name: 'LANLINGXIAN LIANGYA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1548827', name: 'LANSHANXIAN QINGFENG SHUMA TONGXIN MAOYI YOUXIAN GONGSI' },
	{ code: '1513286', name: 'LANXIAN YINUO TONGXUN YOUXIAN GONGSI' },
	{ code: '2066457', name: 'LANXISHI GUANGHAN TONGXUN QICAI JINGYINGBU' },
	{ code: '1658261', name: 'LANXIXIAN JIAXING SHOUJI DAMAICHANG' },
	{ code: '1310193', name: 'LANZHOU AIBODE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1548474', name: 'LANZHOU FENGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1764290', name: 'LANZHOU HENGFENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1332690', name: 'LANZHOU JIARUN TONGXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1528637', name: 'LANZHOU XINFENGYUAN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '564589', name: 'LANZHOU YUEDA TONGXUN YOUXIAN GONGSI' },
	{ code: '1514552', name: 'LAOHEKOU YIYANG DIANZI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1720668', name: 'LAOLINGSHI YOULIAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1791341', name: 'LAOTINGXIAN CHENGQU QIYU SHOUJIDIAN' },
	{ code: '1293894', name: 'LAOTINGXIAN HAITAO TONGXUNBU' },
	{ code: '2648205', name: 'LASA HONGKAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '631484', name: 'LASA XINGYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1389516', name: 'LECHANGSHI RUILIAN DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '2456125', name: 'LEPINGSHI SHANGFENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2469705', name: 'LEPINGSHI TALANG TONGXUN GUANGCHANG' },
	{ code: '1273878', name: 'LESHAN XUNGE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1280277', name: 'LESHAN YINGJIE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '682141', name: 'LESHANSHI DAXINTONG SHOUJI XIAOSHOU LIANSUO YOUXIAN GONGSI' },
	{ code: '1549716', name: 'LESHANSHI TIANLANG DIANQI MAOYI YOUXIAN GONGSI' },
	{ code: '1739854', name: 'LETINGXIAN LONGLI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1294545', name: 'LIANGCHENGXIAN XINYUAN MAOYI YOUXIAN GONGSI' },
	{ code: '1787879', name: 'LIANGPINGQU QIWEI TONGXUN QICAI JINGYINGBU' },
	{ code: '1268552', name: 'LIANGPINGXIAN WANFENG TONGXIN YOUXIAN GONGSI' },
	{ code: '1659753', name: 'LIANGSHAN HENGLONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '695327', name: 'LIANGSHANZHOU LEIDA TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1790236', name: 'LIANGZHOUQU CHANGSHENG TONGXUNBU' },
	{ code: '1236352', name: 'LIANHUAXIAN XIANGFENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1738859', name: 'LIANJIANGSHI DINGSHENG MAOYI YOUXIAN GONGSI' },
	{ code: '2374376', name: 'LIANYUANSHI ZHENXIN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1301462', name: 'LIANYUNGANG BAOJIA JIADIAN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2369767', name: 'LIANYUNGANG HUASHITONG XIEZHONG MAOYI YOUXIAN GONGSI' },
	{ code: '1759677', name: 'LIANYUNGANG MINGCHUANG ZHIYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1494530', name: 'LIANYUNGANG TIANFENG TONGXIN YOUXIAN GONGSI' },
	{ code: '1461221', name: 'LIANYUNGANG WANWEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1232100', name: 'LIANYUNGANG YINHE TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '693444', name: 'LIANYUNGANGSHI CHANGYUAN DIANXUN QICAI YOUXIAN GONGSI' },
	{ code: '2059744', name: 'LIANYUNGANGSHI QIANYI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1251829', name: 'LIANYUNGANGSHI XIEZHONG JINGMAO YOUXIAN GONGSI' },
	{ code: '1337487', name: 'LIANYUNGANGSHI XINCHANGYUAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1121049', name: 'LIANYUNGANGSHI XINHUADE DIANZI YOUXIAN GONGSI' },
	{ code: '1284568', name: 'LIANZHOUSHI HEJI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1286641', name: 'LIAOCHENG SIHAITONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1965656', name: 'LIAOCHENGSHI KEDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '901556', name: 'LIAOCHENGSHI XIANGYUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1696146', name: 'LIAONING BAIWEI BORUI MAOYI YOUXIAN GONGSI' },
	{ code: '2169897', name: 'LIAONING BOYUAN JINGMAO FAZHAN YOUXIAN GONGSI' },
	{ code: '563506', name: 'LIAONING CHONGSEN YIHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321912', name: 'LIAONING HAOLIAN ZHONGFAN SHANGMAO LIANSUO QIYE JINGYING GUANLI YOUXIAN GONGSI' },
	{ code: '1270061', name: 'LIAONING HUANENG DIANXUN YOUXIAN GONGSI' },
	{ code: '1005518', name: 'LIAONING HUIRI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '901562', name: 'LIAONING KAILONG TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1818722', name: 'LIAONING KEJIANXIN TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1440191', name: 'LIAONING SHENXIN SHIDA TONGXIN DIANZI YOUXIAN GONGSI' },
	{ code: '1183829', name: 'LIAONING TAIXING JIAHE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1961262', name: 'LIAONING WANBAOXIN SHANGWU XINXI ZIXUN YOUXIAN GONGSI' },
	{ code: '1784151', name: 'LIAONING XIANGLONGMAO GUOJI MAOYI YOUXIAN GONGSI' },
	{ code: '2531085', name: 'LIAONING YIPIN QUANCHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '543028', name: 'LIAONING ZHONGYOU PUTAI YIDONG TONGXIN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1036140', name: 'LIAOYANG HENGTONG KEJI YOUXIAN GONGSI' },
	{ code: '2710205', name: 'LIAOYANG LONGYUAN TONGXUN FUWU YOUXIAN GONGSI' },
	{ code: '2035542', name: 'LIAOYUANSHI LONG\'AN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1685357', name: 'LIAOYUANSHI MINGJI JINGMAO YOUXIAN GONGSI' },
	{ code: '2064362', name: 'LIAOYUANSHI TIANTIAN SHOUJI SHANGMAOCHENG YOUXIAN GONGSI' },
	{ code: '1338045', name: 'LIAOYUANSHI XINDA SHUMA GUANGCHANG YOUXIAN GONGSI' },
	{ code: '2060823', name: 'LICHUANSHI XUNFEI KEJI YOUXIAN GONGSI' },
	{ code: '1971971', name: 'LICHUANXIAN HUIKELONG SHOUJIDIAN' },
	{ code: '1263520', name: 'LIJIANG HONGFA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1720888', name: 'LIJIANG TIANREN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1167309', name: 'LIJIANG WEICHI QICHE WEIXIU FUWU YOUXIAN ZEREN GONGSI' },
	{ code: '792866', name: 'LIJIANG WEICHI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1693072', name: 'LIJIANG YITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1393475', name: 'LILINGSHI HAOLONG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1338051', name: 'LILINGSHI JIAXUN TONGXIN SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1720565', name: 'LIN’AN BOTIAN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1062613', name: 'LINCANG WANTONG SHIYE YOUXIAN GONGSI' },
	{ code: '1479236', name: 'LINCANG XINDA KEJI YOUXIAN GONGSI' },
	{ code: '1355848', name: 'LINCANGSHI YONGCHENG SHANGWU YOUXIAN GONGSI' },
	{ code: '1791352', name: 'LINCHENGXIAN LINCHENGZHEN CHENGXIN YIDONG SHOUJI SHANGCHENG' },
	{ code: '1595662', name: 'LINCHENGXIAN PUHUI TONGXIN SHEBEI JINGXIAO YOUXIAN GONGSI' },
	{ code: '1798680', name: 'LINDIANXIAN CHANGTONG WANGLUO TONGXUN QICAI SHANGDIAN' },
	{ code: '1275082', name: 'LINDIANXIAN DALEI SHOUJIDIAN' },
	{ code: '1316094', name: 'LINFEN DONGLIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '697997', name: 'LINFENSHI DIANLIAN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '2036109', name: 'LINFENSHI YAODUQU WANGXUNTONG TONGXUN QICAI JINGXIAOBU' },
	{ code: '1813622', name: 'LINFENSHI YAODUQU WANXIN TONGXUN QICAIDIAN' },
	{ code: '1787516', name: 'LINFENSHI YAODUQU XINDONGLIAN TONGXUN QICAI JINGXIAOBU' },
	{ code: '1167320', name: 'LINFENSHI YIXINDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1281996', name: 'LINGBAOSHI CHENGDE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1637131', name: 'LINGCHUAN KESHANG SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '1296720', name: 'LINGQIUXIAN HUAXIN TENGFEI TONGXIN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1708832', name: 'LINGQIUXIAN TUOLIAN DIANZI YOUXIAN GONGSI' },
	{ code: '1262324', name: 'LINGSHIXIAN SITONG DIANZI KEMAO YOUXIAN GONGSI' },
	{ code: '1974199', name: 'LINGSHIXIAN SITONGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2005326', name: 'LINGSHOUXIAN GUOLI TONGXUNBU' },
	{ code: '1969954', name: 'LINGTAIXIAN HUASHANG TONGXUNDIAN' },
	{ code: '1302986', name: 'LINGUIXIAN HUANGZHE TONGXUN' },
	{ code: '1341010', name: 'LINHAISHI XINLIN TONGXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1315546', name: 'LINJIANGSHI HUIDA JINGMAO YOUXIAN GONGSI' },
	{ code: '1538152', name: 'LINLIXIAN GUANRUI TONGXIN YOUXIAN GONGSI' },
	{ code: '1143627', name: 'LINQING SHUOBO DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1808550', name: 'LINQINGSHI GUANZHILIN SHOUJI DAMAICHANG' },
	{ code: '1302330', name: 'LINQUXIAN BAOTONG DIANQI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1393919', name: 'LINSHUXIAN CHENGTONG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1291817', name: 'LINXI JIUTIAN ZHUOXUN DIANZI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1181109', name: 'LINXIASHI DONGFANG KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1691207', name: 'LINXIXIAN HENGBO TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1248510', name: 'LINYI CHENGDE TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1353174', name: 'LINYI HUAXIA YONGCHANG SHUMA CHANPIN LIANSUO YOUXIAN GONGSI' },
	{ code: '671354', name: 'LINYI TIANYIN TONGXIN YOUXIAN GONGSI' },
	{ code: '1293273', name: 'LINYI XINYE SHANGSHA YOUXIAN GONGSI' },
	{ code: '1341058', name: 'LINYI YITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '840397', name: 'LINYISHI DATANG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '648212', name: 'LINYISHI JIULONG DIANXUN YOUXIAN GONGSI' },
	{ code: '1296150', name: 'LINYISHI SUNING DIANXUN YOUXIAN GONGSI FEIXIAN FENGONGSI' },
	{ code: '1648809', name: 'LINZHOUSHI MEINIANDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '2408640', name: 'LIQUAN QIHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1799747', name: 'LIQUAN TIANYI TONGXUN QICAI JINGXIAOBU' },
	{ code: '2171937', name: 'LISHUXIAN XINGZHIHAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1969886', name: 'LIULINXIAN TIANDIHE SHOUJI MAICHANG' },
	{ code: '1769795', name: 'LIUPANSHUI HANGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1286099', name: 'LIUPANSHUI YILIAN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1223886', name: 'LIUYANG DERONG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1252331', name: 'LIUYANG MEITENG KEJI YOUXIAN GONGSI' },
	{ code: '1484949', name: 'LIUYANGSHI HAOQI MAOYI YOUXIAN GONGSI' },
	{ code: '2681405', name: 'LIUYANGSHI ZHENYU KEJI JINGYINGBU' },
	{ code: '671720', name: 'LIUZHOUSHI DALI MAOYI YOUXIAN GONGSI' },
	{ code: '1695575', name: 'LIUZHOUSHI GUDUO TONGXUN YOUXIAN GONGSI' },
	{ code: '1693395', name: 'LIUZHOUSHI HUIZHI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1338115', name: 'LIUZHOUSHI JIALIAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1290162', name: 'LIUZHOUSHI JINRI DIANXUN KEJI YOUXIAN GONGSI' },
	{ code: '1813608', name: 'LIUZHOUSHI TIANCHENG TONGXUN QICAI JINGYINGBU' },
	{ code: '1476596', name: 'LIUZHOUSHI YUFENGQU SHENXUN TONGXIN JINGYINGBU' },
	{ code: '2464107', name: 'LIXIAN KAIYOU DIANZI YOUXIAN GONGSI' },
	{ code: '2063270', name: 'LIXIAN XIANGZI TONGXUN MENSHIBU' },
	{ code: '953322', name: 'LIXIAN YIJIAYI DIANZICHENG' },
	{ code: '691423', name: 'LIYANG YUANDA TONGXIN FAZHAN YOUXIAN GONGSI' },
	{ code: '1660732', name: 'LIYANGSHI DINGTIAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1369013', name: 'LONGCHANG DAOXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1399382', name: 'LONGHAISHI SHIMA XINSANYA SHEBEI SHANGHANG' },
	{ code: '1666546', name: 'LONGHUAXIAN YITONG DIANZI TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '2372393', name: 'LONGJIANGXIAN JINMA WANGZI SHOUJI MAICHANG' },
	{ code: '2000610', name: 'LONGKOU TONGTIANDI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2066471', name: 'LONGKOUSHI LONGGANG ZHUOYUE SHOUJIDIAN' },
	{ code: '672398', name: 'LONGKOUSHI TONGTIANDI JINGMAO YOUXIAN GONGSI' },
	{ code: '1710377', name: 'LONGKOUSHI XINGUOMEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1696181', name: 'LONGLIN GUOGUO TONGXUNDIAN' },
	{ code: '1282009', name: 'LONGNAN GUOMEI TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1601280', name: 'LONGNAN QIANYUAN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2077279', name: 'LONGNAN TIANBO KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1799759', name: 'LONGNANXIAN SHUIJINGGONG SHOUJI SHANGCHANG' },
	{ code: '1320608', name: 'LONGSHAN XIANGYOU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1273943', name: 'LONGXI HUATAI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1815726', name: 'LONGXI HUAXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1513911', name: 'LONGXIAN XUNDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1336636', name: 'LONGYAN LIANXIN KEJI YOUXIAN GONGSI' },
	{ code: '1655353', name: 'LONGYANSHI JINDONG MAOYI YOUXIAN GONGSI' },
	{ code: '1560980', name: 'LONGYANSHI LIANYI MAOYI YOUXIAN GONGSI' },
	{ code: '1399359', name: 'LONGYANSHI RENGUI DIANZI YOUXIAN GONGSI' },
	{ code: '1341159', name: 'LONGYANSHI XINLUOQU ZHONGYITONG DIANXUN YOUXIAN GONGSI' },
	{ code: '1284585', name: 'LONGYANSHI ZHENHUA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1416790', name: 'LOUDI QIANGLIAN KEJI YOUXIAN GONGSI' },
	{ code: '886289', name: 'LOUDISHI DINGYI KEJI YOUXIAN GONGSI' },
	{ code: '2263817', name: 'LOUDISHI XINGCAN MAOYI YOUXIAN GONGSI' },
	{ code: '1025431', name: 'LOUDISHI YONGXING DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1731880', name: 'LU\'ANSHI JUNKAI SHUMA XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1805252', name: 'LU\'ANSHI XINGMAO DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '1395187', name: 'LUAN JIUWUBA WUJIUBA DIANXUN YOUXIAN GONGSI' },
	{ code: '1284627', name: 'LUANCHUANXIAN XIANDAI DIANZI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1279788', name: 'LUANXIAN HONGFA TONGXUN DIANZI QICAI YOUXIAN GONGSI' },
	{ code: '2407500', name: 'LUFENG XINGXIN SHOUJI MAICHANG' },
	{ code: '1389544', name: 'LUFENGSHI GUIZHIGU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1179548', name: 'LULIANGXIAN SHITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1479252', name: 'LUOBEIXIAN FENGXIANGZHEN YIXIN TONGXUN SHANGDIAN' },
	{ code: '1284468', name: 'LUOCHUAN LONGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '2009005', name: 'LUOCHUANXIAN SHENGLUO ZHINENG SHENGTAIDIAN' },
	{ code: '1192804', name: 'LUODINGSHI QUANQIU TONGXUN YOUXIAN GONGSI' },
	{ code: '1235035', name: 'LUOHE LEYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2104258', name: 'LUOHESHI SHUNJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '690206', name: 'LUOHESHI WANBAO TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1637160', name: 'LUOHESHI WANDEBAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1790772', name: 'LUONINGXIAN CHENGQU YANGDONGLIANG SHOUJI YIDIAN' },
	{ code: '1235886', name: 'LUONINGXIAN MINGZHE ZHINENG TONGXIN SHANGHANG' },
	{ code: '1309476', name: 'LUOPINGXIAN HANBO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1578301', name: 'LUOSHANXIAN TIANGE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2003486', name: 'LUOSHANXIAN ZHONGYI SHOUJIDIAN' },
	{ code: '2008061', name: 'LUOTIANXIAN BOQIANG DIANXINCHENG' },
	{ code: '1593173', name: 'LUOTIANXIAN YITONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1257581', name: 'LUOYANG DONGLI TONGXIN YOUXIAN GONGSI' },
	{ code: '1796776', name: 'LUOYANG GUOYA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1018802', name: 'LUOYANG LECHANG DIANZI CHANPIN YOUXIAN GONGSI YANSHI FENGONGSI' },
	{ code: '1542524', name: 'LUOYANG XINFENG DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1777152', name: 'LUOYANG XUANHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1097429', name: 'LUOYANGSHI LONGHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1278334', name: 'LUOYANGSHI SANMAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1593172', name: 'LUSHANXIAN KAIDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1819278', name: 'LUSHUI WANDA TONGXUN' },
	{ code: '1341043', name: 'LUXIXIAN HENGSHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1337702', name: 'LUXIXIAN JIASHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1776858', name: 'LUYIXIAN WANDA TONGXUN YOUXIAN GONGSI' },
	{ code: '679771', name: 'LUZHOU FEISITE YIDONG DIANHUA XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2612585', name: 'LUZHOU JIAHAI DIANZI YOUXIAN GONGSI' },
	{ code: '672740', name: 'LUZHOU SHANGCHENG TONGXIN YOUXIAN GONGSI' },
	{ code: '698041', name: 'LUZHOU XUNJIE TONGXUN YOUXIAN GONGSI' },
	{ code: '1302807', name: 'LUZHOUSHI JIANGYANGQU HAIZHUO TONGXUN SHEBEI JINGYINGBU' },
	{ code: '1278960', name: 'LUZHOUSHI JIANGYANGQU HUITENG DIANNAO YOUXIAN GONGSI' },
	{ code: '1267120', name: 'LVLIANGSHI LIANCHUANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2392782', name: 'LVLIANGSHI LISHIQU AIFENG KEJI FUWUBU' },
	{ code: '1270563', name: 'LVLIANGSHI TIANDIHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '963760', name: 'LVLIANGSHI XUNJIE KEJI YOUXIAN GONGSI' },
	{ code: '2372897', name: 'MA\'ANSHAN LINXUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1395179', name: 'MAANSHAN BOCHEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1323544', name: 'MAANSHANSHI CHENGCHENG TONGXUN QICAI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1301429', name: 'MAANSHANSHI LIANXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1343608', name: 'MAANSHANSHI SANWEI TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1816052', name: 'MACHENGSHI DINGQI TONGXIN QIJIANDIAN' },
	{ code: '1280402', name: 'MACHENGSHI TIANHE TONGXIN YOUXIAN GONGSI' },
	{ code: '1819252', name: 'MAGUAN XINTAI TONGXINDIAN' },
	{ code: '1625241', name: 'MAGUANXIAN TAILI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1031893', name: 'MANZHOULI PING\'AN SHIYE YOUXIAN ZEREN GONGSI' },
	{ code: '1972077', name: 'MANZHOULISHI HUABIN SHOUJI DAMAICHANG' },
	{ code: '1782484', name: 'MAOMINGSHI DIANBAIQU ZHONGHUANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2000266', name: 'MAOMINGSHI TONGHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '600653', name: 'MAOMINGSHI TONGHUI DIANXIN QICAI YOUXIAN GONGSI' },
	{ code: '1255898', name: 'MEIHEKOUSHI HONGXINXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1819271', name: 'MEIHEKOUSHI JIAXIN BAITONG SHOUJI PEIJIAN SHANGDIAN' },
	{ code: '2104254', name: 'MEIHEKOUSHI JUTAI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '964582', name: 'MEISHANSHI XINYA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1187349', name: 'MEISHANSHI ZHAORUI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1816048', name: 'MEIZHOU CHENYANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1270123', name: 'MEIZHOUSHI JINJUN MAOYI YOUXIAN GONGSI YANGGUANG SHOUJI CHAOSHI' },
	{ code: '1358774', name: 'MEIZHOUSHI JIXIANG TONGXIN YOUXIAN GONGSI' },
	{ code: '1240056', name: 'MEIZHOUSHI MINGXIN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '949251', name: 'MEIZHOUSHI QICHANG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1994805', name: 'MEIZHOUSHI XINHANGYU TONGXIN YOUXIAN GONGSI' },
	{ code: '2216357', name: 'MENGCHENGXIAN YITONG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1560598', name: 'MENGCUN HUIZU ZIZHIXIAN LEYI TONGXUN YOUXIAN GONGSI' },
	{ code: '1404592', name: 'MENGLAXIAN DIXIONG TONGXUN' },
	{ code: '1739154', name: 'MENGLIAN SHIFENG MAOYI YOUXIAN GONGSI' },
	{ code: '2000256', name: 'MENGZI DAOXIN TONGXUN QICAIDIAN' },
	{ code: '1791300', name: 'MENGZI HAIWEI TONGXUN QICAI JINGYINGBU' },
	{ code: '667139', name: 'METRO JINJIANG CASH & CARRY CO., LTD.' },
	{ code: '1741558', name: 'MIANCHIXIAN JINHUI DIANZI YOUXIAN GONGSI' },
	{ code: '1809361', name: 'MIANCHIXIAN XINHUAJIE HENGTONG DIANZI TONGXUN QICAI MENSHI' },
	{ code: '1540086', name: 'MIANNINGXIAN YUNSHIDAI TONGXIN YOUXIAN GONGSI' },
	{ code: '1305072', name: 'MIANYANG CHENGQU XINGYULONG TONGXUN QICAI JINGYINGBU' },
	{ code: '889029', name: 'MIANYANG JIAHUA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1285982', name: 'MIANYANG LONGSHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '691415', name: 'MIANYANGSHI JIAHEWANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1489407', name: 'MIANYANGSHI JINMIANTONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1651618', name: 'MIANYANGSHI RENCHENG TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2394015', name: 'MIANYANGSHI WEIZHI KEJI FUWU YOUXIAN GONGSI' },
	{ code: '2293577', name: 'MIANYANGSHI XINXIN WANGZHE TONGXIN YOUXIAN GONGSI' },
	{ code: '1247911', name: 'MIANYANGSHI ZHANGUO SHIYE YOUXIAN ZEREN GONGSI' },
	{ code: '1666699', name: 'MIDUXIAN FEIYANG TONGXUN QICAI JINGYINGBU' },
	{ code: '1744634', name: 'MINQINXIAN TIANYING DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2474985', name: 'MINQUANXIAN HUICHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1280283', name: 'MINQUANXIAN XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2071666', name: 'MINXIAN JIALIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1318721', name: 'MIZHIXIAN XINZHIHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '698050', name: 'MUDANJIANGSHI NANFANG DINGXING DIANXUN YOUXIAN GONGSI' },
	{ code: '2267097', name: 'MUDANJIANGSHI TIANLIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1336606', name: 'MUDANJIANGSHI WANDA GUANGCHANG SHANGKE TONGXUN QICAI SHANGDIAN' },
	{ code: '1469206', name: 'MUDANJIANGSHI XUNTONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1708779', name: 'MULANXIAN TIANSU SHENGSHI KEJI SHANGDIAN' },
	{ code: '2650985', name: 'MULINGSHI HUABIN SHOUJI MAICHANG' },
	{ code: '1522603', name: 'MULINGSHI WEITAO TONGXUN YOUXIAN GONGSI' },
	{ code: '1685364', name: 'NAIMANQI YIXUNTONG SHUMA DIANZI YOUXIAN ZEREN GONGSI' },
	{ code: '1856341', name: 'NANBU BINJIANG BANSHICHU YINXUN TONGXUN HEZUO YINGYETING' },
	{ code: '574692', name: 'NANCHANG HONGCHENG DASHA SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1397512', name: 'NANCHANG LIJIAWEI MAOYI YOUXIAN GONGSI' },
	{ code: '1092423', name: 'NANCHANG XINLIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1566914', name: 'NANCHANG YASHENG KEJI YOUXIAN GONGSI' },
	{ code: '1298006', name: 'NANCHANGSHI FEIMING KEJI YOUXIAN GONGSI' },
	{ code: '1291818', name: 'NANCHANGSHI SIPING MAOYI YOUXIAN GONGSI' },
	{ code: '1302907', name: 'NANCHONG GUANGXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1296526', name: 'NANCHONG HONGXIANG TONGXIN GONGCHENG YOUXIAN GONGSI ANHAN FENGONGSI' },
	{ code: '1296540', name: 'NANCHONG HUANXIN ZHISHANG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '688018', name: 'NANCHONGSHI LIANCHUANG TONGXUN QICAI YOUXIANGONGSI' },
	{ code: '1176738', name: 'NANCHONGSHI YINXUN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2189360', name: 'NANGONGSHI XUNJIE TONGXUN QICAI MAOYI YOUXIAN GONGSI' },
	{ code: '1389428', name: 'NANJIAN QIHANG TONGXUNDIAN' },
	{ code: '1394053', name: 'NANJING AIGUO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2409156', name: 'NANJING BAIXIA SUNING YIGOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1772576', name: 'NANJING BOYIZE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '688015', name: 'NANJING CHIXIN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1297118', name: 'NANJING CHUNWANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1302784', name: 'NANJING FEIBIDA TONGXIN CHANPIN YOUXIAN GONGSI' },
	{ code: '1773855', name: 'NANJING FENJIN SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '1751147', name: 'NANJING HONGZHUTAI XINXI JISHU YOUXIAN GONGSI' },
	{ code: '2365857', name: 'NANJING HUIGOU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1760095', name: 'NANJING HUIXIANGLAI WANGLUO YOUXIAN GONGSI' },
	{ code: '2448905', name: 'NANJING KAXI TEMENG MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1774246', name: 'NANJING KUNSHIDA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1300932', name: 'NANJING LISHUI LINGCHAO DIANZI MAOYI ZHONGXIN' },
	{ code: '1633293', name: 'NANJING QITAO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2372003', name: 'NANJING QUANCHENG TONGXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1337478', name: 'NANJING RENHE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1645646', name: 'NANJING SHENZHOU SHUMA YOUXIAN GONGSI' },
	{ code: '1679066', name: 'NANJING SUNING DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1784546', name: 'NANJING TIANHUAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2377923', name: 'NANJING TIANLIN WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1748249', name: 'NANJING TIANYU HUITONG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '2062295', name: 'NANJING TIANYU JUZHI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1297112', name: 'NANJING WENBIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1751157', name: 'NANJING XUNSHENG TONGXIN CHANPIN YOUXIAN GONGSI' },
	{ code: '1732380', name: 'NANJING YAOU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2072720', name: 'NANJING YINGTONG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1240778', name: 'NANJING YUMING KEJI YOUXIAN GONGSI' },
	{ code: '1065443', name: 'NANJING YUNTIAN SHUMA KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '1770509', name: 'NANJING YUTONG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1054629', name: 'NANJING ZHONGFUDA DIANZI TONGXIN JISHU YOUXIAN GONGSI KUNSHAN FENGONGSI' },
	{ code: '1312079', name: 'NANJINGXIAN XINSHUNDA MAOYI YOUXIAN GONGSI' },
	{ code: '1754511', name: 'NANLEXIAN YAXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1764691', name: 'NANNING JIMAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1258503', name: 'NANNING LIAOSONG LINFENG TOUZI YOUXIAN GONGSI' },
	{ code: '1389522', name: 'NANNING MEIWO TONGXUN CHANPIN YOUXIAN GONGSI' },
	{ code: '264644', name: 'NANNING RISHANG ELECTRONICS TRADE CO.,LTD.' },
	{ code: '1601273', name: 'NANNING SHENGXINZHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '695342', name: 'NANNING SIYIHAI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1259094', name: 'NANNING XINKU TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1306208', name: 'NANNINGSHI BAIDI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '690430', name: 'NANNINGSHI CHANGDIAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1790249', name: 'NANNINGSHI GANXIE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1342443', name: 'NANNINGSHI HUIHAO TONGXUN QICAI JINGYINGBU' },
	{ code: '1992172', name: 'NANNINGSHI LELIAN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1244029', name: 'NANNINGSHI LINLI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1478327', name: 'NANNINGSHI MINHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1282656', name: 'NANNINGSHI TIANYE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '548943', name: 'NANNINGSHI WANGZHE TONGXUN GUFEN YOUXIAN GONGSI' },
	{ code: '1293395', name: 'NANNINGSHI WUMINGQU DAYU TONGXUN YOUXIAN GONGSI' },
	{ code: '921049', name: 'NANNINGSHI YUEZHI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1699439', name: 'NANPING KUDI SHUMA YOUXIAN GONGSI' },
	{ code: '927433', name: 'NANPINGSHI JINCHANGCHENG DIANZI QICAI YOUXIAN GONGSI' },
	{ code: '1395188', name: 'NANPINGSHI XINZHONGYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2397301', name: 'NANPINGSHI YIDONG TONGXIN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1654998', name: 'NANPINGSHI ZHONGYU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1760050', name: 'NANTONG CHUANGLI MAOYI YOUXIAN GONGSI' },
	{ code: '1801039', name: 'NANTONG HECAI MAOYI YOUXIAN GONGSI' },
	{ code: '1475852', name: 'NANTONG JUXIANG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1992697', name: 'NANTONG XUANYIN DIANSHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1278986', name: 'NANTONG YUDAO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1739568', name: 'NANTONG ZHIWEI SHUMA YOUXIAN GONGSI' },
	{ code: '1995959', name: 'NANTONGSHI TONGZHOUQU LONGXIN TONGXIN SHEBEI MAOYI YOUXIAN GONGSI' },
	{ code: '1252278', name: 'NANTONGSHI XINSANLIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1321123', name: 'NANXIAN LIANCHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1745549', name: 'NANYANG CHENXING KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1293342', name: 'NANYANG YIXUN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '686227', name: 'NANYANGSHI HENGSHENG DIANXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1777170', name: 'NANYANGSHI SANHAO TONGXUN YOUXIAN GONGSI' },
	{ code: '2108978', name: 'NANYANGSHI WANDELONG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1404511', name: 'NANYANGSHI XUNLIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1298022', name: 'NANZHANGXIAN DADONG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1760108', name: 'NANZHAOXIAN HAOTIAN TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '2428745', name: 'NANZHAOXIAN MINGZHE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2221637', name: 'NEHESHI KUYUE SHOUJI SHANGDIAN' },
	{ code: '1745468', name: 'NEIHUANG ZHIZUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1813545', name: 'NEIJIANG CHENGTAI TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '1624372', name: 'NEIJIANG CHENGTAI TOUZI FAZHAN YOUXIAN GONGSI' },
	{ code: '899759', name: 'NEIJIANGSHI DAXINTONG SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1318683', name: 'NEIJIANGSHI ZHONGQU YOUER TONGXIN SHEBEI JINGYINGBU' },
	{ code: '1511037', name: 'NEIMENGGU BEICHEN YINSHUA WUZI YOUXIAN GONGSI' },
	{ code: '1343046', name: 'NEIMENGGU HUITENG KAIYAO XINXI XITONG JICHENG YOUXIAN GONGSI' },
	{ code: '1819151', name: 'NEIMENGGU LAN\'AO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1312750', name: 'NEIMENGGU LANHAI DIANXUN YOUXIAN ZEREN GONGSI' },
	{ code: '616913', name: 'NEIMENGGU LIANJIE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '616438', name: 'NEIMENGGU PUTIAN XINXI CHANYE YOUXIAN GONGSI' },
	{ code: '1760112', name: 'NEIMENGGU QIANZHAN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2009658', name: 'NEIMENGGU XINYUAN KEJI YOUXIAN GONGSI' },
	{ code: '1159251', name: 'NEIMENGGU XUNYI XINXI JISHU YOUXIAN GONGSI' },
	{ code: '2008072', name: 'NEIMENGGU YILE WULIAN KEJI YOUXIAN GONGSI' },
	{ code: '1731888', name: 'NEIMENGGU YOUFENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1493547', name: 'NEIQIUXIAN BOHANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1761879', name: 'NEIQIUXIAN BOREN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1240847', name: 'NENJIANGXIAN ZHONGYOU PUTAI SHOUJI MAICHANG' },
	{ code: '1544828', name: 'NIKE SPORTS (CHINA) CO., LTD.' },
	{ code: '1093886', name: 'NING\'ANSHI NING\'ANZHEN MINGREN SHOUJI DAMAICHANG' },
	{ code: '2050829', name: 'NING\'ER TIANSHENG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '1297826', name: 'NINGBO BEILUN JIAZHIBO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1801405', name: 'NINGBO BEILUN WEIEN KUAIJIE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1332671', name: 'NINGBO BEILUNQU SHUNHUANG MAOYI YOUXIAN GONGSI' },
	{ code: '1737244', name: 'NINGBO HAOYUAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1310208', name: 'NINGBO JIFENG SHOUJI YIZU TONGXUN YOUXIAN GONGSI' },
	{ code: '2089783', name: 'NINGBO JIUWUBA XINHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1025852', name: 'NINGBO JIUWUBA XINNUO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1489408', name: 'NINGBO KEHAI YICHANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1644597', name: 'NINGBO KULE CHAOWAN SHANGMAO FAZHAN YOUXIAN GONGSI' },
	{ code: '1345092', name: 'NINGBO LANGJUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1275479', name: 'NINGBO QUANXIANTONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1695558', name: 'NINGBO TONGXIN JIUWUBA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1805228', name: 'NINGBO YIKA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1737240', name: 'NINGBO YIMENG SHUMA DIANZI YOUXIAN GONGSI' },
	{ code: '2089749', name: 'NINGBOSHI GUANGDA SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '753347', name: 'NINGBOSHI XINGJITONG YIDONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1744491', name: 'NINGBOSHI XINYIQILAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1314784', name: 'NINGBOSHI YINZHOU WANHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1194699', name: 'NINGBOSHI YINZHOU ZHENGDE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1305139', name: 'NINGBOSHI ZHENHAI CHENGHE TONGXIN SHEBEI JINGYINGBU' },
	{ code: '1731473', name: 'NINGDESHI DONGQIAO JINGJI KAIFAQU CHUANGBO TONGXUN YOUXIAN GONGSI' },
	{ code: '1233615', name: 'NINGDESHI HAIFENGTONG NONGYE FAZHAN YOUXIAN GONGSI' },
	{ code: '1402062', name: 'NINGDESHI KEDI TONGXUN YOUXIAN GONGSI NINGDE DIYI JINGYINGBU' },
	{ code: '1360063', name: 'NINGDESHI LIANHE WANZHONG TONGXUN MAOYI YOUXIAN GONGSI' },
	{ code: '940926', name: 'NINGDESHI YINHAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1776548', name: 'NINGDUXIAN YIFANG KEJI YOUXIAN GONGSI' },
	{ code: '1795079', name: 'NINGGUOSHI SANHE YUANDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '2089794', name: 'NINGHAI TONGXIN XIANGHE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1790761', name: 'NINGHAIXIAN DATANG JIAYONG DIANQI SHANGCHANG' },
	{ code: '1342501', name: 'NINGHAIXIAN DATANG WANRUN TONGXIN YOUXIAN GONGSI' },
	{ code: '2456528', name: 'NINGJIN DINGHUA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1469201', name: 'NINGJINXIAN HUAJIA TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2067080', name: 'NINGJINXIAN SHUNDA TONGXUN QICAI JINGXIAOBU' },
	{ code: '1336697', name: 'NINGJINXIAN TENGJIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1455903', name: 'NINGQIANGXIAN FEIXIANG SHOUJI TONGXUNCHENG' },
	{ code: '2714545', name: 'NINGQIANGXIAN FEIXIANG ZHICHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1209584', name: 'NINGWUXIAN LITIAN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1297710', name: 'NINGXIA DEHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1300999', name: 'NINGXIA GUANMING TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '2366757', name: 'NINGXIA LANSENLIN TONGXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1284469', name: 'NINGXIA LIANSHENG SHIDAI KEMAO YOUXIAN GONGSI' },
	{ code: '2349517', name: 'NINGXIA SHENGRONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1329009', name: 'NINGXIA SHENYUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1455852', name: 'NINGXIA SIYING HULIAN DIANZI YOUXIAN GONGSI' },
	{ code: '671356', name: 'NINGXIA XIANDAI TONGXIN YOUXIAN GONGSI' },
	{ code: '1994865', name: 'NINGXIA YANGFAN QIHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1948849', name: 'NINGXIA YIHEXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1719593', name: 'NINGXIA ZHICHENG YONGHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321641', name: 'NINGYUANXIAN HENGBO DIANZI YOUXIAN GONGSI' },
	{ code: '1802176', name: 'NINGYUANXIAN XIEHENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1341109', name: 'PAN\'ANXIAN XUQIJIA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1300756', name: 'PAN\'ANXIAN YIFU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1259526', name: 'PANJIN HENGTAIXIN SHANGMAO GUFEN YOUXIAN GONGSI' },
	{ code: '1278318', name: 'PANJIN SHUANGTAIZIQU XUNTONG TONGXUN QICAI JINGXIAOCHU' },
	{ code: '1338043', name: 'PANJIN TIANTUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '840109', name: 'PANJIN TONGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '799892', name: 'PANJIN TONGYOU KEGONGMAO YOUXIAN GONGSI' },
	{ code: '1739546', name: 'PANJIN XURI WEIYE DIANNAO YOUXIAN GONGSI' },
	{ code: '1054885', name: 'PANSHISHI DONGNINGJIE HUIMIN TONGXUN SHANGDIAN' },
	{ code: '1960554', name: 'PANSHISHI WANTONG KEJI YOUXIAN GONGSI' },
	{ code: '1070484', name: 'PANZHIHUA JIAHAI DIANZI YOUXIAN GONGSI' },
	{ code: '1312821', name: 'PEIXIAN JIAHE DIANXUN YOUXIAN GONGSI' },
	{ code: '1651632', name: 'PENG\'AN MEIRI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1540090', name: 'PENGSHUIXIAN CHANGSHENG DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '2409079', name: 'PENGYANGXIAN SHANGZHI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1539379', name: 'PING AN HEALTH INSURANCE COMPANY,LTD.' },
	{ code: '1813303', name: 'PINGCHANGXIAN CHANGCHENG WANGZHE TONGXUN JINGYINGBU' },
	{ code: '1474941', name: 'PINGCHANGXIAN CHANGYUAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '678752', name: 'PINGDINGSHANSHI DIXINTONG DIANZI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1283964', name: 'PINGDINGSHANSHI DONGFANG SHENGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '843276', name: 'PINGDINGSHANSHI PINGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1252301', name: 'PINGDINGSHANSHI TIANYI XIAOHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1293173', name: 'PINGDINGSHANSHI YIFEI TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1769748', name: 'PINGDINGSHANSHI YUXUN TONGXUN YOUXIAN GONGSI' },
	{ code: '1550102', name: 'PINGDINGSHANSHI ZHONGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1508742', name: 'PINGDINGXIAN FEIXIANG KEMAO FAZHAN YOUXIAN GONGSI' },
	{ code: '2501205', name: 'PINGGUO WANGYU TONGXUNDIAN' },
	{ code: '1219392', name: 'PINGGUO WANGZHE TONGXUN QIJIANDIAN' },
	{ code: '1819264', name: 'PINGGUOXIAN WANGZHE TONGXUN ZHIZUNDIAN' },
	{ code: '2305837', name: 'PINGJIANGXIAN HENGHUI MAOYI YOUXIAN GONGSI' },
	{ code: '1764286', name: 'PINGLIANG BAFANG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1005511', name: 'PINGLIANGSHI HUAYU SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '839578', name: 'PINGLIANGSHI TIANDA DIANXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1578307', name: 'PINGLUOXIAN RUIXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1288670', name: 'PINGNANXIAN LIANZHI TONGXUN YOUXIAN GONGSI' },
	{ code: '1314388', name: 'PINGNANXIAN SHIJI TONGXUN YOUXIAN GONGSI' },
	{ code: '2265780', name: 'PINGNANXIAN SHIJIETONG SHOUJICHENG' },
	{ code: '1550099', name: 'PINGSHANXIAN GUOXUN SHANGRUI DIANQI YOUXIAN GONGSI' },
	{ code: '2035490', name: 'PINGSHANXIAN HESHUN TONGXUNBU' },
	{ code: '1284567', name: 'PINGSHANXIAN WANSHUN TONGXIN FUWUBU' },
	{ code: '1969976', name: 'PINGTANGXIAN LIHUA TONGXUNDIAN' },
	{ code: '1560606', name: 'PINGWUXIAN LONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1160191', name: 'PINGXIANGSHI BIGAO DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1305211', name: 'PINGXIANGSHI JINPENG YULE XIUXIAN YOUXIAN GONGSI' },
	{ code: '1018782', name: 'PINGXIANGSHI TALANG DIANXUN QICAIDIAN' },
	{ code: '1283101', name: 'PINGXIANGXIAN RICHU TONGXUN JINGYINGBU' },
	{ code: '1819295', name: 'PINGXIANGXIAN XIAOZHI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1774270', name: 'PINGYANGXIAN ZHAOYANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1790182', name: 'PINGYIXIAN JINGDONG SHOUJI DIANNAO DAMAICHANG' },
	{ code: '1305825', name: 'PINGYIXIAN TIANQIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1651637', name: 'PIZHOU SHISHANG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1316188', name: 'POYANGXIAN HONGBO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1975635', name: 'POYANGXIAN SHIDAI TONGXUN DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1997956', name: 'PUCHENGXIAN CHENHUI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1163322', name: 'PUCHENGXIAN FENGHONG MAOYI YOUXIAN GONGSI' },
	{ code: '1393571', name: 'PUDING BAIHUI KEJI MAOYI YOUXIAN GONGSI' },
	{ code: '690402', name: 'PUJIANG DAREN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1299232', name: 'PUJIANG SHENGTIAN GONGMAO YOUXIAN GONGSI' },
	{ code: '2057657', name: 'PUNINGSHI JIAQIHENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1310136', name: 'PUTIANSHI DANANFANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1301502', name: 'PUTIANSHI LESIMAI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '799742', name: 'PUTIANSHI LIANCHUANG MAOYI YOUXIAN GONGSI' },
	{ code: '1740278', name: 'PUTIANSHI TIANSHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '2051090', name: 'PUXIAN KEFENGLING TONGXUN YOUXIAN GONGSI' },
	{ code: '1314929', name: 'PUYANGSHI DONGRAN WUZI YOUXIAN GONGSI' },
	{ code: '1235063', name: 'PUYANGSHI TAILI KEMAO YOUXIAN GONGSI' },
	{ code: '1343511', name: 'PUYANGSHI TIANYI XINGKONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2267217', name: 'PUYANGSHI TONGYANG SHUMA SHEYING QICAI YOUXIAN GONGSI' },
	{ code: '1314962', name: 'PUYANGSHI WANSHENG KEMAO YOUXIAN GONGSI' },
	{ code: '1321029', name: 'PUYANGSHI ZHINENG SHIDAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '963025', name: 'QIAN\'ANSHI QIAN\'ANZHEN LONGJIE SHOUJIDIAN' },
	{ code: '1782450', name: 'QIAN\'ANXIAN YUANHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '706908', name: 'QIANANSHI LIANQIANG LONGXUN SHOUJI JINGXIAOCHU' },
	{ code: '1984149', name: 'QIANDONGNANZHOU GUOMAO TONGXIN YOUXIAN GONGSI' },
	{ code: '1055519', name: 'QIANDONGNANZHOU XUNJIE TONGXUN YOUXIAN GONGSI' },
	{ code: '1025815', name: 'QIANJIANG KUDONG SHUMA SHEBEI YOUXIAN GONGSI' },
	{ code: '2036055', name: 'QIANNANZHOU LIHUA KEJI YOUXIAN GONGSI' },
	{ code: '1336559', name: 'QIANNANZHOU LIHUA TONGXUN YOUXIAN GONGSI' },
	{ code: '1495719', name: 'QIANSHANXIAN ZHONGYOU TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1966500', name: 'QIANWEI DAYAN TONGXUN QICAI JINGYINGBU' },
	{ code: '2066425', name: 'QIANWEI TIANLANG TONGXUN JINGYINGBU ERDIAN' },
	{ code: '1236456', name: 'QIANWEIXIAN KAIRUI MAOYI YOUXIAN GONGSI' },
	{ code: '1533243', name: 'QIANXI\'NANZHOU AISIDE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1404625', name: 'QIANXI\'NANZHOU XINJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1630239', name: 'QIANXI\'NANZHOU XIONGYING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1806224', name: 'QIANXIAN CHENGGUAN QIZHONG TONGXUN SHOUJI MAICHANG' },
	{ code: '1148554', name: 'QIANXINANZHOU DESHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1239549', name: 'QIANXIXIAN BEIJIXING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1290225', name: 'QICHUN SHICHUANG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1398214', name: 'QIDONG FANGZHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1666593', name: 'QIDONGSHI HENGXIN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1983443', name: 'QIDONGSHI JIDE TONGXUN QICAI JINGYINGBU' },
	{ code: '1302346', name: 'QIHEXIAN XUNJIE WANGLUO KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1404589', name: 'QIMEN GUANGYI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2008104', name: 'QIN\'ANXIAN HONGTAO TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2037343', name: 'QING\'AN XINSHIDAI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2407601', name: 'QING\'ANXIAN QINJIANAN SHOUJIDIAN' },
	{ code: '1128131', name: 'QING\'ANXIAN SHIDAI TONGXUN SHANGDIAN' },
	{ code: '1429810', name: 'QINGCHUANXIAN SHIDAI HUAYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1194041', name: 'QINGDAO ANYE TIANFANG SHANGWU YOUXIAN GONGSI' },
	{ code: '1306436', name: 'QINGDAO BAIYUEHUI SHUMA YOUXIAN GONGSI' },
	{ code: '1645668', name: 'QINGDAO BOWEN WEIYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1341782', name: 'QINGDAO CHUNFENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1785934', name: 'QINGDAO HEHE XINTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '792859', name: 'QINGDAO HEJIN ZHONGLIAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1262392', name: 'QINGDAO HERUITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1228521', name: 'QINGDAO HONGYUE WEIYE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1129722', name: 'QINGDAO JIAYUE ZHINENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1318676', name: 'QINGDAO RUICHENG ZHIYUAN MAOYI YOUXIAN GONGSI' },
	{ code: '1270081', name: 'QINGDAO RUNZE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2085992', name: 'QINGDAO SHEN\'AI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '648462', name: 'QINGDAO SHILONG GONGMAO YOUXIAN GONGSI' },
	{ code: '1290285', name: 'QINGDAO TIANFANG AIJIUJIU SHANGWU YOUXIAN GONGSI' },
	{ code: '540898', name: 'QINGDAO YUHENG DIANQI YOUXIAN GONGSI' },
	{ code: '1032737', name: 'QINGDAO YUJINCHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '939956', name: 'QINGDAO ZHONGYI SITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1518537', name: 'QINGFENG XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2767865', name: 'QINGHAI BINZHI DIANZI YOUXIAN GONGSI' },
	{ code: '2079852', name: 'QINGHAI JINMUZHI TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1288165', name: 'QINGHEXIAN QUANYU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1740330', name: 'QINGHEXIAN ZHIHE MAOYI YOUXIAN GONGSI' },
	{ code: '1818361', name: 'QINGHEXIAN ZHONGYU DIANXUN QICAI SHANGHANG' },
	{ code: '1515121', name: 'QINGSHENXIAN YUNYAO SHUMA YOUXIAN GONGSI' },
	{ code: '1393493', name: 'QINGTIANXIAN HONGDA ZHIBO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1706760', name: 'QINGXIAN HENGDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1560989', name: 'QINGYANG BOYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1272270', name: 'QINGYANG XINGYU SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1219381', name: 'QINGYANGXIAN SHENGXIANG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1294609', name: 'QINGYUAN YAOYING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2322598', name: 'QINGYUANSHI HONGJUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1284539', name: 'QINGYUANSHI TIANHONG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2408137', name: 'QINGZHENSHI XINGHUA YIDONG TONGXIN FENDIAN' },
	{ code: '1032469', name: 'QINGZHOU ZHONGJIN GONGMAO YOUXIAN GONGSI' },
	{ code: '1808673', name: 'QINGZHOUSHI JIUSHENG TONGXUN QICAI JINGYINGBU' },
	{ code: '1522444', name: 'QINHUANGDAO HEKAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1252337', name: 'QINHUANGDAO RUNJIU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321030', name: 'QINHUANGDAO SHENGLIAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '671538', name: 'QINHUANGDAO XIECHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1974443', name: 'QINYANGSHI HUAIFU ZHONGLU SHENGDA TONGXUN QICAI XIAOSHOUBU' },
	{ code: '1248537', name: 'QINYANGSHI XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1467823', name: 'QINYUANXIAN YANGGUANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1283800', name: 'QINZHOUSHI HENGFENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '670481', name: 'QIQIHAER HUAXING SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '563913', name: 'QIQIHAER NANGUO KEJI YOUXIAN GONGSI' },
	{ code: '1341156', name: 'QIQIHAERSHI SHIJI XINPENG JINGMAO YOUXIAN GONGSI' },
	{ code: '1265995', name: 'QIQIHAR QIXING KEJI YOUXIAN GONGSI' },
	{ code: '1021050', name: 'QITAIHESHI WANLIAN TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1770363', name: 'QIUXIAN YUEYU TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1745478', name: 'QIXIAN CHUANGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1549717', name: 'QIXIAN HONGKAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1045537', name: 'QIXIAN HUIXIAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1753459', name: 'QIXIAN XINXINGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1981528', name: 'QIXIASHI KU\'AI SHOUJIDIAN' },
	{ code: '1752358', name: 'QIXIASHI PINGSONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1993849', name: 'QIXINGGUANQU TENGLONG TONGXUN SHOUJI JINGYINGBU' },
	{ code: '1818354', name: 'QIYANGXIAN WUXI QUANSHUN SHOUJIDIAN' },
	{ code: '1286718', name: 'QUANGANG ZHONGXIN GONGYEQU YIXUN TONGXUN JINGYINGBU' },
	{ code: '2050801', name: 'QUANJIAO LIANSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1267244', name: 'QUANNAN YUANWANG TONGXIN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '783649', name: 'QUANZHOU HELI DIANZI YOUXIAN GONGSI' },
	{ code: '1294435', name: 'QUANZHOUSHI JIUJIU DIANXUN MAOYI YOUXIAN GONGSI' },
	{ code: '443309', name: 'QUANZHOUSHI LICHENGXIN SANGAO DIANNAO YOUXIAN GONGSI' },
	{ code: '1341065', name: 'QUANZHOUSHI MINGGANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1754506', name: 'QUFU DINGLI SHANGMAO YOUXIAN GONGSI' },
	{ code: '989239', name: 'QUFU GUANZHILIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1297859', name: 'QUJINGSHI GUOHUA KEJI YOUXIAN GONGSI' },
	{ code: '1293295', name: 'QUJINGSHI QILINQU RONGCHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1395177', name: 'QUJINGSHI QILINQU ZHIYE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '648139', name: 'QUJINGSHI QILINQU ZHONGXIN TONGXUN QICAI JISHU YOUXIAN GONGSI' },
	{ code: '2350037', name: 'QUWOXIAN HAORUI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2486025', name: 'QUYANGXIAN YUXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1731868', name: 'QUZHOU JIELU TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '2077289', name: 'QUZHOU SHUOHONG TONGXIN YOUXIAN GONGSI' },
	{ code: '1744439', name: 'QUZHOU YINYUAN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1337501', name: 'QUZHOUSHI XIDUODUO TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1753062', name: 'QUZHOUSHI XUNCHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1657990', name: 'QUZHOUSHI XUNLONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2071679', name: 'RAOYANGXIAN HAOXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1991457', name: 'RENHUAISHI HONGRUIDA TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1754566', name: 'RENHUAXIAN YUEDA DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '980061', name: 'RENQIUSHI HONGYAN TONGXUN QICAI JINGYINGBU' },
	{ code: '1808621', name: 'RENQIUSHI JIANGYAN QICAI JINGYINGBU' },
	{ code: '693377', name: 'RIZHAO DINGCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1301730', name: 'RIZHAO WANXIANG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1224756', name: 'RIZHAOSHI GUANGPU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '672399', name: 'RIZHAOSHI HENGTAI TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1343040', name: 'RONGCHENGXIAN LONGEN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '964660', name: 'RONGXIAN FENGDA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2501088', name: 'RONGXIAN WEIZHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '884718', name: 'RONGXIAN XINYANG TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1297703', name: 'RUDONG YIHUA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1980637', name: 'RUGAOSHI CHENGZHONG SHOUJI DAMAICHANG' },
	{ code: '1593167', name: 'RUGAOSHI TENGDA SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1307890', name: 'RUIANSHI FENGZE MAOYI YOUXIAN GONGSI' },
	{ code: '1307896', name: 'RUIANSHI HONGLIAN SHUMA TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1331234', name: 'RUIANSHI WANBO TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1739208', name: 'RUIANSHI YUZHOU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1274958', name: 'RUICHANGSHI JINGTONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1767681', name: 'RUICHANGSHI JINGXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1294616', name: 'RUICHENGXIAN YINGQIAN DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1341030', name: 'RUILISHI HUIFU TONGXUN YOUXIAN GONGSI' },
	{ code: '1266681', name: 'RUILISHI WANRUI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1791394', name: 'RUILISHI ZHISHENG TONGXUN QICAI JINGYINGBU' },
	{ code: '1195508', name: 'RUYANGXIAN YAGUANG KEJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1990897', name: 'RUYUAN YAOZU ZIZHIXIAN HAISEN TONGXUNDIAN' },
	{ code: '1791288', name: 'SANHESHI XUEDA TONGXUN SHEBEI XIAOSHOUBU' },
	{ code: '1285980', name: 'SANHESHI XUERUI KEJI TONGXUN YOUXIAN GONGSI' },
	{ code: '1719615', name: 'SANJIANGXIAN HEYI DIANZI KEJI MAOYI YOUXIAN GONGSI' },
	{ code: '841692', name: 'SANLIAN SHANGSHE GUFEN YOUXIAN GONGSI JINAN JIADIAN FENGONGSI' },
	{ code: '1042150', name: 'SANMENXIA HEZHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1739952', name: 'SANMENXIA YUANDONG TONGXIN YOUXIAN GONGSI' },
	{ code: '1275095', name: 'SANMENXIA ZHONGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1467879', name: 'SANMINGSHI HONGXING TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1341009', name: 'SANMINGSHI KEXUN MAOYI YOUXIAN GONGSI' },
	{ code: '896969', name: 'SANYA XINYANHAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1449117', name: 'SANYUAN DONGHUAN WEILAI TONGXUN QICAI JINGXIAOBU' },
	{ code: '1413126', name: 'SENHUAN (TIANJIN) SHANGMAO YOUXIAN GONGSI' },
	{ code: '274243', name: 'SHAANXI CELLSTAR RETAIL CHAIN CO.,LTD' },
	{ code: '2042392', name: 'SHAANXI DINGXINRUN KEJI YOUXIAN GONGSI' },
	{ code: '1294498', name: 'SHAANXI JIERUIDA DIANZI YOUXIAN GONGSI' },
	{ code: '1257918', name: 'SHAANXI JINGYU SHIYE YOUXIAN ZEREN GONGSI' },
	{ code: '1372711', name: 'SHAANXI MOFEISI DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '1336620', name: 'SHAANXI PUCHENG KAICHENGRUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '810223', name: 'SHAANXI SHIJI LONGTAI SHIYE YOUXIAN GONGSI' },
	{ code: '648511', name: 'SHAANXI SHIJITONG KEMAO YOUXIAN GONGSI' },
	{ code: '1533208', name: 'SHAANXI WUGONG KAIYUAN DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1985139', name: 'SHAANXI WUGONG YUFEI WENHUA TONGXUN YOUXIAN GONGSI' },
	{ code: '1260383', name: 'SHAANXI YUANJING SHANGMAO YOUXIAN GONGSI' },
	{ code: '2681465', name: 'SHAANXI ZHENGFANG MINGYI XINXI KEJI YOUXIAN GONGSI' },
	{ code: '648157', name: 'SHAANXI ZHONGREN TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '2408588', name: 'SHAANXI ZHONGTIAN MINGXU TONGXIN YOUXIAN GONGSI' },
	{ code: '1214295', name: 'SHAHESHI BIHAI TONGXUN JIANKANGJIE DIAN' },
	{ code: '1791299', name: 'SHAHESHI BIHAI TONGXUN JICHANGLUDIAN' },
	{ code: '2187257', name: 'SHANDONG AIJIA ZHINENG KEJI YOUXIAN GONGSI' },
	{ code: '886864', name: 'SHANDONG CHANGLONG DIANXUN YOUXIAN GONGSI' },
	{ code: '2382460', name: 'SHANDONG DEJUCAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '671719', name: 'SHANDONG DEZHOU BAIHUO DALOU (JITUAN) YOUXIAN ZEREN GONGSI' },
	{ code: '1647411', name: 'SHANDONG DINGDING HULIAN JINGMAO YOUXIAN GONGSI' },
	{ code: '1455823', name: 'SHANDONG DONG\'E XINRUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1808539', name: 'SHANDONG GUANXIAN XINWANHAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1481113', name: 'SHANDONG HENGZHENG WEIYE DIANZI YOUXIAN GONGSI' },
	{ code: '556399', name: 'SHANDONG HONGJI DIANZI YOUXIAN GONGSI' },
	{ code: '672396', name: 'SHANDONG HONGYUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1243798', name: 'SHANDONG JINGUANLIN MAOYI FAZHAN YOUXIAN GONGSI' },
	{ code: '1194141', name: 'SHANDONG LONGGUI SHANGMAO JITUAN YOUXIAN GONGSI' },
	{ code: '793027', name: 'SHANDONG LUBAI BAIHUO DALOU JITUAN DONGYING JIAYONG DIANQI YOUXIAN GONGSI' },
	{ code: '1283811', name: 'SHANDONG PAIMI TONGXIN GUANLI YOUXIAN GONGSI' },
	{ code: '667150', name: 'SHANDONG PENGYOU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '706862', name: 'SHANDONG SANFANG LINGHANG DIANZI JISHU YOUXIAN GONGSI' },
	{ code: '1751192', name: 'SHANDONG SANYUAN JIADIAN YOUXIAN GONGSI' },
	{ code: '678762', name: 'SHANDONG SHIJI HUATAI MAOYI YOUXIAN GONGSI' },
	{ code: '551849', name: 'SHANDONG XINDESAI MAOYI YOUXIAN GONGSI' },
	{ code: '1283794', name: 'SHANDONG XINSHUNSHENG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1337486', name: 'SHANDONG XINXING JITUAN YOUXIAN GONGSI' },
	{ code: '556507', name: 'SHANDONG YITONG TONGXIN FAZHAN YOUXIAN GONGSI' },
	{ code: '2073534', name: 'SHANDONG YIWEI SHUMA YOUXIAN GONGSI' },
	{ code: '559205', name: 'SHANDONG YIWEI XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1404608', name: 'SHANDONGSHENG QIXING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1744627', name: 'SHANDONGSHENG YUNCHENGXIAN HUACHUANG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1796916', name: 'SHANDONGSHENG ZIYAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2078096', name: 'SHANGCAIXIAN HENGYI JUNCHENG DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1779098', name: 'SHANGCAIXIAN HUISHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1615313', name: 'SHANGDUXIAN HONGYI TONGXUN YOUXIAN GONGSI' },
	{ code: '2189777', name: 'SHANGDUXIAN HONGYI TONGXUNBU' },
	{ code: '2110545', name: 'SHANGHAI BAIHUA YUEBANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1660039', name: 'SHANGHAI BAILIAN YUNSHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1475582', name: 'SHANGHAI BAISHIDE DIANZI YOUXIAN GONGSI' },
	{ code: '1971181', name: 'SHANGHAI CHANGPUSI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '849836', name: 'SHANGHAI CHUANDA TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1654666', name: 'SHANGHAI DIMEN MAOYI YOUXIAN GONGSI' },
	{ code: '2407586', name: 'SHANGHAI DIXIN DIANZI TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1279623', name: 'SHANGHAI FANDIAN SHIYE YOUXIAN GONGSI' },
	{ code: '1302939', name: 'SHANGHAI FEIJIA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1278926', name: 'SHANGHAI FENGLAIYI XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1695557', name: 'SHANGHAI FENGXING DIANXUN SHEBEI LIANSUO YOUXIAN GONGSI' },
	{ code: '1768529', name: 'SHANGHAI FUSHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1067292', name: 'SHANGHAI FUSHENG YIDONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '229248', name: 'SHANGHAI GOLDEN DIGITAL CO.,LTD' },
	{ code: '1283153', name: 'SHANGHAI GUANDI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '539729', name: 'SHANGHAI GUANGDA TONGXIN ZHONGDUAN CHANPIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '616159', name: 'SHANGHAI HAIXING QIYE FAZHAN YOUXIAN GONGSI' },
	{ code: '1775378', name: 'SHANGHAI HANQI TONGXIN SHEBEI FAZHAN YOUXIAN GONGSI' },
	{ code: '1283176', name: 'SHANGHAI HONGYANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1963319', name: 'SHANGHAI HUILING DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '2038750', name: 'SHANGHAI JIAHU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1273866', name: 'SHANGHAI JINBAO SHIYE YOUXIAN GONGSI' },
	{ code: '1279744', name: 'SHANGHAI JINBO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1302732', name: 'SHANGHAI KUNCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1381153', name: 'SHANGHAI LIANDI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '299640', name: 'SHANGHAI LIANGYU MAOYI FAZHAN YOUXIAN GONGSI' },
	{ code: '1306719', name: 'SHANGHAI LINGYONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1647481', name: 'SHANGHAI MANQI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '254035', name: 'SHANGHAI MEICHENG HI-TECH CO.,LTD' },
	{ code: '1279740', name: 'SHANGHAI MINGSHEN MAOYI YOUXIAN GONGSI' },
	{ code: '680996', name: 'SHANGHAI MINGSONG KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '2112081', name: 'SHANGHAI MINHANG COSTCO TRADING CO., LTD.' },
	{ code: '885355', name: 'SHANGHAI MINLI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1632671', name: 'SHANGHAI NENGLIANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2072325', name: 'SHANGHAI PEIZHI XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1302721', name: 'SHANGHAI QIANQIU TONGXIN GONGCHENG JISHU YOUXIAN GONGSI' },
	{ code: '1279008', name: 'SHANGHAI QIQIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1283177', name: 'SHANGHAI SHENGNAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1651931', name: 'SHANGHAI SHENGQING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1330934', name: 'SHANGHAI SHENGSU TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '265273', name: 'SHANGHAI SHENHUI TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1194754', name: 'SHANGHAI SHENYOU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '687158', name: 'SHANGHAI SHENZHOU SHUMA YOUXIAN GONGSI' },
	{ code: '1471321', name: 'SHANGHAI SHIMU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1583786', name: 'SHANGHAI SHIMU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '632365', name: 'SHANGHAI SONGYANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '849785', name: 'SHANGHAI SOUJICHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1631673', name: 'SHANGHAI WANGQING SHIYE YOUXIAN GONGSI' },
	{ code: '1645180', name: 'SHANGHAI WUJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1475879', name: 'SHANGHAI XIANGSHANG MAOYI YOUXIAN GONGSI' },
	{ code: '1381146', name: 'SHANGHAI XUANTE SHIYE YOUXIAN GONGSI' },
	{ code: '1592476', name: 'SHANGHAI YANGZHI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1310096', name: 'SHANGHAI YANHUO XINXI KEJI YOUXIAN GONGSI' },
	{ code: '476022', name: 'SHANGHAI YIBO TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1305653', name: 'SHANGHAI YIHE SHIYE YOUXIAN GONGSI' },
	{ code: '1600856', name: 'SHANGHAI YINGMAI YUNJISUAN JISHU YOUXIAN GONGSI' },
	{ code: '1023503', name: 'SHANGHAI YIZHEN MAOYI YOUXIAN GONGSI' },
	{ code: '1963610', name: 'SHANGHAI YUNZHI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1297099', name: 'SHANGHAI ZHIXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '453253', name: 'SHANGHAI ZHONGXUAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1266758', name: 'SHANGHANGXIAN XINHANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1525241', name: 'SHANGHE QUANHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2073098', name: 'SHANGLUOSHI JIERUN TONGXUN YOUXIAN GONGSI' },
	{ code: '1056865', name: 'SHANGLUOSHI JIEXUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1358690', name: 'SHANGQIUSHI GUOAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1369024', name: 'SHANGQIUSHI SHIYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '686251', name: 'SHANGQIUSHI WANXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1752375', name: 'SHANGRAOSHI CHANGYUAN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '2074509', name: 'SHANGRAOSHI HUAYIN KEJI YOUXIAN GONGSI' },
	{ code: '1303545', name: 'SHANGRAOSHI QIDIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1314243', name: 'SHANGRAOSHI WEIYUAN SHIYE YOUXIAN GONGSI' },
	{ code: '1163328', name: 'SHANGZHISHI XINTAO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1194595', name: 'SHANHE LINGSHOU LIANSUO YOUXIAN ZEREN GONGSI' },
	{ code: '544152', name: 'SHANTIAN DIANJI (SHENYANG) SHANGYE YOUXIAN GONGSI' },
	{ code: '559168', name: 'SHANTOUSHI FENGXING TONGXUN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1305670', name: 'SHANTOUSHI HAOJIE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1306470', name: 'SHANTOUSHI HONGJING DIANQI SHEBEI YOUXIAN GONGSI' },
	{ code: '1301452', name: 'SHANTOUSHI JINSHILONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1273927', name: 'SHANTOUSHI JINXIN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '2090977', name: 'SHANTOUSHI SHENGTIAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1296642', name: 'SHANTOUSHI WEICHUANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1307889', name: 'SHANTOUSHI XINJIE TONGXIN FUWU YOUXIAN GONGSI' },
	{ code: '1312720', name: 'SHANTOUSHI YILI DIANXUN YOUXIAN GONGSI' },
	{ code: '1233546', name: 'SHANWEISHI BAFANG TONGXIN YOUXIAN GONGSI' },
	{ code: '2407626', name: 'SHANWEISHI HUIXUN KEJI YOUXIAN GONGSI' },
	{ code: '1316769', name: 'SHANXI CHANGSHENGCHANG KEJI YOUXIAN GONGSI' },
	{ code: '1249179', name: 'SHANXI FANHAIXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1302712', name: 'SHANXI HAIRUN XINTONG KEJI YOUXIAN GONGSI' },
	{ code: '1389414', name: 'SHANXI HENGYUAN BAIYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1666553', name: 'SHANXI LONGXIANGTONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1299220', name: 'SHANXI MINGLAN KEJI YOUXIAN GONGSI DATONG FENGONGSI' },
	{ code: '1278973', name: 'SHANXI QIANYI HUICHUAN KEMAO YOUXIAN GONGSI' },
	{ code: '1394671', name: 'SHANXI QUANQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1310745', name: 'SHANXI RUTAI DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '1770374', name: 'SHANXI TIANSHENG SHUNYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1071827', name: 'SHANXI XINBO YITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1127664', name: 'SHANXI XINMANTANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1253519', name: 'SHANXI YIDE HENGBAO MAOYI YOUXIAN GONGSI' },
	{ code: '678757', name: 'SHANXI YUANJIA SHIJI KEMAO YOUXIAN GONGSI' },
	{ code: '1286093', name: 'SHANXI YUANTONG XINDA KEJI YOUXIAN GONGSI' },
	{ code: '1770463', name: 'SHANXI ZHISHENGHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1492295', name: 'SHANXI ZHONGHE LINGFENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1278528', name: 'SHANXI ZHONGSHENG WANDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1592475', name: 'SHANXIAN GENGNA SHANGMAO YOUXIAN GONGSI' },
	{ code: '764381', name: 'SHANXISHENG XINZHOU HUABEI TONGXUN YOUXIAN GONGSI' },
	{ code: '1491593', name: 'SHAODONGXIAN FADA KEJI DIANZI YOUXIAN GONGSI' },
	{ code: '1808626', name: 'SHAODONGXIAN GUANRU TONGXIN YOUXIAN GONGSI' },
	{ code: '1287988', name: 'SHAODONGXIAN KANGLONG SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1769752', name: 'SHAOGUANSHI ZHENJIANGQU ZENGYUAN MAOYI SHANGHANG' },
	{ code: '1655349', name: 'SHAOWU GUOGUANG DIANZI YOUXIAN GONGSI' },
	{ code: '1297093', name: 'SHAOXING BAIXIN DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1331220', name: 'SHAOXING SHANGYU NUOHENG MAOYI YOUXIAN GONGSI' },
	{ code: '951830', name: 'SHAOXING SHIBANG CHANGYUAN TONGXIN YOUXIAN GONGSI' },
	{ code: '616916', name: 'SHAOXING SHITONG CHANGYUAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1287317', name: 'SHAOXINGSHI DIBAO TONGXIN YOUXIAN GONGSI' },
	{ code: '690405', name: 'SHAOXINGSHI KEQIAOQU YONGHUA DIANZI YOUXIAN GONGSI' },
	{ code: '1329037', name: 'SHAOXINGSHI KEQIAOQU ZEJIA DIANZI YOUXIAN GONGSI' },
	{ code: '1283107', name: 'SHAOXINGSHI RUOLIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1783159', name: 'SHAOYANG ALIBABA SHOUJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1400961', name: 'SHAOYANGSHI BUBUGAO TONGXIN FAZHAN YOUXIAN GONGSI' },
	{ code: '1540092', name: 'SHAOYANGSHI CHENFENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1244514', name: 'SHAOYANGSHI SHUANGQINGQU SHUMAGANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1682261', name: 'SHAOYANGXIAN XINGKE SHUMA YOUXIAN GONGSI' },
	{ code: '1296563', name: 'SHENGZHOU TIANKAI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1637133', name: 'SHENMUXIAN QUANYOU KEJI YOUXIAN GONGSI' },
	{ code: '1968584', name: 'SHENQIUXIAN FEIDA TONGXUN QICAI MENSHIBU' },
	{ code: '1227212', name: 'SHENXI SHANGMAO (SHANGHAI) YOUXIAN GONGSI' },
	{ code: '1270011', name: 'SHENXIAN YAXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1718751', name: 'SHENYANG BAISIAI KEJI YOUXIAN GONGSI' },
	{ code: '1320520', name: 'SHENYANG DONGFANG TONGYONG DIANXIN YOUXIAN GONGSI' },
	{ code: '682377', name: 'SHENYANG DONGSHENG YULONG TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2395577', name: 'SHENYANG HALI\'OU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1693787', name: 'SHENYANG HEHENG BAIHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1332697', name: 'SHENYANG JINKAIWEI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '835570', name: 'SHENYANG LANTIAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1338018', name: 'SHENYANG LEIMING DINGXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1237019', name: 'SHENYANG MAOXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1389422', name: 'SHENYANG MEIHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '2037353', name: 'SHENYANG MINGJING DIANZI YOUXIAN GONGSI' },
	{ code: '1782437', name: 'SHENYANG RONGZHONG KEJI YOUXIAN GONGSI' },
	{ code: '1286694', name: 'SHENYANG SENYU TONGXUN YOUXIAN GONGSI' },
	{ code: '1396921', name: 'SHENYANG TIANYUXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2108989', name: 'SHENYANG XINFENGGE DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1818741', name: 'SHENYANG YIQUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1960533', name: 'SHENYANG YIYUE DINGSHENG KEJI YOUXIAN GONGSI' },
	{ code: '1259100', name: 'SHENYANG YONGZHIXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1449111', name: 'SHENYANG YUETONGJIA MAOYI YOUXIAN GONGSI' },
	{ code: '1764603', name: 'SHENYANG ZHONGLAN KEJI YOUXIAN GONGSI' },
	{ code: '1312735', name: 'SHENYANG ZHONGYOU TAIDA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1035085', name: 'SHENYANGSHI CHANGRUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2108985', name: 'SHENYANGSHI HUARUI TUODA TONGXIN FUWU YOUXIAN GONGSI' },
	{ code: '1381185', name: 'SHENYANGSHI YIHONGFEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1731815', name: 'SHENYANGSHI ZHONGBANG YIHONGFEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1475843', name: 'SHENZHEN BEISHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1338037', name: 'SHENZHEN DEXIN SHENGTIAN DIANZI YOUXIAN GONGSI' },
	{ code: '228302', name: 'SHENZHEN SUNDAN(CHAIN-STORE) STOCK CO.', name: 'LTD' },
	{ code: '1856342', name: 'SHENZHEN XINGMEI LIANHE TONGXUN YOUXIAN GONGSI' },
	{ code: '290541', name: 'SHENZHENSHI AISHIDE GUFEN YOUXIAN GONGSI' },
	{ code: '2035976', name: 'SHENZHENSHI BENXIONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '691406', name: 'SHENZHENSHI FENGSHANG SHUMA DIANZI YOUXIAN GONGSI' },
	{ code: '560247', name: 'SHENZHENSHI FUTAILE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1945324', name: 'SHENZHENSHI GAOFEIYI KEJI YOUXIAN GONGSI' },
	{ code: '1632059', name: 'SHENZHENSHI GAOFEIYI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1744432', name: 'SHENZHENSHI GUIZHIGU SHIKONG KEJI YOUXIAN GONGSI' },
	{ code: '616477', name: 'SHENZHENSHI HENGBO SHANGYE LIANSUO YOUXIAN GONGSI' },
	{ code: '1314802', name: 'SHENZHENSHI HENGXINFENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1672089', name: 'SHENZHENSHI HESHUO SHUMA YOUXIAN GONGSI' },
	{ code: '1341169', name: 'SHENZHENSHI HEZHIXUN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '274475', name: 'SHENZHENSHI HONGZHAO SHIYE FAZHAN YOUXIAN GONGSI' },
	{ code: '1301459', name: 'SHENZHENSHI HUALIXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1144937', name: 'SHENZHENSHI JIALIAN XUNTONG KEJI YOUXIAN GONGSI' },
	{ code: '1297675', name: 'SHENZHENSHI JIALIANFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1773981', name: 'SHENZHENSHI JIUHUI DIANZI YOUXIAN GONGSI' },
	{ code: '964552', name: 'SHENZHENSHI JUNYE ZHIXING KEJI LIANSUO YOUXIAN GONGSI' },
	{ code: '1856091', name: 'SHENZHENSHI JUNYETONG SHIYE YOUXIAN GONGSI' },
	{ code: '1299244', name: 'SHENZHENSHI KALAKA KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '686245', name: 'SHENZHENSHI KEDIYU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '265264', name: 'SHENZHENSHI KUDONG SHUMA YOUXIAN GONGSI' },
	{ code: '1805265', name: 'SHENZHENSHI LANGANG KEJI YOUXIAN GONGSI' },
	{ code: '1493544', name: 'SHENZHENSHI LIANHE YILI KEJI YOUXIAN GONGSI' },
	{ code: '1261893', name: 'SHENZHENSHI LIANXINGDA TONGXUN FAZHAN YOUXIAN GONGSI' },
	{ code: '1745083', name: 'SHENZHENSHI LIFANTE XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1303749', name: 'SHENZHENSHI LONGHUAXINQU DALANG ZHONGHUAYU SHUMA QICAIDIAN' },
	{ code: '1338036', name: 'SHENZHENSHI LONGTENGYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1223962', name: 'SHENZHENSHI MAIKEFENG KEJI YOUXIAN GONGSI' },
	{ code: '1393509', name: 'SHENZHENSHI NANSHANQU LIANFA SHOUJIDIAN' },
	{ code: '1413470', name: 'SHENZHENSHI PENGTIAN SHUMA YOUXIAN GONGSI' },
	{ code: '1307106', name: 'SHENZHENSHI QUNJIA MAOYI YOUXIAN GONGSI' },
	{ code: '1301479', name: 'SHENZHENSHI SAIWEIKE TONGXIN LIANSUO YOUXIAN GONGSI' },
	{ code: '1314794', name: 'SHENZHENSHI SANHAI TONGXUN YOUXIAN GONGSI' },
	{ code: '2219397', name: 'SHENZHENSHI SHENGHUO BENSE SHIYE YOUXIAN GONGSI' },
	{ code: '1404616', name: 'SHENZHENSHI SHENHENGTONG TONGXIN YOUXIAN GONGSI' },
	{ code: '2460665', name: 'SHENZHENSHI SHIJI KAIXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '2086774', name: 'SHENZHENSHI TIANLIAN ZHONGDUAN YOUXIAN GONGSI' },
	{ code: '2408026', name: 'SHENZHENSHI WUZHOU LIANXIN MAOYI YOUXIAN GONGSI' },
	{ code: '947339', name: 'SHENZHENSHI XINBOTE DIANZI YOUXIAN GONGSI' },
	{ code: '1301469', name: 'SHENZHENSHI XINHE JITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1332699', name: 'SHENZHENSHI XINLONGLIAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1338022', name: 'SHENZHENSHI YANTIANQU JIXIN TONGXUN SHANGDIAN' },
	{ code: '1382369', name: 'SHENZHENSHI YILINGLINGYI SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1624432', name: 'SHENZHENSHI YIMING KEJI YOUXIAN GONGSI' },
	{ code: '2135120', name: 'SHENZHENSHI YINNUO JIAXIN MAOYI YOUXIAN GONGSI' },
	{ code: '1320546', name: 'SHENZHENSHI YIPINGFANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '540067', name: 'SHENZHENSHI YITIAN YIDONG SHUMA LIANSUO YOUXIAN GONGSI' },
	{ code: '693462', name: 'SHENZHENSHI ZHAORUN TOUZI FAZHAN YOUXIAN GONGSI' },
	{ code: '1587282', name: 'SHENZHOU SHUMA (SHENZHEN) YOUXIAN GONGSI' },
	{ code: '1745521', name: 'SHEQI HENGXIN DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1464080', name: 'SHEQIXIAN TIANCHENG YIXUN KEJI YOUXIAN GONGSI' },
	{ code: '1975718', name: 'SHEXIAN QIANJIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2079839', name: 'SHEXIAN YUANHENG TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1720539', name: 'SHEYANGXIAN TIANHAO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1512498', name: 'SHICHENGXIAN HUAYANG KEJI MAOYI YOUXIAN GONGSI' },
	{ code: '1261844', name: 'SHIHEZISHI XINYUANWANG TONGXUN SHUMA SHANGMAO YOUXIAN GONGSI' },
	{ code: '540058', name: 'SHIJI LIANFA (WUHAN) KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '672405', name: 'SHIJIAZHUANG FENGNIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1762265', name: 'SHIJIAZHUANG GUOXUN CHANGYUAN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2078146', name: 'SHIJIAZHUANG HONGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1287310', name: 'SHIJIAZHUANG RUIDI TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1194027', name: 'SHIJIAZHUANG TEBAILE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1429143', name: 'SHIJIAZHUANG ZHANGHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1779487', name: 'SHIJIAZHUANGSHI YUANDACHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321140', name: 'SHIMENXIAN ZHONGYI SHUMA CHANPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1775386', name: 'SHIPINGXIAN RONGBO NAYANG KEJI YOUXIAN GONGSI' },
	{ code: '1310852', name: 'SHIPINGXIAN XINYAO SHOUJIDIAN' },
	{ code: '1019112', name: 'SHISHISHI JINGHANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1815656', name: 'SHISHOUSHI HUANGSHUJIE QUANWANGTONG SHIDAI TONGXIN GUANGCHANG' },
	{ code: '1278985', name: 'SHISHOUSHI RUXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1426969', name: 'SHIXINGXIAN HUAXUN SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1561403', name: 'SHIYAN KUYOU MAOYI YOUXIAN GONGSI' },
	{ code: '1372010', name: 'SHIYAN SHENGRUI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1266735', name: 'SHIYAN YIBOKE GONGMAO YOUXIAN GONGSI' },
	{ code: '1478326', name: 'SHIYANSHI CHECHENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1273891', name: 'SHIYANSHI DONGXIANG RUIXIN GONGMAO YOUXIAN GONGSI' },
	{ code: '1455745', name: 'SHIZHU TUJIAZU ZIZHIXIAN CHENYANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1329048', name: 'SHIZHU TUJIAZU ZIZHIXIAN YIBO SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1492291', name: 'SHIZONGXIAN JINGHUI TONGXUNBU' },
	{ code: '1787884', name: 'SHIZONGXIAN XINFU TONGXUN SHOUJI XIAOSHOUDIAN' },
	{ code: '1991904', name: 'SHIZUISHANSHI GUOBEI SHIGUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1278395', name: 'SHOUGUANG WEIHONG JINGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2037327', name: 'SHOUGUANGSHI DEXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1809354', name: 'SHOUGUANGSHIQU DONGFANG TONGXUNBU' },
	{ code: '1083026', name: 'SHUANGCHENGSHI PINZHUO KEJI YOUXIAN GONGSI' },
	{ code: '1476577', name: 'SHUANGCHENGSHI XINXIEHENG TONGXUN SHANGDIAN' },
	{ code: '1701553', name: 'SHUANGFENGXIAN HEYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1809369', name: 'SHUANGFENGXIAN HEYI TONGXIN JINGYINGBU' },
	{ code: '1297188', name: 'SHUANGLIAOSHI JINGDONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1998010', name: 'SHUANGLIAOSHI LANKE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2081777', name: 'SHUANGLIAOSHI YUJUN LEIYU SHOUJI SHUMA GUANGCHANG' },
	{ code: '1560602', name: 'SHUANGPAIXIAN LONGYUE KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1816558', name: 'SHUANGTAQU GAOBAI TONGXUNDIAN' },
	{ code: '2849965', name: 'SHUANGYANGQU GUOWEI TONGXUN SHANGCHENG' },
	{ code: '1233597', name: 'SHUANGYASHANSHI DONGBEIYA TONGXIN YOUXIAN GONGSI' },
	{ code: '1966881', name: 'SHUCHENGXIAN CHENGGUANZHEN TONGXUN SHIJIE SHOUJI MAICHANG' },
	{ code: '1213138', name: 'SHUCHENGXIAN XINWANFO TONGXUN YOUXIAN GONGSI' },
	{ code: '2074499', name: 'SHULANSHI HAIBAO TONGXUN ZHONGDUAN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1404578', name: 'SHUNPINGXIAN YIXUN TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1284604', name: 'SHUOZHOUSHI HUAQIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '840394', name: 'SHUOZHOUSHI XINCHAOREN SHUMA QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1779900', name: 'SHUYANG QIXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1232712', name: 'SHUYANGXIAN HUILIDI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1816632', name: 'SHUYANGXIAN SHUCHENG TIANHUI SHOUJI JINGYINGBU' },
	{ code: '1650374', name: 'SICHUAN CHANGHONG JIAHUA DUOLA YOUHUO DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '2080207', name: 'SICHUAN CHANGHONG JIAHUA SHUZI JISHU YOUXIAN GONGSI' },
	{ code: '1230755', name: 'SICHUAN CHANGHONG JIAHUA SHUZI JISHU YOUXIAN GONGSI' },
	{ code: '1150166', name: 'SICHUAN CHANGLIANXIN TOUZI GUANLI YOUXIAN GONGSI' },
	{ code: '1066136', name: 'SICHUAN CHENGTAI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2042407', name: 'SICHUAN CHIXINWEI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1022699', name: 'SICHUAN GUANGWEI TONGXUN YOUXIAN GONGSI' },
	{ code: '1061262', name: 'SICHUAN JIAHAI DIANZI YOUXIAN GONGSI' },
	{ code: '1290307', name: 'SICHUAN LONGXING SHIJI SHANGMAO YOUXIAN GONGSI JINTANG DIYI FENGONGSI' },
	{ code: '697961', name: 'SICHUAN MEISHAN KANGDA DIANZI YOUXIAN GONGSI' },
	{ code: '1337479', name: 'SICHUAN MEIXUNDA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1214515', name: 'SICHUAN RONGDA HENGYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1307134', name: 'SICHUAN SANLIAN KEJI YOUXIAN GONGSI' },
	{ code: '670416', name: 'SICHUAN SHANSHUI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1738872', name: 'SICHUAN WEIENDUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2369057', name: 'SICHUAN WEITAI TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '945354', name: 'SICHUAN XINZHILIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1263034', name: 'SICHUAN YIBIN JINGYING TONGXUN QICAI MAOYI YOUXIAN GONGSI' },
	{ code: '1310207', name: 'SICHUAN YIMENG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2079804', name: 'SICHUAN ZHICHENG MINGSHI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '850699', name: 'SICHUANSHENG SHENGZHE TONGXIN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1071162', name: 'SICHUANSHENG YIBINSHI YANJUN MAOYI LIANSUO YOUXIAN ZEREN GONGSI' },
	{ code: '1334211', name: 'SIHUI HUITENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1381171', name: 'SINANXIAN YAOXIANG TONGXIN KEJI GUANGCHANG' },
	{ code: '1067135', name: 'SIPING XIANGYU TONGXUN YOUXIAN GONGSI' },
	{ code: '1995526', name: 'SIPINGSHI HONGXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1819127', name: 'SIPINGSHI JIUWO TONGXUN YOUXIAN GONGSI' },
	{ code: '1720617', name: 'SIYANG DELONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1651627', name: 'SIZIWANGQI GUWEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1966925', name: 'SIZIWANGQI WULANHUAZHEN KUNBAO LITENG YINSHANG RUGUI ZHENGBEILUDIAN' },
	{ code: '2363497', name: 'SONGXIAN CHENZHIWEI SHOUJIDIAN' },
	{ code: '1420949', name: 'SONGXIAN TAIXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2469308', name: 'SONGXIXIAN YIYANG MAOYI YOUXIAN GONGSI' },
	{ code: '1770470', name: 'SONGYUANSHI BEICHEN JINGMAO YOUXIAN GONGSI' },
	{ code: '1549718', name: 'SONGYUANSHI MEIRUI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '751853', name: 'SONGYUANSHI NINGJIANGQU LANTIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '966117', name: 'SONGYUANSHI SHENGTANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1075351', name: 'SONGZISHI XINXIANG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1686290', name: 'SU\'NITEYOUQI WEICHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1816637', name: 'SUCHENGQU JINNUO BAOLONG ZHINENG SHOUJI TIYANDIAN' },
	{ code: '1404517', name: 'SUICHANG DINGTONG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1246156', name: 'SUIFENHESHI TIANYIN DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '2265684', name: 'SUIFENHESHI XINHUA JINGPIN SHOUJI MAICHANG' },
	{ code: '1285992', name: 'SUIHUA HONGCHANG CHANGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1332705', name: 'SUIHUA XUNJIE YANGGUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '680940', name: 'SUIHUASHI HONGCHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1752705', name: 'SUILINGXIAN XIAOFENG SHOUJI MAICHANG' },
	{ code: '2710025', name: 'SUINING LANGFENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1637138', name: 'SUININGSHI KUNCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2113072', name: 'SUININGSHI TIANDI CHANGHONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1195450', name: 'SUININGSHI XINMAOYUAN KEJI YOUXIAN GONGSI' },
	{ code: '1719275', name: 'SUININGXIAN QUANQIU SHOUJI YOUXIAN GONGSI' },
	{ code: '1315456', name: 'SUIXIAN ZHONGYI TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2380977', name: 'SUIXIXIAN YONGSHENG DIANXUN YOUXIAN GONGSI' },
	{ code: '1816155', name: 'SUIZHONGXIAN SUIZHONGZHEN SHOUJI DASHIJIE' },
	{ code: '2137117', name: 'SUIZHONGXIAN XINJIA TONGXUN YOUXIAN GONGSI' },
	{ code: '840764', name: 'SUIZHOUSHI GUANGTONG DIANXUN YOUXIAN GONGSI' },
	{ code: '1132102', name: 'SUIZHOUSHI XINTONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '276502', name: 'SUNING YIGOU JITUAN GUFEN YOUXIAN GONGSI SUNING CAIGOU ZHONGXIN' },
	{ code: '1819333', name: 'SUNINGXIAN XINFANGZHOU TONGXUN MENSHIBU' },
	{ code: '1426977', name: 'SUNINGXIAN YAFANG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2189700', name: 'SUNITEYOUQI SAIWEI GAOKE' },
	{ code: '2393548', name: 'SUQIAN AIBEINUO KEJI YOUXIAN GONGSI' },
	{ code: '2325020', name: 'SUQIAN JINGDONG JIAPIN MAOYI YOUXIAN GONGSI' },
	{ code: '1193379', name: 'SUQIAN JINNUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1310215', name: 'SUQIANSHI HUANQIU TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1235909', name: 'SUQIANSHI QIAOFENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1782832', name: 'SUQIANSHI QUANXING HUANQIU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1633269', name: 'SUSONGXIAN CHANGHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2831249', name: 'SUSONGXIAN XINLI TONGXUN YOUXIAN GONGSI' },
	{ code: '1157006', name: 'SUZHOU BAIYIDA MAOYI YOUXIAN GONGSI' },
	{ code: '1301483', name: 'SUZHOU DUOLIANXI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1600948', name: 'SUZHOU GONGYE YUANQU HUIYAO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1278979', name: 'SUZHOU HAILIAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '691394', name: 'SUZHOU HONGYU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1772499', name: 'SUZHOU JINDIANPING SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1397499', name: 'SUZHOU LANGXIN DIANZI CHANPIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1156092', name: 'SUZHOU SHUNTONG WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '623030', name: 'SUZHOU SIWEI GE’ER XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1334222', name: 'SUZHOU XUANWEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1270032', name: 'SUZHOU YANFENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1377347', name: 'SUZHOU YITUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1774259', name: 'SUZHOU YUEMING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2549305', name: 'SUZHOU ZEJI ZHIJIA XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1314820', name: 'SUZHOU ZHANGUOXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1780293', name: 'SUZHOU ZHIXING YILIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1815710', name: 'SUZHOUQU JINRUI SHOUJI JINGXIAOBU' },
	{ code: '1688872', name: 'SUZHOUSHI BAISEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1262423', name: 'SUZHOUSHI BEIER TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1741279', name: 'SUZHOUSHI DINGYUANGUANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1579094', name: 'SUZHOUSHI HEZHIGUANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1737881', name: 'SUZHOUSHI LEGUAN SHOUJI YOUXIAN GONGSI' },
	{ code: '1752709', name: 'SUZHOUSHI TENGJIE MAOYI YOUXIAN GONGSI' },
	{ code: '1759169', name: 'SUZHOUSHI WANGHUI SHOUJI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1315457', name: 'SUZHOUSHI YIBAITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1021011', name: 'SUZHOUSHI YOUWEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1062242', name: 'TAI\'AN DAZHONG JINGMAO YOUXIAN GONGSI' },
	{ code: '2599426', name: 'TAI\'AN WANBANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1283866', name: 'TAI\'ANSHI DONGFANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1740326', name: 'TAI\'ANSHI JINLUKE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '572055', name: 'TAI\'ANSHI LUKE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1399365', name: 'TAICANG KAITE TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1294622', name: 'TAICANG QUANCHENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1319877', name: 'TAICANGSHI BEIWEN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '2079776', name: 'TAICANGSHI SHAXIZHEN QUANJIA TONGXIN QICAI SHANGDIAN' },
	{ code: '1745062', name: 'TAICANGSHI XIEHENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1280315', name: 'TAIGUXIAN HANHONG ZHIBO KEJI YOUXIAN GONGSI' },
	{ code: '2009699', name: 'TAIHEXIAN HENGDA ZICHAN GUANLI YOUXIAN GONGSI' },
	{ code: '1296709', name: 'TAIHEXIAN JIAHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1751136', name: 'TAIHEXIAN SANGLI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1522606', name: 'TAIHEXIAN SHUANGYING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1283196', name: 'TAIHUXIAN TIANBANG TONGXUN JISHU FUWU YOUXIAN GONGSI' },
	{ code: '1745533', name: 'TAIKANGXIAN MINGYI KEJI YOUXIAN GONGSI' },
	{ code: '1482305', name: 'TAIKANGXIAN XINTIANDI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1527150', name: 'TAILAIXIAN JINZHENGDA SHOUJI MAICHANG' },
	{ code: '1389437', name: 'TAIPUSIQI YONGJIWEIYE TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1800071', name: 'TAIQIANXIAN YUANDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '690399', name: 'TAIXINGSHI JIAYUAN SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '2549165', name: 'TAIXINGSHI QIXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1240187', name: 'TAIYUAN BOYUAN ZHONGHANG KEMAO YOUXIAN GONGSI' },
	{ code: '672079', name: 'TAIYUAN SANYOU JINGPIN SHANGSHA YOUXIAN GONGSI' },
	{ code: '560350', name: 'TAIYUAN SOUJICHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '2066074', name: 'TAIYUANSHI CHAOLIDA KEMAO YOUXIAN GONGSI' },
	{ code: '1682235', name: 'TAIYUANSHI JINYI DIANXUN KEMAO YOUXIAN GONGSI' },
	{ code: '841216', name: 'TAIYUANSHI KUAICHENG TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1759159', name: 'TAIYUANSHI TAITONG KEJI MAOYI YOUXIAN GONGSI' },
	{ code: '1402073', name: 'TAIYUANSHI TONGXIN SHIYE YOUXIAN GONGSI' },
	{ code: '2380636', name: 'TAIZHOU QIANYI TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1305140', name: 'TAIZHOU SANQI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2372781', name: 'TAIZHOU XINWU MAOYI YOUXIAN GONGSI' },
	{ code: '682124', name: 'TAIZHOU YINXINTONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1475620', name: 'TAIZHOU YONGHONG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1296677', name: 'TAIZHOU YUESHENG MAOYI GUFEN YOUXIAN GONGSI' },
	{ code: '840753', name: 'TAIZHOUSHI BAIMAO TONGXUN CHANPIN YOUXIAN GONGSI' },
	{ code: '1291848', name: 'TAIZHOUSHI CHANGHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1719288', name: 'TAIZHOUSHI HENGQIANG DIANZI YOUXIAN GONGSI' },
	{ code: '1275462', name: 'TAIZHOUSHI HUANGYAN JIAYIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1291831', name: 'TAIZHOUSHI JIAOJIANG CHANGYUAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1282675', name: 'TAIZHOUSHI LUQIAO LULUTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '840387', name: 'TAIZHOUSHI TONGBO DIANXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1303657', name: 'TAIZHOUSHI XINGNUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '2003161', name: 'TAIZHOUSHI XINJUESHI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1777650', name: 'TAIZHOUSHI ZHISHANG JIAPIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1796373', name: 'TANCHENGXIAN JINHUA TONGXIN YOUXIAN GONGSI' },
	{ code: '1220742', name: 'TANCHENGXIAN LIANYI DIANQI YOUXIAN GONGSI' },
	{ code: '1321626', name: 'TANGHE JIULONG SHUMA TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '2003493', name: 'TANGHEXIAN CHANGJIU SHOUJIDIAN' },
	{ code: '1482307', name: 'TANGSHAN JIATONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '964674', name: 'TANGSHAN JINGQIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1469190', name: 'TANGSHAN SHILI TONGXIN YOUXIAN GONGSI' },
	{ code: '1790754', name: 'TANGSHAN TENGYU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1353180', name: 'TANGSHAN YINGHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '980057', name: 'TANGSHAN YINGZHAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1435553', name: 'TANGSHAN YINGZHISHUO SHANGMAO YOUXIAN GONGSI' },
	{ code: '2037398', name: 'TANGSHAN YUYANGTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1542558', name: 'TANGSHANSHI FENGNANQU YUXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '648140', name: 'TANGSHANSHI FENGRUNQU FENGZHIHUA DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1488329', name: 'TANGSHANSHI MANZHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1761886', name: 'TANGSHANSHI YINYUAN DIANZI TONGXIN YOUXIAN GONGSI' },
	{ code: '2060004', name: 'TANGSHANSHI ZONGLIANG YIZHILONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315663', name: 'TANGYUANXIAN CHENGYI SHUMADIAN' },
	{ code: '2077339', name: 'TAOBO QIYE FAZHAN (SHANGHAI) YOUXIAN GONGSI' },
	{ code: '1479248', name: 'TAONANSHI QIHANG DIANZI YOUXIAN GONGSI' },
	{ code: '1791872', name: 'TENGCHONGSHI YONGCHENG TONGXIN JINGYINGBU' },
	{ code: '1769829', name: 'TENGZHOUSHI FEIXUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1785434', name: 'TENGZHOUSHI TIANYIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '810220', name: 'TENGZHOUSHI XIANGHAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1737154', name: 'TIANCHANGSHI LICHENG TONGXUN QICAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1975705', name: 'TIANCHANGSHI LIXIANG TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1266169', name: 'TIANCHANGSHI TIANDONG TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1972038', name: 'TIANCHANGSHI TIANFA DONGCHENG SHOUJI JINGYINGBU' },
	{ code: '1811475', name: 'TIANDONGXIAN WANSHENG SHUMA TONGXUN GUANGCHANG' },
	{ code: '719726', name: 'TIANJIN BAILIAN TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1267243', name: 'TIANJIN BATE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1649185', name: 'TIANJIN FANGZHENG MINGRUI KEJI YOUXIAN GONGSI' },
	{ code: '1638235', name: 'TIANJIN FANGZHENG QIRUI KEJI YOUXIAN GONGSI' },
	{ code: '229268', name: 'TIANJIN FUSHISONG KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1283142', name: 'TIANJIN HAOZHAN SHIJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1365253', name: 'TIANJIN HUANQIU TONGXIN FAZHAN YOUXIAN GONGSI' },
	{ code: '964722', name: 'TIANJIN JIASHUNTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '839930', name: 'TIANJIN KAIFAQU XINYATE DIANZICHENG YOUXIAN GONGSI' },
	{ code: '2060773', name: 'TIANJIN LIANXUNTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1779559', name: 'TIANJIN SHANGSHI TONGXU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1312744', name: 'TIANJIN TIANYI SHISHANG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1777142', name: 'TIANJIN WUXIAN KONGJIAN KEJI YOUXIAN GONGSI' },
	{ code: '1306214', name: 'TIANJIN XIANFENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1622263', name: 'TIANJIN XINJITONG KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1283129', name: 'TIANJIN ZHONGLIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1770391', name: 'TIANJINSHI AILAIWUYOU DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1283133', name: 'TIANJINSHI CHUANHE JIYE TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '537887', name: 'TIANJINSHI DATANG TONGXIN YOUXIAN GONGSI' },
	{ code: '538007', name: 'TIANJINSHI HONGXUNTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1382378', name: 'TIANJINSHI HUANZHOU KEMAO YOUXIAN GONGSI' },
	{ code: '1307823', name: 'TIANJINSHI MAITAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1469198', name: 'TIANJINSHI MEILANGTE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1397518', name: 'TIANJINSHI NINGHEQU YOUYOU TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '698029', name: 'TIANJINSHI RUITONG DIANXUN KEJI YOUXIAN GONGSI' },
	{ code: '1397519', name: 'TIANJINSHI SHANGQIDA KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1307835', name: 'TIANJINSHI SIHAIJITONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1261833', name: 'TIANJINSHI TAOLING SHANGMAO YOUXIAN GONGSI' },
	{ code: '2549385', name: 'TIANJINSHI TIANCHENG HONGTUO KEJI YOUXIAN GONGSI' },
	{ code: '1288660', name: 'TIANJINSHI TIANYI HONGTUO TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1469195', name: 'TIANJINSHI TONGYINGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '2109038', name: 'TIANJINSHI WANGTE KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1469196', name: 'TIANJINSHI WANGTE TONGXIN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '531016', name: 'TIANJINSHI WUSI KEJI YOUXIAN GONGSI' },
	{ code: '1469192', name: 'TIANJINSHI XINJI KEJI YOUXIAN GONGSI' },
	{ code: '1377353', name: 'TIANJINSHI YACHENG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1813556', name: 'TIANJINSHI YUXINGLI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2331780', name: 'TIANMEN LEDONG SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '1991810', name: 'TIANSHUI JIAJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '2372400', name: 'TIANSHUI TIANQI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1060308', name: 'TIANSHUI TIANXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '648131', name: 'TIANYANG DIANQI YOUXIAN GONGSI' },
	{ code: '893413', name: 'TIANYIN XINXI FUWU (BEIJING) YOUXIAN GONGSI' },
	{ code: '1413914', name: 'TIANZHUXIAN DONGHUA DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1980310', name: 'TIELING YICHENG RUNFENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1455835', name: 'TIELINGSHI HAIYUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1300782', name: 'TIELINGSHI HONGTAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1818875', name: 'TIELINGSHI WANYUN TONGXUN YOUXIAN GONGSI' },
	{ code: '1263066', name: 'TIELINGSHI XINYUANSHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1262429', name: 'TONGBAI MINGZHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1791275', name: 'TONGBAIXIAN CHENGGUAN HENGSHENG SHUMA TONGXUNCHENG' },
	{ code: '1174847', name: 'TONGCHENGSHI CHENG\'AN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1297124', name: 'TONGCHUANSHI HENGWEI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1731488', name: 'TONGDAO FEIHONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1739138', name: 'TONGHUA HONGXIANG DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1984196', name: 'TONGHUASHI DAWEI TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1023598', name: 'TONGHUASHI LIHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1672166', name: 'TONGJIANGSHI WANDA TONGXUN YOUXIAN GONGSI' },
	{ code: '1404623', name: 'TONGJIANGXIAN FUQUN MIJIA TONGXUNCHENG' },
	{ code: '1337724', name: 'TONGLIAO ZHONGYOU DINGXIN SHUMA TONGXUN YOUXIAN ZEREN GONGSI DIYI FENGONGSI' },
	{ code: '690409', name: 'TONGLIAO ZHONGYOUDINGXIN SHUMA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1600952', name: 'TONGLIAOSHI DINGYUE DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1573879', name: 'TONGLIAOSHI JINDINGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '2489025', name: 'TONGLIAOSHI YUANDONG DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1381231', name: 'TONGLING JULONGYING SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2009628', name: 'TONGLING KUCHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1252363', name: 'TONGLINGSHI JINTIANXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1049240', name: 'TONGLINGXIAN HENGYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1110177', name: 'TONGREN CHANGCHENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '2108130', name: 'TONGRENSHI BIJIANGQU SONGSONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1682414', name: 'TONGRENSHI CHANGXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1765016', name: 'TONGSHAN SHENGLI WEIYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321850', name: 'TONGXIANGSHI LANSIDAI TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1815738', name: 'TONGXIANGSHI PUYUAN JINJINGYUAN SHOUJIDIAN' },
	{ code: '1109055', name: 'TONGXIANGSHI WUTONG JINPENG HUAJI SHIJIE YOUXIAN GONGSI' },
	{ code: '1331207', name: 'TONGXIANGSHI XINSIFANG DIANZI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1745067', name: 'TONGXINXIAN SANRENHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1760124', name: 'TONGXUXIAN SHENGPING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1809012', name: 'TONGYUXIAN LANTIAN TONGXUN SHANGDIAN' },
	{ code: '2847528', name: 'TONGYUXIAN ZHISHANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1404591', name: 'TONGYUXIAN ZHISHANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1319887', name: 'TOUDENGCANG (BEIJING) MAOYI FAZHAN YOUXIAN GONGSI' },
	{ code: '2008998', name: 'TUMOTEYOUQI SALAQI JINPIN TONGXUN MENSHI' },
	{ code: '1785856', name: 'TUOKETUOXIAN WANGHUANLE SHOUJI MAICHANG' },
	{ code: '1508457', name: 'TUOKETUOXIAN YIBOYUN SHOUJI ZHUANMAI YOUXIAN ZEREN GONGSI' },
	{ code: '1960304', name: 'TUQUANXIAN LINGHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1780298', name: 'WAFANGDIANSHI CHANGXIN SHUMA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '552213', name: 'WALMART (CHINA) INVESTMENT CO., LTD' },
	{ code: '1329159', name: 'WAN\'ANXIAN DINGYI DIANXUN YOUXIAN GONGSI' },
	{ code: '1816647', name: 'WAN\'ANXIAN XIEXINHONG SHOUJIDIAN' },
	{ code: '1506938', name: 'WANGJIANGXIAN LIANSHENG TONGXUN JINGYINGBU' },
	{ code: '1244508', name: 'WANGKUIXIAN WANGKUIZHEN HUABIN TONGXUN QICAI SHANGDIAN' },
	{ code: '2073046', name: 'WANGQINGXIAN HUIFENG DIANNAO XIAOSHOU YOUXIAN ZEREN GONGSI' },
	{ code: '1320397', name: 'WANNIANXIAN FEIPENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1811063', name: 'WANNIANXIAN HONGXIN ZHIDING ZHUANYINGDIAN' },
	{ code: '1655357', name: 'WANRONGXIAN DIANFENG KEMAO YOUXIAN GONGSI' },
	{ code: '1283918', name: 'WANYUANSHI KAIYUAN KEJI YOUXIAN GONGSI' },
	{ code: '2008054', name: 'WANYUANSHI ZULIN TONGXUN YINGYETING' },
	{ code: '1169883', name: 'WANZAIXIAN XUNJIE KEJI YOUXIAN GONGSI' },
	{ code: '1341824', name: 'WEIFANG GOUDE SHANGMAO YOUXIAN GONGSI' },
	{ code: '2267120', name: 'WEIFANG JIACHANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1248507', name: 'WEIFANG JINZHENG JISUANJI JISHU KAIFA YOUXIAN GONGSI' },
	{ code: '680999', name: 'WEIFANG JINZHENG SHIJI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2379120', name: 'WEIFANG MAIFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1655044', name: 'WEIFANG YIKU HULIAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '965121', name: 'WEIHAI BAKESONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '693394', name: 'WEIHAI LIANFA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1754976', name: 'WEIHAI PENGYOU XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1154195', name: 'WEIHAI XINWANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1027475', name: 'WEIHAI ZHONGGUANCUN SHUMA YOUXIAN GONGSI' },
	{ code: '1270543', name: 'WEIHUISHI BAISHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1816714', name: 'WEIHUISHI WEIHUI DADAO JINGCAI TONGXUNBU' },
	{ code: '1235034', name: 'WEINAN GAOXUN DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '1331085', name: 'WEINAN PENGXIANG KEJI FUWU YOUXIAN GONGSI' },
	{ code: '1745084', name: 'WEINING WANXITONG KEJI YOUXIAN GONGSI' },
	{ code: '1810951', name: 'WEISHANXIAN XIAZHEN SHENGHE TONGXUN QICAI JINGYINGBU' },
	{ code: '1224777', name: 'WEISHANXIAN XINHUI MAOYI YOUXIAN GONGSI' },
	{ code: '1745545', name: 'WEISHIXIAN HONGFA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1267950', name: 'WEISHIXIAN XINGUOXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1551386', name: 'WEN\'ANXIAN JIUZHOU JIAYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1516959', name: 'WENCHENGXIAN DADI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1309452', name: 'WENG\'ANXIAN WUJIANG TONGXUN YOUXIAN GONGSI' },
	{ code: '696072', name: 'WENLING HUAXING TONGXUN YOUXIAN GONGSI' },
	{ code: '1393547', name: 'WENLINGSHI HUAXUN TONGXIN YOUXIAN GONGSI' },
	{ code: '1800450', name: 'WENQUANLU HUANQIU DIANZI KEJI CHANPIN XIAOSHOU ZHONGXIN' },
	{ code: '1404609', name: 'WENSHAN LONGXINGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1249224', name: 'WENSHAN TIANRUN KEJI YOUXIAN GONGSI' },
	{ code: '1969169', name: 'WENSHANSHI XUNJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1516960', name: 'WENSHUIXIAN TIANHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1808486', name: 'WENXIAN FENGYAN SHOUJICHENG' },
	{ code: '1948872', name: 'WENXIAN HESHENG NONGJI YOUXIAN ZEREN GONGSI' },
	{ code: '1205077', name: 'WENXIAN XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1651935', name: 'WENZHOU CHANGXIN XINXI JISHU YOUXIAN GONGSI' },
	{ code: '1252369', name: 'WENZHOU LINGLINGYI SHUMA TONGXIN YOUXIAN GONGSI' },
	{ code: '1193296', name: 'WENZHOU MEILE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1278959', name: 'WENZHOU MINGDA KEJI YOUXIAN GONGSI' },
	{ code: '1398174', name: 'WENZHOU MOGUYUN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1294442', name: 'WENZHOU SIAISI TONGXIN YOUXIAN GONGSI' },
	{ code: '2060075', name: 'WENZHOU ZHIDAXIN TONGXIN FUWU YOUXIAN GONGSI' },
	{ code: '1023982', name: 'WENZHOUSHI CHUANGXIN JINGMAO YOUXIAN GONGSI' },
	{ code: '1812866', name: 'WENZHOUSHI SANTONG SHUMA YOUXIAN GONGSI' },
	{ code: '1396779', name: 'WENZHOUSHI SHUANGXIONG TONGXUN YOUXIAN GONGSI' },
	{ code: '793038', name: 'WENZHOUSHI WANSHUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1287297', name: 'WENZHOUSHI WEIXIN JINGMAO YOUXIAN GONGSI' },
	{ code: '1287303', name: 'WENZHOUSHI ZHILING TONGXUN YOUXIAN GONGSI' },
	{ code: '888569', name: 'WENZHOUSHI ZHONGFU SHUMA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1960311', name: 'WU\'ANSHI GAOYUAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2469665', name: 'WU\'ANSHI WU\'ANZHEN HONGXIN TONGXUN QICAI MENSHIBU' },
	{ code: '1014155', name: 'WUANSHI YOUHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1316129', name: 'WUCHENGXIAN DINGXUN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1126912', name: 'WUCHUANSHI HONGDA TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1791283', name: 'WUCHUANSHI MEILU HONGDA SHUMA TONGXIN YINGYEBU' },
	{ code: '1622617', name: 'WUDALIANCHISHI HUIXIN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2170080', name: 'WUDALIANCHISHI XINHUIXIN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1769838', name: 'WUFENG XUNJIA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2082853', name: 'WUGANGSHI SHUANGFA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '764776', name: 'WUHAISHI CHAOQIANG TONGXUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1365264', name: 'WUHAN AIHUA JIAYAN KEMAO YOUXIAN GONGSI' },
	{ code: '2471925', name: 'WUHAN AOYITONG DIANXIN KEJI YOUXIAN GONGSI' },
	{ code: '1057950', name: 'WUHAN FUCHENRUI KEJI YOUXIAN GONGSI' },
	{ code: '543037', name: 'WUHAN GONGMAO YOUXIAN GONGSI' },
	{ code: '1718817', name: 'WUHAN HEXINTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1363609', name: 'WUHAN HONGFA XINDA TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1365247', name: 'WUHAN HUITONG TIANHUA TONGXUN YOUXIAN GONGSI' },
	{ code: '1279050', name: 'WUHAN JIAMA WEIYE KEJI YOUXIAN GONGSI' },
	{ code: '1301444', name: 'WUHAN JIARAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1331074', name: 'WUHAN JULIJIAHONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1096752', name: 'WUHAN JUYUAN SHIJI KEJI YOUXIAN GONGSI' },
	{ code: '1307824', name: 'WUHAN LESHANG HAOYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1341057', name: 'WUHAN LETONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1284431', name: 'WUHAN LONGSHIDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1282540', name: 'WUHAN MAIDIGE SHANGMAO YOUXIAN GONGSI' },
	{ code: '2365797', name: 'WUHAN QIMING TIANJI KEJI YOUXIAN GONGSI' },
	{ code: '1318205', name: 'WUHAN SHUNDA SHIJI TONGXIN YOUXIAN GONGSI' },
	{ code: '1478340', name: 'WUHAN TONGTIAN SHIDAI DIANXUN YOUXIAN GONGSI' },
	{ code: '1302154', name: 'WUHAN WENXINTONG TONGXUN YOUXIAN GONGSI' },
	{ code: '615431', name: 'WUHAN XINLEXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1382386', name: 'WUHAN XINRUITONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1312710', name: 'WUHAN XINTONGCHUANG KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1120450', name: 'WUHAN XINWANGTONG YIDONG TONGXIN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1120407', name: 'WUHAN ZHONGYUAN DIANZI XINXI GONGSI QIXING TONGXUN JINGYINGBU' },
	{ code: '752904', name: 'WUHAN ZHONGYUAN DIANZI XINXI GONGSI XINTONG ZHONGYUAN SHANGMAO FEN\'GONGSI' },
	{ code: '1286655', name: 'WUHAN ZIZHUO KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1296636', name: 'WUHANSHI HENGXIN FENGHUA KEJI YOUXIAN GONGSI' },
	{ code: '1365241', name: 'WUHANSHI JIANG\'ANQU HENGHUA TONGXUN QICAI JINGYINGBU' },
	{ code: '1302832', name: 'WUHANSHI NANJI AIHUA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1294428', name: 'WUHANSHI WEITE HUANYU KEJI YOUXIAN GONGSI' },
	{ code: '2072630', name: 'WUHANSHI XINFANGDA TONGXUN YOUXIAN GONGSI' },
	{ code: '648135', name: 'WUHANSHI ZHILONG DIANZI TONGXIN YOUXIAN GONGSI' },
	{ code: '1969969', name: 'WUHOUQU MINGXUN DIANNAO HAOCAI JINGYINGBU' },
	{ code: '1394729', name: 'WUHU JINQIU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1287320', name: 'WUHU JINTIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1426970', name: 'WUHU XUNYUAN TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1120362', name: 'WUHU YULONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1654310', name: 'WUHUSHI HONGJIE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1262407', name: 'WUHUSHI HONGXIN XINXI KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1300951', name: 'WUHUSHI HUICHUANG JILIANG TONGXUN QIJU YOUXIAN ZEREN GONGSI' },
	{ code: '1731893', name: 'WUHUSHI JIEHUA SHUMA CHANPIN XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1193368', name: 'WUHUXIAN KESAI WANGLUO YOUXIAN ZEREN GONGSI' },
	{ code: '572057', name: 'WUJIANGSHI TENGDA DIANQI YOUXIAN GONGSI' },
	{ code: '2407983', name: 'WUJIXIAN ZHONGXIN SHOUJIDIAN' },
	{ code: '1050276', name: 'WULANCHABUSHI JINING KUNBAO LITENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1294628', name: 'WULANCHABUSHI JININGQU SHENTONG XINGE TONGXUN YOUXIAN GONGSI' },
	{ code: '753250', name: 'WULANHAOTESHI GUBEIJI TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1482310', name: 'WULATEQIANQI YUANZHOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1397608', name: 'WULATEZHONGQI HAOTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1065257', name: 'WULUMUQI HONGYU YOUKE TONGXUN YOUXIAN GONGSI' },
	{ code: '1330150', name: 'WULUMUQISHI JINCHENG XIANGRUI MAOYI YOUXIAN GONGSI' },
	{ code: '1321057', name: 'WULUMUQISHI SHENGSHENOU TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1404610', name: 'WUMING GUANGDIAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1267783', name: 'WUSHANXIAN HENGTONG GONGMAO YOUXIAN GONGSI' },
	{ code: '1965990', name: 'WUSHENGXIAN YANKOU JINGMEI TONGXUN JINGYINGBU' },
	{ code: '1508458', name: 'WUSHENQI ZHUOXIN GONGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2485945', name: 'WUSHENQI ZHUOXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1281951', name: 'WUWEI CHANGHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2050947', name: 'WUWEI LINGYU SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1296630', name: 'WUXI DONGLI DIANZI YOUXIAN GONGSI' },
	{ code: '1794975', name: 'WUXI JIALIDE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '236123', name: 'WUXI JUNQI TRADE CO.,LTD' },
	{ code: '1269926', name: 'WUXI TIANJI ZHONGDA TONGXIN QICAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1741272', name: 'WUXI WEICHUANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1701062', name: 'WUXI YUANZHEN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1721992', name: 'WUXI ZHIJIA KEJI YOUXIAN GONGSI' },
	{ code: '1964572', name: 'WUXIANGXIAN LONGXIANGTONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1399366', name: 'WUXISHI GUOFU WUZI YOUXIAN GONGSI' },
	{ code: '1286632', name: 'WUXISHI RONGZHENGMING MAOYI YOUXIAN GONGSI' },
	{ code: '682137', name: 'WUXISHI TIANYI TONGXUN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '2085934', name: 'WUXISHI ZONGHUAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1297885', name: 'WUXIXIAN LIYONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1991056', name: 'WUXIXIAN ZHENHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1745534', name: 'WUYANGXIAN YIXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1813568', name: 'WUYISHANSHI JIUJIU TONGXUN YOUXIAN GONGSI' },
	{ code: '1309440', name: 'WUYISHANSHI JIUZHOU TONGXUN YOUXIAN GONGSI' },
	{ code: '1802231', name: 'WUYUANXIAN TIANCHUANG SHIYE YOUXIAN GONGSI' },
	{ code: '1314400', name: 'WUYUANXIAN WANGZHESHIDAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1315041', name: 'WUZHIXIAN XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2200877', name: 'WUZHONGSHI JUNFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1777682', name: 'WUZHOU HUASHENG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1739194', name: 'WUZHOUSHI HAOBO TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1320547', name: 'WUZHOUSHI JIAYUE TONGXIN YOUXIAN GONGSI' },
	{ code: '1244454', name: 'WUZHOUSHI ZHUOYUE TONGXIN YOUXIAN GONGSI' },
	{ code: '1262945', name: 'XI\'AN CHENGXIANG KEJI YOUXIAN GONGSI' },
	{ code: '1329118', name: 'XI\'AN HONGXIANG DIANXUN YOUXIAN GONGSI' },
	{ code: '538020', name: 'XI\'AN HUAZHONG TONGXUN GUANGCHANG YOUXIAN GONGSI' },
	{ code: '1307120', name: 'XI\'AN JIUTIAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1426989', name: 'XI\'AN QIANMO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1513915', name: 'XI\'AN QINGSEN TONGXIN GONGCHENG YOUXIAN GONGSI' },
	{ code: '1286161', name: 'XI\'AN QINLAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1647489', name: 'XI\'AN RUILIANG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1988204', name: 'XI\'AN SEKU SHUJI XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1978138', name: 'XI\'AN SENJING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2126260', name: 'XI\'AN SHIJI ZHONGTIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315563', name: 'XI\'AN TIANXIN SHIYE YOUXIAN GONGSI' },
	{ code: '1290262', name: 'XI\'AN WUXIAN ZHIXING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1473896', name: 'XI\'AN YAHAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1980317', name: 'XI\'AN YAMI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1651633', name: 'XI\'AN YURUN XINXI JISHU YOUXIAN GONGSI' },
	{ code: '2664245', name: 'XI\'AN ZHI\'AIMEI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1315473', name: 'XI\'AN ZHOUYANG DIANLI SHEBEI TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '2169520', name: 'XI\'AN ZIYUN DIANZI YOUXIAN GONGSI' },
	{ code: '1319956', name: 'XI\'ANSHI HUALEI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1600943', name: 'XI\'ANSHI KULIAN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2133137', name: 'XI\'ANSHI LINTONGQU YONGXIN SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1284444', name: 'XI\‘AN GUOZE DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '691392', name: 'XIAMEN FEITELI MAOYI YOUXIAN GONGSI' },
	{ code: '1382377', name: 'XIAMEN GUOCHAOYING TONGXUN YOUXIAN GONGSI' },
	{ code: '1382371', name: 'XIAMEN HEWANG KEJI YOUXIAN GONGSI' },
	{ code: '1305728', name: 'XIAMEN HONGDING MAOYI YOUXIAN GONGSI' },
	{ code: '1257940', name: 'XIAMEN HUAITAI MAOYI YOUXIAN GONGSI' },
	{ code: '1767749', name: 'XIAMEN JIELONGXIN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1801345', name: 'XIAMEN JINCHANGXIN XINXI KEJI YOUXIAN GONGSI' },
	{ code: '2070677', name: 'XIAMEN LIANLIAN SITIAN HULIANWANG KEJI YOUXIAN GONGSI' },
	{ code: '1692740', name: 'XIAMEN RIZHEN SHANGPIN MAOYI YOUXIAN GONGSI' },
	{ code: '1243853', name: 'XIAMEN SHENGTIAN GUANGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1484946', name: 'XIAMEN TAIRI MAOYI YOUXIAN GONGSI' },
	{ code: '1310162', name: 'XIAMEN TIANYITONG WANGLUO TONGXIN YOUXIAN GONGSI' },
	{ code: '1637132', name: 'XIAMEN WEIKE SHIJI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1562770', name: 'XIAMEN WEISIDENG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1063869', name: 'XIAMEN WEIXIN CHUANGLIAN XINXI KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '1305100', name: 'XIAMEN XINKAICHUANG MAOYI YOUXIAN GONGSI' },
	{ code: '753344', name: 'XIAMEN XINLECHANG DIANZI YOUXIAN GONGSI' },
	{ code: '2463708', name: 'XIAMEN XINLECHANG PINPAI YINGXIAO GUANLI YOUXIAN GONGSI' },
	{ code: '1767741', name: 'XIAMEN XINYITENG XINXI KEJI YOUXIAN GONGSI' },
	{ code: '1338146', name: 'XIAMENSHI BAISONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1404599', name: 'XIAMENSHI CHANGXINMAO MAOYI YOUXIAN GONGSI' },
	{ code: '1323653', name: 'XIAMENSHI HUABIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1395194', name: 'XIAMENSHI JIMEI SHENGTONG TONGXUN CHANPIN YOUXIAN GONGSI' },
	{ code: '1302785', name: 'XIAMENSHI SHENGTAIRUI MAOYI YOUXIAN GONGSI' },
	{ code: '616481', name: 'XIAMENSHI TONGAN ZHONGQIAO DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '554421', name: 'XIAMENSHI WANMENG DIANXUN YOUXIAN GONGSI' },
	{ code: '1267131', name: 'XIAMENSHI XINWANGXIN MAOYI YOUXIAN GONGSI' },
	{ code: '1429804', name: 'XIAMENSHI YINGYE MAOYI YOUXIAN GONGSI' },
	{ code: '1800083', name: 'XIAMENSHI YUFA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1050771', name: 'XIAMENSHI ZHONGBO MAOYI YOUXIAN GONGSI' },
	{ code: '1330949', name: 'XIAMENSHI ZHONGTAIDA MAOYI YOUXIAN GONGSI' },
	{ code: '1693067', name: 'XIANFENGXIAN HUAFENG TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1126672', name: 'XIANGCHENGSHI CHANGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1809055', name: 'XIANGCHENGSHI SHUIZHAI YAPING SHOUJI JINGYINGBU' },
	{ code: '1127686', name: 'XIANGCHENGXIAN JIEYU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1968560', name: 'XIANGFUQU CHENGGUANZHEN YIXUN SHOUJI MAICHANG' },
	{ code: '1787897', name: 'XIANGGELILASHI ZHANYANG TONGXUN' },
	{ code: '1301594', name: 'XIANGHE HONGYAN TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1741287', name: 'XIANGSHAN LANGCHENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1263022', name: 'XIANGTAN LIANHONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1251869', name: 'XIANGTANSHI CHENGSE SHUMA TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '616445', name: 'XIANGTANSHI HUAFENG SHIYE YOUXIAN GONGSI' },
	{ code: '1014160', name: 'XIANGTANSHI JINLIANHUA SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1302113', name: 'XIANGTANSHI JINPAI SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '2003847', name: 'XIANGTANXIAN ZHONGDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1007435', name: 'XIANGXI ZIZHIZHOU HENGYU SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '764782', name: 'XIANGXI ZIZHIZHOU HUANYU TONGXUN KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '2075832', name: 'XIANGXIANGSHI WOFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '980477', name: 'XIANGYANG FEIYIN KEJI YOUXIAN GONGSI' },
	{ code: '2441585', name: 'XIANGYANG WEIWO SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1666703', name: 'XIANGYANG XINGQUAN TONGXIN YOUXIAN GONGSI' },
	{ code: '1315463', name: 'XIANGYANG YUANYI TONGXIN YOUXIAN GONGSI' },
	{ code: '616912', name: 'XIANGYANG ZHONGWANG KEJI YOUXIAN GONGSI' },
	{ code: '1305646', name: 'XIANGYANGSHI HONGXIN TIANYI TONGXUN YOUXIAN GONGSI' },
	{ code: '1262934', name: 'XIANGYANGSHI JINBAINIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '671780', name: 'XIANGYANGSHI SHIDAI HUANYU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1032961', name: 'XIANGYANGSHI SHIHAO SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1306616', name: 'XIANGYANGSHI XUANKE TONGXIN YOUXIAN GONGSI' },
	{ code: '1262925', name: 'XIANGYANGSHI YOUERXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1262916', name: 'XIANGYANGSHI ZHONGYOU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1284460', name: 'XIANGYUN SHUOCHUANG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1302772', name: 'XIANJUXIAN PANDUOLA SHOUJI SHUMACHENG YOUXIAN GONGSI' },
	{ code: '1267305', name: 'XIANNING HUANQIU SHANGMAO YOUXIAN GONGSI' },
	{ code: '2169557', name: 'XIANNING MUZIJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1307133', name: 'XIANNINGSHI XINHONGSHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2008127', name: 'XIANNINGSHI YINQUAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1310890', name: 'XIANNINGSHI YIRONG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '810177', name: 'XIANTAOSHI DAYE TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '790313', name: 'XIANTAOSHI SHOUJIA DIANZI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1233578', name: 'XIANTAOSHI YINHE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1595663', name: 'XIANXIAN HUAYUE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1709077', name: 'XIANYANG HAOTIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1267114', name: 'XIANYANG HENGDA DIANZI TONGXUN YOUXIAN GONGSI' },
	{ code: '1630225', name: 'XIANYANG QIZHONG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1321865', name: 'XIANYANG XIANFEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1290231', name: 'XIANYANG XIWANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1291794', name: 'XIANYANG YUANXING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1316078', name: 'XIANYANG ZHONGXIN TONGXIN DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1527153', name: 'XIAOGANSHI BADA TONGXUN YOUXIAN GONGSI' },
	{ code: '799734', name: 'XIAOGANSHI CHENGFENG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1800464', name: 'XIAOGANSHI KAIFAQU HEHAO TONGXIN QICAI JINGYINGBU' },
	{ code: '1017532', name: 'XIAOGANSHI KEHUA SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1290334', name: 'XIAOGANSHI LANGLI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2402200', name: 'XIAOXIAN GAOXINQI TONGXUN SHEBEI JINGYINGBU' },
	{ code: '1279726', name: 'XIAYIXIAN GAOXIN TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1759135', name: 'XIAYIXIAN YONGSHENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1076056', name: 'XICHANG XINCHAOQIANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '840766', name: 'XICHANG XUNJIE TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2546405', name: 'XICHANG XUNJIETONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1462190', name: 'XIFENGXIAN BAODI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1548477', name: 'XIHEXIAN DIQIUCUN DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1645274', name: 'XIHUASHI (NANJING) KEJI YOUXIAN GONGSI' },
	{ code: '1042707', name: 'XILINGUOLEMENG SAICHEN SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1240771', name: 'XIN\'ANXIAN YUANBO DIANZI YOUXIAN GONGSI' },
	{ code: '2065603', name: 'XINCAI XIYAHE MEIJIAHE SHANGYE YOUXIAN GONGSI' },
	{ code: '1818715', name: 'XINCHANGXIAN NANMING JIEDAO MINGXING DIANQIDIAN' },
	{ code: '1294035', name: 'XINCHANGXIAN SIYUAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1074584', name: 'XINFEIYANG SHIYE (HUNAN) YOUXIAN ZEREN GONGSI' },
	{ code: '2037822', name: 'XINFENG HUATONG SHOUJI CHAOSHI' },
	{ code: '1262301', name: 'XINFENG TIANLIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1754562', name: 'XINFENGXIAN ZHUOYUE TONGXUNDIAN' },
	{ code: '1533246', name: 'XING\'ANMENG HONGCHEN SHUMA YOUXIAN GONGSI' },
	{ code: '1275437', name: 'XINGCHENGSHI AIFENG DIANZI JINGYINGBU' },
	{ code: '1310283', name: 'XINGGUO HEMENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1960230', name: 'XINGGUOXIAN PINGXIN TONGXUN JINGXIAOBU' },
	{ code: '1785547', name: 'XINGHUASHI XINGDA TONGXUN JINGYINGBU' },
	{ code: '989226', name: 'XINGHUASHI YIDA SHUMA DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1513916', name: 'XINGLONGXIAN SHENGYOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '2534965', name: 'XINGTAI XINCAIHUI SHANGMAO YOUXIAN GONGSI' },
	{ code: '2280200', name: 'XINGTAI ZHAOHUI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2477585', name: 'XINGTAISHI DAZHI WUJIANG KEJI YOUXIAN GONGSI' },
	{ code: '1139716', name: 'XINGTAISHI GUOTONG SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '623029', name: 'XINGTAISHI HUAYE TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1551388', name: 'XINGTAISHI SIKU SHANGMAO YOUXIAN GONGSI' },
	{ code: '554420', name: 'XINGTIANKONG SHUMA (SHENZHEN) YOUXIAN GONGSI' },
	{ code: '1785531', name: 'XINGXIAN WEIFENG TONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1980298', name: 'XINGYANGSHI GUANGTONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1786807', name: 'XINGYANGSHI SUOHELU HUALIAN TONGXUN YINGYEBU' },
	{ code: '1799686', name: 'XINHEXIAN YINGJIE TONGXUN SHEBEI MENSHI' },
	{ code: '1808662', name: 'XINHUANGXIAN XINSHENG TONGXUN YINGYEBU' },
	{ code: '1548830', name: 'XINING DEHUI KEJI MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1533247', name: 'XINING DONGFANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1287330', name: 'XINING HUAYAO KEJI YOUXIAN GONGSI' },
	{ code: '1319933', name: 'XINING JINHONGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315465', name: 'XINING SHENGMINGHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '700692', name: 'XINING WANXINTONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1287357', name: 'XINING YUANFANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '648137', name: 'XINING ZEBANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '648138', name: 'XINJIANG HONGJING TONGXUN YOUXIAN GONGSI' },
	{ code: '2226657', name: 'XINJIANG HUAYI CHUANGXIN ZHINENG SHEBEI YOUXIAN GONGSI' },
	{ code: '1402000', name: 'XINJIANG JIESEN ZHUOLIAN RUANJIAN KEJI YOUXIAN GONGSI' },
	{ code: '1261857', name: 'XINJIANG SHIJI HENGTIAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1269920', name: 'XINJIANG WOJIA DIANQI YOUXIAN GONGSI' },
	{ code: '1545835', name: 'XINJIANG XINCHENG HENGXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1239575', name: 'XINJIANG XINZHI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1738888', name: 'XINJIANG ZHISHANG TONGXIN FUWU YOUXIAN GONGSI' },
	{ code: '2391220', name: 'XINLESHI BOJIE TONGXUNBU' },
	{ code: '1246174', name: 'XINLESHI GUOXUN TONGXUN SHANGDIAN' },
	{ code: '2039204', name: 'XINMISHI SHIQU CHENGXIN TONGXUN SHANGHANG' },
	{ code: '1396880', name: 'XINPING LONGSHENG TONGXIN YOUXIAN GONGSI' },
	{ code: '1595353', name: 'XINTIANJI (TIANJIN) SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1801412', name: 'XINTIANXIAN JINTONG SHOUJI LIANSUODIAN' },
	{ code: '1542792', name: 'XINTIANXIAN XINGHE KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1279164', name: 'XINXIANGSHI HONGEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1420151', name: 'XINXIANGSHI HONGHOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1742945', name: 'XINXIANGSHI KAIJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '2380650', name: 'XINXIANGSHI LIYE TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1777202', name: 'XINXIANGSHI RUNTAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1673155', name: 'XINXIANGSHI XINQIHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '671729', name: 'XINXIANGSHI XINYI HUANAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1286749', name: 'XINXIANGSHI ZHANPENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '696065', name: 'XINXIANGSHI ZHANXUN DIANZI YOUXIAN GONGSI' },
	{ code: '849844', name: 'XINYANG XIYA HEMEI JIAHE SHANGYE YOUXIAN GONGSI' },
	{ code: '1687174', name: 'XINYANG ZHONGDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1542796', name: 'XINYANGSHI GUORUI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1779105', name: 'XINYANGSHI XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '2067095', name: 'XINYEXIAN SHANSHAN KEJI YOUXIAN GONGSI' },
	{ code: '1036922', name: 'XINYISHI FENGXING DIANXUN QICAI YOUXIAN GONGSI' },
	{ code: '1258497', name: 'XINYISHI LIANNENG SHANGYE MAOYI YOUXIAN GONGSI' },
	{ code: '1738878', name: 'XINYU DINGDING DIANZI YOUXIAN GONGSI' },
	{ code: '671779', name: 'XINYULOU BAIHUO JITUAN YOUXIAN GONGSI' },
	{ code: '1016799', name: 'XINYUSHI FENGCHUN TONGXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1341050', name: 'XINYUSHI SHUIJINGGONG TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1318586', name: 'XINZHONGGUAN ZHINENG KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '1027903', name: 'XINZHOUSHI YONGTONG DIANZI YOUXIAN GONGSI' },
	{ code: '2002917', name: 'XIONGXIAN YOUFU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1413990', name: 'XISHUANGBANNA JINGGONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1320622', name: 'XIUSHAN JINPENG TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1282034', name: 'XIUSHUIXIAN TAIPINGYANG SHOUJI SHUMACHENG YOUXIAN GONGSI' },
	{ code: '1397602', name: 'XIUYAN MANZU ZIZHIXIAN QICHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1948802', name: 'XIWUZHUMUQINQI LEXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1548832', name: 'XIXIAN DONGHAO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1791287', name: 'XIXIAN QUANWANGTONG SHOUJI MAICHANGDIAN' },
	{ code: '1342993', name: 'XIXIAN XIONGDI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '980409', name: 'XIZANG HONGKAI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '2370389', name: 'XIZANG KU\'AI TONGXIN YOUXIAN GONGSI' },
	{ code: '893362', name: 'XIZANG KU\'AI TONGXIN YOUXIAN GONGSI' },
	{ code: '1819140', name: 'XIZANG MEIZHIZU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1489466', name: 'XIZANG YUANWANG SHUMA TONGXIN YOUXIAN GONGSI' },
	{ code: '753523', name: 'XUANCHENG XUNDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '682777', name: 'XUANCHENGSHI YICHENG TONGXIN YOUXIAN GONGSI' },
	{ code: '1179455', name: 'XUANWEISHI KEBAO TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2413225', name: 'XUANWEISHI XIANGRUI SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1144101', name: 'XUCHANG BINHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1012433', name: 'XUCHANG JUNDE KEJI YOUXIAN GONGSI' },
	{ code: '1769834', name: 'XUCHANG WEILAIXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1478275', name: 'XUCHANGSHI DISANJIADIAN SHANGCHANG YOUXIAN GONGSI' },
	{ code: '564264', name: 'XUCHANGSHI PANGDONGLAI (JITUAN) HUAYU DIANQI YOUXIAN GONGSI' },
	{ code: '2382471', name: 'XUCHANGSHI PANGDONGLAI DIANQI YOUXIAN GONGSI' },
	{ code: '2009753', name: 'XUNHUAXIAN KAIYUAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1284593', name: 'XUNKEXIAN YITONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1263021', name: 'XUSHUIXIAN DAKE TONGXUN QICAI JINGXIAOBU' },
	{ code: '1417821', name: 'XUSHUIXIAN YONGHUA DIANZICHENG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1809296', name: 'XUYI YIDA TONGXUN QICAI JINGYINGBU' },
	{ code: '887985', name: 'XUZHOU HONGTAI TONGXUN SHIYE YOUXIAN GONGSI' },
	{ code: '1311804', name: 'XUZHOU JIACHENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1740341', name: 'XUZHOU LEXIANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1273920', name: 'XUZHOU SHIJIE DIANXUN YOUXIAN GONGSI' },
	{ code: '1195436', name: 'XUZHOU XUNJIA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '2533225', name: 'XUZHOU ZHUOSHENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1037331', name: 'XUZHOU ZHUOYUE ZHONGCHEN KEJI YOUXIAN GONGSI' },
	{ code: '1782473', name: 'YAAN RUNZE HENGXIN TONGXIN SHEBEI YOUXIAN ZEREN GONGSI' },
	{ code: '1300847', name: 'YAAN YIHE TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1295989', name: 'YAANSHI XINLI KEJI YOUXIAN GONGSI' },
	{ code: '1786916', name: 'YAANSHI YUCHENGQU QIRUN TONGXUN QICAI JINGYINGBU' },
	{ code: '1808572', name: 'YAANSHI YUCHENGQU XINLIAN TONGXUNBU' },
	{ code: '1286155', name: 'YAKESHI HONGTAIYANG DIANZI KEMAO YOUXIAN GONGSI' },
	{ code: '1290255', name: 'YAKESHISHI HENGTONG DIANZI SHANGMAO YOUXIAN GONGSI' },
	{ code: '695346', name: 'YAMAXUN (ZHONGGUO) TOUZI YOUXIAN GONGSI' },
	{ code: '1338168', name: 'YAN\'AN DIXINTONG GONGMAO YOUXIAN GONGSI' },
	{ code: '1054910', name: 'YAN\'AN SANRUI SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1522445', name: 'YAN\'AN TIANHEREN DIANZI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1400969', name: 'YANBIAN CHANGHONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '2060011', name: 'YANBIAN JIEXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1469203', name: 'YANBIAN JINMA TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1400807', name: 'YANBIAN ZHONGXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1160212', name: 'YANCHENG DECHEN DIANZI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '925668', name: 'YANCHENG FUTONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1739604', name: 'YANCHENG HUAISHENG DIANZI YOUXIAN GONGSI' },
	{ code: '616918', name: 'YANCHENG SHANRUI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1266651', name: 'YANCHENGSHI BEIDOUXING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1290237', name: 'YANCHENGSHI HENGQI KEJI YOUXIAN GONGSI' },
	{ code: '2380583', name: 'YANCHENGSHI LIANYU TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '679267', name: 'YANCHENGSHI XINGZHI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1297199', name: 'YANGGAOXIAN YUEXING TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1672994', name: 'YANGGU SANRENXING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1228533', name: 'YANGJIANGSHI ZHIYI DIANXUN YOUXIAN GONGSI' },
	{ code: '897276', name: 'YANGQUAN YUNHAI KEJI YOUXIAN GONGSI' },
	{ code: '2035594', name: 'YANGQUANSHI GUANGYANG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2000131', name: 'YANGXIN ZHONGJIN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1287371', name: 'YANGXIN ZHONGYUAN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1978148', name: 'YANGXINXIAN XINGGUOZHEN QIJIAN TONGXUN JINGYINGBU' },
	{ code: '1251817', name: 'YANGZHOU AIFENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '688035', name: 'YANGZHOU FEISU WUXIAN HULIANWANG TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '551916', name: 'YANGZHOU HUANQIU TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1739534', name: 'YANGZHOU JIUXING WANGLUO JISHU YOUXIAN GONGSI' },
	{ code: '2381097', name: 'YANGZHOU QIANGHENG DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1270111', name: 'YANGZHOU XIAOTIAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1314803', name: 'YANGZHOU YUNGU MAOYI YOUXIAN GONGSI' },
	{ code: '1745051', name: 'YANGZHOUSHI GOUBAI DIANQI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1297848', name: 'YANGZHOUSHI HUANGJIAN TONGXUN SHUMA QICAI YOUXIAN GONGSI' },
	{ code: '1784507', name: 'YANGZHOUSHI SHENGLAN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '925122', name: 'YANGZHOUSHI WANGZHE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1787905', name: 'YANHE GONGYING TONGXUN YOUXIAN GONGSI' },
	{ code: '2000138', name: 'YANJINXIAN PINFEI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1763458', name: 'YANJISHI XINHUA MAOYI YOUXIAN GONGSI' },
	{ code: '1745607', name: 'YANLINGXIAN YIFENLI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1297158', name: 'YANSHANXIAN YONGLIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1680660', name: 'YANSHISHI HUAIXIN NANLU HUAXIN SHOUJIDIAN' },
	{ code: '1981569', name: 'YANSHISHI HUAXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1816147', name: 'YANSHISHI SHIJI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1393928', name: 'YANSHOUXIAN XINLIDA TONGXUN SHANGDIAN' },
	{ code: '2712589', name: 'YANTAI BAOTONG SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '556454', name: 'YANTAI BAOTONG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '2458905', name: 'YANTAI DESHENG MAOXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1311959', name: 'YANTAI DONGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '835524', name: 'YANTAI JINTONG DIANQI YOUXIAN GONGSI' },
	{ code: '1045497', name: 'YANTAI LIANYU RUNXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1393516', name: 'YANTAI LIXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1303746', name: 'YANTAI XINWANG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1183192', name: 'YANTAI YU\'ANG GUOJI MAOYI YOUXIAN GONGSI' },
	{ code: '1290249', name: 'YANTAISHI WEILILAI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '439183', name: 'YANTAISHI ZHUOYUZHENGXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1856079', name: 'YAO\'ANXIAN TUHONG SHUMA CHANPIN JINGYINGBU' },
	{ code: '551983', name: 'YATAI DIANTONG TOUZI JITUAN YOUXIAN GONGSI' },
	{ code: '1070019', name: 'YIBIN JIAHAI DIANZI YOUXIAN GONGSI' },
	{ code: '2460625', name: 'YIBINSHI BAOYUANHUI MAOYI YOUXIAN GONGSI' },
	{ code: '2307080', name: 'YIBINSHI BIAODIE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '2081793', name: 'YIBINSHI JIATEJIE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1235791', name: 'YIBINSHI XINDAXIANG MAOYI YOUXIAN ZEREN GONGSI' },
	{ code: '1474442', name: 'YICHANG FANGPING TONGXUN MAOYI YOUXIAN GONGSI' },
	{ code: '1233622', name: 'YICHANG HUAWO TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1249197', name: 'YICHANG JIAXING DIANXUN YOUXIAN GONGSI' },
	{ code: '1800457', name: 'YICHANGSHI YILINGQU FANGPING TONGXUN QICAI JINGYINGBU' },
	{ code: '1742960', name: 'YICHUANXIAN BOZHITU DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '945344', name: 'YICHUANXIAN HAOZE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1769799', name: 'YICHUANXIAN HUAYAN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1017510', name: 'YICHUN GUOXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1785476', name: 'YICHUN HONGCHENGBAO TONGXUN YOUXIAN GONGSI' },
	{ code: '2475365', name: 'YICHUNSHI LIANZHONG XINBANG TONGXUN SHEBEI JINGXIAO YOUXIAN GONGSI' },
	{ code: '1358754', name: 'YICHUNSHI LVYING TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '980442', name: 'YICHUNSHI QUANQIUTONG TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1381165', name: 'YICHUNSHI SIWEIDA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '783826', name: 'YICHUNSHI TIANYIN TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1663286', name: 'YICHUNSHI YICHUNQU QUANNENGTONG SHOUJI MAICHANG' },
	{ code: '2008084', name: 'YIDUSHI HANYI TONGXUN YOUXIAN GONGSI' },
	{ code: '1392750', name: 'YIFENGXIAN YITE MAOYI YOUXIAN GONGSI' },
	{ code: '1966000', name: 'YIJINHUOLUOQI YUNTONG SHOUJI ZHUANYINGDIAN' },
	{ code: '2115070', name: 'YILANXIAN WANQUAN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1406084', name: 'YILANXIAN XUEYING YIDONG TONGXUN' },
	{ code: '705730', name: 'YILI XINDIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1768089', name: 'YILIAN XUNDA (BEIJING) KEJI YOUXIAN GONGSI' },
	{ code: '1175383', name: 'YILIANG WANGZHE CHAOYUE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1764282', name: 'YILIANGXIAN GUOFENG DIANXUN SHOUJIDIAN' },
	{ code: '1977886', name: 'YILONGXIAN XINZHENGZHEN CHENGUANG TONGXUN' },
	{ code: '1516088', name: 'YILONGXIAN XINZHENGZHEN TIANCHENG TONGXUNCHENG' },
	{ code: '1219533', name: 'YINANXIAN TIANXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1036107', name: 'YINCHUAN CHUANGGE SHUMA KEMAO YOUXIAN GONGSI' },
	{ code: '1287363', name: 'YINCHUAN JUNHAIXIN TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1296000', name: 'YINCHUAN QIANGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '616442', name: 'YINCHUAN XINHUA BAIHUO DONGQIAO DIANQI YOUXIAN GONGSI' },
	{ code: '1301577', name: 'YINCHUAN ZIQING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1310171', name: 'YINCHUANSHI JINFENGQU ZHENGYU SHOUJI TONGXUN GUANGCHANG' },
	{ code: '1057261', name: 'YINGCHENGSHI LIANDA SHUMA YOUXIAN GONGSI' },
	{ code: '2056413', name: 'YINGDESHI XINXIONGXIN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1560988', name: 'YINGJIANG XIANGCHENG SHUMA MAOYI YOUXIAN GONGSI' },
	{ code: '2066415', name: 'YINGKOU BEICHEN QIKEXING TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1291802', name: 'YINGKOU GUANGSHUNDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '2079878', name: 'YINGKOU JINGJI JISHU KAIFAQU HONGYUN XINGLONG SHOUJI GUANGCHANG' },
	{ code: '799895', name: 'YINGKOU ZHONGYI GUANGCHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1716594', name: 'YINGSHAN CHUNJIANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1492292', name: 'YINGSHANG HENGXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1779962', name: 'YINGTAN JIASHENG DIANZI YOUXIAN GONGSI' },
	{ code: '1005467', name: 'YINGTANSHI XINDONGFANG BOYUAN SHUMA YOUXIAN GONGSI' },
	{ code: '1354159', name: 'YINGTANSHI YUEHUQU XINDONGFANG SHUMA YOUXIAN GONGSI' },
	{ code: '1259513', name: 'YINGXIAN BEI‘ER TONGXUN YOUXIAN GONGSI' },
	{ code: '1296524', name: 'YINTERUISI (BEIJING) XINXI KEJI YOUXIAN GONGSI SHANGHAI GUBEI FENGONGSI' },
	{ code: '1393464', name: 'YITONG MANZU ZIZHIXIAN HONGDA SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1279578', name: 'YIWUSHI HUANCHI MEILE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1054394', name: 'YIWUSHI JINSHA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1279610', name: 'YIWUSHI LIMEI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1249638', name: 'YIWUSHI SHANGJI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '687992', name: 'YIWUSHI YIMEI SHOUJI KEJI YOUXIAN GONGSI' },
	{ code: '1315590', name: 'YIXIAN JINMAO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1314968', name: 'YIXIAN LIANHUA TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1980580', name: 'YIXIAN QUANYOU TONGXUN QICAI XIAOSHOU ZHONGXIN' },
	{ code: '1267293', name: 'YIXINGSHI GUOXUN TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1336643', name: 'YIYANG ANGE JIENENG SHEBEI YOUXIAN GONGSI' },
	{ code: '692116', name: 'YIYANG HONGXIANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1695562', name: 'YIYANGSHI GUOXIANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1314842', name: 'YIYANGXIAN HUAXIN KEJI YOUXIAN GONGSI' },
	{ code: '1291940', name: 'YIYUAN ZHAOHAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1017522', name: 'YONGCHENGSHI XUANMING SHANGMAO YOUXIAN GONGSI' },
	{ code: '1957816', name: 'YONGDINGQU YUANHANG TONGXUNDIAN' },
	{ code: '951870', name: 'YONGKANGSHI JINSHOUZHI SHUMA CHANPIN DIAN' },
	{ code: '679452', name: 'YONGKANGSHI YONGJUN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1413338', name: 'YONGNIANXIAN GUANGGE TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1290256', name: 'YONGSHUN MINZU MAOYI YOUXIAN GONGSI' },
	{ code: '1786912', name: 'YONGXINGXIAN LICHANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1427001', name: 'YONGXINGXIAN TIANDI TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '2072309', name: 'YONGXINGXIAN TIANDI WANGLUO TONGXUN QIJIANDIAN' },
	{ code: '1816692', name: 'YONGXINXIAN XINYONGXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1381179', name: 'YONGXIUXIAN TUBUZHEN XINCHENG YIDONG TONGXIN DAPING SHOUJI GUANGCHANG' },
	{ code: '1660722', name: 'YONGZHOUSHI DONGFANGHONG TONGXUN DIANZI GUANLI YOUXIAN GONGSI' },
	{ code: '1310129', name: 'YONGZHOUSHI JINWANSHUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1301005', name: 'YONGZHOUSHI LEMI TONGXUN YOUXIAN GONGSI' },
	{ code: '1398192', name: 'YONGZHOUSHI LENGSHUITAN WEIXINDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1770130', name: 'YOUXIAN XIANGLONG TONGXIN YOUXIAN GONGSI' },
	{ code: '1286115', name: 'YOUYANGXIAN JINQIAO SHUMA KEJI YOUXIAN ZEREN GONGSI' },
	{ code: '1816115', name: 'YOUYANGXIAN YUGE TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1969521', name: 'YOUYIXIAN YIYANG CHAOPIN SHUMA SHENGHUOGUAN' },
	{ code: '1539022', name: 'YOUYUXIAN HONGBO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1693404', name: 'YUANJIANG HONGSHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1969130', name: 'YUANMOU GUOYU MAOYI YOUXIAN GONGSI' },
	{ code: '1279108', name: 'YUANPINGSHI WEICHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '899961', name: 'YUANPINGSHI YUANPING LONGTENG CHAODA GOUWU SHANGCHENG' },
	{ code: '1707266', name: 'YUANQUXIAN TIANXIA TENGLONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2087816', name: 'YUANSHIXIAN XUNDA TONGXUN QICAI JINGYINGBU' },
	{ code: '1773923', name: 'YUANSHIXIAN YONGHENG DIANZI SHANGWU YOUXIAN GONGSI' },
	{ code: '1293179', name: 'YUANYANGXIAN YUSHENG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1811036', name: 'YUANZHOUQU CHENGDONG QUANQIUTONG SHOUJIDIAN' },
	{ code: '1968571', name: 'YUCHENGXIAN FANGFANG SHOUJI JINGYINGBU' },
	{ code: '1301724', name: 'YUDU SHENZHOU SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1316714', name: 'YUECHIXIAN HUAYU TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1331111', name: 'YUEQINGSHI HENGYUAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1966915', name: 'YUEQINGSHI LIUSHI CHANGXIN TONGXUNDIAN' },
	{ code: '1542554', name: 'YUEQINGSHI ZHONGXUN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1510179', name: 'YUEXIXIAN WANHONG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1798225', name: 'YUEYANG (TIANJIN) KEJI YOUXIAN GONGSI' },
	{ code: '1698160', name: 'YUEYANG CHAOYUE TONGXIN LIANSUO YOUXIAN GONGSI' },
	{ code: '2005352', name: 'YUEYANG SHIJI ZHONGYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1373707', name: 'YUEYANG WANGJIE SHUMA CHANPIN YOUXIAN GONGSI' },
	{ code: '1339264', name: 'YUEYANG WEIPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '764780', name: 'YUEYANG XINJIE MAOYI YOUXIAN GONGSI' },
	{ code: '1300917', name: 'YUEYANG YUNDUAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1330979', name: 'YUEYANGSHI JIAQI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1799738', name: 'YUEYANGXIAN YUNDUAN TONGXUN WANFULAIDIAN' },
	{ code: '1786904', name: 'YUGANXIAN QIANGLI SHOUJICHENG' },
	{ code: '1288024', name: 'YUGANXIAN YUZHI KEJI YOUXIAN GONGSI' },
	{ code: '1745026', name: 'YUHUAN WANRUN SHANGWU TONGXIN YOUXIAN GONGSI' },
	{ code: '1516094', name: 'YUHUANXIAN DIXINTONG TONGXIN SHEBEI SHANGHANG' },
	{ code: '1548478', name: 'YULINSHI HENGJI WEIYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1316206', name: 'YULINSHI HERUNFENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1119422', name: 'YULINSHI WEIFENG WEIYE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1320515', name: 'YULINSHI XINLE SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1615792', name: 'YULINSHI ZHONGSHENGXING SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1682268', name: 'YUMENSHI XINTIANHE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315450', name: 'YUNANXIAN HEXINGDA SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1280469', name: 'YUNCHENG DABENMAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1280368', name: 'YUNCHENGSHI HAOHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1130235', name: 'YUNCHENGSHI HEZHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1478345', name: 'YUNCHENGSHI LIANCHUANG DIANZI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '700711', name: 'YUNCHENGSHI SENBAO TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1485009', name: 'YUNCHENGSHI XINGUANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '2712465', name: 'YUNCHENGSHI YIGAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '783322', name: 'YUNFUSHI ZIYOUYI DIANXIN YOUXIAN GONGSI' },
	{ code: '1817564', name: 'YUNMENG YUETONG XINSHIKONG TONGXIN GUANGCHANG' },
	{ code: '1284445', name: 'YUNNAN BOTAI MAOYI YOUXIAN GONGSI' },
	{ code: '1305772', name: 'YUNNAN DECHUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1257484', name: 'YUNNAN DIBO KEJI YOUXIAN GONGSI' },
	{ code: '1710270', name: 'YUNNAN DINGDANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1280270', name: 'YUNNAN HUAXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1737888', name: 'YUNNAN JIUJI DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1718825', name: 'YUNNAN JUEZHEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1429027', name: 'YUNNAN KAISHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1479239', name: 'YUNNAN KUAYING SHANGMAO YOUXIAN GONGSI' },
	{ code: '574690', name: 'YUNNAN QUANQIU TONGXIN YOUXIAN GONGSI' },
	{ code: '1651312', name: 'YUNNAN SANJIU WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '549005', name: 'YUNNAN XIHUA TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1244007', name: 'YUNNAN XUZHANYU KEJI YOUXIAN GONGSI' },
	{ code: '1286580', name: 'YUNNAN YISHUN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1400888', name: 'YUNNAN YUXI YUKE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1708793', name: 'YUNNAN ZHONGPASHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1310102', name: 'YUNNAN ZHUANGNAN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1691921', name: 'YUNNAN ZHUOCHENG KEJI YOUXIAN GONGSI' },
	{ code: '1194779', name: 'YUNNANSHENG TONGXIN CHANYE FUWU YOUXIAN GONGSI' },
	{ code: '1413480', name: 'YUNYANGXIAN KEMEI TONGXIN MENSHI' },
	{ code: '2008118', name: 'YUNYANGXIAN SHENGJIE SHANGMAO YOUXIAN GONGSI' },
	{ code: '1309470', name: 'YUSHANXIAN HONGSHENG MAOYI YOUXIAN GONGSI' },
	{ code: '1780277', name: 'YUSHANXIAN RUIDE MAOYI YOUXIAN GONGSI' },
	{ code: '1249205', name: 'YUSHUSHI HUACHANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1235840', name: 'YUTAI BAISHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1601258', name: 'YUTIANXIAN LETU TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1266176', name: 'YUXI JIAXIN KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1396874', name: 'YUXI QIANDA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1368007', name: 'YUXI XINGYAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1543747', name: 'YUXI ZHISHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1270019', name: 'YUXI ZHUOYUE TONGXIN YOUXIAN GONGSI' },
	{ code: '2066092', name: 'YUXISHI HENGXUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '690386', name: 'YUYAOSHI XINGJITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2035538', name: 'ZANHUANGXIAN QINGTIAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1992781', name: 'ZAOYANGSHI JIALIAN DIANZI CHANPIN YOUXIAN GONGSI' },
	{ code: '1785532', name: 'ZAOZHUANG JINWEI TONGXUN JISHU YOUXIAN GONGSI' },
	{ code: '1516100', name: 'ZHALAITEQI LONGXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1510182', name: 'ZHALANTUNSHI BUBUGAO DAIBANDIAN' },
	{ code: '1809300', name: 'ZHALANTUNSHI TIANYU HONGTAIYANG SHOUJI DAMAICHANG' },
	{ code: '2037835', name: 'ZHALUTEQI LUBEIZHEN XINDA TONGXUN FENQIBU' },
	{ code: '1467868', name: 'ZHALUTEQI XINDA TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1119794', name: 'ZHANGJIAGANGSHI BAOYI DIANQI YOUXIAN GONGSI' },
	{ code: '2263737', name: 'ZHANGJIAGANGSHI JINHENGJIAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '883462', name: 'ZHANGJIAJIE DONGFANG SHOUJICHENG YOUXIAN GONGSI' },
	{ code: '1542793', name: 'ZHANGJIAJIE HEXUNTONG SHEBEI YOUXIAN GONGSI' },
	{ code: '810211', name: 'ZHANGJIAJIE HULIANTONG DIANZI YOUXIAN GONGSI' },
	{ code: '2503365', name: 'ZHANGJIAJIE ZHIXIN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '840770', name: 'ZHANGJIAKOU DAHENG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1365296', name: 'ZHANGJIAKOU DAHENG SHUMA SHANGMAO ZHONGXIN' },
	{ code: '1762648', name: 'ZHANGJIAKOU GAOXINGTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1495720', name: 'ZHANGJIAKOU JUHAI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1307160', name: 'ZHANGJIAKOU SHENGYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1233629', name: 'ZHANGJIAKOUSHI MINGXUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1321889', name: 'ZHANGJIAKOUSHI XINRISHANG YIDONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1270573', name: 'ZHANGPINGSHI SIHAI TONGXUN YOUXIAN GONGSI' },
	{ code: '1100429', name: 'ZHANGPUXIAN HENGTONG DIANXUN KEJI YOUXIAN GONGSI' },
	{ code: '1057607', name: 'ZHANGQIU JUXIN HENGTONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1802289', name: 'ZHANGSHUSHI HONGXIANG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1076048', name: 'ZHANGSHUSHI SHUNING MAOYI YOUXIAN GONGSI' },
	{ code: '2008925', name: 'ZHANGWUXIAN ZHIGAO LIANWANG ZHUANYINGDIAN' },
	{ code: '1310934', name: 'ZHANGWUXIAN ZHIGAO TONGXUN SHANGHANG' },
	{ code: '1992785', name: 'ZHANGYE SHUNYU TONGXUN YOUXIAN GONGSI' },
	{ code: '1474986', name: 'ZHANGYE YUEDA SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1293366', name: 'ZHANGZHOU ZHONGDA TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1507512', name: 'ZHANGZHOUSHI BITE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1310758', name: 'ZHANGZHOUSHI TIANYITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1396922', name: 'ZHANJIANG BOZHIDAO KEJI YOUXIAN GONGSI' },
	{ code: '1233584', name: 'ZHANJIANG JIAHE TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '783646', name: 'ZHANJIANG JINHUA SHUMA TONGXUN YOUXIAN GONGSI' },
	{ code: '1341803', name: 'ZHANJIANGSHI HAOSHIJIE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1297279', name: 'ZHANJIANGSHI HENGBO TONGXIN YOUXIAN GONGSI' },
	{ code: '1315616', name: 'ZHAODONGSHI TIANTIAN SHOUJI DAMAICHANG' },
	{ code: '2456388', name: 'ZHAODONGSHI XINWEIYI SHANGMAO YOUXIAN GONGSI' },
	{ code: '1815699', name: 'ZHAODONGSHI YONGXIANG TIANTIAN SHOUJI DAMAICHANG' },
	{ code: '1745020', name: 'ZHAOPINGXIAN ZHENHE TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2099060', name: 'ZHAOQINGSHI DUANZHOUQU JIEXUN TONGXIN YOUXIAN GONGSI' },
	{ code: '682673', name: 'ZHAOQINGSHI GUOXIN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1398198', name: 'ZHAOQINGSHI JUWANG TONGXUN YOUXIAN GONGSI' },
	{ code: '916969', name: 'ZHAOTONGSHI WANGFENG JINGMAO YOUXIAN GONGSI' },
	{ code: '2081838', name: 'ZHAOTONGSHI ZHAOYANGQU ANCHEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1677205', name: 'ZHAOTONGSHI ZHAOYANGQU BINGGUO TONGXUN QICAI JINGYINGBU' },
	{ code: '2472406', name: 'ZHAOXIAN CHANGHAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '760311', name: 'ZHAOYUAN HUAXIN DIANZI YOUXIAN GONGSI' },
	{ code: '1479249', name: 'ZHAOYUANXIAN TIANCHENG TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2009687', name: 'ZHAOYUANXIAN TIANYU TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1977862', name: 'ZHAOZHOUXIAN JINPIN KEJI YOUXIAN GONGSI' },
	{ code: '1369047', name: 'ZHAOZHOUXIAN TONGCHUANG KEJI YOUXIAN GONGSI' },
	{ code: '2532865', name: 'ZHAOZHOUXIAN XINTONGCHUANG KEJI YOUXIAN GONGSI' },
	{ code: '1722688', name: 'ZHEJIANG ANRUN KEJI YOUXIAN GONGSI' },
	{ code: '1014632', name: 'ZHEJIANG BOYI TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '556402', name: 'ZHEJIANG DATANG DIANZI TONGXIN YOUXIAN GONGSI' },
	{ code: '964538', name: 'ZHEJIANG DIBOLAI SHIYE GUFEN YOUXIAN GONGSI' },
	{ code: '1772520', name: 'ZHEJIANG HONGRI TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1752429', name: 'ZHEJIANG HUABAIHUI WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '752911', name: 'ZHEJIANG HUAYI TONGXIN SHEBEI FUWU YOUXIAN GONGSI' },
	{ code: '1338021', name: 'ZHEJIANG HUIPU DIANZI TONGXIN YOUXIAN GONGSI' },
	{ code: '1739588', name: 'ZHEJIANG JIANGSHAN TIANCHEN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1739493', name: 'ZHEJIANG LANTUO WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '1995184', name: 'ZHEJIANG LIANDA SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '1720529', name: 'ZHEJIANG LIANFA CHUANGXIN DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '564590', name: 'ZHEJIANG LIANYU KEJI FUWU YOUXIAN GONGSI' },
	{ code: '540079', name: 'ZHEJIANG MEICHENG SHUMA KEJI JITUAN YOUXIAN GONGSI' },
	{ code: '1645283', name: 'ZHEJIANG QIYUE SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '2073126', name: 'ZHEJIANG SANMEN LUTONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '2063292', name: 'ZHEJIANG SHISHUO XINXI KEJI YOUXIAN GONGSI' },
	{ code: '623031', name: 'ZHEJIANG TAIYANG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1286731', name: 'ZHEJIANG TIANYA TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1194120', name: 'ZHEJIANG XUNLIAN MAOYI YOUXIAN GONGSI' },
	{ code: '1330969', name: 'ZHEJIANG YINYAN TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1310156', name: 'ZHEJIANG YITONG KEJI YOUXIAN GONGSI' },
	{ code: '783306', name: 'ZHEJIANG YONGXIN SHUMA KEJI YOUXIAN GONGSI' },
	{ code: '549000', name: 'ZHEJIANG ZHENHUA TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1752379', name: 'ZHEJIANG ZHEXUN TONGXIN JISHU YOUXIAN GONGSI' },
	{ code: '1759623', name: 'ZHEJIANG ZUNCHENG XINXI JISHU YOUXIAN GONGSI' },
	{ code: '691399', name: 'ZHEJIANGSHENG DONGYANGSHI DAJIDA TONGXIN YOUXIAN GONGSI' },
	{ code: '1817641', name: 'ZHENGDINGXIAN HUANA TONGXIN JINGYINGBU' },
	{ code: '1286796', name: 'ZHENGDINGXIAN YOUJIA YOUPIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1486770', name: 'ZHENGYANG HONGWEI DIANXUN YOUXIAN GONGSI' },
	{ code: '564265', name: 'ZHENGZHOU CHAOXUNFA KEJI YOUXIAN GONGSI' },
	{ code: '952076', name: 'ZHENGZHOU CHENXU KEJI YOUXIAN GONGSI' },
	{ code: '1399374', name: 'ZHENGZHOU CHUANGXIN SHIDAI DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '1043446', name: 'ZHENGZHOU GAOHUA SHANGMAO YOUXIAN GONGSI' },
	{ code: '1991820', name: 'ZHENGZHOU GAOYOUGAO SHANGMAO YOUXIAN GONGSI' },
	{ code: '1042068', name: 'ZHENGZHOU HAOXUNTONG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1699527', name: 'ZHENGZHOU HENGXUNDA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1262398', name: 'ZHENGZHOU HONGTU TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1648893', name: 'ZHENGZHOU PENGHEJUN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1315555', name: 'ZHENGZHOU SHENGYU TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1316163', name: 'ZHENGZHOU TONGYILIAN TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1177346', name: 'ZHENGZHOU YACHEN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1217998', name: 'ZHENGZHOU YIJIE TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2170177', name: 'ZHENGZHOU YIYING DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1300770', name: 'ZHENGZHOU YUDA KEMAO YOUXIAN GONGSI' },
	{ code: '2363897', name: 'ZHENGZHOUSHI HUAXIN TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '2304243', name: 'ZHENGZHOUSHI SHENGTONG TONGXIN YOUXIAN GONGSI' },
	{ code: '700717', name: 'ZHENGZHOUSHI YINGXING KEMAO YOUXIAN GONGSI' },
	{ code: '843519', name: 'ZHENGZHOUSHI ZHONGZHOU KEMAO YOUXIAN GONGSI' },
	{ code: '2099068', name: 'ZHENGZHOUSHI ZHONGZHOU TONGXUN YOUXIAN GONGSI' },
	{ code: '1338059', name: 'ZHENJIANG GUOMAO TONGXUN SHEBEI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1802300', name: 'ZHENJIANG HENGYUAN TONGXUN DIANZI YOUXIAN GONGSI' },
	{ code: '1739830', name: 'ZHENJIANG SUDA TONGXUN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1316063', name: 'ZHENJIANGSHI GUOFEI TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1318638', name: 'ZHENJIANGSHI XINDONGYA TONGXUN YOUXIAN GONGSI' },
	{ code: '1310248', name: 'ZHENLAIXIAN DATANG GOUWU YOUXIAN GONGSI' },
	{ code: '1236930', name: 'ZHENPINGXIAN SANHONG TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1536203', name: 'ZHENXIONGXIAN WUFENGZHEN HAISHUMA SHOUJIDIAN' },
	{ code: '1320521', name: 'ZHIJIANG CHENGXIN KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1682655', name: 'ZHIJIANG HUAWO TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '696061', name: 'ZHONGGUO YOUDIAN QICAI CHONGQING GONGSI' },
	{ code: '1231235', name: 'ZHONGGUO YOUDIAN QICAI JITUAN YOUXIAN GONGSI' },
	{ code: '2530985', name: 'ZHONGKE MAIXIANG (BEIJING) KEJI YOUXIAN GONGSI' },
	{ code: '1232988', name: 'ZHONGMOUXIAN YUFA TONGXIN YOUXIAN GONGSI' },
	{ code: '1809365', name: 'ZHONGMUXIAN WANGRUI SHOUJIDIAN' },
	{ code: '1279567', name: 'ZHONGSHAN WEIYU MAOYI YOUXIAN GONGSI' },
	{ code: '1560603', name: 'ZHONGSHANSHI BADA YONGCHENG TONGXIN KEJI YOUXIAN GONGSI' },
	{ code: '1560990', name: 'ZHONGSHANSHI DAGUO HONGTU TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1303552', name: 'ZHONGSHANSHI DONGHUAXUN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1398693', name: 'ZHONGSHANSHI DONGSHENGZHEN LIANYU TONGXUN SHANGHANG' },
	{ code: '783317', name: 'ZHONGSHANSHI DONGYAO DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1302745', name: 'ZHONGSHANSHI ERSHIYI DIANXUN YOUXIAN GONGSI' },
	{ code: '1302705', name: 'ZHONGSHANSHI GUZHEN HENGTAO TONGBAO SHUMADIAN' },
	{ code: '1314217', name: 'ZHONGSHANSHI HEJITONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1220671', name: 'ZHONGSHANSHI HUASHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '1261754', name: 'ZHONGSHANSHI HUAYING TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1303554', name: 'ZHONGSHANSHI JIAXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1283903', name: 'ZHONGSHANSHI LIANYU DIANXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1330149', name: 'ZHONGSHANSHI RONGHAO DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1316034', name: 'ZHONGSHANSHI TIANZHIYU KEJI YOUXIAN GONGSI' },
	{ code: '1302739', name: 'ZHONGSHANSHI XINGQIBA TOUZI YOUXIAN GONGSI' },
	{ code: '1307126', name: 'ZHONGSHANSHI YISHENG FAZHAN TONGXIN YOUXIAN GONGSI' },
	{ code: '560502', name: 'ZHONGSHANSHI ZHINENG YONGCHENG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1779849', name: 'ZHONGSHANSHI ZHONGYUAN TONGXIN YOUXIAN GONGSI' },
	{ code: '2105033', name: 'ZHONGTONGFU WANGYING KEJI YOUXIAN GONGSI' },
	{ code: '1789117', name: 'ZHONGWEISHI JUDINGXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1173432', name: 'ZHONGWEISHI LEIXIN SHANGMAO YOUXIAN GONGSI' },
	{ code: '689315', name: 'ZHONGYOU PUTAI TONGXIN FUWU GUFEN YOUXIAN GONGSI' },
	{ code: '1232862', name: 'ZHONGYOU SHIDAI DIANXUN KEJI YOUXIAN GONGSI' },
	{ code: '540883', name: 'ZHONGYU DIANXUN LIANSUO JITUAN GUFEN YOUXIAN GONGSI' },
	{ code: '1752761', name: 'ZHOUKOU HAIWEI SHANGMAO YOUXIAN GONGSI' },
	{ code: '690355', name: 'ZHOUSHAN JINGJI KAIFAQU HUALV TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '1737232', name: 'ZHOUSHAN PUHAO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1721996', name: 'ZHOUSHANSHI HAIYUE TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1302107', name: 'ZHOUSHANSHI JINSHI SHUMA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1817603', name: 'ZHOUZHIXIAN HENGHUA TONGXUN FUWUBU' },
	{ code: '1297205', name: 'ZHOUZHIXIAN HENGTONG TONGXUN YOUXIAN ZEREN GONGSI' },
	{ code: '700703', name: 'ZHUCHENGSHI LIANDE TONGXUN QICAI YOUXIAN GONGSI' },
	{ code: '1056854', name: 'ZHUCHENGSHI WANLONG MAOYI YOUXIAN GONGSI' },
	{ code: '1321964', name: 'ZHUHAI SHUANGXIN TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1261909', name: 'ZHUHAI TONG\'EN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1338237', name: 'ZHUHAISHI BIAOSHENG TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1334115', name: 'ZHUHAISHI CHUANGHAIMEI TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1324306', name: 'ZHUHAISHI DONGDA GUANGXUN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1258339', name: 'ZHUHAISHI HENGBO TONGXIN SHEBEI FAZHAN YOUXIAN GONGSI' },
	{ code: '1416712', name: 'ZHUHAISHI HUAJIAN LEPU TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1293193', name: 'ZHUHAISHI JINYI TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '648136', name: 'ZHUHAISHI LEIMINGDA TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '1324305', name: 'ZHUHAISHI MINGCHANG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '709578', name: 'ZHUHAISHI TAIFENG DIANYE YOUXIAN GONGSI' },
	{ code: '1307150', name: 'ZHUHAISHI WEISHI MAOYI YOUXIAN GONGSI' },
	{ code: '1465964', name: 'ZHUHAISHI WODA TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '1485164', name: 'ZHUHAISHI YONGNUO TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '889663', name: 'ZHUJISHI YIZHONG TONGXUN SHEBEI YOUXIAN GONGSI' },
	{ code: '953042', name: 'ZHUMADIANSHI FEIHONG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1338067', name: 'ZHUMADIANSHI MINGYANG DIANZI YOUXIAN GONGSI' },
	{ code: '1390515', name: 'ZHUMADIANSHI RUNTONG DIANXUN YOUXIAN GONGSI' },
	{ code: '1731920', name: 'ZHUMADIANSHI SHUNYUAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1429131', name: 'ZHUMADIANSHI XIAOHUOBAN SHANGMAO YOUXIAN GONGSI' },
	{ code: '1037417', name: 'ZHUMADIANSHI XINCHUANG TIANDI DIANZI YOUXIAN GONGSI' },
	{ code: '2071740', name: 'ZHUMADIANSHI XINPENG TONGXUN ZHONGDUAN SHEBEI YOUXIAN GONGSI' },
	{ code: '1966896', name: 'ZHUNGE\'ERQI TANGDONG TONGXUNDIAN' },
	{ code: '1393559', name: 'ZHUNGEERQI LICHANG SHANGMAO YOUXIAN ZEREN GONGSI' },
	{ code: '1513307', name: 'ZHUOLU XINXINDA DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '1762272', name: 'ZHUOLUXIAN QUANMIN WANGLUO KEJI YOUXIAN GONGSI' },
	{ code: '706899', name: 'ZHUOZHOU SHUANGYOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1097383', name: 'ZHUOZHOUSHI HONGRUN SHENZHOU SHANGMAO YOUXIAN GONGSI' },
	{ code: '1439575', name: 'ZHUZHOU LIANSHENG TONGXIN YOUXIAN ZEREN GONGSI' },
	{ code: '1790358', name: 'ZHUZHOU TIANZHI SHIYE YOUXIAN GONGSI' },
	{ code: '1721080', name: 'ZHUZHOU XINXUN TONGXIN SHEBEI YOUXIAN GONGSI' },
	{ code: '2502905', name: 'ZHUZHOUSHI JINHAOTONG KEMAO YOUXIAN GONGSI' },
	{ code: '1301433', name: 'ZHUZHOUSHI SANHE KEJI FAZHAN YOUXIAN GONGSI' },
	{ code: '1801318', name: 'ZIBO MINGWEI DIANZI KEJI YOUXIAN GONGSI' },
	{ code: '691403', name: 'ZIBO SHANGSHA YOUXIAN ZEREN GONGSI' },
	{ code: '723043', name: 'ZIBO XINYULOU BAIHUO YOUXIAN GONGSI' },
	{ code: '690283', name: 'ZIBO YUDU SHANGMAO YOUXIAN GONGSI' },
	{ code: '688042', name: 'ZIGONG TAILI TONGXUN YOUXIAN GONGSI' },
	{ code: '1393524', name: 'ZIGONGSHI HUIHAI TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '840114', name: 'ZIGONGSHI JIURUN MAOYI YOUXIAN GONGSI' },
	{ code: '1397505', name: 'ZIGONGSHI TIANYI YIMEI TONGXUN QICAI YOUXIAN ZEREN GONGSI' },
	{ code: '1817606', name: 'ZIJIN XIANCHENG HENGJI TONGXUN CHENGNANDIAN' },
	{ code: '1297213', name: 'ZIJINXIAN HENGJI KEJI YOUXIAN GONGSI' },
	{ code: '1808990', name: 'ZIXINGSHI QIXING SHOUJI XIAOSHOUDIAN' },
	{ code: '1071146', name: 'ZIYANGSHI BAISHENG TONGXIN YOUXIAN GONGSI' },
	{ code: '1054510', name: 'ZIYANGSHI FEILONG DIANQI YOUXIAN ZEREN GONGSI' },
	{ code: '1316699', name: 'ZONGYANGXIAN WANDA TONGXIN QICAI XIAOSHOU YOUXIAN GONGSI' },
	{ code: '1435488', name: 'ZOUCHENGSHI HAIHUA KEMAO YOUXIAN GONGSI' },
	{ code: '844617', name: 'ZOUCHENGSHI XINTAITONG KEMAO YOUXIAN GONGSI' },
	{ code: '1971966', name: 'ZOUPING XIAOTONG DIANZI CHANPIN MAOYI YOUXIAN GONGSI' },
	{ code: '1210040', name: 'ZOUPINGXIAN HONGXING TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2293600', name: 'ZUNHUASHI BEI\'ERHUAN XIANGYU TONGXUN SHOUJIDIAN' },
	{ code: '980005', name: 'ZUNHUASHI BEIERHUAN XILU XIANGYU TONGXUN QICAIBU' },
	{ code: '1817628', name: 'ZUNHUASHI XISANLIXIANG YUXIN TONGXUN QICAI JINGYINGBU' },
	{ code: '1462260', name: 'ZUNYI AOYE KEJI YOUXIAN GONGSI' },
	{ code: '1301508', name: 'ZUNYI XINGUANGSHEN DIANZI SHEBEI YOUXIAN GONGSI' },
	{ code: '1600957', name: 'ZUNYISHI QINGCANG SHANGMAO YOUXIAN GONGSI' },
	{ code: '1096664', name: 'ZUNYISHI SAIGE DIANZI YOUXIAN ZEREN GONGSI' },
	{ code: '1402519', name: '188 TELECOM GROUP LIMITED' },
	{ code: '367458', name: '2 COMUNICACAO' },
	{ code: '1682221', name: '2S COMPUTER LIMITED' },
	{ code: '1762218', name: '488 PERFORMANCE GROUP, INC.' },
	{ code: '224535', name: 'A. S. WATSON RETAIL (HK) LIMITED' },
	{ code: '1667951', name: 'A1 DIGITAL' },
	{ code: '1696875', name: 'AEON STORES (HONG KONG) CO., LIMITED - AEON' },
	{ code: '1532986', name: 'API ENTERPRISES LIMITED' },
	{ code: '1672887', name: 'ASIA MILES LIMITED' },
	{ code: '1337436', name: 'AULONG INTERNATIONAL LIMITED' },
	{ code: '1060827', name: 'BEATS HK DEALERS' },
	{ code: '1544476', name: 'BEST PARAMOUNT INDUSTRIAL LIMITED' },
	{ code: '1760978', name: 'BRIDGE2 SOLUTIONS, LLC' },
	{ code: '114002', name: 'BROADWAY PHOTO SUPPLY LTD.' },
	{ code: '939742', name: 'CHEUNG MAO KEE ELECTRICAL COMPANY LIMITED' },
	{ code: '1681677', name: 'CHOICE DIGITAL TECHNOLOGY (GROUP) LIMITED' },
	{ code: '237702', name: 'CHUNG YUEN ELECTRICAL COMPANY, LIMITED' },
	{ code: '1424036', name: 'CIRCLE MOBILE COMMUNICATIONS LIMITED' },
	{ code: '1663378', name: 'CITYLINK ELECTRONICS LIMITED' },
	{ code: '367461', name: 'COMPANHIA DE NOVO TELECOMUNICACOES E EQUIPAMENTO, LIMITADA' },
	{ code: '223136', name: 'COMPUCON COMPUTERS LIMITED' },
	{ code: '1986670', name: 'COSMIC TECHNOLOGY (H.K.) LIMITED' },
	{ code: '352834', name: 'D C ONE.HK COMPANY LIMITED' },
	{ code: '2035500', name: 'DIGITAL GLUE HONG KONG LIMITED' },
	{ code: '1682213', name: 'DMA GROUP LIMITED' },
	{ code: '1663443', name: 'DREAM TEAM SMART LIFE LIMITED' },
	{ code: '1663483', name: 'DREAMLINK CREATIVES LIMITED' },
	{ code: '1682661', name: 'EARNER DEVELOPMENT LIMITED - CHINA CLUB AUDIO & VIDEO' },
	{ code: '1493556', name: 'EASCO LIMITED' },
	{ code: '1666594', name: 'EDENRED HONG KONG LIMITED' },
	{ code: '1790354', name: 'ENGY LIMITED' },
	{ code: '345789', name: 'EVERWISH TRADING LIMITED' },
	{ code: '1171859', name: 'FALCON FAITH HOLDINGS LIMITED' },
	{ code: '609093', name: 'FAST RICH TECHNOLOGY LIMITED' },
	{ code: '1665120', name: 'FORESOON ENGINEERING COMPANY LIMITED' },
	{ code: '2037826', name: 'GLOBAL REWARD SOLUTIONS AUS PTY LTD' },
	{ code: '1986654', name: 'GO LOGISTICS AND STORAGE COMPANY LIMITED' },
	{ code: '1318559', name: 'GOOD LOYAL TECHNOLOGY LIMITED' },
	{ code: '1615806', name: 'HERLEE LTD' },
	{ code: '1336527', name: 'HERMES ASIA PACIFIC LTD' },
	{ code: '1338678', name: 'HK PHONE CHANNEL RSL' },
	{ code: '336684', name: 'HMV HONG KONG LIMITED' },
	{ code: '1718752', name: 'HMV MARKETING LIMITED' },
	{ code: '1294529', name: 'HONG KONG NEW ASIA DIGITAL TECHNOLOGY COMPANY LIMITED' },
	{ code: '2341340', name: 'HONG KONG NEW COMMUNICATIONS DIGITAL TECHNOLOGY CO LIMITED' },
	{ code: '1982835', name: 'HONG KONG WANG CHEONG CO. LIMITED' },
	{ code: '495180', name: 'HONGKONG SUNING APPLIANCE RESOURCING LIMITED' },
	{ code: '1667958', name: 'IDEAL DIGITAL' },
	{ code: '1790364', name: 'IKON TELECOM LTD.' },
	{ code: '241003', name: 'INTERNATIONAL OPERATIONS & SERVICES COMPANY (HK) LIMITED' },
	{ code: '1700442', name: 'JEBSEN HOME TECH COMPANY LIMITED - JEBSEN HOME TECH COMPANY LIMITED' },
	{ code: '1790699', name: 'JOY TELECOM EQUIPMENT LIMITED' },
	{ code: '367459', name: 'KAM TELECOM' },
	{ code: '609094', name: 'KCF A STORE LIMITED' },
	{ code: '1790736', name: 'KIN NGAI HONG (MACAU) LDA.' },
	{ code: '1667990', name: 'KINGSOUND AUDIO SYSTEM COMPANY' },
	{ code: '1667994', name: 'KINGSTAR (H.K.) TRADING CO.' },
	{ code: '331118', name: 'KO IO (INTERNATIONAL) GROUP COMPANY LIMITED' },
	{ code: '1982841', name: 'LABELSPLUS INTERNATIONAL LIMITED' },
	{ code: '609095', name: 'LANE CRAWFORD (HONG KONG) LIMITED' },
	{ code: '1682217', name: 'LEAD VISION INTERNATIONAL TRADING LIMITED' },
	{ code: '1681665', name: 'LEI KEI TELECOMMUNICATION HOLDINGS LIMITED' },
	{ code: '1691202', name: 'LGC TECHNOLOGY LIMITED' },
	{ code: '1065456', name: 'LINK PROFIT INTERNATIONAL DEVELOPMENT LIMITED' },
	{ code: '493423', name: 'MACAO NEW HORIZON DIGITAL TECHNOLOGY COMPANY LIMITED' },
	{ code: '1668003', name: 'MAGAZINES INTERNATIONAL (ASIA) LIMITED' },
	{ code: '1785018', name: 'MANULIFE (INTERNATIONAL) LIMITED' },
	{ code: '348513', name: 'MASTER COMMUNICATIONS LIMITED' },
	{ code: '1681682', name: 'MASTER STUDIO' },
	{ code: '1982795', name: 'MEET SOLUTIONS LIMITED' },
	{ code: '1790741', name: 'MONG TIN COMMUNICATIONS EQUIPMENT CO. LTD.' },
	{ code: '2411225', name: 'MPAY LIMITED' },
	{ code: '1584192', name: 'MPAY LIMITED' },
	{ code: '1667998', name: 'MUSIC CAFE SHOP PRO' },
	{ code: '1982832', name: 'MUSIC CAMP' },
	{ code: '1685438', name: 'NEW PORT RETAIL MANAGEMENT LIMITED - FOCO HEADPHONE PRO-SHOP' },
	{ code: '1791778', name: 'NEW SKY LIMITED' },
	{ code: '46376', name: 'NEW VISION TECHNOLOGY LIMITED' },
	{ code: '237710', name: 'NEWBEST.COM LIMITED' },
	{ code: '1544785', name: 'NIKE HONG KONG LIMITED' },
	{ code: '1427606', name: 'NOBLETIME LIMITED' },
	{ code: '298115', name: 'ORIGINAL TECHNOLOGY LIMITED' },
	{ code: '2419725', name: 'PANDA, SOCIEDADE DE GESTAO DE INVESTIMENTOS, LIMITADA' },
	{ code: '1544475', name: 'PLZ ELECTRONICS LIMITED' },
	{ code: '1338666', name: 'RICH PLAN (ASIA) LIMITED' },
	{ code: '649771', name: 'ROYAL ELECTRONICS SQUARE COMPANY LIMITED' },
	{ code: '1992251', name: 'ROYAL VISTA TRADING LIMITED' },
	{ code: '403690', name: 'SANDA TECHNOLOGIES CO LIMITED' },
	{ code: '602976', name: 'SINO SKILL DEVELOPMENT LIMITED - RAAKEE' },
	{ code: '46150', name: 'SINOCITY INDUSTRIES LIMITED' },
	{ code: '735400', name: 'SPREAD INTERNATIONAL LIMITED' },
	{ code: '1969899', name: 'SPRINT-CASS TRAVEL RETAIL (HK) LIMITED' },
	{ code: '426913', name: 'STUDIO A TECHNOLOGY LIMITED' },
	{ code: '432938', name: 'SUCCESS WORLD RETAIL CHAIN LIMITED' },
	{ code: '1682744', name: 'SUI SENG TRADING COMPANY LIMITED' },
	{ code: '363826', name: 'SUNION TELECOM (INT\'L) LIMITED' },
	{ code: '1385880', name: 'SUNSHINE TELECOM LIMITED' },
	{ code: '1504058', name: 'TELECOM DISTRIBUTION COMPANY LIMITED' },
	{ code: '367457', name: 'TELECOM TITETO 3' },
	{ code: '367465', name: 'TELECOMUNICACOES NEWCABLE (1)' },
	{ code: '46164', name: 'ULTIMATE PC & MAC GALLERY LIMITED' },
	{ code: '299645', name: 'VERTEX TECHNOLOGY COMPANY LIMITED' },
	{ code: '431902', name: 'WEALTH JADE LIMITED' },
	{ code: '388613', name: 'WHYNOT TECHNOLOGY' },
	{ code: '1667999', name: 'WINNER CONCEPT INTERNATIONAL LIMITED' },
	{ code: '1393457', name: 'YCY INTERNATIONAL LIMITED' },
	{ code: '1982852', name: 'YOHO HONG KONG LIMITED' },
	{ code: '976099', name: 'ANZHUO TONGXINHANG' },
	{ code: '898488', name: 'ARCOA COMMUNICATION CO.,LTD.' },
	{ code: '1672928', name: 'ASIA MILES LIMITED' },
	{ code: '632357', name: 'AURORA TELECOM CORPORATION' },
	{ code: '1381072', name: 'AVAL TECHNOLOGIES CO., LTD.' },
	{ code: '1020887', name: 'BAIFA BAIZHONG TONGXUN HANG' },
	{ code: '1169901', name: 'BAODONG YOUXIAN GONGSI' },
	{ code: '1060821', name: 'BEATS TW DEALERS' },
	{ code: '252430', name: 'BEST DENKI CO.', name: 'LTD.' },
	{ code: '1631967', name: 'BINGHUANG KEJI YOUXIAN GONGSI' },
	{ code: '2377426', name: 'BINGYI KEJI YOUXIAN GONGSI' },
	{ code: '983832', name: 'BISHENG GUOJI YOUXIAN GONGSI' },
	{ code: '1667924', name: 'BOKELAI SHUWEI KEJI GUFEN YOUXIAN GONGSI' },
	{ code: '1568730', name: 'BOSI TONGXUNHANG' },
	{ code: '2063328', name: 'BOTELIAN YOUXIAN GONGSI' },
	{ code: '1658324', name: 'BOXIN TONGXIN YOUXIAN GONGSI' },
	{ code: '1760959', name: 'BRIDGE2 SOLUTIONS, LLC' },
	{ code: '2098720', name: 'CATHAY UNITED BANK CO., LTD.' },
	{ code: '993448', name: 'CHANGRONG GUOJI YOUXIAN GONGSI' },
	{ code: '1055395', name: 'CHANGXIN DIANXIN SHENGHUOGUAN YOUXIAN GONGSI' },
	{ code: '949910', name: 'CHENGHONG KEJI YOUXIAN GONGSI　' },
	{ code: '983556', name: 'CHENGTAI GUOJI SHIYE GUFEN YOUXIAN GONGSI' },
	{ code: '2515345', name: 'CHENGXUN TONGXUN YOUXIAN GONGSI' },
	{ code: '1992269', name: 'CHENGXUN TONGXUNHANG' },
	{ code: '2821508', name: 'COCOROLIFE CO., LTD.' },
	{ code: '283921', name: 'COSTCO PRESIDENT TAIWAN INC.' },
	{ code: '1816151', name: 'DADA TONGXUN QIYESHE' },
	{ code: '1046901', name: 'DADI DIANXUN GUFEN YOUXIAN GONGSI' },
	{ code: '215901', name: 'DATA EXPRESS INFOTECH CO., LTD' },
	{ code: '2081378', name: 'DAXIANG TONGXUN YOUXIAN GONGSI' },
	{ code: '991635', name: 'DAXIN TONGXIN QICAI HANG' },
	{ code: '1782457', name: 'DAYU SHUWEI YOUXIAN GONGSI' },
	{ code: '1060672', name: 'DAYUAN GUOJI YANGHANG GUFEN YOUXIAN GONGSI' },
	{ code: '949918', name: 'DERUI SHUWEI GUAN' },
	{ code: '1169918', name: 'DEXIN TONGXUN YOUXIAN GONGSI' },
	{ code: '1365285', name: 'DIANLI QIYE YOUXIAN GONGSI' },
	{ code: '1331214', name: 'DIANZHE YOUXIAN GONGSI' },
	{ code: '1273858', name: 'DIBAO QIYE YOUXIAN GONGSI' },
	{ code: '1014865', name: 'DIGU TONGXIN GONGCHENG YOUXIAN GONGSI' },
	{ code: '865420', name: 'DIGUO TONGXUN YOUXIAN GONGSI' },
	{ code: '1005759', name: 'DINGYAO TONGXUN YOUXIAN GONGSI' },
	{ code: '1769772', name: 'DIWANG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '865432', name: 'DONGRONG TONGXIN YOUXIAN GONGSI' },
	{ code: '241107', name: 'E-LIFE MALL CORPORATION' },
	{ code: '1681712', name: 'EARMEGA TECH DIGITAL CO., LTD.' },
	{ code: '1818014', name: 'EDENRED HONG KONG LIMITED' },
	{ code: '1856043', name: 'EDENRED PTE. LTD. TAIWAN BRANCH' },
	{ code: '1977770', name: 'EDENRED PTE. LTD. TAIWAN BRANCH' },
	{ code: '240997', name: 'ESIN ENTERPRISED CO.,LTD' },
	{ code: '1997693', name: 'EVA AIRWAYS CORPORATION' },
	{ code: '330982', name: 'EVER RICH D.F.S. CORPORATION' },
	{ code: '1352415', name: 'FANGXUN TONGXUNHANG' },
	{ code: '668304', name: 'FAR EASTERN AI MAI CO., LTD.' },
	{ code: '210411', name: 'FAYAQUE CO.,LTD' },
	{ code: '1162137', name: 'FENGCHUAN TONGXUN HANG' },
	{ code: '1152487', name: 'FENGJING QIYE YOUXIAN GONGSI' },
	{ code: '1481114', name: 'FENGWO SHENGHUO KEJI YOUXIAN GONGSI' },
	{ code: '1657293', name: 'FUHONG SHUWEI YOUXIAN GONGSI' },
	{ code: '1321134', name: 'FULIANWANG GUFEN YOUXIAN GONGSI' },
	{ code: '1046925', name: 'FUMEI DIANXIN GONGCHENG YOUXIAN GONGSI' },
	{ code: '1219469', name: 'FUYI TONGXIN YOUXIAN GONGSI' },
	{ code: '865448', name: 'GAOXIAN QIYE YOUXIAN GONGSI' },
	{ code: '2048554', name: 'GLOBAL REWARD SOLUTIONS AUS PTY LTD' },
	{ code: '685985', name: 'GUANG-DER INT\'L TECH CO,. LTD.' },
	{ code: '1029932', name: 'GUANGCHENG TONGXUN YOUXIAN GONGSI' },
	{ code: '2082817', name: 'GUANGSU TONGXIN QIYESHE' },
	{ code: '1227936', name: 'GUOZHI TONGXINHANG' },
	{ code: '1804954', name: 'HADIAN KEJI YOUXIAN GONGSI' },
	{ code: '991648', name: 'HALA WANGTONG DIANXUN YOUXIAN GONGSI' },
	{ code: '2378617', name: 'HAOHAO TONGXUN QIYESHE' },
	{ code: '1975738', name: 'HECHENG KEJI YOUXIAN GONGSI' },
	{ code: '1676004', name: 'HEFENG YINXIANG YOUXIAN GONGSI' },
	{ code: '1540004', name: 'HERMES ASIA PACIFIC LTD. TAIWAN BRANCH' },
	{ code: '1537854', name: 'HESHI YOUXIAN GONGSI' },
	{ code: '1613612', name: 'HESHUO LIANHE KEJI GUFEN YOUXIAN GONGSI ZHIGONG FULI WEIYUANHUI' },
	{ code: '251212', name: 'HOMAGE COMPUTER CO LTD' },
	{ code: '1536789', name: 'HON HAI PRECISION INDUSTRY CO., LTD.' },
	{ code: '251213', name: 'HONG-DIAN LIMITED COMPANY' },
	{ code: '1278439', name: 'HONGJUN TONGXUN YOUXIAN GONGSI' },
	{ code: '1213159', name: 'HONGLONG GUOJI DIANXUN YOUXIAN GONGSI' },
	{ code: '1621073', name: 'HONGSHENG DIANXIN YOUXIAN GONGSI' },
	{ code: '984477', name: 'HONGTONG QIYESHE' },
	{ code: '1208982', name: 'HONGYE YOUXIAN GONGSI' },
	{ code: '1094455', name: 'HUACHENG GUOJI YOUXIAN GONGSI' },
	{ code: '1309302', name: 'HUANGJIA DIANXUN KEJI YOUXIAN GONGSI' },
	{ code: '2396887', name: 'HUARUI TONGXUN YOUXIAN GONGSI' },
	{ code: '731835', name: 'ISPOT CO., LTD.' },
	{ code: '1772554', name: 'JIANTONG TONGXUNHANG' },
	{ code: '846767', name: 'JIARONG XINGXIAO GUFEN YOUXIAN GONGSI' },
	{ code: '1045529', name: 'JIASHIDA KEJI YOUXIAN GONGSI' },
	{ code: '1142650', name: 'JIAXIN KEJI YOUXIAN GONGSI' },
	{ code: '865385', name: 'JIESHENG TONGXIN GUFEN YOUXIAN GONGSI' },
	{ code: '1667952', name: 'JIEYANG QIYESHE' },
	{ code: '1687275', name: 'JIMIFANG KEJI YOUXIAN GONGSI' },
	{ code: '1982855', name: 'JINMAJIANG SHUWEI YOUXIAN GONGSI' },
	{ code: '1047877', name: 'JINSANJIAO TONGXIN GUFEN YOUXIAN GONGSI' },
	{ code: '1667920', name: 'JIYASHE GUFEN YOUXIAN GONGSI' },
	{ code: '1624927', name: 'JUTING SHUWEI YOUXIAN GONGSI' },
	{ code: '1170375', name: 'JUZI MOLI DIANXUN YOUXIAN GONGSI' },
	{ code: '1465994', name: 'KESHIDE DIANTONG GUOJI GUFEN YOUXIAN GONGSI' },
	{ code: '1542799', name: 'KUNZHOU GUFEN YOUXIAN GONGSI' },
	{ code: '1682161', name: 'LAI FENG YOUXIAN GONGSI' },
	{ code: '1178891', name: 'LAICHENG TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '2268777', name: 'LANYU YOUXIAN GONGSI' },
	{ code: '1251891', name: 'LEHONG YOUXIAN GONGSI' },
	{ code: '1227949', name: 'LIANCHENG KEJI' },
	{ code: '1293906', name: 'LIANGJIANG TONGXUNHANG' },
	{ code: '1025380', name: 'LIANOU TONGXUN YOUXIAN GONGSI' },
	{ code: '2314497', name: 'LIANQIN GUOJI TONGXUNHANG' },
	{ code: '2090560', name: 'LIANQIN TONGXUN YOUXIAN GONGSI' },
	{ code: '278755', name: 'LIME MULTIMEDIA TECHNOLOGY' },
	{ code: '324006', name: 'LIN TRADING CO., LTD.' },
	{ code: '544916', name: 'LINKWELL INFOTECH LTD.' },
	{ code: '1784611', name: 'LIQUAN TONGXUN YOUXIAN GONGSI' },
	{ code: '1961855', name: 'LIYAN TONGXINHANG' },
	{ code: '1667931', name: 'LIYITONG QIYE YOUXIAN GONGSI' },
	{ code: '701249', name: 'LIZHAN INFOTECH COMPANY LIMITED' },
	{ code: '1025372', name: 'LONGDIAN QIYE YOUXIAN GONGSI' },
	{ code: '1742337', name: 'MACQUARIE CAPITAL LIMITED,TAIWAN SECURITIES BRANCH' },
	{ code: '1052339', name: 'MAOQILAIZHUAN KEJI YOUXIAN GONGSI' },
	{ code: '1208999', name: 'MIKE ZIXUN YOUXIAN GONGSI' },
	{ code: '1980240', name: 'MINGMING KEJI QIYESHE' },
	{ code: '1302806', name: 'MINGWEI TONGXUN YOUXIAN GONGSI' },
	{ code: '2114667', name: 'MOMENTUM SPORTS CO., LTD.' },
	{ code: '1685848', name: 'MOMO.COM INC.' },
	{ code: '1544814', name: 'NIKE TAIWAN LIMITED' },
	{ code: '866175', name: 'OUKENDE GUFEN YOUXIAN GONGSI' },
	{ code: '1062558', name: 'OULIANSHENG SHIYE YOUXIAN GONGSI' },
	{ code: '1062574', name: 'PANGXIYI DIANNAO YOUXIAN GONGSI' },
	{ code: '239125', name: 'PCHOME ONLINE INC.' },
	{ code: '1980247', name: 'PEZ CROWN INTERNATIONAL COPRPORATION' },
	{ code: '240993', name: 'PHILDOM TECH CO.,LTD.' },
	{ code: '1676003', name: 'PINGGUANG DIANQI YOUXIAN GONGSI' },
	{ code: '1131518', name: 'PINGU TONGXUN HANG' },
	{ code: '1685747', name: 'PLAY SOUND INTERNATIONAL CO., LTD.' },
	{ code: '335453', name: 'PRESICARRE CORPORATION' },
	{ code: '2338937', name: 'PRESIDENT CHAIN STORE CORPORATION' },
	{ code: '2585668', name: 'QFANYUN INC.' },
	{ code: '949219', name: 'QIANGLIU YOUXIAN GONGSI' },
	{ code: '1162671', name: 'QIAOKANG TONGXIN HANG' },
	{ code: '1813195', name: 'QIAOKANG TONGXIN YOUXIAN GONGSI' },
	{ code: '1328098', name: 'QILIHENG DIANXIN GUFEN YOUXIAN GONGSI' },
	{ code: '1057184', name: 'QINGMING ZIXUN YOUXIAN GONGSI' },
	{ code: '2072324', name: 'QIYA TONGXIN YOUXIAN GONGSI' },
	{ code: '1153419', name: 'QIYA TONGXINHANG' },
	{ code: '949215', name: 'QUANHANG TONGXIN QICAI YOUXIAN GONGSI' },
	{ code: '1232052', name: 'QUANPEI TONGXIN HANG' },
	{ code: '1272276', name: 'QUANTAI TONGXUNHANG' },
	{ code: '949211', name: 'QUANYU DIANXIN GUFEN YOUXIAN GONGSI' },
	{ code: '1069904', name: 'RENHE TONGXIN YOUXIAN GONGSI' },
	{ code: '1687356', name: 'RIHONG TONGXINHANG' },
	{ code: '2126297', name: 'RONGSHENG GUFEN YOUXIAN GONGSI' },
	{ code: '649947', name: 'RT-MART INTERNATIONAL LTD.,' },
	{ code: '1153962', name: 'RUIFA SHIYE YOUXIAN GONGSI' },
	{ code: '865441', name: 'RUIFA SHUWEI YOUXIAN GONGSI' },
	{ code: '1980558', name: 'SAN JING INFORMATION CO., LTD.' },
	{ code: '1060794', name: 'SENTRA INT. CO.,LTD' },
	{ code: '1658320', name: 'SHANGQING DIANXIN YOUXIAN GONGSI' },
	{ code: '949193', name: 'SHANGWEI SHIYE YOUXIAN GONGSI' },
	{ code: '1286581', name: 'SHENCHUAN GUOJI TONGXIN YOUXIAN GONGSI' },
	{ code: '1971534', name: 'SHENFANG ZIXUN GUFEN YOUXIAN GONGSI' },
	{ code: '983595', name: 'SHENGZHI GUOJI TONGXUN YOUXIAN GONGSI' },
	{ code: '1975727', name: 'SHENYU TONGXUNHANG' },
	{ code: '1023145', name: 'SHICHUANG TONGXUN YOUXIAN GONGSI' },
	{ code: '2585728', name: 'SHIPEI TONGXIN QIYE YOUXIAN GONGSI' },
	{ code: '563680', name: 'SINYA DIGITAL CORPORATION LTD.' },
	{ code: '264947', name: 'STUDIO A INC.' },
	{ code: '239019', name: 'SUNFAR COMPUTER CO., LTD.' },
	{ code: '1331208', name: 'TAIGU SHUWEI KEJI YOUXIAN GONGSI' },
	{ code: '2156010', name: 'TAIHE DIANXUN YOUXIAN GONGSI' },
	{ code: '1393363', name: 'TAIHUA TONGXUNHANG' },
	{ code: '976045', name: 'TAITONG DIANXUNHANG' },
	{ code: '1152531', name: 'TAIWAN XINGDONG ZIXUNSHE' },
	{ code: '2035255', name: 'TAIZHONG SHUWEI TONGXUN YOUXIAN GONGSI' },
	{ code: '947779', name: 'TAOBAO YOUXIAN GONGSI' },
	{ code: '305585', name: 'TASA MENG CORPORATION' },
	{ code: '1189354', name: 'TIANJI TONGXIN YOUXIAN GONGSI' },
	{ code: '1170442', name: 'TONGDA DIANXIN YOUXIAN GONGSI' },
	{ code: '978814', name: 'TONGXUN DE ZHUANJIA' },
	{ code: '723813', name: 'TSAI FAMILY INTERNATIONAL CO., LTD.' },
	{ code: '114023', name: 'TSANN KUEN ENTERPRISE CO., LTD.' },
	{ code: '1978173', name: 'TUOYU XINGYE GUFEN YOUXIAN GONGSI' },
	{ code: '237672', name: 'UNAUTHORIZED RSL - TAIWAN' },
	{ code: '35568', name: 'UNITECH COMPUTER CO., LTD.' },
	{ code: '2637765', name: 'UNITED DIGITAL INTELLIGENCE CO.,LTD.' },
	{ code: '1467880', name: 'WANSHIDA GUOJI YOUXIAN GONGSI' },
	{ code: '976095', name: 'WEIXUN QIYE GUFEN YOUXIAN GONGSI' },
	{ code: '2383671', name: 'WIIN TECHNOLOGIES CO., LTD.' },
	{ code: '1029891', name: 'WUHANG TONGXUN YOUXIAN GONGSI' },
	{ code: '1676002', name: 'XIANGHAO QIYE YOUXIAN GONGSI' },
	{ code: '1227854', name: 'XIANGYI KEJI YOUXIAN GONGSI' },
	{ code: '1055003', name: 'XIAOGANG DIANXIN YOUXIAN GONGSI' },
	{ code: '864514', name: 'XIEXUN GUOJI GUFEN YOUXIAN GONGSI' },
	{ code: '1720645', name: 'XINCHENGQI TONGXUN YOUXIAN GONGSI' },
	{ code: '865395', name: 'XINGDONGNIAO TONGXUN KEJI YOUXIAN GONGSI' },
	{ code: '1667941', name: 'XINPENG DIANQI YOUXIAN GONGSI' },
	{ code: '1768090', name: 'XINWANGSHU QIYE YOUXIAN GONGSI' },
	{ code: '1804973', name: 'XINWEI GUOJI QIYE YOUXIAN GONGSI' },
	{ code: '949760', name: 'XINXUTONG TONGXIN HANG' },
	{ code: '1060793', name: 'XINYOU KEJI YOUXIAN GONGSI' },
	{ code: '2586045', name: 'XUNLIU TONGXIN YOUXIAN GONGSI' },
	{ code: '1616845', name: 'YABO SHUWEI KEJI YOUXIAN GONGSI' },
	{ code: '352606', name: 'YAHOO! TAIWAN HOLDINGS LIMITED,TAIWAN BRANCH' },
	{ code: '1685743', name: 'YAODE SHITING QICAI YOUXIAN GONGSI' },
	{ code: '1675994', name: 'YAOJIE GUOJI YOUXIAN GONGSI' },
	{ code: '1309294', name: 'YAOZHUAN GUOJI YOUXIAN GONGSI' },
	{ code: '966147', name: 'YATAI SHISHANG SHOUJIGUAN' },
	{ code: '1857794', name: 'YIBAIFA YOUXIAN GONGSI' },
	{ code: '1818407', name: 'YIFENG YOUXIAN GONGSI' },
	{ code: '1660058', name: 'YIGUANG DIANXUNHANG' },
	{ code: '865925', name: 'YIHUA TONGXUN YOUXIAN GONGSI' },
	{ code: '1624465', name: 'YIQIER YOUXIAN GONGSI' },
	{ code: '1968711', name: 'YONGHUI GUFEN YOUXIAN GONGSI' },
	{ code: '239021', name: 'YOUTH LLC.' },
	{ code: '1499524', name: 'YUEDONG KEJI YOUXIAN GONGSI' },
	{ code: '1777664', name: 'ZHANTONG TONGXUN YOUXIAN GONGSI' },
	{ code: '1384703', name: 'ZHENGYI TONGXIN YOUXIAN GONGSI' },
	{ code: '976256', name: 'ZHENHAN KEJI TONGXINHANG' },
	{ code: '2042446', name: 'ZHONGPEI TONGXIN QIYE YOUXIAN GONGSI' },
	{ code: '2072248', name: 'ZHUOSHENG QIYESHE' },
	{ code: '1076731', name: 'ZHUXIN DIANXIN YOUXIAN GONGSI' },
	{ code: '1017236', name: 'ELECTRONIC LINE' },
	{ code: '1591863', name: 'INFOSOFT SYSTEMS HQ' },
	{ code: '560211', name: 'ISTREAM' },
	{ code: '339883', name: 'MOLLA' },
	{ code: '1231605', name: 'NEPTUN ALBANIA HQ' },
	{ code: '274618', name: 'ADMAG - HQ' },
	{ code: '552153', name: 'DARKOM - HQ' },
	{ code: '858376', name: 'SARL GNT TELECOM' },
	{ code: '628346', name: 'APPLE - OTHER - CE - CGA ANGOLA' },
	{ code: '558741', name: 'ARAY' },
	{ code: '603002', name: 'IMALL' },
	{ code: '707498', name: 'ISTORE' },
	{ code: '1792745', name: 'MOBILE CENTRE HQ' },
	{ code: '670312', name: 'ZIGZAG ELECTRONICS' },
	{ code: '1728267', name: 'ALMA STORE HQ' },
	{ code: '658537', name: 'ICON INC' },
	{ code: '558897', name: 'MG MUSIC GALLERY' },
	{ code: '1986023', name: 'MY SHOPS LLC HQ' },
	{ code: '2072730', name: 'BAHRAIN DUTY FREE HQ' },
	{ code: '1466376', name: 'BIN HINDI EZONE' },
	{ code: '768138', name: 'EXTRA' },
	{ code: '1142292', name: 'I196- THE ULTIMATE I-STORE' },
	{ code: '265022', name: 'IMACHINES HQ' },
	{ code: '265018', name: 'IWORLD CONNECT' },
	{ code: '944173', name: 'SHARAF DG' },
	{ code: '606252', name: 'VIRGIN MEGASTORE' },
	{ code: '1597181', name: '5ELEMENT' },
	{ code: '836013', name: 'ACLUB' },
	{ code: '279345', name: 'BELYTEREM VOLGOGRADSKAYA' },
	{ code: '677813', name: 'CAFECONNÉCT' },
	{ code: '709386', name: 'ELECTROSILA HQ' },
	{ code: '851890', name: 'EUROSET' },
	{ code: '565320', name: 'I-TECH.BY' },
	{ code: '1277822', name: 'OTHER BELARUS' },
	{ code: '531961', name: 'I-STORE HQ' },
	{ code: '1511841', name: 'JUSTA' },
	{ code: '1240853', name: 'MTS' },
	{ code: '1278689', name: 'NA SVYAZI' },
	{ code: '1525596', name: 'NEWSTORE' },
	{ code: '1278680', name: 'SVYAZNOY' },
	{ code: '794018', name: 'TECHNO' },
	{ code: '543641', name: 'TEHNIKA USPEHA' },
	{ code: '1294755', name: 'TRIOVIST LLC' },
	{ code: '1792753', name: 'ULTRA.BY' },
	{ code: '766463', name: 'VELCOM' },
	{ code: '274485', name: 'APPLE - OTHER - BA' },
	{ code: '1478100', name: 'AT STORE HQ' },
	{ code: '1519764', name: 'DOMOD HQ' },
	{ code: '343118', name: 'MEGATREND' },
	{ code: '285720', name: 'MEKLINE' },
	{ code: '274502', name: 'OTHER RESELLERS - APCOM' },
	{ code: '1478096', name: 'ZVUKISLIKA HQ' },
	{ code: '285687', name: 'APPLE - OTHER - BG' },
	{ code: '342955', name: 'CREATIVE CENTER' },
	{ code: '1722435', name: 'EMAG BG HQ' },
	{ code: '760315', name: 'GLOBAL CO.' },
	{ code: '434540', name: 'IABALKA.EU' },
	{ code: '482129', name: 'IBS BULGARIA' },
	{ code: '551938', name: 'IMAGIC' },
	{ code: '285730', name: 'ISTYLE SOFIA' },
	{ code: '342961', name: 'MAKTIM' },
	{ code: '412821', name: 'MULTIMEDIA WORKSHOP' },
	{ code: '403295', name: 'NOVMAK.COM' },
	{ code: '605644', name: 'O-CONNECT' },
	{ code: '1702930', name: 'O-CONNECT HQ' },
	{ code: '2266221', name: 'OPA SOLUTIONS HQ' },
	{ code: '342963', name: 'PLEISO HQ' },
	{ code: '760313', name: 'POLYCOMP' },
	{ code: '605645', name: 'STEMO' },
	{ code: '605641', name: 'TABLET.BG' },
	{ code: '548591', name: 'TECHNOMARKET HQ + ONLINE' },
	{ code: '342965', name: 'TECHNOPOLIS HQ + ONLINE' },
	{ code: '342967', name: 'UNICOMS STORE' },
	{ code: '1484668', name: 'ZORA HQ' },
	{ code: '360697', name: 'BUROTEC' },
	{ code: '485011', name: 'OFIS - HQ' },
	{ code: '343102', name: 'AD DISCO' },
	{ code: '274483', name: 'APPLE - OTHER - HR' },
	{ code: '972467', name: 'COMBIS' },
	{ code: '560494', name: 'EMMEZETA HQ' },
	{ code: '408449', name: 'FILEX' },
	{ code: '343109', name: 'FINDER' },
	{ code: '598325', name: 'HARVEY NORMAN CROATIA HQ' },
	{ code: '343110', name: 'HG SPOT HQ' },
	{ code: '366232', name: 'INDEPENDENT PARTNERS TM CROATIA' },
	{ code: '496694', name: 'ISTYLE HR HQ' },
	{ code: '343114', name: 'KREATIVNI ODJEL' },
	{ code: '659725', name: 'LINKS' },
	{ code: '648209', name: 'MIKRONIS' },
	{ code: '1491726', name: 'SANCTA DOMENICA HQ' },
	{ code: '477720', name: 'STORE' },
	{ code: '659739', name: 'TEHNOMOBIL' },
	{ code: '547517', name: 'VIRTUS MREŽA D.O.O' },
	{ code: '495707', name: 'ELECTROLINE NICOSIA STROVOLOS' },
	{ code: '280817', name: 'GREYCOM LTD' },
	{ code: '1558969', name: 'ISTORM CY HQ' },
	{ code: '345494', name: 'PRISMA COMPUTER LTD' },
	{ code: '495690', name: 'PUBLIC' },
	{ code: '495693', name: 'STEPHANIS NICOSIA STROVOLOS' },
	{ code: '304457', name: '24U' },
	{ code: '343023', name: 'ALZA CZ' },
	{ code: '455161', name: 'AUTOCONT' },
	{ code: '2007789', name: 'AUTOCONT HQ' },
	{ code: '602964', name: 'AXES COMPUTERS' },
	{ code: '388743', name: 'C.B.C.' },
	{ code: '455162', name: 'COMFOR STORES A.S.' },
	{ code: '780033', name: 'CZC.CZ' },
	{ code: '1481893', name: 'DAPAM' },
	{ code: '482142', name: 'DRAGON COMPUTER' },
	{ code: '303016', name: 'DUHOVY SERVIS' },
	{ code: '648117', name: 'ELECTRO WORLD CZ HQ' },
	{ code: '621159', name: 'EURONICS HQ' },
	{ code: '564771', name: 'KB EXPERT HQ' },
	{ code: '1961055', name: 'HP TRONIC CZECH' },
	{ code: '763822', name: 'ISTYLE HQ' },
	{ code: '712523', name: 'IWANT HQ' },
	{ code: '1478104', name: 'LOGICWORKS' },
	{ code: '845290', name: 'MARKIT' },
	{ code: '2012021', name: 'MEGAPIXEL HQ' },
	{ code: '1749540', name: 'MISE HQ' },
	{ code: '1240368', name: 'MOBIL POHOTOVOST' },
	{ code: '568183', name: 'QUADRO' },
	{ code: '360498', name: 'QUENTIN' },
	{ code: '857487', name: 'SETOS RETAIL' },
	{ code: '919196', name: 'WESTECH CZ' },
	{ code: '602972', name: 'XANADU' },
	{ code: '1427993', name: 'ALSAFY IMPORT EXPORT' },
	{ code: '1243421', name: 'B-TECH' },
	{ code: '1592118', name: 'DELTA COMMUNICATIONS AND TRADING' },
	{ code: '941079', name: 'EGYPT- OTHER RETAILER' },
	{ code: '1078461', name: 'IPLUS - HQ' },
	{ code: '1440124', name: 'MOBILE PLUS' },
	{ code: '2049017', name: 'RADIO TALAAT - HQ' },
	{ code: '602965', name: 'RAYA ELECTRONICS HQ' },
	{ code: '2032573', name: 'SELECT - HQ' },
	{ code: '265026', name: 'SWITCH HQ' },
	{ code: '285692', name: 'TRADELINE STORES MOHANDESSIN' },
	{ code: '1765075', name: 'UNION MOBILE STORES' },
	{ code: '563197', name: 'VIRGIN MEGASTORE' },
	{ code: '1765070', name: 'ZERO STORES' },
	{ code: '462866', name: 'DATEL EE HQ' },
	{ code: '442943', name: 'EURONICS EE HQ' },
	{ code: '454948', name: 'FLEX EE HQ' },
	{ code: '279648', name: 'IM ARVUTID EE APR HQ' },
	{ code: '1541422', name: 'JSC PIGU EE HQ' },
	{ code: '437338', name: 'KLICK ONLINER EE HQ' },
	{ code: '437339', name: 'MARKIT EE HQ' },
	{ code: '1253717', name: 'TELIA EESTI AS' },
	{ code: '656808', name: 'VALGE KLAAR' },
	{ code: '858393', name: 'ISIS' },
	{ code: '520127', name: 'PC LEADER - HQ' },
	{ code: '274612', name: 'TECH AND COM' },
	{ code: '360702', name: 'BUREAUTIQUE & SERVICES' },
	{ code: '590804', name: 'ISTORE GA - HQ' },
	{ code: '852926', name: 'NF STORE' },
	{ code: '653082', name: 'ALTA ELECTRONICS HQ' },
	{ code: '651533', name: 'ELIT ELECTRONICS' },
	{ code: '2043958', name: 'IPLUS HQ' },
	{ code: '1743126', name: 'ISPACE HQ' },
	{ code: '1758284', name: 'ISTORE HQ' },
	{ code: '1758290', name: 'ITECHNIC HQ' },
	{ code: '628349', name: 'GHANA BUCKET' },
	{ code: '431912', name: 'ISTORE - HQ' },
	{ code: '280825', name: 'APPLE - OTHER - CE - ISQUARE' },
	{ code: '427047', name: 'DATA CENTER PIREAUS' },
	{ code: '280826', name: 'DOCUMENT' },
	{ code: '1522925', name: 'DOCUMENT HQ' },
	{ code: '280844', name: 'ELECTRONET SA' },
	{ code: '280836', name: 'KOTSOVOLOS/ELECTROWORLD (DSGI)' },
	{ code: '280831', name: 'GERMANOS SA (T-MOBILE / COSMOTE DEALERS)' },
	{ code: '410478', name: 'ISTORM S.A.' },
	{ code: '349515', name: 'ISYSTEM' },
	{ code: '427148', name: 'KERNEL TECHNOLOGY SYSTEMS L.T.D.' },
	{ code: '2220217', name: 'MSH GR HQ' },
	{ code: '280832', name: 'PLAISIO COMPUTERS' },
	{ code: '482511', name: 'PUBLIC GR' },
	{ code: '595870', name: 'U' },
	{ code: '274610', name: 'ARIANE' },
	{ code: '633412', name: 'BUZZ SXM - HQ' },
	{ code: '631701', name: 'CONNEXION' },
	{ code: '729161', name: 'DIGILIFE' },
	{ code: '360713', name: 'ITEC SERVICES' },
	{ code: '274611', name: 'MCVILLAGE' },
	{ code: '401111', name: 'PC LEADER - HQ' },
	{ code: '2115632', name: 'ALZA HU HQ' },
	{ code: '1816296', name: 'ALZA.HU HQ' },
	{ code: '389556', name: 'APPLE - TSFT - ROC-HU-CONR' },
	{ code: '389562', name: 'APPLE - TSFT - ROC-HU-OTHR' },
	{ code: '389564', name: 'APPLE_REST OF EE_PG_7_811' },
	{ code: '274488', name: 'BIRINCSIK JÓZSEF IJOE HQ' },
	{ code: '427260', name: 'EDIGITAL HQ' },
	{ code: '2113133', name: 'EMAG HU HQ' },
	{ code: '620504', name: 'EURO ONE' },
	{ code: '1789215', name: 'EURONICS HQ' },
	{ code: '564196', name: 'ICENTRE HQ' },
	{ code: '274504', name: 'ISTYLE HQ' },
	{ code: '274513', name: 'MSH HU HQ' },
	{ code: '453421', name: 'SZINVANET' },
	{ code: '1098912', name: '3G MOBILE WORLD' },
	{ code: '1682412', name: 'AADEVRAJ TECH SOLUTIONS PVT LTD' },
	{ code: '1980710', name: 'AATISH FINANCIAL CONSULTANTS LIMITED' },
	{ code: '1757075', name: 'ABHINANDAN ENTERPRISE LLP' },
	{ code: '286123', name: 'ACCESS' },
	{ code: '1096083', name: 'ACCORD COMPUTECH PVT LTD' },
	{ code: '1267933', name: 'ACCUPRO COMPUTERS PVT LTD' },
	{ code: '605984', name: 'ACE TECH SYS PVT LTD' },
	{ code: '1606257', name: 'ADANAC DIGITAL' },
	{ code: '757999', name: 'ADITI COMMUNICATIONS PVT LTD' },
	{ code: '1316155', name: 'ADVANCE INFO DISTRIBUTORS PVT LTD' },
	{ code: '1535372', name: 'AERO STAR ENTERPRISES' },
	{ code: '903129', name: 'AGGARWAL AGENCIES PVT LTD' },
	{ code: '1709970', name: 'AJA ELECTRONICS PVT LTD' },
	{ code: '1156274', name: 'AKHAND DISTRIBUTORS PVT LTD' },
	{ code: '1526541', name: 'AKSH TRADERS PVT LTD' },
	{ code: '1471618', name: 'ALPS DISTRIBUTORS PVT LTD' },
	{ code: '865415', name: 'AMAR COMMUNICATION PVT LTD' },
	{ code: '2033659', name: 'AMAZON WHOLESALE (INDIA) PVT LTD' },
	{ code: '2033596', name: 'APPARIO RETAIL PRIVATE LIMITED' },
	{ code: '1186883', name: 'AMBA MOBILES PVT LTD' },
	{ code: '1594520', name: 'AMIABLE ELECTRONICS PVT LTD' },
	{ code: '1106004', name: 'AMPLE DIGITAL PVT LTD' },
	{ code: '114008', name: 'AMPLE TECHNOLOGIES PRIVATE LIMITED' },
	{ code: '1024391', name: 'AMPLE TECHNOLOGIES PRIVATE LIMITED (SAT)' },
	{ code: '722026', name: 'ANAND INNOVATIONS PVT LTD' },
	{ code: '1516614', name: 'ANGEL INC' },
	{ code: '1718155', name: 'ANJANI INTERNATIONAL' },
	{ code: '1349691', name: 'ANKUR ELECTRONICS' },
	{ code: '1551434', name: 'ANMOL WATCHES & ELECTRONICS PVT LTD' },
	{ code: '1533531', name: 'ANU ENTERPRISES' },
	{ code: '1107094', name: 'APFEL SOLUTIONS' },
	{ code: '713868', name: 'APVISION TECHNOLOGIES' },
	{ code: '875586', name: 'AQL TECHNOLOGY PVT LTD' },
	{ code: '1354687', name: 'AQUATECH ELECTRICALS PVT LTD' },
	{ code: '857393', name: 'ARDAS TELE VENTURES PVT LTD' },
	{ code: '1548506', name: 'ARIHANT INFOCOM' },
	{ code: '983859', name: 'ARIHANTH VENTURES PVT LTD' },
	{ code: '1316280', name: 'ARJAY RETAIL' },
	{ code: '1599700', name: 'ARROWHEAD COMMUNICATIONS P. LTD' },
	{ code: '647917', name: 'ARUSH & CO.' },
	{ code: '869972', name: 'ASB TRADING AGENCY PVT LTD' },
	{ code: '1548512', name: 'ASHOK ENTERPRISES' },
	{ code: '1107071', name: 'ASTA LA VISTA' },
	{ code: '333884', name: 'AURO ENTERPRISES' },
	{ code: '1682272', name: 'AZRB TECH INNOVATIONS PVT. LIMITED' },
	{ code: '1593683', name: 'BABA HARDASRAM AGENCY' },
	{ code: '933554', name: 'BARD ROY INFOTECH PVT LTD' },
	{ code: '719857', name: 'BATHLA TELETECH PVT LTD' },
	{ code: '1722393', name: 'BENZ INDIA PVT LTD' },
	{ code: '485105', name: 'BEYOND COMPUTERS' },
	{ code: '560491', name: 'BHAGWATI SONS VYAPAAR PVT LTD' },
	{ code: '1323699', name: 'BHAJANLAL COMMERCIAL PVT LTD' },
	{ code: '1523472', name: 'BHARTI AIRTEL SERVICES LIMITED' },
	{ code: '957409', name: 'BHAWAR LIFESTYLE' },
	{ code: '838190', name: 'BHR COMMUNICATION PVT LTD' },
	{ code: '628952', name: 'BIG C MOBILES PVT LTD' },
	{ code: '564190', name: 'BUSINESS SOLUTIONS' },
	{ code: '395586', name: 'C.P.R DISTRIBUTORS PVT LTD' },
	{ code: '563621', name: 'CAPITAL ELECTRONICS & APPLIANCES LTD' },
	{ code: '454789', name: 'CARE OFFICE EQUIPMENT LTD' },
	{ code: '1474178', name: 'CELL POINT INDIA PVT LTD' },
	{ code: '1330245', name: 'CELLMAX' },
	{ code: '283885', name: 'CINTHAMANI COMPUTER' },
	{ code: '1324438', name: 'CITY ELECTRONICS' },
	{ code: '1650391', name: 'CLOUDTAIL INDIA PRIVATE LIMITED' },
	{ code: '1559479', name: 'COLORS SALES' },
	{ code: '1726366', name: 'COMFORT WAREHOUSING PVT LTD' },
	{ code: '1560412', name: 'COMMUNICATION WORLD INFOMATIC PVT LTD' },
	{ code: '564193', name: 'COMP-MART INDIA PVT LTD' },
	{ code: '558731', name: 'COMPU SHOPPE' },
	{ code: '558612', name: 'COMPUCITI' },
	{ code: '286673', name: 'COMPUTER EXCHANGE PRIVATE LIMITED' },
	{ code: '1593679', name: 'COMPUTER GALLERY(P) LTD' },
	{ code: '573445', name: 'COMPUTER NETWORK SERVICES' },
	{ code: '1183808', name: 'CONCEPT CHANNEL SUPPLIERS PVT LTD' },
	{ code: '1049868', name: 'CONNEXIONS' },
	{ code: '463054', name: 'CONSOLIDATED PREMIUM RETAILERS' },
	{ code: '1024409', name: 'CONSOLIDATED PREMIUM RETAILERS (SAT)' },
	{ code: '1475888', name: 'CONVERGE SYSTEMS & SERVICES PVT LTD' },
	{ code: '2403951', name: 'CPR DISTRIBUTORS PRIVATE LIMITED' },
	{ code: '1337631', name: 'CRAZELINKS GADGETS PVT LTD' },
	{ code: '1688622', name: 'CREATIVE INFOTECH SOLUTIONS PVT LTD' },
	{ code: '335730', name: 'CRESCENT ELECTRONICS PVT. LTD' },
	{ code: '1542283', name: 'CREW BUSINESS SYSTEMS' },
	{ code: '291328', name: 'INFINITI RETAIL LIMITED' },
	{ code: '1542240', name: 'CS TRADE LINK PVT LTD' },
	{ code: '289979', name: 'CUBIC COMPUTING PRIVATE LIMITED' },
	{ code: '558336', name: 'CURRENTS TECHNOLOGY RETAIL (INDIA) LIMITED' },
	{ code: '889762', name: 'CURRENTS TECHNOLOGY RETAIL (INDIA) LIMITED (SAT)' },
	{ code: '2420105', name: 'DATA INGENIOUS GLOBAL LIMITED' },
	{ code: '494448', name: 'DATAMATION SERVICES' },
	{ code: '2064691', name: 'DELTA TECHNOLOGIES' },
	{ code: '2401248', name: 'DEV MARKETING' },
	{ code: '1108531', name: 'DEV SERVICE STATION' },
	{ code: '1559277', name: 'DEV TRADEWORLD PVT LTD' },
	{ code: '1645317', name: 'DHRUV COMMUNICATION LLP' },
	{ code: '691878', name: 'DHRUV SALES & MARKETING' },
	{ code: '1545844', name: 'DHVY GLOBAL PVT LTD' },
	{ code: '1722738', name: 'DIGILIFE DISTRIBUTION AND MARKETING SERVICES LTD' },
	{ code: '1327331', name: 'DIGITAL COMPUSYSTEMS PVT LTD' },
	{ code: '1100906', name: 'DIGITAL STORE' },
	{ code: '1548501', name: 'DIGITAL TRACK SOLUTIONS PVT LTD' },
	{ code: '1098963', name: 'DIGITRONICS INFOSOLUTIONS PVT LTD' },
	{ code: '1316715', name: 'DIVYANG INFOCARE' },
	{ code: '1141005', name: 'DIVYANG INFOCARE' },
	{ code: '1135785', name: 'DIVYANG INFOWORLD PVT LTD' },
	{ code: '759960', name: 'DIVYASHAKTI FONES PVT LTD' },
	{ code: '605267', name: 'DIXIT INFOTECH SERVICES PVT LTD' },
	{ code: '1593674', name: 'DM SERVICES PVT LTD' },
	{ code: '1625690', name: 'DNS ELECTRONICS PVT LTD' },
	{ code: '1792607', name: 'DYNACONS SYSTEMS AND SOLUTIONS LTD' },
	{ code: '1700484', name: 'E-SPACE COMMUNICATION' },
	{ code: '1540072', name: 'EAGLE ELECTRONICS (INDIA) PVT LTD' },
	{ code: '1098950', name: 'EARTH SYSCOM PVT LTD' },
	{ code: '558609', name: 'EASTERN LOGICA INFOWAY LTD' },
	{ code: '1110258', name: 'EASY DAY' },
	{ code: '1696660', name: 'EHAM DIGITAL LLP' },
	{ code: '1677821', name: 'ELECTRONICS MART INDIA LIMITED' },
	{ code: '1100499', name: 'ELECTRONICS MART INDIA LIMITED' },
	{ code: '1286767', name: 'EMPIRE TRADE MULTI VENTURE PVT LTD' },
	{ code: '998812', name: 'ENCON IMPEX PVT LTD' },
	{ code: '1089391', name: 'EON SALES ENTERPRISES PVT LTD' },
	{ code: '1542267', name: 'EPIC INFOSYS' },
	{ code: '1310927', name: 'EUFOURIA RETAIL LLP' },
	{ code: '717564', name: 'EVEREST DIGITALS PVT LTD' },
	{ code: '1056910', name: 'EVEREST INTERNATIONAL PVT LTD' },
	{ code: '1176753', name: 'EVOLVE BUSINESS VENTURES' },
	{ code: '1302190', name: 'EWORLD BUSINESS SOLUTIONS PVT LTD' },
	{ code: '709820', name: 'EWORLD TECHLIFE PVT LTD' },
	{ code: '605900', name: 'EXLMART' },
	{ code: '1784189', name: 'FBONLINE TRADING PVT LTD' },
	{ code: '983842', name: 'FIDES DISTRIBUTION PVT LTD' },
	{ code: '706913', name: 'FLIPKART INDIA PVT LTD' },
	{ code: '1332866', name: 'FONE HOUSE' },
	{ code: '1318732', name: 'FONE4 COMMUNICATIONS INDIA PVT LTD' },
	{ code: '1320645', name: 'FONEZ 4U' },
	{ code: '2157763', name: 'FRONTIER BUSINESS SYSTEMS PVT LTD' },
	{ code: '474795', name: 'FUTURE RETAIL LIMITED' },
	{ code: '603337', name: 'FUTURE WORLD RETAIL PVT LTD' },
	{ code: '889765', name: 'FUTURE WORLD RETAIL PVT LTD (SAT)' },
	{ code: '1504753', name: 'FUTUREEVENTS ENTERPRISES PVT LTD' },
	{ code: '844666', name: 'G E TRADECOM PVT LTD' },
	{ code: '580531', name: 'G.D ELECTRONICS' },
	{ code: '1107844', name: 'GADGET IT SOLUTIONS' },
	{ code: '595856', name: 'GALAXY OFFICE AUTOMATION PVT LTD' },
	{ code: '1696643', name: 'GALLERY RETAIL LLP' },
	{ code: '1535349', name: 'GARDENIA TELECOM PVT LTD' },
	{ code: '1763052', name: 'GGC INDUSTRIES PVT LTD' },
	{ code: '580297', name: 'GLOBAL PREMIUM LIFESTYLE PVT LTD' },
	{ code: '894845', name: 'GLOBE MOBILITY PVT LTD' },
	{ code: '1702975', name: 'GNG ELECTRONICS PVT LTD' },
	{ code: '546546', name: 'GRAPHIC TRADES PVT LTD' },
	{ code: '291327', name: 'GREAT EASTERN APPLIANCES PVT LTD' },
	{ code: '2112640', name: 'GREAT EASTERN RETAIL PVT LTD' },
	{ code: '1642514', name: 'GREEN MANAGEMENT' },
	{ code: '1540733', name: 'H.P TELECOM INDIA PVT LTD' },
	{ code: '1555020', name: 'HANAFI TELECOM PVT LTD' },
	{ code: '564189', name: 'HARDTRAC COMPUTER SERVICES PVT LTD' },
	{ code: '573521', name: 'HARI OM RETAIL PVT LTD' },
	{ code: '1520224', name: 'HARSHITH ENTERPRISES' },
	{ code: '1541663', name: 'HDN TELECOM PVT LTD' },
	{ code: '2035295', name: 'HELLO MOBILES PRIVATE LIMITED' },
	{ code: '874393', name: 'HI-Q ENTERPRISES PVT LTD' },
	{ code: '1618712', name: 'HOTLINE COMMUNICATIONS' },
	{ code: '465109', name: 'HOTSPOT SALES & SOLUTIONS PVT LTD' },
	{ code: '1307289', name: 'HRA SALES PRIVATE LIMITED' },
	{ code: '1389876', name: 'I CONCEPT' },
	{ code: '1341256', name: 'I MAGIC' },
	{ code: '1316243', name: 'I ZONE' },
	{ code: '1538470', name: 'I-KINGDOM RETAIL PVT LTD' },
	{ code: '1473907', name: 'IACCESS SYSTEMS PVT LTD' },
	{ code: '1644353', name: 'IDELTA ELECTRONIC SERVICES LLP' },
	{ code: '1545893', name: 'IMPRESSIVE TRADEVISION PVT LTD' },
	{ code: '1213846', name: 'INDIA - NAR - COMMERCIAL' },
	{ code: '564183', name: 'INDIRA SWITCH' },
	{ code: '688012', name: 'INDUS MOBILE DISTRIBUTION PVT LTD' },
	{ code: '776161', name: 'INFINIUM (INDIA) LIMITED' },
	{ code: '1640861', name: 'INFOBAHN TECHNICAL SOLUTIONS (I) PVT LTD' },
	{ code: '333883', name: 'INIT SYSTEMS' },
	{ code: '2158317', name: 'INITIATIVE DATA SYSTEMS PVT LTD' },
	{ code: '520776', name: 'INITIATIVE DATA SYSTEMS PVT LTD' },
	{ code: '1542275', name: 'IPDNA COMMUNICATIONS & SERVICES PVT LTD' },
	{ code: '1326405', name: 'IPEARL' },
	{ code: '1057573', name: 'IRIN INFOMEDIA' },
	{ code: '1660477', name: 'ISHOP SOLUTION' },
	{ code: '957176', name: 'IT LOGISTICS' },
	{ code: '1315024', name: 'IT NET INFOCOM PVT LTD' },
	{ code: '509537', name: 'IT NET INFOCOM PVT LTD' },
	{ code: '1632126', name: 'IWISH RETAIL' },
	{ code: '232760', name: 'IWORLD BUSINESS SOLUTIONS PVT LTD' },
	{ code: '957417', name: 'IZENICA' },
	{ code: '1551448', name: 'JALAN INFOSYSTEM PVT LTD' },
	{ code: '1065529', name: 'JAMSONS COMPUTERS PVT LTD' },
	{ code: '1170474', name: 'JARIWALA SALES PVT LTD' },
	{ code: '1724483', name: 'JASRAJ MOBILINKS PVT LTD' },
	{ code: '885017', name: 'JEMS DISTRIBUTORS PVT LTD' },
	{ code: '384833', name: 'JUMBO ELECTRONICS CORPORATION P LTD' },
	{ code: '1548507', name: 'JUST BUY LIVE ENTERPRISE PVT LTD' },
	{ code: '1107916', name: 'JUST SHOPPING PVT LTD' },
	{ code: '1549118', name: 'K K VOICE SOLUTIONS PVT. LTD' },
	{ code: '843763', name: 'K NEE DISTRIBUTORS PVT LTD' },
	{ code: '1096065', name: 'K.C. ELECTRONICS' },
	{ code: '1540028', name: 'K.H DISTRIBUTORS PVT LTD' },
	{ code: '293097', name: 'K.P COMMERCIAL PVT LTD' },
	{ code: '1572573', name: 'KAMAL INFOCOMM PVT LTD' },
	{ code: '1522864', name: 'KARTHIK TECH WORLD' },
	{ code: '1539669', name: 'KBRL RETAIL VENTURES LLP' },
	{ code: '1693440', name: 'KBRL RETAIL VENTURES LLP (MONO)' },
	{ code: '1688559', name: 'KEERTHANAA TELECOM PVT LTD' },
	{ code: '1547844', name: 'KESARS AND KESARS PVT LTD' },
	{ code: '831855', name: 'KGR ENTERPRISES PVT LTD' },
	{ code: '2112625', name: 'KHOSLA ELECTRONICS PVT LTD' },
	{ code: '1743109', name: 'KIRAN PHONES PVT LTD' },
	{ code: '1321798', name: 'KISHA TELELINKS PVT LTD' },
	{ code: '1730399', name: 'KONKAN GRAPHICS PVT LTD' },
	{ code: '1471328', name: 'KRISH INFOSYSTEMS' },
	{ code: '562508', name: 'KRISHNA AGENCIES PVT LTD' },
	{ code: '890381', name: 'KRISHNA WIRELESS DISTRIBUTORS PVT LTD' },
	{ code: '1319858', name: 'KRISHNAM ENTERPRISES' },
	{ code: '1555010', name: 'KSH DISTRIBUTORS PVT LTD' },
	{ code: '1043275', name: 'KUKREJA ELECTRONICS SOLUTIONS PVT LTD' },
	{ code: '874355', name: 'KUKREJA MARKETING PVT LTD' },
	{ code: '691825', name: 'LALANI INFOTECH LTD' },
	{ code: '1354634', name: 'LIMTON PVT LTD' },
	{ code: '558822', name: 'LOGTECH INFOWAY PVT LTD' },
	{ code: '824016', name: 'LOT MOBILES PVT LTD' },
	{ code: '766617', name: 'LULU CONNECT' },
	{ code: '824898', name: 'M/S ANIL ELECTRONICS' },
	{ code: '677290', name: 'M/S GALAXY' },
	{ code: '1605992', name: 'M/S STAR ENTERPRISES' },
	{ code: '787152', name: 'M/S VEDA TECHNOLOGIES & SOLUTIONS' },
	{ code: '1522843', name: 'M3 PROMOTERS PVT LTD' },
	{ code: '240611', name: 'MAC CLINIC MEDIA SOLUTIONS PVT LTD' },
	{ code: '1504105', name: 'MAGIC MOBILES' },
	{ code: '1107898', name: 'MAGNUM COMPUTERS' },
	{ code: '1592131', name: 'MAHAVIR ENTERPRISE' },
	{ code: '1049876', name: 'MAHESH DISTRIBUTORS PVT LTD' },
	{ code: '1329171', name: 'MAHESH RETAILS' },
	{ code: '240603', name: 'MAPLE DIGITAL TECHNOLOGY INT. PVT LTD' },
	{ code: '1024402', name: 'MAPLE DIGITAL TECHNOLOGY INT. PVT.LTD (SAT)' },
	{ code: '898507', name: 'MAPLE TECHNOLOGIES' },
	{ code: '1516618', name: 'MARUTI NANDAN TELECOMM LLP' },
	{ code: '1703764', name: 'MAYUR TELECOM PVT LTD' },
	{ code: '629683', name: 'MEGA COMPUTERS' },
	{ code: '525872', name: 'MEHAK DATA SYSTEMS' },
	{ code: '1975279', name: 'MGA AHUJA ENTERPRISES PVT LTD' },
	{ code: '1107855', name: 'MICRONIX INFOSOLUTIONS LTD' },
	{ code: '1763059', name: 'MINESOURCE INDIA PVT LTD' },
	{ code: '926157', name: 'MITTAL ENTERPRISES' },
	{ code: '890706', name: 'MITTAL INFOCOM PVT LTD' },
	{ code: '1489686', name: 'MITTAL SOLUTIONS PVT LTD' },
	{ code: '1599989', name: 'MK ISTORES PVT LTD' },
	{ code: '600688', name: 'MKG COMPUTERS PVT LTD' },
	{ code: '1041680', name: 'MM9 INFORMATION TECHNOLOGIES PVT LTD' },
	{ code: '1321748', name: 'MOBICARE' },
	{ code: '1060013', name: 'MOBILE MOOVERS' },
	{ code: '1541412', name: 'MOBILE MOOVERS INC' },
	{ code: '1549126', name: 'MOBILE PLANET' },
	{ code: '1583945', name: 'MOBILE TECHNOLOGY COMPANY' },
	{ code: '892772', name: 'MOBILITI WORLD' },
	{ code: '564195', name: 'MONARCH INFOTECH' },
	{ code: '1323745', name: 'MONTANA COMMUNICATIONS' },
	{ code: '1551445', name: 'MOSARAM BHAGWANDAS (AGENCIES)' },
	{ code: '1530924', name: 'MSM COMPUTER WORLD LLP' },
	{ code: '540066', name: 'MSM COMPUTERS' },
	{ code: '1540052', name: 'MUNDRA MARKETING PVT LTD' },
	{ code: '1599696', name: 'N.R KAMAT AGENCIES PVT LTD' },
	{ code: '722018', name: 'N.S. JAIN & CO. PVT LTD' },
	{ code: '728281', name: 'NAKODA COMPUTRONICS' },
	{ code: '1120346', name: 'NATIONWIDE MEDIA & ENTERTAINMENT PVT LTD' },
	{ code: '1538459', name: 'NAVSHI INFOTECH PVT LTD' },
	{ code: '1781715', name: 'NESCO INFOSYSTEM PVT LTD' },
	{ code: '291264', name: 'NGRT SYSTEMS PVT LTD' },
	{ code: '1049796', name: 'NICE FOTO MAKERS PVT LTD' },
	{ code: '1549115', name: 'NIRANJANA TELECOM PVT LTD' },
	{ code: '1192915', name: 'NOOK MICRO DISTRIBUTION LTD' },
	{ code: '1037876', name: 'NUANCE GROUP FASHION & LUXURY DUTY FREE PVT LTD' },
	{ code: '890141', name: 'NUANCE GROUP INDIA PVT LTD' },
	{ code: '290539', name: 'NYASA ENTERPRISE' },
	{ code: '1278483', name: 'NYASA TECHNOLOGIES' },
	{ code: '790307', name: 'OFFICE SHOP PVT LTD' },
	{ code: '2111155', name: 'ORIENT TECHNOLOGIES PVT LTD' },
	{ code: '2093542', name: 'OXYGEN THE DIGITAL SHOP' },
	{ code: '558302', name: 'OXYGEN THE DIGITAL SHOP' },
	{ code: '1541402', name: 'P.P MARKETING & SERVICES PVT LTD' },
	{ code: '1240752', name: 'P3S SOLUTIONS' },
	{ code: '603369', name: 'P3S VENTURES' },
	{ code: '820928', name: 'PACIFIC CITIFONE DEALS PVT LTD' },
	{ code: '600693', name: 'PAI INTERNATIONAL ELECTRONICS LTD' },
	{ code: '1734494', name: 'PARINAY COMMUNICATIONS PVT LTD' },
	{ code: '810232', name: 'PARKKAVI TRADING PVT LTD' },
	{ code: '633054', name: 'PC WORLD' },
	{ code: '240610', name: 'PCSS SOLUTIONS PRIVATE LIMITED' },
	{ code: '780767', name: 'PEGASUS RETAIL INDIA PVT LTD' },
	{ code: '274374', name: 'PICATYPE SYSTEMS PVT LTD' },
	{ code: '562499', name: 'PLACEWELL SYSTEMS & SOLUTIONS PVT LTD' },
	{ code: '1559214', name: 'PNR RETAIL LLP' },
	{ code: '1644359', name: 'POORVIKA MOBILES PVT LTD' },
	{ code: '715263', name: 'POORVIKA MOBILES PVT LTD' },
	{ code: '890697', name: 'POWER TRADEWINGS PVT LTD' },
	{ code: '1515108', name: 'PRAKASH RETAIL PVT LTD' },
	{ code: '1066646', name: 'PRATHAM INFRATEL PVT LTD' },
	{ code: '1096099', name: 'PREM SONS' },
	{ code: '1055069', name: 'PREMIER NIRMAN PVT LTD' },
	{ code: '540345', name: 'PREMIUM LIFESTYLE & FASHION INDIA PVT LTD' },
	{ code: '889753', name: 'PREMIUM LIFESTYLE & FASHION INDIA PVT LTD (MONO)' },
	{ code: '1112213', name: 'PRIME COMMUNICATION' },
	{ code: '1039535', name: 'PRIYANKA WIRELESS (INDIA) PVT LTD' },
	{ code: '558301', name: 'QRS RETAIL LIMITED' },
	{ code: '1551442', name: 'R G COMMERCIAL' },
	{ code: '1056947', name: 'R G NETWORKS' },
	{ code: '588991', name: 'R.A SANTANA MARKETING SERVICES PVT LTD' },
	{ code: '1540134', name: 'R.G CELLULARS PVT LTD' },
	{ code: '838691', name: 'RADIANT KAILASH AGENCIES PVT LTD' },
	{ code: '1554730', name: 'RAHUL PHARMA DISTRIBUTORS' },
	{ code: '605875', name: 'RAKESH TRADING COMPANY' },
	{ code: '1593678', name: 'REALTIME TECHNOLGIES' },
	{ code: '1645313', name: 'RED MARKETING SERVICES' },
	{ code: '1719703', name: 'REGAL SUPPLIER PVT LTD' },
	{ code: '589107', name: 'RELIANCE RETAIL LIMITED' },
	{ code: '284446', name: 'RELIANCE RETAIL LIMITED' },
	{ code: '843757', name: 'REPORTING ONLY' },
	{ code: '843754', name: 'REPORTING ONLY' },
	{ code: '843748', name: 'REPORTING ONLY' },
	{ code: '843756', name: 'REPORTING ONLY' },
	{ code: '1963275', name: 'RMK DIGITAL PVT LTD' },
	{ code: '1633696', name: 'ROHAN ISTATION PVT LTD' },
	{ code: '1098976', name: 'ROHAN PERIPHERALS PVT LTD' },
	{ code: '1062270', name: 'ROYALMATE DISTRIBUTORS PVT LTD' },
	{ code: '241238', name: 'RS TWO THREE TWO (P) LTD' },
	{ code: '114009', name: 'RSG INFOTECH PVT LTD' },
	{ code: '439496', name: 'RSG SOLUTIONS PVT LTD' },
	{ code: '1746505', name: 'RSR INFORMATICS PVT LTD' },
	{ code: '1549121', name: 'S G CORPORATE MOBILITY PVT LTD' },
	{ code: '1267952', name: 'S.K ELECTRODS PVT LTD' },
	{ code: '1318720', name: 'S.K RETAIL VENTURES' },
	{ code: '1030376', name: 'S.MUNJAL INFOSOLUTIONS PVT LTD' },
	{ code: '831253', name: 'S.V TELECOM PVT LTD' },
	{ code: '735108', name: 'SA INFOSYS PVT LTD' },
	{ code: '1516622', name: 'SAANVI ENTERPRISES' },
	{ code: '1709960', name: 'SABRIMALA INDUSTRIES INDIA LIMITED' },
	{ code: '728215', name: 'SACH TELECOM PVT LTD' },
	{ code: '1736463', name: 'SADGURU DYNAMICS PVT LTD' },
	{ code: '705739', name: 'SAI VARDHAN ASSOCIATES PVT LTD' },
	{ code: '654098', name: 'SAIRAJ TECH INFRA PVT LTD' },
	{ code: '1337601', name: 'SAITRONICS SYSTEMS PVT LTD' },
	{ code: '558611', name: 'SALES INDIA PVT LTD' },
	{ code: '1594522', name: 'SALORA INTERNATIONAL LIMITED' },
	{ code: '1330430', name: 'SALUJA ELECTRONICS' },
	{ code: '1076081', name: 'SALUJA OVERSEAS PVT LTD' },
	{ code: '558722', name: 'SAN INFOTEK' },
	{ code: '1542286', name: 'SANASH IMPEX PVT LTD' },
	{ code: '543791', name: 'SANGEETHA MOBILES PRIVATE LIMITED' },
	{ code: '791304', name: 'SANMATI TRADERS' },
	{ code: '1547847', name: 'SANSON SALES PVT LTD' },
	{ code: '706905', name: 'SANWARIA TEXPRO PVT LTD' },
	{ code: '561775', name: 'SAVITHRI DIGITAL COMPUTERS' },
	{ code: '1551760', name: 'SEKTRA MARKETING SERVICES PVT LTD' },
	{ code: '2043785', name: 'SERVICE LEE TECHNOLOGIES PVT LTD' },
	{ code: '719519', name: 'SHALIMAR SALES PVT LTD' },
	{ code: '1948844', name: 'SHINOY MARKETING PVT LTD' },
	{ code: '1386406', name: 'SHREE RANI SATI TRADECORP PVT LTD' },
	{ code: '1540091', name: 'SHRI MAA MARKETING PVT LTD' },
	{ code: '1367499', name: 'SHRI SANWAR SAI INFOCOM PVT LTD' },
	{ code: '1698232', name: 'SHRI SANWAR SAI INFOCOM PVT LTD' },
	{ code: '558303', name: 'SHWETA COMPUTERS & PERIPHERALS' },
	{ code: '1542262', name: 'SHWETA MOBILES & COMPUTERS' },
	{ code: '1547839', name: 'SIDDH CORPORATION PVT LTD' },
	{ code: '1620088', name: 'SILVER SYSTEM ENTERPRISES PVT LTD' },
	{ code: '1538263', name: 'SIMPHONY MOBILE PVT LTD' },
	{ code: '1741260', name: 'SIMPLY CELLULAR TELECOM PVT LTD' },
	{ code: '1719289', name: 'SISL INFOTECH PVT LTD' },
	{ code: '1592113', name: 'SMRM INNOVATIVE VENTURES PVT LTD' },
	{ code: '1323705', name: 'SNEH COMMUNICATION' },
	{ code: '485441', name: 'SOFTCELL TECHNOLOGIES GLOBAL PVT LTD' },
	{ code: '1242903', name: 'SOGO COMPUTERS PVT LTD' },
	{ code: '330379', name: 'SOLUTION' },
	{ code: '1471640', name: 'SPECTRUM TRADE & COMMUNICATION CENTRE' },
	{ code: '1515101', name: 'SRI SHYAM BABA ELECTRONIC PVT LTD' },
	{ code: '1599701', name: 'SRS MULTIBRAND RETAIL PVT. LTD.' },
	{ code: '838697', name: 'SRT ENTERPRISES PVT LTD' },
	{ code: '2119880', name: 'SS COMMUNICATION & SERVICES PVT LTD' },
	{ code: '1538260', name: 'SSHOPPEE.COM PVT LTD' },
	{ code: '1816080', name: 'STERLING INFOWAYS PVT LTD' },
	{ code: '1594523', name: 'STRIDES COMMERCE PVT LTD' },
	{ code: '1057660', name: 'SUN SYSTEMS' },
	{ code: '1398138', name: 'SUN SYSTEMS' },
	{ code: '605868', name: 'SUNTRONIX' },
	{ code: '1688569', name: 'SUPER POWER GADGETS PVT. LTD.' },
	{ code: '1302201', name: 'SUPERIOR SYSTEMS' },
	{ code: '1622694', name: 'SUPERIOR TELE MOBILES PVT LTD.' },
	{ code: '560246', name: 'SUPREME COMPUTERS INDIA PVT LTD' },
	{ code: '558299', name: 'SUPREME IT MALL' },
	{ code: '1504130', name: 'SUPREME PARRADISE' },
	{ code: '831859', name: 'SURESH KUMAR AND SONS TRADING PVT LTD' },
	{ code: '1554718', name: 'SV ECCENTRICS PVT LTD' },
	{ code: '1540742', name: 'SWAPNALOK DISTRIBUTION HUB PVT LTD' },
	{ code: '1540728', name: 'SWARANBHUMI TRADE LINK PVT LTD' },
	{ code: '1549149', name: 'SYNERGY SYSTEMS' },
	{ code: '228374', name: 'SYSTEMATIX MEDIA' },
	{ code: '1519862', name: 'T.J COMMUNICATIONS PVT LTD' },
	{ code: '432779', name: 'TEAM COMPUTERS PVT LTD' },
	{ code: '1570885', name: 'TECH GALLERY PVT LTD' },
	{ code: '1519869', name: 'TECH INDIA INFOCOM PVT LTD' },
	{ code: '1251900', name: 'TECHNO KART INDIA LTD' },
	{ code: '1475869', name: 'TECHNOVISION SALES AND SERVICES' },
	{ code: '1688615', name: 'TECHSPARK VENTURES' },
	{ code: '241230', name: 'TECHVANTAGE' },
	{ code: '351996', name: 'TEKNO SOLUTION PVT LTD' },
	{ code: '1472953', name: 'TELEECARE NETWORK INDIA PVT LTD' },
	{ code: '1098987', name: 'TELEGLOBAL DISTRIBUTORS PVT LTD' },
	{ code: '1600971', name: 'TELEPHONE SHOPPEES' },
	{ code: '1475899', name: 'THE CHENNAI MOBILES' },
	{ code: '475422', name: 'THE MOBILE STORE SERVICES LIMITED' },
	{ code: '2252477', name: 'THOUGHTSOL INFOTECH PVT LTD' },
	{ code: '1321255', name: 'TIME LIFESTYLE PVT LTD' },
	{ code: '2211117', name: 'TIRUMALA MUSIC CENTRE PVT LTD' },
	{ code: '595865', name: 'TONERLINE PRIVATE LIMITED' },
	{ code: '1699554', name: 'TOUCHSTONE TELESERVICES PVT LTD' },
	{ code: '981147', name: 'TRESOR SYSTEMS PVT LTD' },
	{ code: '1041724', name: 'TRESOR SYSTEMS PVT LTD (SAT)' },
	{ code: '1012443', name: 'TULSI MARKETING' },
	{ code: '1624460', name: 'TVCORP SOLUTIONS PVT LTD' },
	{ code: '1525033', name: 'ULTIMATE PREMIUM RETAIL' },
	{ code: '1570889', name: 'UMANG BUSINESS CONSULTANT PVT LTD' },
	{ code: '209914', name: 'UNICORN INFOSOLUTIONS PVT LTD' },
	{ code: '991875', name: 'UNICORN INFOSOLUTIONS PVT LTD (MONO)' },
	{ code: '1702968', name: 'UNIFOUR VENTURES PVT LTD' },
	{ code: '2304597', name: 'UNITED INDIA MARKETING PVT LTD' },
	{ code: '1520188', name: 'V.R INFOZONE PVT LTD' },
	{ code: '1107250', name: 'VALUE POINT SYSTEMS PVT LTD' },
	{ code: '1699563', name: 'VCELL INDIA IMPEX PVT LTD' },
	{ code: '1473553', name: 'VEGA SOLUTIONS PVT LTD' },
	{ code: '2381460', name: 'VELOCIS SYSTEMS PRIVATE LIMITED' },
	{ code: '1556449', name: 'VELOCIS SYSTEMS PVT LTD' },
	{ code: '291806', name: 'VENUS DATA PRODUCTS' },
	{ code: '1143774', name: 'VENUS DATA PRODUCTS (SAT)' },
	{ code: '823954', name: 'VERSATILE MOBILE DISTRIBUTORS PVT LTD' },
	{ code: '405688', name: 'VIJAY SALES' },
	{ code: '1709965', name: 'VISION DISTRIBUTION PVT LTD' },
	{ code: '283889', name: 'VISION EFFECT TECHNOSOLUTIONS P.LTD' },
	{ code: '722023', name: 'VISION TELECOMMUNICATION INDIA PVT LTD' },
	{ code: '1350247', name: 'VODAFONE IDEA COMMUNICATION SYSTEMS LIMITED' },
	{ code: '1545890', name: 'VOHRA MOBILES PVT LTD' },
	{ code: '1677613', name: 'VRP TELEMATICS PVT LTD' },
	{ code: '1393430', name: 'VSCG DISTRIBUTION PVT LTD' },
	{ code: '1556463', name: 'VSN INTERNATIONAL' },
	{ code: '1540722', name: 'WALVEKAR TELECOM PVT LTD' },
	{ code: '1600956', name: 'WELL KNOWN COMPUTERS PVT LTD' },
	{ code: '290289', name: 'WORK STORE LIMITED' },
	{ code: '409928', name: 'WORTH TECHNOLOGY PRIVATE LIMITED' },
	{ code: '2034005', name: 'WWES IT SOLUTIONS PRIVATE LIMITED' },
	{ code: '1968253', name: 'WYSETEK SYSTEMS TECHNOLOGISTS PVT LTD' },
	{ code: '1112186', name: 'XAGE COMMUNICATION INDIA PVT LTD' },
	{ code: '1641186', name: 'XELERATE' },
	{ code: '1540725', name: 'YO DIGITALS PVT LTD' },
	{ code: '560193', name: 'YOUDAN MARKETING' },
	{ code: '1131598', name: 'ZENICA LIFESTYLE PVT LTD (SAT)' },
	{ code: '1709966', name: 'ZENITH TELECOMMUNICATIONS PVT LTD' },
	{ code: '1108613', name: 'ZONES CORPORATE SOLUTIONS PVT LTD' },
	{ code: '1446695', name: 'IT LOBBY' },
	{ code: '1592133', name: 'MASTER GROUP' },
	{ code: '890922', name: 'OTHER IPHONE- IRAQ' },
	{ code: '1197976', name: 'A.NIN-NUN LTD' },
	{ code: '1354250', name: 'AKADEMON ISRAEL LTD' },
	{ code: '1207072', name: 'AKRAM SBITANY & SONS CO. LTD' },
	{ code: '1787236', name: 'ALFA' },
	{ code: '1420320', name: 'A.L.M COMMERCE 2000 LTD' },
	{ code: '1792823', name: 'AUTOPHONE' },
	{ code: '542397', name: 'BEST BUY MARKETING CHAINS LTD' },
	{ code: '1792830', name: 'BEST MOBILE LTD' },
	{ code: '2348297', name: 'BEZEQ' },
	{ code: '363652', name: 'BUG MULTISYSTEM LTD.' },
	{ code: '994193', name: 'C.T.I GO MOBILE LTD' },
	{ code: '1792836', name: 'CLUB PHONE' },
	{ code: '2112810', name: 'COM-MEDIA COMPUTER SERVICES' },
	{ code: '1793047', name: 'COVER SHOP' },
	{ code: '2487286', name: 'DIRECT CURRENT LTD' },
	{ code: '534973', name: 'DOUBLE TEAM COMPUTERS LTD.' },
	{ code: '1426704', name: 'ELECTRA CONSUMER PRODUCTS (1951) LTD' },
	{ code: '1393553', name: 'ESPIRCOM SYSTEMS LTD' },
	{ code: '2079993', name: 'FREE' },
	{ code: '1793053', name: 'GREEN MOBILE' },
	{ code: '1368098', name: 'HI BIZ LTD' },
	{ code: '1489559', name: 'IBM ISRAEL LTD.' },
	{ code: '1220315', name: 'ICELL G.M.A LTD' },
	{ code: '340753', name: 'IDIGITAL STORE LTD' },
	{ code: '1220338', name: 'IDIGITAL SOLUTIONS LTD' },
	{ code: '555092', name: 'IHOUSE FOR COMPUTERS' },
	{ code: '2106703', name: 'INFORMAT COMPUTERS LTD' },
	{ code: '1465710', name: 'ISTORE' },
	{ code: '562962', name: 'IVORY COMPUTERS LTD' },
	{ code: '1744817', name: 'K.S.P' },
	{ code: '976341', name: 'KRAVITZ' },
	{ code: '629196', name: 'MATRIX IT INTEGRATION&INFRASTRUCTUES LTD' },
	{ code: '1197974', name: 'MICROJUK COMPUTERS LTD' },
	{ code: '1793063', name: 'MILLENIUM' },
	{ code: '1793057', name: 'MY PHONE - WE GOT YOU COVERED' },
	{ code: '2080262', name: 'OFFICE DEPOT' },
	{ code: '1197945', name: 'ONE SYSTEM INTEGRATION LTD.' },
	{ code: '1393148', name: 'OPTIMIZE COMPUTERS LTD' },
	{ code: '1793058', name: 'ORIPHONE' },
	{ code: '1023683', name: 'PETCOM ELECTRIC EILAT LTD' },
	{ code: '1793068', name: 'SMS MOBILE' },
	{ code: '1197975', name: 'WEDIGGIT LTD' },
	{ code: '1792383', name: 'YOYO PHONE' },
	{ code: '2093576', name: 'ZONES TECHNOLOGY ISRAEL LTD' },
	{ code: '1507349', name: 'BOLLESTORE - HQ' },
	{ code: '1070709', name: 'AL MATAJJER' },
	{ code: '1592519', name: 'ARAB RAYA LLC (ARCO)' },
	{ code: '1809005', name: 'E-STORE' },
	{ code: '2049548', name: 'ISYSTEMS - HQ' },
	{ code: '557789', name: 'MULTI VISION' },
	{ code: '563323', name: 'SMARTBUY' },
	{ code: '543482', name: 'ALSER COMPUTERS MEREY' },
	{ code: '694497', name: 'ATYRAU TECHINVEST' },
	{ code: '994549', name: 'BELIY VETER KZ LTD' },
	{ code: '872430', name: 'EVRIKA' },
	{ code: '1301650', name: 'FORA' },
	{ code: '285735', name: 'IPOINT' },
	{ code: '2082442', name: 'IPOINT HQ' },
	{ code: '670537', name: 'KIBERNET' },
	{ code: '673707', name: 'MECHTA HQ' },
	{ code: '1214043', name: 'MEGATEL' },
	{ code: '563203', name: 'SULPAK' },
	{ code: '1197438', name: 'TECHNODOM HQ' },
	{ code: '1791381', name: 'AVI COMMUNICATION' },
	{ code: '274616', name: 'ELITE - SARIT CENTRE' },
	{ code: '1791383', name: 'GLOBOEDGE - HQ' },
	{ code: '889137', name: 'KENYA BUCKET' },
	{ code: '439191', name: 'MAC & MORE SOLUTIONS' },
	{ code: '274606', name: 'PERSONAL SYSTEMS' },
	{ code: '756774', name: 'SALUTE I WORLD - HQ' },
	{ code: '1789769', name: 'ABDUL LATIF JAMEEL TECHNOLOGY PRODUCTS CO. LTD' },
	{ code: '1787979', name: 'ADVANCED INFORMATION TECHNOLOGY' },
	{ code: '1789965', name: 'AHMAD ABDULWAHID ABDULLAH ANAAM &' },
	{ code: '1787995', name: 'AHMAD KHALIFA AL-KHALIFA EST.' },
	{ code: '1431979', name: 'AJYAL COMPUTERS TRADING EST.' },
	{ code: '1788492', name: 'AKLANIAT' },
	{ code: '1978101', name: 'AL MUSBAH INTERNATIONAL TRADING CO' },
	{ code: '1787988', name: 'AL TUFFAHA AL MOKHDARRA FOR TELECOMMUNICATION' },
	{ code: '1787996', name: 'AL-ADAWAT AL-ELECTRONYAH' },
	{ code: '1788111', name: 'AL-MOKALAMAH AL-SAREEAH' },
	{ code: '1789120', name: 'AL-MUTTASIL TELECOMUNICATION DEVICES CO. LTD' },
	{ code: '1789021', name: 'AL-RASAIL FOR COMMUNICATION DEVICES EST' },
	{ code: '2415365', name: 'ALAM WATEEN COMMUNICATION EST' },
	{ code: '1788003', name: 'ALGHANIM INTERNATIONAL ELECTRONICS' },
	{ code: '1789088', name: 'ALHADAF SYSTEM EST' },
	{ code: '1787926', name: 'ALI REZA HOLDING' },
	{ code: '1788545', name: 'ALL MATAJER AL KOUBRA' },
	{ code: '1788117', name: 'ALMOKAAB ALMOMAYAZ CO' },
	{ code: '2048744', name: 'ALNASEJ INTERNATIONAL COMPANY LIMITED' },
	{ code: '1787978', name: 'AMAL MOHAMMED MESSAOUDI' },
	{ code: '2372520', name: 'ASBAQ FOR TRANSPORTATION AND STORAGE' },
	{ code: '1789104', name: 'ASSR AL JAWAL FOR TRADING' },
	{ code: '633123', name: 'AXIOM TELECOM' },
	{ code: '1788570', name: 'AYSSAR ABDULMAJEED ALKHAYAL EST.' },
	{ code: '1789901', name: 'BASHEER NOOR ALDEAN ALHADAD CO FOR ELECTRONIC' },
	{ code: '1948943', name: 'BAUD TELECOM COMPANY' },
	{ code: '1991418', name: 'BAYT ASASE TRADING EST.' },
	{ code: '1788115', name: 'CADY AL-KHAFJI TRADING EST.' },
	{ code: '1320457', name: 'COM HOUSE' },
	{ code: '1321207', name: 'COMPUBAND' },
	{ code: '1788002', name: 'DISTRIBUTION LINES FOR COMMUNICATION' },
	{ code: '573895', name: 'EMAX - HQ' },
	{ code: '2090284', name: 'EXCELLENCE ARABIA TRADING COMPANY' },
	{ code: '1789802', name: 'UNITED ELECTRONICS COMPANY' },
	{ code: '1279894', name: 'FAST TELECOM' },
	{ code: '1789345', name: 'FAWAZ ABDULAZIZ ALHOKAIR & CO. (SJSC)' },
	{ code: '1788493', name: 'FOUR DIRECTIONS COMMUNICATIONS & I.T. CO.' },
	{ code: '789709', name: 'G-TECH COMPANY LIMITED' },
	{ code: '1789101', name: 'GENERATION FEAT TRADING EST.' },
	{ code: '789645', name: 'GENIUS VALLEY FOR INFORMATION TECHNOLOGY AND' },
	{ code: '1025463', name: 'GLOBAL STORE - HQ' },
	{ code: '1332823', name: 'HAMAD ABDULLAH AL-MANEA' },
	{ code: '1788010', name: 'HAMAD BN ABDULLAH AL MANEA COMPANY' },
	{ code: '1366292', name: 'HOMY' },
	{ code: '1235269', name: 'I-CENTER' },
	{ code: '1237060', name: 'ICON STORE' },
	{ code: '1788560', name: 'IMACHINES LLC BRANCH' },
	{ code: '1789094', name: 'INTEGRATED MARKETING ENTERPRISES CO' },
	{ code: '1787993', name: 'ISTORE EST FOR TELCOMMICATION' },
	{ code: '1789791', name: 'ADVANCED COMPUTER AND ELECTRONIC DISTRIBUTION (ISTYLE)' },
	{ code: '285700', name: 'ISTYLE - HQ' },
	{ code: '1218904', name: 'ITECHIA' },
	{ code: '1788483', name: 'JADDAF TELECOM' },
	{ code: '1789854', name: 'JARIR MARKETING COMPANY' },
	{ code: '1789096', name: 'KHALID AL-HADDAD TELECOM CO . LTD' },
	{ code: '1980976', name: 'APPLE - OTHER RETAIL - KSA' },
	{ code: '1788110', name: 'KUNOOZ AL-BARAKA FOR TRADING EST.' },
	{ code: '1788108', name: 'LIFE ORBIT EST.' },
	{ code: '1789349', name: 'LULU SAUDI HYPERMARKET L.L.C' },
	{ code: '1788557', name: 'MAGDI ZIDAN ESTABLISHMENT' },
	{ code: '2123701', name: 'MAHMOOD MOHAMMAD HUSSAIN JUSTANIEAH TRADING EST.' },
	{ code: '2032939', name: 'MAJED FARED NAZER TRADING EST' },
	{ code: '1789091', name: 'MASHAEEL AL-ASEMAH FOR TRADING EST.' },
	{ code: '1788109', name: 'MATJAR AL-TATBIKAT FOR TRADING EST.' },
	{ code: '1789100', name: 'MDS FOR COMPUTER SYSTEMS' },
	{ code: '1431962', name: 'MOBASHR ALSOUT EST.' },
	{ code: '1789092', name: 'MODERN ELECTRONICS COMPANY LIMITED' },
	{ code: '1788116', name: 'MODERN MATRIX COMMERCIAL EST.' },
	{ code: '1787992', name: 'MOHANNAD SALEH AL-SHUWAIKHAT EST.' },
	{ code: '1789087', name: 'NAHIL COMPUTER COMPANY' },
	{ code: '1789099', name: 'NAIF MOHAMAD BASAMAD EST.' },
	{ code: '1789095', name: 'NAIZAK GLOBAL ENGINEERING SYSTEMS' },
	{ code: '1789090', name: 'NAJD TELECOM EST' },
	{ code: '1788487', name: 'NOUF IBRAHEM ALZAREA EST' },
	{ code: '1226308', name: 'OLAYA COMPUTERS' },
	{ code: '2059761', name: 'PANDA RETAIL COMPANY' },
	{ code: '1789023', name: 'QASER ALTHURAIAY TRADING' },
	{ code: '2060141', name: 'RADADI MADINA TRADING EST' },
	{ code: '1330334', name: 'RATEEMAH' },
	{ code: '1789093', name: 'ROKN ALMAJARRAH FOR TRADING CO' },
	{ code: '2005739', name: 'SAKHRATAIN INFORMATION TECHNOLOGY COMPANY BRANCH' },
	{ code: '1788120', name: 'SALEH ABDULHAMEED AL-HAZMI' },
	{ code: '1789105', name: 'SAUDI BUSINESS MACHINES LTD' },
	{ code: '1789119', name: 'SAUDI COMPANY FOR HARDWARE' },
	{ code: '2079406', name: 'SAUDI FRENCH COMPANY FOR DUTY FREE OPERATIONS & MANAGEMENT' },
	{ code: '1789288', name: 'SAUDI HYPERMARKET COMPANY' },
	{ code: '1789102', name: 'SAUDI INTELLIGENT SOLUTIONS' },
	{ code: '1426488', name: 'SAUDI TELECOM AXIOM CO. BRANCH' },
	{ code: '1794662', name: 'SAUDI UNICOM FOR COMMUNICATION' },
	{ code: '1788485', name: 'SEDER GROUP COMPANY' },
	{ code: '1788566', name: 'SHIFAA AL-RIYADH FOR TRADING EST.' },
	{ code: '1325210', name: 'SMARTSTORE' },
	{ code: '494816', name: 'STEP TECH' },
	{ code: '1428347', name: 'SUPPORTING PROJECTS CO.' },
	{ code: '1788491', name: 'SYNC STORE FOR COMPUTERS EST.' },
	{ code: '2098005', name: 'TOTAL TECHNICAL TRIANGLE CO.' },
	{ code: '1326245', name: 'UNICOM' },
	{ code: '1789326', name: 'UNITED EDDY CO. LTD.' },
	{ code: '1789103', name: 'UNITED INTERNATIONAL TRADING' },
	{ code: '2045569', name: 'WARD NASIRIYAH FOR COMMUNICATIONS AND INFORMATION TECHNOLOGY' },
	{ code: '1340643', name: 'WASEET ELECT.' },
	{ code: '1789017', name: 'SAMTIAH ARABIA CO LTD' },
	{ code: '561784', name: 'AIC' },
	{ code: '563922', name: 'AL-NAHAR' },
	{ code: '624384', name: 'BEST - HQ' },
	{ code: '624399', name: 'CARREFOUR - HQ' },
	{ code: '285704', name: 'DIGITS - HQ' },
	{ code: '624254', name: 'EUREKA' },
	{ code: '1537089', name: 'FIRST LINE' },
	{ code: '683739', name: 'FUTURE COMMUNICATIONS COMPANY' },
	{ code: '1796321', name: 'GAIT' },
	{ code: '265033', name: 'IOC' },
	{ code: '624886', name: 'JARIR BOOKSTORE - HQ' },
	{ code: '917681', name: 'KUWAIT - OTHER RETAILERS' },
	{ code: '1586989', name: 'LULU - KUWAIT' },
	{ code: '491007', name: 'XCITE - HQ' },
	{ code: '1718089', name: '1A ONLINER LV HQ' },
	{ code: '437337', name: 'ATEA LV HQ' },
	{ code: '485379', name: 'CAPITAL LV HQ' },
	{ code: '279658', name: 'CAPITAL APR LV HQ OLD AID' },
	{ code: '352099', name: 'ELKOR LV HQ' },
	{ code: '442935', name: 'EURONICS LV HQ' },
	{ code: '1949949', name: 'IM ARVUTID LV APR HQ' },
	{ code: '1736537', name: 'ISTYLE LV APR HQ' },
	{ code: '1541426', name: 'JSC PIGU LV HQ' },
	{ code: '1101682', name: 'MARKIT LV HQ' },
	{ code: '490962', name: 'RD ELECTRONICS LV HQ' },
	{ code: '294632', name: 'LATVIJAS MOBILAIS TELEFONS SIA' },
	{ code: '1521984', name: 'TET HQ' },
	{ code: '535749', name: 'UPSTORE ONLINER LV HQ' },
	{ code: '657703', name: 'ACTYV' },
	{ code: '285705', name: 'ADKOM' },
	{ code: '265019', name: 'INTERLINK' },
	{ code: '345459', name: 'ISTYLE' },
	{ code: '890927', name: 'OTHER IPHONE- LEBANON' },
	{ code: '656843', name: 'ALYAM - HQ' },
	{ code: '683658', name: 'BERENICE TECH - HQ' },
	{ code: '360719', name: 'PROTECH - HQ' },
	{ code: '345463', name: 'ATEA LT HQ' },
	{ code: '352100', name: 'AVITELA LT HQ' },
	{ code: '279670', name: 'ELEKTROMARKT LT HQ' },
	{ code: '279660', name: 'IDEAL LT APR HQ' },
	{ code: '873414', name: 'IM ARVUTID LT APR HQ' },
	{ code: '279651', name: 'ISHOP ONLINER LT HQ' },
	{ code: '1101664', name: 'MARKIT LT HQ' },
	{ code: '1521954', name: 'MOBILI LINIJA LV HQ' },
	{ code: '535748', name: 'OMNITEL' },
	{ code: '1532476', name: 'PIGU ONLINER LT HQ' },
	{ code: '336990', name: 'RESIT LT HQ' },
	{ code: '380552', name: 'TECHNORAMA LT HQ' },
	{ code: '1627024', name: 'AB TELIA LIETUVA' },
	{ code: '352163', name: 'TOPO CENTRAS LT HQ' },
	{ code: '1700470', name: 'VARLE ONLINER LT HQ' },
	{ code: '665858', name: 'ISTYLE MACEDONIA HQ' },
	{ code: '1540763', name: 'NEPTUN MACEDONIA HQ' },
	{ code: '360720', name: 'MAKATY - HQ' },
	{ code: '731769', name: 'ASB - HQ' },
	{ code: '285689', name: 'ICENTRE BIRKIRKARA' },
	{ code: '1128354', name: 'MATRIX - SKYPARKS CENTRE' },
	{ code: '274621', name: 'DIGILIFE' },
	{ code: '858384', name: 'HORIZON MARTINIQUE DISTRIBUTION' },
	{ code: '363557', name: 'LIBRAIRIE ANTILLAISE GALLERIA - HQ' },
	{ code: '612539', name: 'CAMEL COMPUTERS' },
	{ code: '644136', name: 'APEX' },
	{ code: '413784', name: 'CASH & CARRY - HQ' },
	{ code: '595042', name: 'ISPACE - HQ' },
	{ code: '274599', name: 'LEAL - HQ' },
	{ code: '285708', name: 'PAOMA' },
	{ code: '543821', name: 'ALINA ELECTRONIC' },
	{ code: '559408', name: 'MCS COMPUTER CENTER' },
	{ code: '727125', name: 'ULTRA' },
	{ code: '651698', name: 'ICENTAR HQ' },
	{ code: '2039210', name: 'TECHNO MAX HQ' },
	{ code: '651286', name: 'COSMOS HQ' },
	{ code: '413785', name: 'DISWAY' },
	{ code: '442931', name: 'ELECTROPLANET HQ' },
	{ code: '572019', name: 'FNAC MAROC' },
	{ code: '564028', name: 'ISPOT' },
	{ code: '1999792', name: 'ISTYLE - HQ' },
	{ code: '274620', name: 'MAGIMAG - HQ' },
	{ code: '1754660', name: 'MARJANE' },
	{ code: '595039', name: 'MCI MA' },
	{ code: '1383363', name: 'VIRGIN MEGASTORE' },
	{ code: '435562', name: 'COMPACT MEGASTORE - HQ' },
	{ code: '274597', name: 'COMPU SYSTEMS - HQ' },
	{ code: '274596', name: 'DIGITAL PLANETE - HQ' },
	{ code: '771483', name: 'EXPERT - HQ' },
	{ code: '360771', name: 'ISTORE NC - HQ' },
	{ code: '771486', name: 'LAS DE TREFLE - HQ' },
	{ code: '1718481', name: 'AMISTAD PHONES LIMITED' },
	{ code: '628347', name: 'APPLE - OTHER - CE - CGA NIGERIA' },
	{ code: '1516209', name: 'CALLUS MILLER - HQ' },
	{ code: '1949284', name: 'CASPER GADGETS SOLUTION' },
	{ code: '1644292', name: 'DAX - HQ' },
	{ code: '614322', name: 'DOE RESOURCES' },
	{ code: '1644297', name: 'FINET - OBAFEMI AWOLOWO' },
	{ code: '2266157', name: 'GWAMMAJA PHONES - HQ' },
	{ code: '1644299', name: 'HARMONY' },
	{ code: '612850', name: 'ICONNECT' },
	{ code: '2267760', name: 'INTEGRAL COMMUNICATIONS - HQ' },
	{ code: '759177', name: 'ISTORE HQ' },
	{ code: '1718483', name: 'KOLIA ANGEL' },
	{ code: '1644318', name: 'M&G' },
	{ code: '1974968', name: 'MTN' },
	{ code: '1949292', name: 'ONIGX - HQ' },
	{ code: '1644305', name: 'PLUG DEVICES - HQ' },
	{ code: '1644306', name: 'POINTEK - HQ' },
	{ code: '1949294', name: 'REJIWDO - HQ' },
	{ code: '2267817', name: 'ROYALINE TECH LTD - HQ' },
	{ code: '1029207', name: 'SLOT - HQ' },
	{ code: '1029190', name: 'SPAR - ALEKO' },
	{ code: '1949295', name: 'VEEDHEV - HQ' },
	{ code: '2267838', name: 'WIRELESS 927 - HQ' },
	{ code: '1418050', name: 'AL AFNAN COMPUTERS' },
	{ code: '2267098', name: 'CAPI - MUSCAT DUTY FREE' },
	{ code: '1096746', name: 'EMAX - HQ' },
	{ code: '787973', name: 'EXTRA OMAN - HQ' },
	{ code: '1455954', name: 'GADGETS' },
	{ code: '265024', name: 'LOAY INTERNATIONAL' },
	{ code: '791629', name: 'MISTORE' },
	{ code: '890926', name: 'OTHER IPHONE- OMAN' },
	{ code: '1409331', name: 'VIRGIN OMAN' },
	{ code: '272029', name: 'CORTLAND - POZNAŃ' },
	{ code: '367204', name: 'EURO-NET SP Z O.O.' },
	{ code: '2031984', name: 'HP INC POLSKA SP. Z O. O.' },
	{ code: '792188', name: 'EUROTEL S.A.' },
	{ code: '444778', name: 'IMAD' },
	{ code: '1152922', name: 'INNERGO SYSTEMS ODDZIAŁ WARSZAWA' },
	{ code: '1572200', name: 'INTEGRATED SOLUTIONS SP. Z O.O.' },
	{ code: '1950346', name: 'ISPOT POLAND SP. Z O.O.' },
	{ code: '1100445', name: 'ITERRA SP. Z O.O.' },
	{ code: '234150', name: 'KOMPUTRONIK HQ' },
	{ code: '302841', name: 'MAQSIMUM' },
	{ code: '272045', name: 'MSH PL HQ' },
	{ code: '2005323', name: 'NEONET S.A.' },
	{ code: '272031', name: 'RCS SYSTEMI KOMPUTEROWE 2' },
	{ code: '427421', name: 'RESET-PC' },
	{ code: '303375', name: 'SAD SP. Z O.O.' },
	{ code: '792196', name: 'TERG S.A.' },
	{ code: '1702940', name: 'TRECOM SPÓŁKA AKCYJNA SP.K.' },
	{ code: '662142', name: 'X-KOM' },
	{ code: '265034', name: '51 EAST HQ' },
	{ code: '547533', name: 'CARREFOUR' },
	{ code: '546873', name: 'DUTY FREE QATAR HQ' },
	{ code: '285686', name: 'E-PRO SOLUTIONS - HQ' },
	{ code: '1093870', name: 'EMAX- HQ' },
	{ code: '972417', name: 'GHASHAM' },
	{ code: '651016', name: 'IQ HQ' },
	{ code: '556179', name: 'ISPACE - LAGOONA MALL' },
	{ code: '285688', name: 'ISPOT - HQ' },
	{ code: '554776', name: 'JARIR BOOKSTORE - HQ' },
	{ code: '969140', name: 'LULU - HQ' },
	{ code: '2337257', name: 'MANNAI CORPORATION' },
	{ code: '2380611', name: 'PHONE BOOTH - HQ' },
	{ code: '941071', name: 'QATAR- OTHER RETAILER' },
	{ code: '840923', name: 'SHARAF DG QATAR' },
	{ code: '1189360', name: 'STARLINK' },
	{ code: '265028', name: 'VIRGIN MEGASTORE HQ' },
	{ code: '256230', name: 'APPLE - OTHER - ASBICS' },
	{ code: '1789113', name: 'ASIA CELL RETAIL HQ' },
	{ code: '669733', name: 'ISHOP' },
	{ code: '1686323', name: 'PLANETA GSM HQ' },
	{ code: '265043', name: 'APPLE - OTHER RETAIL - ROME' },
	{ code: '1389985', name: 'CELL CITY' },
	{ code: '1389624', name: 'CORE CONSUMER ELECTRONICS - HQ' },
	{ code: '1390187', name: 'ECONET - HQ' },
	{ code: '360694', name: 'HPC INFORMATIQUE' },
	{ code: '1390090', name: 'ICLIK - HQ' },
	{ code: '1390188', name: 'INCREDIBLE CON. GABORONE AIRPORT' },
	{ code: '1390178', name: 'INCREDIBLE CONNECTION' },
	{ code: '323992', name: 'INCREDIBLE CONNECTION' },
	{ code: '1389625', name: 'ISTORE' },
	{ code: '1389970', name: 'IT-IQ BOTSWANA' },
	{ code: '1389628', name: 'ITI' },
	{ code: '1389964', name: 'ITWORX - HQ' },
	{ code: '274614', name: 'IVEA HQ' },
	{ code: '213548', name: 'IVEA' },
	{ code: '360776', name: 'LANDRY INFORMATIQUE' },
	{ code: '274613', name: 'APPLE - OTHER - CE - MCI' },
	{ code: '675401', name: 'MEDIA NUI - HQ' },
	{ code: '858374', name: 'MEGA BUREAUTIQUE S.A.R.L' },
	{ code: '1390001', name: 'MINERVA - HQ' },
	{ code: '1390194', name: 'MOBILE TELECOMMUNICATIONS LTD' },
	{ code: '1390007', name: 'MOBILITY - HQ' },
	{ code: '1389623', name: 'NANODOG - HQ' },
	{ code: '274603', name: 'NEWTON SYSTEMS - HQ' },
	{ code: '1390008', name: 'PC CENTRE - HQ' },
	{ code: '1390025', name: 'SOLUTION CENTRE - HQ' },
	{ code: '274598', name: 'SYNERGIE - HQ' },
	{ code: '756804', name: 'TINKI STORE - HQ' },
	{ code: '404715', name: 'AGORA - HQ' },
	{ code: '815671', name: 'BUREAU VALLÉE SAINT-ANDRÉ' },
	{ code: '589150', name: 'CARREFOUR HQ' },
	{ code: '815667', name: 'GÉANT TAMPON' },
	{ code: '274602', name: 'ISHOP - HQ' },
	{ code: '274615', name: 'MICROSTOR - HQ' },
	{ code: '715806', name: 'RAVATE ST ANDRE' },
	{ code: '54933', name: 'STOR' },
	{ code: '343038', name: 'ALTEX HQ + ONLINE' },
	{ code: '274498', name: 'APPLE - OTHER - RO' },
	{ code: '981996', name: 'ATEX COMPUTER' },
	{ code: '466705', name: 'COSMOTE ROMANIAN MOBILE TELECOMMUNS' },
	{ code: '1446696', name: 'EMAG HQ + ONLINE' },
	{ code: '1975719', name: 'EMAG HU' },
	{ code: '307667', name: 'EURO GSM' },
	{ code: '546998', name: 'FLANCO HQ + ONLINE' },
	{ code: '858352', name: 'ISPOT PREMIUM ROMANIA SRL' },
	{ code: '274506', name: 'ISTYLE RO HQ' },
	{ code: '982024', name: 'ITRADE' },
	{ code: '343050', name: 'NOUMAX HQ' },
	{ code: '307670', name: 'SAY' },
	{ code: '453210', name: 'VODAFONE ROMANIA, TRANSFER BUCKET' },
	{ code: '456494', name: 'VOX STORE' },
	{ code: '1493584', name: 'LANIT-INTEGRATION LLC' },
	{ code: '1798377', name: '05.RU' },
	{ code: '2377558', name: '05.RU' },
	{ code: '1025473', name: '123.RU' },
	{ code: '1533367', name: '7SOTOK' },
	{ code: '1522924', name: 'A-STORE' },
	{ code: '894455', name: 'A11' },
	{ code: '1576131', name: 'A3 SPORT LLC' },
	{ code: '1279839', name: 'ABIT' },
	{ code: '1482221', name: 'ALFA LLC' },
	{ code: '940104', name: 'AMOS' },
	{ code: '742849', name: 'ANTEN' },
	{ code: '1613337', name: 'ARSENAL PARTNER' },
	{ code: '794939', name: 'ATTO' },
	{ code: '1987992', name: 'AUCHAN' },
	{ code: '1768542', name: 'BERKS' },
	{ code: '2116354', name: 'BLEYD LLC' },
	{ code: '1613336', name: 'CENTER' },
	{ code: '1533363', name: 'CIFROGRAD' },
	{ code: '1133897', name: 'CIFROGRAD VLADIVOSTOK' },
	{ code: '742830', name: 'CIFROVOY MIR' },
	{ code: '1768550', name: 'CITILINK' },
	{ code: '739855', name: 'COMPUTERMARKET' },
	{ code: '2096088', name: 'CSTORE' },
	{ code: '1397643', name: 'DATAPHONE' },
	{ code: '1397649', name: 'DDP-PRO' },
	{ code: '740294', name: 'DEEPARTMENT' },
	{ code: '1988011', name: 'DELICATESSI STEREO' },
	{ code: '1397708', name: 'DELTA COMPUTERS' },
	{ code: '1342547', name: 'DIVIZION' },
	{ code: '1508315', name: 'DIXIS' },
	{ code: '740195', name: 'DIXIS-IT' },
	{ code: '739862', name: 'DNS' },
	{ code: '1261977', name: 'DOMOTECHNIKA' },
	{ code: '743656', name: 'DREAM STORE' },
	{ code: '1671771', name: 'E2E4' },
	{ code: '742878', name: 'ELDORADO' },
	{ code: '1671783', name: 'ELDORADO' },
	{ code: '1475706', name: 'ELDORADO' },
	{ code: '743633', name: 'ELEX' },
	{ code: '2431536', name: 'ENTERNOTE' },
	{ code: '1384859', name: 'HERMES' },
	{ code: '1466975', name: 'IBM' },
	{ code: '740691', name: 'IBOOM' },
	{ code: '743878', name: 'ICENTER KALININGRAD' },
	{ code: '739852', name: 'ICENTRE' },
	{ code: '740188', name: 'ICOVER PRO' },
	{ code: '1671761', name: 'IGOROD' },
	{ code: '2112112', name: 'INVENTIVE DLM' },
	{ code: '890734', name: 'IPLANET' },
	{ code: '739839', name: 'IPORT' },
	{ code: '1482939', name: 'ISHOP' },
	{ code: '743874', name: 'ISHOP MGN' },
	{ code: '739858', name: 'ISTORE' },
	{ code: '737219', name: 'KEY' },
	{ code: '743886', name: 'KOMUS' },
	{ code: '1108631', name: 'M.TIME' },
	{ code: '1261976', name: 'MACAROON' },
	{ code: '1452771', name: 'MAJESTICK' },
	{ code: '1630420', name: 'MAXI TRADE' },
	{ code: '1452759', name: 'MCCENTRE LLC' },
	{ code: '743327', name: 'MEDIA MARKT SATURN' },
	{ code: '789640', name: 'MEGAFON' },
	{ code: '742958', name: 'MEGAPOLIS' },
	{ code: '743968', name: 'METRO' },
	{ code: '1261984', name: 'MOBILE LOGISTIK' },
	{ code: '1133898', name: 'MS.PHONE' },
	{ code: '743030', name: 'M.VIDEO' },
	{ code: '743936', name: 'NIX' },
	{ code: '1577369', name: 'NOTIK' },
	{ code: '740300', name: 'NOTIK' },
	{ code: '2624088', name: 'NOTIK' },
	{ code: '757980', name: 'NOU-HAU' },
	{ code: '1765195', name: 'OFFICEMAG' },
	{ code: '1779312', name: 'OGO!' },
	{ code: '866196', name: 'OLDI' },
	{ code: '1995597', name: 'ONLINE' },
	{ code: '743663', name: 'ONLINE TRADE' },
	{ code: '743953', name: 'OZON' },
	{ code: '1261993', name: 'PERVIY SOTOVIY' },
	{ code: '1765187', name: 'PLUS7' },
	{ code: '1533353', name: 'POSITRONIKA' },
	{ code: '740706', name: 'POWERLINE' },
	{ code: '1983255', name: 'PRIME' },
	{ code: '743885', name: 'REMBYTTECHNIKA' },
	{ code: '744044', name: 'RE:STORE' },
	{ code: '740457', name: 'RU STORE' },
	{ code: '1249905', name: 'SBERBANK-SERVICE' },
	{ code: '740156', name: 'SINTEZ-RUS' },
	{ code: '1671779', name: 'SLAVIA-TECH LLC' },
	{ code: '743902', name: 'SOFTLINE' },
	{ code: '998544', name: 'SP SIDOROV S.V.' },
	{ code: '743949', name: 'SVET COMPUTERS' },
	{ code: '738095', name: 'SVYAZNOY CHAIN' },
	{ code: '743638', name: 'TD POISK' },
	{ code: '743923', name: 'TECHNOCITY' },
	{ code: '743983', name: 'TECHNOMAKS' },
	{ code: '740473', name: 'TECHNOPARK' },
	{ code: '740347', name: 'TECHNOSILA' },
	{ code: '1108679', name: 'TEGRUS' },
	{ code: '1577359', name: 'TEGRUS' },
	{ code: '1342541', name: 'TELE2 RETAIL' },
	{ code: '1512934', name: 'THE ISTORE' },
	{ code: '743972', name: 'THE ISTORE' },
	{ code: '1533358', name: 'TREND TELECOM' },
	{ code: '845751', name: 'TSUM TH' },
	{ code: '1988016', name: 'ULMART' },
	{ code: '743992', name: 'ULMART BOGATYRSKY' },
	{ code: '742839', name: 'UP-STORE' },
	{ code: '1576137', name: 'UP&RUN LLC' },
	{ code: '1108644', name: 'VEKUS' },
	{ code: '1259070', name: 'VESPA' },
	{ code: '1261990', name: 'WILDBERRIES' },
	{ code: '1671787', name: 'X-COM' },
	{ code: '1044442', name: 'XCOM-SHOP' },
	{ code: '858371', name: 'ATLANTIC BUROTIC INF (A.B.I. SUARL)' },
	{ code: '1083781', name: 'MULTIMEDIA STORE - HQ' },
	{ code: '840121', name: 'PICO MEGA - HQ' },
	{ code: '274484', name: 'APPLE - OTHER - RS' },
	{ code: '670311', name: 'AUDIO PLUS' },
	{ code: '1662373', name: 'DUFRY HQ' },
	{ code: '556731', name: 'GIGATRON' },
	{ code: '972454', name: 'INFOCOM' },
	{ code: '345462', name: 'ISTYLE SERBIA HQ' },
	{ code: '285715', name: 'MOLLLA' },
	{ code: '1625405', name: 'NEPTUN KOSOVO HQ' },
	{ code: '274486', name: 'APPLE - OTHER - SERBIA' },
	{ code: '532184', name: 'SIEMENS IT SOLUTIONS AND SERVICES D.O.O.' },
	{ code: '994696', name: 'SISTEM:I' },
	{ code: '544962', name: 'TEHNOMANIJA' },
	{ code: '1613756', name: 'WIN WIN HQ' },
	{ code: '620414', name: 'WIN WIN HQ' },
	{ code: '343126', name: 'МАЦОЛА' },
	{ code: '595043', name: 'ITECH COMPUTER SERVICES PTY. LTD.' },
	{ code: '1112312', name: 'ALZA.SK' },
	{ code: '593878', name: 'APPLE-AFVT-ROC-SLOVAKIA-OTHR' },
	{ code: '1961066', name: 'ELEKTROSPED SLOVAKIA' },
	{ code: '643455', name: 'ISTYLE SLOVAKIA HQ' },
	{ code: '531863', name: 'M:ZONE SPOL. S.R.O' },
	{ code: '343141', name: 'NAY HQ' },
	{ code: '1421873', name: 'SMARTY SK S.R.O.' },
	{ code: '681064', name: 'SWP' },
	{ code: '343146', name: 'T-COM SLOVAKIA' },
	{ code: '340735', name: 'TRACO COMPUTERS' },
	{ code: '343147', name: 'WESTECH SK' },
	{ code: '343151', name: 'ARIS' },
	{ code: '404362', name: 'BIG BANG D.O.O.' },
	{ code: '285728', name: 'EPL' },
	{ code: '404371', name: 'HARVEY NORMAN SLOVENIA' },
	{ code: '343154', name: 'HRUŠKA' },
	{ code: '1755065', name: 'ILINKS HQ' },
	{ code: '343153', name: 'ISTORE' },
	{ code: '1690622', name: 'ISTORE-X HQ' },
	{ code: '680970', name: 'KOBRA' },
	{ code: '457700', name: 'MIMOVRSTE' },
	{ code: '285729', name: 'ISTYLE SLOVENIA HQ' },
	{ code: '531804', name: 'PRO23' },
	{ code: '274501', name: 'APPLE - OTHER - SI' },
	{ code: '274539', name: 'APPLE - OTHER PROSUMER - ZA - CORE' },
	{ code: '274595', name: 'APPLE - OTHER RETAIL - ZA - CORE' },
	{ code: '669505', name: 'CAPI' },
	{ code: '1560742', name: 'CDW SOUTH AFRICA (PTY) LTD' },
	{ code: '324024', name: 'CELLUCITY' },
	{ code: '274532', name: 'CITISOURCE' },
	{ code: '1436938', name: 'CORE PERIPHERALS (PTY) LTD' },
	{ code: '1428731', name: 'DATACENTRIX (PTY) LTD' },
	{ code: '653597', name: 'DG' },
	{ code: '211867', name: 'DIGICAPE' },
	{ code: '605479', name: 'DIGITAL EXPERIENCE' },
	{ code: '323994', name: 'DION WIRED' },
	{ code: '494560', name: 'DIONWIRED - HQ' },
	{ code: '1029543', name: 'DOTFNB' },
	{ code: '1978094', name: 'EDGARS' },
	{ code: '523346', name: 'FIRST TECHNOLOGY NATIONAL (PTY) LTD' },
	{ code: '718494', name: 'FNB' },
	{ code: '274592', name: 'GAME - HQ' },
	{ code: '1478316', name: 'IBM SOUTH AFRICA (PROPRIETARY) LIMITED' },
	{ code: '300323', name: 'ICOMPUTING SOLUTIONS CC' },
	{ code: '274588', name: 'INCREDIBLE CONNECTION - HQ' },
	{ code: '1428732', name: 'IPT HOLDINGS (PTY) LTD' },
	{ code: '274589', name: 'ISER EXPERT' },
	{ code: '225979', name: 'ISTORE' },
	{ code: '1993922', name: 'JD GROUP' },
	{ code: '274590', name: 'KALAHARI.NET A DIVISION OF 24.COM -SA' },
	{ code: '669545', name: 'KLOPPERS' },
	{ code: '558360', name: 'MAKRO - HQ' },
	{ code: '1482897', name: 'MEGAGRAPHIX COMPUTERS' },
	{ code: '324022', name: 'METRO HOME' },
	{ code: '1513935', name: 'MTN ROODEPOORT' },
	{ code: '274593', name: 'MUSICA' },
	{ code: '1651402', name: 'MUSTEK ELECTRONICS LIMITED' },
	{ code: '274537', name: 'NEW WORLD MENLYN' },
	{ code: '1427255', name: 'PRO-NETWORKS' },
	{ code: '274587', name: 'PROTEA FURNISHERS S.A. -SA' },
	{ code: '1427263', name: 'RUNNING COMPUTERS' },
	{ code: '1427262', name: 'SHAW GROUP' },
	{ code: '1427271', name: 'SHOP AND SHIP ONLINE (PTY) LTD' },
	{ code: '1427232', name: 'TAKEALOT ONLINE (PTY) LTD' },
	{ code: '844651', name: 'TELKOM MOBILE' },
	{ code: '544312', name: 'THE DIGITAL EXPERIENCE' },
	{ code: '481281', name: 'THINK AHEAD EDUCATION SOLUTIONS' },
	{ code: '1427217', name: 'UBUNTU TECHNOLOGY (PTY) LTD' },
	{ code: '651597', name: 'ICOM' },
	{ code: '1567025', name: 'TAJSET' },
	{ code: '274604', name: 'ELITE COMPUTERS LIMITED' },
	{ code: '285712', name: 'ISTORE' },
	{ code: '360777', name: 'ISTORE - HQ' },
	{ code: '889136', name: 'TANZANIA BUCKET' },
	{ code: '274617', name: 'CIB - HQ' },
	{ code: '274608', name: 'ISPACE - HQ' },
	{ code: '293841', name: 'ISTORE' },
	{ code: '537647', name: 'ITECH SOUSSE' },
	{ code: '1071377', name: 'ARÇELİK A.Ş' },
	{ code: '801201', name: 'ARTI' },
	{ code: '858342', name: 'ARTI TROY TEKNOLOJİ BİLGİSAYAR SATIŞ A.Ş.' },
	{ code: '801902', name: 'AYNET' },
	{ code: '1349124', name: 'AZTEK BUCKET ACCOUNT' },
	{ code: '1578650', name: 'BARCIN SPOR MALZEMELERI TİC.VE SAN.A.S.' },
	{ code: '801885', name: 'BAYLAN YAPIMCILIK REKLAM BİLİŞİM TİCARET LTD. ŞTİ.' },
	{ code: '801514', name: 'BİMEKS' },
	{ code: '842066', name: 'BİREYSEL BİLGİSAYAR' },
	{ code: '894230', name: 'CARREFOURSA CARREFOUR SABANCI TİC.MERKEZİ A.Ş.' },
	{ code: '1003772', name: 'COMPRO BILISIM TEKNOLOJILERI ANONIM SIRKETI' },
	{ code: '801590', name: 'D&R' },
	{ code: '1550884', name: 'DESTEK BILGISAYAR VE İLETIŞIM HIZMETLERI TICARET A.Ş.' },
	{ code: '801511', name: 'EGEMEK' },
	{ code: '801891', name: 'ESC TEKNOLOJI BILIŞIM TICARET LIMITED ŞIRKETI' },
	{ code: '896808', name: 'EVKUR ALIŞVERİŞ MERKEZLERİ TİC.A.Ş' },
	{ code: '1240779', name: 'GARANTI BILIŞIM TEKNOLOJISI VE TICARET T.A.Ş.' },
	{ code: '801895', name: 'GÜRGENÇLER BILIŞIM İLETIŞIM İÇ VE DIŞ TICARET LTD.ŞTI' },
	{ code: '2388684', name: 'HEPSIBURADA' },
	{ code: '1020618', name: 'İNNOVA BILIŞIM ÇÖZÜMLERI A.Ş.' },
	{ code: '873333', name: 'KOÇ SİSTEM' },
	{ code: '1643620', name: 'KURALKAN BILIŞIM OTOMOTIV SANAYI VE DIŞ TICARET A.Ş.' },
	{ code: '801736', name: 'LYDIA' },
	{ code: '801697', name: 'MEDIA MARKT TURKEY TIC. LTD. ŞTI.' },
	{ code: '801918', name: 'OFİS İLETİŞİM BİLGİSAYAR SAN. TİC.LTD.ŞTİ.' },
	{ code: '801887', name: 'POZITIF TEKNOLOJI TIC. LTD. ŞTI.' },
	{ code: '801733', name: 'PROMC' },
	{ code: '801580', name: 'PUPA BİLİŞİM HİZMETLERİ TİCARET A.Ş' },
	{ code: '818920', name: 'PUPA TECH' },
	{ code: '1551439', name: 'SENTIM BILISIM TEKNOLOJILERI SAN. VE TIC. A.S.' },
	{ code: '801731', name: 'SERVIS BILGISAYAR SISTEMLERI SAN. VE TIC. LTD.ŞTI.' },
	{ code: '1512910', name: 'SETUR SERVIS TURISTIK A.Ş.' },
	{ code: '801206', name: 'TEKNOSA' },
	{ code: '801894', name: 'TVT ELEKTRİK ELEKTRONİK BİLGİSAYAR İNŞ. İHR.TUR.TİC.VE SAN. LTD. ŞTİ.' },
	{ code: '1578654', name: 'UNAL HAZIR GIYIM TKSTIL MB. BY.SYSN.TC.LT.ST' },
	{ code: '801821', name: 'VATAN' },
	{ code: '442964', name: 'TMSTORE' },
	{ code: '1130979', name: 'ABM AAR' },
	{ code: '1741261', name: 'ACHIEVER COMPUTERS LLC' },
	{ code: '1578679', name: 'AL MANA RETAIL LLC' },
	{ code: '1752779', name: 'AL THANAYYAN INTERNATIONAL' },
	{ code: '924535', name: 'ALPHA DATA LLC' },
	{ code: '1388387', name: 'AMT INTERNATIONAL LLC' },
	{ code: '1592554', name: 'ATIKSH TRADING' },
	{ code: '924381', name: 'AXIOM TELECOM L.L.C' },
	{ code: '1381909', name: 'CADENSWORTH UAE LLC' },
	{ code: '924597', name: 'CARREFOUR' },
	{ code: '924538', name: 'CDA' },
	{ code: '1130972', name: 'CDW MIDDLE EAST FZ-LLC' },
	{ code: '1176207', name: 'CTS- RDS' },
	{ code: '1394177', name: 'DALSON TRADING CO LLC' },
	{ code: '1198561', name: 'DUBAI DUTY FREE' },
	{ code: '924351', name: 'E-CITY GENERAL TRADING LLC' },
	{ code: '1190251', name: 'EROS' },
	{ code: '924648', name: 'FAST TELECOM GENERL TRADING LLC' },
	{ code: '924330', name: 'GADGITECH ME DMCC' },
	{ code: '2192637', name: 'GRAPH EAST COMPUTERS LLC' },
	{ code: '1479295', name: 'GULF BUSINESS MACHINES (GBM) LLC' },
	{ code: '924568', name: 'HARMAN HOUSE LLC' },
	{ code: '1364932', name: 'IBM MIDDLE EAST FZ. , LLC' },
	{ code: '924553', name: 'ISTORE' },
	{ code: '924541', name: 'ISTYLE COMPUTERS LLC' },
	{ code: '1513395', name: 'J T R S COMPUTER TRADING & REPAIRING LLC' },
	{ code: '924368', name: 'JACKYS ELECTRONICS L.L.C' },
	{ code: '2049142', name: 'JARIR' },
	{ code: '924628', name: 'JUMBO ELECTRONICS' },
	{ code: '1774435', name: 'EMAX - HQ' },
	{ code: '1208494', name: 'MAX ELECTRONICS LLC' },
	{ code: '924579', name: 'LULU CENTER LLC' },
	{ code: '1734880', name: 'MARS DISTRIBUTION LLC' },
	{ code: '1139077', name: 'MDS - ASE' },
	{ code: '1535318', name: 'MDS - ENTERPRISE' },
	{ code: '924510', name: 'MEDIACAST FZ, L.L.C.' },
	{ code: '924321', name: 'MINDWARE S.A' },
	{ code: '924322', name: 'NEW VISION FZE' },
	{ code: '924558', name: 'PLUG-INS' },
	{ code: '924507', name: 'PRO TECHNOLOGY' },
	{ code: '2120322', name: 'ROMEX GENERAL TRADING L.L.C' },
	{ code: '924297', name: 'SHARAF DG L.L.C' },
	{ code: '1481221', name: 'SUPRA MIDDLE EAST LTD LLC' },
	{ code: '924537', name: 'TECHMART' },
	{ code: '1592096', name: 'UNION COOPERATIVE SOCIETY' },
	{ code: '1479291', name: 'UNITED COMPUTER & MANAGEMENT CONSULTANCY COMPANY LLC' },
	{ code: '924618', name: 'VIRGIN MEGASTORE' },
	{ code: '2192678', name: 'XPEDITOR LINK MOBILE PHONES TRADING LLC' },
	{ code: '274605', name: 'ELITE - HQ' },
	{ code: '1980331', name: 'UGANDA BUCKET' },
	{ code: '595051', name: 'ALLO HQ' },
	{ code: '432901', name: 'AMERICA' },
	{ code: '632293', name: 'BRAIN' },
	{ code: '2823085', name: 'CETRADING HQ' },
	{ code: '720212', name: 'CIFROVIK PRO' },
	{ code: '1296291', name: 'CITRUS HQ' },
	{ code: '413662', name: 'COMFY' },
	{ code: '705782', name: 'CTLINE' },
	{ code: '562773', name: 'ELDORADO HQ' },
	{ code: '546440', name: 'FOXTROT/FOXMART' },
	{ code: '300096', name: 'ILAND' },
	{ code: '515507', name: 'IMAG' },
	{ code: '1726147', name: 'ION HQ' },
	{ code: '337204', name: 'ISMART' },
	{ code: '639865', name: 'ISTUDIO HQ' },
	{ code: '345468', name: 'IVIRA' },
	{ code: '588913', name: 'KOMPYUTERNIY VSESVIT' },
	{ code: '589013', name: 'KOMPYUTERNYI VSESVIT' },
	{ code: '279625', name: 'M-HOUSE HQ' },
	{ code: '423064', name: 'MOYO HQ' },
	{ code: '411995', name: 'MSHOP' },
	{ code: '413664', name: 'MSTORE.COM.UA' },
	{ code: '1082562', name: 'OTHER UKRAINE' },
	{ code: '665856', name: 'RIM 2000' },
	{ code: '413667', name: 'ROZETKA HQ (ONLINE)' },
	{ code: '414448', name: 'APPLE - OTHER - UA' },
	{ code: '656665', name: 'WHITESTORE' },
	{ code: '1856427', name: 'A I C INC.' },
	{ code: '482239', name: 'ACCESS MEDIA' },
	{ code: '481841', name: 'ADVANCE CORPORATION' },
	{ code: '1206180', name: 'AEON RETAIL CO., LTD.' },
	{ code: '532110', name: 'AEON RETAIL CO., LTD.' },
	{ code: '1629045', name: 'AEON RETAIL STORE CO., LTD' },
	{ code: '1018742', name: 'AEON RETAIL CO., LTD.' },
	{ code: '213336', name: 'AEON RETAIL CO., LTD.' },
	{ code: '482228', name: 'ALCOM CORPORATION' },
	{ code: '2406247', name: 'AMAZON.COM SALES, INC.' },
	{ code: '113858', name: 'AMAZON JAPAN G.K.' },
	{ code: '1263596', name: 'ANKYU CO. LTD.' },
	{ code: '590792', name: 'ARROW JAPAN,INC' },
	{ code: '878421', name: 'ATS, INC' },
	{ code: '451385', name: 'BADEN, INC.' },
	{ code: '482238', name: 'BBC CORPORATION' },
	{ code: '1067109', name: 'BEATS_SPECIAL DEAL (418679)' },
	{ code: '243190', name: 'BEISIA DENKI CO.,LTD.' },
	{ code: '451387', name: 'BELL-PARK CO., LTD.' },
	{ code: '390019', name: 'BELL-PARK CO., LTD.' },
	{ code: '454534', name: 'BELL-PARK CO., LTD.' },
	{ code: '33817', name: 'BIC CAMERA INC.' },
	{ code: '693440', name: 'BIC CAMERA INC.' },
	{ code: '1140406', name: 'CULTURE CONVENIENCE CLUB CO.,LTD.' },
	{ code: '1228527', name: 'CULTURE CONVENIENCE CLUB CO.,LTD.' },
	{ code: '471719', name: 'CHUGOKU DENSETSU KOOGYO CO., LTD.' },
	{ code: '469180', name: 'COMIPURA AGENT CO., LTD.' },
	{ code: '469295', name: 'COMNET,INC.' },
	{ code: '451396', name: 'CONEXIO CORPORATION' },
	{ code: '765312', name: 'COSMONET CO., LTD.' },
	{ code: '1949186', name: 'COSMONET CO., LTD.' },
	{ code: '451388', name: 'COSMONET CO., LTD.' },
	{ code: '262546', name: 'COSTCO WHOLESALE JAPAN LTD.' },
	{ code: '471115', name: 'CREAR, LTD.' },
	{ code: '469181', name: 'CWMOBILE CO.,LTD.' },
	{ code: '547773', name: 'D POPS CORPORATION' },
	{ code: '481831', name: 'DA CAPO CORPORATION' },
	{ code: '1263637', name: 'DAIEI DENTSU, INC.' },
	{ code: '481875', name: 'DAIWA TSUSHIN CO., LTD.' },
	{ code: '481825', name: 'DEEP TECH CORPORATION' },
	{ code: '2037272', name: 'DELIGHT UP CO., LTD.' },
	{ code: '451390', name: 'DIAMOND TELECOM, INC.' },
	{ code: '1687289', name: 'DLX CO., LTD.' },
	{ code: '1263638', name: 'DMT INC.' },
	{ code: '1207391', name: 'DOVER STREET MARKET JAPAN CO., LTD' },
	{ code: '1263605', name: 'DUNK CO. LTD.' },
	{ code: '467023', name: 'E.M TELECOM, INC.' },
	{ code: '1210917', name: 'EARTH MOBILE' },
	{ code: '481910', name: 'EARTH NET' },
	{ code: '213093', name: 'EDION CORPORATION' },
	{ code: '467024', name: 'EDION CORPORATION' },
	{ code: '1263606', name: 'EILL CO. LTD.' },
	{ code: '34085', name: 'ELP CO., LTD.' },
	{ code: '469409', name: 'ENZIN CORPORATION' },
	{ code: '1263611', name: 'ERSTER RANG CO. LTD.' },
	{ code: '1263625', name: 'FORTEGROUP CORPORATION CO. LTD.' },
	{ code: '1263636', name: 'FORWARD CO. LTD.' },
	{ code: '469979', name: 'FUJITELECOMS, INC.' },
	{ code: '1525213', name: 'FUJIYA CAMERA K.K.' },
	{ code: '467027', name: 'GLOBAL COMMUNICATIONS CO' },
	{ code: '451393', name: 'GOHMAE, INC.' },
	{ code: '1263555', name: 'HAM CENTER CHITOSE CO. LTD.' },
	{ code: '1263575', name: 'HAM SHOP BE-1 INC.' },
	{ code: '1332594', name: 'HERMÈS JAPON CO., LTD.' },
	{ code: '1263554', name: 'HOKKAIDO TSUSHIN NETWORK CO.' },
	{ code: '1263547', name: 'HOKUSHIN TELEC CO.' },
	{ code: '426125', name: 'IDEA INTERNATIONAL CO., LTD.' },
	{ code: '481905', name: 'IKEDA COMMUNICATION DEVICE' },
	{ code: '1577296', name: 'ILLUME INC.' },
	{ code: '1509512', name: 'INTERNATIONAL TRADE INC.' },
	{ code: '1207380', name: 'ISETAN MITSUKOSHI LTD.' },
	{ code: '451395', name: 'ISLENET, INC.' },
	{ code: '1528687', name: 'ITO MOBILE CO., LTD' },
	{ code: '215283', name: 'ITO-YOKADO CO., LTD.' },
	{ code: '481836', name: 'ITS CORPORATION' },
	{ code: '449972', name: 'ITX CORPORATION' },
	{ code: '1047953', name: 'ITX CORPORATION' },
	{ code: '1509511', name: 'JAPAN TELEMESSAGE INC.' },
	{ code: '1263579', name: 'JATEXNET CO. LTD' },
	{ code: '1744965', name: 'JISHISHI, K.K.' },
	{ code: '33774', name: 'JOSHIN DENKI CO.,LTD.' },
	{ code: '451400', name: 'JOYCOM, INC.' },
	{ code: '33875', name: 'K\'S HOLDINGS CORPORATION' },
	{ code: '1263633', name: 'K&S INC.' },
	{ code: '481842', name: 'KADOYA CORPORATION' },
	{ code: '532132', name: 'KANEMATSU COMMUNICATIONS LIMITED' },
	{ code: '1060281', name: 'KANJITSU DENKI CO., LTD.' },
	{ code: '647244', name: 'KITAMURA CO., LTD.' },
	{ code: '34094', name: 'KITCUT CO., LTD' },
	{ code: '426178', name: 'KITCUT CO., LTD' },
	{ code: '435916', name: 'KITCUT CO., LTD' },
	{ code: '1263595', name: 'KYNES CO. LTD.' },
	{ code: '1509514', name: 'LAOX CO.,LTD.' },
	{ code: '467026', name: 'M DIGI, INC' },
	{ code: '481826', name: 'MAXAM COMMUNICATIONS' },
	{ code: '1263598', name: 'MEDIA JAPAN' },
	{ code: '481919', name: 'MEDIA PLANNING' },
	{ code: '1509515', name: 'MIKI GAKKI CO., LTD.' },
	{ code: '426122', name: 'MISUMI CO., LTD.' },
	{ code: '34086', name: 'MISUMI CO., LTD.' },
	{ code: '1263632', name: 'MKK COMPANY LIMITED' },
	{ code: '237600', name: 'MR MAX CORPORATION' },
	{ code: '482236', name: 'MT NET CORPORATION' },
	{ code: '1541403', name: 'MUSICLAND INC.' },
	{ code: '481916', name: 'MX MOBILING CO., LTD.' },
	{ code: '470263', name: 'MYTELEPHONE CORPORATION' },
	{ code: '1492945', name: 'NANGOKU COMMUNICATIONS CORP' },
	{ code: '1263610', name: 'NEUES CO. LTD.' },
	{ code: '482225', name: 'NEW WAVE CORPORATION' },
	{ code: '1544750', name: 'NIKE JAPAN GROUP, LLC' },
	{ code: '1024423', name: 'NIKKEI BP' },
	{ code: '1260182', name: 'NK INTERNATIONAL CO., LTD' },
	{ code: '34116', name: 'NOJIMA CORPORATION' },
	{ code: '578813', name: 'NOJIMA CORPORATION' },
	{ code: '1744399', name: 'NORTH ENERGY INC.' },
	{ code: '1263578', name: 'NSC CO.' },
	{ code: '1806092', name: 'OFFICE PRIME CO., LTD.' },
	{ code: '1560408', name: 'OTAI.INC' },
	{ code: '1263589', name: 'PAL CO. LTD' },
	{ code: '33947', name: 'PC DEPOT CORPORATION' },
	{ code: '1944752', name: 'PCI INC' },
	{ code: '1266137', name: 'PERSONAL NET CO. LTD.' },
	{ code: '1156085', name: 'PLAZA CREATE CO., LTD.' },
	{ code: '1263604', name: 'PRONETWORKS CO. LTD.' },
	{ code: '451415', name: 'RANET CO., LTD.' },
	{ code: '1856474', name: 'REX CO., LTD.' },
	{ code: '481929', name: 'SANWA FACTORY CO., LTD.' },
	{ code: '1509516', name: 'SCAY' },
	{ code: '481906', name: 'SDMOBILE CO., LTD' },
	{ code: '218548', name: 'SEIYU GK' },
	{ code: '451403', name: 'SEKISHO MOBILE, INC.' },
	{ code: '1964542', name: 'SEVEN-ELEVEN JAPAN CO., LTD.' },
	{ code: '1263591', name: 'SHICHINOHE COMMUNICATION INDUSTRY CO. LTD' },
	{ code: '1509513', name: 'SHIMAMURA MUSIC CORPORATION' },
	{ code: '451405', name: 'SKI, INC.' },
	{ code: '481871', name: 'SKYNET' },
	{ code: '1963998', name: 'SMART TOOL CO., LTD.' },
	{ code: '1438940', name: 'SNAP K.K.' },
	{ code: '451402', name: 'SO MOBILE CO., LTD.' },
	{ code: '482234', name: 'SONODA FUDOUSAN' },
	{ code: '1263612', name: 'SOPHIA CO. LTD.' },
	{ code: '482227', name: 'SUN MOBILE COMMUNICATION' },
	{ code: '481874', name: 'SYSTEM INN YOSHINO' },
	{ code: '1010993', name: 'T-GAIA CORPORATION' },
	{ code: '451407', name: 'T-GAIA CORPORATION' },
	{ code: '1944780', name: 'T-GAIA CORPORATION' },
	{ code: '1614004', name: 'T-RING INC.' },
	{ code: '481834', name: 'TAKATSUDO DENSHI CORPORATION' },
	{ code: '1263580', name: 'TALISMAN LTD.' },
	{ code: '451409', name: 'TDMOBILE CORPORATION' },
	{ code: '1803953', name: 'TELE-NISHI CORPORATION' },
	{ code: '1263609', name: 'TELECOM J CO. LTD' },
	{ code: '451411', name: 'TELECOM SERVICE CO., LTD' },
	{ code: '1398773', name: 'TELEEARTHJAPAN CO., LTD.' },
	{ code: '1263607', name: 'TELEPHONE LEASE CO. LTD.' },
	{ code: '1263592', name: 'TELMEDIA CO. LTD' },
	{ code: '2067194', name: 'TENACIOUS CO.,LTD.' },
	{ code: '1539629', name: 'THE LOFT CO.,LTD.' },
	{ code: '590794', name: 'TI MOBILE CO.LTD.' },
	{ code: '1460493', name: 'TM NETWORK INC.' },
	{ code: '469412', name: 'TO-TSU,INC.' },
	{ code: '471786', name: 'TOHOKU TELEMEDIA KAIHATSU, INC' },
	{ code: '590796', name: 'TOKAI COMMUNICATIONS CORPORATION' },
	{ code: '467019', name: 'TOMOE LINK, CO., LTD.' },
	{ code: '481873', name: 'TOP Y JAPAN' },
	{ code: '481925', name: 'TOSA DENSHI KOGYO' },
	{ code: '451412', name: 'TOSHIN MOBILE CORPORATION' },
	{ code: '1263608', name: 'TOUKAIHANDY CO. LTD.' },
	{ code: '1510388', name: 'TOWER RECORDS JAPAN INC.' },
	{ code: '451413', name: 'TOYOTA GENUINE PARTS & ACCESSORIES, INC.' },
	{ code: '469978', name: 'TRAVEL STATION, INC.' },
	{ code: '1626884', name: 'TRYCOM WORKS' },
	{ code: '1263627', name: 'TSUCHIYACORPORATION CO. LTD.' },
	{ code: '2102409', name: 'TSUSHINTOKKI CO., LTD.' },
	{ code: '1263597', name: 'TWO&TWO CO. LTD.' },
	{ code: '1263635', name: 'U-TECK CO. LTD.' },
	{ code: '467029', name: 'UPBEAT CORPORATION' },
	{ code: '471114', name: 'VALQOM CO.,LTD.' },
	{ code: '1210912', name: 'VILLAGE MOBILE' },
	{ code: '1263631', name: 'WITH CO. LTD' },
	{ code: '33962', name: 'YAMADA-DENKI CO., LTD.' },
	{ code: '1263590', name: 'YAMAZAWA INSURANCE SERVICE CO.,LTD.' },
	{ code: '1263628', name: 'YANADEN CORPORATION' },
	{ code: '33825', name: 'YODOBASHI CAMERA COMPANY, LIMITED' },
	{ code: '1482315', name: 'YOSHIDA & CO., LTD.' },
	{ code: '34074', name: 'YU CO., LTD' },
	{ code: '1263629', name: 'YUMEC COMPANY LIMITED' },
	{ code: '1285968', name: 'AK DISTRIBUTION CO., LTD' },
	{ code: '1765856', name: 'AK DISTRIBUTION CO., LTD' },
	{ code: '1282523', name: 'ALL IN ONE TECH' },
	{ code: '1293281', name: 'ANTZ. CO., LTD' },
	{ code: '236894', name: 'ASHOP CO.,LTD' },
	{ code: '287787', name: 'BEYONDTECH, INC.' },
	{ code: '273803', name: 'BEYONDTECH, INC.' },
	{ code: '1592561', name: 'BLUE BOX CO., LTD' },
	{ code: '1593208', name: 'CJ DIGITAL MUSIC CORPORATION' },
	{ code: '1593215', name: 'CJ DIGITAL MUSIC CORPORATION' },
	{ code: '1262406', name: 'CJ E&M CORP.' },
	{ code: '1060804', name: 'CJ E&M CORP.' },
	{ code: '221220', name: 'COSTCO WHOLESALE KOREA CO. ,LTD' },
	{ code: '2044301', name: 'COUPANG CORP.' },
	{ code: '236887', name: 'DAIHWA COMPUTER INC.' },
	{ code: '92914', name: 'DAIHWA COMPUTER INC.' },
	{ code: '681036', name: 'E-MART INC.' },
	{ code: '343495', name: 'GALA INTERNATIONAL CO.,LTD' },
	{ code: '1332720', name: 'HERMES KOREA' },
	{ code: '236892', name: 'JONGROMAC SYSTEM CO.,LTD' },
	{ code: '1282517', name: 'KOOKJAE CORPORATION' },
	{ code: '2137000', name: 'AKD_KT_T2 BEATS(845611)' },
	{ code: '865347', name: 'LOTTE HIMART CO.,LTD' },
	{ code: '342884', name: 'NEPTUNEKOREA LTD.' },
	{ code: '1557613', name: 'NIKE KOREA LLC' },
	{ code: '454941', name: 'PEACH VALLEY CORP.' },
	{ code: '525255', name: 'PEACH VALLEY CORP.' },
	{ code: '544621', name: 'SHINSEGAE INC.' },
	{ code: '1247997', name: 'SUNKYUNG DFS,. LTD' },
	{ code: '242584', name: 'UBASE INC.' },
	{ code: '818897', name: 'VALIANT CO., LTD.' },
	{ code: '448485', name: 'WINIAAID CO.,LTD' },
	{ code: '545017', name: 'INGRAM MICRO INDIA PRIVATE LIMITED SINGAPORE BRANCH' },
	{ code: '262079', name: 'REDINGTON DISTRIBUTION PTE LTD' },
	{ code: '106031', name: 'SPECTRA INNOVATIONS PTE LTD' },
	{ code: '405264', name: 'UNAUTHORIZED RSL - INDIA' },
	{ code: '268230', name: 'ALOHAISHOPPE' },
	{ code: '1110594', name: 'BANGLALINK DIGITAL COMMUNICATIONS LTD.' },
	{ code: '1034727', name: 'COMPUSTAR PVT LTD' },
	{ code: '342514', name: 'COMPUTER SOURCE LTD' },
	{ code: '356365', name: 'COMPUTER SOURCE MACHINES LTD' },
	{ code: '349916', name: 'EXECUTIVE IT LIMITED' },
	{ code: '1555278', name: 'EXECUTIVE MACHINES LTD.' },
	{ code: '1971975', name: 'GADGET & GEAR' },
	{ code: '280701', name: 'NON CONTRACTING: COMPANY - BANGLADESH' },
	{ code: '227938', name: 'SATCOM COMPUTERS LIMITED' },
	{ code: '947875', name: 'THAKRAL INFORMATION SYSTEMS PRIVATE LIMITED' },
	{ code: '1049444', name: 'TRUST SOLUTIONS PRIVATE LIMITED' },
	{ code: '1279601', name: 'AV ELECTRONICS SDN BHD' },
	{ code: '2350021', name: 'INTEGRATED COMMUNICATIONS SDN BHD' },
	{ code: '1707275', name: 'NON CONTRACTING: COMPANY - BRUNEI' },
	{ code: '257224', name: 'AFZ CO., LTD' },
	{ code: '1723991', name: 'BAYON' },
	{ code: '1994886', name: 'CACTUS REPAIR CO., LTD' },
	{ code: '1663313', name: 'FALCON TECH CO., LTD.' },
	{ code: '266913', name: 'GDV REPORTING' },
	{ code: '286309', name: 'GLOBAL STRATEGIC HOLDINGS DISTRIBUTION (CAMBODIA) PTE. LTD.' },
	{ code: '1723999', name: 'HAKSE' },
	{ code: '1030726', name: 'INGRAM MICRO ASIA LTD' },
	{ code: '1724003', name: 'KOUNG Y' },
	{ code: '1691254', name: 'MWG (CAMBODIA) CO., LTD.' },
	{ code: '846909', name: 'NEEKA LTD' },
	{ code: '1621754', name: 'NIKA PHONE SHOP' },
	{ code: '1621749', name: 'NKR TRADING CO., LTD.' },
	{ code: '1707257', name: 'NON CONTRACTING: COMPANY - CAMBODIA' },
	{ code: '345358', name: 'PTC COMPUTER CO., LTD' },
	{ code: '1724004', name: 'SEANG LIN PHONESHOP' },
	{ code: '1724009', name: 'SOK LY' },
	{ code: '1808528', name: 'SOUTH EAST ASIA TELECOM (CAMBODIA) CO., LTD.' },
	{ code: '1621758', name: 'TECHXPERT LIMITED.' },
	{ code: '1695538', name: 'THAKRAL ONE COMPANY LIMITED' },
	{ code: '284815', name: 'UNI YOUNG TECHNOLOGIES' },
	{ code: '2067940', name: 'AZTEQ PTE. LTD.' },
	{ code: '1678560', name: 'HYBRID FOR AID 1084317' },
	{ code: '785598', name: 'LOTUS TECHNOLOGY COMPANY LIMITED' },
	{ code: '237641', name: 'SERIAL I-TECH (FAR EAST) PTE. LTD.' },
	{ code: '1132064', name: 'T M W ENTERPRISE LIMITED' },
	{ code: '575697', name: 'AAAT TESTING - SERVICE TIER 2' },
	{ code: '1066131', name: 'ASTRO INTERNATIONAL, PT' },
	{ code: '410657', name: 'BHINNEKA MENTARIDIMENSI, PT' },
	{ code: '485551', name: 'BINA USAHA MANDIRI SEJAHTERA, PT' },
	{ code: '822814', name: 'BRIGHTSTAR CORP. - TRAD DEALER' },
	{ code: '822810', name: 'BRIGHTSTAR CORP. - ORG DEALER' },
	{ code: '818247', name: 'BRIGHTSTAR TRIKOMSEL PTE. LTD.' },
	{ code: '1451502', name: 'CALOSA GLOBAL INDONESIA CV.' },
	{ code: '2064263', name: 'CELLULAR WORLD, CV.' },
	{ code: '282788', name: 'CENTRIS' },
	{ code: '251250', name: 'CIPTA DATINDO, PT' },
	{ code: '682049', name: 'DATA CITRA MANDIRI, PT' },
	{ code: '1000643', name: 'ECS INDO JAYA, PT' },
	{ code: '807463', name: 'ECS INDO JAYA, PT' },
	{ code: '1000651', name: 'EMAX FORTUNE INTERNATIONAL, PT' },
	{ code: '671353', name: 'PT ERAJAYA SWASEMBADA TBK - TRAD DEALER' },
	{ code: '671351', name: 'PT ERAJAYA SWASEMBADA TBK - ORG DEALER' },
	{ code: '1000656', name: 'ERAJAYA SWASEMBADA,PT,TBK' },
	{ code: '828111', name: 'SINAR EKA SELARAS, PT' },
	{ code: '668080', name: 'PT ERAJAYA SWASEMBADA TBK' },
	{ code: '793102', name: 'ERAJAYA SWASEMBADA,PT,TBK' },
	{ code: '1000661', name: 'ESOLUSINDO KENCANA, PT' },
	{ code: '503210', name: 'GLOBAL TELESHOP, PT.TBK' },
	{ code: '331628', name: 'I-PLUG' },
	{ code: '426209', name: 'PT. INETINDO INFOCOM' },
	{ code: '1739905', name: 'LAZADA' },
	{ code: '2118297', name: 'PT MAP ZONA ADIPERKASA' },
	{ code: '2121357', name: 'PT MAPPLE MITRA ADIPERKASA' },
	{ code: '2308686', name: 'PT MITRA ADIPERKASA, TBK' },
	{ code: '2288180', name: 'PT MAPPLE MITRA ADIPERKASA' },
	{ code: '2301157', name: 'PT MAPPLE MITRA ADIPERKASA' },
	{ code: '451340', name: 'MDIST AID 836155' },
	{ code: '244028', name: 'NON CONTRACTING: COMPANY - INDONESIA' },
	{ code: '1620203', name: 'PT ERAJAYA SWASEMBADA TBK - TELESINDO' },
	{ code: '345236', name: 'PT TRIKOMSEL OKE TBK' },
	{ code: '1000663', name: 'PT. BANGUN PERSADA TATA MAKMUR' },
	{ code: '1324909', name: 'PT. ERAFONE ARTHA RETAILINDO' },
	{ code: '1516189', name: 'PT. PLAY RETAIL INDONESIA' },
	{ code: '2060887', name: 'PT. SANSICO UTAMA' },
	{ code: '1597427', name: 'SENTRALWATCH PERKASA INTERNASIONAL, PT' },
	{ code: '1000667', name: 'SISTECH KHARISMA ,PT' },
	{ code: '1753124', name: 'PT. SYNNEX METRODATA INDONESIA' },
	{ code: '290811', name: 'TOKO MEGA ELEKTRONIK' },
	{ code: '1000671', name: 'TRIO DISTRIBUSI, PT' },
	{ code: '819016', name: 'ULTIMA SEJAHTERA ABADI, PT' },
	{ code: '1486735', name: 'URBAN RITEL INTERNASIONAL, PT' },
	{ code: '1131573', name: 'L THONG HUOT TELECOM CO., LTD.' },
	{ code: '1707267', name: 'NON CONTRACTING: COMPANY - LAOS' },
	{ code: '1032932', name: 'ACCESS MOBILE MARKETING (M) SDN. BHD.' },
	{ code: '1228075', name: 'ACTION RETAILS MANAGEMENT SDN. BHD.' },
	{ code: '1227220', name: 'ADVANCE CELL STORE SDN. BHD.' },
	{ code: '1228540', name: 'AEROPHONE SHOP' },
	{ code: '1229314', name: 'ALL GADZET SOLUTION SDN. BHD.' },
	{ code: '382533', name: 'ALL IT HYPERMARKET SDN. BHD.' },
	{ code: '1078745', name: 'ALLIED MARKETING & TRADING COMPANY' },
	{ code: '1111489', name: 'ALPHACOM' },
	{ code: '1047878', name: 'AMG WORLD ENTERPRISE' },
	{ code: '1227227', name: 'AMSHORE MARKETING' },
	{ code: '1229320', name: 'ASCEND TIMECEL' },
	{ code: '242847', name: 'ASCENTOUCH RESOURCES SDN. BHD.' },
	{ code: '1052351', name: 'ASHITA COMMUNICATION SDN. BHD.' },
	{ code: '1052323', name: 'ASIA MOBILE SUPPLIES SDN. BHD.' },
	{ code: '1050228', name: 'ATLANTIS COMMUNICATION SALES & SERVICES' },
	{ code: '1227270', name: 'B AND S TRADING' },
	{ code: '1228552', name: 'BA HANSON MARKETING' },
	{ code: '1052317', name: 'BBS DISTRIBUTIONS SDN. BHD.' },
	{ code: '707966', name: 'BERJAYA RADIOSHACK SDN. BHD.' },
	{ code: '293388', name: 'BEST DENKI MALAYSIA SDN. BHD.' },
	{ code: '1050223', name: 'BG PHONEMART SDN. BHD.' },
	{ code: '1475017', name: 'BMSTAR TECHNOLOGY SDN. BHD.' },
	{ code: '548018', name: 'BOULEVARD IT SUPERSTORE SDN. BHD.' },
	{ code: '735906', name: 'BOULEVARD SOLUTION SDN. BHD.' },
	{ code: '1331199', name: 'BP EXCELCITY SDN. BHD.' },
	{ code: '1029499', name: 'BRIGHTSTAR DISTRIBUTION SDN. BHD.' },
	{ code: '441542', name: 'C-ZONE SDN. BHD.' },
	{ code: '465236', name: 'CABLECOM SDN. BHD.' },
	{ code: '114247', name: 'CG COMPUTERS SDN. BHD.' },
	{ code: '1047894', name: 'CHARLIE CELLULAR MARKETING SDN. BHD.' },
	{ code: '1223131', name: 'CHEAN & YONG SDN. BHD.' },
	{ code: '805253', name: 'CHOP HOCK LEONG SDN. BHD.' },
	{ code: '1223885', name: 'CITY MOBILE COMMUNICATIONS SOLUTIONS' },
	{ code: '484757', name: 'COURTS (MALAYSIA) SDN. BHD.' },
	{ code: '1229335', name: 'DAILY GAIN TELESHOP SDN. BHD.' },
	{ code: '244751', name: 'DANCOM TT&L TELECOMMUNICATIONS (M) SDN. BHD.' },
	{ code: '1121965', name: 'DE BAND' },
	{ code: '292591', name: 'DIGITAL FORTRESS SDN BHD' },
	{ code: '1224644', name: 'DIGITAL MOBILE NETWORK SDN. BHD.' },
	{ code: '1233426', name: 'DIRECT MOBILE' },
	{ code: '1227277', name: 'DIRECTD RETAIL & WHOLESALE SDN. BHD.' },
	{ code: '1047926', name: 'EAGLECOM TECHNOLOGY SDN. BHD.' },
	{ code: '1224665', name: 'EAST COAST TELE CENTRE' },
	{ code: '1337443', name: 'EASTERN RECORDS SDN. BHD.' },
	{ code: '1227292', name: 'EASY COMMUNICATION' },
	{ code: '1227310', name: 'EASYCOM TELECOMMUNICATIONS' },
	{ code: '91710', name: 'VSTECS ASTAR SDN. BHD.' },
	{ code: '289944', name: 'ELITETRAX MARKETING SDN. BHD.' },
	{ code: '2151438', name: 'ENVICO TRAVEL RETAIL SDN. BHD.' },
	{ code: '273928', name: 'EPICENTRE LIFESTYLE SDN. BHD.' },
	{ code: '1227323', name: 'ES MARKETING' },
	{ code: '1339255', name: 'ETHER TECHNOLOGY SDN. BHD.' },
	{ code: '2255697', name: 'EWT TECHNOLOGY SDN. BHD.' },
	{ code: '1058003', name: 'EXCLUSIVE TELECOMMUNICATION SDN. BHD.' },
	{ code: '1229278', name: 'EXTREME MOBILE CENTRE' },
	{ code: '1047895', name: 'EXTROVEST COMUNICATIONS SDN. BHD.' },
	{ code: '1227353', name: 'F & G ORIGINAL MARKETING SDN. BHD.' },
	{ code: '1223100', name: 'FATT SOON ENTERPRISE' },
	{ code: '1232932', name: 'FIRSTCO. MOBILE (M) SDN. BHD.' },
	{ code: '1229297', name: 'FIRSTOUCH TELECOMMUNICATION' },
	{ code: '1229343', name: 'FM RETAIL MANAGEMENT SDN. BHD.' },
	{ code: '885405', name: 'FOTOCHARLIE SDN. BHD.' },
	{ code: '1032934', name: 'FUTURE MOBILE SALES & SERVICE' },
	{ code: '1227311', name: 'FUTURE MOBILE WORLD' },
	{ code: '777389', name: 'MY FUTUREWORLD SDN. BHD.' },
	{ code: '1047937', name: 'GALAXY COMM SDN. BHD.' },
	{ code: '1223150', name: 'GAN\'S LOCK-SAFE SERVICES & TRADING' },
	{ code: '1339267', name: 'GENESIS' },
	{ code: '1050837', name: 'GTL ELECTRONICS SDN. BHD.' },
	{ code: '1229364', name: 'H & T HIGH TECH MOBILE STATION' },
	{ code: '1342239', name: 'HEADPHONIC WAVE' },
	{ code: '1047967', name: 'HI-TECH COMMUNICATIONS' },
	{ code: '1229366', name: 'HILL TOP TRADING' },
	{ code: '1464093', name: 'HLK SUPERSTORE SDN. BHD.' },
	{ code: '1061325', name: 'HWEE SENG ELECTRONICS SDN. BHD.' },
	{ code: '1678570', name: 'HYBRID FOR AID 1084317' },
	{ code: '1052333', name: 'I GADGETS' },
	{ code: '1227826', name: 'ICENTER MOBILE' },
	{ code: '1227868', name: 'IG MOBILE CENTRE' },
	{ code: '1227832', name: 'INFOTECH TRADING' },
	{ code: '721010', name: 'IT HYPERSTORE (M) SDN. BHD.' },
	{ code: '274342', name: 'IT ONE SUPERSTORE SDN BHD' },
	{ code: '1339285', name: 'J-COM ENTERPRISE' },
	{ code: '1205647', name: 'JAYACOM INFORMATION SDN. BHD.' },
	{ code: '1227366', name: 'JIT SING TELESHOP' },
	{ code: '1232138', name: 'K TOP COMMUNICATION' },
	{ code: '1227839', name: 'KASH-CONNEXIONS SDN. BHD.' },
	{ code: '755936', name: 'KHIN GUAN CORPORATION SDN. BHD.' },
	{ code: '324007', name: 'KIN YIN TRADING SDN. BHD.' },
	{ code: '1047954', name: 'L & T MOBILE CARE' },
	{ code: '1227848', name: 'LABUAN PHONE SHOP SDN. BHD.' },
	{ code: '1227865', name: 'LANGKAWI IT CITY' },
	{ code: '1739245', name: 'LAZADA' },
	{ code: '1230769', name: 'LEDDER ENTERPRISE (LABUAN) SDN. BHD.' },
	{ code: '1227386', name: 'LEE WAI TRADING' },
	{ code: '1341283', name: 'LINKWAY SHOP' },
	{ code: '1230786', name: 'LIONMAS FURNISHERS (M) SDN. BHD.' },
	{ code: '1331206', name: 'LT PHONE CENTRE' },
	{ code: '1506943', name: 'LULU GROUP RETAIL SDN. BHD.' },
	{ code: '1227193', name: 'M TEL MOBILE & SERVICES' },
	{ code: '242282', name: 'MAC BORNEO' },
	{ code: '239714', name: 'MAC CITY SDN. BHD.' },
	{ code: '864923', name: 'MAC STUDIO SDN. BHD.' },
	{ code: '251248', name: 'MACHINES SDN. BHD.' },
	{ code: '1230756', name: 'MARS MOBILE DISTRIBUTION SDN. BHD.' },
	{ code: '1050772', name: 'MAX MOBILE COMMUNICATION' },
	{ code: '321881', name: 'MC.COM' },
	{ code: '1227346', name: 'METOO CONNECT SDN. BHD.' },
	{ code: '1047961', name: 'METRO COMM MARKETING ENTERPRISE' },
	{ code: '1341285', name: 'METRO ELECTRONIC SUPPLIES' },
	{ code: '1227213', name: 'MOBILE 9 COMMUNICATION' },
	{ code: '1227347', name: 'MOBILE CHOICE COMMUNICATIONS' },
	{ code: '1230748', name: 'MOBILE CITY GROUP SDN. BHD.' },
	{ code: '1052331', name: 'MODERN-TELE SDN. BHD.' },
	{ code: '1052313', name: 'MPS TELECOMMUNICATION SDN. BHD.' },
	{ code: '1227219', name: 'MS GADGETS SDN. BHD.' },
	{ code: '1227442', name: 'MS MOBILE ENTERPRISE' },
	{ code: '221244', name: 'MTCS GROUP SDN. BHD.' },
	{ code: '1227291', name: 'MULTI TECH SALES & SERVICES' },
	{ code: '1047968', name: 'MYCOM CELLULAR' },
	{ code: '1227226', name: 'NAKANO NORTHERN TRADING' },
	{ code: '719749', name: 'NAVOTECH TECHNOLOGY SDN. BHD.' },
	{ code: '728179', name: 'NCS SALES & SERVICES SDN. BHD.' },
	{ code: '1047973', name: 'NETZCOM (M) SDN. BHD.' },
	{ code: '1223124', name: 'NEXT TELE COMMUNICATION' },
	{ code: '827677', name: 'NEXXONE TECHNOLOGIES SDN. BHD.' },
	{ code: '695923', name: 'NINTH STORE (M) SDN. BHD.' },
	{ code: '1055039', name: 'NLM ENTERPRISE' },
	{ code: '241133', name: 'NON CONTRACTING: COMPANY - MALAYSIA' },
	{ code: '1218947', name: 'NSS AUTOMATION TRADING SDN. BHD.' },
	{ code: '1228043', name: 'NUSA TELECOMMUNICATION' },
	{ code: '1227924', name: 'NZ TELECOMUNICATION' },
	{ code: '1232681', name: 'OK MOBILE SDN. BHD.' },
	{ code: '1228478', name: 'ONE TOUCH MOBILE SDN. BHD.' },
	{ code: '283602', name: 'OPTIPRIMUS SOLUTIONS & TECHNOLOGIES SDN BHD' },
	{ code: '1475022', name: 'ORIGINAL ACCESSORIES (M) SDN. BHD.' },
	{ code: '1227264', name: 'OSCAR COLOUR LAB & TELECOMMUNICATION SDN. BHD.' },
	{ code: '1210952', name: 'OXIMATE SDN. BHD.' },
	{ code: '715742', name: 'P C WORLD SDN. BHD.' },
	{ code: '703385', name: 'P.C. IMAGE SDN. BHD.' },
	{ code: '251362', name: 'PERFECTMAC COMPANY' },
	{ code: '686044', name: 'PHENOMENAL ICONS SDN. BHD.' },
	{ code: '1227448', name: 'PHONE HUT SDN. BHD.' },
	{ code: '1227937', name: 'PHONE SOLUTION TRADING' },
	{ code: '1047985', name: 'PHONE STATION TELECOMMUNICATION' },
	{ code: '1227454', name: 'PHONE TALK STATION SDN. BHD.' },
	{ code: '1227276', name: 'PHONE TOUCH COMMUNICATION SALES & SERVICES' },
	{ code: '693493', name: 'PICOTEC SDN. BHD.' },
	{ code: '827289', name: 'PKU TECHNOLOGY SDN. BHD.' },
	{ code: '1047986', name: 'PLANTRONICS TELELINK SDN. BHD.' },
	{ code: '1228593', name: 'POLYCALL SDN. BHD.' },
	{ code: '1054421', name: 'PRIORITY MOBILE ENTERPRISE' },
	{ code: '1341298', name: 'REKOD JAYA (WISMA MERDEKA)' },
	{ code: '1227987', name: 'RING KU MOBILE' },
	{ code: '1232760', name: 'SATU GADGET DOT COM SDN. BHD.' },
	{ code: '1227994', name: 'SENG HUAT ELECTRICAL & HOME APPLIANCES SDN. BHD.' },
	{ code: '257194', name: 'SENHENG ELECTRIC (KL) SDN. BHD.' },
	{ code: '93201', name: 'SERVEX (MALAYSIA) SDN. BHD.' },
	{ code: '1227998', name: 'SEVEN MOBILE TRADING' },
	{ code: '1228081', name: 'SHINING TELECOMMUNICATION SDN. BHD.' },
	{ code: '274560', name: 'SIBU IT ENTERPRISE SDN. BHD.' },
	{ code: '1219468', name: 'SIMS MOBILE & EQUIPMENT' },
	{ code: '1055045', name: 'SIN PING JIA MOBILE SDN. BHD.' },
	{ code: '1228685', name: 'SMART CELLULAR PHONE' },
	{ code: '1228695', name: 'SMART COMMUNICATION NETWORK' },
	{ code: '366726', name: 'SNS NETWORK (M) SDN. BHD.' },
	{ code: '1676121', name: 'SSL TELECENTRE SDN. BHD.' },
	{ code: '1219489', name: 'STAR CONNECT COMMUNICATION' },
	{ code: '1055038', name: 'STARFONE COMMUNICATIONS' },
	{ code: '1223068', name: 'STYLISH MOBILE SHOP' },
	{ code: '1229303', name: 'SUCCESS MOBILE CARE' },
	{ code: '1050287', name: 'T Z MOBILE SDN. BHD.' },
	{ code: '1239388', name: 'T-PHONE SDN. BHD.' },
	{ code: '1229336', name: 'TAC MOBILE TELESHOP' },
	{ code: '1229342', name: 'TALK POINT NETWORK SDN. BHD.' },
	{ code: '1226835', name: 'TEO GEE WEE ENTERPRISE' },
	{ code: '1220035', name: 'THE ONE MOBILE SDN. BHD.' },
	{ code: '1226842', name: 'THREE COM DIGITAL SDN. BHD.' },
	{ code: '321883', name: 'THUNDER MATCH TECHNOLOGY SDN. BHD.' },
	{ code: '1050232', name: 'TOIYO TELESHOP' },
	{ code: '1232224', name: 'TOP LINE TELESHOP' },
	{ code: '1055351', name: 'TWG ENTERPRISE' },
	{ code: '1228087', name: 'UI MOBILE SDN. BHD.' },
	{ code: '687177', name: 'ULTIMATE DEVICES SDN. BHD.' },
	{ code: '1220222', name: 'UNG\'S TELECOMMUNICATION SDN. BHD.' },
	{ code: '759911', name: 'VAST IT SDN. BHD.' },
	{ code: '997832', name: 'VIEWNET COMPUTER SYSTEM SDN. BHD.' },
	{ code: '1229351', name: 'VISION TELESTATION' },
	{ code: '1220264', name: 'VIVID TELECOMMUNICATION SDN. BHD.' },
	{ code: '1049244', name: 'VK MOBILE SDN. BHD.' },
	{ code: '1229365', name: 'WEE PIN TRADING' },
	{ code: '1220270', name: 'XIAN FENG MOBILE ENTERPRISE' },
	{ code: '858976', name: 'YES SUPPORT SERVICES SDN. BHD.' },
	{ code: '1227424', name: 'YI COM MOBILE' },
	{ code: '1220277', name: 'YI FATT TELECOMMUNICATION SDN. BHD.' },
	{ code: '1229372', name: 'YS DIGITAL CENTRE' },
	{ code: '395216', name: 'ZODIAC TRADE SDN. BHD.' },
	{ code: '236813', name: 'FOCUS COMPUTERS PTE. LTD.' },
	{ code: '1707272', name: 'NON CONTRACTING: COMPANY - MALDIVES' },
	{ code: '236817', name: 'REEFSIDE COMPANY PVT LTD' },
	{ code: '1241553', name: 'SOFT TOUCH MALDIVES PRIVATE LIMITED' },
	{ code: '458019', name: 'BODI-ELECTRONICS LLC' },
	{ code: '898010', name: 'BSB SERVICE LIMITED LIABILITY COMPANY' },
	{ code: '948255', name: 'NOMIN TRADING LIMITED LIABILITY COMPANY' },
	{ code: '1707268', name: 'NON CONTRACTING: COMPANY - MONGOLIA' },
	{ code: '2113758', name: 'SAIN-INTERNATIONAL LIMITED LIABILITY COMPANY' },
	{ code: '689330', name: 'SUMMIT COMPUTER TECHNOLOGY' },
	{ code: '2164360', name: 'UNITEL LIMITED LIABILITY COMPANY' },
	{ code: '852656', name: 'MAGELLAN GROUP COMPANY LIMITED' },
	{ code: '1688568', name: 'N A K Z GROUP COMPANY LIMITED' },
	{ code: '1707262', name: 'NON CONTRACTING: COMPANY - MYANMAR' },
	{ code: '849826', name: 'ONE FUTUREWORLD MYANMAR LIMITED' },
	{ code: '1544582', name: 'WAI YAN LWIN COMPANY LIMITED' },
	{ code: '363814', name: 'ASTRAL COMPUTERS NEPAL PVT. LTD.' },
	{ code: '771407', name: 'B.T. PLANET PRIVATE LIMITED' },
	{ code: '342946', name: 'EVOLUTION TRADING PVT. LTD.' },
	{ code: '711470', name: 'GENERATION NEXT COMMUNICATION PRIVATE LIMITED' },
	{ code: '1093298', name: 'NON CONTRACTING: COMPANY - NEPAL' },
	{ code: '209917', name: 'ABDUL REHMAN TAHIR DBA PAK MAC' },
	{ code: '1084329', name: 'ADVANCE TELECOM' },
	{ code: '673071', name: 'AYOOB EUSOOF DBA PAKISTAN OFFICE PRODUCTS' },
	{ code: '690416', name: 'COMPSI (PRIVATE) LIMITED' },
	{ code: '240030', name: 'CONCEPT COMPUTER PROFESSIONALS' },
	{ code: '690404', name: 'EHTESHAM AHMED DBA COMPUTER POINT M/S' },
	{ code: '792152', name: 'FUTURE TECH' },
	{ code: '844613', name: 'HASEEB AHMED DBA H.B. COMPUTERS' },
	{ code: '1036099', name: 'I2 PAKISTAN (PRIVATE) LIMITED' },
	{ code: '280449', name: 'IFTIKHAR AHMED DBA I TECH' },
	{ code: '1534287', name: 'INOVI TECHNOLOGIES' },
	{ code: '291144', name: 'IRAFFLES' },
	{ code: '1659738', name: 'MULLER & PHIPPS (PAKISTAN) (PRIVATE) LIMITED' },
	{ code: '362894', name: 'NON CONTRACTING: COMPANY - PAKISTAN' },
	{ code: '240028', name: 'RAFFLES PRIVATE LIMITED' },
	{ code: '1857253', name: 'TECH SIRAT (PVT.) LIMITED' },
	{ code: '1014873', name: 'TELENOR PAKISTAN (PRIVATE) LIMITED' },
	{ code: '1014869', name: 'UFONE' },
	{ code: '1121112', name: 'WARID TELECOM (PRIVATE) LIMITED' },
	{ code: '817387', name: 'ZULFIQAR ALI DBA ION AUDIO VISUAL' },
	{ code: '1050745', name: '8TELCOM,INC.' },
	{ code: '238100', name: 'ELECTROWORLD, INC.' },
	{ code: '686850', name: 'ABENSON VENTURES, INC.' },
	{ code: '227916', name: 'ACCENT MICRO PRODUCTS, INC.' },
	{ code: '1711522', name: 'ACCENTHUB INC.' },
	{ code: '1711527', name: 'ACCENTPLUS INC.' },
	{ code: '1049852', name: 'AEROPHONE ENTERPRISES & CO.' },
	{ code: '1050182', name: 'AMBASSADOR HOME AND ELECTRONICS CENTRE, INC.' },
	{ code: '293729', name: 'ANSON EMPORIUM CORPORATION' },
	{ code: '300316', name: 'AUTOMATIC APPLIANCES, INC.' },
	{ code: '114375', name: 'AXIS DISTRIBUTION INC' },
	{ code: '414575', name: 'BANANA TELECOMMUNICATIONS, INC.' },
	{ code: '1734515', name: 'BCG ENTERPRISES' },
	{ code: '1070490', name: 'BOHOL QUALITY MEGABYTE INCORPORATED' },
	{ code: '237636', name: 'BOXTALKS INC.' },
	{ code: '1050193', name: 'BSD INTERNATIONAL MOBILE INC.' },
	{ code: '545853', name: 'CHIAMBROS, INC.' },
	{ code: '745596', name: 'COLUMBIA COMPUTER CENTER (DAVAO), INC.' },
	{ code: '753346', name: 'CSI WAREHOUSE CLUB, INC.' },
	{ code: '720971', name: 'DIGITAL INTERFACE, INC.' },
	{ code: '690383', name: 'EMCOR, INCORPORATED' },
	{ code: '745803', name: 'ENIGMA TECHNOLOGIES, INC.' },
	{ code: '408235', name: 'ETC VIRTUAL TRADING' },
	{ code: '2617608', name: 'FONE STYLE ACCESSORIES INC.' },
	{ code: '1050209', name: 'FONERANGE COMMUNICATION, INC.' },
	{ code: '695249', name: 'FORTRESS' },
	{ code: '406752', name: 'GADGETS IN STYLE TRADING CORPORATION' },
	{ code: '1655389', name: 'GADGETSXTREME INCORPORATED' },
	{ code: '1631677', name: 'GALAXY HUB TECHNO LIMITED CO.' },
	{ code: '1663842', name: 'GALLEON ENTERPRISES' },
	{ code: '759069', name: 'GLOMARKET TRADING CORPORATION' },
	{ code: '1050192', name: 'GUANZON MERCHANDISING CORPORATION' },
	{ code: '1046911', name: 'HI-FACTOR IONTECH DIGITAL INDUSTRIES CORPORATION' },
	{ code: '227917', name: 'HPS ICT INC.' },
	{ code: '410652', name: 'IN-BOX PERSONAL PHONES AND ACCESSORIES' },
	{ code: '347248', name: 'INFOWORX, INC.' },
	{ code: '759265', name: 'INTERPACE CORPORATION' },
	{ code: '1057551', name: 'IONTECH INC.' },
	{ code: '1029503', name: 'IRIDIUM TECHNOLOGIES INC.' },
	{ code: '712436', name: 'JENNIFFER LARRAZABAL QUILANG DBA ORMOC NET' },
	{ code: '1435617', name: 'KAREILA MANAGEMENT CORPORATION' },
	{ code: '1740298', name: 'LAZADA' },
	{ code: '917553', name: 'LENIN COMPUTER SYSTEMS, INC.' },
	{ code: '1046916', name: 'LIM, HELEN DE VERA DBA PINES MULTI TELECOM SHOP' },
	{ code: '114235', name: 'M.E. INTERNATIONAL, INC.' },
	{ code: '237638', name: 'MACINTEL, INC.' },
	{ code: '299946', name: 'MACOLYTES, INC.' },
	{ code: '682387', name: 'MAGIC APPLIANCE CENTER INC' },
	{ code: '1050755', name: 'MEGA CELLULAR NETWORK, INC.' },
	{ code: '753885', name: 'METRO NUTECH SOLUTIONS CORPORATION' },
	{ code: '1036173', name: 'MIND ALLIANCE VENTURES, INC.' },
	{ code: '290063', name: 'MOBILIO, INC.' },
	{ code: '760112', name: 'MODUVI, INC.' },
	{ code: '238633', name: 'VSTECS PHILS. INC.' },
	{ code: '251830', name: 'NON CONTRACTING: COMPANY - PHILIPPINES' },
	{ code: '1700356', name: 'OPNETWORKS INC.' },
	{ code: '731032', name: 'ORO GRAPHIC, INC.' },
	{ code: '1733735', name: 'PISMO DIGITAL LIFESTYLE INC.' },
	{ code: '114243', name: 'POWER MAC CENTER. INC.' },
	{ code: '1654681', name: 'EVOLVE TECH LIFESTYLE INC.' },
	{ code: '356529', name: 'RED ICENTER TECHNOLOGIES CORP.' },
	{ code: '720930', name: 'ROBINSONS APPLIANCES CORP.' },
	{ code: '529960', name: 'SCAN & PRINT GRAPHIC HOUSE, INC.' },
	{ code: '1629850', name: 'SILICON VALLEY COMPUTER GROUP PHILS. INC.' },
	{ code: '319583', name: 'STUDIO84 INC.' },
	{ code: '893701', name: 'SYNCHRONIZATION INCORPORATED' },
	{ code: '1036556', name: 'TAPNIO, BENJAMIN WONG DBA FONE STYLE ACCESSORIES' },
	{ code: '562172', name: 'TARLAC MAC ENTERPRISES, INC.' },
	{ code: '388040', name: 'TECH SAVVY GADGETS CORNER' },
	{ code: '752300', name: 'TECHCONCEPTS INCORPORATED' },
	{ code: '759073', name: 'TECHNOHOLICS CO.' },
	{ code: '666856', name: 'TECHNOPAQ, INC.' },
	{ code: '1043109', name: 'THINK BEYOND (GUAM), LLC' },
	{ code: '657997', name: 'THINKING TOOLS, INC.' },
	{ code: '1046907', name: 'TRIVENSON TELECOM, INC.' },
	{ code: '822066', name: 'VISMAY INTERNATIONAL CORP.' },
	{ code: '735395', name: 'WESTERN MARKETING CORPORATION' },
	{ code: '753500', name: 'WILLY AND SONS CORPORATION' },
	{ code: '709383', name: 'WORDTEXT SYSTEMS, INC.' },
	{ code: '1722442', name: 'ZENIO INTERNATIONAL INC.' },
	{ code: '2067916', name: 'AZTEQ PTE. LTD.' },
	{ code: '1084317', name: 'GENERATION NEXT COMMUNICATIONS PTE. LTD.' },
	{ code: '1036472', name: 'MEGA PLUS AFGHANISTAN LTD' },
	{ code: '2232637', name: 'MERCANTILE PACIFIC ASIA PTE. LTD.' },
	{ code: '1707273', name: 'NON CONTRACTING: COMPANY - AFGHANISTAN' },
	{ code: '1707274', name: 'NON CONTRACTING: COMPANY - BHUTAN' },
	{ code: '1034763', name: 'PAYOR FOR AID 1105870' },
	{ code: '840369', name: 'PAYOR FOR AID 280943,846909,849826' },
	{ code: '330988', name: 'SERIAL I-TECH (FAR EAST) PTE. LTD.' },
	{ code: '1330181', name: 'A-MOBILE PTE. LTD.' },
	{ code: '601912', name: 'AEON EARTH PRIVATE LIMITED' },
	{ code: '684881', name: 'ARROW COMMUNICATION GROUP PRIVATE LIMITED' },
	{ code: '2623766', name: 'ASIAPAC DISTRIBUTION PTE LTD' },
	{ code: '242878', name: 'AUDIO HOUSE MARKETING PTE LTD' },
	{ code: '114245', name: 'BEST DENKI (SINGAPORE) PTE. LTD.' },
	{ code: '1330180', name: 'BIG BOX SINGAPORE PTE. LTD.' },
	{ code: '668578', name: 'BRIGHTSTAR LOGISTICS PTE. LTD.' },
	{ code: '227919', name: 'CHALLENGER TECHNOLOGIES LIMITED' },
	{ code: '677830', name: 'COMMS EQUIPMENTS PTE LTD' },
	{ code: '46080', name: 'COURTS (SINGAPORE) PTE. LTD.' },
	{ code: '35683', name: 'VSTECS (SINGAPORE) PTE. LTD.' },
	{ code: '282787', name: 'ELUSH (T3) PTE LTD' },
	{ code: '335839', name: 'EPICENTRE PTE. LTD.' },
	{ code: '678328', name: 'G3 MOBILE & DIGITAL PTE. LTD.' },
	{ code: '787128', name: 'GAIN CITY BEST-ELECTRIC PTE LTD' },
	{ code: '1332706', name: 'HERMES' },
	{ code: '1599979', name: 'HERMES' },
	{ code: '2385182', name: 'HERMES' },
	{ code: '1303555', name: 'HGT MOBILE' },
	{ code: '1060819', name: 'HWEE SENG (ELECTRONICS) PTE LTD' },
	{ code: '35693', name: 'INGRAM MICRO ASIA LTD' },
	{ code: '240653', name: 'INNOVIX DISTRIBUTION PTE. LTD.' },
	{ code: '243193', name: 'JIM AND RICH TRADING' },
	{ code: '1739893', name: 'LAZADA' },
	{ code: '678317', name: 'LIAN YEW TYRE & BATTERY (PTE) LTD.' },
	{ code: '227924', name: 'MC2 PTE LTD' },
	{ code: '768077', name: 'MILLER PTE. LTD.' },
	{ code: '1856083', name: 'MILLER PTE. LTD.' },
	{ code: '1341686', name: 'MOBILE GX' },
	{ code: '244037', name: 'MOHAMED MUSTAFA & SAMSUDDIN CO. PTE LTD' },
	{ code: '242676', name: 'NEWSTEAD TECHNOLOGIES PTE LTD' },
	{ code: '217405', name: 'NON CONTRACTING: COMPANY - SINGAPORE' },
	{ code: '304789', name: 'PERTAMA MERCHANDISING PTE LTD' },
	{ code: '728815', name: 'POLARIS NETWORK PTE. LTD.' },
	{ code: '1654938', name: 'POPULAR BOOK COMPANY (PTE.) LIMITED' },
	{ code: '1248602', name: 'PRIME NET (S) PRIVATE LIMITED' },
	{ code: '241516', name: 'QCD TECHNOLOGY PTE LTD' },
	{ code: '1249365', name: 'REMO COMM PRIVATE LIMITED' },
	{ code: '1768504', name: 'RUNNING LAB PTE. LTD.' },
	{ code: '1248624', name: 'SG TELECOM PTE. LTD.' },
	{ code: '1341687', name: 'SOLUTION MATRIX PTE. LTD.' },
	{ code: '284470', name: 'SPRINT-CASS PTE. LTD.' },
	{ code: '408182', name: 'STEREO ELECTRONICS PTE LTD' },
	{ code: '1588390', name: 'TELESTATION INFOCOMM PTE. LTD.' },
	{ code: '680580', name: 'TRENDS \'N\' TRENDIES PTE LTD' },
	{ code: '1659757', name: 'ABANS PLC' },
	{ code: '342896', name: 'ABANS PLC' },
	{ code: '209918', name: 'B. T. OPTIONS (PRIVATE) LIMITED' },
	{ code: '1324961', name: 'D.P.J. HOLDINGS' },
	{ code: '828345', name: 'FUTUREWORLD LANKA (PVT) LTD' },
	{ code: '2062118', name: 'IDEALZ LANKA (PRIVATE) LIMITED' },
	{ code: '266915', name: 'NON CONTRACTING: COMPANY - SRI LANKA' },
	{ code: '925626', name: 'SINGER (SRI LANKA) PLC' },
	{ code: '709982', name: 'UNI WALKERS (PRIVATE) LIMITED' },
	{ code: '333683', name: 'AKIHABARA CO.,LTD.' },
	{ code: '662528', name: 'BEST HITECH CENTER SURIN PART., LTD.' },
	{ code: '646104', name: 'BIG C SUPERCENTER PUBLIC CO., LTD.' },
	{ code: '795013', name: 'CHI CHANG COMPUTER (THAILAND) CO., LTD.' },
	{ code: '664936', name: 'CHIANGMAI SIAM TV. COMPANY LIMITED' },
	{ code: '838649', name: 'CHOCKDEE IT AND ELECTRIC COMPANY LIMITED' },
	{ code: '277999', name: 'COM7 PUBLIC COMPANY LIMITED' },
	{ code: '277248', name: 'COPPERWIRED CO., LTD.' },
	{ code: '2194377', name: 'COPPERWIRED PUBLIC COMPANY LIMITED' },
	{ code: '278501', name: 'COPPERWIRED-E CO., LTD.' },
	{ code: '283884', name: 'FOTO FILE GROUP CO.,LTD.' },
	{ code: '1107899', name: 'GDV REPORTING' },
	{ code: '664980', name: 'HANG O PENG HONG (2009) CO., LTD.' },
	{ code: '2041374', name: 'HERMES' },
	{ code: '285581', name: 'IT CITY PUBLIC COMPANY LIMITED' },
	{ code: '1617433', name: 'IVERSON TRAINING CENTER CO., LTD.' },
	{ code: '1581642', name: 'JAYMART MOBILE COMPANY LIMITED' },
	{ code: '1428301', name: 'JAYMART PUBLIC COMPANY LIMITED' },
	{ code: '279272', name: 'KING POWER DUTY FREE CO., LTD.' },
	{ code: '731537', name: 'LAMTHONG ROI-ET CO., LTD.' },
	{ code: '1739897', name: 'LAZADA' },
	{ code: '1758025', name: 'MULTISYS COMPUTER & NETWORK CO., LTD.' },
	{ code: '485009', name: 'MUNKONGGADGET CO., LTD.' },
	{ code: '731070', name: 'NAKHON D.C. CO., LTD.' },
	{ code: '663996', name: 'NIYOM PHANICH CO., LTD.' },
	{ code: '685549', name: 'NIYOMPHANICH LAMPANG CO., LTD.' },
	{ code: '277993', name: 'NON CONTRACTING: COMPANY - THAILAND' },
	{ code: '664982', name: 'NUMCHAI HOME ELECTRONICS CO., LTD.' },
	{ code: '722423', name: 'PHIABPHROMELECTRIC PART.,LTD.' },
	{ code: '277235', name: 'PIMAN INFO SERVICE CO LTD' },
	{ code: '837955', name: 'POONSIN ELECTRIC AND CONSTRUCTION LIMITED PARTNERSHIP' },
	{ code: '292585', name: 'POWER BUY CO., LTD.' },
	{ code: '1060810', name: 'RTB TECHNOLOGY CO., LTD.' },
	{ code: '328471', name: 'S.P.A. COMPUTER CO.,LTD.' },
	{ code: '662525', name: 'SAARDGROUP CO.,LTD.' },
	{ code: '685718', name: 'SAHAPANICH CHIANGMAI CO., LTD.' },
	{ code: '906560', name: 'SAHAPAT TV SALES CO., LTD' },
	{ code: '720993', name: 'SAHATHANEE MARKETING LTD.,PART.' },
	{ code: '721003', name: 'SAKCHAI SOLUTIONS CO., LTD.' },
	{ code: '837931', name: 'SEANG TOKYO CO., LTD.' },
	{ code: '662520', name: 'SINTHANEE ELECTRONIC CO., LTD.' },
	{ code: '277234', name: 'SIS DISTRIBUTION (THAILAND) PCL.' },
	{ code: '525257', name: 'S P V I PUBLIC COMPANY LIMITED' },
	{ code: '685697', name: 'STL COMMERCIAL CO., LTD.' },
	{ code: '685530', name: 'SUCHIN PANICH PART., LTD.' },
	{ code: '721021', name: 'SURAJIT THUNGSONG CO., LTD.' },
	{ code: '1584288', name: 'SYNNEX (THAILAND) PUBLIC COMPANY LIMITED' },
	{ code: '662719', name: 'TAIFAHMOTOR COMPUTER CO., LTD.' },
	{ code: '685526', name: 'THAIPANICH VIDEO COMPANY LIMITED' },
	{ code: '685703', name: 'THAIPANITCH MARKETING CO., LTD.' },
	{ code: '664957', name: 'THANATHIWAT COMPANY LIMITED.' },
	{ code: '685472', name: 'THAWEEYONT MARKETING CO.,LTD.' },
	{ code: '664016', name: 'THE BEST 1 ELECTRIC CITY CO., LTD.' },
	{ code: '349293', name: 'THE MALL GROUP CO., LTD.' },
	{ code: '276877', name: 'THE VALUE SYSTEMS CO., LTD.' },
	{ code: '277243', name: 'UFICON COMPANY LIMITED' },
	{ code: '721009', name: 'VICHAI ELECTRIC SARABURI LTD.,PART.' },
	{ code: '1541599', name: '131 TRADING AND SERVICE COMPANY LIMITED.' },
	{ code: '1539339', name: '147 HOUSEHOLD BUSINESS' },
	{ code: '1539096', name: 'AN GIA LOC JOINT STOCK COMPANY' },
	{ code: '1539336', name: 'ANH CHUONG COMPANY LIMITED' },
	{ code: '1541667', name: 'ANH VINH TRADING COMPANY LIMITED' },
	{ code: '1539073', name: 'BACH KHOA EQUIPMENT CORPORATION' },
	{ code: '1767745', name: 'DUYANH FASHION AND COSMETICS CO., LTD' },
	{ code: '1466016', name: 'GDV REPORTING' },
	{ code: '1465487', name: 'SYNNEX FPT DISTRIBUTION COMPANY LIMITED' },
	{ code: '1464762', name: 'FPT DIGITAL RETAIL JOINT STOCK COMPANY' },
	{ code: '1539364', name: 'GIA KHUONG TRADING AND SERVICE COMPANY LIMITED.' },
	{ code: '1539311', name: 'GLOBAL COMPANY LIMITED' },
	{ code: '1539381', name: 'HOANG NAM MOBILE COMPANY LIMITED' },
	{ code: '1539095', name: 'HOANG PHAT MOBILE PHONE COMPANY LIMITED' },
	{ code: '1539380', name: 'HUY ANH COMPANY LIMITED' },
	{ code: '1465567', name: 'KTC CO.,LTD' },
	{ code: '1740302', name: 'LAZADA' },
	{ code: '1539394', name: 'LINH VU COMPANY LIMITED - BAC LIEU BRANCH' },
	{ code: '1539372', name: 'MAI ANH ONE-MEMBER COMPANY LIMITED' },
	{ code: '1465625', name: 'MAI NGUYEN MOBILE TECHNOLOGY COMPANY LIMITED' },
	{ code: '1465640', name: 'MEDIAMART VIETNAM CORPORATION' },
	{ code: '1465520', name: 'MINH TAM ELECTRONICS TRADING SERVICES COMPANY LIMITED' },
	{ code: '1465570', name: 'NEW MIND SOLUTION COMPANY LIMITED' },
	{ code: '1465559', name: 'NGUYEN KIM TRADING JOINT STOCK COMPANY' },
	{ code: '1466000', name: 'NON CONTRACTING: COMPANY - VIETNAM' },
	{ code: '1539078', name: 'PHONG VU TRADING - SERVICE CORPORATION' },
	{ code: '1465614', name: 'PHUONG TUNG INVESTMENT AND TECHNOLOGY JOINT STOCK COMPANY' },
	{ code: '1465634', name: 'PI CO JOINT STOCK COMPANY' },
	{ code: '1539389', name: 'QUOC HUNG COMPANY LIMITED' },
	{ code: '1466010', name: 'QUOC NGHIA TECHNOLOGIES COMPANY LIMITED' },
	{ code: '1465565', name: 'STORY I COMPANY LIMITED' },
	{ code: '1465560', name: 'CONG TY TNHH TAI NANG' },
	{ code: '1539376', name: 'TAM LOAN TECHNOLOGY ONE-MEMBER COMPANY LIMITED.' },
	{ code: '1465584', name: 'MOBILE WORLD' },
	{ code: '1465954', name: 'THIEN NAM HOA CO., LTD' },
	{ code: '1541591', name: 'TOAN PHAT MOBILE TRADING AND SERVICE COMPANY LIMITED' },
	{ code: '1466004', name: 'TRANSHIP LOA AID 1465584' },
	{ code: '1551042', name: 'TU SON ELECTRIC COMPUTER AND TELECOM COMPANY LIMITED' },
	{ code: '1465637', name: 'VHC TRADING COMPANY LIMITED' },
	{ code: '1465963', name: 'VIEN THONG A IMPORT EXPORT TRADING PRODUCTION CORPORATION' },
	{ code: '1539361', name: 'VIENNGOC CO., LTD' },
	{ code: '1539326', name: 'VIET NAM CPN TRANSPORT AND TRADING JOINT STOCK COMPANY' },
	{ code: '1707210', name: 'VIETTEL IMPORT - EXPORT LIMITED COMPANY' },
	{ code: '1465641', name: 'VINH QUANG TRADE - SERVICE CO., LTD' },
	{ code: '2039628', name: 'VINPRO BUSINESS AND TRADING SERVICES JOINT STOCK COMPANY' },
	{ code: '1539331', name: 'VP INVESTMENT AND TRADING JOINT STOCK COMPANY' },
	{ code: '31373', name: '12TH MAN TECHNOLOGY' },
	{ code: '209080', name: 'ABT ELECTRONICS AND APPLIANCES' },
	{ code: '206160', name: 'ADORAMA INC' },
	{ code: '6281', name: 'ADVANCED OFFICE SYSTEMS' },
	{ code: '1042412', name: 'ALASKA MACSTORE' },
	{ code: '35019', name: 'ALASKA MACSTORE' },
	{ code: '1432688', name: 'ALEXANDER WANG INC' },
	{ code: '221943', name: 'ALLIANCE FOR COLLEGE-READY PUBLIC SCHOOLS' },
	{ code: '11976', name: 'ALPHA COMPUTER CENTER INC' },
	{ code: '105986', name: 'ALTO MUSIC' },
	{ code: '97347', name: 'AMAZON.COM' },
	{ code: '763843', name: 'AMAZON' },
	{ code: '628624', name: 'AMERICAN COMPUTER SYSTEMS' },
	{ code: '628622', name: 'AMERICAN COMPUTER SYSTEMS INC' },
	{ code: '1745402', name: 'ANONYOME LABS' },
	{ code: '209263', name: 'ATLANTA PRO AUDIO' },
	{ code: '408281', name: 'AUDIOTRONICS INC' },
	{ code: '413201', name: 'AUDIOTRONICS INC' },
	{ code: '316237', name: 'AUSTIN MAC WORKS' },
	{ code: '29007', name: 'B AND H PHOTO VIDEO CORP' },
	{ code: '612776', name: 'BAILLIOS' },
	{ code: '1507779', name: 'BAILLIOS' },
	{ code: '563881', name: 'BAILLIOS' },
	{ code: '1260114', name: 'BANDIER' },
	{ code: '1212466', name: 'BARNES AND NOBLE COLLEGE BOOKSELLERS' },
	{ code: '24790', name: 'BEAR IMAGES PHOTOGRAPHIC INC' },
	{ code: '11932', name: 'BELL TECHLOGIX INC' },
	{ code: '17601', name: 'BEST BUY' },
	{ code: '1120775', name: 'BEST BUY' },
	{ code: '647190', name: 'BETTENDORF SCHOOL DISTRICT' },
	{ code: '399735', name: 'BJS WHOLESALE CLUB INC' },
	{ code: '715810', name: 'BLUERIDGEMAC' },
	{ code: '715833', name: 'BLUERIDGEMAC' },
	{ code: '1417010', name: 'BOONTON BOARD OF EDUCATION' },
	{ code: '426696', name: 'BOUTIQUE ISTORE' },
	{ code: '781539', name: 'BRANDSMART USA' },
	{ code: '1187089', name: 'BROOKSTONE' },
	{ code: '99975', name: 'BROWN COMPUTER SOLUTIONS INC' },
	{ code: '12126', name: 'BUNDY COMPUTER' },
	{ code: '1389034', name: 'BURLINGTON COUNTY INSTITUTE OF TECHNOLOGY' },
	{ code: '562418', name: 'CAPITAL REGION BOCES' },
	{ code: '30590', name: 'CAPITOL MACINTOSH' },
	{ code: '389313', name: 'CAPITOLMAC' },
	{ code: '389162', name: 'CAPITOLMAC' },
	{ code: '388655', name: 'CELEBRITY CRUISES INC' },
	{ code: '1203194', name: 'CENTURY 21 DEPARTMENT STORES' },
	{ code: '238027', name: 'CENTURY 23' },
	{ code: '242754', name: 'CHARLOTTE STREET COMPUTERS' },
	{ code: '222393', name: 'CHECKPOINT SERVICES INC' },
	{ code: '1397401', name: 'CITY MAC' },
	{ code: '432737', name: 'CITY MAC' },
	{ code: '427213', name: 'CITY MAC' },
	{ code: '1397395', name: 'CITYMAC' },
	{ code: '22823', name: 'COMPUCRAFT INC' },
	{ code: '12108', name: 'COMPUTER ADVANTAGE' },
	{ code: '30395', name: 'COMPUTER CARE' },
	{ code: '277407', name: 'COMPUTER CENTER' },
	{ code: '8058', name: 'COMPUTER CITY SUPERCENTER' },
	{ code: '438002', name: 'COMPUTER CONCEPTS LLC' },
	{ code: '12086', name: 'COMPUTER DIMENSIONS INC' },
	{ code: '212288', name: 'COMPUTER EVOLUTION' },
	{ code: '5850', name: 'COMPUTER HARDWARE INC' },
	{ code: '31016', name: 'COMPUTER LINK' },
	{ code: '558789', name: 'COMPUTER PROS' },
	{ code: '445217', name: 'COMPUTER RESOURCE CENTER' },
	{ code: '265862', name: 'COMPUTER SOLUTIONS' },
	{ code: '11613', name: 'COMPUTER SUPERCENTER' },
	{ code: '11543', name: 'COMPUTER VILLAGE INC' },
	{ code: '29565', name: 'COMPUTER VILLAGE INC' },
	{ code: '13160', name: 'COMPUTER WORLD' },
	{ code: '201101', name: 'COMPUTERS AND BEYOND' },
	{ code: '221690', name: 'COMPUTERS PLUS' },
	{ code: '388009', name: 'COMPUTERS PLUS INC' },
	{ code: '1420566', name: 'COMPUZONE' },
	{ code: '8141', name: 'COMPUZONE' },
	{ code: '662475', name: 'CONNECTING POINT' },
	{ code: '11301', name: 'CONNECTING POINT COMPUTER CENTER' },
	{ code: '11348', name: 'CONNECTING POINT COMPUTER CENTER' },
	{ code: '11339', name: 'CONNECTING POINT COMPUTER CENTERS' },
	{ code: '11323', name: 'CONNECTING POINT COMPUTER CENTERS' },
	{ code: '225313', name: 'CONNECTING POINT COMPUTERS' },
	{ code: '1200446', name: 'CONNS' },
	{ code: '731278', name: 'CORE COMPUTERS AND SERVICE' },
	{ code: '30036', name: 'CORNICE' },
	{ code: '999871', name: 'COSTCO WHOLESALE CORPORATION' },
	{ code: '579818', name: 'COSTCO WHOLESALE' },
	{ code: '266662', name: 'CREATE MORE INC' },
	{ code: '66681', name: 'CREATIVE COMPUTING' },
	{ code: '209264', name: 'CRUTCHFIELD CORPORATION' },
	{ code: '99565', name: 'CRYWOLF' },
	{ code: '1586311', name: 'CSS INC' },
	{ code: '273825', name: 'CURACAO' },
	{ code: '13143', name: 'CW TECHNOLOGY' },
	{ code: '225963', name: 'CYBERGEAR' },
	{ code: '22947', name: 'DAKOTA IMAGING' },
	{ code: '109795', name: 'DALE PRO AUDIO' },
	{ code: '1064', name: 'DATABASE SYSTEMS' },
	{ code: '22303', name: 'DATAVISION COMPUTER VIDEO' },
	{ code: '17117', name: 'DEVICELAND' },
	{ code: '6513', name: 'DI NO COMPUTERS INC' },
	{ code: '1236610', name: 'DICKS MERCHANDISING AND SUPPLY CHAIN INC' },
	{ code: '546472', name: 'DICKS SPORTING GOODS' },
	{ code: '596149', name: 'DICKS SPORTING GOODS' },
	{ code: '1193857', name: 'DIGITAL GADGETS' },
	{ code: '331190', name: 'DIGITAL MEDICS' },
	{ code: '1743238', name: 'DIMENSION DATA NORTH AMERICA INC' },
	{ code: '216809', name: 'DOCTORMAC' },
	{ code: '262157', name: 'DOTFOIL COMPUTERS' },
	{ code: '388549', name: 'DOTFOIL COMPUTERS' },
	{ code: '12737', name: 'DOUBLE CLICK COMPUTERS' },
	{ code: '1189105', name: 'DYCKMAN ELECTRONICS CENTER' },
	{ code: '28564', name: 'EDUCOMP INC' },
	{ code: '2644', name: 'EFFECTIVE COMMUNICATION' },
	{ code: '1283597', name: 'EMERGENCY PLANNING MANAGEMENT' },
	{ code: '1451712', name: 'ENGLEWOOD PUBLIC SCHOOL DISTRICT' },
	{ code: '1288509', name: 'ENJOY TECHNOLOGY INC' },
	{ code: '2377666', name: 'EPAY NORTH AMERICA' },
	{ code: '200940', name: 'EXPERCOM' },
	{ code: '261402', name: 'F11 PHOTOGRAPHIC SUPPLIES' },
	{ code: '221', name: 'FIRST CHOICE COMPUTER CO' },
	{ code: '1587897', name: 'FLEET FEET SPORTS' },
	{ code: '1587908', name: 'FLEET FEET SPORTS (SFO)' },
	{ code: '1598927', name: 'FLEET FEET SPORTS DEVELOPEMENT COMPANY' },
	{ code: '438225', name: 'FLINT AUDIO VIDEO' },
	{ code: '212641', name: 'FLINT AUDIO VIDEO' },
	{ code: '1213339', name: 'FLORIDA INTERNATIONAL UNIVERSITY' },
	{ code: '660089', name: 'FOLLETT HIGHER EDUCATION GROUP' },
	{ code: '29957', name: 'FOTO CARE LTD' },
	{ code: '223625', name: 'FRED MEYER' },
	{ code: '21434', name: 'FRY\'S ELECTRONICS' },
	{ code: '211673', name: 'FULL COMPASS SYSTEMS' },
	{ code: '24151', name: 'G2 COMPUTERS LLC' },
	{ code: '1277832', name: 'GALA TEC' },
	{ code: '274294', name: 'GALA TEC' },
	{ code: '745667', name: 'GATORTEC' },
	{ code: '745668', name: 'GATORTEC' },
	{ code: '63013', name: 'GDV - GOVERNMENT UNAUTHORIZED RESELLERS' },
	{ code: '342423', name: 'GEEKHAMPTON' },
	{ code: '388532', name: 'GEEKHAMPTON' },
	{ code: '1284193', name: 'GOVDIRECT' },
	{ code: '1207845', name: 'GOVX INC' },
	{ code: '28192', name: 'GRAPHICOM' },
	{ code: '25743', name: 'GRAPHITE' },
	{ code: '1280288', name: 'GRAVITY' },
	{ code: '1423711', name: 'GUITAR CENTER INC' },
	{ code: '209153', name: 'GUITAR CENTER STORES INC' },
	{ code: '7231', name: 'HADDOCK COMPUTER CENTER' },
	{ code: '64291', name: 'HAPPYMAC DIGITAL ELECTRONICS' },
	{ code: '11664', name: 'HARDIN COMPUTER' },
	{ code: '31484', name: 'HARRELL\'S BEN FRANKLIN STORE #4678' },
	{ code: '1332560', name: 'HERMES OF PARIS INC' },
	{ code: '466937', name: 'HHGREGG' },
	{ code: '789108', name: 'HP' },
	{ code: '1190663', name: 'HSNI LLC' },
	{ code: '1701210', name: 'HUDSON GROUP HG RETAIL LLC' },
	{ code: '439395', name: 'HUPPINS ONECALL' },
	{ code: '668448', name: 'HYPHENET INC' },
	{ code: '840258', name: 'ICAPE' },
	{ code: '840267', name: 'ICAPE' },
	{ code: '8037', name: 'INACOM CORPORATION' },
	{ code: '15290', name: 'INGRAM MICRO INC' },
	{ code: '1187233', name: 'INMOTION - CORP' },
	{ code: '30', name: 'INTELLIGENT ELECTRONICS' },
	{ code: '423855', name: 'IPRO CENTER' },
	{ code: '549170', name: 'IPRO CENTER' },
	{ code: '756133', name: 'IQMETRIX USA INC' },
	{ code: '910587', name: 'ISOURCE' },
	{ code: '478259', name: 'ISTORE' },
	{ code: '475362', name: 'ISTORE' },
	{ code: '1369919', name: 'ISTORE BY ST MORITZ' },
	{ code: '1432708', name: 'ISTORE BY ST MORITZ' },
	{ code: '389482', name: 'IT WORX' },
	{ code: '201140', name: 'ITECH' },
	{ code: '640421', name: 'ITRAVEL' },
	{ code: '789798', name: 'ITWORKS' },
	{ code: '734817', name: 'ITWORKS' },
	{ code: '1587907', name: 'JACK RABBIT' },
	{ code: '1238220', name: 'JACKTHREADS' },
	{ code: '22931', name: 'JESSI GROUP INC' },
	{ code: '1630583', name: 'JET.COM' },
	{ code: '11535', name: 'JUNEAU ELECTRONICS INC' },
	{ code: '230928', name: 'KAPI\'OLANI COMMUNITY COLLEGE' },
	{ code: '1346499', name: 'KITH RETAIL' },
	{ code: '1518439', name: 'KOHLS' },
	{ code: '444954', name: 'KOHLS DEPARTMENT STORES INC' },
	{ code: '26716', name: 'KROGER FAMILY CENTER COMPUTERS' },
	{ code: '229812', name: 'L2 TECHNOLOGIES' },
	{ code: '236404', name: 'LAKES REGION COMPUTER' },
	{ code: '109990', name: 'LAPIN SYSTEMS INC' },
	{ code: '13958', name: 'LECHMERE' },
	{ code: '1202483', name: 'LINKS UNLIMITED INC' },
	{ code: '1108915', name: 'LIVINGSOUND LLC' },
	{ code: '1108898', name: 'LIVINGSOUND LLC' },
	{ code: '322215', name: 'LOMBART INSTRUMENT' },
	{ code: '335566', name: 'LOS FELIZ HI TECH' },
	{ code: '33018', name: 'MAC FUSION' },
	{ code: '24455', name: 'MAC MADE EASY' },
	{ code: '107997', name: 'MAC O RAMA' },
	{ code: '24831', name: 'MAC ON SITE INC' },
	{ code: '213340', name: 'MAC PROS' },
	{ code: '23267', name: 'MAC RESOURCE' },
	{ code: '11689', name: 'MAC RX INC' },
	{ code: '23709', name: 'MAC WORKS' },
	{ code: '595119', name: 'MACCAFE' },
	{ code: '595116', name: 'MACCAFE' },
	{ code: '628073', name: 'MACCLINIC SEDONA' },
	{ code: '628070', name: 'MACCLINIC SEDONA' },
	{ code: '23092', name: 'MACDOCTORS' },
	{ code: '245697', name: 'MACEDGE' },
	{ code: '32909', name: 'MACEXPERTS OF CALIFORNIA' },
	{ code: '212989', name: 'MACHEADQUARTERS' },
	{ code: '231628', name: 'MACLIFE' },
	{ code: '32087', name: 'MACMAN' },
	{ code: '71099', name: 'MACMEDIA' },
	{ code: '32021', name: 'MACNET' },
	{ code: '23842', name: 'MACPDS' },
	{ code: '24785', name: 'MACSOLUTIONS PLUS INC' },
	{ code: '25113', name: 'MACSUPERSTORE' },
	{ code: '71029', name: 'MACTECH SOLUTIONS' },
	{ code: '1393181', name: 'MACTLC' },
	{ code: '25406', name: 'MACTLC' },
	{ code: '1242394', name: 'MACYS INC' },
	{ code: '705841', name: 'MACYS SYSTEMS AND TECHNOLOGY INC' },
	{ code: '630045', name: 'MAD MACS' },
	{ code: '24396', name: 'MANCO INDUSTRIES INC' },
	{ code: '61987', name: 'MATRX SYSTEMS' },
	{ code: '1198347', name: 'MAXFIELD' },
	{ code: '21275', name: 'MCDUFF/AV&C' },
	{ code: '1497331', name: 'MCMOBILE INC' },
	{ code: '11377', name: 'MEGABYTE COMPUTER CENTER' },
	{ code: '221918', name: 'MEIJER INC' },
	{ code: '201961', name: 'MELROSEMAC' },
	{ code: '13404', name: 'MICRO CENTER' },
	{ code: '22776', name: 'MICRO TECHNOLOGY GROUP INC' },
	{ code: '11507', name: 'MICROPOWER COMPUTER' },
	{ code: '1198241', name: 'MICROSOFT CORPORATION' },
	{ code: '33099', name: 'MIKES TECH SHOP' },
	{ code: '229637', name: 'MIKEYS HOOK UP' },
	{ code: '1402304', name: 'MILLVILLE BOARD OF EDUCATION' },
	{ code: '472260', name: 'MODERN HOME SYSTEMS' },
	{ code: '628123', name: 'MODIA' },
	{ code: '226109', name: 'AAFES EXCH - HQ' },
	{ code: '224998', name: 'NEXCOM - HQ' },
	{ code: '226845', name: 'USCG EXCH - HQ' },
	{ code: '225636', name: 'USMC EXCH - HQ' },
	{ code: '242245', name: 'VETERANS CANTEEN SERVICE - HQ' },
	{ code: '213641', name: 'MUSICIANS FRIEND' },
	{ code: '13364', name: 'NEBRASKA FURNITURE MART' },
	{ code: '1484082', name: 'NEIMAN MARCUS GROUP' },
	{ code: '1544653', name: 'NIKE RETAIL SERVICES INC' },
	{ code: '986984', name: 'NINEBARK CONSULTING' },
	{ code: '1260734', name: 'NORTECH' },
	{ code: '436869', name: 'NORTECH CSS' },
	{ code: '791993', name: 'OCTORARA AREA SCHOOL DISTRICT' },
	{ code: '421505', name: 'ONECLICK' },
	{ code: '345414', name: 'ONECLICK' },
	{ code: '27908', name: 'P C RICHARD AND SON' },
	{ code: '1061420', name: 'P C RICHARD AND SON' },
	{ code: '22860', name: 'PACIFIC PREMIER COMPUTERS' },
	{ code: '843591', name: 'PATERSON ARTS AND SCIENCE CHTR SCHL' },
	{ code: '28921', name: 'PC AGE, INC.' },
	{ code: '286636', name: 'PC WAREHOUSE' },
	{ code: '1706960', name: 'PCI TEC' },
	{ code: '33227', name: 'PERRY COMPUTER SERVICES' },
	{ code: '1588241', name: 'POTOMAC RIVER RUNNING INC' },
	{ code: '1234003', name: 'POWERMAX' },
	{ code: '1197473', name: 'POWERMAX' },
	{ code: '1260089', name: 'RADIOSHACK' },
	{ code: '226786', name: 'RADIOSHACK' },
	{ code: '482150', name: 'RAND TECHNOLOGY' },
	{ code: '17603', name: 'RC WILLEY HOME FURNISHINGS' },
	{ code: '1202032', name: 'RECORD MART MONEY MANAGEMENT LLC' },
	{ code: '286365', name: 'RIBBIT COMPUTERS LLC' },
	{ code: '11573', name: 'RICKS COMPUTERS INC' },
	{ code: '1587909', name: 'ROAD RUNNER SPORTS' },
	{ code: '1410054', name: 'ROSELLE BOARD OF EDUCATION' },
	{ code: '24966', name: 'RSPE AUDIO SOLUTIONS' },
	{ code: '223460', name: 'SALINE AREA SCHOOLS' },
	{ code: '224261', name: 'SAMS CLUB' },
	{ code: '209565', name: 'SAMYS CAMERA' },
	{ code: '236830', name: 'SAVANT SYSTEMS LLC' },
	{ code: '1203856', name: 'SCHEELS' },
	{ code: '455937', name: 'SCK INC' },
	{ code: '1414191', name: 'SHELTER ISLAND UFSD' },
	{ code: '33266', name: 'SIGNATURE MAC INC' },
	{ code: '251005', name: 'SIMPLY MAC' },
	{ code: '270549', name: 'SIMPLY MACINTOSH' },
	{ code: '11333', name: 'SIMUTEK' },
	{ code: '1401350', name: 'SKI TOWN COMPUTING' },
	{ code: '1401330', name: 'SKI TOWN COMPUTING' },
	{ code: '30381', name: 'SMALL DOG ELECTRONICS' },
	{ code: '1513748', name: 'SMALL DOG ELECTRONICS' },
	{ code: '496950', name: 'SMALL DOG ELECTRONICS INC' },
	{ code: '223455', name: 'SOUTHGATE COMMUNITY SCHOOLS' },
	{ code: '95342', name: 'SPRINGBOARD MEDIA' },
	{ code: '753778', name: 'STAPLES THE OFFICE SUPERSTORE' },
	{ code: '15112', name: 'STAPLES' },
	{ code: '304378', name: 'STRONG SOLUTIONS' },
	{ code: '283428', name: 'SUNRISE COMPUTERS' },
	{ code: '33173', name: 'SWEETWATER SOUND' },
	{ code: '56270', name: 'SYSTEMS PLUS COMPUTERS INC' },
	{ code: '109487', name: 'TARGET CORPORATION' },
	{ code: '24802', name: 'TBI COMPUTER' },
	{ code: '404433', name: 'TBI COMPUTER' },
	{ code: '64244', name: 'TCW COMPUTER SYSTEMS' },
	{ code: '96745', name: 'TD CURRAN INC' },
	{ code: '11653', name: 'TEAM TECHNOLOGY' },
	{ code: '15291', name: 'TECH DATA PRODUCT MANAGEMENT INC' },
	{ code: '475405', name: 'TECHONE CENTRE' },
	{ code: '478326', name: 'TECHONE CENTRE' },
	{ code: '300302', name: 'TECHWISE COMPUTERS LLC' },
	{ code: '11948', name: 'THE COMPUTER LAB INC' },
	{ code: '46440', name: 'THE COMPUTER LOFT INC' },
	{ code: '91930', name: 'THE COMPUTER PLACE' },
	{ code: '95454', name: 'THE COMPUTER STORE' },
	{ code: '61310', name: 'THE COMPUTER STORE INC' },
	{ code: '281868', name: 'THE CORE' },
	{ code: '22853', name: 'THE CREATIVE EDGE' },
	{ code: '11638', name: 'THE LEARNING CENTER LTD' },
	{ code: '435118', name: 'THE MAC EXPERIENCE INC' },
	{ code: '289878', name: 'THE MAC EXPRESS' },
	{ code: '95675', name: 'THE MAC GUYS' },
	{ code: '31023', name: 'THE MAC GUYS' },
	{ code: '233998', name: 'THE MAC RANCH LLC' },
	{ code: '268497', name: 'THE MAC SHACK' },
	{ code: '336932', name: 'THE ORCHARD' },
	{ code: '1202053', name: 'TILLYS' },
	{ code: '299626', name: 'TOYS R US' },
	{ code: '23033', name: 'TRICOM' },
	{ code: '17972', name: 'UNITEK COMPUTER STORES' },
	{ code: '632808', name: 'VGH COMPUTER SERVICE' },
	{ code: '222965', name: 'VINTAGE KING AUDIO' },
	{ code: '25231', name: 'VISIONARY COMPUTER' },
	{ code: '28627', name: 'VISUAL DYNAMICS INC' },
	{ code: '24561', name: 'VOELKER RESEARCH' },
	{ code: '1228920', name: 'VON MAUR' },
	{ code: '2059869', name: 'WALGREENS' },
	{ code: '17600', name: 'WALMART' },
	{ code: '452454', name: 'WESTGATE COMPUTERS' },
	{ code: '852182', name: 'WESTLAKE PRO' },
	{ code: '887349', name: 'WILLIAM S HART UNION HIGH SD' },
	{ code: '229982', name: 'WINDHAM SCHOOL DEPARTMENT' },
	{ code: '23118', name: 'YES COMPUTERS' },
	{ code: '1189062', name: 'YOUR ELECTRONIC WAREHOUSE' },
	{ code: '1189019', name: 'ZORRO SOUNDS' },
	{ code: '1630825', name: 'ACP HOLDING ÖSTERREICH GMBH' },
	{ code: '657432', name: 'ACP IT SOLUTIONS GMBH' },
	{ code: '109081', name: 'ALLINONE EDV VERTRIEBS UND BERATUNGS GMBH' },
	{ code: '600553', name: 'APPLE - CYBERPORT GMBH (WIEN)' },
	{ code: '288982', name: 'CANCOM A+D IT-SOLUTIONS GMBH' },
	{ code: '109080', name: 'COMPUTER SERVICE HARD+SOFT GMBH' },
	{ code: '523140', name: 'ELBE GMBH' },
	{ code: '380579', name: 'ELECTRONICPARTNER AUSTRIA GMBH' },
	{ code: '357414', name: 'EPOSCOMPUTER HANDELS GMBH' },
	{ code: '843156', name: 'HAAI GMBH' },
	{ code: '2031944', name: 'HP AUSTRIA GMBH' },
	{ code: '1463859', name: 'IT-TEAM GMBH' },
	{ code: '617456', name: 'KAPSCH BUSINESSCOM AG' },
	{ code: '52974', name: 'MAC)OFFICE' },
	{ code: '300321', name: 'MCAG ACHLEITNER GMBH' },
	{ code: '214606', name: 'MEDIA-SATURN BETEILIGUNGS GMBH' },
	{ code: '103851', name: 'NC DIGITALIS GMBH' },
	{ code: '229722', name: 'RED ZAC' },
	{ code: '101408', name: 'TOOLS AT WORK HARD + SOFT VERTRIEBSGES.M.B.H.' },
	{ code: '103856', name: 'WEILNBÖCK & KÖNIGSEDER OG' },
	{ code: '2571805', name: 'A&M NV' },
	{ code: '103863', name: 'ANAITIS CONSULT - TRAINING CENTER' },
	{ code: '1451582', name: 'BOL.COM' },
	{ code: '236504', name: 'CARREFOUR' },
	{ code: '1644030', name: 'CENTRAL POINT' },
	{ code: '666867', name: 'CIPIYOU VERVIERS' },
	{ code: '454943', name: 'COMPUTACENTER NV/SA' },
	{ code: '103902', name: 'COMPUTERSYSTEMEN WILLAERT BVBA' },
	{ code: '1401221', name: 'COOLBLUE' },
	{ code: '1711602', name: 'CORA' },
	{ code: '226148', name: 'CORA NV' },
	{ code: '2043870', name: 'DISCORP' },
	{ code: '103871', name: 'DOUBLE PRECISION BVBA' },
	{ code: '463078', name: 'ECONOCOM' },
	{ code: '2086399', name: 'ESSEC' },
	{ code: '1018875', name: 'EXELLENT' },
	{ code: '53090', name: 'FEM COMPUTERS' },
	{ code: '211738', name: 'FNAC BELGIUM NV/SA' },
	{ code: '1482109', name: 'GETRONICS' },
	{ code: '1497275', name: 'HERMÈS' },
	{ code: '2031949', name: 'HP BELGIUM BVBA' },
	{ code: '1587079', name: 'INFOTHEEK' },
	{ code: '236503', name: 'KREFEL NV' },
	{ code: '695609', name: 'LAB9 STORES' },
	{ code: '225279', name: 'MAKRO' },
	{ code: '236506', name: 'MAVEDRO' },
	{ code: '211740', name: 'MEDIA MARKT SATURN BELGIUM NV' },
	{ code: '103887', name: 'MEGA-DOC NV' },
	{ code: '1644322', name: 'MOBCO' },
	{ code: '2487385', name: 'MOBITEL' },
	{ code: '97459', name: 'NEW VANDEN BORRE NV' },
	{ code: '1808242', name: 'NTT' },
	{ code: '571671', name: 'REALDOLMEN NV' },
	{ code: '439497', name: 'SAGAMGRAPHIC NV' },
	{ code: '405216', name: 'SANOMA MAGAZINES BELGIUM NV' },
	{ code: '53096', name: 'SBI' },
	{ code: '589163', name: 'SUNNY EUROPE NV' },
	{ code: '593860', name: 'SWITCH NOORDERLAAN (HQ)' },
	{ code: '2731505', name: 'SWITCH' },
	{ code: '630919', name: 'SYSTEMAT BELGIUM SA/NV' },
	{ code: '103898', name: 'T.A.C.O SYSTEMS SA' },
	{ code: '976312', name: 'TAKING OFF' },
	{ code: '2117989', name: '2WAYS-IT APS' },
	{ code: '657067', name: '2WAYSIT.DK' },
	{ code: '542838', name: 'ADMIRE' },
	{ code: '1781828', name: 'ANY.CLOUD A/S' },
	{ code: '103915', name: 'ANYMAC' },
	{ code: '254749', name: 'ATEA A/S' },
	{ code: '558379', name: 'CBC IT AMBA' },
	{ code: '426135', name: 'COMM2IG A/S' },
	{ code: '457299', name: 'COMPUTERCITY DANMARK, FILIAL AF SIBA AB, SVERIGE' },
	{ code: '649336', name: 'COMPUTERSALG A/S' },
	{ code: '2011538', name: 'DANOFFICE IT APS' },
	{ code: '261746', name: 'SALLING GROUP' },
	{ code: '1645843', name: 'DANTELL A/S' },
	{ code: '1944846', name: 'DESCOM A/S' },
	{ code: '80170', name: 'DUSTIN A/S' },
	{ code: '424013', name: 'ED-DATA A/S' },
	{ code: '596571', name: 'EDGEMO A/S' },
	{ code: '670670', name: 'EG A/S' },
	{ code: '2405266', name: 'EGISS A/S' },
	{ code: '229955', name: 'ELKJØP NORDIC AS (DENMARK)' },
	{ code: '1001480', name: 'POWER INTERNATIONAL AS' },
	{ code: '2031955', name: 'HP INC DANMARK APS' },
	{ code: '56069', name: 'HUMAC A/S' },
	{ code: '1327309', name: 'IT RELATION A/S' },
	{ code: '2522648', name: 'KLC ERHVERV GLOSTRUP APS' },
	{ code: '438205', name: 'KMD A/S' },
	{ code: '616292', name: 'KOMPETERA A/S' },
	{ code: '558378', name: 'KONTORSYD' },
	{ code: '2366997', name: 'MARKIT B2B APS' },
	{ code: '2509745', name: 'MOBITEL A/S' },
	{ code: '56059', name: 'NEMA P/F' },
	{ code: '1522694', name: 'NETONNET AB' },
	{ code: '2277117', name: 'NETSOLUTIONS APS' },
	{ code: '655004', name: 'APPELSIINI FINLAND OY' },
	{ code: '406398', name: 'ATEA FINLAND OY' },
	{ code: '1397749', name: 'BLC' },
	{ code: '1557427', name: 'CHILIT OY' },
	{ code: '588992', name: 'DATA GROUP KOTKA' },
	{ code: '103922', name: 'DUSTIN FINLAND OY' },
	{ code: '2069939', name: 'ELISA SANTA MONICA OY' },
	{ code: '229956', name: 'ELKJOP NORDIC AS (FINLAND)' },
	{ code: '647103', name: 'FUJITSU FINLAND OY' },
	{ code: '1021405', name: 'G7 DATA OY' },
	{ code: '1042886', name: 'ILAAKSO IT OY' },
	{ code: '485687', name: 'ILONA IT' },
	{ code: '100664', name: 'MACPEOPLE OY' },
	{ code: '2103063', name: 'MCARE RENTAL OY' },
	{ code: '1373850', name: 'MOBILE SPA VANTAA OY' },
	{ code: '1481892', name: 'MPY' },
	{ code: '406647', name: 'MULTITRONIC OY' },
	{ code: '2522485', name: 'MULTITRONIC PRO OY' },
	{ code: '537464', name: 'MUSTA-PEKKA OY' },
	{ code: '335972', name: 'OMENA GROUP KY' },
	{ code: '1394145', name: 'PCP PARTNER OY' },
	{ code: '1045153', name: 'POWER FINLAND' },
	{ code: '99952', name: 'POWERHOUSE' },
	{ code: '1124911', name: 'SIGNAL PARTNERS OY' },
	{ code: '897397', name: 'SOK' },
	{ code: '225516', name: 'STOCKMANN OYJ ABP' },
	{ code: '608388', name: 'SYSTEMASTORE OY' },
	{ code: '99950', name: 'TECTOR' },
	{ code: '524989', name: 'TEKNISET' },
	{ code: '536128', name: 'TELIA DATAINFO OY' },
	{ code: '2101377', name: 'TELIA INMICS-NEBULA OY' },
	{ code: '748289', name: 'TIETO CORPORATION' },
	{ code: '563787', name: 'TIETO FINLAND OY' },
	{ code: '1152940', name: 'TIETOKESKUS' },
	{ code: '100653', name: 'VARIMPORT' },
	{ code: '1067366', name: 'VEIKON KONE' },
	{ code: '666763', name: 'VELJEKSET KESKINEN OY' },
	{ code: '233125', name: 'VERKKOKAUPPA.COM' },
	{ code: '1743059', name: 'XLLNC FINLAND' },
	{ code: '578932', name: 'ACCOR' },
	{ code: '53487', name: 'ACHATS MARCHANDISES CASINO SIÈGE SOCIAL' },
	{ code: '101489', name: 'ACTIMAG CAEN L\'ÉCUREUIL' },
	{ code: '777383', name: 'ACTIVIUM - ID' },
	{ code: '104018', name: 'AKOS' },
	{ code: '53681', name: 'ALIS' },
	{ code: '214016', name: 'ALL-MAC' },
	{ code: '1481683', name: 'ALT-S' },
	{ code: '251402', name: 'AMAZON EU SARL' },
	{ code: '260465', name: 'ANDROMAC SIÈGE SOCIAL' },
	{ code: '1559786', name: 'APPLE - BOUYGUES TELECOM DUMMY ACCOUNT' },
	{ code: '1451542', name: 'APPLE - CARREFOUR DUMMY ACCOUNT' },
	{ code: '2286957', name: 'ARP' },
	{ code: '101095', name: 'ATREID' },
	{ code: '53496', name: 'AUCHAN HYPERMARCHE SIÈGE SOCIAL' },
	{ code: '53686', name: 'BEA INFORMATIQUE' },
	{ code: '616492', name: 'BECHTLE DIRECT MOLSHEIM' },
	{ code: '91933', name: 'BOULANGER SIÈGE SOCIAL' },
	{ code: '104249', name: 'BUROTICA' },
	{ code: '245460', name: 'CARREFOUR MARCHANDISES INTERNATIONALES SIÈGE SOCIAL' },
	{ code: '1201788', name: 'CFI' },
	{ code: '112400', name: 'COLETTE' },
	{ code: '239784', name: 'COMPUTACENTER FRANCE' },
	{ code: '217581', name: 'CONFORAMA FRANCE' },
	{ code: '356356', name: 'CORA' },
	{ code: '337192', name: 'CORIOLIS TELECOM SAS' },
	{ code: '405103', name: 'CORSIDEV' },
	{ code: '101841', name: 'CTM' },
	{ code: '1240295', name: 'CULTURA' },
	{ code: '1758324', name: 'DXM BRAGARD CESSON-SÉVIGNÉ' },
	{ code: '866235', name: 'EASY ADMINISTRATION SIÈGE SOCIAL' },
	{ code: '1025516', name: 'ECONOCOM PRODUCTS AND SOLUTIONS PUTEAUX' },
	{ code: '352553', name: 'EX&CO' },
	{ code: '704310', name: 'FBX SYSTEME CHAMALIERES' },
	{ code: '104530', name: 'FCI GROUPE' },
	{ code: '53801', name: 'FNAC DARTY PARTICIPATIONS ET SERVICES SIÈGE SOCIAL' },
	{ code: '232513', name: 'GALILÉE' },
	{ code: '54091', name: 'GERMOND' },
	{ code: '1758336', name: 'GROUPE LDLC' },
	{ code: '1331386', name: 'HERMÈS SELLIER' },
	{ code: '2031961', name: 'HP FRANCE SAS' },
	{ code: '262547', name: 'HYPER PLEIN CIEL' },
	{ code: '46982', name: 'ICONCEPT SIÈGE SOCIAL' },
	{ code: '359020', name: 'ILLAM INFORMATIQUE' },
	{ code: '229697', name: 'INFORMATIQUE ET PREVENTION SIÈGE SOCIAL' },
	{ code: '64713', name: 'INMAC WSTORE' },
	{ code: '1760295', name: 'INSIGHT' },
	{ code: '104707', name: 'INTER MICRO & RESEAUX' },
	{ code: '109184', name: 'INTER-ACTIF SIÈGE SOCIAL' },
	{ code: '731174', name: 'IRSI MAILLOT' },
	{ code: '113168', name: 'ISTACK' },
	{ code: '91728', name: 'ITRIBU MAUGUIO' },
	{ code: '616489', name: 'LAFI PARIS' },
	{ code: '1039509', name: 'LICK SIÈGE SOCIAL' },
	{ code: '1576144', name: 'LOUIS PION SAS SIÈGE SOCIAL' },
	{ code: '104220', name: 'MAC ONLY' },
	{ code: '104853', name: 'MACSHOP' },
	{ code: '2206057', name: 'MARKIT B2B' },
	{ code: '214300', name: 'MC SERVICE' },
	{ code: '1084516', name: 'MCI32' },
	{ code: '53886', name: 'MCS NICE' },
	{ code: '100331', name: 'MEDIACASH' },
	{ code: '366674', name: 'MEGACHIP MANOSQUE' },
	{ code: '363138', name: 'METRO CASH & CARRY FRANCE' },
	{ code: '104849', name: 'MICRO ANALYSES COMMUNICATION MAC' },
	{ code: '239785', name: 'MICROSEA' },
	{ code: '104933', name: 'MICROTOOLS' },
	{ code: '104984', name: 'NEYRIAL' },
	{ code: '1544721', name: 'NIKE EUROPE - NIKE NEDERLAND' },
	{ code: '724887', name: 'O2I GENNEVILLIERS' },
	{ code: '616484', name: 'OFFICE DEPOT SIEGE SOCIAL' },
	{ code: '53884', name: 'OLYS - SIÈGE SOCIAL' },
	{ code: '113179', name: 'OPALE' },
	{ code: '766150', name: 'ORDISYS HOLDING' },
	{ code: '2285640', name: 'PC21' },
	{ code: '606058', name: 'PIXMANIA SIEGE SOCIAL' },
	{ code: '352363', name: 'RESEAU DIGITAL' },
	{ code: '229693', name: 'REVEIL INFORMATIQUE' },
	{ code: '871292', name: 'RICOH FRANCE' },
	{ code: '210166', name: 'SC GALEC' },
	{ code: '581398', name: 'SCC FRANCE' },
	{ code: '109134', name: 'SERIANS - BUROTEC' },
	{ code: '1551069', name: 'SHI INTERNATIONAL SAS' },
	{ code: '2284097', name: 'STIM PLUS' },
	{ code: '53461', name: 'SYMBIOSE INFORMATIQUE SIÈGE SOCIAL' },
	{ code: '1763474', name: 'TOP OFFICE SIÈGE SOCIAL' },
	{ code: '1753358', name: 'TRYO' },
	{ code: '98966', name: 'WELCOME INFORMATIQUE' },
	{ code: '108916', name: 'A.S.T.I. GMBH AGENTUR FÜR SYSTEME TECHNIK INNOVATION' },
	{ code: '110610', name: 'ACS GROUP ALPHA COMPUTER SALES GMBH' },
	{ code: '2301340', name: 'ACV ELECTRONIC GMBH' },
	{ code: '105363', name: 'ADAM SOFT OHG' },
	{ code: '1022806', name: 'AGELERO IT-SYSTEME ERDING E.K.' },
	{ code: '1022845', name: 'AGELERO LANDSHUT' },
	{ code: '1682797', name: 'AIXXESS GMBH' },
	{ code: '448371', name: 'ALTERNATE GMBH' },
	{ code: '237017', name: 'AMAZON EU SARL' },
	{ code: '2107402', name: 'AMV GMBH' },
	{ code: '238601', name: 'ARKTIS SOFTWARE GMBH' },
	{ code: '252060', name: 'BECHTLE GMBH' },
	{ code: '288943', name: 'BETTERWORX GMBH' },
	{ code: '108918', name: 'COMPETENCE GMBH' },
	{ code: '52878', name: 'CANCOM GMBH' },
	{ code: '108970', name: 'CMC COMPUTER HANDELS GMBH' },
	{ code: '108919', name: 'COMPUMAC COMPUTERVERTRIEB GMBH' },
	{ code: '294625', name: 'COMPUSTORE BAJUWARENSTRASSE' },
	{ code: '440295', name: 'COMPUTACENTER AG & CO. OHG' },
	{ code: '108920', name: 'COMPUTER X SERVICE- UND VERTRIEBS GMBH' },
	{ code: '447347', name: 'COMPUTER ZAGLER' },
	{ code: '108921', name: 'COMPUTERHAUS GDD SALESPOINT GMBH' },
	{ code: '749352', name: 'CONCAT AG' },
	{ code: '588074', name: 'CONRAD ELECTRONIC' },
	{ code: '105370', name: 'CYBERPORT STORE DRESDEN WALDSCHLOESSCHEN' },
	{ code: '105373', name: 'DENKFORM GMBH' },
	{ code: '108927', name: 'DTPDIREKT' },
	{ code: '108928', name: 'DUBRAU GMBH' },
	{ code: '1394171', name: 'EINSNULLEINS GMBH' },
	{ code: '241451', name: 'ELECTRONIC PARTNER' },
	{ code: '1685149', name: 'ENERGY NET GMBH' },
	{ code: '222658', name: 'ERGO SUM E.K.' },
	{ code: '229217', name: 'EURONICS DEUTSCHLAND EG' },
	{ code: '238636', name: 'EXPERT' },
	{ code: '1045168', name: 'FAKTORDREI GMBH' },
	{ code: '213083', name: 'FMS - COMPUTER GMBH' },
	{ code: '2031999', name: 'FOXCONN EUROPEAN MANUFACTURING SERVICES S. R. O.' },
	{ code: '349473', name: 'FUN COMPUTER E. K.' },
	{ code: '53186', name: 'GRAVIS GMBH' },
	{ code: '105382', name: 'GRID CAD & COMPUTER GMBH' },
	{ code: '94172', name: 'HAMBURG 4 GMBH' },
	{ code: '54844', name: 'HAUS & GROSS IT.SERVICES GMBH' },
	{ code: '105385', name: 'HEINER HOFFECKER' },
	{ code: '1003287', name: 'HENCKE SYSTEMBERATUNG GMBH' },
	{ code: '1331407', name: 'HERMÈS GMBH' },
	{ code: '2031962', name: 'HP DEUTSCHLAND GMBH' },
	{ code: '283605', name: 'IMAZING COMPUTERSYSTEME OHG' },
	{ code: '268431', name: 'IMPLEMENT-IT GMBH' },
	{ code: '780795', name: 'INSIGHT TECHNOLOGY SOLUTIONS GMBH' },
	{ code: '1245199', name: 'IT NEUBAUER BY HIGHTI GMBH & CO. KG' },
	{ code: '546787', name: 'IT-HAUS GMBH' },
	{ code: '2113574', name: 'JACOB ELEKTRONIK GMBH' },
	{ code: '52818', name: 'JOSEPH COMPUTER & SERVICE GMBH' },
	{ code: '110627', name: 'JUERGEN JOSEPH JJ - COMPUTER - DIENSTLEISTUNGEN' },
	{ code: '1766143', name: 'KOMSA ENTERPRISE SERVICES GMBH' },
	{ code: '1487500', name: 'KONICA MINOLTA IT SOLUTIONS GMBH' },
	{ code: '259060', name: 'LEVEL 16 GMBH' },
	{ code: '1783282', name: 'LOGIWAY GMBH' },
	{ code: '108942', name: 'LOTS A BITS GMBH & CO. KG' },
	{ code: '108980', name: 'MAC CONSULT' },
	{ code: '211965', name: 'MAC PLACE - ANTJE E. MEYER' },
	{ code: '108978', name: 'MAC SYSTEM GMBH' },
	{ code: '210757', name: 'MEDIA ARTS' },
	{ code: '110018', name: 'MEDIA-SATURN DEUTSCHLAND GMBH' },
	{ code: '108946', name: 'MELADOS COMPUTER GMBH' },
	{ code: '680162', name: 'MERCATEO AG' },
	{ code: '108947', name: 'MERLIN STORE' },
	{ code: '232885', name: 'METRO DEUTSCHLAND GMBH' },
	{ code: '588920', name: 'MISCO GERMANY INC.' },
	{ code: '1465682', name: 'MR-DATENTECHNIK VERTRIEBS- UND SERVICE GMBH' },
	{ code: '537905', name: 'MÜLLER LTD. & CO. KG' },
	{ code: '1493593', name: 'NETZTECHNIK SCHIFFMANN GMBH' },
	{ code: '1544718', name: 'NIKE EUROPE - NIKE NEDERLAND' },
	{ code: '1576992', name: 'NORITEL MOBILE KOMMUNIKATION GMBH' },
	{ code: '898584', name: 'OFFICE DEPOT DEUTSCHLAND GMBH' },
	{ code: '288824', name: 'ORGTEAM DATENSYSTEME GMBH' },
	{ code: '225840', name: 'OTTO GMBH & CO.KG' },
	{ code: '212187', name: 'PHOENIX GMBH' },
	{ code: '554869', name: 'REDNET AG' },
	{ code: '108954', name: 'REMUS DATENTECHNIK GMBH' },
	{ code: '305294', name: 'RESTORE GMBH' },
	{ code: '291882', name: 'RINGFOTO GMBH & CO. ALFO MARKETING KG' },
	{ code: '112763', name: 'SANDATA EDV-SYSTEMHAUS GMBH' },
	{ code: '105409', name: 'SBS SYSTEMBERATUNG & SERVICE GMBH' },
	{ code: '108955', name: 'SEIBOLD & PARTNER SYSTEMHAUS GMBH' },
	{ code: '656624', name: 'STAPLES' },
	{ code: '53176', name: 'SYSTEMCONNECT GESELLSCHAFT FUR SERVICE, SUPPORT, BERATUNG MBH' },
	{ code: '62372', name: 'TEAMPOINT KÖLN GMBH' },
	{ code: '604504', name: 'BYTECLUB GMBH' },
	{ code: '364076', name: 'THINK ABOUT IT GMBH' },
	{ code: '2432245', name: 'THINKRED GESELLSCHAFT FÜR DIGITALE BILDUNG MBH' },
	{ code: '105417', name: 'TRYTEC! MICROSYSTEMS' },
	{ code: '612310', name: 'URANO INFORMATIONSSYSTEME GMBH' },
	{ code: '1815706', name: 'VALORA HOLDING GERMANY GMBH' },
	{ code: '327476', name: 'ARGOS LIMITED' },
	{ code: '353392', name: 'ARKPHIRE IRELAND LIMITED' },
	{ code: '451904', name: 'BT COMMUNICATIONS IRELAND LTD' },
	{ code: '2110614', name: 'COMPUB' },
	{ code: '1489502', name: 'D.I.D ELECTRICAL' },
	{ code: '327475', name: 'DSG RETAIL IRELAND LIMITED' },
	{ code: '332168', name: 'EXPERT IRELAND RETAIL LIMITED' },
	{ code: '55899', name: 'GALMAC COMPUTERS LTD' },
	{ code: '229900', name: 'HARVEY NORMAN TRADING (IRELAND) LIMITED' },
	{ code: '680980', name: 'HIBERNIA EVROS TECHNOLOGY GROUP' },
	{ code: '830733', name: 'ICONNECT CORK' },
	{ code: '558340', name: 'M.J. FLOOD TECHNOLOGY LTD' },
	{ code: '56026', name: 'MACTIVATE LTD' },
	{ code: '451419', name: 'PFH TECHNOLOGY GROUP' },
	{ code: '55895', name: 'TYPETEC IRELAND LTD' },
	{ code: '1587104', name: 'WRIGGLE' },
	{ code: '1205357', name: '10 CORSO COMO' },
	{ code: '482805', name: '4WD INFORMATICA SRL' },
	{ code: '92003', name: 'ABC INFORMATICA SRL' },
	{ code: '2046502', name: 'AMAZON EU SARL' },
	{ code: '53051', name: 'BAGNETTI S.R.L.' },
	{ code: '1133859', name: 'BT ITALIA S.P.A.' },
	{ code: '233278', name: 'C&C CONSULTING SPA' },
	{ code: '445032', name: 'CARAMORI SRL' },
	{ code: '209861', name: 'C.E. COMMUNICATIONS ENGINEERING SRL' },
	{ code: '105448', name: 'COMPULAB SRL' },
	{ code: '1536279', name: 'COMPUTIME' },
	{ code: '55731', name: 'COMPUTIME' },
	{ code: '224512', name: 'CONVERGE SPA' },
	{ code: '226162', name: 'COOP ITALIA SOC. COOP.ARL' },
	{ code: '105456', name: 'CUORDIMELA SRL' },
	{ code: '92024', name: 'DATA PORT' },
	{ code: '478794', name: 'DATA X' },
	{ code: '556528', name: 'DATAPIU SRL' },
	{ code: '55906', name: 'DATATRADE SPA' },
	{ code: '1561580', name: 'DAVOS 95 SRL' },
	{ code: '1974645', name: 'ECONOCOM INTERNATIONAL ITALIA SPA' },
	{ code: '105471', name: 'EDIMATICA' },
	{ code: '108806', name: 'ELABORAZIONE DATI PINEROLO SRL - PIXEL' },
	{ code: '493745', name: 'ELMEC INFORMATICA' },
	{ code: '105475', name: 'ENIWARE SRL' },
	{ code: '224444', name: 'EURONICS SEDE' },
	{ code: '224114', name: 'EXPERT ITALY SPA CONSORTILE' },
	{ code: '105479', name: 'FACCINCANI & GANDOLFI SRL' },
	{ code: '1781847', name: 'FILIPPETTI SPA' },
	{ code: '217451', name: 'FLAMINIA COMPUTER' },
	{ code: '274404', name: 'FOTOFORNITURE GUIDO SABATINI SPA' },
	{ code: '735434', name: 'FUTURA GRAFICA SPA' },
	{ code: '105482', name: 'GAMMA SNC DI PEPINO OSCAR & C.' },
	{ code: '224115', name: 'G.R.E. S.P.A' },
	{ code: '1331400', name: 'HERMÈS ITALIE SPA' },
	{ code: '2031971', name: 'HP ITALY S.R.L.' },
	{ code: '482806', name: 'I.T.STORE SRL' },
	{ code: '685997', name: 'IKORNER' },
	{ code: '108924', name: 'INFORMATICA BIELLA' },
	{ code: '55721', name: 'INFORMATICA COMMERCIALE' },
	{ code: '292644', name: 'KEYSTORE ONE' },
	{ code: '1974640', name: 'KRONOS INFORMATICA SRL' },
	{ code: '53053', name: 'KRONOS SRL' },
	{ code: '294064', name: 'L\'ALTRA MELA' },
	{ code: '1566973', name: 'L\’INNOMINATO S.P.A' },
	{ code: '105501', name: 'LOGOS INFORMATICA SRL' },
	{ code: '108933', name: 'MAC IN SRL' },
	{ code: '105510', name: 'MACHELP' },
	{ code: '55723', name: 'MAGELLANO SRL' },
	{ code: '55718', name: 'MAGNETIC MEDIA BUSTO ARSIZIO SRL' },
	{ code: '55720', name: 'MAGNETIC MEDIA NETWORK SPA' },
	{ code: '1598346', name: 'MAXIMA S.P.A.' },
	{ code: '2638065', name: 'MED COMPUTER SRL' },
	{ code: '54831', name: 'MEDIAMARKET SPA' },
	{ code: '432654', name: 'MISCO' },
	{ code: '98506', name: 'MOS80 SRL' },
	{ code: '345589', name: 'MYSTORE' },
	{ code: '105520', name: 'N.C.S. SISTEMI SNC' },
	{ code: '645519', name: 'N.P.U. GROUP SRL' },
	{ code: '282560', name: 'OPEN SOLUTIONS SRL' },
	{ code: '55711', name: 'ORMI COMPUTERS SRL' },
	{ code: '322607', name: 'R-STORE SPA' },
	{ code: '53050', name: 'RAFFO SRL' },
	{ code: '53080', name: 'SER DATA SRL' },
	{ code: '1121592', name: 'UNIEURO SPA' },
	{ code: '108807', name: 'SISTED SRL' },
	{ code: '108808', name: 'T.T. TECNOSISTEMI SPA' },
	{ code: '820498', name: 'TECNOELETTRONICA DI GRILLI FABIO' },
	{ code: '223355', name: 'THE STORE SRL' },
	{ code: '105557', name: 'TOP INK SRL - MAC & IDEA' },
	{ code: '53052', name: 'UNIT TREND S.R.L.' },
	{ code: '105560', name: 'UOMO COMPUTER VICENZA SRL' },
	{ code: '1439732', name: 'VAR GROUP' },
	{ code: '1587074', name: 'WINS S.R.L.' },
	{ code: '284343', name: 'CBC INFORMATIQUE SA' },
	{ code: '103881', name: 'LINEHEART' },
	{ code: '1805022', name: 'NTT LUXEMBOURG PSF SA' },
	{ code: '54025', name: '' },
	{ code: '223169', name: 'AMAC HQ' },
	{ code: '2633165', name: 'AMAZON EU SARL' },
	{ code: '1560810', name: 'ARDENSPORT B.V.' },
	{ code: '565370', name: 'ARP NEDERLAND B.V.' },
	{ code: '94510', name: 'BCC' },
	{ code: '405814', name: 'BOL.COM' },
	{ code: '229951', name: 'BOSE GP' },
	{ code: '254487', name: 'BRISK ICT' },
	{ code: '1282090', name: 'BTC' },
	{ code: '390433', name: 'CAMERANU.NL B.V.' },
	{ code: '746757', name: 'CAPI HQ' },
	{ code: '2108137', name: 'CDW NETHERLANDS IT SOLUTIONS' },
	{ code: '612655', name: 'COMPUTACENTER B.V.' },
	{ code: '212100', name: 'COOLBLUE' },
	{ code: '2086094', name: 'DE ROLF GROEP' },
	{ code: '1550436', name: 'DETRON ICT GROEP' },
	{ code: '1382550', name: 'DIXONS' },
	{ code: '423809', name: 'EYETI B.V.' },
	{ code: '475791', name: 'HEDERA KANTOOREFFICIENCY B.V.' },
	{ code: '2031979', name: 'HP NEDERLAND B.V.' },
	{ code: '1697911', name: 'HUDSON\'S BAY' },
	{ code: '105604', name: 'I-MOTION' },
	{ code: '1382556', name: 'ICENTRE' },
	{ code: '850862', name: 'ICENTRE' },
	{ code: '794616', name: 'CENTRAL POINT HOLDING B.V.' },
	{ code: '657079', name: 'INSIGHT ENTERPRISES NETHERLANDS B.V.' },
	{ code: '1291949', name: 'IVIZI HQ' },
	{ code: '1187416', name: 'KICK AUTOMATISERING B.V.' },
	{ code: '108951', name: 'LJS AUTOMATISERING BV' },
	{ code: '253966', name: 'MCPARTNERS' },
	{ code: '237167', name: 'MEDIA MARKT SATURN HOLDING NEDERLAND B.V.' },
	{ code: '464616', name: 'MISCO NEDERLAND BV' },
	{ code: '108960', name: 'NEG IT SOLUTIONS' },
	{ code: '1544714', name: 'NIKE HILVERSUM EMPLOYEE STORE' },
	{ code: '2039239', name: 'OFFICE CENTRE' },
	{ code: '462960', name: 'OFFICE CENTRE ALMERE' },
	{ code: '1983348', name: 'PRIMERA' },
	{ code: '457296', name: 'RADIO OBBINK' },
	{ code: '1026094', name: 'SKOOL AUTOMATISERING' },
	{ code: '551919', name: 'SMILE COMPUTERS' },
	{ code: '525439', name: 'SWITCH IT SOLUTIONS B.V.' },
	{ code: '1520446', name: 'TELECOMBINATIE' },
	{ code: '105633', name: 'THE FUTURE STORE' },
	{ code: '1036225', name: 'VAN DIJK EDUCATIE B.V.' },
	{ code: '223384', name: 'WEHKAMP' },
	{ code: '1291941', name: 'XANDO HQ / B2B LOCATION' },
	{ code: '2115198', name: 'ZETACOM B.V.' },
	{ code: '1279917', name: 'ZETACOM CREAFORTI B.V.' },
	{ code: '351344', name: 'ANDRE PARTNERE' },
	{ code: '577810', name: 'ATEA LOGISTICS AB' },
	{ code: '261793', name: 'ATEA AS' },
	{ code: '750568', name: 'COOP MIDT-NORGE SA' },
	{ code: '1486876', name: 'DUSTIN AB (NORWAY)' },
	{ code: '211490', name: 'DUSTIN NORWAY AS' },
	{ code: '229594', name: 'ELKJØP NORDIC AS NORWAY' },
	{ code: '109130', name: 'EPLEHUSET AS' },
	{ code: '236667', name: 'EURONICS NORGE AS' },
	{ code: '1063960', name: 'EVRY NORGE AS' },
	{ code: '408442', name: 'POWER INTERNATIONAL AS (NORWAY)' },
	{ code: '2031983', name: 'HP NORGE AS' },
	{ code: '55796', name: 'INFOGRAFIKK AS' },
	{ code: '525040', name: 'INGRAM MICRO /CO MOOBI NORWAY AS' },
	{ code: '1221067', name: 'INTERNATIONAL BUSINESS MACHINES AS' },
	{ code: '1439739', name: 'INTILITY AS' },
	{ code: '398315', name: 'IT PARTNER' },
	{ code: '666997', name: 'KOMPLETT SERVICES AS' },
	{ code: '539897', name: 'NORDIALOG' },
	{ code: '1772662', name: 'LIN EDUCATION AS' },
	{ code: '1025529', name: 'MOBIT NORGE AS' },
	{ code: '1522691', name: 'NETONNET AB' },
	{ code: '1556782', name: 'NETONNET, NORSK AVDELING AV UTENLANDSK FORETAG' },
	{ code: '805556', name: 'PAGE ONE' },
	{ code: '276449', name: 'PIPPIN AS' },
	{ code: '1561051', name: 'RIKT AS' },
	{ code: '678579', name: 'SERIT AS' },
	{ code: '1556482', name: 'SIBA NORGE FILIAL AV SIBA AB SVERIGE' },
	{ code: '1252444', name: 'SPACEWORLD SOUNDGARDEN AS' },
	{ code: '1759173', name: 'TECHSTEP ASA' },
	{ code: '1764397', name: 'TELENOR UCOM STAVANGER' },
	{ code: '765498', name: 'TELL' },
	{ code: '102820', name: 'VIDEO 4' },
	{ code: '1426726', name: 'VISOLIT NORWAY AS' },
	{ code: '604729', name: 'AUCHAN' },
	{ code: '1474791', name: 'CILNET' },
	{ code: '422304', name: 'COMPUTOVISEU' },
	{ code: '304486', name: 'DISTRILOC' },
	{ code: '272059', name: 'EL CORTE INGLES' },
	{ code: '368114', name: 'FNAC PORTUGAL, LDA' },
	{ code: '815660', name: 'GMS' },
	{ code: '389658', name: 'ILOOKSTORE, LDA' },
	{ code: '1558032', name: 'INCREDIBLE STRATEGY UNIPESSOAL, LDA' },
	{ code: '2319437', name: 'INFORMANTEM, S.A.' },
	{ code: '838775', name: 'ITEN SOLUTIONS' },
	{ code: '422302', name: 'LOJAS CONFORTO' },
	{ code: '1543190', name: 'MBIT COMPUTADORES E SERVIÇOS DE INFORMÁTICA, LDA' },
	{ code: '358841', name: 'MCSERVICE' },
	{ code: '303027', name: 'MEDIA-SATURN SERVIÇOS DE APOIO ADMN., LDA' },
	{ code: '422318', name: 'MEGATRONICA' },
	{ code: '422310', name: 'OFICINA DOS NEURÓNIOS' },
	{ code: '422309', name: 'PLACALOGICA-SUPORTE TÉCNICO INFORMÁTICO, LDA.' },
	{ code: '422728', name: 'POWERHOUR' },
	{ code: '272063', name: 'RADIO POPULAR' },
	{ code: '304488', name: 'RHPRO' },
	{ code: '422522', name: 'SCSI - SINERGIA, COMPANHIA DE SISTEMAS INFORMATICOS LDA' },
	{ code: '1498320', name: 'SUPRIDES XXI LDA' },
	{ code: '272061', name: 'WORTEN, EQUIPAMENTOS PARA O LAR, SA' },
	{ code: '2046492', name: 'AMAZON EU SARL' },
	{ code: '105667', name: 'AS COMPUTER SANTIAGO' },
	{ code: '108777', name: 'ASSECO' },
	{ code: '341107', name: 'AXEL SPRINGER ESPANA S.A.' },
	{ code: '55850', name: 'BANANA COMPUTER' },
	{ code: '1279911', name: 'BECHTLE, S.L.U.' },
	{ code: '343136', name: 'BENOTAC FUENCARRAL' },
	{ code: '1241519', name: 'BETA SYSTEM' },
	{ code: '105677', name: 'BURMAC' },
	{ code: '105679', name: 'CENTREGRAF EQUIPAMENTS TIC CATALUNYA S.L.' },
	{ code: '232374', name: 'CENTROS COMERCIALES CARREFOUR, S.A.' },
	{ code: '55935', name: 'COMPOLASER' },
	{ code: '1982331', name: 'CRYSTAL MEDIA SHOPS S.L.' },
	{ code: '1561964', name: 'DIAZ TAPIA S.L.' },
	{ code: '105690', name: 'DTP MURCIA' },
	{ code: '659957', name: 'ECOMPUTER' },
	{ code: '994554', name: 'ECONOCOM' },
	{ code: '55943', name: 'EL CORTE INGLÉS, S.A.' },
	{ code: '360845', name: 'EMAZING' },
	{ code: '445122', name: 'EROSKI,S.COOP' },
	{ code: '1560801', name: 'ESPORGAL S.L.' },
	{ code: '55873', name: 'FNAC ESPAÑA' },
	{ code: '55936', name: 'GESTIOMATICA' },
	{ code: '2096487', name: 'GOLDENMAC OFICINAS PISA' },
	{ code: '1497276', name: 'HERMÈS' },
	{ code: '2031994', name: 'HP PRINTING AND COMPUTING SOLUTIONS' },
	{ code: '227263', name: 'IDENDA' },
	{ code: '303923', name: 'INFORMATICA EL CORTE INGLES, S.A.' },
	{ code: '105711', name: 'INFOTEC' },
	{ code: '2525825', name: 'INTECAT ISTORE SABADELL' },
	{ code: '55881', name: 'K-TUIN' },
	{ code: '691923', name: 'MECANORBA CACERES' },
	{ code: '213444', name: 'MEDIAMARKT SATURN ADMINISTRACION ESPANA S.A.U.' },
	{ code: '55939', name: 'MERCOR' },
	{ code: '55858', name: 'MICROGESTIO' },
	{ code: '105738', name: 'MICROTOOLS' },
	{ code: '629800', name: 'MISCO' },
	{ code: '1544717', name: 'NIKE EUROPE - NIKE NEDERLAND' },
	{ code: '736345', name: 'PISTA CERO' },
	{ code: '1412697', name: 'RANDOM' },
	{ code: '228400', name: 'REDDER - SEGESA' },
	{ code: '554889', name: 'ROSSELLIMAC GRANADA' },
	{ code: '1513981', name: 'SEMIC' },
	{ code: '560774', name: 'SERINGE S.A.' },
	{ code: '616989', name: 'SICOS' },
	{ code: '239878', name: 'SINERSIS ESTRATEGIAS DE DISTRIBUCION S.A.' },
	{ code: '304609', name: 'TELEFÓNICA SERVICIOS AUDIOVISUALES' },
	{ code: '1464119', name: 'TXESMIKA, S.A.' },
	{ code: '92198', name: 'WORTEN ESPAÑA DISTRIBUCION,S.L' },
	{ code: '105782', name: 'WOZNIAK SOLUCIONES' },
	{ code: '659751', name: 'ADVANIA AB' },
	{ code: '1945805', name: 'ADVANIA SVERIGE AB' },
	{ code: '368135', name: 'ATEA SVERIGE AB' },
	{ code: '717415', name: 'AV SYD AB' },
	{ code: '253035', name: 'B2B IT-PARTNER AB' },
	{ code: '388636', name: 'BK DATA' },
	{ code: '458783', name: 'CENTILJON IT CONSULTING AB' },
	{ code: '341072', name: 'CLAS OHLSON AB' },
	{ code: '527327', name: 'DATABYRÅN' },
	{ code: '55652', name: 'DD DATA DISTRIBUTION AB' },
	{ code: '385065', name: 'DIALECT AB' },
	{ code: '108878', name: 'DICOM DATAUTVECKLING AB' },
	{ code: '2667568', name: 'DIGITAL INN' },
	{ code: '818682', name: 'DIGITAL INN AB' },
	{ code: '216563', name: 'DUSTIN AKTIEBOLAG' },
	{ code: '237215', name: 'ELECTRA SWEDEN AKTIEBOLAG' },
	{ code: '229942', name: 'ELKJØP NORDIC AS (SWEDEN)' },
	{ code: '224682', name: 'EURONICS AB' },
	{ code: '1475973', name: 'EVRY SWEDEN AB' },
	{ code: '55671', name: 'MACFORUM AB' },
	{ code: '890761', name: 'INVID GRUPPEN AB' },
	{ code: '542042', name: 'ITF SVENSK IT FUNKTION AB' },
	{ code: '1037050', name: 'JANSSONDATA' },
	{ code: '342521', name: 'KISEL STOCKHOLM AB' },
	{ code: '2081354', name: 'KJELL & COMPANY' },
	{ code: '288643', name: 'KOMPLETT SERVICES SWEDEN AB' },
	{ code: '555646', name: 'KONEO AB' },
	{ code: '108871', name: 'KULLANDER I SVERIGE AB' },
	{ code: '280614', name: 'LAN-ASSISTANS SWEDEN AB' },
	{ code: '286348', name: 'LIN EDUCATION' },
	{ code: '293401', name: 'LOP' },
	{ code: '1683155', name: 'MACHUSET AB' },
	{ code: '113562', name: 'MACRENT - HYRCENTRALEN' },
	{ code: '1290467', name: 'MACSUPPORT AB' },
	{ code: '240570', name: 'MEDIA-SATURN NORDIC SHARED SERVICES AB' },
	{ code: '209300', name: 'MEDIATEKNIK I SKÖVDE AB' },
	{ code: '1235926', name: 'MISCO AB' },
	{ code: '1522683', name: 'NETONNET AB' },
	{ code: '580170', name: 'NORDLO SERVICES AB' },
	{ code: '347110', name: 'RICOH SVERIGE AB' },
	{ code: '109580', name: 'SIBA' },
	{ code: '615512', name: 'STJÄRNA FYRKANT COM SYSTEM AB' },
	{ code: '2522305', name: 'TECHSTEP SWEDEN AB' },
	{ code: '271830', name: 'TELENOR SVERIGE AB' },
	{ code: '1678764', name: 'WEBHALLEN SVERIGE AB' },
	{ code: '52669', name: 'A & F SYSTEMS AG' },
	{ code: '554437', name: 'ANYKEY IT AG' },
	{ code: '52671', name: 'ART COMPUTER HEADQUARTER' },
	{ code: '52667', name: 'AV INFORMATICA SAGL' },
	{ code: '648956', name: 'BECHTLE HOLDING SCHWEIZ HEADQUARTER' },
	{ code: '943787', name: 'BRACK.CH AG' },
	{ code: '108493', name: 'COMAC NEUCHATEL' },
	{ code: '109100', name: 'COMPU-TRADE WYSS' },
	{ code: '1539397', name: 'CONFORAMA DIRECTION SA HQ' },
	{ code: '108505', name: 'CROPMARK AG' },
	{ code: '52731', name: 'CSG COMPUTERSHOP SA' },
	{ code: '52733', name: 'DATA QUEST HQ AND CORPORATE BUSINESS' },
	{ code: '1671495', name: 'DATA QUEST ICT SOLUTIONS AG' },
	{ code: '254084', name: 'DGSOLUTIONS SARL' },
	{ code: '368724', name: 'DOS GROUP SA' },
	{ code: '92224', name: 'FNAC HEADQUARTER' },
	{ code: '238596', name: 'FUST HEADQUARTER' },
	{ code: '105834', name: 'HEINIGER UNTERNEHMENSBERATUNG AG' },
	{ code: '1331394', name: 'HERMES (SUISSE) SA' },
	{ code: '2031937', name: 'HP INTERNATIONAL SÀRL' },
	{ code: '2031938', name: 'HP SCHWEIZ GMBH' },
	{ code: '227774', name: 'INTERDISCOUNT HAUPTSITZ' },
	{ code: '368907', name: 'ITEN INFORMATIK UNTERAEGERI' },
	{ code: '105837', name: 'L\'AGE DU SOFT SA' },
	{ code: '288589', name: 'M-ELECTRONICS ZUERICH HEADQUARTER' },
	{ code: '105851', name: 'MAGIKS SERVICES INFORMATIQUE SARL' },
	{ code: '107842', name: 'MANOR BASEL HEADQUARTER' },
	{ code: '221436', name: 'MEDIA MARKT HEADQUARTER' },
	{ code: '345857', name: 'MEGAHERTZ COMPUTER SA' },
	{ code: '2087576', name: 'MEMOIRE VIVE S.A.' },
	{ code: '632370', name: 'OCOM AG' },
	{ code: '1093310', name: 'POST CH AG HEADQUARTER' },
	{ code: '111678', name: 'POWER SOLUTIONS GMBH' },
	{ code: '105866', name: 'PUMPKIN' },
	{ code: '105880', name: 'TOMAC COMPUTER CONCEPT' },
	{ code: '1698667', name: 'VALORA SCHWEIZ AG HQ' },
	{ code: '108494', name: 'YMAC', name: ' SV & MAC YVERDON' },
	{ code: '218126', name: 'AA MAC LIMITED' },
	{ code: '366735', name: 'ACADEMIA LIMITED' },
	{ code: '1124414', name: 'ACCENTURE (UK) LIMITED' },
	{ code: '463210', name: 'ACS' },
	{ code: '237019', name: 'AMAZON EU SARL' },
	{ code: '95200', name: 'AMSYS' },
	{ code: '1733434', name: 'AO.COM' },
	{ code: '213940', name: 'ARGOS LTD' },
	{ code: '233916', name: 'ASDA STORES LTD' },
	{ code: '300381', name: 'BE VALUED LIMITED' },
	{ code: '535734', name: 'BECHTLE' },
	{ code: '663173', name: 'BRITISH TELECOMMUNICATIONS PLC' },
	{ code: '562627', name: 'CAPITA' },
	{ code: '238300', name: 'CCS MEDIA LTD' },
	{ code: '542394', name: 'CDW LIMITED' },
	{ code: '1576500', name: 'CENTERPRISE INTERNATIONAL LIMITED' },
	{ code: '105906', name: 'CLOCKWORK CREATIVE TECHNOLOGY LTD' },
	{ code: '54787', name: 'COLYER LONDON' },
	{ code: '1526588', name: 'COMPUB' },
	{ code: '209430', name: 'COMPUTACENTER' },
	{ code: '54916', name: 'DSG RETAIL LTD' },
	{ code: '113153', name: 'EDUCATION LINCS LTD' },
	{ code: '435102', name: 'EUROPEAN ELECTRONIQUE LTD' },
	{ code: '106403', name: 'FARPOINT' },
	{ code: '109057', name: 'FLAME LIMITED' },
	{ code: '54857', name: 'GBM DIGITAL TECHNOLOGIES LTD' },
	{ code: '1122146', name: 'GENISYS GROUP' },
	{ code: '109345', name: 'GRAFX' },
	{ code: '109020', name: 'GUERNSEY COMPUTERS LTD' },
	{ code: '300206', name: 'HARDSOFT COMPUTERS' },
	{ code: '2095730', name: 'HARRODS' },
	{ code: '1331433', name: 'HERMÈS' },
	{ code: '106404', name: 'HI-TEC BRADFORD LTD' },
	{ code: '2112182', name: 'HMV' },
	{ code: '2031665', name: 'HP INC UK LIMITED' },
	{ code: '97366', name: 'IANSYST LTD' },
	{ code: '279360', name: 'ICONOLOGY LTD' },
	{ code: '220968', name: 'INSIGHT DIRECT (UK) LTD' },
	{ code: '1748884', name: 'IQ' },
	{ code: '529912', name: 'IQ' },
	{ code: '46981', name: 'ISTORE STRAND' },
	{ code: '453506', name: 'IT LAB LIMITED' },
	{ code: '106405', name: 'JENNINGS COMPUTER SERVICES LTD' },
	{ code: '54837', name: 'JIGSAW24' },
	{ code: '54286', name: 'JOHN LEWIS PLC' },
	{ code: '268309', name: 'JTRS LTD' },
	{ code: '217952', name: 'KAIZEN' },
	{ code: '54804', name: 'KRCS' },
	{ code: '612691', name: 'LEAF' },
	{ code: '395308', name: 'LETS CONNECT IT SOLUTIONS LIMITED' },
	{ code: '209093', name: 'MAC SYS LTD' },
	{ code: '54797', name: 'MCC GROUP' },
	{ code: '278657', name: 'MISCO' },
	{ code: '54798', name: 'MR ISLINGTON' },
	{ code: '106575', name: 'NEXUS GS LTD' },
	{ code: '1544704', name: 'NIKE EUROPE - NIKE NEDERLAND' },
	{ code: '2003418', name: 'ONECOM' },
	{ code: '452257', name: 'PARAGON COMPUTER SERVICES LTD' },
	{ code: '291224', name: 'POWERHOUSE' },
	{ code: '213473', name: 'PROBRAND LIMITED' },
	{ code: '213076', name: 'RM EDUCATION LTD' },
	{ code: '109030', name: 'ROBERT HARDING (COMPUTERS) LTD' },
	{ code: '211355', name: 'RUBICON WORKFLOW SOLUTIONS LIMITED' },
	{ code: '214082', name: 'S L ELECTROTECH (INSTRUMENTATION) LTD' },
	{ code: '243449', name: 'SAINSBURY\'S SUPERMARKETS LTD' },
	{ code: '113440', name: 'SERVICEWEB LTD' },
	{ code: '2683085', name: 'SHI CORPORATION UK LIMITED' },
	{ code: '347000', name: 'SHOP DIRECT' },
	{ code: '294174', name: 'SOFTCAT PLC' },
	{ code: '509914', name: 'SOFTWARE BOX LTD' },
	{ code: '1296836', name: 'SOLUTIONS INC' },
	{ code: '54853', name: 'SOLUTIONS INC LTD' },
	{ code: '455014', name: 'SPECIALIST COMPUTER CENTRES (SCC)' },
	{ code: '440222', name: 'STONE' },
	{ code: '294684', name: 'STORM TECHNOLOGIES LTD' },
	{ code: '241317', name: 'STORMFRONT' },
	{ code: '277563', name: 'STORMFRONT RETAIL LTD' },
	{ code: '651525', name: 'SYSTEMS SUPPORT' },
	{ code: '1654335', name: 'TANGIBLE BENEFIT LIMITED' },
	{ code: '229369', name: 'TESCO STORES LIMITED' },
	{ code: '109515', name: 'THE DIGITAL GARAGE GROUP LIMITED' },
	{ code: '101410', name: 'TOUCAN COMPUTING LTD' },
	{ code: '54678', name: 'TRAMS LIMITED' },
	{ code: '2110876', name: 'TRANSPUTEC' },
	{ code: '651578', name: 'TRANSPUTEC COMPUTERS PLC' },
	{ code: '2432045', name: 'TRUSTMARQUE' },
	{ code: '105969', name: 'TWILIGHT ZONE ENGINEERING LTD' },
	{ code: '52699', name: 'VIEWDATA COMPUTING LTD' },
	{ code: '687171', name: 'VOHKUS LTD' },
	{ code: '54681', name: 'WESTERN COMPUTER BRISTOL' },
	{ code: '1361940', name: 'WH SMITH' },
	{ code: '387794', name: 'XMA LIMITED' },
	{ code: '1574099', name: 'ZONES (UK) LTD' },
];
export default COMPANIES;
