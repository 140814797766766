import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import BaseModule from "../BaseModule";
import Styles from "./MaskSliderModule.module.scss";
import { createMarkup } from "../../../utils";
import Context from "../../../context/Context";
import ReplayButton from "../../ReplayButton";
import Hotspot from "../../Hotspot";

let draggingControl = false

export default function MaskSliderModule(props) {
  const { module, variant, introAudioFinished, progress, chapter } = props;
  const context = useContext(Context);
  const { setCanAdvance, setForceDefaultCursor, language, languageID, siteID } = context;
  const replaceCopyTitleNotifier = "";
  const replaceCopyBodyNotifier = "";
  const overRef = useRef()
  const overMaskRef = useRef()
  const underRef = useRef()
  const underMaskRef = useRef()
  const draggableRef = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const [interacted, setInteracted] = useState(false)
  const forceCanAdvanceTrue = progress && progress[siteID].some((e) => e.chapter === chapter.id && e.moduleID === module.id)

  const handleMouseEnter = () => {
    setForceDefaultCursor(true)
  }

  const handleMouseLeave = () => {
    if (!isDragging) setForceDefaultCursor(false)
  }

  function moveDraggable(event) {
    setInteracted(true)
    const fullWidth = underRef.current.getBoundingClientRect().width
    const width = event.clientX - overRef.current.getBoundingClientRect().left

    if (width < fullWidth && width > 2) {
      const index = 15

      const overWidth = width > (fullWidth / 2) + index ? width : (width < (fullWidth / 2) - index ? width : fullWidth / 2)
      const left = width > (fullWidth / 2) + index ? `${event.clientX}px` : (width < (fullWidth / 2) - index ? `${event.clientX}px` : `50%`)

      overRef.current.style.width = `${overWidth}px`
      draggableRef.current.style.left = left

      const half = fullWidth / 2
      const delta = half / 2

      const overOpacity = (width - half) / delta
      const underOpacity = (half - width) / delta

      overMaskRef.current.style.opacity = overOpacity > 0 ? ( (overOpacity < 1) ? overOpacity : 1 ) : 0
      underMaskRef.current.style.opacity = underOpacity > 0 ? ( (underOpacity < 1) ? underOpacity : 1 ) : 0
    }
  }

  function mouseMoveEvent(event) {
    if (draggingControl) {
      moveDraggable(event)
    }
  }

  function touchMoveEvent(event) {
    moveDraggable(event.touches[0])
  }

  function startDragging() {
    draggingControl = true
    setIsDragging(true)
  }

  function stopDragging() {
    draggingControl = false
    setIsDragging(false)
  }

  function resizeImg(imgWidth) {
    const width = imgWidth / 2
    overRef.current.style.width = `${width}px`
    draggableRef.current.style.left = `50%`
    overMaskRef.current.style.opacity = 0
    underMaskRef.current.style.opacity = 0
  }

  function onResize() {
    resizeImg(underRef.current.getBoundingClientRect().width)
  }

  function startEvents() {
    document.addEventListener('pointermove', mouseMoveEvent)
    document.addEventListener('pointerup', stopDragging)
    document.addEventListener('touchend', stopDragging)
    window.addEventListener('resize', onResize)
    window.addEventListener('mousemove', mouseMoveEvent, false)
    window.addEventListener('touchmove', touchMoveEvent)
  }

  function stopEvents() {
    document.removeEventListener('pointermove', mouseMoveEvent)
    document.removeEventListener('pointerup', stopDragging)
    document.removeEventListener('touchend', stopDragging)
    window.removeEventListener('resize', onResize)
    window.removeEventListener('mousemove', mouseMoveEvent, false)
    window.removeEventListener('touchmove', touchMoveEvent)
  }

  useEffect(() => {
    if (forceCanAdvanceTrue) setCanAdvance(true)

    var img = document.createElement('img')
    img.onload = function() { 
      const width = window.innerWidth > this.width ? this.width : window.innerWidth
      resizeImg(width)
    }
    img.src = underRef.current.querySelector('img').src

    startEvents()
    return () => { stopEvents() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (introAudioFinished && interacted) setCanAdvance(true)
    else if (!forceCanAdvanceTrue) setCanAdvance(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introAudioFinished])

  useEffect(() => {
    if (interacted && introAudioFinished) setCanAdvance(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interacted])
  
  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        "MaskSliderModule"
      )}
    >
      <div className={Styles.container}>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.title]
              ? language[module.content.title]
              : replaceCopyTitleNotifier
          )}
        />
        <div className={`${Styles.text} ${languageID==='JA_JP' ? Styles.left : null}`}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.text]
                ? language[module.content.text]
                : replaceCopyBodyNotifier
            )}
          />
        </div>

        <ReplayButton
          audioObject={module.content.voiceover}
          attachToGlobalCanAdvance
        />

        <div className={Styles.maskSliderContainer}>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={Styles.draggable}
            ref={draggableRef}
            >
            <button
              onPointerDown={startDragging}
              onTouchStart={startDragging}
              className={`${Styles.hotspot} ${ isDragging ? Styles.dragging : '' }`}
            >
              <Hotspot />
            </button>
            <img className={Styles.hotspotArrows} src={`${process.env.PUBLIC_URL}/assets/images/maskSlider-arrows-hotspot.svg`} alt={'draggable hotspot arrows'} />
            <img className={Styles.line} src={`${process.env.PUBLIC_URL}/assets/images/draggable-line.png`} alt={'draggable line'} />
          </div>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={Styles.maskSlider}
            >
            <div className={Styles.over} ref={overRef}>
              <div className={Styles.overlay} ref={overMaskRef}>
                <img src={`${process.env.PUBLIC_URL}${module.content.maskSlider.over.mask}`} alt={'power beats pro mask'} />
              </div>
              <img src={`${process.env.PUBLIC_URL}${module.content.maskSlider.over.img}`} alt={'power beats pro case'} />
            </div>
            <div className={Styles.under} ref={underRef}>
              <div className={Styles.overlay} ref={underMaskRef}>
                <img src={`${process.env.PUBLIC_URL}${module.content.maskSlider.under.mask}`} alt={'beats fit pro mask'} />
              </div>
              <img src={`${process.env.PUBLIC_URL}${module.content.maskSlider.under.img}`} alt={'beats fit pro case'} />
            </div>
          </div>
        </div>
      </div>
    </BaseModule>
  );
}
