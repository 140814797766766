import React, { useEffect, useContext } from 'react';
import Context from '../../context/Context';
import * as ROUTES from '../../constants/routes';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as SITEDATA from '../../constants';

import TextModule from '../../components/Module/TextModule';
// import AudioControl from '../AudioControl';
import Styles from './welcome.module.scss';

const WelcomeBack = (props) => {
	const { firebase, authUser } = props;

	const context = useContext(Context);
	const { setOverRideChapter, setOverRideNextButton, setUnmuteOnNextButtonClick ,overRideNextButton, siteID} = context;
	const hasMouseInput = window.matchMedia("(any-hover: hover)").matches;
	const SETTINGS = siteID
    ? SITEDATA[`${siteID}_SETTINGS`]
    : SITEDATA[`studioBuds_SETTINGS`];

	useEffect(() => {
		setUnmuteOnNextButtonClick(true);
		return () => {
			setUnmuteOnNextButtonClick(false);
		};
	}, [setUnmuteOnNextButtonClick]);

	// console.log('==========================welcomePgE props', props);
	// console.log('==========================welcomePgE authUSER', authUser);

	firebase
		.user(authUser.uid)
		.get()
		.then((snapshot) => {
			// console.log("welcome back, authuser",snapshot.val())
			// console.log("welcome back siteID",siteID)
			const lastVisited = snapshot.val().lastVisited;
			if (lastVisited === 0) {
				//THIS IS A NEW USER, GET HER OUT OF HERE.  SHE SHOULDNT SEE WELCOMBACK!
				props.history.push(ROUTES.HOME);
			}
			//   GO TO LAST VISITED OR GO TO INTRO
			setOverRideNextButton(
				lastVisited ? `${process.env.PUBLIC_URL}/${lastVisited[siteID].chapter}/${lastVisited[siteID].moduleID}` : ROUTES.INTRO
			);
			// history.push(homePath)
			// props.history.push(homePath);
		})
		.catch((error) => {
			console.log("welcome back catch error",error)
			// props.history.push(ROUTES.INTRO);
			// props.history.push(ROUTES.HOME);
		});
	setOverRideChapter('Global_Intro');
	useEffect(() => {
		firebase.doSetParameter('welcomeViewed', true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firebase]);
	useEffect(() => {
		return setOverRideChapter(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chapter = {
		id: 'welcome',
		title: 'welcome',
	};
	const module = {	
		id: 'welcome',
		title: 'welcome',
		nextChapter: '/intro',
		replaceNextButtonContent: 'Global_ProductSelector_CTA_2',
		content: {
			eyebrow: null,
			text: 'Intro_Welcome_ReturningUser',
		},
	};

	if (SETTINGS.backgroundWelcomeBackScreen) {
		module[SETTINGS.backgroundWelcomeBackScreen.prop] = SETTINGS.backgroundWelcomeBackScreen.value
	}
	// console.log("overRideNextButton",overRideNextButton)
	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.25, ease: 'easeOut' }}
			className={Styles.base}
			>
			<div className={Styles.welcome} />
			<div>
				<TextModule chapter={chapter} module={module} {...props} />
				{!hasMouseInput && (
					<Context.Consumer>
						{({ language }) => {
							return (
								<Link to={overRideNextButton} className="ctaButton centerBottom">
									{language[module.replaceNextButtonContent]}
								</Link>
							)
						}}
					</Context.Consumer>
				)}
			</div>
		</motion.div>
	);
};

export default withFirebase(WelcomeBack);
