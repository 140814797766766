const copy = {
	Global_UI_Next: '다음',
Global_UI_Begin: '시작',
Global_UI_Back: '뒤로',
Global_UI_Settings: '설정',
Global_UI_Signout: '로그아웃',
Global_UI_NextChapter: '다음 장',
Global_UI_PercentageComplete: '<<number>>% 완료',
Global_UI_Replay: '다시 재생',
Global_Field_Location: '위치',
Global_Languages_Select: '언어 선택:',
Global_Field_EnterEmail: '이메일 주소를 입력하세요',
Global_Field_CreatePassword: '암호를 생성하세요',
Global_Field_Password: '암호',
Global_Field_Password_Forgot: '암호를 잊어버림',
Global_Password_Subtitle: '암호는 8자 이상으로 대문자, 소문자, 숫자, 기호를 포함해야 합니다.',
Global_Password_Change: '변경',
Global_Password_Reset_Headline: '암호 재설정',
Global_Password_Reset_Subtitle: '암호 재설정 방법에 대한 지침을 받으려면 이메일을 입력하세요.',
Global_Password_Reset_Confirm: '발송되었습니다! 이메일을 확인하세요.',
Global_Password_ChangeConfirm: '암호가 업데이트되었습니다',
Global_Password_Show: '보기',
Global_Password_Hide: '가리기',
Global_Details_Title: '추가 세부 사항',
Global_Details_Field_FirstName: '이름',
Global_Details_Field_LastName: '성',
Global_Details_Field_EmployeeID: '직원 ID(선택 사항)',
Global_Details_Field_BusinessRole: '직무',
Global_Details_Field_BusinessRole1: '매니저',
Global_Details_Field_BusinessRole2: '판매 담당자',
Global_Details_Field_BusinessRole3: '강사',
Global_Details_Field_CompanyName: '회사 이름:',
Global_Details_Field_Country: '국가',
Global_Field_Password_Error: '입력한 암호가 잘못되었습니다. 다시 시도해 주세요.',
Global_Field_Email_Error: '유효하지 않은 이메일 주소를 입력했습니다. 다시 시도해 주세요.',
Global_Field_Details_Error: '모든 필수 필드를 입력해 주세요.',
Global_Greeting: '<<name>> 님, 안녕하세요.',
Global_CompletionMessage: '<<number>>% 완료되었습니다.',
Global_Chapter: '<<number>>장',
 Global_Chapter_Subtitle: '<<number>>장/<<total>>',
Global_Intro: '',
Global_ChapterTitle_Sound: '사운드',
Global_ChapterTitle_ANC: 'ANC',
Global_ChapterTitle_Transparency: '주변음 허용 모드',
Global_ChapterTitle_Design: '디자인',
Global_ChapterTitle_Compatibility: '호환성',
Global_ChapterTitle_Controls: '컨트롤',
Global_ChapterTitle_Battery: '배터리',
Global_Menu: '메뉴',
Intro_Welcome_Label: '사용 경험',
Intro_Welcome_ChapterText: 'Beats Studio Buds 사용 경험을 시작합니다. 클릭하거나 탭하여 알아보세요.',
CR_TRNR_Intro_Sorry: '죄송합니다. 문제가 발생했습니다. 관리자에게 새 접근 링크를 문의하세요.',
Intro_Intro_Subtitle: 'Beats Studio Buds 소개. 스튜디오급 사운드를 제공하는 진정한 무선 노이즈 캔슬링 이어버드. 클릭하거나 탭하여 살펴보세요.',
Sound_Intro_Title: '사운드',
Sound_Intro_VOSubtitle: '향상된 엔지니어링을 통해 강력하고 균형 잡힌 사운드를 선사합니다.',
Sound_ChapterText1: '훌륭한 사운드는 <em>편안한 착용감과 어쿠스틱 씰</em>에서 시작됩니다.',
Sound_FindYourFit_Title: '다양한 이어팁으로 음악을 느껴라.',
Sound_FindYourFit_Subtitle: '세 가지 크기의 실리콘 이어팁으로 사용자에게 꼭 맞는 착용감을 선사하고 사운드에 몰입할 수 있는 어쿠스틱 씰을 형성합니다.',
Sound_FindYourFit_Medium: '중간 길이(기본값)',
Sound_FindYourFit_Small: '소형',
Sound_FindYourFit_Large: '대형',
Sound_ChapterText2: 'Beats Studio Buds는 <em>완전 맞춤형 어쿠스틱 플랫폼</em>을 통해 콤팩트한 디자인으로  <em>강력하고 균형 잡힌 사운드</em>를 선사하도록 설계되었습니다.',
Sound_DrivenByPremiumSound_Title: '프리미엄 사운드 지원',
Sound_DrivenByPremiumSound_Subtitle: '전용 이중 요소 진동판 드라이버를 채택한 맞춤형 어쿠스틱 플랫폼이 선명하고 균형 잡힌 사운드를 선사합니다.',
Sound_MoreMusicLessDistortion_Title: '선명한 사운드. 왜곡이 적은 사운드.',
Sound_MoreMusicLessDistortion_Subtitle: '진동판 드라이버의 유연한 재질은 진동 왜곡을 줄여 전체 스펙트럼, 특히 저음역대에서 선명하고 정확한 사운드를 선사합니다.',
Sound_MoreMusicLessDistortion_Diaphragm: '진동판',
Sound_FineTunedAudio_Title: '정교하게 튜닝된 오디오',
Sound_FineTunedAudio_Subtitle: '재생 보정 기능을 갖춘 디지털 프로세서가 실시간으로 반응하여 외부 사운드가 음악의 선명함이나 감성에 영향을 미치지 않도록 합니다.',
Sound_ChapterText3: '<em>고품질 통화 성능과 더욱 선명한 음성</em>으로 전화 통화가 또렷하게 들립니다. ',
Sound_SpeakingOfSound_Title: '고품질 통화 성능 ',
Sound_SpeakingOfSound_Subtitle: '특별 설계된 포트가 마이크에서 외부 바람 소리를 분리하고 듀얼 빔포밍 마이크가 사용자의 음성만 잡아내 또렷한 음질을 제공합니다.',
Sound_Summary_Title: '아티스트가 의도한 대로 음악을 전달하도록 설계',
Sound_Summary1_ThreeEartip: '세 가지 이어팁 옵션으로 사용자에게 꼭 맞는 착용감을 선사하면서 최고의 사운드를 제공합니다.',
Sound_Summary2_CustomAcoustic: '맞춤형 어쿠스틱 플랫폼이 고품질의 강력한 사운드를 선사합니다.',
Sound_Summary3_FlexibleDriverDiaphragm: '유연한 진동판 드라이버가 선명하고 정확한 사운드를 선사합니다.',
Sound_Summary4_FinetunedAudio: '정교하게 튜닝된 오디오가 음악의 선명함과 감성을 유지합니다.',
Sound_Summary5_SpeakingOfSound: '듀얼 빔포밍 마이크가 사용자의 음성만 잡아내면서 고품질 통화 성능을 제공합니다.',
ANC_Intro_TITLE: '액티브 노이즈 캔슬링 ',
ANC_Intro_VOSubtitle: '액티브 노이즈 캔슬링 기능으로 끊김 없이 감상하고 소음을 효과적으로 차단하세요.',
ANC_ChapterText1: '<em>편안한 착용감과 어쿠스틱 씰</em>이 외부 소음을 차단하여 진정 몰입감 넘치는 청취 경험을 제공합니다.',
ANC_Seal_Title: '어쿠스틱 씰',
ANC_Seal_Subtitle: '세 가지 크기 중 편안한 착용감을 제공하는 이어팁을 선택하여 원치 않는 소음을 차단하는 어쿠스틱 씰을 형성합니다.',
ANC_ChapterText2: '적응형 기술이 적용된 ANC는 <em>주변 환경에 맞게 조정되며</em> 재생 보정 기능은 <em>음악의 선명함과 감성을 유지합니다.</em>',
ANC_ANCThatAdapts_Title: '적응형 ANC',
ANC_ANCThatAdapts_Subtitle: '미세 조정 필터가 원치 않는 소음을 동적으로 조정하여 필터링하고 실시간으로 노이즈 캔슬링 기능을 최적화합니다.',
ANC_ANCThatAdapts_AdaptiveANC: '적응형 ANC',
ANC_Playback_Title: '재생 보정',
ANC_Playback_Subtitle: '노이즈 캔슬링이 적용되면 음악을 방해하지 않도록 재생 보정 기능이 해당 오디오 파일을 초당 48,000회 원본으로 다시 매핑합니다.',
ANC_Playback_ANCProcess: 'ANC 프로세스',
ANC_Playback_PlaybackCorrection: '재생 보정',
ANC_UseCases_Subtitle: '각 사용 사례를 클릭하거나 탭하여 자세히 알아보세요. ',
ANC_UseCases_Studying: '학습',
ANC_UseCases_Studying_Subtitle: '공부에 집중할 수 있도록 소음을 차단합니다',
ANC_UseCases_WorkingOut: '운동',
ANC_UseCases_WorkingOut_Subtitle: '방해 요소 없이 운동에 몰입할 수 있습니다',
ANC_UseCases_Airplane: '비행기',
ANC_UseCases_Airplane_Subtitle: '승객들의 소리나 엔진 소음을 줄여 비행을 즐길 수 있습니다',
ANC_UseCases_Commute: '통근',
ANC_UseCases_Commute_Subtitle: '통근 길에 도시의 배경 소음을 줄일 수 있습니다',
ANC_Summary_Title: 'ANC로 끊김 없이 집중합니다.',
ANC_Summary1_ImmerseSound: '몰입감 넘치는 사운드는 편안한 착용감과 어쿠스틱 씰에서 시작됩니다.',
ANC_Summary2_AdaptiveTechnology: '적응형 기술이 지속적으로 주변 환경에 맞게 조정합니다.',
ANC_Summary3_PlaybackCorrection: '재생 보정 기능이 선명한 사운드를 유지합니다.',
ANC_Summary4_UseCases: 'ANC를 사용하여 공부  운동, 비행기 여행 또는 통근에 집중할 수 있습니다.',
Transparency_Intro_Title: '주변음 허용 모드 ',
Transparency_Intro_VOSubtitle: '주변음 허용 모드로 주변의 소음을 들을 수 있습니다.',
Transparency_ChapterText1: '<em>주변의 소음을 들어야</em> 할 때는 주변음 허용 모드로 쉽게 전환합니다.',
Transparency_TransparencyMode_Title: '주변음 허용 모드 ',
Transparency_TransparencyMode_Subtitle: '주변음 허용 모드는 주변의 소리를 감상 중인 음악과 혼합하여 자연스럽고 \'개방적인\' 청취 경험을 제공합니다.',
Transparency_TransparencyMode_PressHold: '길게 누르기',
Transparency_ChapterText2: '<em>외장 마이크</em>를 통해 외부 소음을 듣습니다.',
Transparency_FeedForwardMic_Title: '피드 포워드 마이크',
Transparency_FeedForwardMic_Subtitle: '각 이어버드의 피드 포워드 마이크가 주변 환경의 외부 소음을 감지합니다.',
Transparency_FeedForwardMic_FeedForwardMic: '피드 포워드 마이크',
Transparency_UseCases_Subtitle: '각 사용 사례를 클릭하거나 탭하여 자세히 알아보세요. ',
Transparency_UseCases_Office: '사무실',
Transparency_UseCases_Office_Subtitle: '음악을 들으면서 직장에서 계속 협업하고 동료의 이야기를 듣습니다.',
Transparency_UseCases_Travel: '여행',
Transparency_UseCases_Travel_Subtitle: '공항 게이트나 기차 플랫폼에서 중요한 안내 방송을 듣습니다.',
Transparency_UseCases_Street: '길거리',
Transparency_UseCases_Street_Subtitle: '주변의 교통 상황과 번잡한 교차로를 인식할 수 있습니다.',
Transparency_Summary_Title: '주변음 허용 모드로 주변의 소음을 들을 수 있습니다.',
Transparency_Summary1_MixTheSounds: '주변의 소리를 감상 중인 음악과 혼합합니다.',
Transparency_Summary2_FeedForward: '피드 포워드 마이크가 주변 환경의 외부 소음을 감지합니다.',
Transparency_Summary3_HearYourSurroundings: '여행 중일 때 또는 사무실이나 거리에서 주변의 소리를 듣습니다.',
Design_Intro_Title: '디자인',
Design_Intro_VOSubtitle: 'Beats Studio Buds는 진정한 무선의 자유를 만끽하도록 디자인되었습니다.',
Design_ChapterText1: '무선의 콤팩트한 디자인으로 <em>일상 생활에 자연스럽게 융화됩니다.</em>',
Design_WirelessFreedom_Title: '무선으로 누리는 자유 ',
Design_WirelessFreedom_Subtitle: '무선의 자유로움을 선사하는 진정한 무선 이어버드. ',
Design_PocketSizedPortability_Titles: '포켓 크기의 뛰어난 휴대성',
Design_PocketSizedPortability_Subtitles: '슬림하고 가벼운 충전 케이스는 뛰어난 휴대성을 제공하며 주머니에 바로 넣을 수 있습니다.',
Design_WaterAndSweatResistant_Title: '땀과 수분에 강한 이어버드*  ',
Design_WaterAndSweatResistant_Subtitle: 'IPX-4 등급의 땀과 수분에 강한 이어버드는 어떤 요소에도 문제없이 사용할 수 있습니다.',
Design_WaterAndSweatResistant_Disclaimer: '* Beats Studio Buds는 비수상 스포츠 및 운동용으로, 땀과 수분에 강합니다.',
Design_ChapterText2: 'Beats Studio Buds는 <em>착용감이 가장 좋은 크기를 고를 수 있도록</em> 광범위한 사용자를 대상으로 테스트를 거쳤습니다.',
Design_Comfort_Title: '편안한 디자인',
Design_Comfort_Subtitle: '부드러운 실리콘 이어버드는 귀의 해부학적 구조를 바탕으로 디자인되어 편안함과 안정성, 안정감을 제공합니다.',
Design_Comfort_Tragus: '귀구슬',
Design_Comfort_Antitragus: '맞구슬',
Design_SizeMatters_Title: '콤팩트한 디자인',
Design_SizeMatters_Subtitle: '세련되고 콤팩트한 인이어 디자인은 오늘날 대부분의 무선 이어버드보다 훨씬 가볍고 작습니다.',
Design_SizeMatters_BeatsStudio: 'BEATS STUDIO BUDS',
Design_SizeMatters_BeatsStudio_10_1G: '10.1g',
Design_SizeMatters_Bose: 'Bose QC 이어버드',
Design_SizeMatters_Bose_18_7G: '18.7g',
Design_SizeMatters_Sony: 'Sony WF-1000XM3',
Design_SizeMatters_Sony_16_5G: '16.5g',
Design_SizeMatters_Samsung: '삼성 갤럭시 버즈 프로',
Design_SizeMatters_Samsung_12_5G: '12.5g',
Design_ChapterText3: '이어버드는 하루 종일 휴대할 수 있도록 설계된 편리한 충전 케이스에서 <em>안전하게 보호</em>됩니다.',
Design_PerfectMatch_Title: '자석 위치',
Design_PerfectMatch_Subtitle: '충전 케이스 내부의 자석은 이어버드가 항상 올바른 위치에 놓이도록 배치되어 있습니다.',
Design_Contact_Title: '정밀한 접촉',
Design_Contact_Subtitle: '케이스 내부의 포고 핀에는 이어버드와 케이스가 정밀하게 접촉할 수 있도록 탄력적인 판 스프링이 사용되므로 충전을 위해 이어버드를 올바르게 장착할 수 있습니다.',
Design_Contact_PogoPin: '포고 핀',
Design_FindYourMatch_Title: '디자인 매치 ',
Design_FindYourMatch_Subtitle: '동일 색상의 충전 케이스',
Design_FindYourMatch_White: '화이트',
Design_FindYourMatch_Black: '블랙',
Design_FindYourMatch_BeatsRed: 'Beats 레드',
Design_SustainablePackaging_Title: '지속 가능한 포장',
Design_SustainablePackaging_Subtitle: 'Beats Studio Buds 포장지는 재활용 펄프 및 지속 가능 산림에서 공급하는 92%의 식물성 소재로 제작됩니다.',
Design_Summary_Title: '진정한 무선의 자유를 위한 디자인',
Design_Summary1_WirelessFreedom: '무선의 자유로움을 선사하는 진정한 무선 이어버드. ',
Design_Summary2_PocketSizedPortability: '주머니에 쉽게 들어가는 가벼운 충전 케이스.',
Design_Summary3_WaterAndSweatResistant: 'IPX-4 등급의 땀과 수분에 강한 이어버드<sup>*</sup>.',
Design_Summary4_Comfort: '편안함과 안정성을 제공하는 세련되고 콤팩트한 이어버드.',
Design_Summary5_SizeMatters: '대부분의 무선 이어버드보다 훨씬 가볍고 작은 인이어 디자인.',
Design_Summary6_PerfectMatch: '이어버드가 항상 올바른 위치에 놓이도록 배치된 충전 케이스 내부의 자석.',
Design_Summary7_Contact: '충전을 위해 이어버드를 제대로 장착할 수 있도록 돕는 케이스 내부의 포고 핀.',
Design_Summary8_FindYourMatch: '블랙, 화이트, Beats 레드 색상으로 제공되는 동일 색상의 충전 케이스.',
Compatibility_Intro_Title: '호환성',
Compatibility_Intro_VOSubtitle: 'Beats Studio Buds는 Apple 및 안드로이드 기기 모두와 호환됩니다.',
Compatibility_ChapterText1: '<em>Apple과 안드로이드 기기 모두에서</em> 손쉽게 사용할 수 있습니다.',
Compatibility_OneTouch_Title: '원터치 페어링 ',
Compatibility_OneTouch_Subtitle: '간단한 원터치 페어링으로 Bluetooth를 통해 Apple<sup>1</sup> 또는 안드로이드<sup>2</sup> 기기를 쉽게 연결할 수 있습니다. ',
Compatibility_OneTouch_Disclaimer: '1 원터치 페어링을 사용하려면 iOS 14.6 이상 버전이 필요합니다. <br/>2 Fast Pair를 사용하려면 위치를 활성화해야 합니다. 기능을 모두 사용하려면 Android 6.0 이상 버전의 기기에서 활성화된 Google Play 서비스 및 Google 계정이 필요합니다. ',
Compatibility_VoiceAssistant_Title: '음성 인식 ',
Compatibility_VoiceAssistant_Subtitle: 'Beats Studio Buds는 사용자가 선호하는 음성 인식과 호환되며 맞춤형 \'길게 누르기\' 기능으로 활성화할 수 있습니다.',
Compatibility_LocateDevice_Title: '기기 찾기 ',
Compatibility_LocateDevice_Subtitle: 'iOS의 \'나의 찾기\' 기능과 안드로이드의 \'나의 기기 찾기\' 기능을 사용하여 이어버드를 쉽게 찾을 수 있습니다. ',
Compatibility_USBCCharging_Title: 'USB-C 충전 ',
Compatibility_USBCCharging_Subtitle: 'USB-C 충전 커넥터는 Apple과 안드로이드에서 모두 사용 가능하므로 어디서든 충전할 수 있습니다. ',
Compatibility_ManageDevice_Title: '기기 관리 ',
Compatibility_ManageDevice_Subtitle: 'Apple 기기 또는 안드로이드용 Beats 앱을 통해 \'길게 누르기\' 사용자화, 감상 모드, 제품 이름 변경, 펌웨어 업데이트와 같은 기능을 관리합니다.',
Compatibility_Summary_Title: 'Apple 및 안드로이드 기기와 호환 가능.',
Compatibility_Summary1_OneTouch: 'iOS 및 안드로이드 모두에서 작동하는 원터치 페어링.',
Compatibility_Summary2_VoiceAssistant: '선호하는 음성 인식 기능을 활성화하여 통화 및 음악 등을 관리할 수 있습니다.',
Compatibility_Summary3_LocateDevice: '기기를 사용하여 이어버드를 쉽게 찾을 수 있습니다.',
Compatibility_Summary4_USBCCharging: 'USB-C 충전 커넥터는 범용 충전을 제공합니다.',
Compatibility_Summary5_ManageDevice: 'iOS 또는 안드로이드용 Beats 앱을 통해 기기를 관리합니다.',
Control_Intro_Title: '컨트롤',
Control_Intro_VOSubtitle: 'Beats Studio Buds로 하루 종일 사운드를 제어하세요.',
Control_ChapterText1: '<em>컨트롤을 통해 간편하게 조정하여</em> 사운드를 관리하세요.',
Control_OnEarControls_Title: '온이어 컨트롤 ',
Control_OnEarControls_Subtitle: '온이어 컨트롤로 통화 및 음악을 관리하고, 감상 모드를 전환하고, 음성 인식 기능을 활성화할 수 있습니다.<sup>*</sup>',
Control_OnEarControls_Disclaimer: '* 길게 누르기 기능을 사용자화하려면 iOS 14.6 이상 버전을 실행하는 iPhone 또는 최신 버전의 Beats 앱이 설치된 안드로이드 기기가 필요합니다.',
Control_OnEarControls_Instruction: '각 컨트롤을 클릭하거나 탭하여 계속 진행하세요.',
Control_OnEarControls_PlayPause: '버튼을 눌러 음악 재생, 일시 정지 또는 전화 받기/끊기 ',
Control_OnEarControls_Skip: '버튼을 두 번 눌러 트랙 건너뛰기 ',
Control_OnEarControls_Previous: '버튼을 세 번 눌러 이전 트랙 재생하기 ',
Control_OnEarControls_ScanForward: '버튼을 두 번 길게 눌러 앞으로 스캔하기 ',
Control_OnEarControls_ScanBackward: '버튼을 세 번 길게 눌러 뒤로 스캔하기 ',
Control_OnEarControls_ToggleModes: '버튼을 길게 눌러 감상 모드 전환 및 끄기 또는 사용자화하여 음성 인식 기능 활성화하기<sup>*</sup>',
Control_PickASide_Title: '원하는 쪽에 설정',
Control_PickASide_Subtitle: 'iOS 또는 안드로이드용 Beats 앱을 사용하여 각 이어버드에서 \'길게 누르기\' 기능을 사용자화하여 ANC 또는 음성 인식 지원을 활성화하도록 지정할 수 있습니다.<sup>*</sup>',
Control_PickASide_Disclaimer: '* 길게 누르기 기능을 사용자화하려면 iOS 14.6 이상 버전을 실행하는 iPhone 또는 최신 버전의 Beats 앱이 설치된 안드로이드 기기가 필요합니다.',
Control_SayItWithSiri_Title: 'Siri에게 요청하기 ',
Control_SayItWithSiri_Subtitle: 'Siri를 통해 핸즈프리로 음악 감상, 통화 관리, 음량 조절 등 원하는 작업을 수행할 수 있습니다.<sup>*</sup>',
Control_SayItWithSiri_Disclaimer: '* Siri는 일부 언어와 지역에서는 지원되지 않으며, 지역에 따라 기능이 다를 수 있습니다. 인터넷 연결이 필요하며, 이동통신 데이터 요금이 부과될 수 있습니다. ',
Control_SayItWithSiri_SiriCommand1: '“Siri야, 공부할 때 듣는 플레이리스트 틀어줘.”',
Control_SayItWithSiri_SiriCommand2: '“Siri야, 근처에 여성이 운영하는 매장을 보여줘.”',
Control_SayItWithSiri_SiriCommand3: '“Siri야, 타이머를 10분으로 설정해줘.”',
Control_Summary_Title: '온이어 컨트롤로 사운드를 관리합니다.',
Control_Summary1_OnEarControls: '온이어 컨트롤로 통화와 음악을 관리할 수 있습니다.',
Control_Summary2_PickASide: '양쪽 이어버드에서 \'길게 누르기\' 기능을 사용자화할 수 있습니다.',
Control_Summary3_SayItWithSiri: '핸즈프리로 Siri를 이용합니다.',
Battery_Intro_Title: '배터리',
Battery_Intro_VOSubtitle: '하루 종일 지속되는 배터리로 온종일 플레이하세요.',
Battery_ChapterText1: '하루 종일 활동할 수 있도록 <em>온종일 지속되는 배터리</em>.',
Battery_FastFuel_Title: '최대 8시간',
Battery_FastFuel_Subtitle: '고효율 칩으로 구동되는 각각의 이어버드에서 최대 8시간 동안 감상할 수 있습니다.<sup>*</sup>',
Battery_FastFuel_Disclaimer: '* 배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.',
Battery_20Hours_Title: '최대 24시간',
Battery_20Hours_Subtitle: '포켓 크기의 휴대용 케이스를 통해 추가 2회 충전이 가능합니다. 따라서 최대 24시간 재생이 가능합니다.<sup>*</sup>',
Battery_20Hours_Disclaimer: '* 배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.',
Battery_FuelYourDay_Title: 'Fast Fuel 충전 기술',
Battery_FuelYourDay_Subtitle: '배터리 잔량이 없을 때 Fast Fuel 기능으로 5분만 충전해도 1시간 재생이 가능합니다.<sup>*</sup>',
Battery_FuelYourDay_Disclaimer: '* 배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.',
Battery_UseCases_Subtitle: '각 사용 사례를 클릭하거나 탭하여 자세히 알아보세요. ',
Battery_UseCases_Listen: '음악 감상',
Battery_UseCases_Listen_Subtitle: '좋아하는 라디오 프로그램을 12주 동안 들어 보세요.<sup>*</sup>',
Battery_UseCases_Listen_Disclaimer: '*2시간의 감상 세션 기준',
Battery_UseCases_Meditate: '명상',
Battery_UseCases_Meditate_Subtitle: '빗소리 명상으로 32번 휴식을 취하세요.<sup>*</sup>',
Battery_UseCases_Meditate_Disclaimer: '*45분의 세션 기준',
Battery_UseCases_Game: '게임',
Battery_UseCases_Game_Subtitle: '좋아하는 게임을 24번 플레이하세요.<sup>*</sup>',
Battery_UseCases_Game_Disclaimer: '*마이크를 사용하지 않아도 되는 1시간의 게임 세션 기준',
Battery_UseCases_Commute: '통근',
Battery_UseCases_Commute_Subtitle: '24일 동안 통근 중에 즐기세요.<sup>*</sup>',
Battery_UseCases_Commute_Disclaimer: '* 1시간 통근 기준. 배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.',
Battery_WhatsInTheBox_Title: '제품 구성',
Battery_WhatsInTheBox_Subtitle: 'Beats Studio Buds에는 포켓 크기의 충전 케이스, 범용 USB-C 충전 커넥터, 세 가지 크기 중에서 선택할 수 있는 이어팁이 함께 제공됩니다.',
Batter_Summary_Title: '하루 종일 지속되는 배터리.',
Battery_Summary1_PowerYourDay: '이어버드는 최대 8시간의 감상 시간을 제공합니다.',
Battery_Summary2_UpTo24: '최대 24시간 재생이 가능합니다.',
Battery_Summary3_FastFuel: 'Fast Fuel 기능으로 1시간 추가 재생이 가능합니다.',
Battery_Summary4_UseCases: '하루 종일 지속되는 배터리로 음악을 감상하고, 명상하고, 게임을 즐기세요.',
Battery_Summary5_WhatsInTheBox: '포켓 크기의 충전 케이스, USB-C 충전 커넥터, 세 가지 크기의 이어팁이 함께 제공됩니다.',
Menu_Sound_Module1: '사운드 소개',
Menu_Sound_Module2: '안정적인 착용감에서 시작되는 훌륭한 사운드',
Menu_Sound_Module3: '세 가지 크기의 이어팁',
Menu_Sound_Module4: '강력하고 균형 잡힌 사운드',
Menu_Sound_Module5: '맞춤형 어쿠스틱 플랫폼',
Menu_Sound_Module6: '선명한 사운드. 왜곡이 적은 사운드. ',
Menu_Sound_Module7: '정교하게 튜닝된 오디오',
Menu_Sound_Module8: '전화 통화 ',
Menu_Sound_Module9: '고품질 통화 성능',
Menu_Sound_Module10: '사운드 요약',
Menu_ANC_Module1: 'ANC 소개',
Menu_ANC_Module2: '어쿠스틱 씰',
Menu_ANC_Module3: '씰에서 시작',
Menu_ANC_Module4: '기술',
Menu_ANC_Module5: '적응형 ANC',
Menu_ANC_Module6: '재생 보정',
Menu_ANC_Module7: 'ANC 사용 사례',
Menu_ANC_Module8: 'ANC 요약',
Menu_Transparency_Module1: '주변음 허용 모드 소개',
Menu_Transparency_Module2: '주변 소리 듣기',
Menu_Transparency_Module3: '주변음 허용 모드 ',
Menu_Transparency_Module4: '외장 마이크',
Menu_Transparency_Module5: '피드 포워드 마이크',
Menu_Transparency_Module6: '주변음 허용 모드 사용 사례',
Menu_Transparency_Module7: '주변음 허용 모드 요약',
Menu_Design_Module1: '디자인 소개',
Menu_Design_Module2: '무선의 콤팩트한 디자인',
Menu_Design_Module3: '무선의 자유',
Menu_Design_Module4: '포켓 크기의 뛰어난 휴대성',
Menu_Design_Module5: '땀과 수분에 강함',
Menu_Design_Module6: '착용감이 가장 좋은 크기',
Menu_Design_Module7: '편안한 디자인',
Menu_Design_Module8: '콤팩트한 디자인',
Menu_Design_Module9: '편리한 충전 케이스',
Menu_Design_Module10: '자석 위치',
Menu_Design_Module11: '정밀한 접촉',
Menu_Design_Module12: '색상',
Menu_Design_Module13: '디자인 요약',
Menu_Compatibility_Module1: '호환성 소개',
Menu_Compatibility_Module2: 'Apple 및 안드로이드 기기와 호환',
Menu_Compatibility_Module3: '원터치 페어링',
Menu_Compatibility_Module4: '음성 인식',
Menu_Compatibility_Module5: '기기 찾기 ',
Menu_Compatibility_Module6: 'USB-C 충전 ',
Menu_Compatibility_Module7: '기기 관리',
Menu_Compatibility_Module8: '호환성 요약',
Menu_Control_Module1: '컨트롤 소개',
Menu_Control_Module2: '간편한 이용',
Menu_Control_Module3: '온이어 컨트롤',
Menu_Control_Module4: '원하는 쪽에 설정',
Menu_Control_Module5: 'SIRI에게 요청하기 ',
Menu_Control_Module6: '컨트롤 요약',
Menu_Battery_Module1: '배터리 소개',
Menu_Battery_Module2: '하루 종일 지속되는 배터리',
Menu_Battery_Module3: '8시간의 감상 시간',
Menu_Battery_Module4: '최대 24시간 재생',
Menu_Battery_Module5: 'FAST FUEL',
Menu_Battery_Module6: '배터리 사용 사례',
Menu_Battery_Module7: '제품 구성',
Menu_Battery_Module8: '배터리 요약',
Outro_ChapterText1: '액티브 노이즈 캔슬링 기능, 주변음 허용 모드, 프리미엄 사운드, 무선, <em>포켓 크기의 디자인을 갖춘 Beats Studio Buds는 일상 생활에 자연스럽게 융화됩니다.</em>',
IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
Global_Country_ALBANIA: '알바니아',
Global_Country_ALGERIA: '알제리',
Global_Country_ANDORRA: '안도라',
Global_Country_ANGOLA: '앙골라',
Global_Country_ARGENTINA: '아르헨티나',
Global_Country_ARMENIA: '아르메니아',
Global_Country_ARUBA: '아루바',
Global_Country_AUSTRALIA: '오스트레일리아',
Global_Country_AUSTRIA: '오스트리아',
Global_Country_AZERBAIJAN: '아제르바이잔',
Global_Country_BAHAMAS: '바하마',
Global_Country_BAHRAIN: '바레인',
Global_Country_BANGLADESH: '방글라데시',
Global_Country_BARBADOS: '바베이도스',
Global_Country_BELARUS: '벨라루스',
Global_Country_BELGIUM: '벨기에',
Global_Country_BOLIVIA: '볼리비아',
Global_Country_BOSNIAHERZRGOVINA: '보스니아 헤르체고비나',
Global_Country_BOTSWANA: '보츠와나',
Global_Country_BRAZIL: '브라질',
Global_Country_BRUNEI: '브루나이 다루살람',
Global_Country_BULGARIA: '불가리아',
Global_Country_CAMBODIA: '캄보디아',
Global_Country_CAMEROON: '카메룬',
Global_Country_CANADA: '캐나다',
Global_Country_CAYMANISLANDS: '케이맨 제도',
Global_Country_CHILE: '칠레',
Global_Country_CHINA : '중국(본토)',
Global_Country_COLOMBIA: '콜롬비아',
Global_Country_COOKISLANDS: '쿡 제도',
Global_Country_COSTARICA: '코스타리카',
Global_Country_CROATIA: '크로아티아',
Global_Country_CYPRUS: '키프로스',
Global_Country_CZECHREPUBLIC: '체코 공화국',
Global_Country_DENMARK: '덴마크',
Global_Country_DOMINICANREPUBLIC: '도미니카 공화국',
Global_Country_ECUADOR: '에콰도르',
Global_Country_EGYPT: '이집트',
Global_Country_ELSALVADOR: '엘살바도르',
Global_Country_ESTONIA: '에스토니아',
Global_Country_FAROEISLANDS: '페로 제도',
Global_Country_FIJI: '피지',
Global_Country_FINLAND: '핀란드',
Global_Country_FRANCE: '프랑스',
Global_Country_FRENCHGUIANA: '프랑스령 기아나',
Global_Country_FRENCHPOLYNESIA: '프랑스령 폴리네시아',
Global_Country_GEORGIA: '조지아',
Global_Country_GERMANY: '독일',
Global_Country_GHANA: '가나',
Global_Country_GIBRALTAR: '지브롤터',
Global_Country_GREECE: '그리스',
Global_Country_GUADELOUPE: '과들루프',
Global_Country_GUAM: '괌',
Global_Country_GUATEMALA: '과테말라',
Global_Country_GUERNSEY: '건지',
Global_Country_GUINEA: '기니',
Global_Country_GUYANA: '가이아나',
Global_Country_HONDURAS: '온두라스',
Global_Country_HONGKONG: '홍콩',
Global_Country_HUNGARY: '헝가리',
Global_Country_ICELAND: '아이슬란드',
Global_Country_INDIA: '인도',
Global_Country_INDONESIA: '인도네시아',
Global_Country_IRAQ: '이라크',
Global_Country_IRELAND: '아일랜드',
Global_Country_ISLEOFMAN: '맨섬',
Global_Country_ISRAEL: '이스라엘',
Global_Country_ITALY: '이탈리아',
Global_Country_IVORYCOAST: '코트디부아르',
Global_Country_JAPAN: '일본',
Global_Country_JERSEY: '저지',
Global_Country_JORDAN: '요르단',
Global_Country_KAZAKHSTAN: '카자흐스탄',
Global_Country_KENYA: '케냐',
Global_Country_KOREA: '대한민국',
Global_Country_KUWAIT: '쿠웨이트',
Global_Country_KYRGYZSTAN: '키르기스스탄',
Global_Country_LAOS: '라오스',
Global_Country_LATVIA: '라트비아',
Global_Country_LEBANON: '레바논',
Global_Country_LESOTHO: '레소토',
Global_Country_LITHUANIA: '리투아니아',
Global_Country_LUXEMBOURG: '룩셈부르크',
Global_Country_MACAU: '마카오',
Global_Country_MACEDONIA: '마케도니아',
Global_Country_MADAGASCAR: '마다가스카르',
Global_Country_MALAWI: '말라위',
Global_Country_MALAYSIA: '말레이시아',
Global_Country_MALDIVES: '몰디브',
Global_Country_MALTA: '몰타',
Global_Country_MARTINIQUE: '마르티니크',
Global_Country_MAURITIUS: '모리셔스',
Global_Country_MAYOTTE: '마요트',
Global_Country_MEXICO: '멕시코',
Global_Country_MOLDOVA: '몰도바',
Global_Country_MONACO: '모나코',
Global_Country_MONTENEGRO: '몬테네그로',
Global_Country_MOROCCO: '모로코',
Global_Country_MOZAMBIQUE: '모잠비크',
Global_Country_MYANMAR: '미얀마',
Global_Country_NAMIBIA: '나미비아',
Global_Country_NEPAL: '네팔',
Global_Country_NETHERLANDS: '네덜란드',
Global_Country_NEWCALEDONIA: '뉴칼레도니아',
Global_Country_NEWZEALAND: '뉴질랜드',
Global_Country_NICARAGUA: '니카라과',
Global_Country_NIGERIA: '나이지리아',
Global_Country_NORWAY: '노르웨이',
Global_Country_OMAN: '오만',
Global_Country_PAKISTAN: '파키스탄',
Global_Country_PALESTINIANTERRITORY: '팔레스타인',
Global_Country_PANAMA: '파나마',
Global_Country_PARAGUAY: '파라과이',
Global_Country_PERU: '페루',
Global_Country_PHILIPPINES: '필리핀',
Global_Country_POLAND: '폴란드',
Global_Country_PORTUGAL: '포르투갈',
Global_Country_PUERTORICO: '푸에르토리코',
Global_Country_QATAR: '카타르',
Global_Country_REUNION: '레위니옹',
Global_Country_ROMANIA: '루마니아',
Global_Country_RUSSIA: '러시아',
Global_Country_SAINTMARTIN: '생마르탱',
Global_Country_SANMARINO: '산마리노',
Global_Country_SAUDIARABIA: '사우디아라비아',
Global_Country_SENEGAL: '세네갈',
Global_Country_SERBIA: '세르비아',
Global_Country_SINGAPORE: '싱가포르',
Global_Country_SINTMAARTEN: '신트마르턴',
Global_Country_SLOVAKIA: '슬로바키아',
Global_Country_SLOVENIA: '슬로베니아',
Global_Country_SOUTHAFRICA: '남아프리카 공화국',
Global_Country_SPAIN: '스페인',
Global_Country_SRILANKA: '스리랑카',
Global_Country_SWEDEN: '스웨덴',
Global_Country_SWITZERLAND: '스위스',
Global_Country_TAIWAN: '대만',
Global_Country_TAJIKISTAN: '타지키스탄',
Global_Country_TANZANIA: '탄자니아',
Global_Country_THAILAND: '태국',
Global_Country_TRINIDADTOBAGO: '트리니다드 토바고',
Global_Country_TUNISIA: '튀니지',
Global_Country_TURKEY: '터키',
Global_Country_TURKMENISTAN: '투르크메니스탄',
Global_Country_UGANDA: '우간다',
Global_Country_UKRAINE: '우크라이나',
Global_Country_UAE: '아랍에미리트',
Global_Country_UK: '영국',
Global_Country_US: '미국',
Global_Country_URUGUAY: '우루과이',
Global_Country_UZBEKISTAN: '우즈베키스탄',
Global_Country_VENEZUELA: '베네수엘라',
Global_Country_VIETNAM: '베트남',
Global_Country_ZAMBIA: '잠비아',
Global_Country_ZIMBABWE: '짐바브웨',
Global_Menu_Confirm: '확인',
Global_Menu_Help: '도움이 필요하신가요? <u>문의하기</u>',
Global_Menu_Restart: '사용 경험 재시작',
IntroOutro_TestYourKnowledge: 'Beats Studio Buds에 대한 지식을 테스트하세요.',
IntroOutro_CTA: '퀴즈 풀기',
Global_Menu_Language: '언어',
Global_Password_Rules_Header: '암호는 다음을 포함해야 합니다.',
Global_Password_Rule_Length: '최소 8자',
Global_Password_Rule_Uppercase: '대문자',
Global_Password_Rule_Lowercase: '소문자',
Global_Password_Rule_Number: '숫자',
Global_Password_Rule_Special: '특수 문자(!@#$%^&*)',
Global_Field_Password_CreateError: '암호가 최소 요구 사항을 충족하지 않습니다.',


Global_ProductSelector_Headline: "다음 Beats TRNR 경험 중 하나를 선택하세요.",
Global_ProductSelector_Product1: "Beats Studio Buds",
Global_ProductSelector_Product2: "Beats Fit Pro",
Global_ProductSelector_CTA_1: "시작하기",
Global_ProductSelector_CTA_2: "다시 시작하기",
Global_ProductSelector_CTA_3: "다시 시작하기",
Global_WelcomeInstructions_Headline: "Beats 전문가로 만들어드리는 Beats TRNR에 오신 것을 환영합니다.",
Global_WelcomeInstructions_Subhead: "다음을 숙지하시기 바랍니다.",
Global_WelcomeInstructions_Body1: "각 교육 경험을 완료하는 데 약 15분이 소요되어야 합니다.",
Global_WelcomeInstructions_Body2: "각 교육 경험은 청취를 필요로합니다. 사운드가 켜져 있는지 확인합니다.",
Global_WelcomeInstructions_Body3: "각 교육 경험에서 다음으로 넘어가기 전에 각 세션을 먼저 완료해야 한다는 점을 기억하시기 바랍니다.",
Global_WelcomeInstructions_Body4: "잠시 자리를 비워야 해도 걱정할 필요는 없습니다. 중단한 부분부터 언제든 다시 이어 할 수 있습니다.",
Intro_Welcome_ReturningUser: "중단한 부분부터 다시 시작 할까요? 클릭 또는 탭하여 계속해서 Beats Fit Pro에 대해 알아보세요.",
FitPro_Menu_Chapter1: "1장",
FitPro_Menu_Chapter2: "2장",
FitPro_Menu_Chapter3: "3장",
FitPro_Menu_Chapter4: "4장",
FitPro_Menu_Chapter5: "5장",
FitPro_Menu_Chapter6: "6장",
FitPro_Menu_Chapter7: "7장",
FitPro_Menu_ChapterTitle_Fit: "착용감",
FitPro_Menu_ChapterTitle_Sound: "사운드",
FitPro_Menu_ChapterTitle_ListeningModes: "감상 모드",
FitPro_Menu_ChapterTitle_Controls: "컨트롤",
FitPro_Menu_ChapterTitle_AppleH1Chip: "Apple H1 칩",
FitPro_Menu_ChapterTitle_Design: "디자인",
FitPro_Menu_ChapterTitle_Battery: "배터리",
Global_Menu_Button_Restart: "경험 재시작하기",
Global_Menu_Button_SelectProduct: "다른 제품 선택하기",
Global_Menu_Button_Contact: "도움이 필요하신가요? 문의하기",
FitPro_Menu_ChapterTitle_Fit_1: "유연한 착용감",
FitPro_Menu_ChapterTitle_Fit_2: "안정적으로 착용할 수 있는<br/>유연한 윙팁",
FitPro_Menu_ChapterTitle_Fit_3: "하루 종일 지속되는 편안함",
FitPro_Menu_ChapterTitle_Fit_4: "범용 핏",
FitPro_Menu_ChapterTitle_Fit_5: "소재",
FitPro_Menu_ChapterTitle_Fit_6: "뛰어난 착용감",
FitPro_Menu_ChapterTitle_Fit_7: "3개의 이어팁",
FitPro_Menu_ChapterTitle_Fit_8: "착용 테스트",
FitPro_Menu_ChapterTitle_Fit_9: "어쿠스틱 씰",
FitPro_Menu_ChapterTitle_Fit_10: "착용감 요약",
FitPro_Menu_ChapterTitle_Sound_1: "맞춤형 드라이버",
FitPro_Menu_ChapterTitle_Sound_2: "드라이버 크기",
FitPro_Menu_ChapterTitle_Sound_3: "플리티드 드라이버",
FitPro_Menu_ChapterTitle_Sound_4: "최적화된 공기 흐름",
FitPro_Menu_ChapterTitle_Sound_5: "왜곡이 적은 사운드",
FitPro_Menu_ChapterTitle_Sound_6: "탁월한 사운드",
FitPro_Menu_ChapterTitle_Sound_7: "공간 음향",
FitPro_Menu_ChapterTitle_Sound_8: "공간 음향 사용 사례",
FitPro_Menu_ChapterTitle_Sound_9: "3개의 마이크",
FitPro_Menu_ChapterTitle_Sound_10: "사운드 요약",
FitPro_Menu_ChapterTitle_Modes_1: "3가지 감상 모드",
FitPro_Menu_ChapterTitle_Modes_2: "액티브 노이즈 캔슬링",
FitPro_Menu_ChapterTitle_Modes_3: "외향 마이크",
FitPro_Menu_ChapterTitle_Modes_4: "안티 노이즈",
FitPro_Menu_ChapterTitle_Modes_5: "내향 마이크",
FitPro_Menu_ChapterTitle_Modes_6: "액티브 노이즈 캔슬링 사용 사례",
FitPro_Menu_ChapterTitle_Modes_7: "주변음 허용 모드",
FitPro_Menu_ChapterTitle_Modes_8: "주변 상황 인식",
FitPro_Menu_ChapterTitle_Modes_9: "외부 마이크",
FitPro_Menu_ChapterTitle_Modes_10: "주변음 허용 모드 사용 사례",
FitPro_Menu_ChapterTitle_Modes_11: "적응형 EQ",
FitPro_Menu_ChapterTitle_Modes_12: "맞춤형 소음 차단",
FitPro_Menu_ChapterTitle_Modes_13: "감상 모드 요약",
FitPro_Menu_ChapterTitle_Controls_1: "온이어 버튼",
FitPro_Menu_ChapterTitle_Controls_2: "눌러서 재생",
FitPro_Menu_ChapterTitle_Controls_3: "개별 이어버드 연결성",
FitPro_Menu_ChapterTitle_Controls_4: "오디오 및 감상 모드 컨트롤",
FitPro_Menu_ChapterTitle_Controls_5: "감상 모드 전환",
FitPro_Menu_ChapterTitle_Controls_6: "온이어 컨트롤",
FitPro_Menu_ChapterTitle_Controls_7: "원하는 쪽에 설정",
FitPro_Menu_ChapterTitle_Controls_8: "안드로이드 호환성",
FitPro_Menu_ChapterTitle_Controls_9: "컨트롤 요약",
FitPro_Menu_ChapterTitle_H1_1: "매끄러운 <nobr>Apple 연동</nobr>",
FitPro_Menu_ChapterTitle_H1_2: "APPLE H1 칩",
FitPro_Menu_ChapterTitle_H1_3: "원터치 페어링",
FitPro_Menu_ChapterTitle_H1_4: "Siri야",
FitPro_Menu_ChapterTitle_H1_5: "자동 전환",
FitPro_Menu_ChapterTitle_H1_6: "오디오 공유",
FitPro_Menu_ChapterTitle_H1_7: "나의 기기 찾기",
FitPro_Menu_ChapterTitle_H1_8: "APPLE H1 칩 요약",
FitPro_Menu_ChapterTitle_Design_1: "충전 케이스",
FitPro_Menu_ChapterTitle_Design_2: "포켓에 쏙 들어가는 사이즈",
FitPro_Menu_ChapterTitle_Design_3: "50% 작아진 크기",
FitPro_Menu_ChapterTitle_Design_4: "스타일에 어울리는 색상",
FitPro_Menu_ChapterTitle_Design_5: "간편한 휴대를 위한 디자인",
FitPro_Menu_ChapterTitle_Design_6: "무선의 자유",
FitPro_Menu_ChapterTitle_Design_7: "개별 이어버드 연결성",
FitPro_Menu_ChapterTitle_Design_8: "비, 땀, 햇빛에 강함",
FitPro_Menu_ChapterTitle_Design_9: "디자인 요약",
FitPro_Menu_ChapterTitle_Battery_1: "오래 지속되는 감상 시간",
FitPro_Menu_ChapterTitle_Battery_2: "최대 24시간 재생",
FitPro_Menu_ChapterTitle_Battery_3: "FAST FUEL",
FitPro_Menu_ChapterTitle_Battery_4: "USB-C 충전",
FitPro_Menu_ChapterTitle_Battery_5: "제품 구성",
FitPro_Menu_ChapterTitle_Battery_6: "배터리 요약",
FitPro_Intro_Title: 'Beats<br/>Fit Pro',
FitPro_Intro_Subtitle: "Beats Fit Pro를 소개합니다. 온종일 활동을 위해 제작된 진정한 무선 노이즈 캔슬링 이어버드입니다. 클릭 또는 탭하여 살펴보세요.",
FitPro_Fit_Title: "착용감",
FitPro_Fit_Intro_VOSubtitle: "Beats Fit Pro는 온종일 편안하고 안정적으로 착용하도록 디자인되었습니다.",
FitPro_Fit_ChapterText1: "<em>귀에 꼭 맞는 유연한 착용감</em>을 선사하는 편안하고 안정적인 핏의 윙팁 탑재.",
FitPro_Fit_FlexibleWingtip_Title: "유연하고 안정적인 착용감의 윙팁",
FitPro_Fit_FlexibleWingtip_Subtitle: "Beats Fit Pro 제작에서 가장 큰 우선순위는 윙팁 디자인을 완벽하게 구현하는 것이었습니다. Beats는 연구를 통해 거의 모든 귀 형태에 완벽하게 고정되는 윙팁 개발의 핵심은 각 사용자 고유의 귀 해부학에 맞춰 적응하는 것임을 알아냈습니다.",
FitPro_Fit_AllDay_Title: "하루 종일 지속되는 편안함",
FitPro_Fit_AllDay_Subtitle: "범용 윙팁 디자인은 달리기 운동을 즐기는 사용자부터 프로 운동선수에 이르는 모든 유형의 활동적인 사용자를 대상으로 일 년에 걸친 궁극적인 테스트를 거쳤습니다.",
FitPro_Fit_Universal_Title: "모두에게 딱 맞는 윙팁",
FitPro_Fit_Universal_Subtitle: "윙팁이 귀에 얼마나 잘 맞는지는 사용자의 귀 크기에 따라 달라집니다. 윙팁은 여러 각도로 휘어져 다양한 귀 크기에 맞춰 안정성을 제공합니다.",
FitPro_Fit_Materials_Title: "부드러우면서도 안정적인 착용감",
FitPro_Fit_Materials_Subtitle: "고유한 윙팁은 수천 명의 귀를 측정한 값을 통해 디지털 모델링되었습니다. 어떤 형태나 크기의 귀에도 편안하고 안정적으로 착용될 수 있도록 코어는 단단하고 주변부는 부드럽게 잘 휘어지는 소재를 사용하여 완벽한 조합을 이루었습니다.",
FitPro_Fit_ChapterText2: "뛰어난 사운드는 <em>뛰어난 착용감</em>에서 시작되죠. 올바른 사이즈의 이어팁은 내게 꼭 맞는 착용감을 선사합니다.",
FitPro_Fit_EarTips_Title: "최적의 사이즈 찾기",
FitPro_Fit_EarTips_Subtitle: "꼭 맞는 착용감과 어쿠스틱 씰을 위한 최적의 사이즈를 손쉽게 찾을 수 있도록 세 가지 크기의 부드러운 실리콘 이어팁 옵션이 제공됩니다.",
FitPro_Fit_FitTest_Title: "착용감 테스트",
FitPro_Fit_FitTest_Subtitle: "Apple과 안드로이드 기기에서 모두 착용 테스트를 설정할 수 있으며, 맞춤형 어쿠스틱 씰과 최적화된 성능을 위해 딱 맞는 착용감을 찾도록 도와줍니다. 착용 테스트는 최적의 어쿠스틱 씰을 확인하기 위해 노이즈 누설음을 분석합니다.",
FitPro_Fit_FitTest_Disclaimer: "*이어팁 착용 테스트는 iOS 15.1 이상 버전, 안드로이드의 경우 Beats 앱이 필요합니다",
FitPro_Fit_Seal_Title: "최고의 성능을 위한 최적의 씰",
FitPro_Fit_Seal_Subtitle: "딱 맞는 핏으로 맞춤화된 어쿠스틱 씰과 최적의 퍼포먼스를 즐길 수 있습니다. 적절한 어쿠스틱 씰은 액티브 노이즈 캔슬링은 물론 주변음 허용 모드가 최상의 성능을 발휘하도록 도와줍니다.",
FitPro_Fit_Summary_Title: "편안함과 안정성을 위한 디자인",
FitPro_Fit_Summary1_FlexibleWingtip: "Beats Fit Pro 제작의 우선순위는 윙팁 디자인을 완벽하게 구현하는 것이었습니다.",
FitPro_Fit_Summary2_AllDay: "범용 윙팁 디자인은 모든 분야의 운동선수들로부터 편안한 착용감 테스트를 받았습니다.",
FitPro_Fit_Summary3_Universal: "윙팁이 귀에 얼마나 잘 맞는지는 사용자의 귀 크기에 따라 달라집니다.",
FitPro_Fit_Summary4_Materials: "Beats의 윙팁 소재는 촉감이 부드러우면서 단단합니다.",
FitPro_Fit_Summary5_EarTips: "세 가지 크기의 부드러운 실리콘 이어팁 옵션으로 올바른 사이즈를 쉽게 찾을 수 있습니다.",
FitPro_Fit_Summary6_FitTest: "착용 테스트는 맞춤형 어쿠스틱 씰과 최적의 퍼포먼스를 위해 적절한 핏을 확인하는 데 도움이 됩니다.",
FitPro_Fit_Summary7_Seal: "적절한 어쿠스틱 씰은 최고의 사운드가 구현될 수 있도록 합니다.",
FitPro_Fit_Summary7_Seal_ChineseAlt: "",
FitPro_Sound_Title: "사운드",
FitPro_Sound_Intro_VOSubtitle: "Beats Fit Pro는 강력하고 균형 잡힌 사운드를 선사하도록 설계되었습니다.",
FitPro_Sound_ChapterText1: "Beats Fit Pro는 주파수 곡선 전반에서의 다이나믹 레인지를 위한 <em>맞춤형 드라이버</em>를 탑재했습니다.",
FitPro_Sound_Driver_Title: "작지만 강력하다",
FitPro_Sound_Driver_Subtitle: "드라이버는 9.5mm 직경으로 작은 크기를 유지하면서도 탁월한 저음, 고음과 중간음을 그대로 구현해냈습니다. 이는 미국 1센트 주화 직경의 절반 정도밖에 되지 않는 크기입니다.",
FitPro_Sound_Driver_Label: "9.5mm",
FitPro_Sound_Pleated_Title: "완벽한 플리티드 드라이버",
FitPro_Sound_Pleated_Subtitle: "Beats의 독점적인 플리티드 드라이버는 유연성을 더하면서도 미세진동을 크게 줄였습니다. 플리티드 드라이버는 가장 설계와 제조가 어려운 드라이버 중 하나입니다.",
FitPro_Sound_Airflow_Title: "자연스러운 흐름",
FitPro_Sound_Airflow_Subtitle: "드라이버를 통과하는 최적의 공기 흐름은 고음 왜곡을 최소화하고 강력한 저음 재생을 이끌어냅니다.",
FitPro_Sound_UniqueDriver_Title: "Beats의 <br/>시그니처 사운드",
FitPro_Sound_UniqueDriver_Subtitle: "독특한 드라이버 디자인은 <br/>놀라울 정도로 적은 왜곡의 <br/>강력하고 균형 잡힌 사운드를 <br/>완성합니다.",
FitPro_Sound_ChapterText2: "나만의 감상 방식에 맞는 <em>탁월한 사운드</em>를 즐겨보세요.",
FitPro_Sound_SpatialAudio_Title: "모든 차원을 아우르는 음향",
FitPro_Sound_SpatialAudio_Subtitle: "동적 머리 추적으로 구현되는 공간 음향은 머리의 움직임을 따라 조정되는 몰입감 넘치는 사운드를 선사합니다.*",
FitPro_Sound_SpatialAudio_Disclaimer: "*호환되는 하드웨어 및 소프트웨어가 필요합니다. 해당 기능을 지원하는 앱의 호환 콘텐츠에서 작동합니다. Dolby Atmos로 제공되지 않는 콘텐츠도 있습니다. 머리 추적 기능을 사용하려면 iPhone 또는 iPad가 필요합니다.",
FitPro_Sound_UseCases_Subtitle: "각 사용 사례를 클릭하거나 탭하여 자세히 알아보세요.",
FitPro_Sound_UseCases_Music: "음악",
FitPro_Sound_UseCases_Music_Subtitle: "다차원 사운드로 스튜디오에 온 것처럼 좋아하는 음악을 감상해보세요.*",
FitPro_Sound_UseCases_Movies: "영화",
FitPro_Sound_UseCases_Movies_Subtitle: "마치 극장에 온 것과 같은 서라운드 사운드와 감각으로 좋아하는 영화를 감상할 수 있습니다.*",
FitPro_Sound_UseCases_Games: "게임",
FitPro_Sound_UseCases_Games_Subtitle: "완전히 새로운 수준으로 몰입감 넘치는 게임을 즐겨보세요.*",
FitPro_Sound_UseCases_Disclaimer_1: "*호환되는 하드웨어 및 소프트웨어가 필요합니다. 해당 기능을 지원하는 앱의 호환 콘텐츠에서 작동합니다. Dolby Atmos로 제공되지 않는 콘텐츠도 있습니다. 머리 추적 기능을 사용하려면 iPhone 또는 iPad가 필요합니다.",
FitPro_Sound_Calls_Title: "크고 선명한 음질",
FitPro_Sound_Calls_Subtitle: "Beats Fit Pro는 내장된 가속도 센서와 Class 1 Bluetooth® 기술로 사용자의 음성을 인식합니다. 마이크가 음성을 정확히잡아내고, 디지털 프로세서는 외부 소음과 바람 소리를 걸러내어 또렷하고 선명한 음질로 통화할 수 있습니다.",
FitPro_Sound_Summary_Title: "강력하고 균형 잡힌 사운드를 선사하는 설계",
FitPro_Sound_Summary1_Driver: "드라이버는 9.5mm 직경으로 작은 크기를 유지하면서도 탁월한 저음, 고음, 중간음을 그대로 구현해냈습니다.",
FitPro_Sound_Summary2_Pleated: "Beats의 독점적인 플리티드 드라이버는 유연성을 더하면서도 미세진동을 제거합니다.",
FitPro_Sound_Summary3_Airflow: "드라이버를 통과하는 최적의 공기 흐름이 고음 왜곡을 최소화하고 깔끔한 저음 퍼포먼스를 보장합니다.",
FitPro_Sound_Summary4_UniqueDriver: "독특한 드라이버 디자인은 놀라울 정도로 적은 왜곡의 강력하고 균형 잡힌 사운드를 완성합니다.",
FitPro_Sound_Summary5_SpatialAudio: "동적 머리 추적으로 구현되는 공간 음향은 머리의 움직임을 따라 몰입감 넘치는 사운드를 선사합니다.",
FitPro_Sound_Summary6_UseCases: "동적 머리 추적으로 구현되는 공간 음향은 머리의 움직임을 따라 극장에 온 것과 같은 몰입감 넘치는 경험을 선사합니다.",
FitPro_Sound_Summary7_Calls: "Beats Fit Pro는 사용자의 음성을 인식하여 가장 선명한 음성을 구현합니다.",
FitPro_Modes_Title: "감상 모드",
FitPro_Modes_Intro_VOSubtitle: "세 가지 감상 모드로 원하는 대로 사운드를 제어할 수 있습니다. ",
FitPro_Modes_Toggle_Title: "원하는 대로 듣기",
FitPro_Modes_Toggle_Subtitle: "액티브 노이즈 캔슬링, 주변음 허용 모드와 적응형 EQ의 세 가지 고유한 옵션을 통해 필요한 감상 모드를 언제든 선택할 수 있습니다.",
FitPro_Modes_Toggle_Disclaimer: "특별히 데모용으로 제작되었습니다.",
FitPro_Modes_Toggle_Label1: "노이즈 캔슬링",
FitPro_Modes_Toggle_Label2: "적응형 EQ",
FitPro_Modes_Toggle_Label3: "주변음 허용 모드",
FitPro_Modes_Toggle_MusicLabel: "지금 재생 중",
FitPro_Modes_Toggle_SongTitle: "Mood",
FitPro_Modes_Toggle_SongArtist: "24kGoldn, ft. iann dior",
FitPro_Modes_ChapterText1: "<em>액티브 노이즈 캔슬링</em>이 외부 소음을 차단하여 온전히 음악을 즐길 수 있습니다.",
FitPro_Modes_ANCExternalMic_Title: "외부 소음 감지",
FitPro_Modes_ANCExternalMic_Subtitle: "액티브 노이즈 캔슬링은 외향 마이크가 주변 환경의 소음을 탐지하는 것으로부터 시작됩니다.",
FitPro_Modes_ANCAntiNoise_Title: "원하지 않는 사운드 상쇄",
FitPro_Modes_ANCAntiNoise_Subtitle: "Beats Fit Pro는 <nobr>외부 사운드와</nobr> 똑같은 안티 노이즈로 이를 상쇄시키고, 계속해서 적응하며 외부 소음이 들리기도 전에 이를 감쇠합니다.",
FitPro_Modes_ANCInwardMic_Title: "지속적인 조절",
FitPro_Modes_ANCInwardMic_Subtitle: "내향 마이크는 착용감과 어쿠스틱 씰에 영향을 주는 추가적인 사운드를 분석하고, 안티 노이즈로 이를 제거합니다.",
FitPro_Modes_ANCUseCases_Subtitle: "각 사용 사례를 클릭 또는 탭하여 자세히 알아보세요.",
FitPro_Modes_ANCUseCases_Commute: "통근",
FitPro_Modes_ANCUseCases_Commute_Subtitle: "통근길에 도시의 배경 소음을 줄일 수 있습니다.",
FitPro_Modes_ANCUseCases_Exercise: "운동",
FitPro_Modes_ANCUseCases_Exercise_Subtitle: "방해 요소 없이 운동에 몰입할 수 있습니다.",
FitPro_Modes_ANCUseCases_Travel: "여행",
FitPro_Modes_ANCUseCases_Travel_Subtitle: "승객들의 소리나 엔진 소음을 줄여 비행을 즐길 수 있습니다",
FitPro_Modes_ANCUseCases_Meditate: "명상",
FitPro_Modes_ANCUseCases_Meditate_Subtitle: "방해 요소를 차단하여 호흡에 집중하고 내면의 평화를 찾아보세요.",
FitPro_Modes_ChapterText2: "<em>주변음 허용 모드</em>로 <nobr>주변 상황을</nobr> 인식할 수 있습니다.",
FitPro_Modes_TMAware_Title: "나를 감싼 세상의 사운드",
FitPro_Modes_TMAware_Subtitle: "주변음 허용 모드는 외부 사운드를 음악에 혼합하여 주변 환경 인식을도와줍니다.",
FitPro_Modes_TMExternalMic_Title: "주변 소음을 정확히 잡아내는 외부 마이크",
FitPro_Modes_TMExternalMic_Subtitle: "외부 마이크는 주변 환경의 외부 소음과 주변 소음을 잡아내어 자연스러운 '열린' 감각의 감상 경험을 선사합니다. ",
FitPro_Modes_TMUseCases_Subtitle: "각 사용 사례를 클릭하거나 탭하여 자세히 알아보세요.",
FitPro_Modes_TMUseCases_Run: "달리기",
FitPro_Modes_TMUseCases_Run_Subtitle: "음악을 들으면서 주변의 소리를 듣고 페이스를 유지하세요.",
FitPro_Modes_TMUseCases_Travel: "여행",
FitPro_Modes_TMUseCases_Travel_Subtitle: "공항 게이트나 기차 플랫폼에서 중요한 안내 방송을 듣습니다.",
FitPro_Modes_TMUseCases_Walk: "걷기",
FitPro_Modes_TMUseCases_Walk_Subtitle: "주변의 교통 상황과 번잡한 교차로를 인식할 수 있습니다.",
FitPro_Modes_TMUseCases_StandBy: "기다리기",
FitPro_Modes_TMUseCases_StandBy_Subtitle: "시끄러운 커피숍에 있거나 친구를 기다릴 때 음악을 들으면서도 내 이름을 정확하게 들을 수 있습니다.",
FitPro_Modes_ChapterText3: "<em>적응형 EQ</em>는 개인 고유의 핏에 정확히 맞춘 튜닝을 선사합니다.",
FitPro_Modes_AdaptiveEQ_Title: "내게 적응하는 사운드",
FitPro_Modes_AdaptiveEQ_Subtitle: "액티브 노이즈 캔슬링과 주변음 허용 모드가 꺼진 상태에서는 적응형 EQ가 마이크를 활용해 사용자 고유의 귀 형태에 맞춰 사운드를 자동으로 튜닝합니다.",
FitPro_Modes_Summary_Title: "음악에 집중, 또는 주변 사운드 듣기",
FitPro_Modes_Summary1_Toggle: "액티브 노이즈 캔슬링, 주변음 허용 모드와 적응형 EQ의 세 가지 고유한 옵션을 통해 필요한 감상 모드를 언제든 선택할 수 있습니다.",
FitPro_Modes_Summary2_ANCExtermalMic: "액티브 노이즈 캔슬링은 외향 마이크가 주변 환경의 소음을 탐지하는 것으로부터 시작됩니다.",
FitPro_Modes_Summary3_ANCAntiNoise: "Beats Fit Pro는 그런 다음 안티 노이즈로 이를 상쇄시킵니다.",
FitPro_Modes_Summary4_ANCInwardMic: "내향 마이크는 착용감과 어쿠스틱 씰에 영향을 주는 추가적인 사운드를 분석합니다.",
FitPro_Modes_Summary5_ANCUseCases: "ANC를 사용하여 출퇴근, 운동, 여행 또는 명상에 집중할 수 있습니다.",
FitPro_Modes_Summary6_TMAware: "주변음 허용 모드로 <nobr>주변 상황을</nobr> 인식할 수 있습니다.",
FitPro_Modes_Summary7_TMExternalMic: "외부 마이크는 주변 환경의 외부 소음과 주변 소음을 잡아냅니다.",
FitPro_Modes_Summary8_TMUseCases: "달리기, 여행, 걷기 또는 기다리는 동안 주변의 사운드를 들을 수 있습니다.",
FitPro_Modes_Summary9_AdaptiveEQ: "적응형 EQ가 마이크를 활용해 사용자 고유의 귀 형태에 맞춰 사운드를 자동으로 튜닝합니다.",
FitPro_Controls_Title: "컨트롤",
FitPro_Controls_Intro_VOSubtitle: "버튼을 한 번만 누르면 이어버드를 컨트롤할 수 있습니다.",
FitPro_Controls_ChapterText1: "편리한 <em>온이어 버튼</em>으로 손쉬운 컨트롤이 가능합니다.",
FitPro_Controls_PhysicalButton_Title: "살짝 눌러 재생",
FitPro_Controls_PhysicalButton_Subtitle: "물리적인 버튼으로 듣고 있는 사운드를 쉽게 컨트롤해보세요.",
FitPro_Controls_BothSides_Title: "개별 이어버드 연결성",
FitPro_Controls_BothSides_Subtitle: "양쪽 이어버드에 버튼이 있어 양손으로 음악을 제어할 수 있습니다.",
FitPro_Controls_ChapterText2: "언제, 어디서나 <em>오디오와 감상 모드 컨트롤</em>이 가능합니다.",
FitPro_Controls_PressHold_Title: "매끄럽게 전환되는 감상 모드",
FitPro_Controls_PressHold_Subtitle: "버튼을 길게 눌러 감상 모드 전환 또는 사용자화하여 Siri 활성화.*",
FitPro_Controls_PressHold_Disclaimer: "*길게 누르기 기능을 사용자 설정하려면 iOS 14.6 이상 버전을 실행하는 iPhone 또는 최신 버전의 Beats 앱이 설치된 안드로이드 기기가 필요합니다. Siri는 일부 언어와 국가에서는 지원되지 않으며, 국가별로 기능이 다를 수 있습니다. 인터넷 연결이 필요하며, 이동통신 데이터 요금이 부과될 수 있습니다.",
FitPro_Controls_PressHold_Label1: "노이즈 캔슬링 모드",
FitPro_Controls_PressHold_Label2: "주변음 허용 모드",
FitPro_Controls_Playback_Title: "온이어 컨트롤",
FitPro_Controls_Playback_Subtitle: "온이어 컨트롤로 통화와 음악을 관리할 수 있습니다.",
FitPro_Modes_Playback_MusicLabel: "지금 재생 중",
FitPro_Modes_Playback_SongTitle1: "5 Seconds of Summer",
FitPro_Modes_Playback_SongArtist1: "Best Years",
FitPro_Modes_Playback_SongTitle2: "Boyfriend",
FitPro_Modes_Playback_SongArtist2: "Selena Gomez",
FitPro_Modes_Playback_SongTitle3: "Lovesick",
FitPro_Modes_Playback_SongArtist3: "Trevor Daniel",
FitPro_Modes_Playback_SongTitle4: " Mood",
FitPro_Modes_Playback_SongArtist4: "24kGoldn, ft. iann dior",
FitPro_Modes_Playback_SongTitle5: "What If I Told You That I Love You",
FitPro_Modes_Playback_SongArtist5: "Ali Gatie",
FitPro_Controls_Playback_Instruction: "핫스팟을 클릭하거나 탭하여 각각의 명령을 연습해보세요.",
FitPro_Controls_Playback_Play1: "버튼을 눌러 음악 일시 정지 또는 전화 받기",
FitPro_Controls_Playback_Play2: "버튼을 눌러 음악 재생 또는 전화 끊기",
FitPro_Controls_Playback_Skip: "버튼을 두 번 눌러 트랙 건너뛰기",
FitPro_Controls_Playback_Previous: "버튼을 세 번 눌러 이전 트랙 재생하기",
FitPro_Controls_Playback_ScanFwd: "버튼을 두 번 길게 눌러 앞으로 스캔하기",
FitPro_Controls_Playback_ScanBck: "버튼을 세 번 길게 눌러 뒤로 스캔하기",
FitPro_Controls_Customize_Title: "원하는 쪽에 설정",
FitPro_Controls_Customize_Subtitle: "iOS나 안드로이드용 Beats 앱을 통해 각 이어버드의 \"길게 누르기\" 컨트롤 기능을 사용자화하여 액티브 노이즈 캔슬링, 주변음 허용 모드, 적응형 EQ, 음량 조절 또는 음성 인식 기능을 활성화할 수 있습니다.*",
FitPro_Controls_Customize_Disclaimer: "*Beats 앱은 안드로이드 8.0 이상 버전이 필요합니다. 앱은 Google Play 스토어 및 beatsbydre.com에서 이용할 수 있습니다.",
FitPro_Controls_Android_Title: "안드로이드 기기와 사용 가능",
FitPro_Controls_Android_Subtitle: "안드로이드용 Beats 앱을 통해 원터치 페어링, 맞춤형 컨트롤, 배터리 잔량, 펌웨어 업데이트와 같은 향상된 기능이 제공됩니다.*",
FitPro_Controls_Android_Disclaimer: "*Beats 앱은 안드로이드 8.0 이상 버전이 필요합니다. 앱은 Google Play 스토어 및 beatsbydre.com에서 이용할 수 있습니다.",
FitPro_Controls_Summary_Title: "손끝에서 일어나는 완벽한 컨트롤",
FitPro_Controls_Summary1_PhysicalButton: "물리적인 버튼으로 듣고 있는 사운드를 쉽게 컨트롤해보세요.",
FitPro_Controls_Summary2_BothSides: "양쪽 이어버드에 버튼이 있어 양손으로 음악을 제어할 수 있습니다.",
FitPro_Controls_Summary3_PressHold: "버튼을 길게 눌러 감상 모드를 전환해보세요.",
FitPro_Controls_Summary4_Playback: "온이어 컨트롤로 통화와 음악을 관리할 수 있습니다.",
FitPro_Controls_Summary5_Customize: "양쪽 이어버드에서 '길게 누르기' 기능을 사용자화할 수 있습니다.",
FitPro_Controls_Summary6_Android: "안드로이드용 Beats 앱을 통해 향상된 기능이 제공됩니다.*",
FitPro_H1_Title: "Apple H1 칩",
FitPro_H1_Intro_VOSubtitle: "Beats Fit Pro는 Apple H1 칩을 활용해 설계되었습니다. ",
FitPro_H1_AppleIntegration_Title: "매끄러운 <nobr>Apple 연동</nobr>",
FitPro_H1_AppleIntegration_Subtitle: "Apple H1 칩을 탑재하여 향상된 기능과 Apple 기기와 매끄러운 연동을 제공합니다.",
FitPro_H1_ChapterText1: "<em>Apple H1 칩</em>으로 설계된 Beats Fit Pro는 인기 있는 기능을 제공합니다.",
FitPro_H1_Pairing_Title: "원터치 페어링",
FitPro_H1_Pairing_Subtitle: "Beats Fit Pro는 박스에서 꺼낸 즉시 간단한 원터치 페어링을 통해 업계 최고의 Class 1 Bluetooth®로 iOS 기기와 연결됩니다.*",
FitPro_H1_Pairing_Disclaimer: "*iSO 15.1 이상 버전이 필요합니다.",
FitPro_H1_HeySiri_Title: "Siri야",
FitPro_H1_HeySiri_Subtitle: "음악, 통화, 길 안내 컨트롤은 물론 “Siri야”를 말해 더 많은 기능을 사용할 수 있습니다.*",
FitPro_H1_HeySiri_Disclaimer: "*Siri는 일부 언어와 국가에서는 지원되지 않으며, 국가별로 기능이 다를 수 있습니다. 인터넷 연결이 필요하며, 이동통신 데이터 요금이 부과될 수 있습니다.",
FitPro_H1_AutoSwitch_Title: "자동 전환",
FitPro_H1_AutoSwitch_Subtitle: "Beats Fit Pro는 자동 전환으로 기기 간에 사운드의 매끄러운 연동이 가능합니다.*",
FitPro_H1_AutoSwitch_Disclaimer: "*자동 전환은 macOS 11.1, iOS 14.3, iPadOS 14.3, watchOS 7.2, tvOS 14.3 이상 버전이 필요합니다.",
FitPro_H1_AudioSharing_Title: "오디오 공유",
FitPro_H1_AudioSharing_Subtitle: "AirPods이나 Beats 헤드폰을 가진 친구와 좋아하는 음악을 공유하고, 함께 감상해보세요.*",
FitPro_H1_AudioSharing_Disclaimer: "*오디오 공유는 지원하는 무선 Beats 헤드폰과 모든 AirPods에 호환됩니다. 호환되는 하드웨어 및 소프트웨어가 필요합니다.",
FitPro_H1_FindMy_Title: "나의 기기 찾기",
FitPro_H1_FindMy_Subtitle: "나의 찾기 기능으로 어디에서나 Beats Fit Pro를 쉽게 찾을 수 있습니다.",
FitPro_H1_FindMy_Disclaimer: "*나의 Beats 찾기는 iPhone 및 iPod touch(iOS 15.1 이상 버전), iPad(iPadOS 15.1 이상 버전), 또는 Mac(macOS Monterey 12.0.1 이상 버전)이 필요합니다. 고객은 Apple ID가 있고, '나의 찾기'가 활성화된 iCloud 계정에 로그인한 상태여야 합니다.",
FitPro_H1_Summary_Title: "Apple H1 칩 탑재",
FitPro_H1_Summary1_Seamless: "Apple 기기와의 매끄러운 연동을 위한 Apple H1 칩이 탑재되었습니다.",
FitPro_H1_Summary2_Pairing: "간단한 원터치 페어링으로 Bluetooth®를 통해 쉽게 연결이 가능합니다.",
FitPro_H1_Summary3_HeySiri: "\"Siri야\"라고 간편하게 핸즈프리로 Siri를 불러 질문을 물어보세요.",
FitPro_H1_Summary4_AutoSwitch: "Beats Fit Pro는 자동 전환으로 기기 간 매끄러운 연동이 가능합니다.*",
FitPro_H1_Summary5_AudioSharing: "AirPods이나 Beats 헤드폰을 가진 친구와 음악을 공유해보세요.",
FitPro_H1_Summary6_FindMy: "나의 찾기 기능으로 이어버드의 위치를 쉽게 찾을 수 있습니다.",
FitPro_Design_Title: "디자인",
FitPro_Design_Intro_VOSubtitle: "Beats Fit Pro는 온종일 활동에 맞춰 디자인되었습니다. ",
FitPro_Design_ChapterText1: "<em>충전 케이스</em>는 휴대 중에 이어버드를 충전할 수 있도록 디자인되었습니다.",
FitPro_Design_Pocket_Title: "포켓에 쏙 들어가는 사이즈",
FitPro_Design_Pocket_Subtitle: "Beats Fit Pro를 동일한 색상의 충전 케이스에 넣어 간편하게 휴대할 수 있습니다.",
FitPro_Design_Size_Title: "50% 더 작아진 크기",
FitPro_Design_Size_Subtitle: "Beats Fit Pro 케이스는 Powerbeats Pro 케이스보다 50% 더 작아졌습니다.",
FitPro_Design_Color_Title: "스타일에 어울리는 색상",
FitPro_Design_Color_Subtitle: "<nobr>네 가지</nobr> 고유한 이어버드 색상과 동일한 색상의 케이스가 제공되어 내 스타일에 맞춰 선택할 수 있습니다.",
FitPro_Design_Color_Black: "BEATS 블랙",
FitPro_Design_Color_White: "BEATS 화이트",
FitPro_Design_Color_Purple: "스톤 퍼플",
FitPro_Design_Color_Gray: "세이지 그레이",
FitPro_Design_ChapterText2: "<em>Beats Fit Pro 이어버드</em>는 어디에서 무엇을 하든 함께 할 수 있도록 디자인되었습니다.",
FitPro_Design_TrueWireless_Title: "무선으로 누리는 자유",
FitPro_Design_TrueWireless_Subtitle: "Beats Fit Pro는 일상의 활동과 항상 함께 할 수 있는 진정한 무선 이어버드입니다.",
FitPro_Design_Independent_Title: "한 번에 하나씩",
FitPro_Design_Independent_Subtitle: "개별 이어버드 연결성으로 한쪽 이어버드만 착용할 수 있으며, 양쪽 버튼 모두를 사용할 수 있어 사운드의 완벽한 제어가 가능합니다.",
FitPro_Design_Sweat_Title: "비, 땀, 햇빛에 강함",
FitPro_Design_Sweat_Subtitle: "Beats Fit Pro 이어버드는 땀과 수분에 강한 IPX-4 등급으로 비바람과 가장 거친 운동까지 버텨낼 수 있습니다.*",
FitPro_Design_Sweat_Disclaimer: "*Beats Fit Pro는 비수상 스포츠 및 운동용으로, 땀과 수분에 강합니다. 땀과 습기에 강한 생활 방수 효과는 영구적이지 않습니다. 충전 케이스에는 땀과 습기에 강한 생활 방수 기능이 없습니다.",
FitPro_Design_Summary_Title: "온종일 활동에 맞춘 디자인",
FitPro_Design_Summary1_Pocket: "Beats Fit Pro는 언제든 휴대할 수 있는 포켓 크기의 충전 케이스와 함께 완성되었습니다.",
FitPro_Design_Summary2_Size: "Beats Fit Pro 케이스는 Powerbeats Pro 케이스보다 50% 더 작아졌습니다.",
FitPro_Design_Summary3_Color: "자신의 스타일에 맞춰 선택할 수 있도록 <nobr>네 가지</nobr> 색상 옵션이 제공됩니다.",
FitPro_Design_Summary4_TrueWireless: "Beats Fit Pro는 진정한 무선 이어버드입니다.",
FitPro_Design_Summary5_Independent: "개별 이어버드 연결성으로 한 번에 한쪽 이어버드만 착용할 수 있습니다.",
FitPro_Design_Summary6_Sweat: "Beats Fit Pro는 땀과 수분에 강한 IPX-4 등급을 자랑합니다.",
FitPro_Battery_Title: "배터리",
FitPro_Battery_Intro_VOSubtitle: "긴 배터리 사용 시간으로 계속해서 음악을 감상해보세요.",
FitPro_Battery_6Hours_Title: "오래 지속되는 감상 시간",
FitPro_Battery_6Hours_Subtitle: "각 이어버드로 최대 6시간 동안 좋아하는 음악을 감상할 수 있습니다.*",
FitPro_Battery_6Hours_Disclaimer: "*배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.",
FitPro_Battery_18Hours_Title: "최대 24시간 재생",
FitPro_Battery_18Hours_Subtitle: "충전 케이스로 추가 18시간의 감상 시간을 즐겨보세요.*",
FitPro_Battery_18Hours_Disclaimer: "*배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.",
FitPro_Battery_FastFuel_Title: "잠깐의 순간이 파워풀한 시간으로",
FitPro_Battery_FastFuel_Subtitle: "Fast Fuel 기능을 사용하면 5분만 충전해도 1시간의 추가 재생이 가능합니다.*",
FitPro_Battery_FastFuel_Disclaimer: "*배터리 사용 시간은 기기 설정, 환경, 사용 방법 및 기타 여러 요인들에 따라 달라집니다.",
FitPro_Battery_USBC_Title: "USB-C 충전",
FitPro_Battery_USBC_Subtitle: "USB-C 충전 커넥터는 Apple과 안드로이드에서 사용 가능하여 어디서든 충전할 수 있습니다.",
FitPro_Battery_WhatsInTheBox_Title: "제품 구성",
FitPro_Battery_WhatsInTheBox_Subtitle: "Beats Fit Pro에는 포켓 크기의 충전 케이스, 범용 USB-C 충전 커넥터, 세 가지 크기 중에서 선택할 수 있는 이어팁이 함께 제공됩니다.",
FitPro_Battery_Summary_Title: "계속되는 음악 감상 시간",
FitPro_Battery_Summary1_6Hours: "각 이어버드로 6시간의 감상이 가능합니다.",
FitPro_Battery_Summary2_18Hours: "충전 케이스로 추가 18시간의 감상 시간을 즐겨보세요.",
FitPro_Battery_Summary3_FastFuel: "Fast Fuel 기능을 사용하면 5분만 충전해도 1시간 추가 재생이 가능합니다.",
FitPro_Battery_Summary4_USBC: "USB-C 충전 커넥터로 어디서든 충전할 수 있습니다.",
FitPro_Battery_Summary5_WhatsInTheBox: "포켓 크기의 충전 케이스, USB-C 충전 커넥터, 세 가지 크기의 이어팁이 함께 제공됩니다.",
FitPro_Outro_ChapterText1: "유연하고 안정적인 착용감의 윙팁, 세 가지 감상 모드와 콤팩트한 포켓 크기의 충전 케이스를 갖춘 <em>Beats Fit Pro를 통해 원하는 대로 사운드를 제어해보세요.</em>",
FitPro_TestKnowledge: "Beats Fit Pro 지식을 지금 테스트해보세요.",
FitPro_TakeQuiz: "퀴즈 풀기",
Global_ForgotEmail_NoUser:"이 ID에 해당되는 사용자 기록이 없습니다. 사용자가 삭제되었을 수 있습니다."

};

export default copy;
