const copy = {

	Global_ProductSelector_Headline: "Wähle ein Beats TRNR Erlebnis",
	Global_ProductSelector_Product1: "Beats Studio Buds",
	Global_ProductSelector_Product2: "Beats Fit Pro",
	Global_ProductSelector_CTA_1: "Los geht’s",
	Global_ProductSelector_CTA_2: "Fortsetzen",
	Global_ProductSelector_CTA_3: "NEUSTART",
	Global_WelcomeInstructions_Headline: "Willkommen bei Beats TRNR, für Beats Experten im Training.",
	Global_WelcomeInstructions_Subhead: "Das erwartet dich hier:",
	Global_WelcomeInstructions_Body1: "Jede Trainingseinheit sollte etwa 15 Minuten dauern.",
	Global_WelcomeInstructions_Body2: "Jede Training-Session sollte hörbar sein. Stelle also sicher, dass dein Ton eingeschaltet ist.",
	Global_WelcomeInstructions_Body3: "Denke in jeder Trainingseinheit daran, jeden einzelnen Abschnitt abzuschließen bevor du fortfährst.",
	Global_WelcomeInstructions_Body4: "Mach dir keine Sorgen, wenn du kurz weg musst. Du kannst jederzeit weitermachen, wo du aufgehört hast.",
	Global_Menu_Button_SelectProduct: "Anderes Produkt auswählen",



	Global_UI_Next: 'WEITER',
Global_UI_Begin: 'START',
Global_UI_Back: 'ZURÜCK',
Global_UI_Settings: 'Einstellungen',
Global_UI_Signout: 'Abmelden',
Global_UI_NextChapter: 'NÄCHSTES KAPITEL',
Global_UI_PercentageComplete: '<<number>>%  ABGESCHLOSSEN',
Global_UI_Replay: 'ERNEUT ABSPIELEN',
Global_Field_Location: 'ORT',
Global_Languages_Select: 'SPRACHAUSWAHL:',
Global_Field_EnterEmail: 'E-Mail Adresse eingeben',
Global_Field_CreatePassword: 'Passwort erstellen',
Global_Field_Password: 'Passwort',
Global_Field_Password_Forgot: 'PASSWORT VERGESSEN',
Global_Password_Subtitle: 'DAS PASSWORT MUSS AUS MINDESTENS 8 ZEICHEN EINSCHLIESSLICH GROSS- UND KLEINBUCHSTABEN, ZAHLEN UND SONDERZEICHEN BESTEHEN.',
Global_Password_Change: 'ÄNDERN',
Global_Password_Reset_Headline: 'Passwort zurücksetzen',
Global_Password_Reset_Subtitle: 'Gib deine E-Mail Adresse ein, um eine Anleitung zum Zurücksetzen des Passworts zu erhalten.',
Global_Password_Reset_Confirm: 'Gesendet! Überprüfe deine E-Mails.',
Global_Password_ChangeConfirm: 'Passwort aktualisiert',
Global_Password_Show: 'ANZEIGEN',
Global_Password_Hide: 'AUSBLENDEN',
Global_Details_Title: 'Weitere Angaben',
Global_Details_Field_FirstName: 'Vorname',
Global_Details_Field_LastName: 'Nachname',
Global_Details_Field_EmployeeID: 'Mitarbeiter ID (optional)',
Global_Details_Field_BusinessRole: 'Rolle im Unternehmen',
Global_Details_Field_BusinessRole1: 'Management',
Global_Details_Field_BusinessRole2: 'Vertrieb',
Global_Details_Field_BusinessRole3: 'Schulung',
Global_Details_Field_CompanyName: 'Name des Unternehmens:',
Global_Details_Field_Country: 'Land',
Global_Field_Password_Error: 'Das eingegebene Passwort ist falsch. Versuche es noch einmal.',
Global_Field_Email_Error: 'Du hast eine ungültige E-Mail Adresse eingegeben. Versuche es noch einmal.',
Global_Field_Details_Error: 'Fülle die erforderlichen Felder aus.',
Global_Greeting: 'Hallo <<name>>: ',
Global_CompletionMessage: 'du hast <<number>>% abgeschlossen.',
Global_Chapter: 'KAP. <<number>>',
 Global_Chapter_Subtitle: 'KAP. <<number>> VON <<total>>',
Global_Intro: 'Einführung',
Global_ChapterTitle_Sound: 'Sound',
Global_ChapterTitle_ANC: 'ANC',
Global_ChapterTitle_Transparency: 'Transparenzmodus',
Global_ChapterTitle_Design: 'Design',
Global_ChapterTitle_Compatibility: 'Kompatibilität',
Global_ChapterTitle_Controls: 'Steuerung',
Global_ChapterTitle_Battery: 'Batterie',
Global_Menu: 'Menü',
Intro_Welcome_Label: 'ERLEBNIS',
Intro_Welcome_ChapterText: 'Willkommen beim Beats Studio Buds Erlebnis. Klicke oder tippe weiter, um mehr zu entdecken.',
Intro_Welcome_ReturningUser: 'Bereit, dort weiterzumachen, wo du aufgehört hast? Klicke oder tippe, um die Beats Studio Buds weiter zu entdecken.',
CR_TRNR_Intro_Sorry: 'Es tut uns leid. Da ist etwas schiefgelaufen. Wende dich an deinen Vorgesetzten, um einen neuen Zugangslink zu erhalten.',
Intro_Intro_Subtitle: 'Jetzt neu: Beats Studio Buds. Komplett kabellose In-Ear Kopfhörer mit Noise-Cancelling und Sound in Studioqualität. Klicke oder tippe weiter, um mehr zu erfahren.',
Sound_Intro_Title: 'Sound',
Sound_Intro_VOSubtitle: 'Die fortschrittliche Technik liefert kraftvollen, ausgewogenen Sound.',
Sound_ChapterText1: 'Großartiger Sound beginnt mit einer <em>hervorragenden Passform und einer bequemen, akustischen Abdichtung.</em>',
Sound_FindYourFit_Title: 'Finde den perfekten Sitz. Genieß die Musik.',
Sound_FindYourFit_Subtitle: 'Drei Ohreinsätze aus Silikon garantieren eine individuelle Passform sowie eine optimierte Geräuschisolierung für ein noch intensiveres Hörerlebnis.',
Sound_FindYourFit_Medium: 'MITTELGROSS (STANDARD)',
Sound_FindYourFit_Small: 'KLEIN',
Sound_FindYourFit_Large: 'GROSS',
Sound_ChapterText2: 'Die Beats Studio Buds wurden für einen <em>kraftvollen, ausgewogenen Sound</em> in kompaktem Design konzipiert, ermöglicht durch eine <em>speziell entwickelte Akustikplattform.</em>',
Sound_DrivenByPremiumSound_Title: 'Premium Sound',
Sound_DrivenByPremiumSound_Subtitle: 'Eine speziell entwickelte Akustikplattform mit einer innovativen Membran mit zwei Elementen sorgt für klaren, ausgewogenen Sound.',
Sound_MoreMusicLessDistortion_Title: 'Klarer Sound. Geringe Verzerrung.',
Sound_MoreMusicLessDistortion_Subtitle: 'Die Membran besteht aus einem flexiblen Material und sorgt für minimale Verzerrung durch Vibrationen und somit für klaren, präzisen Sound im gesamten Spektrum – insbesondere im Bass.',
Sound_MoreMusicLessDistortion_Diaphragm: 'MEMBRAN',
Sound_FineTunedAudio_Title: 'Audiofeinabstimmung',
Sound_FineTunedAudio_Subtitle: 'Ein digitaler Prozessor mit Wiedergabekorrektur reagiert in Echtzeit, damit Umgebungsgeräusche die Klarheit oder Emotion deiner Musik nicht beeinträchtigen.',
Sound_ChapterText3: 'Telefonanrufe klingen klar dank <em>der hohen Qualität der Anrufe und der verbesserten Sprachqualität.</em> ',
Sound_SpeakingOfSound_Title: 'Exzellente Anrufqualität ',
Sound_SpeakingOfSound_Subtitle: 'Ein spezieller Anschluss isoliert das Mikrofon von externen Windgeräuschen, während wellenbündelnde Mikrofone deine Stimme aufnehmen, um für verbesserte Sprachqualität zu sorgen.',
Sound_Summary_Title: 'Entwickelt, damit Musik so klingt, wie der Künstler es vorgesehen hat',
Sound_Summary1_ThreeEartip: 'Die Ohreinsätze in drei Größen bieten optimale Passform und großartigen Sound.',
Sound_Summary2_CustomAcoustic: 'Die spezielle Akustikplattform bietet hochqualitativen, kraftvollen Sound.',
Sound_Summary3_FlexibleDriverDiaphragm: 'Die flexible Membran sorgt für klaren, präzisen Sound.',
Sound_Summary4_FinetunedAudio: 'Die Audiofeinabstimmung erhält die Klarheit und Emotion deiner Musik.',
Sound_Summary5_SpeakingOfSound: 'Die wellenbündelnden Mikrofone nehmen deine Stimme auf und sorgen für Anrufe in Top-Qualität.',
ANC_Intro_TITLE: 'Aktives Noise-Cancelling ',
ANC_Intro_VOSubtitle: 'Dank aktivem Noise-Cancelling kannst du dich auf die Musik konzentrieren und störende Geräusche unterdrücken.',
ANC_ChapterText1: '<em>Eine bequeme akustische Abdichtung</em> unterdrückt zusätzlich Umgebungsgeräusche, sodass das Hörerlebnis so intensiv wie möglich ist.',
ANC_Seal_Title: 'Akustische Abdichtung',
ANC_Seal_Subtitle: 'Ohreinsätze in drei Größen bieten eine bequeme Passform und sorgen für eine akustische Abdichtung, die unerwünschte Geräusche unterdrückt.',
ANC_ChapterText2: 'ANC mit adaptiver Technologie <em>passt sich an die Umgebung an,</em> während die Wiedergabekorrektur <em>die Klarheit und Emotion deiner Musik erhält.</em>',
ANC_ANCThatAdapts_Title: 'Adaptives ANC',
ANC_ANCThatAdapts_Subtitle: 'Ein fein abgestimmter Filter, der sich dynamisch anpasst, filtert unerwünschte Geräusche und optimiert das Noise-Cancelling in Echtzeit.',
ANC_ANCThatAdapts_AdaptiveANC: 'ADAPTIVES ANC',
ANC_Playback_Title: 'Wiedergabekorrektur',
ANC_Playback_Subtitle: 'Bei aktiviertem Noise-Cancelling analysiert die Wiedergabekorrektur die ursprüngliche Audiodatei 48.000 mal pro Sekunde, sodass ANC nie die Musik beeinträchtigt.',
ANC_Playback_ANCProcess: 'ANC VORGANG',
ANC_Playback_PlaybackCorrection: 'WIEDERGABEKORREKTUR',
ANC_UseCases_Subtitle: 'Klicke oder tippe auf jede Verwendungsmöglichkeit, um mehr zu erfahren.',
ANC_UseCases_Studying: 'Lernen',
ANC_UseCases_Studying_Subtitle: 'Blende störende Geräusche aus, damit du dich besser auf deine Arbeit konzentrieren kannst',
ANC_UseCases_WorkingOut: 'Training',
ANC_UseCases_WorkingOut_Subtitle: 'Vertiefe dich ohne Ablenkungen in dein Training',
ANC_UseCases_Airplane: 'Flugzeug',
ANC_UseCases_Airplane_Subtitle: 'Senke den Geräuschpegel der Passagiere oder eines lauten Antriebs, um den Flug noch mehr zu genießen',
ANC_UseCases_Commute: 'Arbeitsweg',
ANC_UseCases_Commute_Subtitle: 'Höre beim Pendeln weniger Hintergrundgeräusche der Stadt',
ANC_Summary_Title: 'Bleibe mit ANC konzentriert.',
ANC_Summary1_ImmerseSound: 'Intensiver Sound beginnt bei einer bequemen akustischen Abdichtung.',
ANC_Summary2_AdaptiveTechnology: 'Die adaptive Technologie passt sich laufend der Umgebung an.',
ANC_Summary3_PlaybackCorrection: 'Die Wiedergabekorrektur erhält die Klarheit des Sounds.',
ANC_Summary4_UseCases: 'Dank ANC bist du beim Lernen, beim Trainieren, bei Flugreisen oder auf dem Arbeitsweg konzentrierter.',
Transparency_Intro_Title: 'TRANSPARENZMODUS',
Transparency_Intro_VOSubtitle: 'Lass mit dem Transparenzmodus die Welt in deine Ohren.',
Transparency_ChapterText1: 'Wechsle zum Transparenzmodus, wenn du <em>dein Umfeld hören</em> möchtest.',
Transparency_TransparencyMode_Title: 'Transparenzmodus ',
Transparency_TransparencyMode_Subtitle: 'Im Transparenzmodus sind gleichzeitig Umgebungsgeräusche und Musik hörbar, sodass ein natürliches, „offenes“ Hörerlebnis entsteht.',
Transparency_TransparencyMode_PressHold: 'GEDRÜCKT HALTEN',
Transparency_ChapterText2: '<em>Externe Mikrofone</em> nehmen Umgebungsgeräusche auf.',
Transparency_FeedForwardMic_Title: 'Vorwärts gerichtete Mikrofone',
Transparency_FeedForwardMic_Subtitle: 'Ein vorwärts gerichtetes Mikrofon an jedem In-Ear Kopfhörer erkennt Umgebungsgeräusche.',
Transparency_FeedForwardMic_FeedForwardMic: 'VORWÄRTS GERICHTETES MIKROFON',
Transparency_UseCases_Subtitle: 'Klicke oder tippe auf jede Verwendungsmöglichkeit, um mehr zu erfahren.',
Transparency_UseCases_Office: 'Büro',
Transparency_UseCases_Office_Subtitle: 'Bleib bei der Arbeit kollaborativ und höre deine Kollegen, während du Musik hörst',
Transparency_UseCases_Travel: 'Reisen',
Transparency_UseCases_Travel_Subtitle: 'Höre wichtige Durchsagen am Flughafen oder am Bahnhof.',
Transparency_UseCases_Street: 'Straße',
Transparency_UseCases_Street_Subtitle: 'Achte auf den Verkehr in der Umgebung und auf verkehrsreiche Kreuzungen.',
Transparency_Summary_Title: 'Lass mit dem Transparenzmodus die Welt in deine Ohren.',
Transparency_Summary1_MixTheSounds: 'Nimm beim Musikhören auch die Umgebung wahr.',
Transparency_Summary2_FeedForward: 'Vorwärts gerichtete Mikrofone erkennen Umgebungsgeräusche.',
Transparency_Summary3_HearYourSurroundings: 'Höre Umgebungsgeräusche im Büro, beim Reisen oder auf der Straße.',
Design_Intro_Title: 'Design',
Design_Intro_VOSubtitle: 'Die Beats Studio Buds sind komplett kabellos.',
Design_ChapterText1: 'Das kabellose, kompakte Design <em>passt nahtlos in dein Leben.</em>',
Design_WirelessFreedom_Title: 'Kabellose Freiheit. ',
Design_WirelessFreedom_Subtitle: 'Komplett kabellose In-Ear Kopfhörer für volle Bewegungsfreiheit. ',
Design_PocketSizedPortability_Titles: 'Passt problemlos in die Hosentasche',
Design_PocketSizedPortability_Subtitles: 'Das schlanke, leichte Ladecase lässt sich überallhin mitnehmen und passt problemlos in die Hosentasche.',
Design_WaterAndSweatResistant_Title: 'Schweiß- und wasserbeständige In-Ear Kopfhörer*  ',
Design_WaterAndSweatResistant_Subtitle: 'Die In-Ear Kopfhörer verfügen über die IPX4 Zertifizierung für Schweiß- und Wasserbeständigkeit, sodass sie jeder Belastung standhalten.',
Design_WaterAndSweatResistant_Disclaimer: '* Die Beats Studio Buds sind schweiß- und wasserbeständig, aber nicht für Wassersport geeignet.',
Design_ChapterText2: 'Die Beats Studio Buds wurden bei einem breiten Zielpublikum getestet, um <em>eine bequeme, flexible Passform garantieren zu können.</em>',
Design_Comfort_Title: 'Entwickelt für mehr Tragekomfort',
Design_Comfort_Subtitle: 'Die weichen In-Ear Kopfhörer aus Silikon wurden für die Ohranatomie entwickelt, um für Komfort, Stabilität und einen sicheren Halt zu sorgen.',
Design_Comfort_Tragus: 'TRAGUS',
Design_Comfort_Antitragus: 'ANTITRAGUS',
Design_SizeMatters_Title: 'Kompaktes Design',
Design_SizeMatters_Subtitle: 'Das schlanke, kompakte Design der In-Ear Kopfhörer ist deutlich leichter und kleiner als das der meisten anderen aktuellen In-Ear Kopfhörer.',
Design_SizeMatters_BeatsStudio: 'BEATS FLEX PRO',
Design_SizeMatters_BeatsStudio_10_1G: '10,1 G',
Design_SizeMatters_Bose: 'BOSE QC IN-EAR KOPFHÖRER',
Design_SizeMatters_Bose_18_7G: '18,7 G',
Design_SizeMatters_Sony: 'Sony WF-1000XM3',
Design_SizeMatters_Sony_16_5G: '16,5 G',
Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
Design_SizeMatters_Samsung_12_5G: '12,5 G',
Design_ChapterText3: 'Die In-Ear Kopfhörer bleiben <em>sicher und geschützt</em>, wenn sie sich im praktischen Ladecase befinden, das du überallhin mitnehmen kannst.',
Design_PerfectMatch_Title: 'Magnetische Ausrichtung',
Design_PerfectMatch_Subtitle: 'Die Magnete im Ladecase sind so ausgerichtet, dass die In-Ear Kopfhörer jedes Mal korrekt liegen bleiben.',
Design_Contact_Title: 'Genauer Kontakt',
Design_Contact_Subtitle: 'Federkontaktstifte im Case nutzen robuste Blattfedern für den genauen Kontakt zwischen In-Ear Kopfhörern und Case, wodurch die In-Ear Kopfhörer zum Laden korrekt einrasten.',
Design_Contact_PogoPin: 'FEDERKONTAKTSTIFT',
Design_FindYourMatch_Title: 'Finde das passende Ladecase ',
Design_FindYourMatch_Subtitle: 'Farblich passendes Ladecase',
Design_FindYourMatch_White: 'WEISS',
Design_FindYourMatch_Black: 'SCHWARZ',
Design_FindYourMatch_BeatsRed: 'BEATS ROT',
Design_SustainablePackaging_Title: 'Nachhaltige Verpackung',
Design_SustainablePackaging_Subtitle: 'Die Verpackung der Beats Studio Buds besteht zu 92 % aus pflanzlichem Material, das aus recycelten Holzfasern und nachhaltiger Forstwirtschaft gewonnen wird.',
Design_Summary_Title: 'Komplett kabellose Freiheit',
Design_Summary1_WirelessFreedom: 'Komplett kabellose In-Ear Kopfhörer für volle Bewegungsfreiheit. ',
Design_Summary2_PocketSizedPortability: 'Das leichte Ladecase passt problemlos in die Hosentasche.',
Design_Summary3_WaterAndSweatResistant: 'Die In-Ear Kopfhörer verfügen über die IPX4 Zertifizierung für Schweiß- und Wasserbeständigkeit.',
Design_Summary4_Comfort: 'Die schlanken, kompakten In-Ear Kopfhörer bieten Tragekomfort und Stabilität.',
Design_Summary5_SizeMatters: 'Das Design der In-Ear Kopfhörer ist deutlich kleiner und leichter als das der meisten kabellosen In-Ear Kopfhörer.',
Design_Summary6_PerfectMatch: 'Magnete im Ladecase sorgen dafür, dass die In-Ear Kopfhörer jedes Mal korrekt liegen.',
Design_Summary7_Contact: 'Dank der Federkontaktstifte im Case rasten die In-Ear Kopfhörer zum Laden richtig ein.',
Design_Summary8_FindYourMatch: 'Die farblich passenden Ladecases sind in Schwarz, Weiß und Beats Rot erhältlich.',
Compatibility_Intro_Title: 'Kompatibilität',
Compatibility_Intro_VOSubtitle: 'Die Beats Studio Buds sind mit Apple und Android Geräten kompatibel.',
Compatibility_ChapterText1: 'Benutzerfreundliche Verwendung mit <em>Apple und Android Geräten.</em>',
Compatibility_OneTouch_Title: 'Kopplung per einfachem Tastendruck ',
Compatibility_OneTouch_Subtitle: 'Verbinde dank Kopplung per einfachem Tastendruck dein Apple<sup>1</sup> oder Android<sup>2</sup> Gerät mühelos via Bluetooth. ',
Compatibility_OneTouch_Disclaimer: '1 Kopplung per einfachem Tastendruck erfordert iOS 14.6 oder neuer.<br/>2 Für Fast Pair müssen Ortungsdienste aktiviert sein. Der vollständige Funktionsumfang erfordert ein Gerät mit Android 6.0 oder neuer mit aktivierten Google Play-Diensten und ein Google-Konto.',
Compatibility_VoiceAssistant_Title: 'Sprachassistent ',
Compatibility_VoiceAssistant_Subtitle: 'Die Beats Studio Buds sind kompatibel mit deinem Lieblingssprachassistenten, der durch eine benutzerdefinierte Funktion beim Gedrückthalten aktiviert werden kann.',
Compatibility_LocateDevice_Title: 'Gerät orten ',
Compatibility_LocateDevice_Subtitle: 'Mithilfe des Features „Wo ist?“ in iOS oder „Mein Gerät finden“ in Android kannst du deine In-Ear Kopfhörer mühelos orten. ',
Compatibility_USBCCharging_Title: 'Aufladen per USB‑C ',
Compatibility_USBCCharging_Subtitle: 'Dank des mit Apple und Android Geräten kompatiblen USB-C Ladeanschlusses kannst du deine In-Ear Kopfhörer überall laden. ',
Compatibility_ManageDevice_Title: 'Gerät verwalten ',
Compatibility_ManageDevice_Subtitle: 'Verwalte Features wie die benutzerdefinierte Funktion beim Gedrückthalten, Hörmodi, benenne das Produkt um und erhalte Firmware-Updates über dein Apple Gerät oder die Beats App für Android.',
Compatibility_Summary_Title: 'Kompatibel mit Apple und Android.',
Compatibility_Summary1_OneTouch: 'Kopplung per einfachem Tastendruck sowohl für iOS als auch für Android.',
Compatibility_Summary2_VoiceAssistant: 'Aktiviere deinen Lieblingssprachassistenten, um Anrufe, Musik und mehr zu verwalten.',
Compatibility_Summary3_LocateDevice: 'Mithilfe des Geräts kannst du deine In-Ear Kopfhörer mühelos orten.',
Compatibility_Summary4_USBCCharging: 'Der USB-C Ladeanschluss ermöglicht universelles Laden.',
Compatibility_Summary5_ManageDevice: 'Verwalte dein Gerät in iOS oder mit der Beats App für Android.',
Control_Intro_Title: 'Steuerung',
Control_Intro_VOSubtitle: 'Steuere mit den Beats Studio Buds deinen Sound den ganzen Tag lang.',
Control_ChapterText1: 'Steuere den Sound mithilfe <em>mühelos zugänglicher Bedienelemente.</em>',
Control_OnEarControls_Title: 'Bedienelemente am Ohr ',
Control_OnEarControls_Subtitle: 'Dank der Bedienelemente direkt am Ohr kannst du Anrufe und Musik steuern, zwischen Hörmodi wechseln und den Sprachassistenten aktivieren.<sup>*</sup>',
Control_OnEarControls_Disclaimer: '* Um die Funktion zum Gedrückthalten anpassen zu können, ist ein iPhone mit iOS 14.6 oder neuer oder ein Android Gerät mit der neuesten Version der Beats App erforderlich.',
Control_OnEarControls_Instruction: 'Klicke oder tippe zum Fortfahren auf jedes Bedienelement.',
Control_OnEarControls_PlayPause: 'Drücken für Wiedergabe/Pause und Anrufe annehmen/beenden ',
Control_OnEarControls_Skip: 'Taste zweimal drücken, um einen Song zu überspringen ',
Control_OnEarControls_Previous: 'Taste dreimal drücken, um vorherigen Titel zu spielen',
Control_OnEarControls_ScanForward: 'Taste zweimal drücken und halten: vorspulen ',
Control_OnEarControls_ScanBackward: 'Taste dreimal drücken und halten: zurückspulen ',
Control_OnEarControls_ToggleModes: 'Gedrückt halten, um zwischen Hörmodi zu wechseln oder diese zu deaktivieren, oder die Funktion anpassen, um den Sprachassistenten zu aktivieren.<sup>*</sup>',
Control_PickASide_Title: 'Wähle eine Seite',
Control_PickASide_Subtitle: 'Passe die beim Gedrückthalten aktivierte Funktion mithilfe von iOS oder der Beats App für Android an jedem In-Ear Kopfhörer an, um ANC oder den Sprachassistenten zu aktivieren.<sup>*</sup>',
Control_PickASide_Disclaimer: '* Um die Funktion zum Gedrückthalten anpassen zu können, ist ein iPhone mit iOS 14.6 oder neuer oder ein Android Gerät mit der neuesten Version der Beats App erforderlich.',
Control_SayItWithSiri_Title: 'Sag\'s mit Siri ',
Control_SayItWithSiri_Subtitle: 'Mit Siri kannst du jederzeit Musik und Anrufe steuern und die Lautstärke regeln, ohne deine Hände zu benutzen<sup>*</sup>',
Control_SayItWithSiri_Disclaimer: '* Siri ist möglicherweise nicht in allen Sprachen oder Regionen verfügbar. Die Funktionen können je nach Region variieren. Internetzugang erforderlich. Es können Mobilfunkgebühren anfallen. ',
Control_SayItWithSiri_SiriCommand1: '„Hey Siri, spiele meine Lern-Playlist.“',
Control_SayItWithSiri_SiriCommand2: '„Hey Siri, zeige mir von Frauen geführte Unternehmen in der Nähe.“',
Control_SayItWithSiri_SiriCommand3: '„Hey Siri, stelle einen Timer auf 10 Minuten.“',
Control_Summary_Title: 'Steuere den Sound mit den Bedienelementen am Ohr.',
Control_Summary1_OnEarControls: 'Mit den Bedienelementen am Ohr können Anrufe und Musik gesteuert werden.',
Control_Summary2_PickASide: 'Passe die beim Gedrückthalten aktivierte Funktion am linken und rechten In-Ear Kopfhörer an.',
Control_Summary3_SayItWithSiri: 'Aktiviere Siri, ohne deine Hände zu benutzen.',
Battery_Intro_Title: 'Batterie',
Battery_Intro_VOSubtitle: 'Energie für den ganzen Tag dank einer Batterie, die den ganzen Tag lang hält.',
Battery_ChapterText1: '<em>Eine Batterie für den ganzen Tag,</em> mit der du in Bewegung bleibst.',
Battery_FastFuel_Title: 'Bis zu 8 Stunden',
Battery_FastFuel_Subtitle: 'Jeder In-Ear Kopfhörer verfügt über einen hocheffizienten Chip, der bis zu 8 Stunden Wiedergabezeit bietet.<sup>*</sup>',
Battery_FastFuel_Disclaimer: '* Die Batterielaufzeit hängt von den Geräteeinstellungen, der Umgebung, der Verwendung und vielen weiteren Faktoren ab.',
Battery_20Hours_Title: 'Bis zu 24 Stunden',
Battery_20Hours_Subtitle: 'Ein Ladecase im Hosentaschenformat bietet zwei weitere Aufladungen, wodurch insgesamt bis zu 24 Stunden Wiedergabe möglich sind.<sup>*</sup>',
Battery_20Hours_Disclaimer: '* Die Batterielaufzeit hängt von den Geräteeinstellungen, der Umgebung, der Verwendung und vielen weiteren Faktoren ab.',
Battery_FuelYourDay_Title: 'Power für den ganzen Tag',
Battery_FuelYourDay_Subtitle: 'Dank Fast Fuel genügen 5 Minuten Ladezeit für 1 Stunde Wiedergabe, wenn die Batterie fast leer ist.<sup>*</sup>',
Battery_FuelYourDay_Disclaimer: '* Die Batterielaufzeit hängt von den Geräteeinstellungen, der Umgebung, der Verwendung und vielen weiteren Faktoren ab.',
Battery_UseCases_Subtitle: 'Klicke oder tippe auf jede Verwendungsmöglichkeit, um mehr zu erfahren.',
Battery_UseCases_Listen: 'Hören',
Battery_UseCases_Listen_Subtitle: 'Höre dir bis zu 12 Wochen lang deine Lieblingsradiosendungen an.<sup>*</sup>',
Battery_UseCases_Listen_Disclaimer: '* Bei einer Hördauer von 2 Stunden',
Battery_UseCases_Meditate: 'Meditieren',
Battery_UseCases_Meditate_Subtitle: 'Entspanne dich mit 32 Meditationen zu Regengeräuschen.<sup>*</sup>',
Battery_UseCases_Meditate_Disclaimer: '* Bei einer Hördauer von 45 Minuten',
Battery_UseCases_Game: 'Spielen',
Battery_UseCases_Game_Subtitle: 'Spiele 24 Runden deines Lieblingsspiels.<sup>*</sup>',
Battery_UseCases_Game_Disclaimer: '* Bei einer Spielrundendauer von 1 Stunde ohne Einsatz der Mikrofone',
Battery_UseCases_Commute: 'Arbeitsweg',
Battery_UseCases_Commute_Subtitle: 'Höre 24 Tage lang beim Pendeln Audio.<sup>*</sup>',
Battery_UseCases_Commute_Disclaimer: '* Bei einer Fahrtzeit von 1 Stunde. Die Batterielaufzeit hängt von den Geräteeinstellungen, der Umgebung, der Verwendung und vielen weiteren Faktoren ab.',
Battery_WhatsInTheBox_Title: 'Lieferumfang',
Battery_WhatsInTheBox_Subtitle: 'Die Beats Studio Buds werden mit einem Ladecase im Hosentaschenformat, einem universellen USB-C Ladeanschluss und Ohreinsätzen in drei wählbaren Größen ausgeliefert.',
Batter_Summary_Title: 'Die Batterie hält den ganzen Tag.',
Battery_Summary1_PowerYourDay: 'Die In-Ear Kopfhörer bieten bis zu 8 Stunden Wiedergabedauer.',
Battery_Summary2_UpTo24: 'Insgesamt bis zu 24 Stunden Wiedergabe.',
Battery_Summary3_FastFuel: 'Aufladen mit Fast Fuel bietet bis zu 1 Stunde Wiedergabedauer.',
Battery_Summary4_UseCases: 'Ob du Musik hörst, meditierst oder spielst – die Batterie hält den ganzen Tat.',
Battery_Summary5_WhatsInTheBox: 'Die Verpackung enthält ein Ladecase im Hosentaschenformat, einen USB-C Ladeanschluss und Ohreinsätze in drei Größen.',
Menu_Sound_Module1: 'SOUND – EINFÜHRUNG',
Menu_Sound_Module2: 'EIN PERFEKTER SOUND ERFORDERT DIE PERFEKTE PASSFORM',
Menu_Sound_Module3: 'DREI OHREINSÄTZE',
Menu_Sound_Module4: 'KRAFTVOLLER, AUSGEWOGENER SOUND',
Menu_Sound_Module5: 'SPEZIELLE AKUSTIKPLATTFORM',
Menu_Sound_Module6: 'KLARER SOUND. GERINGE VERZERRUNG. ',
Menu_Sound_Module7: 'AUDIOFEINABSTIMMUNG',
Menu_Sound_Module8: 'TELEFONANRUFE ',
Menu_Sound_Module9: 'EXZELLENTE ANRUFQUALITÄT',
Menu_Sound_Module10: 'SOUND – ZUSAMMENFASSUNG',
Menu_ANC_Module1: 'ANC – EINFÜHRUNG',
Menu_ANC_Module2: 'AKUSTISCHE ABDICHTUNG',
Menu_ANC_Module3: 'MIT DER ABDICHTUNG STARTEN',
Menu_ANC_Module4: 'TECHNOLOGIE',
Menu_ANC_Module5: 'ADAPTIVES ANC',
Menu_ANC_Module6: 'WIEDERGABEKORREKTUR',
Menu_ANC_Module7: 'ANC – ANWENDUNGSBEISPIELE',
Menu_ANC_Module8: 'ANC – ZUSAMMENFASSUNG',
Menu_Transparency_Module1: 'TRANSPARENZ – EINFÜHRUNG',
Menu_Transparency_Module2: 'GERÄUSCHE AUS DEM UMFELD HÖREN',
Menu_Transparency_Module3: 'TRANSPARENZMODUS',
Menu_Transparency_Module4: 'EXTERNE MIKROFONE',
Menu_Transparency_Module5: 'VORWÄRTS GERICHTETE MIKROFONE',
Menu_Transparency_Module6: 'TRANSPARENZ – ANWENDUNGSBEISPIELE',
Menu_Transparency_Module7: 'TRANSPARENZ – ZUSAMMENFASSUNG',
Menu_Design_Module1: 'DESIGN – EINFÜHRUNG',
Menu_Design_Module2: 'KABELLOSES, KOMPAKTES DESIGN',
Menu_Design_Module3: 'KABELLOSE FREIHEIT',
Menu_Design_Module4: 'PASST PROBLEMLOS IN DIE HOSENTASCHE',
Menu_Design_Module5: 'SCHWEISS- UND WASSERBESTÄNDIG',
Menu_Design_Module6: 'BEQUEME UND FLEXIBLE PASSFORM',
Menu_Design_Module7: 'ENTWICKELT FÜR MEHR TRAGEKOMFORT',
Menu_Design_Module8: 'KOMPAKTES DESIGN',
Menu_Design_Module9: 'KOMFORTABLES LADECASE',
Menu_Design_Module10: 'MAGNETISCHE AUSRICHTUNG',
Menu_Design_Module11: 'GENAUER KONTAKT',
Menu_Design_Module12: 'FARBEN',
Menu_Design_Module13: 'DESIGN – ZUSAMMENFASSUNG',
Menu_Compatibility_Module1: 'KOMPATIBILITÄT – EINFÜHRUNG',
Menu_Compatibility_Module2: 'KOMPATIBEL MIT APPLE UND ANDROID',
Menu_Compatibility_Module3: 'KOPPLUNG PER EINFACHEM TASTENDRUCK',
Menu_Compatibility_Module4: 'SPRACHASSISTENT',
Menu_Compatibility_Module5: 'GERÄT ORTEN',
Menu_Compatibility_Module6: 'AUFLADEN PER USB‑C ',
Menu_Compatibility_Module7: 'GERÄT VERWALTEN',
Menu_Compatibility_Module8: 'KOMPATIBILITÄT – ZUSAMMENFASSUNG',
Menu_Control_Module1: 'BEDIENUNG – EINFÜHRUNG',
Menu_Control_Module2: 'EINFACHER ZUGRIFF',
Menu_Control_Module3: 'STEUERUNG AM OHR',
Menu_Control_Module4: 'WÄHLE EINE SEITE',
Menu_Control_Module5: 'SAG\'S MIT SIRI',
Menu_Control_Module6: 'BEDIENUNG – ZUSAMMENFASSUNG',
Menu_Battery_Module1: 'BATTERIE – EINFÜHRUNG',
Menu_Battery_Module2: 'BATTERIE FÜR DEN GANZEN TAG',
Menu_Battery_Module3: '8 STUNDEN WIEDERGABE',
Menu_Battery_Module4: 'INSGESAMT 24 STUNDEN WIEDERGABE',
Menu_Battery_Module5: 'FAST FUEL',
Menu_Battery_Module6: 'BATTERIE – ANWENDUNGSBEISPIELE',
Menu_Battery_Module7: 'LIEFERUMFANG',
Menu_Battery_Module8: 'BATTERIE – ZUSAMMENFASSUNG',
Outro_ChapterText1: 'Dank Aktivem Noise-Cancelling, Transparenzmodus, erstklassigem Sound und einem kabellosen <em>Design im Hosentaschenformat passen die Beats Studio Buds nahtlos in dein Leben.</em>',
IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
Global_Country_ALBANIA: 'ALBANIEN',
Global_Country_ALGERIA: 'ALGERIEN',
Global_Country_ANDORRA: 'ANDORRA',
Global_Country_ANGOLA: 'ANGOLA',
Global_Country_ARGENTINA: 'ARGENTINIEN',
Global_Country_ARMENIA: 'ARMENIEN',
Global_Country_ARUBA: 'ARUBA',
Global_Country_AUSTRALIA: 'AUSTRALIEN',
Global_Country_AUSTRIA: 'ÖSTERREICH',
Global_Country_AZERBAIJAN: 'ASERBAIDSCHAN',
Global_Country_BAHAMAS: 'BAHAMAS',
Global_Country_BAHRAIN: 'BAHREIN',
Global_Country_BANGLADESH: 'BANGLADESCH',
Global_Country_BARBADOS: 'BARBADOS',
Global_Country_BELARUS: 'BELARUS',
Global_Country_BELGIUM: 'BELGIEN',
Global_Country_BOLIVIA: 'BOLIVIEN',
Global_Country_BOSNIAHERZRGOVINA: 'BOSNIEN UND HERZEGOWINA',
Global_Country_BOTSWANA: 'BOTSUANA',
Global_Country_BRAZIL: 'BRASILIEN',
Global_Country_BRUNEI: 'BRUNEI DARUSSALAM',
Global_Country_BULGARIA: 'BULGARIEN',
Global_Country_CAMBODIA: 'KAMBODSCHA',
Global_Country_CAMEROON: 'KAMERUN',
Global_Country_CANADA: 'KANADA',
Global_Country_CAYMANISLANDS: 'KAIMANINSELN',
Global_Country_CHILE: 'CHILE',
Global_Country_CHINA : 'CHINA (FESTLAND)',
Global_Country_COLOMBIA: 'KOLUMBIEN',
Global_Country_COOKISLANDS: 'COOKINSELN',
Global_Country_COSTARICA: 'COSTA RICA',
Global_Country_CROATIA: 'KROATIEN',
Global_Country_CYPRUS: 'ZYPERN',
Global_Country_CZECHREPUBLIC: 'TSCHECHISCHE REPUBLIK',
Global_Country_DENMARK: 'DÄNEMARK',
Global_Country_DOMINICANREPUBLIC: 'DOMINIKANISCHE REPUBLIK',
Global_Country_ECUADOR: 'ECUADOR',
Global_Country_EGYPT: 'ÄGYPTEN',
Global_Country_ELSALVADOR: 'EL SALVADOR',
Global_Country_ESTONIA: 'ESTLAND',
Global_Country_FAROEISLANDS: 'FÄRÖER',
Global_Country_FIJI: 'FIDSCHI',
Global_Country_FINLAND: 'FINNLAND',
Global_Country_FRANCE: 'FRANKREICH',
Global_Country_FRENCHGUIANA: 'FRANZÖSISCH-GUAYANA',
Global_Country_FRENCHPOLYNESIA: 'FRANZÖSISCH-POLYNESIEN',
Global_Country_GEORGIA: 'GEORGIEN',
Global_Country_GERMANY: 'DEUTSCHLAND',
Global_Country_GHANA: 'GHANA',
Global_Country_GIBRALTAR: 'GIBRALTAR',
Global_Country_GREECE: 'GRIECHENLAND',
Global_Country_GUADELOUPE: 'GUADELOUPE',
Global_Country_GUAM: 'GUAM',
Global_Country_GUATEMALA: 'GUATEMALA',
Global_Country_GUERNSEY: 'GUERNSEY',
Global_Country_GUINEA: 'GUINEA',
Global_Country_GUYANA: 'GUYANA',
Global_Country_HONDURAS: 'HONDURAS',
Global_Country_HONGKONG: 'HONGKONG',
Global_Country_HUNGARY: 'UNGARN',
Global_Country_ICELAND: 'ISLAND',
Global_Country_INDIA: 'INDIEN',
Global_Country_INDONESIA: 'INDONESIEN',
Global_Country_IRAQ: 'IRAK',
Global_Country_IRELAND: 'IRLAND',
Global_Country_ISLEOFMAN: 'ISLE OF MAN',
Global_Country_ISRAEL: 'ISRAEL',
Global_Country_ITALY: 'ITALIEN',
Global_Country_IVORYCOAST: 'ELFENBEINKÜSTE',
Global_Country_JAPAN: 'JAPAN',
Global_Country_JERSEY: 'JERSEY',
Global_Country_JORDAN: 'JORDANIEN',
Global_Country_KAZAKHSTAN: 'KASACHSTAN',
Global_Country_KENYA: 'KENIA',
Global_Country_KOREA: 'KOREA, REPUBLIK',
Global_Country_KUWAIT: 'KUWAIT',
Global_Country_KYRGYZSTAN: 'KIRGISISTAN',
Global_Country_LAOS: 'LAOS',
Global_Country_LATVIA: 'LETTLAND',
Global_Country_LEBANON: 'LIBANON',
Global_Country_LESOTHO: 'LESOTHO',
Global_Country_LITHUANIA: 'LITAUEN',
Global_Country_LUXEMBOURG: 'LUXEMBURG',
Global_Country_MACAU: 'MACAU',
Global_Country_MACEDONIA: 'NORDMAZEDONIEN',
Global_Country_MADAGASCAR: 'MADAGASKAR',
Global_Country_MALAWI: 'MALAWI',
Global_Country_MALAYSIA: 'MALAYSIA',
Global_Country_MALDIVES: 'MALEDIVEN',
Global_Country_MALTA: 'MALTA',
Global_Country_MARTINIQUE: 'MARTINIQUE',
Global_Country_MAURITIUS: 'MAURITIUS',
Global_Country_MAYOTTE: 'MAYOTTE',
Global_Country_MEXICO: 'MEXIKO',
Global_Country_MOLDOVA: 'MOLDAWIEN',
Global_Country_MONACO: 'MONACO',
Global_Country_MONTENEGRO: 'MONTENEGRO',
Global_Country_MOROCCO: 'MAROKKO',
Global_Country_MOZAMBIQUE: 'MOSAMBIK',
Global_Country_MYANMAR: 'MYANMAR',
Global_Country_NAMIBIA: 'NAMIBIA',
Global_Country_NEPAL: 'NEPAL',
Global_Country_NETHERLANDS: 'NIEDERLANDE',
Global_Country_NEWCALEDONIA: 'NEUKALEDONIEN',
Global_Country_NEWZEALAND: 'NEUSEELAND',
Global_Country_NICARAGUA: 'NICARAGUA',
Global_Country_NIGERIA: 'NIGERIA',
Global_Country_NORWAY: 'NORWEGEN',
Global_Country_OMAN: 'OMAN',
Global_Country_PAKISTAN: 'PAKISTAN',
Global_Country_PALESTINIANTERRITORY: 'PALÄSTINENSISCHE AUTONOMIEGEBIETE',
Global_Country_PANAMA: 'PANAMA',
Global_Country_PARAGUAY: 'PARAGUAY',
Global_Country_PERU: 'PERU',
Global_Country_PHILIPPINES: 'PHILIPPINEN',
Global_Country_POLAND: 'POLEN',
Global_Country_PORTUGAL: 'PORTUGAL',
Global_Country_PUERTORICO: 'PUERTO RICO',
Global_Country_QATAR: 'KATAR',
Global_Country_REUNION: 'RÉUNION',
Global_Country_ROMANIA: 'RUMÄNIEN',
Global_Country_RUSSIA: 'RUSSLAND',
Global_Country_SAINTMARTIN: 'ST. MARTIN',
Global_Country_SANMARINO: 'SAN MARINO',
Global_Country_SAUDIARABIA: 'SAUDI-ARABIEN',
Global_Country_SENEGAL: 'SENEGAL',
Global_Country_SERBIA: 'SERBIEN',
Global_Country_SINGAPORE: 'SINGAPUR',
Global_Country_SINTMAARTEN: 'SINT MAARTEN',
Global_Country_SLOVAKIA: 'SLOWAKEI',
Global_Country_SLOVENIA: 'SLOWENIEN',
Global_Country_SOUTHAFRICA: 'SÜDAFRIKA',
Global_Country_SPAIN: 'SPANIEN',
Global_Country_SRILANKA: 'SRI LANKA',
Global_Country_SWEDEN: 'SCHWEDEN',
Global_Country_SWITZERLAND: 'SCHWEIZ',
Global_Country_TAIWAN: 'TAIWAN',
Global_Country_TAJIKISTAN: 'TADSCHIKISTAN',
Global_Country_TANZANIA: 'TANSANIA',
Global_Country_THAILAND: 'THAILAND',
Global_Country_TRINIDADTOBAGO: 'TRINIDAD UND TOBAGO',
Global_Country_TUNISIA: 'TUNESIEN',
Global_Country_TURKEY: 'TÜRKEI',
Global_Country_TURKMENISTAN: 'TURKMENISTAN',
Global_Country_UGANDA: 'UGANDA',
Global_Country_UKRAINE: 'UKRAINE',
Global_Country_UAE: 'VEREINIGTE ARABISCHE EMIRATE',
Global_Country_UK: 'VEREINIGTES KÖNIGREICH',
Global_Country_US: 'USA',
Global_Country_URUGUAY: 'URUGUAY',
Global_Country_UZBEKISTAN: 'USBEKISTAN',
Global_Country_VENEZUELA: 'VENEZUELA',
Global_Country_VIETNAM: 'VIETNAM',
Global_Country_ZAMBIA: 'SAMBIA',
Global_Country_ZIMBABWE: 'SIMBABWE',
Global_Menu_Confirm: 'Bestätigen',
Global_Menu_Help: 'Brauchst du Hilfe? <u>Frage uns</u>',
Global_Menu_Restart: 'ERLEBNIS NEU BEGINNEN',
Global_Menu_Button_Restart: "Einheit neu starten",
IntroOutro_TestYourKnowledge: 'Überprüfe dein Wissen über die Beats Studio Buds.',
IntroOutro_CTA: 'Mach das Quiz',
Global_Menu_Language: 'SPRACHE',
Global_Password_Rules_Header: 'Passwort muss Folgendes enthalten',
Global_Password_Rule_Length: 'Mindestens 8 Zeichen',
Global_Password_Rule_Uppercase: '1 Großbuchstaben',
Global_Password_Rule_Lowercase: '1 Kleinbuchstaben',
Global_Password_Rule_Number: '1 Zahl',
Global_Password_Rule_Special: 'Sonderzeichen (!@#$%^&*)',
Global_Field_Password_CreateError: 'Passwort erfüllt nicht die Mindestanforderungen.',
Global_ForgotEmail_NoUser:"Es gibt keine Benutzerdaten, die dieser Kennung entsprechen. Möglicherweise wurde der Benutzer gelöscht."


};

export default copy;
