import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import BaseModule from '../BaseModule';
import { AnimatePresence, motion } from 'framer-motion';
import Styles from './TransparencyModule.module.scss';
import { createMarkup } from '../../../utils';
import Context from '../../../context/Context';
import ReplayButton from '../../ReplayButton';
import Hotspot from './components/Hotspot';
import useCanvasSize from '../../../hooks/useCanvasSize';
import {
  detectInteraction,
  clearDetectInteraction,
} from '../../../utils/detectInteractions';

export default function TransparencyModule(props) {
  const [isDefault, setDefault] = useState(true);
  const { module, variant, videoContext, introVideoFinished } = props;
  const CANVAS_SIZE = useCanvasSize();
  const { content, alternateTheme } = module;
  const {
    video,
    loop,
    videoTransitionToAlternate,
    loopAlternate,
    videoTransitionToDefault,
    hotspotCoordinates
  } = content;
  const context = useContext(Context);
  const { language, setTheme, theme } = context;
  const replaceCopyTitleNotifier = 'Why not update title?';
  const replaceCopyBodyNotifier = 'Why not update the body copy?';
  let canvasInterval;
  let pressDelayTimeout;
  let isDefaultMode = true; // Else is alternate mode
  let transitionInProgress = false;

  const drawVideoFrame = video => {
    if (video.paused) {
      clearInterval(canvasInterval);
    } else {
      videoContext.drawImage(video, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
    }
  };

  const clearVideoFrame = () => {
    if (videoContext) {
      videoContext.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
    }
  };

  const handlePause = () => {
    clearInterval(canvasInterval);
  };
  const handlePlay = vidEl => {
    clearInterval(canvasInterval);
    canvasInterval = window.setInterval(() => {
      drawVideoFrame(vidEl);
    }, 1000 / 30);
  };

  const pauseAllVideos = () => {
    [
      video,
      videoTransitionToDefault,
      videoTransitionToAlternate,
      loop,
      loopAlternate,
    ].forEach(vid => {
      if (vid && vid.videoElement) {
        vid.videoElement.pause();
      }
    });
  };

  const switchMode = () => {
    if (transitionInProgress) {
      return;
    }
    // TrackEvent(ga4, `transparency_click`, 'click', 'user_action');

    transitionInProgress = true;
    clearTimeout(pressDelayTimeout);
    isDefaultMode = !isDefaultMode;

    setDefault(isDefaultMode);
    alternateTheme && setTheme(!isDefaultMode ? 'dark' : 'light');

    const transitionVideo = isDefaultMode
      ? videoTransitionToDefault
      : videoTransitionToAlternate;
    const loopVideo = isDefaultMode ? loop : loopAlternate;

    const videoElement = transitionVideo.videoElement;
    const loopVideoElement = loopVideo.videoElement;

    const handleTransitionVideoEnded = () => {
      clearInterval(canvasInterval);

      videoElement.currentTime = 0;
      videoElement.pause();
      videoElement.onplay = null;

      loopVideoElement.currentTime = 0;
      loopVideoElement.pause();
      loopVideoElement.play();
      transitionInProgress = false;
    };

    pauseAllVideos();

    pressDelayTimeout = setTimeout(() => {
      videoElement.onplay = () => handlePlay(videoElement);
      videoElement.onpause = handlePause;
      videoElement.onended = handleTransitionVideoEnded;

      loopVideoElement.onplay = () => handlePlay(loopVideoElement);
      loopVideoElement.onpause = handlePause;

      videoElement.currentTime = 0;
      videoElement.pause();
      videoElement.play();
    }, 10);
  };

  const renderModeInfo = () => {
    if (isDefault) {
      return (
        <>
          <ReactSVG
            src={
              process.env.PUBLIC_URL +
              '/assets/images/interactive/icon-controls-canceling.svg'
            }
          />
          {content.labelMode && (
            <span>
              {language[content.labelMode.default]
                ? language[content.labelMode.default]
                : replaceCopyBodyNotifier}
            </span>
          )}
        </>
      );
    }

    return (
      <>
        <ReactSVG
          src={
            process.env.PUBLIC_URL +
            '/assets/images/interactive/icon-controls-transparency.svg'
          }
        />
        {content.labelMode && (
          <span>
            {language[content.labelMode.alternate]
              ? language[content.labelMode.alternate]
              : replaceCopyBodyNotifier}
          </span>
        )}
      </>
    );
  };

  useEffect(() => {
    return () => {
      pauseAllVideos();
      clearInterval(canvasInterval);
      clearTimeout(pressDelayTimeout);
      clearVideoFrame();
      clearDetectInteraction();
    };
  }, []);

  const hotspotRef = useCallback(ref => {
    if (ref && videoContext) {
      detectInteraction('hold', ref, switchMode);
    }
  }, [videoContext]);

  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        'TransparencyModule'
      )}
    >
      <div className={Styles.container}>
        <AnimatePresence>
          {hotspotCoordinates && introVideoFinished && (
            <Hotspot
              ref={hotspotRef}
              hotspotCoordinates={hotspotCoordinates}
              origin={videoContext?.canvas}
            />
          )}
        </AnimatePresence>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.title]
              ? language[module.content.title]
              : replaceCopyTitleNotifier
          )}
        />
        <div className={Styles.text}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.text]
                ? language[module.content.text]
                : replaceCopyBodyNotifier
            )}
          />
          {module.content.textCTA && (
            <a
              className={clsx('ctaButton', Styles.textCTA)}
              href={module.content.textCTA.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language[module.content.textCTA.label]
                ? language[module.content.textCTA.label]
                : replaceCopyBodyNotifier}
            </a>
          )}
          {module.content.disclaimer && (
          <div
            className={ `${Styles.disclaimer} ${theme ? Styles[theme] : Styles.light}`}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}
        </div>

        {variant === 'leftAlign' && (
          <ReplayButton
            videoElement={module.content.video.videoElement}
            audioObject={module.content.voiceover}
            attachToGlobalCanAdvance
          />
        )}

        <div className={Styles.currentMode}>
          <motion.div
            key={isDefault}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {renderModeInfo()}
          </motion.div>
        </div>
      </div>
    </BaseModule>
  );
}
