const copy = {
	Global_Menu_Button_SelectProduct: "別の製品を選択する",

	Global_ProductSelector_Headline: "Beats TRNR体験を選択",
Global_ProductSelector_Product1: "Beats Studio Buds",
Global_ProductSelector_Product2: "Beats Fit Pro",
Global_ProductSelector_CTA_1: "始める",
Global_ProductSelector_CTA_2: "再開する",
Global_ProductSelector_CTA_3: "やり直す",
Global_WelcomeInstructions_Headline: "Beats TRNRへようこそ。Beatsのエキスパートへの道のりはここからスタートします。",
Global_WelcomeInstructions_Subhead: "今後の流れは以下の通りです。",
Global_WelcomeInstructions_Body1: "各トレーニングは15分ほどで終わります。",
Global_WelcomeInstructions_Body2: "トレーニングではそれぞれサウンドが再生されます。サウンドが「オン」になっていることを確認してください。",
Global_WelcomeInstructions_Body3: "トレーニング体験を終了したら、先に進む前に各セクションを完了してください。",
Global_WelcomeInstructions_Body4: "トレーニングを中断することも可能です。その場合、後で中断したところから再生できます。",
	Global_UI_Next: '次へ',
Global_UI_Begin: '開始',
Global_UI_Back: '戻る',
Global_UI_Settings: '設定',
Global_UI_Signout: 'サインアウト',
Global_UI_NextChapter: '次のチャプターへ',
Global_UI_PercentageComplete: '<<number>>%完了',
Global_UI_Replay: 'もう一度見る',
Global_Field_Location: 'ロケーション',
Global_Languages_Select: '言語を選択：',
Global_Field_EnterEmail: 'Eメールアドレスを入力してください',
Global_Field_CreatePassword: 'パスワードを作成してください',
Global_Field_Password: 'パスワード',
Global_Field_Password_Forgot: 'パスワードを忘れた場合',
Global_Password_Subtitle: 'パスワードは8文字以上で、大文字、小文字、数字、記号を含める必要があります。',
Global_Password_Change: '変更',
Global_Password_Reset_Headline: 'パスワードをリセット',
Global_Password_Reset_Subtitle: 'パスワードのリセット方法を受け取るEメールアドレスを入力してください。',
Global_Password_Reset_Confirm: '送信されました。Eメールをご確認ください。',
Global_Password_ChangeConfirm: 'パスワードがアップデートされました',
Global_Password_Show: '表示',
Global_Password_Hide: '非表示',
Global_Details_Title: '詳細情報を入力してください',
Global_Details_Field_FirstName: '名',
Global_Details_Field_LastName: '姓',
Global_Details_Field_EmployeeID: '従業員ID（任意）',
Global_Details_Field_BusinessRole: '職務',
Global_Details_Field_BusinessRole1: 'マネージャー',
Global_Details_Field_BusinessRole2: '営業担当者',
Global_Details_Field_BusinessRole3: 'トレーナー',
Global_Details_Field_CompanyName: '会社名：',
Global_Details_Field_Country: '国',
Global_Field_Password_Error: '入力されたパスワードが間違っています。もう一度お試しください。',
Global_Field_Email_Error: '入力されたEメールアドレスは無効です。もう一度お試しください。',
Global_Field_Details_Error: 'すべての必須フィールドに入力してください。',
Global_Greeting: '<<name>>さん、',
Global_CompletionMessage: '<<number>>%完了しました。',
Global_Chapter: 'チャプター<<number>>',
 Global_Chapter_Subtitle: 'チャプター<<number>>／<<total>>',
Global_Intro: 'はじめに',
Global_ChapterTitle_Sound: 'サウンド',
Global_ChapterTitle_ANC: 'ANC',
Global_ChapterTitle_Transparency: '外部音取り込みモード',
Global_ChapterTitle_Design: 'デザイン',
Global_ChapterTitle_Compatibility: '互換性',
Global_ChapterTitle_Controls: 'コントロール',
Global_ChapterTitle_Battery: 'バッテリー',
Global_Menu: 'メニュー',
Intro_Welcome_Label: '体験',
Intro_Welcome_ChapterText: 'Beats Studio BudsのトレーニングAppにようこそ。クリック／タップして始めましょう。',
Intro_Welcome_ReturningUser: '前回終了したところから再開しますか？クリック／タップして、Beats Studio Budsのトレーニングを続けましょう。',
CR_TRNR_Intro_Sorry: '申し訳ございませんが、問題が発生しました。スーパーバイザーから新しいアクセスリンクを入手してください。',
Intro_Intro_Subtitle: 'Beats Studio Buds、登場。スタジオで生まれたサウンドをそのままお届けする、完全ワイヤレスのノイズキャンセリングイヤーバッド。クリック／タップして、詳しく確認してください。',
Sound_Intro_Title: 'サウンド',
Sound_Intro_VOSubtitle: '最新のテクノロジーが、パワフルでバランスに優れたサウンドを実現します。',
Sound_ChapterText1: '素晴らしいサウンドには、<em>快適なフィット感と密閉性が大切です。</em>',
Sound_FindYourFit_Title: '自分だけのフィット感で音楽を体験しよう',
Sound_FindYourFit_Subtitle: '3つのサイズのシリコーン製イヤーチップにより、自分に合ったフィット感と密閉性を確保。臨場感あふれるサウンドが楽しめます。',
Sound_FindYourFit_Medium: 'ミディアム（デフォルト）',
Sound_FindYourFit_Small: 'スモール',
Sound_FindYourFit_Large: 'ラージ',
Sound_ChapterText2: 'Beats Studio Budsはコンパクトな設計ながら、<em>独自のアコースティックプラットフォームにより、</em><em>パワフルでバランスに優れたサウンドを実現。</em>',
Sound_DrivenByPremiumSound_Title: '心を動かす、極上のサウンド',
Sound_DrivenByPremiumSound_Subtitle: '2枚の振動板を使用した独自のドライバをアコースティックプラットフォームに搭載。クリアでバランスに優れたサウンドをお届けします。',
Sound_MoreMusicLessDistortion_Title: 'クリアなサウンド。歪みを低減。',
Sound_MoreMusicLessDistortion_Subtitle: 'ドライバの振動板には柔軟な素材が使用されており、振動による歪みが低減されるため、特に低音域を含む音域全体で、クリアで緻密に再現されたサウンドを実現します。',
Sound_MoreMusicLessDistortion_Diaphragm: '振動板',
Sound_FineTunedAudio_Title: '緻密なオーディオチューニング',
Sound_FineTunedAudio_Subtitle: '再生音の補正機能を備えたデジタルプロセッサが、周囲の音がサウンドの明瞭さや質感を損なわないよう、リアルタイムに応答します。',
Sound_ChapterText3: '<em>高品質な通話性能とよりクリアな音声により、</em>通話の聞きやすさが向上しました。 ',
Sound_SpeakingOfSound_Title: '高い通話品質 ',
Sound_SpeakingOfSound_Subtitle: '特別設計のポートが外部の雑音を切り離し、デュアルビームフォーミングマイクがユーザーの声を認識することでクリアな音声を実現します。',
Sound_Summary_Title: 'アーティストが届けたいサウンドを再現するために',
Sound_Summary1_ThreeEartip: '3種類のサイズから選べるイヤーチップで、優れたサウンドをぴったりのフィット感で楽しめます。',
Sound_Summary2_CustomAcoustic: '独自のアコースティックプラット<br/>フォームが、高品質でパワフルな<br/>サウンドを実現します。',
Sound_Summary3_FlexibleDriverDiaphragm: '柔軟なドライバの振動板が、クリアで緻密なサウンドを再現します。',
Sound_Summary4_FinetunedAudio: '緻密なオーディオチューニングが、サウンドの明瞭さと質感を再現します。',
Sound_Summary5_SpeakingOfSound: 'デュアルビームフォーミングマイクがユーザーの声を認識し、高品質な通話性能を実現します。',
ANC_Intro_TITLE: 'アクティブノイズキャンセリング ',
ANC_Intro_VOSubtitle: 'アクティブノイズキャンセリング機能が不要な音を遮断するので、集中力をキープできます。',
ANC_ChapterText1: '<em>快適性と密閉性を兼ね備えたイヤーチップが</em>周囲の雑音を遮断するので、臨場感あふれるサウンドを楽しめます。',
ANC_Seal_Title: '密閉性を確保',
ANC_Seal_Subtitle: 'イヤーチップは3種類のサイズから選べます。自分にぴったりのイヤーチップが密閉性を確保し、不要な雑音を遮断してくれます。',
ANC_ChapterText2: 'アダプティブテクノロジーによるANCが<em>周囲の環境に適応し、</em>再生音の補正機能によって<em>サウンドの明瞭さと質感が再現されます。</em>',
ANC_ANCThatAdapts_Title: '周囲の環境に適応するANC',
ANC_ANCThatAdapts_Subtitle: '緻密にチューニングされた<br/>フィルターが周囲の環境に継続的に適応することで、不要な雑音を遮断し、リアルタイムでノイズキャンセリングを最適化します。',
ANC_ANCThatAdapts_AdaptiveANC: 'アダプティブANC',
ANC_Playback_Title: '再生音の補正機能',
ANC_Playback_Subtitle: 'ノイズキャンセリングが適用された場合にも、ANCによってサウンドが損なわれないよう、再生音の補正機能がオリジナルのオーディオファイルとの比較を1秒間に48,000回行います。',
ANC_Playback_ANCProcess: 'ANCのプロセス',
ANC_Playback_PlaybackCorrection: '再生音の補正機能',
ANC_UseCases_Subtitle: '各ユースケースをクリック／タップして、詳しく確認してください。 ',
ANC_UseCases_Studying: '勉強',
ANC_UseCases_Studying_Subtitle: '不要な音を遮断するので、勉強に集中できます',
ANC_UseCases_WorkingOut: 'ワークアウト',
ANC_UseCases_WorkingOut_Subtitle: '何にも邪魔されることなく、ワークアウトに没頭できます',
ANC_UseCases_Airplane: '飛行機',
ANC_UseCases_Airplane_Subtitle: '乗客の声やうるさいエンジン音を遮断するので、フライトをもっと楽しめます',
ANC_UseCases_Commute: '通勤',
ANC_UseCases_Commute_Subtitle: '街中の喧騒を気にすることなく通勤できます',
ANC_Summary_Title: 'ANCで集中力をキープ。',
ANC_Summary1_ImmerseSound: '臨場感あふれるサウンドには、快適性と密閉性が必須です。',
ANC_Summary2_AdaptiveTechnology: 'アダプティブテクノロジーが、周囲の環境に継続的に適応します。',
ANC_Summary3_PlaybackCorrection: '再生音の補正機能が、クリアなサウンドを維持します。',
ANC_Summary4_UseCases: '勉強やワークアウト中、飛行機の中、通勤時などにANCをオンにすれば、集中力をキープできます。',
Transparency_Intro_Title: '外部音取り込みモード ',
Transparency_Intro_VOSubtitle: '外部音取り込みモードにすれば周囲とつながっていられます。',
Transparency_ChapterText1: '<em>周囲の音を聞く必要がある場合は</em>外部音取り込みモードに切り替えられます。',
Transparency_TransparencyMode_Title: '外部音取り込みモード ',
Transparency_TransparencyMode_Subtitle: '外部音取り込みモードが周囲の音を音楽に取り込むので、自然な環境で音楽を聴くことができます。',
Transparency_TransparencyMode_PressHold: '長押し',
Transparency_ChapterText2: '<em>外部マイクが</em>周囲の雑音を検知します。',
Transparency_FeedForwardMic_Title: 'フィードフォワード用のマイク',
Transparency_FeedForwardMic_Subtitle: '各イヤーバッドに搭載されたフィードフォワード用のマイクが、外部の雑音を検知します。',
Transparency_FeedForwardMic_FeedForwardMic: 'フィードフォワード用のマイク',
Transparency_UseCases_Subtitle: '各ユースケースをクリック／タップして、詳しく確認してください。 ',
Transparency_UseCases_Office: 'オフィス',
Transparency_UseCases_Office_Subtitle: '音楽を聴きながらでも、同僚の声を聞き取り共同作業ができます。',
Transparency_UseCases_Travel: '旅行',
Transparency_UseCases_Travel_Subtitle: '空港のゲートや電車のプラットフォームで重要なアナウンスを聞き逃すことがありません。',
Transparency_UseCases_Street: '道路',
Transparency_UseCases_Street_Subtitle: '周囲の車や交差点に気を配っていられます。',
Transparency_Summary_Title: '外部音取り込みモードにすれば周囲とつながっていられます。',
Transparency_Summary1_MixTheSounds: '周囲の音を音楽に取り込みます。',
Transparency_Summary2_FeedForward: 'フィードフォワード用のマイクが、周囲の雑音を検知します。',
Transparency_Summary3_HearYourSurroundings: 'オフィスでも、旅行中でも、道路でも、周囲の状況を把握できます。',
Design_Intro_Title: 'デザイン',
Design_Intro_VOSubtitle: 'Beats Studio Budsは完全ワイヤレスのイヤフォンです。',
Design_ChapterText1: 'ワイヤレスでコンパクトなデザインが、<em>生活のあらゆるシーンに溶け込みます。</em>',
Design_WirelessFreedom_Title: 'ワイヤレスという自由 ',
Design_WirelessFreedom_Subtitle: '動きを制限しない、完全ワイヤレスのイヤーバッド。 ',
Design_PocketSizedPortability_Titles: 'ポケットサイズで持ち運びが簡単',
Design_PocketSizedPortability_Subtitles: 'スリムで軽量の充電ケースは持ち運びやすく、ポケットにすっぽりと収まります。',
Design_WaterAndSweatResistant_Title: '耐汗耐水仕様のイヤーバッド*  ',
Design_WaterAndSweatResistant_Subtitle: 'イヤフォンはIPX4等級の耐汗耐水性能を備えているので、さまざまな活動に対応できます。',
Design_WaterAndSweatResistant_Disclaimer: '* Beats Studio Budsは、ウォータースポーツ以外のスポーツやエクササイズに対応可能な耐汗耐水性能を備えています。',
Design_ChapterText2: 'Beats Studio Budsは幅広いユーザーでテストされており、<em>快適で柔軟なフィット感を提供します。</em>',
Design_Comfort_Title: '快適な装着感',
Design_Comfort_Subtitle: 'ソフトなシリコーン製イヤーバッドは、耳の構造に基づいて設計されており、快適性、安定性、高い密閉性を実現します。',
Design_Comfort_Tragus: '耳珠',
Design_Comfort_Antitragus: '対珠',
Design_SizeMatters_Title: 'コンパクトなデザイン',
Design_SizeMatters_Subtitle: '洗練されたコンパクトなインイヤー型デザインで、他社のほとんどのワイヤレスイヤーバッドよりもさらに軽量で小型です。',
Design_SizeMatters_BeatsStudio: 'BEATS FLEX PRO',
Design_SizeMatters_BeatsStudio_10_1G: '10.1G',
Design_SizeMatters_Bose: 'BOSE QC EARBUDS',
Design_SizeMatters_Bose_18_7G: '18.7G',
Design_SizeMatters_Sony: 'Sony WF-1000XM3',
Design_SizeMatters_Sony_16_5G: '16.5G',
Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
Design_SizeMatters_Samsung_12_5G: '12.5G',
Design_ChapterText3: '一日中持ち運べる便利な充電ケースで、<em>イヤーバッドを安全に保護できます。</em>',
Design_PerfectMatch_Title: 'マグネットによる固定',
Design_PerfectMatch_Subtitle: '充電ケース内に配置されたマグネットにより、イヤーバッドが正しい位置に確実にはまるように設計されています。',
Design_Contact_Title: '正確な接触点',
Design_Contact_Subtitle: 'ケース内に取り付けられたポゴピンには丈夫な板バネが使用されており、イヤーバッドとケースがしっかりと接触するように設計されているため、イヤーバッドが固定され確実に充電することができます。',
Design_Contact_PogoPin: 'ポゴピン',
Design_FindYourMatch_Title: '選べるカラー ',
Design_FindYourMatch_Subtitle: '同色の充電ケースが同梱されています。',
Design_FindYourMatch_White: 'ホワイト',
Design_FindYourMatch_Black: 'ブラック',
Design_FindYourMatch_BeatsRed: 'Beatsレッド',
Design_SustainablePackaging_Title: '持続可能なパッケージ',
Design_SustainablePackaging_Subtitle: 'Beats Studio Budsのパッケージに使われている木製繊維の92%は、再生資源と責任ある方法で管理された資源から調達しています。',
Design_Summary_Title: '完全ワイヤレスの設計',
Design_Summary1_WirelessFreedom: '動きを制限しない、完全ワイヤレスのイヤーバッド。 ',
Design_Summary2_PocketSizedPortability: '軽量の充電ケースは、ポケットに<br/>すっぽりと収まります。',
Design_Summary3_WaterAndSweatResistant: 'イヤーバッドは、IPX4等級の耐汗耐水性能を備えています。',
Design_Summary4_Comfort: '洗練されたコンパクトなイヤーバッドが、快適性と安定性を実現します。',
Design_Summary5_SizeMatters: 'ほとんどのワイヤレスイヤーバッドよりも、さらに軽量で小型のインイヤー型デザインです。',
Design_Summary6_PerfectMatch: '充電ケース内にマグネットが配置されているので、イヤーバッドが正しい位置に確実にはまります。',
Design_Summary7_Contact: 'ケース内のポゴピンにより、イヤーバッドを正しく固定して充電できます。',
Design_Summary8_FindYourMatch: '同色の充電ケース（ブラック、ホワイト、Beatsレッド）が同梱されています。',
Compatibility_Intro_Title: '互換性',
Compatibility_Intro_VOSubtitle: 'Beats Studio Budsは、AppleデバイスとAndroidデバイスの両方に対応しています。',
Compatibility_ChapterText1: '<em>Appleデバイスでも、Androidデバイスでも、</em>簡単に使えます。',
Compatibility_OneTouch_Title: 'ワンタッチペアリング機能 ',
Compatibility_OneTouch_Subtitle: 'Bluetoothのワンタッチペアリング機能により、Appleデバイス<sup>1</sup>またはAndroidデバイス<sup>2</sup>に簡単に接続できます。 ',
Compatibility_OneTouch_Disclaimer: '1 ワンタッチペアリングを利用するにはiOS 14.6以降が必要です。<br/>2 Fast Pairを利用するには、位置情報サービスをオンにする必要があります。この機能を完全に利用するには、Google Playサービスが有効で、Googleアカウントが設定されているAndroid 6.0以降を搭載したデバイスが必要です。',
Compatibility_VoiceAssistant_Title: '音声アシスタント ',
Compatibility_VoiceAssistant_Subtitle: 'Beats Studio Budsは、ユーザが使い慣れている音声アシスタントに対応しており、「長押し」機能をカスタマイズして起動することができます。',
Compatibility_LocateDevice_Title: 'デバイスを探す ',
Compatibility_LocateDevice_Subtitle: 'iOSの「探す」機能またはAndroidの「端末を探す」機能を使えば、イヤーバッドを簡単に見つけることができます。 ',
Compatibility_USBCCharging_Title: 'USB-Cで充電 ',
Compatibility_USBCCharging_Subtitle: 'AppleでもAndroidでも使えるUSB-Cの充電コネクタにより、どこでも充電できます。 ',
Compatibility_ManageDevice_Title: 'デバイスを管理する ',
Compatibility_ManageDevice_Subtitle: 'AppleデバイスまたはBeatsアプリ（Androidの場合）を使って、「長押し」機能といった各種機能や再生モードを管理したり、製品の名前を変更したり、ファームウェアをアップデートしたりすることができます。',
Compatibility_Summary_Title: 'AppleデバイスとAndroidデバイスに対応しています。',
Compatibility_Summary1_OneTouch: 'iOSとAndroidの両方でワンタッチペアリング機能を利用できます。',
Compatibility_Summary2_VoiceAssistant: '使い慣れた音声アシスタントを起動して、通話や音楽などを操作できます。',
Compatibility_Summary3_LocateDevice: 'デバイスを使って、イヤーバッドを簡単に見つけることができます。',
Compatibility_Summary4_USBCCharging: 'USB-C充電コネクタ搭載なので、どこでも充電できます。',
Compatibility_Summary5_ManageDevice: 'iOSまたはBeatsアプリ（Androidの場合）を使って、デバイスを管理できます。',
Control_Intro_Title: 'コントロール',
Control_Intro_VOSubtitle: 'Beats Studio Budsで、いつでもサウンドをコントロール。',
Control_ChapterText1: '<em>簡単にアクセスできるコントロールで、</em>音楽を操作できます。',
Control_OnEarControls_Title: 'オンイヤーコントロール ',
Control_OnEarControls_Subtitle: 'オンイヤーコントロールでは、通話や音楽を操作したり、再生モードを切り替えたり、音声アシスタントを起動したりすることができます。<sup>*</sup>',
Control_OnEarControls_Disclaimer: '* 長押しで動作する機能をカスタマイズするには、iOS 14.6以降を搭載したiPhone、または最新バージョンのBeatsアプリがインストールされているAndroidデバイスが必要です。',
Control_OnEarControls_Instruction: '続けるには、各コントロールをクリック／タップしてください。',
Control_OnEarControls_PlayPause: 'ここを押して再生、一時停止、通話の応答／終了 ',
Control_OnEarControls_Skip: 'ボタンを2回押すと<br/>トラックをスキップ ',
Control_OnEarControls_Previous: 'ボタンを3回押すと<br/>前のトラックを再生 ',
Control_OnEarControls_ScanForward: 'ボタンを2回押し、2回目を長押しすると早送り ',
Control_OnEarControls_ScanBackward: 'ボタンを3回押し、3回目を長押しすると巻き戻し ',
Control_OnEarControls_ToggleModes: '長押しして、再生モードを切り替え／オフにできます。または、音声アシスタントを起動するようにカスタマイズすることもできます。<sup>*</sup>',
Control_PickASide_Title: 'どちらの耳でも',
Control_PickASide_Subtitle: 'iOSまたはBeatsアプリ（Androidの場合）を使って、各イヤーバッドの「長押し」機能をカスタマイズすれば、ANCをオンにしたり音声アシスタントを起動したりできます。<sup>*</sup>',
Control_PickASide_Disclaimer: '* 長押しで動作する機能をカスタマイズするには、iOS 14.6以降を搭載したiPhone、または最新バージョンのBeatsアプリがインストールされているAndroidデバイスが必要です。',
Control_SayItWithSiri_Title: '「Hey Siri」を使おう',
Control_SayItWithSiri_Subtitle: 'ハンズフリーでSiriを起動して、いつでも音楽、通話、音量などを操作できます。<sup>*</sup>',
Control_SayItWithSiri_Disclaimer: '* 言語または地域によってはSiriを利用できない場合があります。また、地域によって機能が異なる場合があります。インターネット接続が必要です。携帯電話データ通信料がかかる場合があります。 ',
Control_SayItWithSiri_SiriCommand1: '「Hey Siri、勉強用のプレイリストを再生して」',
Control_SayItWithSiri_SiriCommand2: '「Hey Siri、近くにある女性が経営しているビジネスを教えて」',
Control_SayItWithSiri_SiriCommand3: '「Hey Siri、タイマーを10分にセットして」',
Control_Summary_Title: 'オンイヤーコントロールで音楽を操作できます。',
Control_Summary1_OnEarControls: 'オンイヤーコントロールで、通話や音楽を操作できます。',
Control_Summary2_PickASide: '左右のイヤーバッドで、「長押し」機能をカスタマイズできます。',
Control_Summary3_SayItWithSiri: 'ハンズフリーでSiriを起動できます。',
Battery_Intro_Title: 'バッテリー',
Battery_Intro_VOSubtitle: '一日中使えるバッテリーで、一日中楽しめます。',
Battery_ChapterText1: '<em>一日中使えるバッテリーで、</em>アクティブな毎日をサポートします。',
Battery_FastFuel_Title: '最大8時間',
Battery_FastFuel_Subtitle: '効率的なチップを組み込んだことで、それぞれのイヤー<br/>バッドで最大8時間の再生が<br/>可能です。<sup>*</sup>',
Battery_FastFuel_Disclaimer: '* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります',
Battery_20Hours_Title: '最大24時間',
Battery_20Hours_Subtitle: 'ポケットサイズの充電ケースで2回充電できるので、合計最大24時間の再生が可能です。<sup>*</sup>',
Battery_20Hours_Disclaimer: '* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります',
Battery_FuelYourDay_Title: '一日中、楽しめる',
Battery_FuelYourDay_Subtitle: 'バッテリー残量が少なくなっても、Fast Fuel機能により5分の急速充電で1時間の再生が可能です。<sup>*</sup>',
Battery_FuelYourDay_Disclaimer: '* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります',
Battery_UseCases_Subtitle: '各ユースケースをクリック／タップして、詳しく確認してください。 ',
Battery_UseCases_Listen: '音楽',
Battery_UseCases_Listen_Subtitle: 'お気に入りのラジオ番組を12週間にわたって聴くことができます。<sup>*</sup>',
Battery_UseCases_Listen_Disclaimer: '* 毎回2時間再生した場合',
Battery_UseCases_Meditate: '瞑想',
Battery_UseCases_Meditate_Subtitle: '雨音で32回瞑想することができます。<sup>*</sup>',
Battery_UseCases_Meditate_Disclaimer: '* 毎回45分再生した場合',
Battery_UseCases_Game: 'ゲーム',
Battery_UseCases_Game_Subtitle: 'お気に入りのゲームを24回プレイできます。<sup>*</sup>',
Battery_UseCases_Game_Disclaimer: '* マイクを使わないゲームを毎回1時間プレイした場合',
Battery_UseCases_Commute: '通勤',
Battery_UseCases_Commute_Subtitle: '24回の通勤時間を楽しめます。<sup>*</sup>',
Battery_UseCases_Commute_Disclaimer: '* 毎回1時間の通勤とした場合。バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります。',
Battery_WhatsInTheBox_Title: '同梱物',
Battery_WhatsInTheBox_Subtitle: 'Beats Studio Budsには、ポケットサイズの充電ケース、ユニバーサルUSB-C充電コネクタ、3種類のサイズから選べるイ<br/>ヤーチップが同梱されています。',
Batter_Summary_Title: '一日中使えるバッテリー。',
Battery_Summary1_PowerYourDay: 'イヤーバッドは、最大8時間の再生が可能です。',
Battery_Summary2_UpTo24: '合計最大24時間の再生が可能です。',
Battery_Summary3_FastFuel: 'Fast Fuelの充電で、1時間の再生が可能です。',
Battery_Summary4_UseCases: '一日中使えるバッテリーで、音楽を聴いたり、瞑想をしたり、ゲームをプレイしたりできます。',
Battery_Summary5_WhatsInTheBox: 'ポケットサイズの充電ケース、USB-C充電コネクタ、3種類のサイズのイヤーチップが同梱されています。',
Menu_Sound_Module1: 'サウンドの紹介',
Menu_Sound_Module2: '優れたサウンドは優れたフィット感から',
Menu_Sound_Module3: '3種類のイヤーチップ',
Menu_Sound_Module4: 'パワフルでバランスに優れたサウンド',
Menu_Sound_Module5: '独自のアコースティックプラットフォーム',
Menu_Sound_Module6: 'クリアなサウンドと歪みを低減 ',
Menu_Sound_Module7: '緻密にチューニングされたオーディオ',
Menu_Sound_Module8: '通話 ',
Menu_Sound_Module9: '高品質な通話性能',
Menu_Sound_Module10: 'サウンドのまとめ',
Menu_ANC_Module1: 'ANCの紹介',
Menu_ANC_Module2: '密閉性を確保',
Menu_ANC_Module3: '密閉性を重視',
Menu_ANC_Module4: 'テクノロジー',
Menu_ANC_Module5: '周囲の環境に適応するANC',
Menu_ANC_Module6: '再生音の補正機能',
Menu_ANC_Module7: 'ANCのユースケース',
Menu_ANC_Module8: 'ANCのまとめ',
Menu_Transparency_Module1: '外部音取り込みモードの紹介',
Menu_Transparency_Module2: '周囲の音を聞く',
Menu_Transparency_Module3: '外部音取り込みモード ',
Menu_Transparency_Module4: '外部マイク',
Menu_Transparency_Module5: 'フィードフォワード用のマイク',
Menu_Transparency_Module6: '外部音取り込みモードのユースケース',
Menu_Transparency_Module7: '外部音取り込みモードのまとめ',
Menu_Design_Module1: 'デザインの紹介',
Menu_Design_Module2: 'ワイヤレスでコンパクトなデザイン',
Menu_Design_Module3: 'ワイヤレスという自由',
Menu_Design_Module4: 'ポケットサイズで持ち運びが簡単',
Menu_Design_Module5: '耐汗耐水仕様',
Menu_Design_Module6: '快適で柔軟なフィット感',
Menu_Design_Module7: '快適な装着感',
Menu_Design_Module8: 'コンパクトなデザイン',
Menu_Design_Module9: '便利な充電ケース',
Menu_Design_Module10: 'マグネットによる固定',
Menu_Design_Module11: '正確な接触点',
Menu_Design_Module12: 'カラー',
Menu_Design_Module13: 'デザインのまとめ',
Menu_Compatibility_Module1: '互換性の紹介',
Menu_Compatibility_Module2: 'AppleとAndroidに対応',
Menu_Compatibility_Module3: 'ワンタッチペアリング機能',
Menu_Compatibility_Module4: '音声アシスタント',
Menu_Compatibility_Module5: 'デバイスを探す ',
Menu_Compatibility_Module6: 'USB-Cで充電 ',
Menu_Compatibility_Module7: 'デバイスを管理する',
Menu_Compatibility_Module8: '互換性のまとめ',
Menu_Control_Module1: 'コントロールの紹介',
Menu_Control_Module2: '簡単な操作',
Menu_Control_Module3: 'オンイヤーコントロール',
Menu_Control_Module4: 'どちらの耳でも',
Menu_Control_Module5: '「Hey Siri」を使おう',
Menu_Control_Module6: 'コントロールのまとめ',
Menu_Battery_Module1: 'バッテリーの紹介',
Menu_Battery_Module2: '一日中使えるバッテリー',
Menu_Battery_Module3: '最大8時間の再生時間',
Menu_Battery_Module4: '合計最大24時間の再生時間',
Menu_Battery_Module5: 'Fast Fuel機能',
Menu_Battery_Module6: 'バッテリーのユースケース',
Menu_Battery_Module7: '同梱物',
Menu_Battery_Module8: 'バッテリーのまとめ',
Outro_ChapterText1: 'アクティブノイズキャンセリング機能、外部音取り込みモード、プレミアムなサウンド、ワイヤレスでポケットサイズのデザインなど、<em>Beats Studio Budsは生活のあらゆるシーンに溶け込みます。</em>',
IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
Global_Country_ALBANIA: 'アルバニア',
Global_Country_ALGERIA: 'アルジェリア',
Global_Country_ANDORRA: 'アンドラ',
Global_Country_ANGOLA: 'アンゴラ',
Global_Country_ARGENTINA: 'アルゼンチン',
Global_Country_ARMENIA: 'アルメニア',
Global_Country_ARUBA: 'アルバ',
Global_Country_AUSTRALIA: 'オーストラリア',
Global_Country_AUSTRIA: 'オーストリア',
Global_Country_AZERBAIJAN: 'アゼルバイジャン',
Global_Country_BAHAMAS: 'バハマ',
Global_Country_BAHRAIN: 'バーレーン',
Global_Country_BANGLADESH: 'バングラデシュ',
Global_Country_BARBADOS: 'バルバドス',
Global_Country_BELARUS: 'ベラルーシ',
Global_Country_BELGIUM: 'ベルギー',
Global_Country_BOLIVIA: 'ボリビア',
Global_Country_BOSNIAHERZRGOVINA: 'ボスニア・ヘルツェゴビナ',
Global_Country_BOTSWANA: 'ボツワナ',
Global_Country_BRAZIL: 'ブラジル',
Global_Country_BRUNEI: 'ブルネイ・ダルサラーム',
Global_Country_BULGARIA: 'ブルガリア',
Global_Country_CAMBODIA: 'カンボジア',
Global_Country_CAMEROON: 'カメルーン',
Global_Country_CANADA: 'カナダ',
Global_Country_CAYMANISLANDS: 'ケイマン諸島',
Global_Country_CHILE: 'チリ',
Global_Country_CHINA : '中国本土',
Global_Country_COLOMBIA: 'コロンビア',
Global_Country_COOKISLANDS: 'クック諸島',
Global_Country_COSTARICA: 'コスタリカ',
Global_Country_CROATIA: 'クロアチア',
Global_Country_CYPRUS: 'キプロス',
Global_Country_CZECHREPUBLIC: 'チェコ共和国',
Global_Country_DENMARK: 'デンマーク',
Global_Country_DOMINICANREPUBLIC: 'ドミニカ共和国',
Global_Country_ECUADOR: 'エクアドル',
Global_Country_EGYPT: 'エジプト',
Global_Country_ELSALVADOR: 'エルサルバドル',
Global_Country_ESTONIA: 'エストニア',
Global_Country_FAROEISLANDS: 'フェロー諸島',
Global_Country_FIJI: 'フィジー',
Global_Country_FINLAND: 'フィンランド',
Global_Country_FRANCE: 'フランス',
Global_Country_FRENCHGUIANA: 'フランス領ギアナ',
Global_Country_FRENCHPOLYNESIA: 'フランス領ポリネシア',
Global_Country_GEORGIA: 'ジョージア',
Global_Country_GERMANY: 'ドイツ',
Global_Country_GHANA: 'ガーナ',
Global_Country_GIBRALTAR: 'ジブラルタル',
Global_Country_GREECE: 'ギリシャ',
Global_Country_GUADELOUPE: 'グアドループ',
Global_Country_GUAM: 'グアム',
Global_Country_GUATEMALA: 'グアテマラ',
Global_Country_GUERNSEY: 'ガーンジー',
Global_Country_GUINEA: 'ギニア',
Global_Country_GUYANA: 'ガイアナ',
Global_Country_HONDURAS: 'ホンジュラス',
Global_Country_HONGKONG: '香港',
Global_Country_HUNGARY: 'ハンガリー',
Global_Country_ICELAND: 'アイスランド',
Global_Country_INDIA: 'インド',
Global_Country_INDONESIA: 'インドネシア',
Global_Country_IRAQ: 'イラク',
Global_Country_IRELAND: 'アイルランド',
Global_Country_ISLEOFMAN: 'マン島',
Global_Country_ISRAEL: 'イスラエル',
Global_Country_ITALY: 'イタリア',
Global_Country_IVORYCOAST: 'コートジボワール',
Global_Country_JAPAN: '日本',
Global_Country_JERSEY: 'ジャージー',
Global_Country_JORDAN: 'ヨルダン',
Global_Country_KAZAKHSTAN: 'カザフスタン',
Global_Country_KENYA: 'ケニア',
Global_Country_KOREA: '韓国',
Global_Country_KUWAIT: 'クウェート',
Global_Country_KYRGYZSTAN: 'キルギス',
Global_Country_LAOS: 'ラオス',
Global_Country_LATVIA: 'ラトビア',
Global_Country_LEBANON: 'レバノン',
Global_Country_LESOTHO: 'レソト',
Global_Country_LITHUANIA: 'リトアニア',
Global_Country_LUXEMBOURG: 'ルクセンブルク',
Global_Country_MACAU: 'マカオ',
Global_Country_MACEDONIA: 'マケドニア',
Global_Country_MADAGASCAR: 'マダガスカル',
Global_Country_MALAWI: 'マラウイ',
Global_Country_MALAYSIA: 'マレーシア',
Global_Country_MALDIVES: 'モルディブ',
Global_Country_MALTA: 'マルタ',
Global_Country_MARTINIQUE: 'マルティニーク',
Global_Country_MAURITIUS: 'モーリシャス',
Global_Country_MAYOTTE: 'マヨット',
Global_Country_MEXICO: 'メキシコ',
Global_Country_MOLDOVA: 'モルドバ',
Global_Country_MONACO: 'モナコ',
Global_Country_MONTENEGRO: 'モンテネグロ',
Global_Country_MOROCCO: 'モロッコ',
Global_Country_MOZAMBIQUE: 'モザンビーク',
Global_Country_MYANMAR: 'ミャンマー',
Global_Country_NAMIBIA: 'ナミビア',
Global_Country_NEPAL: 'ネパール',
Global_Country_NETHERLANDS: 'オランダ',
Global_Country_NEWCALEDONIA: 'ニューカレドニア',
Global_Country_NEWZEALAND: 'ニュージーランド',
Global_Country_NICARAGUA: 'ニカラグア',
Global_Country_NIGERIA: 'ナイジェリア',
Global_Country_NORWAY: 'ノルウェー',
Global_Country_OMAN: 'オマーン',
Global_Country_PAKISTAN: 'パキスタン',
Global_Country_PALESTINIANTERRITORY: 'パレスチナ自治区',
Global_Country_PANAMA: 'パナマ',
Global_Country_PARAGUAY: 'パラグアイ',
Global_Country_PERU: 'ペルー',
Global_Country_PHILIPPINES: 'フィリピン',
Global_Country_POLAND: 'ポーランド',
Global_Country_PORTUGAL: 'ポルトガル',
Global_Country_PUERTORICO: 'プエルトリコ',
Global_Country_QATAR: 'カタール',
Global_Country_REUNION: 'レユニオン',
Global_Country_ROMANIA: 'ルーマニア',
Global_Country_RUSSIA: 'ロシア',
Global_Country_SAINTMARTIN: 'サン・マルタン',
Global_Country_SANMARINO: 'サンマリノ',
Global_Country_SAUDIARABIA: 'サウジアラビア',
Global_Country_SENEGAL: 'セネガル',
Global_Country_SERBIA: 'セルビア',
Global_Country_SINGAPORE: 'シンガポール',
Global_Country_SINTMAARTEN: 'シント・マールテン',
Global_Country_SLOVAKIA: 'スロバキア',
Global_Country_SLOVENIA: 'スロベニア',
Global_Country_SOUTHAFRICA: '南アフリカ',
Global_Country_SPAIN: 'スペイン',
Global_Country_SRILANKA: 'スリランカ',
Global_Country_SWEDEN: 'スウェーデン',
Global_Country_SWITZERLAND: 'スイス',
Global_Country_TAIWAN: '台湾',
Global_Country_TAJIKISTAN: 'タジキスタン',
Global_Country_TANZANIA: 'タンザニア',
Global_Country_THAILAND: 'タイ',
Global_Country_TRINIDADTOBAGO: 'トリニダード・ドバゴ',
Global_Country_TUNISIA: 'チュニジア',
Global_Country_TURKEY: 'トルコ',
Global_Country_TURKMENISTAN: 'トルクメニスタン',
Global_Country_UGANDA: 'ウガンダ',
Global_Country_UKRAINE: 'ウクライナ',
Global_Country_UAE: 'アラブ首長国連邦',
Global_Country_UK: '英国',
Global_Country_US: '米国',
Global_Country_URUGUAY: 'ウルグアイ',
Global_Country_UZBEKISTAN: 'ウズベキスタン',
Global_Country_VENEZUELA: 'ベネズエラ',
Global_Country_VIETNAM: 'ベトナム',
Global_Country_ZAMBIA: 'ザンビア',
Global_Country_ZIMBABWE: 'ジンバブエ',
Global_Menu_Confirm: '確認',
Global_Menu_Help: 'サポートが必要ですか？<u>お問い合わせ</u>',
Global_Menu_Restart: 'もう一度確認する',
Global_Menu_Button_Restart: "もう一度再生する",
Global_Menu_Button_Contact: "サポートが必要ですか？お問い合わせ",
IntroOutro_TestYourKnowledge: 'Beats Studio Budsの理解度をテストしましょう。',
IntroOutro_CTA: 'クイズを受ける',
Global_Menu_Language: '言語',
Global_Password_Rules_Header: 'パスワードには次のものが含まれている必要があります',
Global_Password_Rule_Length: '最小文字数：8文字',
Global_Password_Rule_Uppercase: '1つの大文字',
Global_Password_Rule_Lowercase: '1つの小文字',
Global_Password_Rule_Number: '1つの数字',
Global_Password_Rule_Special: '記号（!@#$%^&*）',
Global_Field_Password_CreateError: 'パスワードが最小要件を満たしていません。',
Global_ForgotEmail_NoUser:"この資格情報に関連付けられたユーザーレコードはありません。このユーザーは削除されている可能性があります。"

};

export default copy;
