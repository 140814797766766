import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import Context from '../../context/Context';
import * as SITEDATA from '../../constants';

import Styles from './Cursor.module.scss';
import NextButton from '../NextButton';
import ProgressButton from '../ProgressButton';

const Cursor = (props) => {
	const location = useLocation();
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [linkTo, setLinkTo] = useState();
	const context = useContext(Context);
	const hasMouseInput = window.matchMedia('(any-hover: hover)').matches;
	const [navigationDirection, setNavigationDirection] = useState('next');
	const [isOnLeftSide, setIsOnLeftSide] = useState(false);
	const {
		canAdvance,
		forceDefaultCursor,
		language,
		overRideNextButton,
		setOverRideNextButton,
		unmuteOnNextButtonClick,
		setMuted,
		setSilenceBackgroundAudio,
		showCursor,
		siteID,
	} = context;
	const CHAPTERS = siteID ? SITEDATA[`${siteID}_CHAPTERS`] : SITEDATA[`studioBuds_CHAPTERS`]

	const moduleMatch = matchPath(location.pathname, {
		path: `${process.env.PUBLIC_URL}/:chapterId/:moduleId`,
	});
	const chapterRootMatch = matchPath(location.pathname, {
		path: `${process.env.PUBLIC_URL}/:chapterId`,
	});
	const activeChapterId = moduleMatch ? moduleMatch.params.chapterId : chapterRootMatch?.params.chapterId;
	const activeChapter = CHAPTERS.find((c) => c.id === activeChapterId);
	const activeChapterIndex = CHAPTERS.findIndex((c) => c.id === activeChapterId);
	const activeModuleId = moduleMatch ? moduleMatch.params.moduleId : activeChapter?.modules[0].id;
	const activeModule = activeChapter?.modules.find((m) => m.id === activeModuleId);
	const thisModuleIndex = activeChapter?.modules.indexOf(activeModule);
	const nextModuleIndex = thisModuleIndex + 1;
	let nextModule =
		activeChapter?.modules.length > nextModuleIndex ? activeChapter.modules[nextModuleIndex] : undefined;
	const prevModuleIndex = thisModuleIndex - 1;
	const prevModule = activeChapter?.modules[prevModuleIndex];
	// console.log("prevModuleIndex",prevModuleIndex)

	// console.log("prevModule",prevModule)
	const thisChapterIndex = CHAPTERS.indexOf(activeChapter);
	const nextChapterIndex = thisChapterIndex + 1;
	let nextChapter = CHAPTERS.length > nextChapterIndex ? CHAPTERS[nextChapterIndex] : undefined;

	let nextLinkTo = '';

	if (overRideNextButton) {
		nextLinkTo = overRideNextButton;
	} else {
		if (nextModule) {
			nextLinkTo = `${process.env.PUBLIC_URL}/${activeChapter.id.toLowerCase()}/${nextModule.id.toLowerCase()}`;
		} else if (!nextModule && nextChapter) {
			nextLinkTo = `${process.env.PUBLIC_URL}/${nextChapter.id.toLowerCase()}`;
		}
	}
  
	let prevLinkTo = '';
	if (prevModule) {
		prevLinkTo = `${process.env.PUBLIC_URL}/${activeChapter.id.toLowerCase()}/${prevModule.id.toLowerCase()}`;
	} else if (activeChapterIndex>0) {
		prevLinkTo = `${process.env.PUBLIC_URL}/${CHAPTERS[activeChapterIndex - 1].id.toLowerCase()}/${CHAPTERS[
			activeChapterIndex - 1
		].modules[CHAPTERS[activeChapterIndex - 1].modules.length - 1].id.toLowerCase()}`;
	}

	const onMouseMove = (e) => {
		if (forceDefaultCursor || !canAdvance) {
			return;
		}
		setIsOnLeftSide(e.clientX < window.innerWidth / 2);

		setPosition({ x: e.clientX, y: e.clientY });
	};

	useEffect(() => {
		if (
			isOnLeftSide && 
			activeChapterIndex && 
			(location.pathname.indexOf('/welcome') < 0)
		) {
			setLinkTo(prevLinkTo);
			setNavigationDirection('prev');
		} else {
			setLinkTo(nextLinkTo);
			setNavigationDirection('next');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOnLeftSide, nextLinkTo, prevLinkTo, prevModule]);

	useEffect(() => {
		if (hasMouseInput) {
			document.addEventListener('mousemove', onMouseMove);
		}
		return () => {
			document.removeEventListener('mousemove', onMouseMove);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasMouseInput]);

	if (!hasMouseInput) {
		return null;
	}
	const handleClick = () => {
		if (unmuteOnNextButtonClick) {
			setMuted(false);
			setSilenceBackgroundAudio(false);
		}
		setOverRideNextButton(null);
	};

	return (
		<AnimatePresence exitBeforeEnter>
			{!forceDefaultCursor && !canAdvance && navigationDirection !== 'prev' && linkTo && (
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					exit={{ scale: 0 }}
					className={Styles.root}
					style={showCursor ? { top: position.y, left: position.x } : { top: -1000, left: -1000 }}
					key="progressButton"
					transition={{ duration: 0.15 }}
				>
					<div className={clsx(Styles.cursor, Styles.progressCursor)}>
						<ProgressButton />
					</div>
				</motion.div>
			)}
			{!forceDefaultCursor && (canAdvance || navigationDirection === 'prev') && linkTo && (
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					exit={{ scale: 0 }}
					className={Styles.root}
					style={showCursor ? { top: position.y, left: position.x } : { top: -1000, left: -1000 }}
					key={linkTo}
					transition={{ duration: 0.15 }}
				>
					<Link
						onClick={handleClick}
						to={linkTo}
						className={clsx(Styles.cursor, Styles[navigationDirection])}
					>
						<NextButton filled>
							{activeModule ? language[activeModule.replaceNextButtonContent] : null}
						</NextButton>
					</Link>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default Cursor;
