/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import InteractiveStep from '../InteractiveStep';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Styles from './Carousel.module.scss';

export default function Carousel({ items, activeStep, setActiveStep }) {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    draggable: false,

    speed: 500,
    slidesToShow: 1,
    className: 'controls-carousel',
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(activeStep);
    }
  }, [activeStep]);

  return (
    <div className={Styles.containerSlider}>
      <Slider ref={sliderRef} {...settings}>
        {items.map((step, i) => {
          step.index = i;

          return (
            <InteractiveStep
              index={i}
              key={i}
              step={step}
              activeStep={activeStep}
              onClick={() => setActiveStep(i)}
            />
          );
        })}
      </Slider>
    </div>
  );
}
