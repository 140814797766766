import React, { useState, useEffect } from 'react';

import { withFirebase } from '../../components/Firebase';

const UserList = ({ users }) => (
	<ul>
		{users.map((user) => (
			<li key={user.uid}>
				<span>
					<strong>ID:</strong> {user.uid}
				</span>
				<span>
					<strong>E-Mail:</strong> {user.email}
				</span>
				<span>
					<strong>Username:</strong> {user.username}
				</span>
			</li>
		))}
	</ul>
);

/*
firebase.database().ref('users/' + userId).set({
    username: name,
    email: email,
    profile_picture : imageUrl
  });




*/
const AdminPage = (props) => {
	const { firebase } = props;
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		firebase.users().on('value', (snapshot) => {
			const usersObject = snapshot.val();

			const usersList = Object.keys(usersObject).map((key) => ({
				...usersObject[key],
				uid: key,
			}));

			setUsers(usersList);
			setLoading(false);
		});
		return () => {
			firebase.users().off();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<h1>Admin</h1>

			{loading && <div>Loading ...</div>}

			<UserList users={users} />
		</div>
	);
};

export default withFirebase(AdminPage);
