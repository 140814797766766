
export const USER_INFO = `${process.env.PUBLIC_URL}/userinfo`;
export const SIGN_IN = `${process.env.PUBLIC_URL}/signin`;
export const SIGN_OUT = `${process.env.PUBLIC_URL}/signout`;

export const WELCOME = `${process.env.PUBLIC_URL}/welcome`;
export const WELCOMEBACK = `${process.env.PUBLIC_URL}/welcomeback`;
export const COMPANYREQUIRED = `${process.env.PUBLIC_URL}/company-required`;

export const INTRO = `${process.env.PUBLIC_URL}/intro`;
export const HOME = `${process.env.PUBLIC_URL}/`;
export const ACCOUNT = `${process.env.PUBLIC_URL}/account`;
export const ADMIN = `${process.env.PUBLIC_URL}/admin`;
export const PASSWORD_FORGET = `${process.env.PUBLIC_URL}/pw-forget`;
export const LANGUAGE = `${process.env.PUBLIC_URL}/language`;
export const PRODUCTSELECTOR = `${process.env.PUBLIC_URL}/sites`;


// export const ANC = `${process.env.PUBLIC_URL}/anc`;
// export const INTRO = `${process.env.PUBLIC_URL}/intro`;
// export const SOUND = `${process.env.PUBLIC_URL}/sound`;


// export const ANC_MODULES = `${process.env.PUBLIC_URL}/anc/:moduleID`;
// export const INTRO_MODULES = `${process.env.PUBLIC_URL}/intro/:moduleID`;
// export const LANDING = `${process.env.PUBLIC_URL}/landing`;
// export const LANDING = `${process.env.PUBLIC_URL}/`;
