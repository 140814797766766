import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Firebase, { FirebaseContext } from './components/Firebase';
import GA4React from 'ga-4-react';

import App from './components/App';

const ga4react = new GA4React(window.location.origin.toLowerCase().includes('beatstrnr.com') ? 'G-VTJRQWR6K0' : 'G-YYDTKS66J2');

(async (_) => {
	ga4react.initialize().then(
		(ga4) => {
			ga4.pageview('path');
			ga4.gtag('event', 'pageview', 'path'); // or your custom gtag event
			ReactDOM.render(
				<FirebaseContext.Provider value={new Firebase()}>
					<App ga4={ga4}/>
				</FirebaseContext.Provider>,
				document.getElementById('root')
			);
		},
		(err) => {
			console.error(err);
		}
	);
	
})();

// serviceWorker.unregister();
