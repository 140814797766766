const copy = {
  Global_Menu_Confirm: 'Confirm',
  Global_UI_Next: 'Next',
  Global_UI_Begin: 'Begin',
  Global_UI_Back: 'Back',
  Global_UI_Settings: 'Settings',
  Global_UI_Signout: 'Sign Out',
  Global_UI_NextChapter: 'Next Chapter',
  Global_UI_PercentageComplete: '<<number>>% COMPLETE',
  Global_UI_Replay: 'Replay',
  Global_Languages_Select: 'CHOOSE YOUR LANGUAGE',
  Global_Field_EnterEmail: 'Enter email address',
  Global_Field_CreatePassword: 'Create Password',
  Global_Field_Password: 'Password',
  Global_Field_Password_Forgot: 'Forgot Password',
  Global_Password_Subtitle:
    'PASSWORD SHOULD BE 8+ CHARACTERS, WITH AT LEAST ONE UPPERCASE, ONE LOWERCASE LETTER, NUMBER, AND ONE SYMBOL (!@#$, ETC)',
  Global_Password_Change: 'CHANGE',
  Global_Password_ChangeConfirm: 'Password updated',
  Global_Password_Show: 'SHOW',
  Global_Password_Hide: 'HIDE',
  Global_Password_Reset_Headline: 'Reset password',
  Global_Password_Reset_Subtitle:
    'Enter your email to receive instructions on how to reset your password.',
  Global_Password_Reset_Confirm: 'Sent! Check your email.',
  Global_Details_Title: 'A few more details',
  Global_Details_Field_FirstName: 'First name',
  Global_Details_Field_LastName: 'Last name',
  Global_Details_Field_EmployeeID: 'Employee ID (Optional)',
  Global_Details_Field_BusinessRole: 'Business Role',
  Global_Details_Field_BusinessRole1: 'Manager',
  Global_Details_Field_BusinessRole2: 'Sales Person',
  Global_Details_Field_BusinessRole3: 'Trainer',
  Global_Details_Field_CompanyName: 'Company Name:',
  Global_Details_Field_Country: 'Country',
  Global_Field_Password_Error:
    'The password you entered is incorrect. Please try again.',
  Global_Field_Password_CreateError:
    'Password does not meet minimal requirements.',
  Global_Field_Email_Error:
    'You have entered an invalid email address. Please try again.',
  Global_Field_Details_Error: 'Please fill out all required fields.',
  Global_Greeting: 'Hi <<name>>,',
  Global_CompletionMessage: 'You are <<number>>% complete',
  Global_Chapter: 'CH <<number>>',
  Global_Chapter_Subtitle: 'CH <<number>> OF <<total>>',
  Global_Intro: 'Intro',
  Global_ChapterTitle_Sound: 'Sound',
  Global_ChapterTitle_ANC: 'ANC',
  Global_ChapterTitle_Transparency: 'Transparency',
  Global_ChapterTitle_Design: 'Design',
  Global_ChapterTitle_Compatibility: 'Compatibility',
  Global_ChapterTitle_Controls: 'Controls',
  Global_ChapterTitle_Battery: 'Battery',
  Global_Menu: 'Menu',
  Global_ForgotEmail_NoUser:"There is no user record corresponding to this identifier. The user may have been deleted.",
  CR_TRNR_Intro_Sorry:
    'We’re sorry. Something went wrong. Please contact your supervisor for a new access link.',

  Intro_Welcome_ChapterText:
    'Welcome to the Beats Studio Buds experience. Click or tap through and discover.',
  Intro_Welcome_ReturningUser:
    'Ready to pick up where you left off? Click or tap to continue exploring Beats Studio Buds.',
  Intro_Intro_Subtitle:
    'Introducing Beats Studio Buds. True wireless, noise cancelling earbuds with studio quality sound. Click or tap through to explore.',
  Sound_Intro_Chapter: 'replace this comment',
  Sound_Intro_Title: 'Sound',
  Sound_Intro_VOSubtitle:
    'Advanced engineering delivers powerful, balanced sound.',
  Sound_ChapterText1:
    'Great sound starts with a <em>customized fit and comfortable acoustic seal.</em>',
  Sound_FindYourFit_Title: 'Find your fit. Feel the music.',
  Sound_FindYourFit_Subtitle:
    'Three silicone eartip sizes offer a personalized fit and acoustic seal for immersive audio.',
  Sound_FindYourFit_Medium: 'replace this comment',
  Sound_FindYourFit_Small: 'replace this comment',
  Sound_FindYourFit_Large: 'replace this comment',
  Sound_ChapterText2:
    'Beats Studio Buds are engineered to deliver <em>powerful, balanced sound</em> in a compact design, through an <em>entirely customized acoustic platform.</em>',
  Sound_DrivenByPremiumSound_Title: 'Driven by premium sound',
  Sound_DrivenByPremiumSound_Subtitle:
    'A custom acoustic platform featuring a proprietary, dual-element diaphragm driver produces clear, balanced sound.',
  Sound_MoreMusicLessDistortion_Title: 'Clear sound. Low distortion.',
  Sound_MoreMusicLessDistortion_Subtitle:
    'The flexible material of the driver diaphragm produces low vibrational distortion, resulting in clear, accurate sound across the entire spectrum—especially in the bass range.',
  Sound_MoreMusicLessDistortion_Diaphragm: 'DIAPHRAGM',
  Sound_FineTunedAudio_Title: 'Fine-tuned audio',
  Sound_FineTunedAudio_Subtitle:
    'A Digital Processor with playback correction responds in real-time to ensure that external sounds won’t affect the clarity or emotion of your music.',
  Sound_ChapterText3:
    'Phone calls sound clear with <em>high-quality call performance and elevated voice clarity.</em> ',
  Sound_SpeakingOfSound_Title: 'High-quality call performance',
  Sound_SpeakingOfSound_Subtitle:
    'A specially designed port isolates the microphone from external wind noise, while dual-beam forming microphones target your voice for elevated voice clarity.',
  Sound_Summary_Title: 'Engineered to deliver music as the artist intended',
  Sound_Summary1_ThreeEartip:
    'Three eartip options offer a customized fit for great sound.',
  Sound_Summary2_CustomAcoustic:
    'Custom acoustic platform delivers high-quality, powerful sound.',
  Sound_Summary3_FlexibleDriverDiaphragm:
    'Flexible, driver diaphragm produces clear, accurate sound.',
  Sound_Summary4_FinetunedAudio:
    'Fine-tuned audio preserves the clarity and emotion of your music.',
  Sound_Summary5_SpeakingOfSound:
    'Dual-beam forming microphones target your voice for high-quality call performance.',
  ANC_Intro_Chapter: 'replace this comment',
  ANC_Intro_TITLE: 'Active Noise Cancelling',
  ANC_Intro_VOSubtitle:
    'Stay focused and block out distractions with Active Noise Cancelling.',
  ANC_ChapterText1:
    '<em>A comfortable acoustic seal</em> helps block external noise for a truly immersive listening experience.',
  ANC_Seal_Title: 'Acoustic Seal',
  ANC_Seal_Subtitle:
    'Three eartip sizes let you choose a comfortable fit to create an acoustic seal that blocks unwanted noise.',
  ANC_ChapterText2:
    'ANC with adaptive technology <em>adjusts to your surroundings</em> while Playback Correction <em>preserves the clarity and emotion of your music.</em>',
  ANC_ANCThatAdapts_Title: 'ANC that adapts',
  ANC_ANCThatAdapts_Subtitle:
    'A finely-tuned filter dynamically adjusts to filter unwanted noise and optimize noise cancellation in real time.',
  ANC_ANCThatAdapts_AdaptiveANC: 'replace this comment',
  ANC_Playback_Title: 'Playback Correction',
  ANC_Playback_Subtitle:
    'When noise cancelling is applied, Playback Correction maps that audio file back to the original at 48,000 times a second, ensuring that ANC never interferes with your music.',
  ANC_Playback_ANCProcess: 'replace this comment',
  ANC_Playback_PlaybackCorrection: 'replace this comment',
  ANC_UseCases_Subtitle: 'Click or tap each use case to learn more. ',
  ANC_UseCases_Studying: 'Studying',
  ANC_UseCases_Studying_Subtitle:
    'Block out distractions so you can stay focused on your work',
  ANC_UseCases_WorkingOut: 'Working Out',
  ANC_UseCases_WorkingOut_Subtitle:
    'Immerse yourself in your workout without any distractions',
  ANC_UseCases_Airplane: 'Airplane',
  ANC_UseCases_Airplane_Subtitle:
    'Quiet the sounds of passengers or a loud engine to better enjoy your flight',
  ANC_UseCases_Commute: 'Commute',
  ANC_UseCases_Commute_Subtitle:
    'Commute with less of the city’s background noise',
  ANC_Summary_Title: 'Stay focused with Active Noise Cancelling.',
  ANC_Summary1_ImmerseSound:
    'Immersive sound starts with a comfortable acoustic seal.',
  ANC_Summary2_AdaptiveTechnology:
    'Adaptive technology continuously adjusts to your surroundings.',
  ANC_Summary3_PlaybackCorrection:
    'Playback correction preserves the clarity of your sound.',
  ANC_Summary4_UseCases:
    'Use ANC to focus while studying, working out, for airplane travel, or commuting.',
  Transparency_Intro_Chapter: 'replace this comment',
  Transparency_Intro_Title: 'TRANSPARENCY MODE',
  Transparency_Intro_VOSubtitle: 'Let the world in with Transparency mode.',
  Transparency_ChapterText1:
    'Switch to Transparency mode when you need to <em>hear the world around you.</em>',
  Transparency_TransparencyMode_Title: 'Transparency mode',
  Transparency_TransparencyMode_Subtitle:
    'Transparency modes mixes the sounds of your surroundings back in with your music—for a natural, ‘open’ listening experience.',
  Transparency_TransparencyMode_PressHold: 'PRESS + HOLD',
  Transparency_ChapterText2:
    '<em>External microphones</em> listen for outside noise.',
  Transparency_FeedForwardMic_Title: 'Feed-forward microphones',
  Transparency_FeedForwardMic_Subtitle:
    'A feed-forward microphone on each earbud detects external noises in your environment.',
  Transparency_FeedForwardMic_FeedForwardMic: 'FEED-FORWARD MICROPHONE',
  Transparency_UseCases_Subtitle: 'Click or tap each use case to learn more.',
  Transparency_UseCases_Office: 'Office',
  Transparency_UseCases_Office_Subtitle:
    'Stay collaborative at work and hear your coworkers while you listen to music',
  Transparency_UseCases_Travel: 'Travel',
  Transparency_UseCases_Travel_Subtitle:
    'Listen for important announcements at an airport gate or train platform.',
  Transparency_UseCases_Street: 'Street',
  Transparency_UseCases_Street_Subtitle:
    'Stay aware of surrounding traffic and busy intersections.',
  Transparency_Summary_Title: 'Let the world back in with Transparency mode.',
  Transparency_Summary1_MixTheSounds:
    'Mix the sounds of your surroundings back in with your music',
  Transparency_Summary2_FeedForward:
    'Feed-forward microphones detect external noises in your environment.',
  Transparency_Summary3_HearYourSurroundings:
    'Hear your surroundings at the office, while travelling, or on the street.',
  Design_Intro_Chapter: 'Replace this text',
  Design_Intro_Title: 'Design',
  Design_Intro_VOSubtitle:
    'Beats Studio Buds are designed for truly wireless freedom.',
  Design_ChapterText1:
    'The wireless, compact design <em>fits seamlessly into your life.</em>',
  Design_WirelessFreedom_Title: 'Wireless freedom',
  Design_WirelessFreedom_Subtitle: 'True wireless earbuds don’t hold you back.',
  Design_PocketSizedPortability_Titles: 'Pocket-sized portability',
  Design_PocketSizedPortability_Subtitles:
    'A slim, lightweight charging case offers superior portability and slips right into your pocket.',
  Design_WaterAndSweatResistant_Title:
    'Sweat & water resistant earbuds<sup>*</sup> ',
  Design_WaterAndSweatResistant_Subtitle:
    'The earbuds are IPX-4 rated for sweat and water resistance to keep you going through the elements.',
  Design_WaterAndSweatResistant_Disclaimer:
    '* Beats Studio Buds are sweat and water resistant for non-water sports and exercise.',
  Design_ChapterText2:
    'Beats Studio Buds are tested across a wide audience to ensure a <em>comfortable, versatile fit.</em>',
  Design_Comfort_Title: 'Designed for comfort',
  Design_Comfort_Subtitle:
    'Soft, silicone earbuds are designed around the ear’s anatomy to provide comfort, stability, and the confidence of staying put.',
  Design_Comfort_Tragus: 'TRAGUS',
  Design_Comfort_Antitragus: 'ANTITRAGUS',
  Design_SizeMatters_Title: 'Compact Design',
  Design_SizeMatters_Subtitle:
    'The sleek, compact in-ear design is significantly lighter and smaller than most wireless earbuds today.',
  Design_SizeMatters_BeatsStudio: 'BEATS FLEX PRO',
  Design_SizeMatters_BeatsStudio_10_1G: '10.1G',
  Design_SizeMatters_Bose: 'BOSE QC EARBUDS',
  Design_SizeMatters_Bose_18_7G: '18.7G',
  Design_SizeMatters_Sony: 'Sony WF-1000XM3',
  Design_SizeMatters_Sony_16_5G: '16.5G',
  Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
  Design_SizeMatters_Samsung_12_5G: '12.5G',
  Design_ChapterText3:
    'Earbuds stay <em>secure and protected</em> in a convenient charging case designed to keep up with you all day.',
  Design_ChapterText4:
    'Beats Studio Buds come in <em>eco-friendly, sustainable packaging</em>.',
  Design_PerfectMatch_Title: 'Magnetic positioning',
  Design_PerfectMatch_Subtitle:
    'Magnets inside the charging case are oriented so that the earbuds fall into the right place, every time.',
  Design_Contact_Title: 'Precise contact',
  Design_Contact_Subtitle:
    'Pogo pins within the case use a resilient leaf spring for precise contact between the earbuds and case—resulting in properly seated earbuds for charging.',
  Design_Contact_PogoPin: 'POGO PIN',
  Design_FindYourMatch_Title: 'Find your match',
  Design_FindYourMatch_Subtitle: 'Color matching charging case',
  Design_FindYourMatch_White: 'WHITE',
  Design_FindYourMatch_Black: 'BLACK',
  Design_FindYourMatch_BeatsRed: 'BEATS RED',
  Design_Summary_Title: 'Designed for truly wireless freedom',
  Design_Summary1_WirelessFreedom: 'True wireless earbuds don’t hold you back.',
  Design_Summary2_PocketSizedPortability:
    'Lightweight charging case easily fits in your pocket.',
  Design_Summary3_WaterAndSweatResistant:
    'IPX-4 rating for sweat and water resistant earbuds.',
  Design_Summary4_Comfort:
    'Sleek, compact earbuds provide comfort and stability.',
  Design_Summary5_SizeMatters:
    'In-ear design is significantly smaller and lighter than most wireless earbuds.',
  Design_Summary6_PerfectMatch:
    'Magnets inside the charging case ensure earbuds fall into place every time.',
  Design_Summary7_Contact:
    'Pogo pins within the case help properly seat earbuds for charging.',
  Design_Summary8_FindYourMatch:
    'Color matching charging case comes in Black, White, and Beats Red.',
  Design_SustainablePackaging_Title: 'Sustainable packaging',
  Design_SustainablePackaging_Subtitle:
    'Beats Studio Buds packaging is made from 92% plant-based material sourced from a combination of recycled fiber and sustainable forests.',
  Compatibility_Intro_Chapter: 'replace this comment',
  Compatibility_Intro_Title: 'Compatibility',
  Compatibility_Intro_VOSubtitle:
    'Compatible with both Apple and Android devices. ',
  Compatibility_ChapterText1:
    'Easily use with <em>both Apple and Android devices.</em>',
  Compatibility_OneTouch_Title: 'One-touch pairing ',
  Compatibility_OneTouch_Subtitle:
    'Easily connect your Apple<sup>1</sup> or Android<sup>2</sup> device via Bluetooth with simple one-touch pairing. ',
  Compatibility_OneTouch_Disclaimer:
    '<sup>1 One-touch pairing requires iOS 14.6 or later.<br/>2 Fast Pair requires location enabled. Full access to features requires an Android 6.0 or newer device with Google Play Service enabled and Google Account.</sup>',
  Compatibility_VoiceAssistant_Title: 'Voice assistant',
  Compatibility_VoiceAssistant_Subtitle:
    'Beats Studio Buds are compatible with your preferred voice assistant, which can be activated with a customized “Press and Hold” function.',
  Compatibility_LocateDevice_Title: 'Locate your device',
  Compatibility_LocateDevice_Subtitle:
    'Use the “Find My” feature for iOS or “Find My Device” feature for Android to easily locate your earbuds.',
  Compatibility_USBCCharging_Title: 'USB-C charging',
  Compatibility_USBCCharging_Subtitle:
    'A USB-C charge connector works across Apple and Android so you can charge up anywhere.',
  Compatibility_ManageDevice_Title: 'Manage your device ',
  Compatibility_ManageDevice_Subtitle:
    'Manage features like "Press and Hold” customization, listening modes, renaming your product, and firmware updates through your Apple device or the Beats App for Android.',
  Compatibility_Summary_Title: 'Compatible with Apple and Android devices.',
  Compatibility_Summary1_OneTouch:
    'One-touch pairing for both iOS and Android.',
  Compatibility_Summary2_VoiceAssistant:
    'Activate preferred voice assistant to manage calls, music, and more.',
  Compatibility_Summary3_LocateDevice:
    'Easily locate your earbuds using your device.',
  Compatibility_Summary4_USBCCharging:
    'USB-C charge connector offers universal charging.',
  Compatibility_Summary5_ManageDevice:
    'Manage your device through iOS or the Beats App for Android.',
  Control_Intro_Chapter: 'replace this comment',
  Control_Intro_Title: 'Controls',
  Control_Intro_VOSubtitle:
    'Control your sound, and your day, with Beats Studio Buds.',
  Control_ChapterText1:
    'Manage your sound with <em>easy access to controls.</em>',
  Control_OnEarControls_Title: 'On-ear controls',
  Control_OnEarControls_Instructions: 'Click on each control command:',
  Control_OnEarControls_Subtitle:
    'On-ear controls let you manage calls and music, toggle between listening modes and activate voice assistant.<sup>*</sup>',
  Control_OnEarControls_Disclaimer:
    '* Press and hold feature customization requires an iPhone running iOS 14.6 or later, or an Android device with the latest version of the Beats app installed.',
  Control_OnEarControls_Instruction:
    'Click or tap through each control to continue.',
  Control_OnEarControls_PlayPause: 'Press to play, pause and answer/end calls ',
  Control_OnEarControls_Skip: 'Double press button to skip track',
  Control_OnEarControls_Previous: 'Triple press button to play previous track ',
  Control_OnEarControls_ScanForward:
    'Double press and hold button to scan forward ',
  Control_OnEarControls_ScanBackward:
    'Triple press and hold button to scan backwards ',
  Control_OnEarControls_ToggleModes:
    'Press and hold to toggle between and turn off listening modes or customize to activate voice assistant<sup>*</sup>',
  Control_PickASide_Title: 'Pick a side',
  Control_PickASide_Subtitle:
    'Use iOS or the Beats App for Android to customize "Press and Hold” on each earbud to activate ANC or your voice assistant.<sup>*</sup>',
  Control_PickASide_Disclaimer:
    '*Press and hold feature customization requires an iPhone running iOS 14.6 or later, or an Android device with the latest version of the Beats app installed.',
  Control_SayItWithSiri_Title: 'Say it with Siri',
  Control_SayItWithSiri_Subtitle:
    'Hands-free access to Siri lets you control music, manage calls, adjust volume, and more whenever you need it.<sup>*</sup>',
  Control_SayItWithSiri_Disclaimer:
    '*Siri may not be available in all languages or in all areas, and features may vary by area. Internet access is required. Cellular data charges may apply.',
  Control_SayItWithSiri_SiriCommand1: '“Hey Siri, play my study playlist.”',
  Control_SayItWithSiri_SiriCommand2:
    '“Hey Siri, show me female-run businesses nearby.”',
  Control_SayItWithSiri_SiriCommand3: '“Hey Siri, set a timer for 10 minutes.”',
  Control_Summary_Title: 'Manage your sound with on-ear controls.',
  Control_Summary1_OnEarControls:
    'On-ear controls let you manage calls and music.',
  Control_Summary2_PickASide:
    'Customize the “Press and Hold” function on the left and right earbud.',
  Control_Summary3_SayItWithSiri: 'Hands-free access to Siri.',
  Battery_Intro_Chapter: 'Battery',
  Battery_Intro_Title: 'Battery',
  Battery_Intro_VOSubtitle: 'Power your day with a battery that lasts all day.',
  Battery_ChapterText1: '<em>All-day battery</em> that keeps you moving.',
  Battery_FastFuel_Title: 'Up to 8 hours',
  Battery_FastFuel_Subtitle:
    'Powered by a highly efficient chip, each earbud provides up to 8 hours of listening time.<sup>*</sup>',
  Battery_FastFuel_Disclaimer:
    '* Battery life depends on device settings, environment, usage, and many other factors',
  Battery_20Hours_Title: 'Up to 24 hours',
  Battery_20Hours_Subtitle:
    'A pocket-sized charging case provides two additional charges, resulting in up to 24 hours of combined playback.<sup>*</sup>',
  Battery_20Hours_Disclaimer:
    '* Battery life depends on device settings, environment, usage, and many other factors',
  Battery_FuelYourDay_Title: 'Fuel your day',
  Battery_FuelYourDay_Subtitle:
    'When the battery is low, a 5 minute Fast Fuel charge provides 1 hour of playback.<sup>*</sup>',
  Battery_FuelYourDay_Disclaimer:
    '* Battery life depends on device settings, environment, usage, and many other factors',
  Battery_UseCases_Subtitle: 'Click or tap each use case to learn more. ',
  Battery_UseCases_Listen: 'Listen',
  Battery_UseCases_Listen_Subtitle:
    'Listen to 12 weeks of your favorite radio shows.<sup>*</sup>',
  Battery_UseCases_Listen_Disclaimer: '*Based on 2 hour listening sessions',
  Battery_UseCases_Meditate: 'Meditate',
  Battery_UseCases_Meditate_Subtitle:
    'Relax with 32 rain sound meditations.<sup>*</sup>',
  Battery_UseCases_Meditate_Disclaimer: '*Based on 45 minute sessions',
  Battery_UseCases_Game: 'Game',
  Battery_UseCases_Game_Subtitle:
    'Play 24 sessions of your favorite game.<sup>*</sup>',
  Battery_UseCases_Game_Disclaimer:
    '*Based on 1 hour gaming sessions that do not require the use of microphones',
  Battery_UseCases_Commute: 'Commute',
  Battery_UseCases_Commute_Subtitle: 'Enjoy 24 days of commuting.<sup>*</sup>',
  Battery_UseCases_Commute_Disclaimer:
    '* Based on a 1 hour commute. Battery life depends on device settings, environment, usage, and many other factors.',
  Battery_WhatsInTheBox_Title: 'What’s in the box',
  Battery_WhatsInTheBox_Subtitle:
    'Beats Studio Buds comes with a pocket-sized charging case, a universal USB-C charge connector, and three eartip sizes to choose from.',
  Batter_Summary_Title: 'Battery that lasts all day.',
  Battery_Summary1_PowerYourDay: 'Earbuds provide up to 8 hours of playback.',
  Battery_Summary2_UpTo24: 'Up to 24 hours of combined playback.',
  Battery_Summary3_FastFuel: 'Fast Fuel charge provides 1 hour of playback.',
  Battery_Summary4_UseCases: 'Listen, meditate, and game with all-day battery.',
  Battery_Summary5_WhatsInTheBox:
    'Box includes a pocket-sized charging case, USB-C charge connector, and three eartip sizes.',
  Menu_Sound_Module1: 'SOUND INTRO',
  Menu_Sound_Module2: 'GREAT SOUND STARTS WITH A GREAT FIT',
  Menu_Sound_Module3: 'THREE EARTIPS',
  Menu_Sound_Module4: 'POWERFUL, BALANCED SOUND',
  Menu_Sound_Module5: 'CUSTOMIZED ACOUSTIC PLATFORM',
  Menu_Sound_Module6: 'CLEAR SOUND. LOW DISTORTION. ',
  Menu_Sound_Module7: 'FINE-TUNED AUDIO',
  Menu_Sound_Module8: 'PHONE CALLS ',
  Menu_Sound_Module9: 'HIGH-QUALITY CALL PERFORMANCE',
  Menu_Sound_Module10: 'SOUND SUMMARY',
  Menu_ANC_Module1: 'ANC INTRO',
  Menu_ANC_Module2: 'ACOUSTIC SEAL',
  Menu_ANC_Module3: 'START WITH THE SEAL',
  Menu_ANC_Module4: 'TECHNOLOGY',
  Menu_ANC_Module5: 'ANC THAT ADAPTS',
  Menu_ANC_Module6: 'PLAYBACK CORRECTION',
  Menu_ANC_Module7: 'ANC USE CASES',
  Menu_ANC_Module8: 'ANC SUMMARY',
  Menu_Transparency_Module1: 'TRANSPARENCY INTRO',
  Menu_Transparency_Module2: 'HEAR THE WORLD AROUND YOU',
  Menu_Transparency_Module3: 'TRANSPARENCY MODE',
  Menu_Transparency_Module4: 'EXTERNAL MICROPHONES',
  Menu_Transparency_Module5: 'FEED-FORWARD MICROPHONES',
  Menu_Transparency_Module6: 'TRANSPARENCY USE CASES',
  Menu_Transparency_Module7: 'TRANSPARENCY SUMMARY',
  Menu_Design_Module1: 'DESIGN INTRO',
  Menu_Design_Module2: 'WIRELESS COMPACT DESIGN',
  Menu_Design_Module3: 'WIRELESS FREEDOM',
  Menu_Design_Module4: 'POCKET-SIZED PORTABILITY',
  Menu_Design_Module5: 'SWEAT & WATER RESISTANCE',
  Menu_Design_Module6: 'COMFORTABLE & VERSATILE FIT',
  Menu_Design_Module7: 'DESIGNED FOR COMFORT',
  Menu_Design_Module8: 'COMPACT DESIGN',
  Menu_Design_Module9: 'CONVENIENT CHARGING CASE',
  Menu_Design_Module10: 'MAGNETIC POSITIONING',
  Menu_Design_Module11: 'PRECISE CONTACT',
  Menu_Design_Module12: 'COLORS',
  Menu_Design_Module13: 'DESIGN SUMMARY',
  Menu_Compatibility_Module1: 'COMPATIBILITY INTRO',
  Menu_Compatibility_Module2: 'COMPATIBLE WITH APPLE AND ANDROID',
  Menu_Compatibility_Module3: 'ONE-TOUCH PAIRING',
  Menu_Compatibility_Module4: 'VOICE ASSISTANT',
  Menu_Compatibility_Module5: 'LOCATE YOUR DEVICE',
  Menu_Compatibility_Module6: 'USB-C CHARGING',
  Menu_Compatibility_Module7: 'MANAGE YOUR DEVICE',
  Menu_Compatibility_Module8: 'COMPATIBILITY SUMMARY',
  Menu_Control_Module1: 'CONTROL INTRO',
  Menu_Control_Module2: 'EASY ACCESS',
  Menu_Control_Module3: 'ON-EAR CONTROLS',
  Menu_Control_Module4: 'PICK A SIDE',
  Menu_Control_Module5: 'SAY IT WITH SIRI',
  Menu_Control_Module6: 'CONTROL SUMMARY',
  Menu_Battery_Module1: 'BATTERY INTRO',
  Menu_Battery_Module2: 'ALL-DAY BATTERY',
  Menu_Battery_Module3: '8 HOURS OF LISTENING TIME',
  Menu_Battery_Module4: '24 HOURS OF COMBINED PLAYBACK',
  Menu_Battery_Module5: 'FAST FUEL',
  Menu_Battery_Module6: 'BATTERY USE CASES',
  Menu_Battery_Module7: "WHAT'S IN THE BOX",
  Menu_Battery_Module8: 'BATTERY SUMMARY',
  Outro_ChapterText1:
    'With Active Noise Cancelling, Transparency mode, premium sound, and a wireless, pocket-sized design, <em>Beats Studio Buds fit seamlessly into your life.</em>',
  IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
  Blank: ' ',
  Global_Country_ALBANIA: 'ALBANIA',
  Global_Country_ALGERIA: 'ALGERIA',
  Global_Country_ANDORRA: 'ANDORRA',
  Global_Country_ANGOLA: 'ANGOLA',
  Global_Country_ARGENTINA: 'ARGENTINA',
  Global_Country_ARMENIA: 'ARMENIA',
  Global_Country_ARUBA: 'ARUBA',
  Global_Country_AUSTRALIA: 'AUSTRALIA',
  Global_Country_AUSTRIA: 'AUSTRIA',
  Global_Country_AZERBAIJAN: 'AZERBAIJAN',
  Global_Country_BAHAMAS: 'BAHAMAS',
  Global_Country_BAHRAIN: 'BAHRAIN',
  Global_Country_BANGLADESH: 'BANGLADESH',
  Global_Country_BARBADOS: 'BARBADOS',
  Global_Country_BELARUS: 'BELARUS',
  Global_Country_BELGIUM: 'BELGIUM',
  Global_Country_BOLIVIA: 'BOLIVIA',
  Global_Country_BOSNIAHERZRGOVINA: 'BOSNIA & HERZRGOVINA',
  Global_Country_BOTSWANA: 'BOTSWANA',
  Global_Country_BRAZIL: 'BRAZIL',
  Global_Country_BRUNEI: 'BRUNEI DARUSSALAM',
  Global_Country_BULGARIA: 'BULGARIA',
  Global_Country_CAMBODIA: 'CAMBODIA',
  Global_Country_CAMEROON: 'CAMEROON',
  Global_Country_CANADA: 'CANADA',
  Global_Country_CAYMANISLANDS: 'CAYMAN ISLANDS',
  Global_Country_CHILE: 'CHILE',
  Global_Country_CHINA: 'CHINA (MAINLAND)',
  Global_Country_COLOMBIA: 'COLOMBIA',
  Global_Country_COOKISLANDS: 'COOK ISLANDS',
  Global_Country_COSTARICA: 'COSTA RICA',
  Global_Country_CROATIA: 'CROATIA',
  Global_Country_CYPRUS: 'CYPRUS',
  Global_Country_CZECHREPUBLIC: 'CZECH REPUBLIC',
  Global_Country_DENMARK: 'DENMARK',
  Global_Country_DOMINICANREPUBLIC: 'DOMINICAN REPUBLIC',
  Global_Country_ECUADOR: 'ECUADOR',
  Global_Country_EGYPT: 'EGYPT',
  Global_Country_ELSALVADOR: 'EL SALVADOR',
  Global_Country_ESTONIA: 'ESTONIA',
  Global_Country_FAROEISLANDS: 'FAROE ISLANDS',
  Global_Country_FIJI: 'FIJI',
  Global_Country_FINLAND: 'FINLAND',
  Global_Country_FRANCE: 'FRANCE',
  Global_Country_FRENCHGUIANA: 'FRENCH GUIANA',
  Global_Country_FRENCHPOLYNESIA: 'FRENCH POLYNESIA',
  Global_Country_GEORGIA: 'GEORGIA',
  Global_Country_GERMANY: 'GERMANY',
  Global_Country_GHANA: 'GHANA',
  Global_Country_GIBRALTAR: 'GIBRALTAR',
  Global_Country_GREECE: 'GREECE',
  Global_Country_GUADELOUPE: 'GUADELOUPE',
  Global_Country_GUAM: 'GUAM',
  Global_Country_GUATEMALA: 'GUATEMALA',
  Global_Country_GUERNSEY: 'GUERNSEY',
  Global_Country_GUINEA: 'GUINEA',
  Global_Country_GUYANA: 'GUYANA',
  Global_Country_HONDURAS: 'HONDURAS',
  Global_Country_HONGKONG: 'HONG KONG',
  Global_Country_HUNGARY: 'HUNGARY',
  Global_Country_ICELAND: 'ICELAND',
  Global_Country_INDIA: 'INDIA',
  Global_Country_INDONESIA: 'INDONESIA',
  Global_Country_IRAQ: 'IRAQ',
  Global_Country_IRELAND: 'IRELAND',
  Global_Country_ISLEOFMAN: 'ISLE OF MAN',
  Global_Country_ISRAEL: 'ISRAEL',
  Global_Country_ITALY: 'ITALY',
  Global_Country_IVORYCOAST: 'IVORY COAST',
  Global_Country_JAPAN: 'JAPAN',
  Global_Country_JERSEY: 'JERSEY',
  Global_Country_JORDAN: 'JORDAN',
  Global_Country_KAZAKHSTAN: 'KAZAKHSTAN',
  Global_Country_KENYA: 'KENYA',
  Global_Country_KOREA: 'KOREA, REPUBLIC OF',
  Global_Country_KUWAIT: 'KUWAIT',
  Global_Country_KYRGYZSTAN: 'KYRGYZSTAN',
  Global_Country_LAOS: 'LAOS',
  Global_Country_LATVIA: 'LATVIA',
  Global_Country_LEBANON: 'LEBANON',
  Global_Country_LESOTHO: 'LESOTHO',
  Global_Country_LITHUANIA: 'LITHUANIA',
  Global_Country_LUXEMBOURG: 'LUXEMBOURG',
  Global_Country_MACAU: 'MACAU',
  Global_Country_MACEDONIA: 'MACEDONIA',
  Global_Country_MADAGASCAR: 'MADAGASCAR',
  Global_Country_MALAWI: 'MALAWI',
  Global_Country_MALAYSIA: 'MALAYSIA',
  Global_Country_MALDIVES: 'MALDIVES',
  Global_Country_MALTA: 'MALTA',
  Global_Country_MARTINIQUE: 'MARTINIQUE',
  Global_Country_MAURITIUS: 'MAURITIUS',
  Global_Country_MAYOTTE: 'MAYOTTE',
  Global_Country_MEXICO: 'MEXICO',
  Global_Country_MOLDOVA: 'MOLDOVA',
  Global_Country_MONACO: 'MONACO',
  Global_Country_MONTENEGRO: 'MONTENEGRO',
  Global_Country_MOROCCO: 'MOROCCO',
  Global_Country_MOZAMBIQUE: 'MOZAMBIQUE',
  Global_Country_MYANMAR: 'MYANMAR',
  Global_Country_NAMIBIA: 'NAMIBIA',
  Global_Country_NEPAL: 'NEPAL',
  Global_Country_NETHERLANDS: 'NETHERLANDS',
  Global_Country_NEWCALEDONIA: 'NEW CALEDONIA',
  Global_Country_NEWZEALAND: 'NEW ZEALAND',
  Global_Country_NICARAGUA: 'NICARAGUA',
  Global_Country_NIGERIA: 'NIGERIA',
  Global_Country_NORWAY: 'NORWAY',
  Global_Country_OMAN: 'OMAN',
  Global_Country_PAKISTAN: 'PAKISTAN',
  Global_Country_PALESTINIANTERRITORY: 'PALESTINIAN TERRITORY',
  Global_Country_PANAMA: 'PANAMA',
  Global_Country_PARAGUAY: 'PARAGUAY',
  Global_Country_PERU: 'PERU',
  Global_Country_PHILIPPINES: 'PHILIPPINES',
  Global_Country_POLAND: 'POLAND',
  Global_Country_PORTUGAL: 'PORTUGAL',
  Global_Country_PUERTORICO: 'PUERTO RICO',
  Global_Country_QATAR: 'QATAR',
  Global_Country_REUNION: 'REUNION',
  Global_Country_ROMANIA: 'ROMANIA',
  Global_Country_RUSSIA: 'RUSSIA',
  Global_Country_SAINTMARTIN: 'SAINT MARTIN',
  Global_Country_SANMARINO: 'SAN MARINO',
  Global_Country_SAUDIARABIA: 'SAUDI ARABIA',
  Global_Country_SENEGAL: 'SENEGAL',
  Global_Country_SERBIA: 'SERBIA',
  Global_Country_SINGAPORE: 'SINGAPORE',
  Global_Country_SINTMAARTEN: 'SINT MAARTEN',
  Global_Country_SLOVAKIA: 'SLOVAKIA',
  Global_Country_SLOVENIA: 'SLOVENIA',
  Global_Country_SOUTHAFRICA: 'SOUTH AFRICA',
  Global_Country_SPAIN: 'SPAIN',
  Global_Country_SRILANKA: 'SRI LANKA',
  Global_Country_SWEDEN: 'SWEDEN',
  Global_Country_SWITZERLAND: 'SWITZERLAND',
  Global_Country_TAIWAN: 'TAIWAN',
  Global_Country_TAJIKISTAN: 'TAJIKISTAN',
  Global_Country_TANZANIA: 'TANZANIA',
  Global_Country_THAILAND: 'THAILAND',
  Global_Country_TRINIDADTOBAGO: 'TRINIDAD & TOBAGO',
  Global_Country_TUNISIA: 'TUNISIA',
  Global_Country_TURKEY: 'TURKEY',
  Global_Country_TURKMENISTAN: 'TURKMENISTAN',
  Global_Country_UGANDA: 'UGANDA',
  Global_Country_UKRAINE: 'UKRAINE',
  Global_Country_UAE: 'UNITED ARAB EMIRATES',
  Global_Country_UK: 'UNITED KINGDOM',
  Global_Country_US: 'UNITED STATES',
  Global_Country_URUGUAY: 'URUGUAY',
  Global_Country_UZBEKISTAN: 'UZBEKISTAN',
  Global_Country_VENEZUELA: 'VENEZUELA',
  Global_Country_VIETNAM: 'VIETNAM',
  Global_Country_ZAMBIA: 'ZAMBIA',
  Global_Country_ZIMBABWE: 'ZIMBABWE',
  IntroOutro_TestYourKnowledge: 'Test your Beats Studio Buds knowledge.',
  IntroOutro_CTA: 'Take The Quiz',
  Global_Menu_Language: 'LANGUAGE',
  Global_Menu_Help: 'Need help? <u>Contact Us</u>',
  Global_Menu_Button_Restart: 'Restart Experience',
  Global_Menu_Button_SelectProduct: 'Select Another Product',
  Global_Field_Location: 'LOCATION',
  Global_Password_Rules_Header: 'Password must contain the following',
  Global_Password_Rule_Length: 'Minimum of 8 characters',
  Global_Password_Rule_Uppercase: '1 uppercase letter',
  Global_Password_Rule_Lowercase: '1 lowercase letter',
  Global_Password_Rule_Number: '1 number',
  Global_Password_Rule_Special: 'Special characters (!@#$%^&*)',

  Global_ProductSelector_Headline: 'Select a Beats TRNR experience:',
  Global_ProductSelector_Product1: 'Beats Studio Buds',
  Global_ProductSelector_Product2: 'Beats Fit Pro',
  Global_ProductSelector_CTA_1: 'Get Started',
  Global_ProductSelector_CTA_2: 'Resume',
  Global_ProductSelector_CTA_3: 'Restart',

  

  Global_WelcomeInstructions_Headline:
    'Welcome to Beats TRNR, where Beats experts are made.',
  Global_WelcomeInstructions_Subhead: 'Here’s what to expect:',
  Global_WelcomeInstructions_Body1:
    'Each training experience should take about 15 minutes to complete.',
  Global_WelcomeInstructions_Body2:
    'Each training experience is meant to be heard. Make sure your sound is on.',
  Global_WelcomeInstructions_Body3:
    'Within each training experience, remember to complete each section before moving on.',
  Global_WelcomeInstructions_Body4:
    'Don’t worry if you need to step away. You can always pick up where you left off.',
};

export default copy;
