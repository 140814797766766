import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import SITES from '../constants/sites';
import Context from '../context/Context';

const TitleComponent = ({ title }) => {
  const context = useContext(Context);
  const { siteID, language, languageID } = context;
  document.documentElement.lang = languageID.split('_')[0].toLowerCase();
  var defaultTitle = 'Beats TRNR';
  const siteName =
    siteID && language
      ? `${
          language[SITES.find(x => x.id === siteID).title]
            ? language[SITES.find(x => x.id === siteID).title]
            : 'Beats TRNR'
        } : ${title}`
      : defaultTitle;

  return (
    <Helmet>
      <title>{siteName ? siteName : defaultTitle}</title>
    </Helmet>
  );
};

export { TitleComponent };
