import React, { useContext, useEffect, useState } from 'react';
import Context from '../../context/Context';
import cookie from 'react-cookies';
import { useHistory } from 'react-router-dom';
import Styles from './productSelector.module.scss';
import * as ROUTES from '../../constants/routes';
import { motion, AnimatePresence } from 'framer-motion';
import SITES from '../../constants/sites';
import { getProgress } from '../../utils';
import { TrackEvent } from '../../utils/track';
// import * as DATA from '../../data';

const ProductSelector = props => {
  const { ga4, progress } = props;
  const history = useHistory();
  const context = useContext(Context);
  const [cookieSite, setCookieSite] = useState(cookie.load('cr_trnr_site'));
  const {
    setCurrentChapter,
    setShowCursor,
    isKioskVersion,
    language,
    setIsHeaderVisible,
    setOverrideTheme,
    overrideTheme,
    setSilenceBackgroundAudio,
    siteID,
    setSiteID,
    theme,
    // isHeaderVisible,
  } = context;
  setShowCursor(false);

  useEffect(() => {
    setCookieSite(cookie.load('cr_trnr_site'));
  }, [cookieSite]);

  useEffect(() => {
    setOverrideTheme(true);
    setSiteID(null);
    setCurrentChapter(null);
    setIsHeaderVisible(false);
    setSilenceBackgroundAudio(true);
    return () => {
      setIsHeaderVisible(true);
      setShowCursor(true);
      setSilenceBackgroundAudio(false);
    };
  }, []);

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const updateSite = code => {
    console.log("-----------updateSite",code)
    if (ga4) {
      TrackEvent(ga4, `site_select_${code}`, 'click', 'user_action');
    }
    setSiteID(code);

    setOverrideTheme(false);
    cookie.save('cr_trnr_site', code, { path: '/' });
    console.log("history.location.search",history.location.search)
    if (isKioskVersion) {
      console.log("ROUTES.WELCOME",ROUTES.WELCOME)

      history.push({
        pathname: ROUTES.WELCOME,
        search: history.location.search,
      });
    } else {
      console.log("ROUTES.HOME",ROUTES.HOME)
      history.push({
        pathname: ROUTES.HOME,
        search: history.location.search,
      });
    }
  };

  const getCTA = site => {
    if (progress[site.id].length === 0) {
      return language['Global_ProductSelector_CTA_1']
        ? language['Global_ProductSelector_CTA_1']
        : 'Get Started';
    } else if (
      getProgress(!isKioskVersion ? progress[site.id] : progress, site.id) ===
      100
    ) {
      return language['Global_ProductSelector_CTA_3']
        ? language['Global_ProductSelector_CTA_3']
        : 'Restart';
    } else {
      return language['Global_ProductSelector_CTA_2']
        ? language['Global_ProductSelector_CTA_2']
        : 'Resume';
    }
  };
// console.log("language['Global_ProductSelector_Headline']",language)
// console.log("overridetheme",overrideTheme)
// console.log("theme",theme)
  return (
    <div className={`${Styles.language}`}>
      <AnimatePresence>
        <motion.div variants={list} className={Styles.languageWrapper}>
          <div className={`${Styles.header}`}>
            {language['Global_ProductSelector_Headline']
              ? language['Global_ProductSelector_Headline']
              : 'Select a Beats TRNR experience:'}
          </div>
          <div>
            {SITES.map((site, i) => (
              <motion.div
                className={`${Styles.item} `}
                key={i}
                onClick={() => updateSite(site.id)}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: i * 0.1 + 0.5, stiffness: 5 }}
              >
                <div className={Styles.productBox}>
                  <div className={Styles.productImage}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/${site.path}.png`}
                      alt={site.title}
                    />
                  </div>
                  <div className={Styles.productInfo}>
                    <h2
                      className={`${
                        siteID === site.id && cookieSite
                          ? Styles.selected
                          : null
                      }`}
                    >
                      {language[site.title]
                        ? language[site.title]
                        : site.title !== 'Global_ProductSelector_Product1'
                        ? 'Beats Fit Pro'
                        : 'Beats Studio Buds'}
                    </h2>

                    <div
                      className={`${Styles.progress} ${
                        progress &&
                        getProgress(
                          !isKioskVersion ? progress[site.id] : progress,
                          site.id
                        ) === 100
                          ? Styles.completed
                          : ''
                      }`}
                      >
                        {language['Global_UI_PercentageComplete'] ? language['Global_UI_PercentageComplete'].replace(
                          '<<number>>',
                          progress
                            ? getProgress(
                              !isKioskVersion
                              ? progress[site.id]
                              : progress,
                              site.id
                            )
                            : 0
                        ) : `${progress
                          ? getProgress(
                            !isKioskVersion
                            ? progress[site.id]
                            : progress,
                            site.id
                          )
                          : 0}% COMPLETE`}
                    </div>

                    <span className={Styles.cta}>
                      {progress && progress[site.id] && getCTA(site)}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ProductSelector;
