import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
const isKioskVersion = process.env.REACT_APP_KIOSK_MODE;

const withAuthorization = (condition) => (Component) => {
	class WithAuthorization extends React.Component {
		componentDidMount() {
			console.log(" withAuthorization componentDidMount isKioskVersion,",isKioskVersion)
			this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
				console.log("onAuthStateChanged")
				if (!condition(authUser) && !isKioskVersion) {
					this.props.history.push({pathname: ROUTES.SIGN_IN, search: this.props.history.location.search});
				}
			});
		}

		componentWillUnmount() {
			this.listener();
		}

		render() {
			return (
				<AuthUserContext.Consumer>
					{(authUser) => (condition(authUser) ? <Component {...this.props} /> : null)}
				</AuthUserContext.Consumer>
			);
		}
	}

	return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
