const TrackPage = (ga4) => {

	ga4.pageview('path');
	// ga4.gtag('event', 'pageview', 'path');
};

const TrackEvent = (ga4, eventname, eventtype, eventcategory) => {
	ga4.event(eventname, eventtype, eventcategory); // or your custom gtag event
};

export { TrackPage, TrackEvent };
