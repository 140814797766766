const copy = {
	Global_UI_Next: '次へ',
Global_UI_Begin: '開始',
Global_UI_Back: '戻る',
Global_UI_Settings: '設定',
Global_UI_Signout: 'サインアウト',
Global_UI_NextChapter: '次のチャプターへ',
Global_UI_PercentageComplete: '<<number>>%完了',
Global_UI_Replay: 'もう一度見る',
Global_Field_Location: 'ロケーション',
Global_Languages_Select: '言語を選択：',
Global_Field_EnterEmail: 'Eメールアドレスを入力してください',
Global_Field_CreatePassword: 'パスワードを作成してください',
Global_Field_Password: 'パスワード',
Global_Field_Password_Forgot: 'パスワードを忘れた場合',
Global_Password_Subtitle: 'パスワードは8文字以上で、大文字、小文字、数字、記号を含める必要があります。',
Global_Password_Change: '変更',
Global_Password_Reset_Headline: 'パスワードをリセット',
Global_Password_Reset_Subtitle: 'パスワードのリセット方法を受け取るEメールアドレスを入力してください。',
Global_Password_Reset_Confirm: '送信されました。Eメールをご確認ください。',
Global_Password_ChangeConfirm: 'パスワードがアップデートされました',
Global_Password_Show: '表示',
Global_Password_Hide: '非表示',
Global_Details_Title: '詳細情報を入力してください',
Global_Details_Field_FirstName: '名',
Global_Details_Field_LastName: '姓',
Global_Details_Field_EmployeeID: '従業員ID（任意）',
Global_Details_Field_BusinessRole: '職務',
Global_Details_Field_BusinessRole1: 'マネージャー',
Global_Details_Field_BusinessRole2: '営業担当者',
Global_Details_Field_BusinessRole3: 'トレーナー',
Global_Details_Field_CompanyName: '会社名：',
Global_Details_Field_Country: '国',
Global_Field_Password_Error: '入力されたパスワードが間違っています。もう一度お試しください。',
Global_Field_Email_Error: '入力されたEメールアドレスは無効です。もう一度お試しください。',
Global_Field_Details_Error: 'すべての必須フィールドに入力してください。',
Global_Greeting: '<<name>>さん、',
Global_CompletionMessage: '<<number>>%完了しました。',
Global_Chapter: 'チャプター<<number>>',
 Global_Chapter_Subtitle: 'チャプター<<number>>／<<total>>',
Global_Intro: 'はじめに',
Global_ChapterTitle_Sound: 'サウンド',
Global_ChapterTitle_ANC: 'ANC',
Global_ChapterTitle_Transparency: '外部音取り込みモード',
Global_ChapterTitle_Design: 'デザイン',
Global_ChapterTitle_Compatibility: '互換性',
Global_ChapterTitle_Controls: 'コントロール',
Global_ChapterTitle_Battery: 'バッテリー',
Global_Menu: 'メニュー',
Intro_Welcome_Label: '体験',
Intro_Welcome_ChapterText: 'Beats Studio BudsのトレーニングAppにようこそ。クリック／タップして始めましょう。',
CR_TRNR_Intro_Sorry: '申し訳ございませんが、問題が発生しました。スーパーバイザーから新しいアクセスリンクを入手してください。',
Intro_Intro_Subtitle: 'Beats Studio Buds、登場。スタジオで生まれたサウンドをそのままお届けする、完全ワイヤレスのノイズキャンセリングイヤーバッド。クリック／タップして、詳しく確認してください。',
Sound_Intro_Title: 'サウンド',
Sound_Intro_VOSubtitle: '最新のテクノロジーが、パワフルでバランスに優れたサウンドを実現します。',
Sound_ChapterText1: '素晴らしいサウンドには、<em>快適なフィット感と密閉性が大切です。</em>',
Sound_FindYourFit_Title: '自分だけのフィット感で音楽を体験しよう',
Sound_FindYourFit_Subtitle: '3つのサイズのシリコーン製イヤーチップにより、自分に合ったフィット感と密閉性を確保。臨場感あふれるサウンドが楽しめます。',
Sound_FindYourFit_Medium: 'ミディアム（デフォルト）',
Sound_FindYourFit_Small: 'スモール',
Sound_FindYourFit_Large: 'ラージ',
Sound_ChapterText2: 'Beats Studio Budsはコンパクトな設計ながら、<em>独自のアコースティックプラットフォームにより、</em><em>パワフルでバランスに優れたサウンドを実現。</em>',
Sound_DrivenByPremiumSound_Title: '心を動かす、極上のサウンド',
Sound_DrivenByPremiumSound_Subtitle: '2枚の振動板を使用した独自のドライバをアコースティックプラットフォームに搭載。クリアでバランスに優れたサウンドをお届けします。',
Sound_MoreMusicLessDistortion_Title: 'クリアなサウンド。歪みを低減。',
Sound_MoreMusicLessDistortion_Subtitle: 'ドライバの振動板には柔軟な素材が使用されており、振動による歪みが低減されるため、特に低音域を含む音域全体で、クリアで緻密に再現されたサウンドを実現します。',
Sound_MoreMusicLessDistortion_Diaphragm: '振動板',
Sound_FineTunedAudio_Title: '緻密なオーディオチューニング',
Sound_FineTunedAudio_Subtitle: '再生音の補正機能を備えたデジタルプロセッサが、周囲の音がサウンドの明瞭さや質感を損なわないよう、リアルタイムに応答します。',
Sound_ChapterText3: '<em>高品質な通話性能とよりクリアな音声により、</em>通話の聞きやすさが向上しました。 ',
Sound_SpeakingOfSound_Title: '高い通話品質 ',
Sound_SpeakingOfSound_Subtitle: '特別設計のポートが外部の雑音を切り離し、デュアルビームフォーミングマイクがユーザーの声を認識することでクリアな音声を実現します。',
Sound_Summary_Title: 'アーティストが届けたいサウンドを再現するために',
Sound_Summary1_ThreeEartip: '3種類のサイズから選べるイヤーチップで、優れたサウンドをぴったりのフィット感で楽しめます。',
Sound_Summary2_CustomAcoustic: '独自のアコースティックプラット<br/>フォームが、高品質でパワフルな<br/>サウンドを実現します。',
Sound_Summary3_FlexibleDriverDiaphragm: '柔軟なドライバの振動板が、クリアで緻密なサウンドを再現します。',
Sound_Summary4_FinetunedAudio: '緻密なオーディオチューニングが、サウンドの明瞭さと質感を再現します。',
Sound_Summary5_SpeakingOfSound: 'デュアルビームフォーミングマイクがユーザーの声を認識し、高品質な通話性能を実現します。',
ANC_Intro_TITLE: 'アクティブノイズキャンセリング ',
ANC_Intro_VOSubtitle: 'アクティブノイズキャンセリング機能が不要な音を遮断するので、集中力をキープできます。',
ANC_ChapterText1: '<em>快適性と密閉性を兼ね備えたイヤーチップが</em>周囲の雑音を遮断するので、臨場感あふれるサウンドを楽しめます。',
ANC_Seal_Title: '密閉性を確保',
ANC_Seal_Subtitle: 'イヤーチップは3種類のサイズから選べます。自分にぴったりのイヤーチップが密閉性を確保し、不要な雑音を遮断してくれます。',
ANC_ChapterText2: 'アダプティブテクノロジーによるANCが<em>周囲の環境に適応し、</em>再生音の補正機能によって<em>サウンドの明瞭さと質感が再現されます。</em>',
ANC_ANCThatAdapts_Title: '周囲の環境に適応するANC',
ANC_ANCThatAdapts_Subtitle: '緻密にチューニングされた<br/>フィルターが周囲の環境に継続的に適応することで、不要な雑音を遮断し、リアルタイムでノイズキャンセリングを最適化します。',
ANC_ANCThatAdapts_AdaptiveANC: 'アダプティブANC',
ANC_Playback_Title: '再生音の補正機能',
ANC_Playback_Subtitle: 'ノイズキャンセリングが適用された場合にも、ANCによってサウンドが損なわれないよう、再生音の補正機能がオリジナルのオーディオファイルとの比較を1秒間に48,000回行います。',
ANC_Playback_ANCProcess: 'ANCのプロセス',
ANC_Playback_PlaybackCorrection: '再生音の補正機能',
ANC_UseCases_Subtitle: '各ユースケースをクリック／タップして、詳しく確認してください。 ',
ANC_UseCases_Studying: '勉強',
ANC_UseCases_Studying_Subtitle: '不要な音を遮断するので、勉強に集中できます',
ANC_UseCases_WorkingOut: 'ワークアウト',
ANC_UseCases_WorkingOut_Subtitle: '何にも邪魔されることなく、ワークアウトに没頭できます',
ANC_UseCases_Airplane: '飛行機',
ANC_UseCases_Airplane_Subtitle: '乗客の声やうるさいエンジン音を遮断するので、フライトをもっと楽しめます',
ANC_UseCases_Commute: '通勤',
ANC_UseCases_Commute_Subtitle: '街中の喧騒を気にすることなく通勤できます',
ANC_Summary_Title: 'ANCで集中力をキープ。',
ANC_Summary1_ImmerseSound: '臨場感あふれるサウンドには、快適性と密閉性が必須です。',
ANC_Summary2_AdaptiveTechnology: 'アダプティブテクノロジーが、周囲の環境に継続的に適応します。',
ANC_Summary3_PlaybackCorrection: '再生音の補正機能が、クリアなサウンドを維持します。',
ANC_Summary4_UseCases: '勉強やワークアウト中、飛行機の中、通勤時などにANCをオンにすれば、集中力をキープできます。',
Transparency_Intro_Title: '外部音取り込みモード ',
Transparency_Intro_VOSubtitle: '外部音取り込みモードにすれば周囲とつながっていられます。',
Transparency_ChapterText1: '<em>周囲の音を聞く必要がある場合は</em>外部音取り込みモードに切り替えられます。',
Transparency_TransparencyMode_Title: '外部音取り込みモード ',
Transparency_TransparencyMode_Subtitle: '外部音取り込みモードが周囲の音を音楽に取り込むので、自然な環境で音楽を聴くことができます。',
Transparency_TransparencyMode_PressHold: '長押し',
Transparency_ChapterText2: '<em>外部マイクが</em>周囲の雑音を検知します。',
Transparency_FeedForwardMic_Title: 'フィードフォワード用のマイク',
Transparency_FeedForwardMic_Subtitle: '各イヤーバッドに搭載されたフィードフォワード用のマイクが、外部の雑音を検知します。',
Transparency_FeedForwardMic_FeedForwardMic: 'フィードフォワード用のマイク',
Transparency_UseCases_Subtitle: '各ユースケースをクリック／タップして、詳しく確認してください。 ',
Transparency_UseCases_Office: 'オフィス',
Transparency_UseCases_Office_Subtitle: '音楽を聴きながらでも、同僚の声を聞き取り共同作業ができます。',
Transparency_UseCases_Travel: '旅行',
Transparency_UseCases_Travel_Subtitle: '空港のゲートや電車のプラットフォームで重要なアナウンスを聞き逃すことがありません。',
Transparency_UseCases_Street: '道路',
Transparency_UseCases_Street_Subtitle: '周囲の車や交差点に気を配っていられます。',
Transparency_Summary_Title: '外部音取り込みモードにすれば周囲とつながっていられます。',
Transparency_Summary1_MixTheSounds: '周囲の音を音楽に取り込みます。',
Transparency_Summary2_FeedForward: 'フィードフォワード用のマイクが、周囲の雑音を検知します。',
Transparency_Summary3_HearYourSurroundings: 'オフィスでも、旅行中でも、道路でも、周囲の状況を把握できます。',
Design_Intro_Title: 'デザイン',
Design_Intro_VOSubtitle: 'Beats Studio Budsは完全ワイヤレスのイヤフォンです。',
Design_ChapterText1: 'ワイヤレスでコンパクトなデザインが、<em>生活のあらゆるシーンに溶け込みます。</em>',
Design_WirelessFreedom_Title: 'ワイヤレスという自由 ',
Design_WirelessFreedom_Subtitle: '動きを制限しない、完全ワイヤレスのイヤーバッド。 ',
Design_PocketSizedPortability_Titles: 'ポケットサイズで持ち運びが簡単',
Design_PocketSizedPortability_Subtitles: 'スリムで軽量の充電ケースは持ち運びやすく、ポケットにすっぽりと収まります。',
Design_WaterAndSweatResistant_Title: '耐汗耐水仕様のイヤーバッド*  ',
Design_WaterAndSweatResistant_Subtitle: 'イヤフォンはIPX4等級の耐汗耐水性能を備えているので、さまざまな活動に対応できます。',
Design_WaterAndSweatResistant_Disclaimer: '* Beats Studio Budsは、ウォータースポーツ以外のスポーツやエクササイズに対応可能な耐汗耐水性能を備えています。',
Design_ChapterText2: 'Beats Studio Budsは幅広いユーザーでテストされており、<em>快適で柔軟なフィット感を提供します。</em>',
Design_Comfort_Title: '快適な装着感',
Design_Comfort_Subtitle: 'ソフトなシリコーン製イヤーバッドは、耳の構造に基づいて設計されており、快適性、安定性、高い密閉性を実現します。',
Design_Comfort_Tragus: '耳珠',
Design_Comfort_Antitragus: '対珠',
Design_SizeMatters_Title: 'コンパクトなデザイン',
Design_SizeMatters_Subtitle: '洗練されたコンパクトなインイヤー型デザインで、他社のほとんどのワイヤレスイヤーバッドよりもさらに軽量で小型です。',
Design_SizeMatters_BeatsStudio: 'BEATS STUDIO BUDS',
Design_SizeMatters_BeatsStudio_10_1G: '10.1G',
Design_SizeMatters_Bose: 'BOSE QC EARBUDS',
Design_SizeMatters_Bose_18_7G: '18.7G',
Design_SizeMatters_Sony: 'Sony WF-1000XM3',
Design_SizeMatters_Sony_16_5G: '16.5G',
Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
Design_SizeMatters_Samsung_12_5G: '12.5G',
Design_ChapterText3: '一日中持ち運べる便利な充電ケースで、<em>イヤーバッドを安全に保護できます。</em>',
Design_PerfectMatch_Title: 'マグネットによる固定',
Design_PerfectMatch_Subtitle: '充電ケース内に配置されたマグネットにより、イヤーバッドが正しい位置に確実にはまるように設計されています。',
Design_Contact_Title: '正確な接触点',
Design_Contact_Subtitle: 'ケース内に取り付けられたポゴピンには丈夫な板バネが使用されており、イヤーバッドとケースがしっかりと接触するように設計されているため、イヤーバッドが固定され確実に充電することができます。',
Design_Contact_PogoPin: 'ポゴピン',
Design_FindYourMatch_Title: '選べるカラー ',
Design_FindYourMatch_Subtitle: '同色の充電ケースが同梱されています。',
Design_FindYourMatch_White: 'ホワイト',
Design_FindYourMatch_Black: 'ブラック',
Design_FindYourMatch_BeatsRed: 'Beatsレッド',
Design_SustainablePackaging_Title: '持続可能なパッケージ',
Design_SustainablePackaging_Subtitle: 'Beats Studio Budsのパッケージに使われている木製繊維の92%は、再生資源と責任ある方法で管理された資源から調達しています。',
Design_Summary_Title: '完全ワイヤレスの設計',
Design_Summary1_WirelessFreedom: '動きを制限しない、完全ワイヤレスのイヤーバッド。 ',
Design_Summary2_PocketSizedPortability: '軽量の充電ケースは、ポケットに<br/>すっぽりと収まります。',
Design_Summary3_WaterAndSweatResistant: 'イヤーバッドは、IPX4等級の耐汗耐水性能を備えています。',
Design_Summary4_Comfort: '洗練されたコンパクトなイヤーバッドが、快適性と安定性を実現します。',
Design_Summary5_SizeMatters: 'ほとんどのワイヤレスイヤーバッドよりも、さらに軽量で小型のインイヤー型デザインです。',
Design_Summary6_PerfectMatch: '充電ケース内にマグネットが配置されているので、イヤーバッドが正しい位置に確実にはまります。',
Design_Summary7_Contact: 'ケース内のポゴピンにより、イヤーバッドを正しく固定して充電できます。',
Design_Summary8_FindYourMatch: '同色の充電ケース（ブラック、ホワイト、Beatsレッド）が同梱されています。',
Compatibility_Intro_Title: '互換性',
Compatibility_Intro_VOSubtitle: 'Beats Studio Budsは、AppleデバイスとAndroidデバイスの両方に対応しています。',
Compatibility_ChapterText1: '<em>Appleデバイスでも、Androidデバイスでも、</em>簡単に使えます。',
Compatibility_OneTouch_Title: 'ワンタッチペアリング機能 ',
Compatibility_OneTouch_Subtitle: 'Bluetoothのワンタッチペアリング機能により、Appleデバイス<sup>1</sup>またはAndroidデバイス<sup>2</sup>に簡単に接続できます。 ',
Compatibility_OneTouch_Disclaimer: '1 ワンタッチペアリングを利用するにはiOS 14.6以降が必要です。<br/>2 Fast Pairを利用するには、位置情報サービスをオンにする必要があります。この機能を完全に利用するには、Google Playサービスが有効で、Googleアカウントが設定されているAndroid 6.0以降を搭載したデバイスが必要です。',
Compatibility_VoiceAssistant_Title: '音声アシスタント ',
Compatibility_VoiceAssistant_Subtitle: 'Beats Studio Budsは、ユーザが使い慣れている音声アシスタントに対応しており、「長押し」機能をカスタマイズして起動することができます。',
Compatibility_LocateDevice_Title: 'デバイスを探す ',
Compatibility_LocateDevice_Subtitle: 'iOSの「探す」機能またはAndroidの「端末を探す」機能を使えば、イヤーバッドを簡単に見つけることができます。 ',
Compatibility_USBCCharging_Title: 'USB-Cで充電 ',
Compatibility_USBCCharging_Subtitle: 'AppleでもAndroidでも使えるUSB-Cの充電コネクタにより、どこでも充電できます。 ',
Compatibility_ManageDevice_Title: 'デバイスを管理する ',
Compatibility_ManageDevice_Subtitle: 'AppleデバイスまたはBeatsアプリ（Androidの場合）を使って、「長押し」機能といった各種機能や再生モードを管理したり、製品の名前を変更したり、ファームウェアをアップデートしたりすることができます。',
Compatibility_Summary_Title: 'AppleデバイスとAndroidデバイスに対応しています。',
Compatibility_Summary1_OneTouch: 'iOSとAndroidの両方でワンタッチペアリング機能を利用できます。',
Compatibility_Summary2_VoiceAssistant: '使い慣れた音声アシスタントを起動して、通話や音楽などを操作できます。',
Compatibility_Summary3_LocateDevice: 'デバイスを使って、イヤーバッドを簡単に見つけることができます。',
Compatibility_Summary4_USBCCharging: 'USB-C充電コネクタ搭載なので、どこでも充電できます。',
Compatibility_Summary5_ManageDevice: 'iOSまたはBeatsアプリ（Androidの場合）を使って、デバイスを管理できます。',
Control_Intro_Title: 'コントロール',
Control_Intro_VOSubtitle: 'Beats Studio Budsで、いつでもサウンドをコントロール。',
Control_ChapterText1: '<em>簡単にアクセスできるコントロールで、</em>音楽を操作できます。',
Control_OnEarControls_Title: 'オンイヤーコントロール ',
Control_OnEarControls_Subtitle: 'オンイヤーコントロールでは、通話や音楽を操作したり、再生モードを切り替えたり、音声アシスタントを起動したりすることができます。<sup>*</sup>',
Control_OnEarControls_Disclaimer: '* 長押しで動作する機能をカスタマイズするには、iOS 14.6以降を搭載したiPhone、または最新バージョンのBeatsアプリがインストールされているAndroidデバイスが必要です。',
Control_OnEarControls_Instruction: '続けるには、各コントロールをクリック／タップしてください。',
Control_OnEarControls_PlayPause: 'ここを押して再生、一時停止、通話の応答／終了 ',
Control_OnEarControls_Skip: 'ボタンを2回押すと<br/>トラックをスキップ ',
Control_OnEarControls_Previous: 'ボタンを3回押すと<br/>前のトラックを再生 ',
Control_OnEarControls_ScanForward: 'ボタンを2回押し、2回目を長押しすると早送り ',
Control_OnEarControls_ScanBackward: 'ボタンを3回押し、3回目を長押しすると巻き戻し ',
Control_OnEarControls_ToggleModes: '長押しして、再生モードを切り替え／オフにできます。または、音声アシスタントを起動するようにカスタマイズすることもできます。<sup>*</sup>',
Control_PickASide_Title: 'どちらの耳でも',
Control_PickASide_Subtitle: 'iOSまたはBeatsアプリ（Androidの場合）を使って、各イヤーバッドの「長押し」機能をカスタマイズすれば、ANCをオンにしたり音声アシスタントを起動したりできます。<sup>*</sup>',
Control_PickASide_Disclaimer: '* 長押しで動作する機能をカスタマイズするには、iOS 14.6以降を搭載したiPhone、または最新バージョンのBeatsアプリがインストールされているAndroidデバイスが必要です。',
Control_SayItWithSiri_Title: '「Hey Siri」を使おう',
Control_SayItWithSiri_Subtitle: 'ハンズフリーでSiriを起動して、いつでも音楽、通話、音量などを操作できます。<sup>*</sup>',
Control_SayItWithSiri_Disclaimer: '* 言語または地域によってはSiriを利用できない場合があります。また、地域によって機能が異なる場合があります。インターネット接続が必要です。携帯電話データ通信料がかかる場合があります。 ',
Control_SayItWithSiri_SiriCommand1: '「Hey Siri、勉強用のプレイリストを再生して」',
Control_SayItWithSiri_SiriCommand2: '「Hey Siri、近くにある女性が経営しているビジネスを教えて」',
Control_SayItWithSiri_SiriCommand3: '「Hey Siri、タイマーを10分にセットして」',
Control_Summary_Title: 'オンイヤーコントロールで音楽を操作できます。',
Control_Summary1_OnEarControls: 'オンイヤーコントロールで、通話や音楽を操作できます。',
Control_Summary2_PickASide: '左右のイヤーバッドで、「長押し」機能をカスタマイズできます。',
Control_Summary3_SayItWithSiri: 'ハンズフリーでSiriを起動できます。',
Battery_Intro_Title: 'バッテリー',
Battery_Intro_VOSubtitle: '一日中使えるバッテリーで、一日中楽しめます。',
Battery_ChapterText1: '<em>一日中使えるバッテリーで、</em>アクティブな毎日をサポートします。',
Battery_FastFuel_Title: '最大8時間',
Battery_FastFuel_Subtitle: '効率的なチップを組み込んだことで、それぞれのイヤー<br/>バッドで最大8時間の再生が<br/>可能です。<sup>*</sup>',
Battery_FastFuel_Disclaimer: '* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります',
Battery_20Hours_Title: '最大24時間',
Battery_20Hours_Subtitle: 'ポケットサイズの充電ケースで2回充電できるので、合計最大24時間の再生が可能です。<sup>*</sup>',
Battery_20Hours_Disclaimer: '* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります',
Battery_FuelYourDay_Title: '一日中、楽しめる',
Battery_FuelYourDay_Subtitle: 'バッテリー残量が少なくなっても、Fast Fuel機能により5分の急速充電で1時間の再生が可能です。<sup>*</sup>',
Battery_FuelYourDay_Disclaimer: '* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります',
Battery_UseCases_Subtitle: '各ユースケースをクリック／タップして、詳しく確認してください。 ',
Battery_UseCases_Listen: '音楽',
Battery_UseCases_Listen_Subtitle: 'お気に入りのラジオ番組を12週間にわたって聴くことができます。<sup>*</sup>',
Battery_UseCases_Listen_Disclaimer: '* 毎回2時間再生した場合',
Battery_UseCases_Meditate: '瞑想',
Battery_UseCases_Meditate_Subtitle: '雨音で32回瞑想することができます。<sup>*</sup>',
Battery_UseCases_Meditate_Disclaimer: '* 毎回45分再生した場合',
Battery_UseCases_Game: 'ゲーム',
Battery_UseCases_Game_Subtitle: 'お気に入りのゲームを24回プレイできます。<sup>*</sup>',
Battery_UseCases_Game_Disclaimer: '* マイクを使わないゲームを毎回1時間プレイした場合',
Battery_UseCases_Commute: '通勤',
Battery_UseCases_Commute_Subtitle: '24回の通勤時間を楽しめます。<sup>*</sup>',
Battery_UseCases_Commute_Disclaimer: '* 毎回1時間の通勤とした場合。バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります。',
Battery_WhatsInTheBox_Title: '同梱物',
Battery_WhatsInTheBox_Subtitle: 'Beats Studio Budsには、ポケットサイズの充電ケース、ユニバーサルUSB-C充電コネクタ、3種類のサイズから選べるイ<br/>ヤーチップが同梱されています。',
Batter_Summary_Title: '一日中使えるバッテリー。',
Battery_Summary1_PowerYourDay: 'イヤーバッドは、最大8時間の再生が可能です。',
Battery_Summary2_UpTo24: '合計最大24時間の再生が可能です。',
Battery_Summary3_FastFuel: 'Fast Fuelの充電で、1時間の再生が可能です。',
Battery_Summary4_UseCases: '一日中使えるバッテリーで、音楽を聴いたり、瞑想をしたり、ゲームをプレイしたりできます。',
Battery_Summary5_WhatsInTheBox: 'ポケットサイズの充電ケース、USB-C充電コネクタ、3種類のサイズのイヤーチップが同梱されています。',
Menu_Sound_Module1: 'サウンドの紹介',
Menu_Sound_Module2: '優れたサウンドは優れたフィット感から',
Menu_Sound_Module3: '3種類のイヤーチップ',
Menu_Sound_Module4: 'パワフルでバランスに優れたサウンド',
Menu_Sound_Module5: '独自のアコースティックプラットフォーム',
Menu_Sound_Module6: 'クリアなサウンドと歪みを低減 ',
Menu_Sound_Module7: '緻密にチューニングされたオーディオ',
Menu_Sound_Module8: '通話 ',
Menu_Sound_Module9: '高品質な通話性能',
Menu_Sound_Module10: 'サウンドのまとめ',
Menu_ANC_Module1: 'ANCの紹介',
Menu_ANC_Module2: '密閉性を確保',
Menu_ANC_Module3: '密閉性を重視',
Menu_ANC_Module4: 'テクノロジー',
Menu_ANC_Module5: '周囲の環境に適応するANC',
Menu_ANC_Module6: '再生音の補正機能',
Menu_ANC_Module7: 'ANCのユースケース',
Menu_ANC_Module8: 'ANCのまとめ',
Menu_Transparency_Module1: '外部音取り込みモードの紹介',
Menu_Transparency_Module2: '周囲の音を聞く',
Menu_Transparency_Module3: '外部音取り込みモード ',
Menu_Transparency_Module4: '外部マイク',
Menu_Transparency_Module5: 'フィードフォワード用のマイク',
Menu_Transparency_Module6: '外部音取り込みモードのユースケース',
Menu_Transparency_Module7: '外部音取り込みモードのまとめ',
Menu_Design_Module1: 'デザインの紹介',
Menu_Design_Module2: 'ワイヤレスでコンパクトなデザイン',
Menu_Design_Module3: 'ワイヤレスという自由',
Menu_Design_Module4: 'ポケットサイズで持ち運びが簡単',
Menu_Design_Module5: '耐汗耐水仕様',
Menu_Design_Module6: '快適で柔軟なフィット感',
Menu_Design_Module7: '快適な装着感',
Menu_Design_Module8: 'コンパクトなデザイン',
Menu_Design_Module9: '便利な充電ケース',
Menu_Design_Module10: 'マグネットによる固定',
Menu_Design_Module11: '正確な接触点',
Menu_Design_Module12: 'カラー',
Menu_Design_Module13: 'デザインのまとめ',
Menu_Compatibility_Module1: '互換性の紹介',
Menu_Compatibility_Module2: 'AppleとAndroidに対応',
Menu_Compatibility_Module3: 'ワンタッチペアリング機能',
Menu_Compatibility_Module4: '音声アシスタント',
Menu_Compatibility_Module5: 'デバイスを探す ',
Menu_Compatibility_Module6: 'USB-Cで充電 ',
Menu_Compatibility_Module7: 'デバイスを管理する',
Menu_Compatibility_Module8: '互換性のまとめ',
Menu_Control_Module1: 'コントロールの紹介',
Menu_Control_Module2: '簡単な操作',
Menu_Control_Module3: 'オンイヤーコントロール',
Menu_Control_Module4: 'どちらの耳でも',
Menu_Control_Module5: '「Hey Siri」を使おう',
Menu_Control_Module6: 'コントロールのまとめ',
Menu_Battery_Module1: 'バッテリーの紹介',
Menu_Battery_Module2: '一日中使えるバッテリー',
Menu_Battery_Module3: '最大8時間の再生時間',
Menu_Battery_Module4: '合計最大24時間の再生時間',
Menu_Battery_Module5: 'Fast Fuel機能',
Menu_Battery_Module6: 'バッテリーのユースケース',
Menu_Battery_Module7: '同梱物',
Menu_Battery_Module8: 'バッテリーのまとめ',
Outro_ChapterText1: 'アクティブノイズキャンセリング機能、外部音取り込みモード、プレミアムなサウンド、ワイヤレスでポケットサイズのデザインなど、<em>Beats Studio Budsは生活のあらゆるシーンに溶け込みます。</em>',
IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
Global_Country_ALBANIA: 'アルバニア',
Global_Country_ALGERIA: 'アルジェリア',
Global_Country_ANDORRA: 'アンドラ',
Global_Country_ANGOLA: 'アンゴラ',
Global_Country_ARGENTINA: 'アルゼンチン',
Global_Country_ARMENIA: 'アルメニア',
Global_Country_ARUBA: 'アルバ',
Global_Country_AUSTRALIA: 'オーストラリア',
Global_Country_AUSTRIA: 'オーストリア',
Global_Country_AZERBAIJAN: 'アゼルバイジャン',
Global_Country_BAHAMAS: 'バハマ',
Global_Country_BAHRAIN: 'バーレーン',
Global_Country_BANGLADESH: 'バングラデシュ',
Global_Country_BARBADOS: 'バルバドス',
Global_Country_BELARUS: 'ベラルーシ',
Global_Country_BELGIUM: 'ベルギー',
Global_Country_BOLIVIA: 'ボリビア',
Global_Country_BOSNIAHERZRGOVINA: 'ボスニア・ヘルツェゴビナ',
Global_Country_BOTSWANA: 'ボツワナ',
Global_Country_BRAZIL: 'ブラジル',
Global_Country_BRUNEI: 'ブルネイ・ダルサラーム',
Global_Country_BULGARIA: 'ブルガリア',
Global_Country_CAMBODIA: 'カンボジア',
Global_Country_CAMEROON: 'カメルーン',
Global_Country_CANADA: 'カナダ',
Global_Country_CAYMANISLANDS: 'ケイマン諸島',
Global_Country_CHILE: 'チリ',
Global_Country_CHINA : '中国本土',
Global_Country_COLOMBIA: 'コロンビア',
Global_Country_COOKISLANDS: 'クック諸島',
Global_Country_COSTARICA: 'コスタリカ',
Global_Country_CROATIA: 'クロアチア',
Global_Country_CYPRUS: 'キプロス',
Global_Country_CZECHREPUBLIC: 'チェコ共和国',
Global_Country_DENMARK: 'デンマーク',
Global_Country_DOMINICANREPUBLIC: 'ドミニカ共和国',
Global_Country_ECUADOR: 'エクアドル',
Global_Country_EGYPT: 'エジプト',
Global_Country_ELSALVADOR: 'エルサルバドル',
Global_Country_ESTONIA: 'エストニア',
Global_Country_FAROEISLANDS: 'フェロー諸島',
Global_Country_FIJI: 'フィジー',
Global_Country_FINLAND: 'フィンランド',
Global_Country_FRANCE: 'フランス',
Global_Country_FRENCHGUIANA: 'フランス領ギアナ',
Global_Country_FRENCHPOLYNESIA: 'フランス領ポリネシア',
Global_Country_GEORGIA: 'ジョージア',
Global_Country_GERMANY: 'ドイツ',
Global_Country_GHANA: 'ガーナ',
Global_Country_GIBRALTAR: 'ジブラルタル',
Global_Country_GREECE: 'ギリシャ',
Global_Country_GUADELOUPE: 'グアドループ',
Global_Country_GUAM: 'グアム',
Global_Country_GUATEMALA: 'グアテマラ',
Global_Country_GUERNSEY: 'ガーンジー',
Global_Country_GUINEA: 'ギニア',
Global_Country_GUYANA: 'ガイアナ',
Global_Country_HONDURAS: 'ホンジュラス',
Global_Country_HONGKONG: '香港',
Global_Country_HUNGARY: 'ハンガリー',
Global_Country_ICELAND: 'アイスランド',
Global_Country_INDIA: 'インド',
Global_Country_INDONESIA: 'インドネシア',
Global_Country_IRAQ: 'イラク',
Global_Country_IRELAND: 'アイルランド',
Global_Country_ISLEOFMAN: 'マン島',
Global_Country_ISRAEL: 'イスラエル',
Global_Country_ITALY: 'イタリア',
Global_Country_IVORYCOAST: 'コートジボワール',
Global_Country_JAPAN: '日本',
Global_Country_JERSEY: 'ジャージー',
Global_Country_JORDAN: 'ヨルダン',
Global_Country_KAZAKHSTAN: 'カザフスタン',
Global_Country_KENYA: 'ケニア',
Global_Country_KOREA: '韓国',
Global_Country_KUWAIT: 'クウェート',
Global_Country_KYRGYZSTAN: 'キルギス',
Global_Country_LAOS: 'ラオス',
Global_Country_LATVIA: 'ラトビア',
Global_Country_LEBANON: 'レバノン',
Global_Country_LESOTHO: 'レソト',
Global_Country_LITHUANIA: 'リトアニア',
Global_Country_LUXEMBOURG: 'ルクセンブルク',
Global_Country_MACAU: 'マカオ',
Global_Country_MACEDONIA: 'マケドニア',
Global_Country_MADAGASCAR: 'マダガスカル',
Global_Country_MALAWI: 'マラウイ',
Global_Country_MALAYSIA: 'マレーシア',
Global_Country_MALDIVES: 'モルディブ',
Global_Country_MALTA: 'マルタ',
Global_Country_MARTINIQUE: 'マルティニーク',
Global_Country_MAURITIUS: 'モーリシャス',
Global_Country_MAYOTTE: 'マヨット',
Global_Country_MEXICO: 'メキシコ',
Global_Country_MOLDOVA: 'モルドバ',
Global_Country_MONACO: 'モナコ',
Global_Country_MONTENEGRO: 'モンテネグロ',
Global_Country_MOROCCO: 'モロッコ',
Global_Country_MOZAMBIQUE: 'モザンビーク',
Global_Country_MYANMAR: 'ミャンマー',
Global_Country_NAMIBIA: 'ナミビア',
Global_Country_NEPAL: 'ネパール',
Global_Country_NETHERLANDS: 'オランダ',
Global_Country_NEWCALEDONIA: 'ニューカレドニア',
Global_Country_NEWZEALAND: 'ニュージーランド',
Global_Country_NICARAGUA: 'ニカラグア',
Global_Country_NIGERIA: 'ナイジェリア',
Global_Country_NORWAY: 'ノルウェー',
Global_Country_OMAN: 'オマーン',
Global_Country_PAKISTAN: 'パキスタン',
Global_Country_PALESTINIANTERRITORY: 'パレスチナ自治区',
Global_Country_PANAMA: 'パナマ',
Global_Country_PARAGUAY: 'パラグアイ',
Global_Country_PERU: 'ペルー',
Global_Country_PHILIPPINES: 'フィリピン',
Global_Country_POLAND: 'ポーランド',
Global_Country_PORTUGAL: 'ポルトガル',
Global_Country_PUERTORICO: 'プエルトリコ',
Global_Country_QATAR: 'カタール',
Global_Country_REUNION: 'レユニオン',
Global_Country_ROMANIA: 'ルーマニア',
Global_Country_RUSSIA: 'ロシア',
Global_Country_SAINTMARTIN: 'サン・マルタン',
Global_Country_SANMARINO: 'サンマリノ',
Global_Country_SAUDIARABIA: 'サウジアラビア',
Global_Country_SENEGAL: 'セネガル',
Global_Country_SERBIA: 'セルビア',
Global_Country_SINGAPORE: 'シンガポール',
Global_Country_SINTMAARTEN: 'シント・マールテン',
Global_Country_SLOVAKIA: 'スロバキア',
Global_Country_SLOVENIA: 'スロベニア',
Global_Country_SOUTHAFRICA: '南アフリカ',
Global_Country_SPAIN: 'スペイン',
Global_Country_SRILANKA: 'スリランカ',
Global_Country_SWEDEN: 'スウェーデン',
Global_Country_SWITZERLAND: 'スイス',
Global_Country_TAIWAN: '台湾',
Global_Country_TAJIKISTAN: 'タジキスタン',
Global_Country_TANZANIA: 'タンザニア',
Global_Country_THAILAND: 'タイ',
Global_Country_TRINIDADTOBAGO: 'トリニダード・ドバゴ',
Global_Country_TUNISIA: 'チュニジア',
Global_Country_TURKEY: 'トルコ',
Global_Country_TURKMENISTAN: 'トルクメニスタン',
Global_Country_UGANDA: 'ウガンダ',
Global_Country_UKRAINE: 'ウクライナ',
Global_Country_UAE: 'アラブ首長国連邦',
Global_Country_UK: '英国',
Global_Country_US: '米国',
Global_Country_URUGUAY: 'ウルグアイ',
Global_Country_UZBEKISTAN: 'ウズベキスタン',
Global_Country_VENEZUELA: 'ベネズエラ',
Global_Country_VIETNAM: 'ベトナム',
Global_Country_ZAMBIA: 'ザンビア',
Global_Country_ZIMBABWE: 'ジンバブエ',
Global_Menu_Confirm: '確認',
Global_Menu_Help: 'サポートが必要ですか？<u>お問い合わせ</u>',
Global_Menu_Restart: 'もう一度確認する',
IntroOutro_TestYourKnowledge: 'Beats Studio Budsの理解度をテストしましょう。',
IntroOutro_CTA: 'クイズを受ける',
Global_Menu_Language: '言語',
Global_Password_Rules_Header: 'パスワードには次のものが含まれている必要があります',
Global_Password_Rule_Length: '最小文字数：8文字',
Global_Password_Rule_Uppercase: '1つの大文字',
Global_Password_Rule_Lowercase: '1つの小文字',
Global_Password_Rule_Number: '1つの数字',
Global_Password_Rule_Special: '記号（!@#$%^&*）',
Global_Field_Password_CreateError: 'パスワードが最小要件を満たしていません。',


Global_ProductSelector_Headline: "Beats TRNR体験を選択",
Global_ProductSelector_Product1: "Beats Studio Buds",
Global_ProductSelector_Product2: "Beats Fit Pro",
Global_ProductSelector_CTA_1: "始める",
Global_ProductSelector_CTA_2: "再開する",
Global_ProductSelector_CTA_3: "やり直す",
Global_WelcomeInstructions_Headline: "Beats TRNRへようこそ。Beatsのエキスパートへの道のりはここからスタートします。",
Global_WelcomeInstructions_Subhead: "今後の流れは以下の通りです。",
Global_WelcomeInstructions_Body1: "各トレーニングは15分ほどで終わります。",
Global_WelcomeInstructions_Body2: "トレーニングではそれぞれサウンドが再生されます。サウンドが「オン」になっていることを確認してください。",
Global_WelcomeInstructions_Body3: "トレーニング体験を終了したら、先に進む前に各セクションを完了してください。",
Global_WelcomeInstructions_Body4: "トレーニングを中断することも可能です。その場合、後で中断したところから再生できます。",
Intro_Welcome_ReturningUser: "前回終了したところから再開しますか？クリック／タップして、Beats Fit Proのトレーニングを続けましょう。",
FitPro_Menu_Chapter1: "CH 1",
FitPro_Menu_Chapter2: "CH 2",
FitPro_Menu_Chapter3: "CH 3",
FitPro_Menu_Chapter4: "CH 4",
FitPro_Menu_Chapter5: "CH 5",
FitPro_Menu_Chapter6: "CH 6",
FitPro_Menu_Chapter7: "CH 7",
FitPro_Menu_ChapterTitle_Fit: "フィット感",
FitPro_Menu_ChapterTitle_Sound: "サウンド",
FitPro_Menu_ChapterTitle_ListeningModes: "再生モード",
FitPro_Menu_ChapterTitle_Controls: "コントロール",
FitPro_Menu_ChapterTitle_AppleH1Chip: "Apple H1チップ",
FitPro_Menu_ChapterTitle_Design: "デザイン",
FitPro_Menu_ChapterTitle_Battery: "バッテリー",
Global_Menu_Button_Restart: "もう一度再生する",
Global_Menu_Button_SelectProduct: "別の製品を選択する",
Global_Menu_Button_Contact: "サポートが必要ですか？お問い合わせ",
FitPro_Menu_ChapterTitle_Fit_1: "柔軟でしっかりとフィット",
FitPro_Menu_ChapterTitle_Fit_2: "耳にしっかりとフィットするウィングチップ",
FitPro_Menu_ChapterTitle_Fit_3: "一日中快適",
FitPro_Menu_ChapterTitle_Fit_4: "あらゆる形の耳にフィット",
FitPro_Menu_ChapterTitle_Fit_5: "素材",
FitPro_Menu_ChapterTitle_Fit_6: "優れたフィット感",
FitPro_Menu_ChapterTitle_Fit_7: "3つのイヤーチップ",
FitPro_Menu_ChapterTitle_Fit_8: "装着状態テスト",
FitPro_Menu_ChapterTitle_Fit_9: "密閉性",
FitPro_Menu_ChapterTitle_Fit_10: "フィット感のまとめ",
FitPro_Menu_ChapterTitle_Sound_1: "独自のドライバ",
FitPro_Menu_ChapterTitle_Sound_2: "ドライバのサイズ",
FitPro_Menu_ChapterTitle_Sound_3: "プリーツドライバ",
FitPro_Menu_ChapterTitle_Sound_4: "理想的な空気の流れ",
FitPro_Menu_ChapterTitle_Sound_5: "音の歪みを低減",
FitPro_Menu_ChapterTitle_Sound_6: "並外れたサウンド",
FitPro_Menu_ChapterTitle_Sound_7: "空間オーディオ",
FitPro_Menu_ChapterTitle_Sound_8: "空間オーディオのユースケース",
FitPro_Menu_ChapterTitle_Sound_9: "3つのマイク",
FitPro_Menu_ChapterTitle_Sound_10: "サウンドのまとめ",
FitPro_Menu_ChapterTitle_Modes_1: "3つの再生モード",
FitPro_Menu_ChapterTitle_Modes_2: "アクティブノイズキャンセリング",
FitPro_Menu_ChapterTitle_Modes_3: "外向きのマイク",
FitPro_Menu_ChapterTitle_Modes_4: "アンチノイズ",
FitPro_Menu_ChapterTitle_Modes_5: "内向きのマイク",
FitPro_Menu_ChapterTitle_Modes_6: "アクティブノイズキャンセリングのユースケース",
FitPro_Menu_ChapterTitle_Modes_7: "外部音取り込みモード",
FitPro_Menu_ChapterTitle_Modes_8: "周囲の状況に気を配る",
FitPro_Menu_ChapterTitle_Modes_9: "外部マイク",
FitPro_Menu_ChapterTitle_Modes_10: "外部音取り込みモードのユースケース",
FitPro_Menu_ChapterTitle_Modes_11: "アダプティブイコライゼーション",
FitPro_Menu_ChapterTitle_Modes_12: "あなたに合わせて調整",
FitPro_Menu_ChapterTitle_Modes_13: "再生モードのまとめ",
FitPro_Menu_ChapterTitle_Controls_1: "オンイヤーボタン",
FitPro_Menu_ChapterTitle_Controls_2: "プッシュして再生",
FitPro_Menu_ChapterTitle_Controls_3: "イヤーバッド片方での使用が可能",
FitPro_Menu_ChapterTitle_Controls_4: "オーディオと再生モードの選択",
FitPro_Menu_ChapterTitle_Controls_5: "再生モードの切り替え",
FitPro_Menu_ChapterTitle_Controls_6: "オンイヤーコントロール",
FitPro_Menu_ChapterTitle_Controls_7: "オンイヤーボタンのカスタマイズ",
FitPro_Menu_ChapterTitle_Controls_8: "Androidとの互換性",
FitPro_Menu_ChapterTitle_Controls_9: "コントロールのまとめ",
FitPro_Menu_ChapterTitle_H1_1: "Appleデバイスとのシームレスな統合",
FitPro_Menu_ChapterTitle_H1_2: "Apple H1チップ",
FitPro_Menu_ChapterTitle_H1_3: "ワンタッチペアリング機能",
FitPro_Menu_ChapterTitle_H1_4: "Hey Siri",
FitPro_Menu_ChapterTitle_H1_5: "自動デバイス切り替え",
FitPro_Menu_ChapterTitle_H1_6: "オーディオ共有",
FitPro_Menu_ChapterTitle_H1_7: "デバイスを探す",
FitPro_Menu_ChapterTitle_H1_8: "Apple H1チップのまとめ",
FitPro_Menu_ChapterTitle_Design_1: "充電ケース",
FitPro_Menu_ChapterTitle_Design_2: "ポケットにフィット",
FitPro_Menu_ChapterTitle_Design_3: "50%小型化",
FitPro_Menu_ChapterTitle_Design_4: "選べるカラー",
FitPro_Menu_ChapterTitle_Design_5: "どこでも何をしていても",
FitPro_Menu_ChapterTitle_Design_6: "ワイヤレスという自由",
FitPro_Menu_ChapterTitle_Design_7: "イヤーバッド片方での使用が可能",
FitPro_Menu_ChapterTitle_Design_8: "雨、汗、晴れでも",
FitPro_Menu_ChapterTitle_Design_9: "デザインのまとめ",
FitPro_Menu_ChapterTitle_Battery_1: "長時間の再生",
FitPro_Menu_ChapterTitle_Battery_2: "合計最大24時間",
FitPro_Menu_ChapterTitle_Battery_3: "Fast Fuel機能",
FitPro_Menu_ChapterTitle_Battery_4: "USB-Cで充電",
FitPro_Menu_ChapterTitle_Battery_5: "同梱物",
FitPro_Menu_ChapterTitle_Battery_6: "バッテリーのまとめ",
FitPro_Intro_Title: 'Beats<br/>Fit Pro',
FitPro_Intro_Subtitle: "Beats Fit Proの紹介。アクティブな一日を過ごせるよう作られた完全ワイヤレスノイズキャンセリングイヤフォン。クリック／タップして、詳しく確認してください。",
FitPro_Fit_Title: "フィット感",
FitPro_Fit_Intro_VOSubtitle: "Beats Fit Proは、一日中快適で安定した着け心地が続くよう設計されています。",
FitPro_Fit_ChapterText1: "<em>耳の形に合わせてフィット</em>する柔軟なウィングチップで、快適で安定した着け心地が続きます。",
FitPro_Fit_FlexibleWingtip_Title: "耳にしっかりとフィットするウィングチップ",
FitPro_Fit_FlexibleWingtip_Subtitle: "Beats Fit Proの開発では、完璧なウィングチップをデザインすることが優先事項でした。調査の結果、ほぼすべての耳で安定した着け心地になるウィングチップを開発するためには、一人ひとりの耳の解剖学的構造に合わせることが重要だという結論に達しました。",
FitPro_Fit_AllDay_Title: "一日中快適",
FitPro_Fit_AllDay_Subtitle: "ウィングチップのユニバーサルデザインは、趣味で走るランナーからプロのアスリートまで、あらゆるスポーツのアスリートによる1年間の徹底的なテストが行われました。",
FitPro_Fit_Universal_Title: "あらゆる耳にフィットするウィングチップ",
FitPro_Fit_Universal_Subtitle: "ウィングチップは耳の形に応じてフィットします。自在に曲がるウィングチップがさまざまな形の耳にしっかりとフィットします。",
FitPro_Fit_Materials_Title: "ソフトな安定感",
FitPro_Fit_Materials_Subtitle: "独特なウィングチップデザインは、何千もの耳を測定してデジタルモデル化したもので、硬いコアを柔らかくしなやかな素材で包み込むという完璧な組み合わせが、あらゆる耳の形状、サイズにしっかりと快適にフィットします。",
FitPro_Fit_ChapterText2: "優れたサウンドは<em>優れたフィット感から</em>。自分に合ったイヤーチップで最高のフィット感を。",
FitPro_Fit_EarTips_Title: "自分のサイズを見つけよう",
FitPro_Fit_EarTips_Subtitle: "選べる3種類のシリコーン製イヤーチップが、どんな耳にもぴったりとフィットし、高い密閉性を実現します。",
FitPro_Fit_FitTest_Title: "フィット感をテスト",
FitPro_Fit_FitTest_Subtitle: "AppleデバイスとAndroidデバイスの両方で使える装着状態テストでフィット感を調整すれば、最適な密閉性と最高のサウンドパフォーマンスを得られます。装着状態テストでは、ノイズの流入を分析して最適な密閉性を実現します。*",
FitPro_Fit_FitTest_Disclaimer: "* イヤーチップの装着状態テストには、iOS 15.1以降、またはAndroid用のBeatsアプリが必要です",
FitPro_Fit_Seal_Title: "密閉性とサウンドパフォーマンス",
FitPro_Fit_Seal_Subtitle: "適切にフィットすることで、どんな耳でも高い密閉性と最適なパフォーマンスを可能にします。適切な密閉性が確保されていると、アクティブノイズキャンセリングモードと外部音取り込みモードが最高のパフォーマンスを発揮できます。",
FitPro_Fit_Summary_Title: "快適さと安定性を重視したデザイン",
FitPro_Fit_Summary1_FlexibleWingtip: "Beats Fit Proの開発では、完璧なウィングチップをデザインすることが優先事項でした。 ",
FitPro_Fit_Summary2_AllDay: "あらゆるスポーツのアスリートによって快適性がテストされたユニバーサルウィングチップデザイン。",
FitPro_Fit_Summary3_Universal: "耳の形が違えば、ウィングチップのフィットも異なります。 ",
FitPro_Fit_Summary4_Materials: "Beats Fit Proのウィングチップ素材は柔らかな手触りながら、剛性も備えています。",
FitPro_Fit_Summary5_EarTips: "3つのサイズから選べる、ソフトなシリコーン製イヤーチップを同梱。自分に合ったサイズを選べます。",
FitPro_Fit_Summary6_FitTest: "装着状態テストでフィット感を調整すれば、最適な密閉性と最高のサウンドパフォーマンスを得られます。",
FitPro_Fit_Summary7_Seal: "適切な密閉性が得られると、ベストな音質で音楽を楽しめます。",
FitPro_Fit_Summary7_Seal_ChineseAlt: "",
FitPro_Sound_Title: "サウンド",
FitPro_Sound_Intro_VOSubtitle: "Beats Fit Proはパワフルでバランスに優れたサウンドを実現できるよう設計されています。",
FitPro_Sound_ChapterText1: "Beats Fit Proは、周波数カーブ全体で幅広いダイナミックレンジを実現する<em>独自のドライバ</em>を内蔵しています。 ",
FitPro_Sound_Driver_Title: "小さくてもパワフル",
FitPro_Sound_Driver_Subtitle: "直径わずか9.5mmの小さなドライバでも、低音、高音、中音域のサウンドは驚異的。1セント硬貨の直径の約半分です。",
FitPro_Sound_Driver_Label: "9.5 mm",
FitPro_Sound_Pleated_Title: "完璧なプリーツ",
FitPro_Sound_Pleated_Subtitle: "Beats Fit Pro独自のプリーツドライバは、微小振動の大幅な低減と柔軟性を可能にします。プリーツドライバは設計と製造が特に難しいパーツです。",
FitPro_Sound_Airflow_Title: "空気の流れを妨げない",
FitPro_Sound_Airflow_Subtitle: "ドライバを通過する適切な空気の流れが、高音の歪みを最小限に抑え、パワフルな低音を生み出します。",
FitPro_Sound_UniqueDriver_Title: "Beatsならではのサウンド",
FitPro_Sound_UniqueDriver_Subtitle: "独自のドライバ設計が、信じられないほど歪みの少ない、パワフルでバランスの取れたサウンドを生み出します。",
FitPro_Sound_ChapterText2: "どんな聴き方にも合う<em>抜群のサウンドをお楽しみください</em>。",
FitPro_Sound_SpatialAudio_Title: "あらゆる方向から聴こえます",
FitPro_Sound_SpatialAudio_Subtitle: "ダイナミックヘッドトラッキングによる空間オーディオは、動きに合わせて調整を行い、臨場感あるサウンドを届けます。*",
FitPro_Sound_SpatialAudio_Disclaimer: "* 対応するハードウェアとソフトウェアが必要です。サポートされているアプリ内の対応するコンテンツで利用できます。コンテンツによってはドルビーアトモスに対応しない場合があります。ヘッドトラッキング機能にはiPhoneまたはiPadが必要です。",
FitPro_Sound_UseCases_Subtitle: "各ユースケースをクリック／タップして、詳しく確認してください。",
FitPro_Sound_UseCases_Music: "ミュージック",
FitPro_Sound_UseCases_Music_Subtitle: "立体的な奥行きのあるサウンドで、あたかもスタジオにいるかのようにお気に入りの音楽を楽しめます。*",
FitPro_Sound_UseCases_Movies: "映画",
FitPro_Sound_UseCases_Movies_Subtitle: "映画館のようなサウンドで音に包まれながらお気に入りの映画を楽しめます。*",
FitPro_Sound_UseCases_Games: "ゲーム",
FitPro_Sound_UseCases_Games_Subtitle: "まったく新しい臨場感あふれるゲームをお楽しみください。*",
FitPro_Sound_UseCases_Disclaimer_1: "* 対応するハードウェアとソフトウェアが必要です。サポートされているアプリ内の対応するコンテンツで利用できます。コンテンツによってはドルビーアトモスに対応しない場合があります。ヘッドトラッキング機能にはiPhoneまたはiPadが必要です。",
FitPro_Sound_Calls_Title: "パワフルでクリアな音質",
FitPro_Sound_Calls_Subtitle: "Beats Fit Proは、加速度センサーとClass 1 Bluetooth®テクノロジーで通話中の声を認識します。マイクが声を正確に捉え、デジタルプロセッサが周囲の音や風切音をカット。クリアで聴き取りやすい音声を届けます。",
FitPro_Sound_Summary_Title: "パワフルでバランスの良いサウンドを生み出すデザイン",
FitPro_Sound_Summary1_Driver: "直径わずか9.5mmの小さなドライバでも、低音、高音、中音域のサウンドは驚異的。",
FitPro_Sound_Summary2_Pleated: "Beats Fit Pro独自のプリーツドライバは、微小振動の低減と柔軟性を可能にします。",
FitPro_Sound_Summary3_Airflow: "ドライバを通過する適切な空気の流れが、高音の歪みを最小限に抑え、クリアな低音パフォーマンスを実現します。",
FitPro_Sound_Summary4_UniqueDriver: "独自のドライバ設計が、信じられないほど歪みの少ない、パワフルでバランスの取れたサウンドを生み出します。",
FitPro_Sound_Summary5_SpatialAudio: "ダイナミックヘッドトラッキングによる空間オーディオは、動きに合わせて調整を行い、臨場感あるサウンドを届けます。",
FitPro_Sound_Summary6_UseCases: "映画館にいるかのような臨場感あふれるサウンドを届ける空間オーディオとダイナミックヘッドトラッキング。",
FitPro_Sound_Summary7_Calls: "Beats Fit Proはあなたの声を検知して通話を最適化します。",
FitPro_Modes_Title: "再生モード",
FitPro_Modes_Intro_VOSubtitle: "3つのリスニングモードでサウンドをコントロール。 ",
FitPro_Modes_Toggle_Title: "ノイズを支配する",
FitPro_Modes_Toggle_Subtitle: "アクティブノイズキャンセリング（ANC）、外部音取り込みモード、アダプティブイコライゼーションの3つの異なるリスニングモードの中から、ニーズに合うモードを簡単に選択できます。",
FitPro_Modes_Toggle_Disclaimer: "デモンストレーション用に特別に作成されました。",
FitPro_Modes_Toggle_Label1: "ノイズキャンセリング",
FitPro_Modes_Toggle_Label2: "アダプティブイコライゼーション",
FitPro_Modes_Toggle_Label3: "外部音取り込みモード",
FitPro_Modes_Toggle_MusicLabel: "Now Playing",
FitPro_Modes_Toggle_SongTitle: "Mood",
FitPro_Modes_Toggle_SongArtist: "24kGoldn – ft. iann dior",
FitPro_Modes_ChapterText1: "<em>アクティブノイズキャンセリング</em>では、外部ノイズを遮断して音楽を楽しむことができます。",
FitPro_Modes_ANCExternalMic_Title: "周囲の雑音を捉える",
FitPro_Modes_ANCExternalMic_Subtitle: "アクティブノイズキャンセリングでは、最初に外向きマイクが外部の音を検出します。",
FitPro_Modes_ANCAntiNoise_Title: "不要な音を打ち消す",
FitPro_Modes_ANCAntiNoise_Subtitle: "次にBeats Fit Proが外部音に応じて同等のアンチノイズを継続的に生み出すことで、雑音が耳に届く前に打ち消されます。",
FitPro_Modes_ANCInwardMic_Title: "継続的に調整",
FitPro_Modes_ANCInwardMic_Subtitle: "内向きのマイクが余分なサウンドを検知して、装着のずれや密閉性を分析します。こうした不要なサウンドも打ち消されます。",
FitPro_Modes_ANCUseCases_Subtitle: "各ユースケースをクリック／タップして、詳しく確認してください。",
FitPro_Modes_ANCUseCases_Commute: "通勤",
FitPro_Modes_ANCUseCases_Commute_Subtitle: "街中の喧騒を気にすることなく通勤できます。",
FitPro_Modes_ANCUseCases_Exercise: "エクササイズ",
FitPro_Modes_ANCUseCases_Exercise_Subtitle: "何にも邪魔されることなく、ワークアウトに没頭できます。",
FitPro_Modes_ANCUseCases_Travel: "旅行",
FitPro_Modes_ANCUseCases_Travel_Subtitle: "うるさいエンジン音や乗客の声を遮断するので、フライトをもっと楽しめます。",
FitPro_Modes_ANCUseCases_Meditate: "瞑想",
FitPro_Modes_ANCUseCases_Meditate_Subtitle: "不要な音を遮断して呼吸に集中し、心を穏やかにしましょう。",
FitPro_Modes_ChapterText2: "<em>外部音取り込みモード</em>で周囲の状況に気を配りましょう",
FitPro_Modes_TMAware_Title: "周囲の音を聞く",
FitPro_Modes_TMAware_Subtitle: "外部音取り込みモードでは、周囲の音と再生している音楽を一緒に聴くことができます。",
FitPro_Modes_TMExternalMic_Title: "周囲の音を捉える外部マイク",
FitPro_Modes_TMExternalMic_Subtitle: "外部マイクが周辺環境の音を捉えるので、自然で開放的な音楽を楽しむことができます。 ",
FitPro_Modes_TMUseCases_Subtitle: "各ユースケースをクリック／タップして、詳しく確認してください。",
FitPro_Modes_TMUseCases_Run: "ランニング",
FitPro_Modes_TMUseCases_Run_Subtitle: "音楽を聴きながらでも、周囲の状況を把握し、ペースを維持できます。",
FitPro_Modes_TMUseCases_Travel: "旅行",
FitPro_Modes_TMUseCases_Travel_Subtitle: "空港のゲートや電車のプラットフォームで重要なアナウンスを聞き逃すことがありません。",
FitPro_Modes_TMUseCases_Walk: "散歩",
FitPro_Modes_TMUseCases_Walk_Subtitle: "交通状況や交差点の状況が把握できます。",
FitPro_Modes_TMUseCases_StandBy: "スタンバイ",
FitPro_Modes_TMUseCases_StandBy_Subtitle: "騒々しい喫茶店で音楽を聴いているときや、友達に会うのを待っているときでも、名前を呼ばれたらはっきりと聞こえます。",
FitPro_Modes_ChapterText3: "<em>アダプティブイコライゼーション</em>は、各自のフィット感に合わせてチューニングします。",
FitPro_Modes_AdaptiveEQ_Title: "あなたに合わせて調整",
FitPro_Modes_AdaptiveEQ_Subtitle: "アクティブノイズキャンセリングと外部音取り込みモードがオフになっている場合、アダプティブイコライゼーションがマイクを使用して、ユーザーの耳の形に合わせて自動的にサウンドを調整します。",
FitPro_Modes_Summary_Title: "音楽に集中する、外部の音を聞く",
FitPro_Modes_Summary1_Toggle: "アクティブノイズキャンセリング、外部音取り込みモード、アダプティブイコライゼーションの3つの異なるリスニングモードの中から、ニーズに合うモードを簡単に選択できます。",
FitPro_Modes_Summary2_ANCExtermalMic: "アクティブノイズキャンセリングでは、最初に外向きマイクが外部の音を検出します。",
FitPro_Modes_Summary3_ANCAntiNoise: "Beats Fit Proは、アンチノイズを生成して外部の音を打ち消します。",
FitPro_Modes_Summary4_ANCInwardMic: "内向きマイクが余分なサウンドを検知して、装着のずれや密閉性を分析します。",
FitPro_Modes_Summary5_ANCUseCases: "通勤中やワークアウト中、旅行中や瞑想時などにANCをオンにすれば、集中力をキープできます。",
FitPro_Modes_Summary6_TMAware: "外部音取り込みモードにより周囲の状況を把握できます。",
FitPro_Modes_Summary7_TMExternalMic: "外部マイクが周囲の音を捉えます。",
FitPro_Modes_Summary8_TMUseCases: "ランニング中、旅行中、歩きながらでも何かを待っているときでも、周囲の音を聞くことができます。",
FitPro_Modes_Summary9_AdaptiveEQ: "アダプティブイコライゼーションは、マイクを使ってユーザーの耳の形に合わせて自動的にサウンドを調整します。",
FitPro_Controls_Title: "コントロール",
FitPro_Controls_Intro_VOSubtitle: "ボタンを押すだけでイヤーバッドを操作できます。",
FitPro_Controls_ChapterText1: "便利な<em>オンイヤーボタン</em>は簡単に使えます。",
FitPro_Controls_PhysicalButton_Title: "1回押して再生",
FitPro_Controls_PhysicalButton_Subtitle: "物理ボタンで、サウンドをコントロールできます。",
FitPro_Controls_BothSides_Title: "イヤーバッド片方での使用が可能",
FitPro_Controls_BothSides_Subtitle: "どちらのイヤーバッドのボタンでも音楽を操作できます。",
FitPro_Controls_ChapterText2: "いつでもどこでも<em>音楽と再生モード</em>の操作や切り替えができます。",
FitPro_Controls_PressHold_Title: "シームレスな再生モードの切り替え",
FitPro_Controls_PressHold_Subtitle: "ボタンを長押しすると再生モードが切り替わります。カスタマイズしてSiriの起動ボタンにすることもできます。*",
FitPro_Controls_PressHold_Disclaimer: "* 長押しで動作する機能をカスタマイズするには、iOS 14.6以降を搭載したiPhone、または最新バージョンのBeatsアプリがインストールされているAndroidデバイスが必要です。言語または地域によってはSiriを利用できない場合や、地域によって機能が異なる場合があります。インターネット接続が必要です。携帯電話データ通信料がかかる場合があります。",
FitPro_Controls_PressHold_Label1: "ノイズキャンセリングモード",
FitPro_Controls_PressHold_Label2: "外部音取り込みモード",
FitPro_Controls_Playback_Title: "オンイヤーコントロール",
FitPro_Controls_Playback_Subtitle: "オンイヤーコントロールで、通話や音楽を操作できます。",
FitPro_Modes_Playback_MusicLabel: "Now Playing",
FitPro_Modes_Playback_SongTitle1: "5 Seconds of Summer",
FitPro_Modes_Playback_SongArtist1: "Best Years",
FitPro_Modes_Playback_SongTitle2: "Boyfriend",
FitPro_Modes_Playback_SongArtist2: "Selena Gomez",
FitPro_Modes_Playback_SongTitle3: "Lovesick",
FitPro_Modes_Playback_SongArtist3: "Trevor Daniel",
FitPro_Modes_Playback_SongTitle4: " Mood",
FitPro_Modes_Playback_SongArtist4: "24kGoldn – ft. iann dior",
FitPro_Modes_Playback_SongTitle5: "What If I Told You That I Love You",
FitPro_Modes_Playback_SongArtist5: "Ali Gatie",
FitPro_Controls_Playback_Instruction: "ボタンを押すかタップして、各コマンドを実行します。",
FitPro_Controls_Playback_Play1: "ボタンを1回押すと音楽の一時停止や通話への応答",
FitPro_Controls_Playback_Play2: "ボタンを1回押すと音楽の再生や通話の終了",
FitPro_Controls_Playback_Skip: "ボタンを2回押すとトラックをスキップ",
FitPro_Controls_Playback_Previous: "ボタンを3回押すと前のトラックを再生",
FitPro_Controls_Playback_ScanFwd: "ボタンを2回押し、2回目を長押しすると早送り",
FitPro_Controls_Playback_ScanBck: "ボタンを3回押し、3回目を長押しすると巻き戻し",
FitPro_Controls_Customize_Title: "オンイヤーボタンのカスタマイズ",
FitPro_Controls_Customize_Subtitle: "iOS用またはAndroid用Beatsアプリで左右のイヤフォンの「長押し」コントロール機能をカスタマイズして、アクティブノイズキャンセリング、外部音取り込みモード、アダプティブイコライゼーション、ボリュームコントロール、または音声アシスタントを実行できるようにします。*",
FitPro_Controls_Customize_Disclaimer: "* BeatsアプリにはAndroid 8.0以降が必要です。アプリはGoogle Playストアやbeatsbydre.comで入手できます。",
FitPro_Controls_Android_Title: "Androidにも対応",
FitPro_Controls_Android_Subtitle: "Android用Beatsアプリで、ワンタッチペアリング、コントロールのカスタマイズ、装着状態テストなどの機能を利用できます。*",
FitPro_Controls_Android_Disclaimer: "* BeatsアプリにはAndroid 8.0以降が必要です。アプリはGoogle Playストアやbeatsbydre.comで入手できます。",
FitPro_Controls_Summary_Title: "指先だけで、思いのままに",
FitPro_Controls_Summary1_PhysicalButton: "物理ボタンで、サウンドをコントロールできます。",
FitPro_Controls_Summary2_BothSides: "どちらのイヤーバッドのボタンでも音楽を操作できます。",
FitPro_Controls_Summary3_PressHold: "ボタンを長押しして、再生モードを切り替えます。",
FitPro_Controls_Summary4_Playback: "オンイヤーコントロールで、通話や音楽を操作できます。",
FitPro_Controls_Summary5_Customize: "左右のイヤフォンの「長押し」コントロール機能をカスタマイズします。",
FitPro_Controls_Summary6_Android: "Android用のBeatsアプリを使用すると、さらに高度な機能を利用できます。",
FitPro_H1_Title: "Apple H1チップ",
FitPro_H1_Intro_VOSubtitle: "Beats Fit Proは、Apple H1チップを内蔵しています。 ",
FitPro_H1_AppleIntegration_Title: "Appleデバイスとのシームレスな統合",
FitPro_H1_AppleIntegration_Subtitle: "Beats Fit Proに搭載されたApple H1チップは、高度な機能とAppleデバイスとのシームレスな統合を可能にします。",
FitPro_H1_ChapterText1: "Beats Fit Proに搭載された<em>Apple H1チップ</em>で、便利な機能が利用できるようになります。",
FitPro_H1_Pairing_Title: "ワンタッチペアリング機能",
FitPro_H1_Pairing_Subtitle: "Beats Fit Proは、業界最高レベルのClass 1 Bluetooth®に対応しているので、箱から出してすぐにワンタッチでiOSデバイスに接続できます。*",
FitPro_H1_Pairing_Disclaimer: "* iOS 15.1以降が必要です。",
FitPro_H1_HeySiri_Title: "Hey Siri",
FitPro_H1_HeySiri_Subtitle: "「Hey Siri」と声をかけるだけで、音楽、通話、経路のチェックなどの操作が可能です。*",
FitPro_H1_HeySiri_Disclaimer: "* 言語または地域によってはSiriを利用できない場合や、地域によって機能が異なる場合があります。インターネット接続が必要です。携帯電話データ通信料がかかる場合があります。",
FitPro_H1_AutoSwitch_Title: "自動デバイス切り替え",
FitPro_H1_AutoSwitch_Subtitle: "Beats Fit Proの自動デバイス切り替えで、シームレスにデバイスを切り替えることができます。*",
FitPro_H1_AutoSwitch_Disclaimer: "* 自動デバイス切り替えにはmacOS 11.1、iOS 14.3、iPadOS 14.3、watchOS 7.2、またはtvOS 14.3以降が必要です。",
FitPro_H1_AudioSharing_Title: "オーディオ共有",
FitPro_H1_AudioSharing_Subtitle: "AirPodsやBeatsのヘッドフォンを持っている人とお気に入りの音楽をシェアして一緒に聴くことができます。*",
FitPro_H1_AudioSharing_Disclaimer: "* オーディオ共有は、BeatsのワイヤレスヘッドフォンとすべてのAirPodsに対応しています。対応するAppleのハードウェアとソフトウェアが必要です。",
FitPro_H1_FindMy_Title: "デバイスを探す",
FitPro_H1_FindMy_Subtitle: "「探す」機能を使用すると、どこにいてもBeats Fit Proを簡単に探すことができます。*",
FitPro_H1_FindMy_Disclaimer: "* 「探す」アプリでBeats製品を探すには、iOS 15.1以降を搭載したiPhoneまたはiPod touch、iPadOS 15.1以降を搭載したiPad、またはmacOS Monterey 12.0.1以降を搭載したMacが必要です。お客様は、お使いのApple IDでiCloudアカウントにサインインし「探す」を有効にする必要があります。",
FitPro_H1_Summary_Title: "Apple H1チップ搭載",
FitPro_H1_Summary1_Seamless: "Beats Fit Proに搭載されたApple H1チップは、Appleデバイスとのシームレスな統合を可能にします。",
FitPro_H1_Summary2_Pairing: "シンプルなワンタッチペアリングで、簡単にBluetooth®接続できます。",
FitPro_H1_Summary3_HeySiri: "「Hey Siri」という声だけでSiriを呼び出せます。",
FitPro_H1_Summary4_AutoSwitch: "Beats Fit Proの自動デバイス切り替えで、シームレスにデバイスを切り替えることができます。",
FitPro_H1_Summary5_AudioSharing: "AirPodsやBeatsのヘッドフォンを持っている人と一緒に同じ音楽を楽しめます。",
FitPro_H1_Summary6_FindMy: "「探す」機能を使えば、イヤーバッドを簡単に見つけることができます。",
FitPro_Design_Title: "デザイン",
FitPro_Design_Intro_VOSubtitle: "Beats Fit Proは、一日中利用できるように設計されています。 ",
FitPro_Design_ChapterText1: "<em>充電ケース</em>は、外出先でイヤーバッドを充電できるように設計されています。",
FitPro_Design_Pocket_Title: "ポケットにフィット",
FitPro_Design_Pocket_Subtitle: "持ち運びに便利な同色の充電ケースで、Beats Fit Proを持って出かけましょう。",
FitPro_Design_Size_Title: "50%以上の小型化",
FitPro_Design_Size_Subtitle: "Beats Fit Proのケースは、Powerbeats Proのケースよりも50%以上も小型化されています。",
FitPro_Design_Color_Title: "選べるカラー",
FitPro_Design_Color_Subtitle: "イヤーバッドと同色の充電ケースは4種類のカラーが用意されているので、自分のスタイルに合ったものを選べます。",
FitPro_Design_Color_Black: "Beatsブラック",
FitPro_Design_Color_White: "Beatsホワイト",
FitPro_Design_Color_Purple: "ストーンパープル",
FitPro_Design_Color_Gray: "セージグレー",
FitPro_Design_ChapterText2: "<em>Beats Fit Proイヤーバッド</em>は、どこにでも持ち運べるように設計されています。",
FitPro_Design_TrueWireless_Title: "ワイヤレスという自由",
FitPro_Design_TrueWireless_Subtitle: "Beats Fit Proは、一日中使い続けられる完全ワイヤレスイヤーバッドです。",
FitPro_Design_Independent_Title: "どちらか片方でも",
FitPro_Design_Independent_Subtitle: "イヤーバッドはどちらか片方だけでも接続することが可能です。片方のイヤフォンを装着しても、両方のイヤーバッドのボタンでサウンドをコントロールできます。",
FitPro_Design_Sweat_Title: "雨、汗、晴れでも",
FitPro_Design_Sweat_Subtitle: "Beats Fit ProのイヤーバッドはIPX4等級の耐汗耐水性能を備え、雨やタフなトレーニングに耐えることができます。*",
FitPro_Design_Sweat_Disclaimer: "* Beats Fit Proには耐汗耐水性能がありますが、水上または水中でのスポーツやエクササイズには対応していません。耐汗耐水性能は永続的に維持されるものではありません。充電ケースに耐汗耐水性能はありません。",
FitPro_Design_Summary_Title: "一日中利用できる設計",
FitPro_Design_Summary1_Pocket: "Beats Fit Pro付属のポケットサイズの充電ケースは外出時も持ち運びが簡単です。",
FitPro_Design_Summary2_Size: "Beats Fit Proのケースは、Powerbeats Proのケースよりも50%以上も小型化されています。",
FitPro_Design_Summary3_Color: "4種類のカラーからお好みに合わせてお選びいただけます。",
FitPro_Design_Summary4_TrueWireless: "Beats Fit Proは完全ワイヤレスのイヤーバッドです。",
FitPro_Design_Summary5_Independent: "イヤーバッドは片方だけでも使用可能です。",
FitPro_Design_Summary6_Sweat: "Beats Fit ProのイヤーバッドにはIPX4等級の耐汗耐水性能があります。",
FitPro_Battery_Title: "バッテリー",
FitPro_Battery_Intro_VOSubtitle: "繰り返し音楽を楽しめるバッテリー駆動時間。 ",
FitPro_Battery_6Hours_Title: "長時間の再生",
FitPro_Battery_6Hours_Subtitle: "各イヤフォンで最大6時間、お気に入りの音楽を楽しめます。*",
FitPro_Battery_6Hours_Disclaimer: "* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります。",
FitPro_Battery_18Hours_Title: "合計で最大24時間",
FitPro_Battery_18Hours_Subtitle: "充電ケースを使用することで、さらに18時間再生できます。*",
FitPro_Battery_18Hours_Disclaimer: "* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります。",
FitPro_Battery_FastFuel_Title: "途切れないパワー",
FitPro_Battery_FastFuel_Subtitle: "急速充電により、5分間の充電で1時間再生できます。*",
FitPro_Battery_FastFuel_Disclaimer: "* バッテリー駆動時間は各種設定、環境、使用方法、その他の多くの要素によって異なります。",
FitPro_Battery_USBC_Title: "USB-Cで充電",
FitPro_Battery_USBC_Subtitle: "AppleデバイスでもAndroidデバイスでも使えるUSB-Cの充電コネクタで、どこにいても充電できます。 ",
FitPro_Battery_WhatsInTheBox_Title: "同梱物",
FitPro_Battery_WhatsInTheBox_Subtitle: "Beats Fit Proには、ポケットサイズの充電ケース、ユニバーサルUSB-C充電コネクタ、3種類のサイズから選べるイヤーチップが同梱されています。",
FitPro_Battery_Summary_Title: "音楽を止めない",
FitPro_Battery_Summary1_6Hours: "イヤーバッド片方で6時間再生できます。",
FitPro_Battery_Summary2_18Hours: "充電ケースを使用することで、さらに18時間再生できます。",
FitPro_Battery_Summary3_FastFuel: "急速充電機能では、5分間の充電で1時間再生できます。",
FitPro_Battery_Summary4_USBC: "同梱のUSB-Cの充電コネクタで、どこにいても充電できます。",
FitPro_Battery_Summary5_WhatsInTheBox: "ポケットサイズの充電ケース、USB-C充電コネクタ、3種類のサイズから選べるイヤーチップが同梱されています。",
FitPro_Outro_ChapterText1: "Beats Fit Proは、柔軟でしっかりと耳にフィットするウィングチップ、3つの再生モード、コンパクトなポケットサイズのデザインで、<em>聴きたいものを自由に楽しめます。</em>",
FitPro_TestKnowledge: "Beats Fit Proの知識をテストします。",
FitPro_TakeQuiz: "クイズに挑戦する",
Global_ForgotEmail_NoUser:"この資格情報に関連付けられたユーザーレコードはありません。このユーザーは削除されている可能性があります。"

};

export default copy;
