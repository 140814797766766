import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {FramerTreeLayoutContext, motion} from 'framer-motion'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Context from "../../context/Context";
import clsx from 'clsx'
import './PasswordForget.scss'

const PasswordForgetPage = props => {
	const {onSubmitForgotDone} = props;
	return(
	
	<div>
		<PasswordForgetForm onSubmitForgotDone={onSubmitForgotDone} />
	</div>
)
	};

const PasswordForgetFormBase = (props) => {
	const { firebase } = props;
	const context = useContext(Context);
	const {language} = context;
	const [email, setEmail] = useState('');
	const [error, setError] = useState({message: null});
	const [success, setSuccess] = useState(false)
	useEffect(() => {
		console.log('useEffect error', error);
		
	}, [error]);
	const validateEmail = (email) => {
		var re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
	const onSubmitForget = (event) => {
		console.log("onSubmitForget------",event)
		firebase
			.doPasswordReset(email)
			.then(() => {
				console.log("then")

				setEmail('');
				setSuccess(true);
				// onSubmitForgotDone();
			})
			.catch((error) => {
				console.log("catch")

				console.log("error",error)
				setError(error);
			});
			console.log("error is",error)
		event.preventDefault();
	};

	const onChange = (event) => {
		console.log("errror",error)
		if (error.message) {
			setError({message: null})
		}
		setEmail(event.target.value);
	};
	const isInvalid = !validateEmail(email);
	return (
		<motion.div 
			className='sign-in-wrapper'
			animate={{opacity: 1, y: '-50%', x: '-50%'}}
			initial={{opacity: 0, y: '50%', x: '-50%'}}
			transition={{ delay: 0.25, stiffness: 1000 }}
		>
	
		<div className='email-wrapper'>
			<h1 className="title">{language['Global_Password_Reset_Headline']}</h1>
			<p className="forgot-description">{language['Global_Password_Reset_Subtitle']}</p>
			{ !success && 
				<React.Fragment>
					<div className="input-wrapper">
						<input 
							className={clsx(error.message ? 'error' : '')}
							name="email" 
							value={email} 
							onChange={onChange} 
							type="text" 
							placeholder={language['Global_Field_EnterEmail']} 
						/>
						<div className={`submit-login ${isInvalid ? 'inactive' : ''}`} onClick={!isInvalid ? onSubmitForget : null}>
							<img
								src={process.env.PUBLIC_URL + '/assets/images/chevron-right-white.svg'}
								className="chevron"
								alt="chevron right"
							/>
						</div>
					</div>
					<p className={clsx("error-message", error.message ? 'visible' : 'hidden')}>{`${language["Global_ForgotEmail_NoUser"]}`}</p>
				</React.Fragment>
			}
			{success &&
				<motion.div 
					className="success-wrapper"
					animate={{opacity: 1}}
					initial={{opacity: 0}}
				>
					<img alt="success" src={process.env.PUBLIC_URL + '/assets/images/check-mark-circle.svg'} />
					<p>{language['Global_Password_Reset_Confirm']}</p>
				</motion.div>
			}
			</div>
		</motion.div>
	);
};

const PasswordForgetLink = () => (
	<p>
		<Link to={ROUTES.PASSWORD_FORGET}>Global_Field_Password_Forgot?</Link>
	</p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
