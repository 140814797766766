const copy = {
	Global_UI_Next: 'SIGUIENTE',
	Global_UI_Begin: 'COMENZAR',
	Global_UI_Back: 'ATRÁS',
	Global_UI_Settings: 'Configuración',
	Global_UI_Signout: 'Cerrar sesión',
	Global_UI_NextChapter: 'SIGUIENTE CAPÍTULO',
	Global_UI_PercentageComplete: '<<number>>%  COMPLETO',
	Global_UI_Replay: 'VOLVER A REPRODUCIR',
	Global_Field_Location: 'LOCALIZACIÓN',
	Global_Languages_Select: 'ELIGE TU IDIOMA:',
	Global_Field_EnterEmail: 'Ingresar correo electrónico',
	Global_Field_CreatePassword: 'Crear contraseña',
	Global_Field_Password: 'Contraseña',
	Global_Field_Password_Forgot: 'OLVIDÉ MI CONTRASEÑA',
	Global_Password_Subtitle:
		'LA CONTRASEÑA DEBE TENER MÁS DE 8 CARACTERES, MAYÚSCULAS Y MINÚSCULAS, NÚMEROS Y SÍMBOLOS.',
	Global_Password_Change: 'CAMBIAR',
	Global_Password_Reset_Headline: 'Restablecer contraseña',
	Global_Password_Reset_Subtitle:
		'Ingresa tu correo electrónico para recibir instrucciones sobre cómo restablecer tu contraseña.',
	Global_Password_Reset_Confirm: '¡Enviado! Revisa tu correo.',
	Global_Password_ChangeConfirm: 'Contraseña actualizada',
	Global_Password_Show: 'MOSTRAR',
	Global_Password_Hide: 'OCULTAR',
	Global_Details_Title: 'Unos datos más',
	Global_Details_Field_FirstName: 'Nombre',
	Global_Details_Field_LastName: 'Apellidos',
	Global_Details_Field_EmployeeID: 'ID de empleado (opcional)',
	Global_Details_Field_BusinessRole: 'Función empresarial',
	Global_Details_Field_BusinessRole1: 'Gerente',
	Global_Details_Field_BusinessRole2: 'Vendedor',
	Global_Details_Field_BusinessRole3: 'Instructor',
	Global_Details_Field_CompanyName: 'Nombre de la empresa:',
	Global_Details_Field_Country: 'País',
	Global_Field_Password_Error: 'La contraseña que ingresaste es incorrecta. Inténtalo de nuevo.',
	Global_Field_Email_Error: 'Ingresaste un correo electrónico no válido. Inténtalo de nuevo.',
	Global_Field_Details_Error: 'Completa todos los campos obligatorios.',
	Global_Greeting: 'Hola, <<name>>:',
	Global_CompletionMessage: 'Completaste el <<number>>%.',
	Global_Chapter: 'CAP. <<number>>',
	Global_Chapter_Subtitle: 'CAP. <<number>> DE <<total>>',
	Global_Intro: 'Introducción',
	Global_ChapterTitle_Sound: 'Sonido',
	Global_ChapterTitle_ANC: 'ANC',
	Global_ChapterTitle_Transparency: 'Modo ambiente',
	Global_ChapterTitle_Design: 'Diseño',
	Global_ChapterTitle_Compatibility: 'Compatibilidad',
	Global_ChapterTitle_Controls: 'Controles',
	Global_ChapterTitle_Battery: 'Batería',
	Global_Menu: 'Menú',
	Intro_Welcome_Label: 'EXPERIENCIA',
	Intro_Welcome_ChapterText:
		'Te damos la bienvenida a la experiencia de los Beats Studio Buds. Toca o haz clic a través de las pantallas y descubre contenido increíble.',
	CR_TRNR_Intro_Sorry:
		'Lo sentimos. Algo salió mal. Comunícate con tu supervisor para obtener un nuevo enlace de acceso.',
	Intro_Intro_Subtitle:
		'Presentamos los Beats Studio Buds. Audífonos in-ear inalámbricos de verdad con cancelación de ruido y sonido de calidad de estudio. Toca o haz clic a través de las pantallas para explorar.',
	Sound_Intro_Title: 'Sonido',
	Sound_Intro_VOSubtitle: 'La ingeniería avanzada ofrece sonido potente y equilibrado.',
	Sound_ChapterText1:
		'Una excelente calidad de sonido se logra a partir de un <em>ajuste personalizado y un cómodo aislamiento acústico.</em>',
	Sound_FindYourFit_Title: 'Encuentra tu ajuste. Siente la música.',
	Sound_FindYourFit_Subtitle:
		'Los tres tamaños de almohadillas de silicona ofrecen un ajuste personalizado y un aislamiento acústico para que el sonido te envuelva.',
	Sound_FindYourFit_Medium: 'MEDIO (PREDETERMINADO)',
	Sound_FindYourFit_Small: 'PEQUEÑAS',
	Sound_FindYourFit_Large: 'GRANDES',
	Sound_ChapterText2:
		'Los Beats Studio Buds están diseñados para transmitir <em>sonido potente y equilibrado</em> en un diseño compacto a través de una <em>plataforma acústica completamente personalizable.</em>',
	Sound_DrivenByPremiumSound_Title: 'Logros del sonido de excelencia',
	Sound_DrivenByPremiumSound_Subtitle:
		'La plataforma acústica personalizada con un controlador de diafragma doble patentado produce sonido claro y equilibrado.',
	Sound_MoreMusicLessDistortion_Title: 'Sonido claro. Poca distorsión.',
	Sound_MoreMusicLessDistortion_Subtitle:
		'El material flexible del diafragma del controlador produce una distorsión vibratoria baja, lo cual transmite sonido claro y preciso a través de todo el espectro, especialmente en el rango de tonos graves.',
	Sound_MoreMusicLessDistortion_Diaphragm: 'DIAFRAGMA',
	Sound_FineTunedAudio_Title: 'Audio refinado',
	Sound_FineTunedAudio_Subtitle:
		'Un procesador digital con corrección de reproducción responde en tiempo real para garantizar que los sonidos externos no afecten la claridad o la emoción de la música.',
	Sound_ChapterText3:
		'Las llamadas telefónicas se escuchan con claridad gracias al <em>rendimiento de llamadas de alta calidad y la mayor claridad de la voz.</em> ',
	Sound_SpeakingOfSound_Title: 'Rendimiento de llamadas de alta calidad ',
	Sound_SpeakingOfSound_Subtitle:
		'Un puerto especialmente diseñado aísla el micrófono del ruido del viento, mientras los micrófonos duales con conformación de haces detectan la voz para que se escuche con mayor claridad.',
	Sound_Summary_Title: 'Diseñados para que la música se escuche como la ideó el artista',
	Sound_Summary1_ThreeEartip:
		'Las tres opciones de almohadillas ofrecen un ajuste personalizado para que el sonido sea espectacular.',
	Sound_Summary2_CustomAcoustic:
		'La plataforma acústica personalizada con un controlador de diafragma doble patentado produce sonido claro y equilibrado.',
	Sound_Summary3_FlexibleDriverDiaphragm: 'El diafragma flexible del controlador produce sonido claro y preciso.',
	Sound_Summary4_FinetunedAudio: 'El audio refinado preserva la claridad y la emoción de la música.',
	Sound_Summary5_SpeakingOfSound:
		'Los micrófonos duales con conformación de haces detectan la voz para que el rendimiento de las llamadas sea de alta calidad.',
	ANC_Intro_TITLE: 'Cancelación de ruido activa ',
	ANC_Intro_VOSubtitle: 'Concéntrate y bloquea las distracciones con la cancelación de ruido activa.',
	ANC_ChapterText1:
		'<em>El cómodo aislamiento acústico</em> bloquea el ruido externo para lograr una experiencia auditiva realmente envolvente.',
	ANC_Seal_Title: 'Aislamiento acústico',
	ANC_Seal_Subtitle:
		'Gracias a los tres tamaños de almohadillas, puedes elegir un ajuste cómodo para crear un aislamiento acústico que bloquee el ruido no deseado.',
	ANC_ChapterText2:
		'La ANC con tecnología adaptativa <em>se ajusta a lo que te rodea</em>,mientras la corrección de reproducción<em>garantiza la claridad y la emoción de la música.</em>',
	ANC_ANCThatAdapts_Title: 'Una ANC que se adapta',
	ANC_ANCThatAdapts_Subtitle:
		'Un filtro muy sensible se ajusta de forma dinámica para filtrar el ruido no deseado y optimizar la cancelación de ruido en tiempo real.',
	ANC_ANCThatAdapts_AdaptiveANC: 'ANC ADAPTATIVA',
	ANC_Playback_Title: 'Corrección de reproducción',
	ANC_Playback_Subtitle:
		'Cuando se activa la cancelación de ruido, la corrección de reproducción ajusta ese archivo de audio al original 48,000 veces por segundo, lo cual garantiza que la ANC nunca interfiera con la música.',
	ANC_Playback_ANCProcess: 'PROCESO DE LA ANC',
	ANC_Playback_PlaybackCorrection: 'CORRECCIÓN DE REPRODUCCIÓN',
	ANC_UseCases_Subtitle: 'Toca o haz clic en cada caso de uso para obtener más información. ',
	ANC_UseCases_Studying: 'Estudio',
	ANC_UseCases_Studying_Subtitle: 'Bloquea las distracciones para no perder la concentración en tu trabajo',
	ANC_UseCases_WorkingOut: 'Entrenamiento',
	ANC_UseCases_WorkingOut_Subtitle: 'Enfócate por completo en tu entrenamiento sin ningún tipo de distracción',
	ANC_UseCases_Airplane: 'Avión',
	ANC_UseCases_Airplane_Subtitle:
		'Silencia los sonidos de los pasajeros o de una turbina para disfrutar más del vuelo',
	ANC_UseCases_Commute: 'Trayectos',
	ANC_UseCases_Commute_Subtitle: 'Viaja hacia y desde el trabajo sin escuchar tanto el ruido de fondo de la ciudad',
	ANC_Summary_Title: 'Concéntrate con la ANC.',
	ANC_Summary1_ImmerseSound: 'El sonido envolvente comienza con un cómodo aislamiento acústico.',
	ANC_Summary2_AdaptiveTechnology: 'La tecnología adaptativa se ajusta continuamente a lo que te rodea.',
	ANC_Summary3_PlaybackCorrection: 'La corrección de reproducción garantiza la claridad del sonido.',
	ANC_Summary4_UseCases:
		'Usa la ANC para concentrarte mientras estudias o entrenas, para un vuelo o para ir al trabajo o volver.',
	Transparency_Intro_Title: 'MODO AMBIENTE',
	Transparency_Intro_VOSubtitle: 'Escucha el mundo con el modo ambiente.',
	Transparency_ChapterText1: 'Cambia al modo ambiente cuando necesites <em>escuchar los sonidos que te rodean.</em>',
	Transparency_TransparencyMode_Title: 'Modo ambiente ',
	Transparency_TransparencyMode_Subtitle:
		'El modo ambiente vuelve a combinar los sonidos a tu alrededor con la música para que la experiencia de audio sea natural y “abierta”.',
	Transparency_TransparencyMode_PressHold: 'MANTÉN PRESIONADO',
	Transparency_ChapterText2: '<em>Los micrófonos externos</em> permiten escuchar el ruido externo.',
	Transparency_FeedForwardMic_Title: 'Micrófonos de alimentación',
	Transparency_FeedForwardMic_Subtitle:
		'El micrófono de alimentación en cada audífono detecta los ruidos externos del entorno.',
	Transparency_FeedForwardMic_FeedForwardMic: 'MICRÓFONO DE ALIMENTACIÓN',
	Transparency_UseCases_Subtitle: 'Toca o haz clic en cada caso de uso para obtener más información. ',
	Transparency_UseCases_Office: 'Oficina',
	Transparency_UseCases_Office_Subtitle:
		'No dejes de colaborar en el trabajo y escucha a tus colegas mientras escuchas música.',
	Transparency_UseCases_Travel: 'Viajes',
	Transparency_UseCases_Travel_Subtitle:
		'Escucha los anuncios importantes en la puerta de embarque de un aeropuerto o la plataforma de un tren.',
	Transparency_UseCases_Street: 'Calle',
	Transparency_UseCases_Street_Subtitle: 'Presta atención al tráfico a tu alrededor y a los cruces peligrosos.',
	Transparency_Summary_Title: 'Escucha el mundo con el modo ambiente.',
	Transparency_Summary1_MixTheSounds: 'Combina los sonidos que te rodean con tu música.',
	Transparency_Summary2_FeedForward: 'Los micrófonos de alimentación detectan los ruidos externos del entorno.',
	Transparency_Summary3_HearYourSurroundings:
		'Presta atención a lo que te rodea en la oficina, mientras viajas o en la calle.',
	Design_Intro_Title: 'Diseño',
	Design_Intro_VOSubtitle: 'Los Beats Studio Buds están diseñados para alcanzar la libertad inalámbrica de verdad.',
	Design_ChapterText1: 'El diseño inalámbrico y compacto <em>se ajusta a tu vida a la perfección.</em>',
	Design_WirelessFreedom_Title: 'Libertad inalámbrica ',
	Design_WirelessFreedom_Subtitle: 'Audífonos inalámbricos de verdad que no te limitan. ',
	Design_PocketSizedPortability_Titles: 'Portabilidad de bolsillo',
	Design_PocketSizedPortability_Subtitles:
		'El estuche de carga liviano y delgado ofrece una mayor portabilidad y cabe perfectamente en tu bolsillo.',
	Design_WaterAndSweatResistant_Title: 'Audífonos resistentes al agua y al sudor*  ',
	Design_WaterAndSweatResistant_Subtitle:
		'Los audífonos tienen una resistencia al agua y al sudor con clasificación IPX-4 para sortear cualquier obstáculo.',
	Design_WaterAndSweatResistant_Disclaimer:
		'* Los Beats Studio Buds son resistentes al agua y al sudor para hacer ejercicio o practicar deportes no acuáticos.',
	Design_ChapterText2:
		'Los Beats Studio Buds se probaron con un amplio público para garantizar un <em>ajuste versátil y cómodo.</em>',
	Design_Comfort_Title: 'Diseñados para ofrecer comodidad',
	Design_Comfort_Subtitle:
		'Los audífonos suaves de silicona están diseñados en función de la anatomía del oído para brindar comodidad, estabilidad y la tranquilidad de que no se moverán.',
	Design_Comfort_Tragus: 'TRAGO',
	Design_Comfort_Antitragus: 'ANTITRAGO',
	Design_SizeMatters_Title: 'Diseño compacto',
	Design_SizeMatters_Subtitle:
		'El diseño elegante y compacto es significativamente más liviano y pequeño que el de la mayoría de los audífonos inalámbricos de hoy en día.',
	Design_SizeMatters_BeatsStudio: 'BEATS STUDIO BUDS',
	Design_SizeMatters_BeatsStudio_10_1G: '10.1 G',
	Design_SizeMatters_Bose: 'AUDÍFONOS BOSE QC',
	Design_SizeMatters_Bose_18_7G: '18.7 G',
	Design_SizeMatters_Sony: 'Sony WF-1000XM3',
	Design_SizeMatters_Sony_16_5G: '16.5 G',
	Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
	Design_SizeMatters_Samsung_12_5G: '12.5 G',
	Design_ChapterText3:
		'Los audífonos permanecen <em>seguros y protegidos</em> en un estuche de carga conveniente, diseñado para seguirte el ritmo durante todo el día.',
	Design_PerfectMatch_Title: 'Ubicación magnética',
	Design_PerfectMatch_Subtitle:
		'Los imanes que se encuentran en el interior del estuche de carga están orientados de modo tal que los audífonos siempre se ubiquen en el mismo lugar.',
	Design_Contact_Title: 'Contacto preciso',
	Design_Contact_Subtitle:
		'Los pines con resorte dentro del estuche usan un resorte de hoja resistente para que el contacto entre los audífonos y el estuche sea preciso, lo cual hace que los audífonos se ubiquen en su lugar para cargarse.',
	Design_Contact_PogoPin: 'PIN CON RESORTE',
	Design_FindYourMatch_Title: 'Encuentra tu estuche ',
	Design_FindYourMatch_Subtitle: 'Estuche de carga del mismo color',
	Design_FindYourMatch_White: 'BLANCO',
	Design_FindYourMatch_Black: 'NEGRO',
	Design_FindYourMatch_BeatsRed: 'BEATS ROJO',
	Design_SustainablePackaging_Title: 'Embalaje sustentable',
	Design_SustainablePackaging_Subtitle:
		'El 92 % del embalaje de los Beats Studio Buds está realizado con material de origen vegetal obtenido de una combinación entre fibras recicladas y bosques sustentables.',
	Design_Summary_Title: 'Diseñados para alcanzar la libertad inalámbrica de verdad',
	Design_Summary1_WirelessFreedom: 'Audífonos inalámbricos de verdad que no te limitan. ',
	Design_Summary2_PocketSizedPortability: 'Estuche de carga liviano que cabe fácilmente en tu bolsillo.',
	Design_Summary3_WaterAndSweatResistant: 'Clasificación IPX-4 para audífonos resistentes al agua y al sudor.',
	Design_Summary4_Comfort: 'Los audífonos elegantes y compactos brindan comodidad y estabilidad.',
	Design_Summary5_SizeMatters:
		'El diseño in-ear es significativamente más pequeño y liviano que el de la mayoría de los audífonos inalámbricos.',
	Design_Summary6_PerfectMatch:
		'Los imanes en el interior del estuche de carga garantizan que los audífonos siempre se ubiquen en el mismo lugar.',
	Design_Summary7_Contact: 'Los pines con resorte dentro del estuche ubican los audífonos en su lugar para cargarse.',
	Design_Summary8_FindYourMatch: 'El estuche de carga viene del mismo color, ya sea negro, blanco o Beats rojo.',
	Compatibility_Intro_Title: 'Compatibilidad',
	Compatibility_Intro_VOSubtitle: 'Los Beats Studio Buds son compatibles con dispositivos Apple y Android.',
	Compatibility_ChapterText1: 'Úsalos fácilmente con <em>dispositivos Apple y Android.</em>',
	Compatibility_OneTouch_Title: 'Enlace de un solo toque ',
	Compatibility_OneTouch_Subtitle:
		'Conecta fácilmente tu dispositivo Apple<sup>1</sup> o Android<sup>2</sup> a través de Bluetooth con el sencillo enlace de un solo toque. ',
	Compatibility_OneTouch_Disclaimer:
		'1 El enlace de un solo toque requiere iOS 14.6 o posterior. <br/>2 El enlace rápido requiere habilitar la ubicación. El acceso completo a las funciones requiere un dispositivo Android 6.0 o posterior con Google Play Services y una cuenta de Google. ',
	Compatibility_VoiceAssistant_Title: 'Asistente de voz ',
	Compatibility_VoiceAssistant_Subtitle:
		'Los Beats Studio Buds son compatibles con tu asistente de voz preferido, que se puede activar con la función personalizada para mantener presionado.',
	Compatibility_LocateDevice_Title: 'Ubica tu dispositivo ',
	Compatibility_LocateDevice_Subtitle:
		'Usa la función Encontrar para iOS o Encontrar mi dispositivo para Android y ubica tus audífonos fácilmente. ',
	Compatibility_USBCCharging_Title: 'Carga USB-C ',
	Compatibility_USBCCharging_Subtitle:
		'El conector de carga USB-C funciona con Apple y Android, de modo que puedes cargarlos donde quieras. ',
	Compatibility_ManageDevice_Title: 'Administra tu dispositivo ',
	Compatibility_ManageDevice_Subtitle:
		'Controla funciones como la personalización para mantener presionado, los modos de audio, cambiarle el nombre al producto y las actualizaciones de firmware desde tu dispositivo Apple o la app Beats para Android.',
	Compatibility_Summary_Title: 'Compatibilidad con dispositivos Apple y Android.',
	Compatibility_Summary1_OneTouch: 'Enlace de un solo toque para iOS y Android.',
	Compatibility_Summary2_VoiceAssistant:
		'Activa tu asistente de voz preferido para administrar las llamadas, la música y mucho más.',
	Compatibility_Summary3_LocateDevice: 'Encuentra fácilmente los audífonos desde tu dispositivo.',
	Compatibility_Summary4_USBCCharging: 'El conector de carga USB-C ofrece carga universal.',
	Compatibility_Summary5_ManageDevice: 'Administra tu dispositivo a través de iOS o la app Beats para Android.',
	Control_Intro_Title: 'Control',
	Control_Intro_VOSubtitle: 'Controla el sonido (y tu día) con los Beats Studio Buds.',
	Control_ChapterText1: 'Administra el sonido con <em>controles de fácil acceso.</em>',
	Control_OnEarControls_Title: 'Controles sobre la oreja ',
	Control_OnEarControls_Subtitle:
		'Los controles sobre la oreja te permiten administrar las llamadas y la música, alternar entre los modos de audio y activar el asistente de voz.<sup>*</sup>',
	Control_OnEarControls_Disclaimer:
		'* A fin de personalizar la función para mantener presionado, es necesario tener un iPhone con iOS 14.6 o posterior, o un dispositivo Android con la versión más reciente de la app Beats instalada.',
	Control_OnEarControls_Instruction: 'Toca o haz clic en cada control para continuar.',
	Control_OnEarControls_PlayPause: 'Presiona para reproducir/pausar y contestar/finalizar llamadas ',
	Control_OnEarControls_Skip: 'Presiona 2 veces para saltar una pista ',
	Control_OnEarControls_Previous: 'Presiona 3 veces para reproducir la pista anterior ',
	Control_OnEarControls_ScanForward: 'Presiona 2 veces y mantén pulsado para avanzar rápido ',
	Control_OnEarControls_ScanBackward: 'Presiona 3 veces y mantén pulsado para retroceder rápido ',
	Control_OnEarControls_ToggleModes:
		'Mantén presionado para alternar entre los modos de audio y desactivarlos o personaliza esta función para activar el asistente de voz.<sup>*</sup>',
	Control_PickASide_Title: 'Elige un audífono',
	Control_PickASide_Subtitle:
		'Usa iOS o la app Beats para Android a fin de personalizar la función para mantener presionado en cada audífono, ya sea que quieras activar ANC o el asistente de voz.<sup>*</sup>',
	Control_PickASide_Disclaimer:
		'* A fin de personalizar la función para mantener presionado, es necesario tener un iPhone con iOS 14.6 o posterior, o un dispositivo Android con la versión más reciente de la app Beats instalada.',
	Control_SayItWithSiri_Title: 'Dilo con Siri ',
	Control_SayItWithSiri_Subtitle:
		'El acceso manos libres a Siri te permite controlar la música, administrar llamadas, ajustar el volumen y más siempre que lo necesites.<sup>*</sup>',
	Control_SayItWithSiri_Disclaimer:
		'* Siri puede no estar disponible en todos los idiomas y áreas, y las funcionalidades pueden variar según el área. Se requiere acceso a Internet. Puede estar sujeto a cargos por uso de datos celulares. ',
	Control_SayItWithSiri_SiriCommand1: '“Oye Siri, reproduce mi lista de reproducción para estudiar”.',
	Control_SayItWithSiri_SiriCommand2: '“Oye Siri, muéstrame comercios dirigidos por mujeres que estén cerca”.',
	Control_SayItWithSiri_SiriCommand3: '“Oye Siri, establece un temporizador de 10 minutos”.',
	Control_Summary_Title: 'Administra el sonido con los controles sobre la oreja.',
	Control_Summary1_OnEarControls: 'Los controles sobre la oreja te permiten administrar las llamadas y la música.',
	Control_Summary2_PickASide: 'Personaliza la función para mantener presionado en el audífono izquierdo y derecho.',
	Control_Summary3_SayItWithSiri: 'Acceso manos libres a Siri.',
	Battery_Intro_Title: 'Batería',
	Battery_Intro_VOSubtitle: 'Carga tu día con una batería que dura todo el día.',
	Battery_ChapterText1: '<em>Batería para todo el día</em> que te mantiene en movimiento.',
	Battery_FastFuel_Title: 'Hasta 8 horas',
	Battery_FastFuel_Subtitle:
		'Con la potencia de un chip altamente eficiente, cada audífono proporciona hasta 8 horas de audio.<sup>*</sup>',
	Battery_FastFuel_Disclaimer:
		'* La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.',
	Battery_20Hours_Title: 'Hasta 24 horas',
	Battery_20Hours_Subtitle:
		'El estuche de carga de bolsillo proporciona dos cargas adicionales, lo que ofrece 24 horas de reproducción total.<sup>*</sup>',
	Battery_20Hours_Disclaimer:
		'* La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.',
	Battery_FuelYourDay_Title: 'Carga para tu día',
	Battery_FuelYourDay_Subtitle:
		'Si te queda poca batería, con 5 minutos de carga tendrás 1 hora de reproducción gracias a Fast Fuel.<sup>*</sup>',
	Battery_FuelYourDay_Disclaimer:
		'* La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.',
	Battery_UseCases_Subtitle: 'Toca o haz clic en cada caso de uso para obtener más información. ',
	Battery_UseCases_Listen: 'Escucha',
	Battery_UseCases_Listen_Subtitle: 'Escucha 12 semanas de tus programas de radio favoritos.<sup>*</sup>',
	Battery_UseCases_Listen_Disclaimer: '* En función de sesiones de reproducción de 2 horas',
	Battery_UseCases_Meditate: 'Meditación',
	Battery_UseCases_Meditate_Subtitle: 'Relájate con 32 meditaciones de sonido de lluvia.<sup>*</sup>',
	Battery_UseCases_Meditate_Disclaimer: '* En función de sesiones de 45 minutos',
	Battery_UseCases_Game: 'Juega',
	Battery_UseCases_Game_Subtitle: 'Participa en 24 sesiones de tu juego favorito.<sup>*</sup>',
	Battery_UseCases_Game_Disclaimer:
		'* En función de sesiones de juego de 1 hora para las que no es necesario usar micrófonos',
	Battery_UseCases_Commute: 'Trayectos',
	Battery_UseCases_Commute_Subtitle: 'Disfruta de 24 días de trayectos hacia y desde el trabajo.<sup>*</sup>',
	Battery_UseCases_Commute_Disclaimer:
		'* En función de un trayecto de 1 hora. La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.',
	Battery_WhatsInTheBox_Title: 'Contenido de la caja',
	Battery_WhatsInTheBox_Subtitle:
		'Los Beats Studio Buds vienen con un estuche de carga de bolsillo, un conector de carga USB-C universal y tres tamaños de almohadillas entre los que puedes elegir.',
	Batter_Summary_Title: 'Batería que dura todo el día.',
	Battery_Summary1_PowerYourDay: 'Los audífonos proporcionan hasta 8 horas de reproducción.',
	Battery_Summary2_UpTo24: 'Hasta 24 horas de reproducción total.',
	Battery_Summary3_FastFuel: 'La carga de Fast Fuel ofrece 1 hora de reproducción.',
	Battery_Summary4_UseCases: 'Escucha, medita y juega con una batería que dura todo el día.',
	Battery_Summary5_WhatsInTheBox:
		'En la caja se incluye un estuche de carga de bolsillo, un conector de carga USB-C y tres tamaños de almohadillas.',
	Menu_Sound_Module1: 'INTRODUCCIÓN A SONIDO',
	Menu_Sound_Module2: 'UNA EXCELENTE CALIDAD DE SONIDO SE LOGRA A PARTIR DE UN AJUSTE PERFECTO',
	Menu_Sound_Module3: 'TRES OPCIONES DE ALMOHADILLAS',
	Menu_Sound_Module4: 'SONIDO POTENTE Y EQUILIBRADO',
	Menu_Sound_Module5: 'PLATAFORMA ACÚSTICA PERSONALIZABLE',
	Menu_Sound_Module6: 'SONIDO CLARO. POCA DISTORSIÓN. ',
	Menu_Sound_Module7: 'AUDIO REFINADO',
	Menu_Sound_Module8: 'LLAMADAS TELEFÓNICAS ',
	Menu_Sound_Module9: 'RENDIMIENTO DE LLAMADAS DE ALTA CALIDAD',
	Menu_Sound_Module10: 'RESUMEN DE SONIDO',
	Menu_ANC_Module1: 'INTRODUCCIÓN A ANC',
	Menu_ANC_Module2: 'AISLAMIENTO ACÚSTICO',
	Menu_ANC_Module3: 'COMIENZA POR LA TECNOLOGÍA',
	Menu_ANC_Module4: 'DE AISLAMIENTO',
	Menu_ANC_Module5: 'ANC QUE SE ADAPTA',
	Menu_ANC_Module6: 'CORRECCIÓN DE REPRODUCCIÓN',
	Menu_ANC_Module7: 'CASOS DE USO DE ANC',
	Menu_ANC_Module8: 'RESUMEN DE ANC',
	Menu_Transparency_Module1: 'INTRODUCCIÓN A MODO AMBIENTE',
	Menu_Transparency_Module2: 'ESCUCHA LOS SONIDOS QUE TE RODEAN',
	Menu_Transparency_Module3: 'MODO AMBIENTE',
	Menu_Transparency_Module4: 'MICRÓFONOS EXTERNOS',
	Menu_Transparency_Module5: 'MICRÓFONOS DE ALIMENTACIÓN',
	Menu_Transparency_Module6: 'CASOS DE USO DEL MODO AMBIENTE',
	Menu_Transparency_Module7: 'RESUMEN DE MODO AMBIENTE',
	Menu_Design_Module1: 'INTRODUCCIÓN A DISEÑO',
	Menu_Design_Module2: 'DISEÑO INALÁMBRICO COMPACTO',
	Menu_Design_Module3: 'LIBERTAD INALÁMBRICA',
	Menu_Design_Module4: 'PORTABILIDAD DE BOLSILLO',
	Menu_Design_Module5: 'RESISTENCIA AL AGUA Y AL SUDOR',
	Menu_Design_Module6: 'AJUSTE CÓMODO Y VERSÁTIL',
	Menu_Design_Module7: 'DISEÑADOS PARA OFRECER COMODIDAD',
	Menu_Design_Module8: 'DISEÑO COMPACTO',
	Menu_Design_Module9: 'PRÁCTICO ESTUCHE DE CARGA',
	Menu_Design_Module10: 'UBICACIÓN MAGNÉTICA',
	Menu_Design_Module11: 'CONTACTO PRECISO',
	Menu_Design_Module12: 'COLORES',
	Menu_Design_Module13: 'RESUMEN DE DISEÑO',
	Menu_Compatibility_Module1: 'INTRODUCCIÓN A COMPATIBILIDAD',
	Menu_Compatibility_Module2: 'COMPATIBLES CON APPLE Y ANDROID',
	Menu_Compatibility_Module3: 'ENLACE DE UN SOLO TOQUE',
	Menu_Compatibility_Module4: 'ASISTENTE DE VOZ',
	Menu_Compatibility_Module5: 'UBICA TU DISPOSITIVO',
	Menu_Compatibility_Module6: 'CARGA USB-C ',
	Menu_Compatibility_Module7: 'ADMINISTRA TU DISPOSITIVO',
	Menu_Compatibility_Module8: 'RESUMEN DE COMPATIBILIDAD',
	Menu_Control_Module1: 'INTRODUCCIÓN A CONTROL',
	Menu_Control_Module2: 'ACCESO SENCILLO',
	Menu_Control_Module3: 'CONTROLES SOBRE LA OREJA',
	Menu_Control_Module4: 'ELIGE UN AUDÍFONO',
	Menu_Control_Module5: 'DILO CON SIRI',
	Menu_Control_Module6: 'RESUMEN DE CONTROL',
	Menu_Battery_Module1: 'INTRODUCCIÓN A BATERÍA',
	Menu_Battery_Module2: 'BATERÍA QUE DURA TODO EL DÍA',
	Menu_Battery_Module3: '8 HORAS DE AUDIO',
	Menu_Battery_Module4: '24 HORAS DE REPRODUCCIÓN TOTAL',
	Menu_Battery_Module5: 'FAST FUEL',
	Menu_Battery_Module6: 'CASOS DE USO DE LA BATERÍA',
	Menu_Battery_Module7: '¿QUÉ HAY EN LA CAJA?',
	Menu_Battery_Module8: 'RESUMEN DE BATERÍA',
	Outro_ChapterText1:
		'Con la cancelación de ruido activa, el modo ambiente, sonido de excelencia y un <em>diseño inalámbrico de bolsillo, los Beats Studio Buds se ajustan perfectamente a tu vida.</em>',
	IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
	Global_Country_ALBANIA: 'ALBANIA',
	Global_Country_ALGERIA: 'ARGELIA',
	Global_Country_ANDORRA: 'ANDORRA',
	Global_Country_ANGOLA: 'ANGOLA',
	Global_Country_ARGENTINA: 'ARGENTINA',
	Global_Country_ARMENIA: 'ARMENIA',
	Global_Country_ARUBA: 'ARUBA',
	Global_Country_AUSTRALIA: 'AUSTRALIA',
	Global_Country_AUSTRIA: 'AUSTRIA',
	Global_Country_AZERBAIJAN: 'AZERBAIYÁN',
	Global_Country_BAHAMAS: 'BAHAMAS',
	Global_Country_BAHRAIN: 'BAHRÉIN',
	Global_Country_BANGLADESH: 'BANGLADÉS',
	Global_Country_BARBADOS: 'BARBADOS',
	Global_Country_BELARUS: 'BIELORRUSIA',
	Global_Country_BELGIUM: 'BÉLGICA',
	Global_Country_BOLIVIA: 'BOLIVIA',
	Global_Country_BOSNIAHERZRGOVINA: 'BOSNIA Y HERZEGOVINA',
	Global_Country_BOTSWANA: 'BOTSUANA',
	Global_Country_BRAZIL: 'BRASIL',
	Global_Country_BRUNEI: 'BRUNÉI DARUSSALAM',
	Global_Country_BULGARIA: 'BULGARIA',
	Global_Country_CAMBODIA: 'CAMBOYA',
	Global_Country_CAMEROON: 'CAMERÚN',
	Global_Country_CANADA: 'CANADÁ',
	Global_Country_CAYMANISLANDS: 'ISLAS CAIMÁN',
	Global_Country_CHILE: 'CHILE',
	Global_Country_CHINA: 'CHINA (CONTINENTAL)',
	Global_Country_COLOMBIA: 'COLOMBIA',
	Global_Country_COOKISLANDS: 'ISLAS COOK',
	Global_Country_COSTARICA: 'COSTA RICA',
	Global_Country_CROATIA: 'CROACIA',
	Global_Country_CYPRUS: 'CHIPRE',
	Global_Country_CZECHREPUBLIC: 'REPÚBLICA CHECA',
	Global_Country_DENMARK: 'DINAMARCA',
	Global_Country_DOMINICANREPUBLIC: 'REPÚBLICA DOMINICANA',
	Global_Country_ECUADOR: 'ECUADOR',
	Global_Country_EGYPT: 'EGIPTO',
	Global_Country_ELSALVADOR: 'EL SALVADOR',
	Global_Country_ESTONIA: 'ESTONIA',
	Global_Country_FAROEISLANDS: 'ISLAS FEROE',
	Global_Country_FIJI: 'FIYI',
	Global_Country_FINLAND: 'FINLANDIA',
	Global_Country_FRANCE: 'FRANCIA',
	Global_Country_FRENCHGUIANA: 'GUAYANA FRANCESA',
	Global_Country_FRENCHPOLYNESIA: 'POLINESIA FRANCESA',
	Global_Country_GEORGIA: 'GEORGIA',
	Global_Country_GERMANY: 'ALEMANIA',
	Global_Country_GHANA: 'GHANA',
	Global_Country_GIBRALTAR: 'GIBRALTAR',
	Global_Country_GREECE: 'GRECIA',
	Global_Country_GUADELOUPE: 'GUADALUPE',
	Global_Country_GUAM: 'GUAM',
	Global_Country_GUATEMALA: 'GUATEMALA',
	Global_Country_GUERNSEY: 'GUERNESEY',
	Global_Country_GUINEA: 'GUINEA',
	Global_Country_GUYANA: 'GUYANA',
	Global_Country_HONDURAS: 'HONDURAS',
	Global_Country_HONGKONG: 'HONG KONG',
	Global_Country_HUNGARY: 'HUNGRÍA',
	Global_Country_ICELAND: 'ISLANDIA',
	Global_Country_INDIA: 'INDIA',
	Global_Country_INDONESIA: 'INDONESIA',
	Global_Country_IRAQ: 'IRAK',
	Global_Country_IRELAND: 'IRLANDA',
	Global_Country_ISLEOFMAN: 'ISLA DE MAN',
	Global_Country_ISRAEL: 'ISRAEL',
	Global_Country_ITALY: 'ITALIA',
	Global_Country_IVORYCOAST: 'COSTA DE MARFIL',
	Global_Country_JAPAN: 'JAPÓN',
	Global_Country_JERSEY: 'JERSEY',
	Global_Country_JORDAN: 'JORDANIA',
	Global_Country_KAZAKHSTAN: 'KAZAJISTÁN',
	Global_Country_KENYA: 'KENIA',
	Global_Country_KOREA: 'COREA, REPÚBLICA DE',
	Global_Country_KUWAIT: 'KUWAIT',
	Global_Country_KYRGYZSTAN: 'KIRGUISTÁN',
	Global_Country_LAOS: 'LAOS',
	Global_Country_LATVIA: 'LETONIA',
	Global_Country_LEBANON: 'LÍBANO',
	Global_Country_LESOTHO: 'LESOTO',
	Global_Country_LITHUANIA: 'LITUANIA',
	Global_Country_LUXEMBOURG: 'LUXEMBURGO',
	Global_Country_MACAU: 'MACAO',
	Global_Country_MACEDONIA: 'MACEDONIA',
	Global_Country_MADAGASCAR: 'MADAGASCAR',
	Global_Country_MALAWI: 'MALAUI',
	Global_Country_MALAYSIA: 'MALASIA',
	Global_Country_MALDIVES: 'MALDIVAS',
	Global_Country_MALTA: 'MALTA',
	Global_Country_MARTINIQUE: 'MARTINICA',
	Global_Country_MAURITIUS: 'MAURICIO',
	Global_Country_MAYOTTE: 'MAYOTTE',
	Global_Country_MEXICO: 'MÉXICO',
	Global_Country_MOLDOVA: 'MOLDAVIA',
	Global_Country_MONACO: 'MÓNACO',
	Global_Country_MONTENEGRO: 'MONTENEGRO',
	Global_Country_MOROCCO: 'MARRUECOS',
	Global_Country_MOZAMBIQUE: 'MOZAMBIQUE',
	Global_Country_MYANMAR: 'BIRMANIA',
	Global_Country_NAMIBIA: 'NAMIBIA',
	Global_Country_NEPAL: 'NEPAL',
	Global_Country_NETHERLANDS: 'PAÍSES BAJOS',
	Global_Country_NEWCALEDONIA: 'NUEVA CALEDONIA',
	Global_Country_NEWZEALAND: 'NUEVA ZELANDA',
	Global_Country_NICARAGUA: 'NICARAGUA',
	Global_Country_NIGERIA: 'NIGERIA',
	Global_Country_NORWAY: 'NORUEGA',
	Global_Country_OMAN: 'OMÁN',
	Global_Country_PAKISTAN: 'PAKISTÁN',
	Global_Country_PALESTINIANTERRITORY: 'TERRITORIOS PALESTINOS',
	Global_Country_PANAMA: 'PANAMÁ',
	Global_Country_PARAGUAY: 'PARAGUAY',
	Global_Country_PERU: 'PERÚ',
	Global_Country_PHILIPPINES: 'FILIPINAS',
	Global_Country_POLAND: 'POLONIA',
	Global_Country_PORTUGAL: 'PORTUGAL',
	Global_Country_PUERTORICO: 'PUERTO RICO',
	Global_Country_QATAR: 'CATAR',
	Global_Country_REUNION: 'REUNIÓN',
	Global_Country_ROMANIA: 'RUMANIA',
	Global_Country_RUSSIA: 'RUSIA',
	Global_Country_SAINTMARTIN: 'SAN MARTÍN',
	Global_Country_SANMARINO: 'SAN MARINO',
	Global_Country_SAUDIARABIA: 'ARABIA SAUDITA',
	Global_Country_SENEGAL: 'SENEGAL',
	Global_Country_SERBIA: 'SERBIA',
	Global_Country_SINGAPORE: 'SINGAPUR',
	Global_Country_SINTMAARTEN: 'SAN MARTÍN',
	Global_Country_SLOVAKIA: 'ESLOVAQUIA',
	Global_Country_SLOVENIA: 'ESLOVENIA',
	Global_Country_SOUTHAFRICA: 'SUDÁFRICA',
	Global_Country_SPAIN: 'ESPAÑA',
	Global_Country_SRILANKA: 'SRI LANKA',
	Global_Country_SWEDEN: 'SUECIA',
	Global_Country_SWITZERLAND: 'SUIZA',
	Global_Country_TAIWAN: 'TAIWÁN',
	Global_Country_TAJIKISTAN: 'TAYIKISTÁN',
	Global_Country_TANZANIA: 'TANZANIA',
	Global_Country_THAILAND: 'TAILANDIA',
	Global_Country_TRINIDADTOBAGO: 'TRINIDAD Y TOBAGO',
	Global_Country_TUNISIA: 'TÚNEZ',
	Global_Country_TURKEY: 'TURQUÍA',
	Global_Country_TURKMENISTAN: 'TURKMENISTÁN',
	Global_Country_UGANDA: 'UGANDA',
	Global_Country_UKRAINE: 'UCRANIA',
	Global_Country_UAE: 'EMIRATOS ÁRABES UNIDOS',
	Global_Country_UK: 'REINO UNIDO',
	Global_Country_US: 'ESTADOS UNIDOS',
	Global_Country_URUGUAY: 'URUGUAY',
	Global_Country_UZBEKISTAN: 'UZBEKISTÁN',
	Global_Country_VENEZUELA: 'VENEZUELA',
	Global_Country_VIETNAM: 'VIETNAM',
	Global_Country_ZAMBIA: 'ZAMBIA',
	Global_Country_ZIMBABWE: 'ZIMBABUE',
	Global_Menu_Confirm: 'Confirmar',
	Global_Menu_Help: '¿Necesitas ayuda? <u>Comunícate con nosotros</u>',
	Global_Menu_Restart: 'REINICIAR LA EXPERIENCIA',
	IntroOutro_TestYourKnowledge: 'Pon a prueba tus conocimientos sobre los Beats Studio Buds.',
	IntroOutro_CTA: 'Responder el cuestionario',
	Global_Menu_Language: 'IDIOMA',
	Global_Password_Rules_Header: 'La contraseña debe contener lo siguiente',
	Global_Password_Rule_Length: 'Un mínimo de 8 caracteres',
	Global_Password_Rule_Uppercase: '1 letra mayúscula',
	Global_Password_Rule_Lowercase: '1 letra minúscula',
	Global_Password_Rule_Number: '1 número',
	Global_Password_Rule_Special: 'Caracteres especiales (!@#$%^&*)',
	
	Global_ProductSelector_Headline: "Selecciona una experiencia Beats TRNR:",
Global_ProductSelector_Product1: "Beats Studio Buds",
Global_ProductSelector_Product2: "Beats Fit Pro",
Global_ProductSelector_CTA_1: "Comenzar",
Global_ProductSelector_CTA_2: "Continuar",
Global_ProductSelector_CTA_3: "VOLVER A COMENZAR",
Global_WelcomeInstructions_Headline: "Te damos la bienvenida a Beats TRNR, donde se forman los expertos en productos Beats.",
Global_WelcomeInstructions_Subhead: "Qué esperar:",
Global_WelcomeInstructions_Body1: "Cada experiencia de capacitación dura alrededor de 15 minutos.",
Global_WelcomeInstructions_Body2: "Cada experiencia de capacitación contiene audio. Asegúrate de tener el sonido activado.",
Global_WelcomeInstructions_Body3: "Debes terminar cada sección antes de pasar a la siguiente.",
Global_WelcomeInstructions_Body4: "Si lo necesitas, puedes hacer pausas y continuar en donde te hayas quedado.",
Intro_Welcome_ReturningUser: "¿Quieres retomar desde donde dejaste? Toca o haz clic para seguir explorando los audífonos Beats Fit Pro.",
FitPro_Menu_Chapter1: "CAP 1",
FitPro_Menu_Chapter2: "CAP 2",
FitPro_Menu_Chapter3: "CAP 3",
FitPro_Menu_Chapter4: "CAP 4",
FitPro_Menu_Chapter5: "CAP 5",
FitPro_Menu_Chapter6: "CAP 6",
FitPro_Menu_Chapter7: "CAP 7",
FitPro_Menu_ChapterTitle_Fit: "Ajuste",
FitPro_Menu_ChapterTitle_Sound: "Sonido",
FitPro_Menu_ChapterTitle_ListeningModes: "Modos de audio",
FitPro_Menu_ChapterTitle_Controls: "Controles",
FitPro_Menu_ChapterTitle_AppleH1Chip: "Chip H1 de Apple",
FitPro_Menu_ChapterTitle_Design: "Diseño",
FitPro_Menu_ChapterTitle_Battery: "Batería",
Global_Menu_Button_Restart: "Reiniciar la experiencia",
Global_Menu_Button_SelectProduct: "Selecciona otro producto",
Global_Menu_Button_Contact: "¿Necesitas ayuda? Comunícate con nosotros",
FitPro_Menu_ChapterTitle_Fit_1: "FLEXIBILIDAD Y AJUSTE",
FitPro_Menu_ChapterTitle_Fit_2: "PUNTAS DE AJUSTE PARA MAYOR COMODIDAD Y ESTABILIDAD",
FitPro_Menu_ChapterTitle_Fit_3: "COMODIDAD DURANTE TODO EL DÍA",
FitPro_Menu_ChapterTitle_Fit_4: "AJUSTE UNIVERSAL",
FitPro_Menu_ChapterTitle_Fit_5: "MATERIAL",
FitPro_Menu_ChapterTitle_Fit_6: "EXCELENTE AJUSTE",
FitPro_Menu_ChapterTitle_Fit_7: "3 ALMOHADILLAS",
FitPro_Menu_ChapterTitle_Fit_8: "PRUEBA DE ALMOHADILLAS",
FitPro_Menu_ChapterTitle_Fit_9: "AISLAMIENTO ACÚSTICO",
FitPro_Menu_ChapterTitle_Fit_10: "RESUMEN DE AJUSTE",
FitPro_Menu_ChapterTitle_Sound_1: "CONTROLADOR PERSONALIZADO",
FitPro_Menu_ChapterTitle_Sound_2: "TAMAÑO DEL CONTROLADOR",
FitPro_Menu_ChapterTitle_Sound_3: "CONTROLADOR CON PLIEGUES",
FitPro_Menu_ChapterTitle_Sound_4: "EXCELENTE FLUJO DE AIRE",
FitPro_Menu_ChapterTitle_Sound_5: "POCA DISTORSIÓN",
FitPro_Menu_ChapterTitle_Sound_6: "SONIDO EXCEPCIONAL",
FitPro_Menu_ChapterTitle_Sound_7: "AUDIO ESPACIAL",
FitPro_Menu_ChapterTitle_Sound_8: "CASOS DE USO DEL AUDIO ESPACIAL",
FitPro_Menu_ChapterTitle_Sound_9: "3 MICRÓFONOS",
FitPro_Menu_ChapterTitle_Sound_10: "RESUMEN DE SONIDO",
FitPro_Menu_ChapterTitle_Modes_1: "3 MODOS DE AUDIO",
FitPro_Menu_ChapterTitle_Modes_2: "CANCELACIÓN DE RUIDO ACTIVA",
FitPro_Menu_ChapterTitle_Modes_3: "MICRÓFONO ORIENTADO HACIA AFUERA",
FitPro_Menu_ChapterTitle_Modes_4: "ANTIRRUIDO",
FitPro_Menu_ChapterTitle_Modes_5: "MICRÓFONO ORIENTADO HACIA ADENTRO",
FitPro_Menu_ChapterTitle_Modes_6: "CASOS DE USO DE LA CANCELACIÓN DE RUIDO ACTIVA",
FitPro_Menu_ChapterTitle_Modes_7: "MODO AMBIENTE",
FitPro_Menu_ChapterTitle_Modes_8: "MANTENTE ALERTA",
FitPro_Menu_ChapterTitle_Modes_9: "MICRÓFONOS EXTERNOS",
FitPro_Menu_ChapterTitle_Modes_10: "CASOS DE USO DEL MODO AMBIENTE",
FitPro_Menu_ChapterTitle_Modes_11: "ECUALIZACIÓN ADAPTATIVA",
FitPro_Menu_ChapterTitle_Modes_12: "SE ADAPTA A TI",
FitPro_Menu_ChapterTitle_Modes_13: "RESUMEN DE MODOS DE AUDIO",
FitPro_Menu_ChapterTitle_Controls_1: "BOTONES SOBRE LA OREJA",
FitPro_Menu_ChapterTitle_Controls_2: "PRESIONA PARA REPRODUCIR",
FitPro_Menu_ChapterTitle_Controls_3: "CONECTIVIDAD INDEPENDIENTE EN CADA AUDÍFONO",
FitPro_Menu_ChapterTitle_Controls_4: "CONTROLA LOS MODOS DE AUDIO",
FitPro_Menu_ChapterTitle_Controls_5: "CAMBIA ENTRE LOS MODOS DE AUDIO",
FitPro_Menu_ChapterTitle_Controls_6: "CONTROLES SOBRE LA OREJA",
FitPro_Menu_ChapterTitle_Controls_7: "ELIGE UN AUDÍFONO",
FitPro_Menu_ChapterTitle_Controls_8: "COMPATIBILIDAD CON ANDROID",
FitPro_Menu_ChapterTitle_Controls_9: "RESUMEN DE CONTROLES",
FitPro_Menu_ChapterTitle_H1_1: "PERFECTA INTEGRACIÓN CON APPLE",
FitPro_Menu_ChapterTitle_H1_2: "CHIP H1 DE APPLE",
FitPro_Menu_ChapterTitle_H1_3: "ENLACE DE UN SOLO TOQUE",
FitPro_Menu_ChapterTitle_H1_4: "OYE SIRI",
FitPro_Menu_ChapterTitle_H1_5: "DETECCIÓN AUTOMÁTICA DE DISPOSITIVO",
FitPro_Menu_ChapterTitle_H1_6: "COMPARTIR AUDIO",
FitPro_Menu_ChapterTitle_H1_7: "ENCUENTRA TUS DISPOSITIVOS",
FitPro_Menu_ChapterTitle_H1_8: "RESUMEN DE CHIP H1 DE APPLE",
FitPro_Menu_ChapterTitle_Design_1: "ESTUCHE DE CARGA",
FitPro_Menu_ChapterTitle_Design_2: "CABEN EN TU BOLSILLO",
FitPro_Menu_ChapterTitle_Design_3: "50% MÁS PEQUEÑOS",
FitPro_Menu_ChapterTitle_Design_4: "LA COMBINACIÓN PERFECTA",
FitPro_Menu_ChapterTitle_Design_5: "DISEÑO PORTÁTIL",
FitPro_Menu_ChapterTitle_Design_6: "LIBERTAD INALÁMBRICA",
FitPro_Menu_ChapterTitle_Design_7: "CONECTIVIDAD INDEPENDIENTE EN CADA AUDÍFONO",
FitPro_Menu_ChapterTitle_Design_8: "LLUVIA, SUDOR O SOL",
FitPro_Menu_ChapterTitle_Design_9: "RESUMEN DE DISEÑO",
FitPro_Menu_ChapterTitle_Battery_1: "DISFRUTA HORAS DE AUDIO",
FitPro_Menu_ChapterTitle_Battery_2: "24 HORAS EN TOTAL",
FitPro_Menu_ChapterTitle_Battery_3: "FAST FUEL",
FitPro_Menu_ChapterTitle_Battery_4: "CARGA USB-C",
FitPro_Menu_ChapterTitle_Battery_5: "¿QUÉ HAY EN LA CAJA?",
FitPro_Menu_ChapterTitle_Battery_6: "RESUMEN DE BATERÍA",
FitPro_Intro_Title: 'Beats<br/>Fit Pro',
FitPro_Intro_Subtitle: "Presentamos los Beats Fit Pro. Audífonos inalámbricos de verdad con cancelación de ruido diseñados para acompañarte todo el día. Toca o haz clic a través de las pantallas para explorar.",
FitPro_Fit_Title: "Ajuste",
FitPro_Fit_Intro_VOSubtitle: "Los audífonos Beats Fit Pro están diseñados para darte comodidad y estabilidad durante todo el día.",
FitPro_Fit_ChapterText1: "Están equipados con puntas de ajuste cómodas y flexibles que <em>se adaptan a tu oído.</em>",
FitPro_Fit_FlexibleWingtip_Title: "Puntas de ajuste para mayor comodidad y estabilidad",
FitPro_Fit_FlexibleWingtip_Subtitle: "Una de las prioridades del equipo de ingeniería de los Beats Fit Pro era el perfeccionamiento de las puntas de ajuste. Los estudios dictaban que lograr ajustarse a la anatomía auditiva de cada persona era la clave para crear una punta de ajuste que se quedara fija en casi todos los tipos de oído.",
FitPro_Fit_AllDay_Title: "Comodidad durante todo el día",
FitPro_Fit_AllDay_Subtitle: "El diseño universal de las puntas de ajuste estuvo a prueba durante todo un año con usuarios activos de todo tipo, desde quienes salen a correr todos los días hasta atletas profesionales.",
FitPro_Fit_Universal_Title: "Una punta de ajuste para todo el mundo",
FitPro_Fit_Universal_Subtitle: "Las puntas de ajuste se adaptan a cada oído según el tamaño de este. Se doblan en distintos ángulos para brindar estabilidad a casi cualquier tamaño.",
FitPro_Fit_Materials_Title: "Estables, pero suaves",
FitPro_Fit_Materials_Subtitle: "Las puntas de ajuste se diseñaron mediante modelado digital usando las medidas de miles de oídos para obtener la perfecta combinación de materiales suaves y flexibles, con un núcleo rígido que proporciona comodidad y estabilidad para oídos de cualquier forma y tamaño.",
FitPro_Fit_ChapterText2: "Una excelente calidad de sonido se logra a partir de <em>un ajuste perfecto.</em> La almohadilla correcta ayuda a personalizar el ajuste.",
FitPro_Fit_EarTips_Title: "Encuentra tu tamaño ideal",
FitPro_Fit_EarTips_Subtitle: "Las tres opciones de almohadillas suaves de silicón ayudan a encontrar el tamaño perfecto para crear un ideal aislamiento acústico.",
FitPro_Fit_FitTest_Title: "Almohadillas a prueba",
FitPro_Fit_FitTest_Subtitle: "La configuración de prueba de almohadillas, tanto en Apple como en Android, ayuda a lograr el ajuste ideal para una experiencia envolvente mediante aislamiento acústico. La prueba de almohadillas analiza la filtración de sonido para confirmar que exista un aislamiento acústico óptimo.*",
FitPro_Fit_FitTest_Disclaimer: "*La prueba de almohadillas requiere iOS 15.1 o posterior o la app Beats para Android",
FitPro_Fit_Seal_Title: "Aislamiento total",
FitPro_Fit_Seal_Subtitle: "Con el ajuste adecuado se crea un aislamiento acústico personalizado que te da el mejor sonido. El aislamiento acústico ayuda a que la cancelación de ruido activa y el modo ambiente funcionen a la perfección.",
FitPro_Fit_Summary_Title: "Diseñados para darte comodidad y estabilidad",
FitPro_Fit_Summary1_FlexibleWingtip: "Una de las prioridades del equipo de ingeniería de los Beats Fit Pro era el perfeccionamiento de las puntas de ajuste.  ",
FitPro_Fit_Summary2_AllDay: "El diseño universal de las puntas de ajuste se sometió a prueba con atletas de todo tipo.",
FitPro_Fit_Summary3_Universal: "Las puntas de ajuste se adaptan a cada oído según el tamaño de éste.  ",
FitPro_Fit_Summary4_Materials: "El material de las puntas de ajuste es suave, pero rígido.",
FitPro_Fit_Summary5_EarTips: "Las tres opciones de almohadillas suaves de silicón ayudan a encontrar el tamaño perfecto.",
FitPro_Fit_Summary6_FitTest: "La prueba de almohadillas ayuda a encontrar un ajuste que genere aislamiento acústico para obtener el mejor sonido.",
FitPro_Fit_Summary7_Seal: "El aislamiento acústico hace que la música suene mejor.",
FitPro_Fit_Summary7_Seal_ChineseAlt: "",
FitPro_Sound_Title: "Sonido",
FitPro_Sound_Intro_VOSubtitle: "Los audífonos Beats Fit Pro están diseñados para brindar un sonido potente y equilibrado.",
FitPro_Sound_ChapterText1: "Los audífonos Beats Fit Pro tienen un <em>controlador personalizado</em> con un gran rango dinámico en la curva de frecuencia. ",
FitPro_Sound_Driver_Title: "Pequeño, pero potente",
FitPro_Sound_Driver_Subtitle: "El controlador tiene sólo 9.5 mm de diámetro, pero genera increíbles tonos graves, agudos y medios. Es la mitad de lo que mide el diámetro de la moneda estadounidense de un centavo.",
FitPro_Sound_Driver_Label: "9.5 mm",
FitPro_Sound_Pleated_Title: "Perfeccionado con pliegues",
FitPro_Sound_Pleated_Subtitle: "El controlador con pliegues patentado agrega flexibilidad al tiempo que reduce significativamente las microvibraciones. Su diseño y fabricación son sumamente complejos.",
FitPro_Sound_Airflow_Title: "Fluye como el viento",
FitPro_Sound_Airflow_Subtitle: "El excelente flujo de aire que permite el controlador minimiza la distorsión de los tonos agudos y reproduce con potencia los tonos graves.",
FitPro_Sound_UniqueDriver_Title: "Calidad de sonido característica",
FitPro_Sound_UniqueDriver_Subtitle: "El diseño exclusivo del controlador crea un sonido potente y equilibrado con muy poca distorsión.",
FitPro_Sound_ChapterText2: "<em>Disfruta un sonido excepcional</em> que se adapta a ti.",
FitPro_Sound_SpatialAudio_Title: "Sonido multidimensional",
FitPro_Sound_SpatialAudio_Subtitle: "El audio espacial con seguimiento dinámico de la cabeza genera un sonido envolvente que se adapta al movimiento de tu cabeza.*",
FitPro_Sound_SpatialAudio_Disclaimer: "*Requiere hardware y software compatibles. Funciona con contenido compatible en apps compatibles. No todo el contenido está disponible en Dolby Atmos. La función de seguimiento dinámico de la cabeza requiere un iPhone o un iPad. ",
FitPro_Sound_UseCases_Subtitle: "Toca o haz clic en cada caso de uso para obtener más información.",
FitPro_Sound_UseCases_Music: "Música",
FitPro_Sound_UseCases_Music_Subtitle: "Escucha tus canciones favoritas con calidad de estudio gracias al sonido multidimensional.*",
FitPro_Sound_UseCases_Movies: "Películas",
FitPro_Sound_UseCases_Movies_Subtitle: "Deja que te envuelva el sonido para sentir que estás al centro de tus películas favoritas.*",
FitPro_Sound_UseCases_Games: "Juegos",
FitPro_Sound_UseCases_Games_Subtitle: "Disfruta tus juegos a un nivel inmersivo.*",
FitPro_Sound_UseCases_Disclaimer_1: "*Requiere hardware y software compatibles. Funciona con contenido compatible en apps compatibles. No todo el contenido está disponible en Dolby Atmos. La función de seguimiento dinámico de la cabeza requiere un iPhone o un iPad. ",
FitPro_Sound_Calls_Title: "El ruido no hace ruido",
FitPro_Sound_Calls_Subtitle: "Con un acelerómetro integrado y tecnología Class 1 Bluetooth®, los audífonos Beats Fit Pro detectan cuando hablas. Los micrófonos detectan tu voz mientras un procesador digital filtra el viento y el ruido externo para crear un sonido claro y nítido.",
FitPro_Sound_Summary_Title: "Diseñados para brindar un sonido potente y equilibrado",
FitPro_Sound_Summary1_Driver: "El controlador tiene sólo 9.5 mm de diámetro, pero brinda increíbles graves, agudos y medios. ",
FitPro_Sound_Summary2_Pleated: "El controlador con pliegues patentado agrega flexibilidad y elimina las microvibraciones.",
FitPro_Sound_Summary3_Airflow: "El excelente flujo de aire que permite el controlador minimiza la distorsión de los tonos agudos y reproduce con nitidez los tonos graves.",
FitPro_Sound_Summary4_UniqueDriver: "El diseño exclusivo del controlador crea un sonido potente y equilibrado con muy poca distorsión.",
FitPro_Sound_Summary5_SpatialAudio: "El audio espacial con seguimiento dinámico de la cabeza genera un sonido envolvente que se adapta al movimiento de tu cabeza.",
FitPro_Sound_Summary6_UseCases: "El audio espacial con seguimiento dinámico de la cabeza crea una experiencia envolvente.",
FitPro_Sound_Summary7_Calls: "Los audífonos Beats Fit Pro detectan cuando hablas para que tu voz se escuche increíble.",
FitPro_Modes_Title: "Modos de audio",
FitPro_Modes_Intro_VOSubtitle: "Tres modos de audio que te permiten controlar lo que quieres escuchar. ",
FitPro_Modes_Toggle_Title: "Escucha lo que realmente importa",
FitPro_Modes_Toggle_Subtitle: "Puedes elegir cualquiera de los tres modos de audio: cancelación de ruido activa (ANC), modo ambiente y Ecualización Adaptativa.",
FitPro_Modes_Toggle_Disclaimer: "Creados específicamente para fines demostrativos.",
FitPro_Modes_Toggle_Label1: "Cancelación de ruido",
FitPro_Modes_Toggle_Label2: "Ecualización Adaptativa",
FitPro_Modes_Toggle_Label3: "Ambiente",
FitPro_Modes_Toggle_MusicLabel: "En reproducción",
FitPro_Modes_Toggle_SongTitle: "Mood",
FitPro_Modes_Toggle_SongArtist: "24kGoldn, ft. iann dior",
FitPro_Modes_ChapterText1: "La <em>cancelación de ruido activa</em> bloquea el ruido externo para que puedas disfrutar tu música.",
FitPro_Modes_ANCExternalMic_Title: "Captura el ruido externo",
FitPro_Modes_ANCExternalMic_Subtitle: "La cancelación de ruido activa empieza con un micrófono orientado hacia afuera que detecta el sonido del entorno.",
FitPro_Modes_ANCAntiNoise_Title: "Neutraliza el sonido no deseado",
FitPro_Modes_ANCAntiNoise_Subtitle: "Los audífonos Beats Fit Pro entonces generan antirruido para cancelar los sonidos del exterior antes de que los escuches.",
FitPro_Modes_ANCInwardMic_Title: "Ajuste continuo",
FitPro_Modes_ANCInwardMic_Subtitle: "Un micrófono orientado hacia adentro ayuda a analizar problemas con el ajuste y el aislamiento acústico mediante la detección de otros sonidos y el uso de antirruido para eliminarlos.",
FitPro_Modes_ANCUseCases_Subtitle: "Toca o haz clic en cada caso de uso para obtener más información.",
FitPro_Modes_ANCUseCases_Commute: "Trayectos",
FitPro_Modes_ANCUseCases_Commute_Subtitle: "Viaja hacia y desde el trabajo sin escuchar tanto el ruido de fondo de la ciudad.",
FitPro_Modes_ANCUseCases_Exercise: "Ejercicio",
FitPro_Modes_ANCUseCases_Exercise_Subtitle: "Enfócate por completo en tu entrenamiento sin ningún tipo de distracción.",
FitPro_Modes_ANCUseCases_Travel: "Viajes",
FitPro_Modes_ANCUseCases_Travel_Subtitle: "Ahoga el sonido del motor y los demás pasajeros para que disfrutes tu vuelo.",
FitPro_Modes_ANCUseCases_Meditate: "Meditación",
FitPro_Modes_ANCUseCases_Meditate_Subtitle: "Encuentra paz interior enfocándote en tu respiración y bloqueando las distracciones.",
FitPro_Modes_ChapterText2: "Mantente alerta de lo que sucede a tu alrededor con el <em>modo ambiente.</em>",
FitPro_Modes_TMAware_Title: "Escucha los sonidos que te rodean",
FitPro_Modes_TMAware_Subtitle: "El modo ambiente mezcla tu música con los sonidos del exterior para mantenerte al tanto de tu entorno.",
FitPro_Modes_TMExternalMic_Title: "Los micrófonos externos detectan el ruido del entorno.",
FitPro_Modes_TMExternalMic_Subtitle: "Los micrófonos externos capturan los ruidos externos y del entorno para darte una experiencia de audio natural y abierta. ",
FitPro_Modes_TMUseCases_Subtitle: "Toca o haz clic en cada caso de uso para obtener más información.",
FitPro_Modes_TMUseCases_Run: "Corre",
FitPro_Modes_TMUseCases_Run_Subtitle: "Mantente al tanto de lo que sucede a tu alrededor sin perder el ritmo.",
FitPro_Modes_TMUseCases_Travel: "Viaja",
FitPro_Modes_TMUseCases_Travel_Subtitle: "Escucha los anuncios importantes en la puerta de embarque de un aeropuerto o la plataforma de un tren.",
FitPro_Modes_TMUseCases_Walk: "Camina",
FitPro_Modes_TMUseCases_Walk_Subtitle: "Presta atención al tráfico a tu alrededor y a los cruces peligrosos.",
FitPro_Modes_TMUseCases_StandBy: "Espera",
FitPro_Modes_TMUseCases_StandBy_Subtitle: "Escucha tu nombre claramente mientras escuchas música en la cafetería o esperas a alguien.",
FitPro_Modes_ChapterText3: "La <em>Ecualización Adaptativa</em> personaliza totalmente tu experiencia de audio.",
FitPro_Modes_AdaptiveEQ_Title: "Se adapta a ti",
FitPro_Modes_AdaptiveEQ_Subtitle: "Al desactivar el modo ambiente y la cancelación de ruido activa, la Ecualización Adaptativa utiliza micrófonos para ajustar el sonido a tu oído automáticamente.",
FitPro_Modes_Summary_Title: "Enfócate en la música o escucha lo que sucede a tu alrededor",
FitPro_Modes_Summary1_Toggle: "Puedes elegir cualquiera de los tres modos de audio: cancelación de ruido activa, modo ambiente y Ecualización Adaptativa.",
FitPro_Modes_Summary2_ANCExtermalMic: "La cancelación de ruido activa empieza con un micrófono orientado hacia afuera que detecta el sonido del entorno.",
FitPro_Modes_Summary3_ANCAntiNoise: "Los audífonos Beats Fit Pro entonces generan la misma cantidad de antirruido.",
FitPro_Modes_Summary4_ANCInwardMic: "Un micrófono orientado hacia adentro ayuda a analizar el ajuste y el aislamiento acústico mediante la detección de sonidos adicionales.",
FitPro_Modes_Summary5_ANCUseCases: "Usa la ANC para concentrarte al ejercitarte, meditar o viajar.",
FitPro_Modes_Summary6_TMAware: "El modo ambiente te permite estar al tanto de lo que te rodea.",
FitPro_Modes_Summary7_TMExternalMic: "Los micrófonos externos capturan los ruidos externos y del entorno.",
FitPro_Modes_Summary8_TMUseCases: "Escucha lo que sucede a tu alrededor cuando salgas a correr, al viajar o caminar, o mientras esperas.",
FitPro_Modes_Summary9_AdaptiveEQ: "La Ecualización Adaptativa utiliza micrófonos para ajustar el sonido automáticamente a la forma del oído de cada persona.",
FitPro_Controls_Title: "Controles",
FitPro_Controls_Intro_VOSubtitle: "Controla tus audífonos con sólo presionar un botón.",
FitPro_Controls_ChapterText1: "Cómodos <em>botones sobre la oreja</em> de fácil acceso.",
FitPro_Controls_PhysicalButton_Title: "Presiona para reproducir",
FitPro_Controls_PhysicalButton_Subtitle: "Con botones físicos es más fácil controlar lo que quieres escuchar.",
FitPro_Controls_BothSides_Title: "Conectividad independiente en cada audífono",
FitPro_Controls_BothSides_Subtitle: "Ambos audífonos contienen botones para que puedas controlar la música con el lado que prefieras.",
FitPro_Controls_ChapterText2: "<em>Controla los modos de audio</em> donde y cuando quieras.",
FitPro_Controls_PressHold_Title: "Cambia fácilmente entre los modos de audio",
FitPro_Controls_PressHold_Subtitle: "Mantén presionado para alternar entre los modos de audio o personalízalo para activar Siri.*",
FitPro_Controls_PressHold_Disclaimer: "*Para personalizar la función de presionar y mantener, es necesario tener un iPhone con iOS 14.6 o posterior, o un dispositivo Android con la versión más reciente de la app Beats instalada. Siri puede no estar disponible en todos los idiomas y áreas, y las funcionalidades pueden variar según el área. Se requiere acceso a Internet. Puede estar sujeto a cargos por uso de datos celulares.",
FitPro_Controls_PressHold_Label1: "Modo de cancelación de ruido",
FitPro_Controls_PressHold_Label2: "Modo ambiente",
FitPro_Controls_Playback_Title: "Controles sobre la oreja",
FitPro_Controls_Playback_Subtitle: "Los controles sobre la oreja te permiten administrar las llamadas y la música.",
FitPro_Modes_Playback_MusicLabel: "En reproducción",
FitPro_Modes_Playback_SongTitle1: "5 Seconds of Summer",
FitPro_Modes_Playback_SongArtist1: "Best Years",
FitPro_Modes_Playback_SongTitle2: "Boyfriend",
FitPro_Modes_Playback_SongArtist2: "Selena Gomez",
FitPro_Modes_Playback_SongTitle3: "Lovesick",
FitPro_Modes_Playback_SongArtist3: "Trevor Daniel",
FitPro_Modes_Playback_SongTitle4: " Mood",
FitPro_Modes_Playback_SongArtist4: "24kGoldn, ft. iann dior",
FitPro_Modes_Playback_SongTitle5: "What If I Told You That I Love You",
FitPro_Modes_Playback_SongArtist5: "Ali Gatie",
FitPro_Controls_Playback_Instruction: "Haz clic o toca para practicar los comandos.",
FitPro_Controls_Playback_Play1: "Presiona para pausar la música o contestar una llamada",
FitPro_Controls_Playback_Play2: "Presiona para reproducir música o colgar",
FitPro_Controls_Playback_Skip: "Presiona 2 veces para saltar una pista",
FitPro_Controls_Playback_Previous: "Presiona 3 veces para reproducir la canción anterior",
FitPro_Controls_Playback_ScanFwd: "Presiona 2 veces y mantén pulsado para avanzar rápido",
FitPro_Controls_Playback_ScanBck: "Presiona 3 veces y mantén pulsado para retroceder rápido",
FitPro_Controls_Customize_Title: "Elige un audífono",
FitPro_Controls_Customize_Subtitle: "Puedes personalizar la función de mantener presionado en cada audífono para activar la cancelación de ruido activa, el modo ambiente, la Ecualización Adaptativa, el control de volumen o el asistente de voz mediante iOS o la app Beats para Android.*",
FitPro_Controls_Customize_Disclaimer: "*La app Beats requiere Android 8.0 o versiones posteriores. está disponible en Google Play Store y en beatsbydre.com.",
FitPro_Controls_Android_Title: "Compatible con Android",
FitPro_Controls_Android_Subtitle: "Con la app Beats para Android, obtienes funcionalidades adicionales como enlace con un solo toque, controles personalizados, niveles de batería y actualizaciones de firmware.*",
FitPro_Controls_Android_Disclaimer: "*La app Beats requiere Android 8.0 o versiones posteriores. está disponible en Google Play Store y en beatsbydre.com.",
FitPro_Controls_Summary_Title: "Todo el control en tus dedos",
FitPro_Controls_Summary1_PhysicalButton: "Con botones físicos es más fácil controlar lo que quieres escuchar.",
FitPro_Controls_Summary2_BothSides: "Ambos audífonos contienen botones para que puedas controlar la música con el lado que prefieras.",
FitPro_Controls_Summary3_PressHold: "Mantén presionado para alternar entre los modos de audio.",
FitPro_Controls_Summary4_Playback: "Los controles sobre la oreja te permiten administrar las llamadas y la música.",
FitPro_Controls_Summary5_Customize: "Puedes personalizar la función de mantener presionado en cada audífono.",
FitPro_Controls_Summary6_Android: "Con al app Beats para Android obtienes funcionalidades adicionales.",
FitPro_H1_Title: "Chip H1 de Apple",
FitPro_H1_Intro_VOSubtitle: "Los audífonos Beats Fit Pro están diseñados para utilizar el chip H1 de Apple. ",
FitPro_H1_AppleIntegration_Title: "Perfecta integración con Apple",
FitPro_H1_AppleIntegration_Subtitle: "Equipados con el chip H1 de Apple que brinda funcionalidades adicionales y perfecta integración con los dispositivos Apple.",
FitPro_H1_ChapterText1: "Los audífonos Beats Fit Pro tienen el <em>chip H1 de Apple</em>, que brinda funcionalidades populares.",
FitPro_H1_Pairing_Title: "Enlace de un solo toque",
FitPro_H1_Pairing_Subtitle: "Los audífonos Beats Fit Pro se conectan con tus dispositivos iOS desde el principio con el enlace de un solo toque mediante tecnología Class 1 Bluetooth® líder en la industria.*",
FitPro_H1_Pairing_Disclaimer: "*Requiere iOS 15.1 o posterior.",
FitPro_H1_HeySiri_Title: "Oye Siri",
FitPro_H1_HeySiri_Subtitle: "Controla tu música, llamadas, indicaciones y mucho más con sólo decir \"Oye Siri\".*",
FitPro_H1_HeySiri_Disclaimer: "* Siri puede no estar disponible en todos los idiomas y áreas, y las funcionalidades pueden variar según el área. Se requiere acceso a Internet. Puede estar sujeto a cargos por uso de datos celulares.",
FitPro_H1_AutoSwitch_Title: "Detección automática de dispositivo",
FitPro_H1_AutoSwitch_Subtitle: "Los audífonos Beats Fit Pro permiten que el sonido pase sin problemas de un dispositivo a otro mediante detección automática.",
FitPro_H1_AutoSwitch_Disclaimer: "*La detección automática de dispositivo requiere macOS 11.1, iOS 14.3, iPadOS 14.3, watchOS 7.2 o tvOS 14.3 o versiones posteriores.",
FitPro_H1_AudioSharing_Title: "Compartir audio",
FitPro_H1_AudioSharing_Subtitle: "Comparte tus canciones favoritas y escucha música con alguien que tenga un par de audífonos Beats o AirPods.*",
FitPro_H1_AudioSharing_Disclaimer: "*La opción para compartir audio es compatible con los audífonos inalámbricos Beats y todos los modelos de AirPods.  Requiere hardware y software de Apple compatibles.",
FitPro_H1_FindMy_Title: "Encuentra tus dispositivos",
FitPro_H1_FindMy_Subtitle: "La funcionalidad de Encontrar te ayuda a saber dónde están tus audífonos en todo momento.*",
FitPro_H1_FindMy_Disclaimer: "*La app Encontrar para los audífonos Beats requiere un iPhone o iPod touch con iOS 15.1 o posterior, un iPad con iPadOS 15.1 o posterior, o una Mac con macOS Monterey 12.0.1 o posterior.  Los clientes deben tener un Apple ID e iniciar sesión en su cuenta de iCloud con la app Encontrar activada.",
FitPro_H1_Summary_Title: "Equipados con el chip H1 de Apple",
FitPro_H1_Summary1_Seamless: "Equipados con el chip H1 de Apple para una perfecta integración con los dispositivos Apple.",
FitPro_H1_Summary2_Pairing: "Se conectan fácilmente mediante Bluetooth® con el sencillo enlace de un solo toque.",
FitPro_H1_Summary3_HeySiri: "Sólo di \"Oye Siri\" para activar a Siri y hacerle una pregunta sin usar las manos.",
FitPro_H1_Summary4_AutoSwitch: "Los audífonos Beats Fit Pro cambian fácilmente de un dispositivo a otro con la detección automática de dispositivo.",
FitPro_H1_Summary5_AudioSharing: "Comparte tu música con alguien que tenga un par de audífonos Beats o AirPods.",
FitPro_H1_Summary6_FindMy: "Usa la app Encontrar para localizar fácilmente tus audífonos.",
FitPro_Design_Title: "Diseño",
FitPro_Design_Intro_VOSubtitle: "Los audífonos Beats Fit Pro están diseñados para acompañarte en todas tus actividades. ",
FitPro_Design_ChapterText1: "El <em>estuche de carga</em> está diseñado para que puedas cargar tus audífonos durante el día.",
FitPro_Design_Pocket_Title: "Caben en tu bolsillo",
FitPro_Design_Pocket_Subtitle: "Lleva tus audífonos Beats Fit Pro a todas partes con el estuche de carga portátil.",
FitPro_Design_Size_Title: "Más de 50% más pequeños",
FitPro_Design_Size_Subtitle: "El estuche de los audífonos Beats Fit Pro es más de 50% más pequeño que el de los Powerbeats Pro.",
FitPro_Design_Color_Title: "La combinación perfecta",
FitPro_Design_Color_Subtitle: "Los audífonos y sus estuches vienen en cuatro colores diferentes para que elijas el que vaya mejor con tu estilo.",
FitPro_Design_Color_Black: "BEATS AZABACHE",
FitPro_Design_Color_White: "BEATS NIEVE",
FitPro_Design_Color_Purple: "MORADO GEMA",
FitPro_Design_Color_Gray: "GRIS MENTOLADO",
FitPro_Design_ChapterText2: "Los audífonos <em>Beats Fit Pro</em> están diseñados para acompañarte adonde vayas.",
FitPro_Design_TrueWireless_Title: "Libertad inalámbrica",
FitPro_Design_TrueWireless_Subtitle: "Los audífonos Beats Fit Pro son inalámbricos de verdad y te acompañan en todas tus actividades.",
FitPro_Design_Independent_Title: "De uno en uno",
FitPro_Design_Independent_Subtitle: "La conectividad independiente te permite usar un solo audífono y seguir teniendo control de la música gracias a los botones integrados en cada uno.",
FitPro_Design_Sweat_Title: "Lluvia, sudor o sol",
FitPro_Design_Sweat_Subtitle: "Los audífonos Beats Fit Pro cuentan con la clasificación IPX-4 de resistencia al sudor y al agua para que puedas usarlos en cualquier condición climática durante tus entrenamientos más extremos.*",
FitPro_Design_Sweat_Disclaimer: "*Los audífonos Beats Fit Pro son resistentes al agua y al sudor para hacer ejercicio o practicar deportes no acuáticos. La resistencia al agua y al sudor no es una condición permanente. El estuche de carga no es resistente al agua ni al sudor.",
FitPro_Design_Summary_Title: "Diseñados para acompañarte en todas tus actividades",
FitPro_Design_Summary1_Pocket: "Los audífonos Beats Fit Pro incluyen un estuche de carga portátil que puedes llevar en el bolsillo.",
FitPro_Design_Summary2_Size: "El estuche de los audífonos Beats Fit Pro es más de 50% más pequeño que el de los Powerbeats Pro.",
FitPro_Design_Summary3_Color: "Disponibles en cuatro colores que combinan con cualquier estilo.",
FitPro_Design_Summary4_TrueWireless: "Los audífonos Beats Fit Pro son inalámbricos de verdad.",
FitPro_Design_Summary5_Independent: "Conectividad independiente en cada audífono para que puedas usar uno solo.",
FitPro_Design_Summary6_Sweat: "Los audífonos Beats Fit Pro tienen la clasificación IPX-4 de resistencia al agua y al sudor.",
FitPro_Battery_Title: "Batería",
FitPro_Battery_Intro_VOSubtitle: "Una batería que te permite escuchar música durante horas y horas. ",
FitPro_Battery_6Hours_Title: "Disfruta horas de audio",
FitPro_Battery_6Hours_Subtitle: "Escucha hasta 6 horas de tu música favorita en cada audífono.* ",
FitPro_Battery_6Hours_Disclaimer: "*La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.",
FitPro_Battery_18Hours_Title: "Hasta 24 horas en total",
FitPro_Battery_18Hours_Subtitle: "Disfruta 18 horas adicionales de audio con el estuche de carga.*",
FitPro_Battery_18Hours_Disclaimer: "*La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.",
FitPro_Battery_FastFuel_Title: "Llega aun más lejos",
FitPro_Battery_FastFuel_Subtitle: "Fast Fuel te da 1 hora más de audio con 5 minutos de carga.*",
FitPro_Battery_FastFuel_Disclaimer: "*La duración de la batería depende de la configuración del dispositivo, el entorno, el uso y muchos otros factores.",
FitPro_Battery_USBC_Title: "Carga USB-C",
FitPro_Battery_USBC_Subtitle: "El conector de carga USB-C funciona con Apple y Android, de modo que puedes cargarlos donde quieras. ",
FitPro_Battery_WhatsInTheBox_Title: "Contenido de la caja",
FitPro_Battery_WhatsInTheBox_Subtitle: "Los audífonos Beats Fit Pro vienen con un estuche de carga de bolsillo, un conector de carga USB-C universal y tres tamaños de almohadillas entre los que puedes elegir.",
FitPro_Battery_Summary_Title: "Horas y horas de audio",
FitPro_Battery_Summary1_6Hours: "6 horas de audio en cada audífono.",
FitPro_Battery_Summary2_18Hours: "Disfruta 18 horas adicionales de audio con el estuche de carga.",
FitPro_Battery_Summary3_FastFuel: "Fast Fuel te da 1 hora más de audio con 5 minutos de carga.",
FitPro_Battery_Summary4_USBC: "Incluyen un conector de carga USB-C para que puedas cargarlos en cualquier parte.",
FitPro_Battery_Summary5_WhatsInTheBox: "En la caja se incluye un estuche de carga de bolsillo, un conector de carga USB-C y tres tamaños de almohadillas.",
FitPro_Outro_ChapterText1: "Con puntas de ajuste flexibles y seguras, tres modos de audio y un diseño compacto y portátil, los audífonos <em>Beats Fit Pro te dan el control de lo que quieres escuchar.</em>",
FitPro_TestKnowledge: "Es hora de poner a prueba tu conocimiento sobre los Beats Fit Pro.",
FitPro_TakeQuiz: "RESPONDER EL CUESTIONARIO",
Global_ForgotEmail_NoUser:"No tenemos registros con esa cuenta. Quizá se borró el usuario."

};
export default copy;
