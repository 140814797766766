import { useEffect, useContext, useRef } from 'react';
import Context from '../../context/Context';

// Cannot set HTMLAudioElement volume on iOS
const deviceCanSetVolume = !/(iPad|iPhone|iPod)/g.test(navigator.userAgent);
const nonDesktop =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const BackgroundAudio = () => {
  const context = useContext(Context);
  const { muted, silenceBackgroundAudio, bgAudio, setMuted } = context;
  const audioElement = useRef(null);
  useEffect(() => {
    if (muted) {
      audioElement.current.pause();
    } else {
      audioElement.current.play();
    }
  }, [muted]);

  useEffect(() => {
    let fadeBackgroundAudioTimeout;
    const rateOfChange = 0.05;

    const fadeOutBackgroundAudio = () => {
      if (audioElement.current.volume > 0) {
        audioElement.current.volume = Math.max(
          audioElement.current.volume - rateOfChange,
          0
        );
        fadeBackgroundAudioTimeout = setTimeout(fadeOutBackgroundAudio, 33);
      } else {
        audioElement.current.pause();
      }
    };
    const fadeInBackgroundAudio = () => {
      if (audioElement.current.paused) {
        try {
          audioElement.current.play();
        } catch (e) {
          // Attempted play without user interaction event. Ignore.
        }
      }
      if (audioElement.current.volume < 0.18) {
        audioElement.current.volume = Math.min(
          audioElement.current.volume + rateOfChange,
          1
        );
        fadeBackgroundAudioTimeout = setTimeout(fadeInBackgroundAudio, 33);
      }
    };

    if (silenceBackgroundAudio) {
      if (deviceCanSetVolume) {
        fadeOutBackgroundAudio();
      } else if (!deviceCanSetVolume || muted) {
        audioElement.current.pause();
      }
    } else {
      if (deviceCanSetVolume) {
        fadeInBackgroundAudio();
      } else if (!muted) {
        audioElement.current.play();
      }
    }
    return () => {
      clearTimeout(fadeBackgroundAudioTimeout);
    };
  }, [silenceBackgroundAudio, muted]);

  useEffect(() => {
    audioElement.current.volume = 0.18;
    if (nonDesktop) {
      window.onblur = () => {
        setMuted(true);
      };
    }
  }, []);

  return (
    <audio
      ref={audioElement}
      src={`${process.env.PUBLIC_URL}${bgAudio}`}
      muted={muted}
      loop
      volume={0.18}
    ></audio>
  );
};

export default BackgroundAudio;
