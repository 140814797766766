import React, { useState, useEffect } from 'react';

import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
	passwordOne: '',
	passwordTwo: '',
	error: null,
};

const PasswordChangeForm = (props) => {
	const { firebase } = props;
	const [passwordOne, setPasswordOne] = useState('');
	const [passwordTwo, setPasswordTwo] = useState('');
	const [error, setError] = useState(null);
	const onSubmit = (event) => {
		firebase
			.doPasswordUpdate(passwordOne)
			.then(() => {
				setPasswordOne('');
				setPasswordTwo('');
				setError(null);
			})
			.catch((error) => {
				setError(error);
			});

		event.preventDefault();
	};

	const onChangePass1 = (event) => {
		setPasswordOne(event.target.value);
	};

	const onChangePass2 = (event) => {
		setPasswordTwo(event.target.value);
	};

	const isInvalid = passwordOne !== passwordTwo || passwordOne === '';
	return (
		<form onSubmit={onSubmit}>
			<input
				name="passwordOne"
				value={passwordOne}
				onChange={onChangePass1}
				type="password"
				placeholder="New Password"
			/>
			<input
				name="passwordTwo"
				value={passwordTwo}
				onChange={onChangePass2}
				type="password"
				placeholder="Confirm New Password"
			/>
			<button disabled={isInvalid} type="submit">
				Reset My Password
			</button>

			{error && <p>{error.message}</p>}
		</form>
	);
};

export default withFirebase(PasswordChangeForm);
