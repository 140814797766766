import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Route, Switch, useLocation, matchPath } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Context from '../../context/Context';
import { useHistory } from 'react-router-dom';

import { preloadModuleVideoAndAudio } from './ChapterPreloader';
import * as SITEDATA from '../../constants';
import Styles from './basechapter.module.scss';
import AudioControl from '../AudioControl';
import WithinChapterNav from './WithinChapterNav';
import useCanvasSize from '../../hooks/useCanvasSize';

const BaseChapter = props => {
  const { chapter, match, setCurrentChapter, progress } = props;
  const context = useContext(Context);
  const { mobileLayout, languageID, language, siteID } = context;
  const CHAPTERS = siteID
    ? SITEDATA[`${siteID}_CHAPTERS`]
    : SITEDATA[`studioBuds_CHAPTERS`];

  const location = useLocation();
  const videoCanvas = useRef(null);
  const CANVAS_SIZE = useCanvasSize();
  const hasMouseInput = window.matchMedia('(any-hover: hover)').matches;

  const [introVideoFinished, setIntroVideoFinished] = useState(false);
  const [introAudioFinished, setIntroAudioFinished] = useState(false);
  const history = useHistory();

  const [videoContext, setVideoContext] = useState(null);

  const moduleMatch = matchPath(location.pathname, {
    path: `${process.env.PUBLIC_URL}/:chapterId/:moduleId`,
  });

  let activeModuleId = moduleMatch
    ? moduleMatch.params.moduleId
    : chapter.modules[0].id;

  let activeModule = chapter.modules.find(m => m.id === activeModuleId);
  if (!activeModule) {
    activeModuleId = 'intro';
    activeModule = chapter.modules.find(m => m.id === activeModuleId);
    history.push(`/${chapter.id}`);
  }

  const thisModuleIndex = chapter.modules.indexOf(activeModule);
  const nextModuleIndex = thisModuleIndex + 1;
  let nextModule =
    chapter.modules.length > nextModuleIndex
      ? chapter.modules[nextModuleIndex]
      : undefined;

  const prevModuleIndex = thisModuleIndex - 1;
  const prevModule = chapter.modules[prevModuleIndex];
  const thisChapterIndex = CHAPTERS.indexOf(chapter);
  const nextChapterIndex = thisChapterIndex + 1;
  let nextChapter =
    CHAPTERS.length > nextChapterIndex ? CHAPTERS[nextChapterIndex] : undefined;

  let nextLinkTo = '';
  if (nextModule) {
    nextLinkTo = `${
      process.env.PUBLIC_URL
    }/${chapter.id.toLowerCase()}/${nextModule.id.toLowerCase()}`;
  } else if (!nextModule && nextChapter) {
    nextLinkTo = `${process.env.PUBLIC_URL}/${nextChapter.id.toLowerCase()}`;
  }

  let prevLinkTo = '';
  if (prevModule) {
    prevLinkTo = `${
      process.env.PUBLIC_URL
    }/${chapter.id.toLowerCase()}/${prevModule.id.toLowerCase()}`;
  }

  useEffect(() => {
    setCurrentChapter(chapter);
  }, [chapter, setCurrentChapter]);

  // Preload next module
  useEffect(() => {
    if (nextModule) {
      preloadModuleVideoAndAudio(nextModule, languageID, CANVAS_SIZE <= 1280);
    }
    if (
      !nextModule &&
      nextChapter &&
      nextChapter.modules &&
      nextChapter.modules[0]
    ) {
      preloadModuleVideoAndAudio(
        nextChapter.modules[0],
        languageID,
        CANVAS_SIZE <= 1280
      );
    }
  }, [nextModule, nextChapter, languageID]);

  useEffect(() => {
    if (videoCanvas.current) {
      const context = videoCanvas.current.getContext('2d');
      setVideoContext(context);
    }
  }, [videoCanvas]);
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.25, ease: 'easeOut' }}
      className={Styles.root}
    >
      <div
        className={Styles.background}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}${
            chapter.backgroundImage ? chapter.backgroundImage.path : null
          })`,
          backgroundSize: 'cover',
          backgroundColor: chapter.backgroundColor,
        }}
      />
      <div id="main-video" className={Styles.mainVideoContainer}>
        <canvas
          ref={videoCanvas}
          className={Styles.mainVideoCanvas}
          width={CANVAS_SIZE}
          height={CANVAS_SIZE}
        />
      </div>

      <AnimatePresence exitBeforeEnter initial={false} type="crossfade">
        <Switch location={location} key={location.pathname}>
          <Route
            exact
            path={`${match.path}`}
            render={pageProps => (
              <activeModule.component
                chapter={chapter}
                introVideoFinished={introVideoFinished}
                introAudioFinished={introAudioFinished}
                setIntroVideoFinished={setIntroVideoFinished}
                setIntroAudioFinished={setIntroAudioFinished}
                module={chapter.modules[0]}
                videoContext={videoContext}
                mobile={mobileLayout}
                {...props}
                {...pageProps}
                {...chapter.modules[0].componentProps}
              />
            )}
          />
          {chapter.modules.map(module => {
            return (
              <Route
                key={module.id}
                exact
                path={`${match.path}/${module.id.toLowerCase()}`}
                render={pageProps => (
                  <module.component
                    chapter={chapter}
                    module={module}
                    introVideoFinished={introVideoFinished}
                    introAudioFinished={introAudioFinished}
                    setIntroVideoFinished={setIntroVideoFinished}
                    setIntroAudioFinished={setIntroAudioFinished}
                    videoContext={videoContext}
                    mobile={mobileLayout}
                    {...props}
                    {...pageProps}
                    {...module.componentProps}
                  />
                )}
              />
            );
          })}
        </Switch>
      </AnimatePresence>
      {!activeModule.hideWithinChapterNav && (
        <WithinChapterNav
          chapter={chapter}
          activeModule={activeModule}
          prevModule={prevModule}
          nextModule={nextModule}
          thisModuleIndex={thisModuleIndex}
          prevLinkTo={prevLinkTo}
          nextLinkTo={nextLinkTo}
          progress={progress}
        />
      )}
      {!hasMouseInput && activeModule.replaceNextButtonContent && (
        <Link to={nextLinkTo} className="ctaButton centerBottom">
          {language[activeModule.replaceNextButtonContent]}
        </Link>
      )}
      <AudioControl className={Styles.audioControl} />
    </motion.div>
  );
};
export default BaseChapter;
