import React, {useContext, useEffect, useState} from 'react';
import Context from '../../context/Context';
import cookie from 'react-cookies';
import { useHistory } from 'react-router-dom';
import Styles from './language.module.scss';
import * as ROUTES from '../../constants/routes';
import {motion, AnimatePresence} from 'framer-motion'
import * as SITEDATA from '../../constants';
import { TrackEvent } from '../../utils/track'

const Language = props => {
	
	const {ga4} = props;
	const history = useHistory();
	const context = useContext(Context);
	const [cookieLanguage, setCookieLanguage] = useState(cookie.load('cr_trnr_lang'));
	const { setShowCursor, isKioskVersion, siteID, setOverrideTheme } = context;
	setShowCursor(false);
	const LANGUAGES = siteID ? SITEDATA[`${siteID}_LANGUAGES`] : SITEDATA[`studioBuds_LANGUAGES`]

	useEffect(() => {
		setCookieLanguage(cookie.load('cr_trnr_lang'));
	}, [cookieLanguage]);

	const list = {
		visible: {
			transition: {
				when: 'beforeChildren',
				staggerChildren: 0.3,
			},
		},
		hidden: {
			transition: {
				when: 'afterChildren',
			},
		},
	};

	return (
		<Context.Consumer>
			{({ setLanguageID, language,languageID }) => {
				const updateLanguage = (code) => {
					if (ga4) {
						TrackEvent(ga4, `language_select_${code}`, 'click', 'user_action');
					}
					setLanguageID(code);
					setOverrideTheme(false);
					cookie.save('cr_trnr_lang', code, { path: '/' });
					if (isKioskVersion) {
						history.push({pathname: ROUTES.INTRO, search: history.location.search})
					} else {
						history.push({pathname: ROUTES.HOME, search: history.location.search})

					}
				};
				return (
					<div className={`${Styles.language}`}>
					<div className={`${Styles.header}`}>{language['Global_Languages_Select'].toUpperCase()}</div>
					<AnimatePresence>
						<motion.div variants={list} className={Styles.languageWrapper}>
							{LANGUAGES.map((language, i) => (
								<motion.div
									 className={`${Styles.item} `} 
									 key={i} 
									 onClick={() => updateLanguage(language.code)}
									 initial={{ opacity: 0, y: 50 }}
									 animate={{ opacity: 1, y: 0 }}
									 transition={{ delay: i * 0.1 + 0.5, stiffness: 5}}
								>
									<span className={`${languageID === language.code && cookieLanguage ? Styles.selected : null}`}>{language.name}</span>
								</motion.div>
							))}
						</motion.div>
						</AnimatePresence>
					</div>
				);
			}}
		</Context.Consumer>
	);
};

export default Language;
