import React, { useContext } from "react";
import clsx from "clsx";
import BaseModule from "../BaseModule";
import Styles from "./TextAndVideoModule.module.scss";
import { createMarkup } from "../../../utils";
import Context from "../../../context/Context";
import ReplayButton from "../../ReplayButton";

export default function TextAndVideoModule(props) {
  const { module, variant } = props;
  const context = useContext(Context);
  const { language, languageID, theme } = context;
  const replaceCopyTitleNotifier = "";
  const replaceCopyBodyNotifier = "";
  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        "TextAndVideoModule"
      )}
    >
      <div className={Styles.container}>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.title]
              ? language[module.content.title]
              : replaceCopyTitleNotifier
          )}
        />
        <div className={`${Styles.text} ${languageID==='JA_JP' ? Styles.left : null}`}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.text]
                ? language[module.content.text]
                : replaceCopyBodyNotifier
            )}
          />
          {module.content.textCTA && (
            <a
              className={clsx("ctaButton", Styles.textCTA, module.id === 'summary' ? 'outroCta' : '')}
              href={module.content.textCTA.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language[module.content.textCTA.label]
                ? language[module.content.textCTA.label]
                : replaceCopyBodyNotifier}
            </a>
          )}
        </div>

        {variant === "leftAlign" && (
          <ReplayButton
            videoElement={module.content.video.videoElement}
            audioObject={module.content.voiceover}
            attachToGlobalCanAdvance
          />
        )}
        {module.content.disclaimer && (
          <div
            className={ `${Styles.disclaimer} ${theme ? Styles[theme] : Styles.light}`}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}
      </div>
    </BaseModule>
  );
}
