/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import BaseModule from '../BaseModule';
import Styles from './InteractiveColorsModule.module.scss';
import { createMarkup } from '../../../utils';
import Context from '../../../context/Context';
import ReplayButton from '../../ReplayButton';
import useCanvasSize from '../../../hooks/useCanvasSize';
import DraggableSpot from './components/DraggableSpot';

const img = new Image();

let lastPercentage;
let lastFrame;
let idTimeout;
let idAnimation;

export default function InteractiveColorsModule(props) {
  const [currentColor, setCurrentColor] = useState(1);
  const [canInteract, setCanInteract] = useState(false);
  const {
    module,
    variant,
    videoContext,
    introVideoFinished,
    setIntroVideoFinished,
    progress,
    chapter,
  } = props;
  const { content } = module;
  const { colors, hotspot } = content;
  const context = useContext(Context);
  const { language, languageID, setCanAdvance, setForceDefaultCursor, siteID } =
    context;
  const replaceCopyTitleNotifier = '';
  const replaceCopyBodyNotifier = '';
  const CANVAS_SIZE = useCanvasSize();
  const draggableRef = useRef();
  const reversedVideo = content.videoTransitionToDefault.videoElement;
  let canvasInterval;

  const forceCanAdvanceTrue =
    progress &&
    progress[siteID].some(
      e => e.chapter === chapter.id && e.moduleID === module.id
    );

  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };

  const framesProps = colors.map(color => color.videoFrames);

  const clearVideoFrame = () => {
    if (videoContext) {
      videoContext.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
    }
  };

  const drawFrame = frame => {
    videoContext.drawImage(frame, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
  };

  const currentFrame = (index, colorIndex) =>
    `${process.env.PUBLIC_URL}${
      framesProps[colorIndex || currentColor - 1].path
    }/frame${index.toString().padStart(4, '0')}.jpg`;

  const updateImage = index => {
    const img = window.colorPickFrames[currentFrame(index)];
    img && drawFrame(window.colorPickFrames[currentFrame(index)]);
  };

  const calc = (percentDone, framesCt) =>
    framesCt - Math.floor(framesCt * percentDone);

  const handleVideoState = percentage => {
    if (percentage >= 0 && percentage <= 1) {
      if (
        lastPercentage !== calc(percentage, framesProps[currentColor - 1].count)
      ) {
        idAnimation = requestAnimationFrame(() =>
          updateImage(calc(percentage, framesProps[currentColor - 1].count))
        );
      }

      lastPercentage = calc(percentage, framesProps[currentColor - 1].count);
    }
  };

  const handleInteractionEnd = lastPercentage => {
    const frameCount = framesProps[currentColor - 1].count;
    lastFrame = calc(lastPercentage, frameCount);
    lastFrame = lastFrame < 1 ? 1 : lastFrame;
    lastFrame = lastFrame > frameCount ? frameCount : lastFrame;
    setCanAdvance(true);
  };

  const handlePlay = vidEl => {
    canvasInterval = window.setInterval(() => {
      drawFrame(vidEl);
    }, 1000 / 30);
  };

  useEffect(() => {
    return () => {
      lastFrame = 1;
      window.clearTimeout(idTimeout);
      window.cancelAnimationFrame(idAnimation);
      clearVideoFrame();
      clearInterval(canvasInterval);
      setIntroVideoFinished(false);
    };
  }, []);

  useEffect(() => {
    if (introVideoFinished) {
      img.src = currentFrame(framesProps[currentColor - 1]);
      img.onload = function () {
        drawFrame(img);
      };

      if (reversedVideo) {
        reversedVideo.currentTime = 0;
        reversedVideo.play();

        reversedVideo.onplay = () => handlePlay(reversedVideo);
        reversedVideo.onended = () => {
          clearInterval(canvasInterval);
          setCanInteract(true);
        };
        reversedVideo.onpause = () => clearInterval(canvasInterval);
      } else {
        setCanInteract(true);
      }
    } else {
      clearInterval(canvasInterval);
      setCanInteract(false);
      setCurrentColor(1);
    }

    if (!forceCanAdvanceTrue) {
      setCanAdvance(false);
    }

    return () => {
      clearInterval(canvasInterval);
    };
  }, [introVideoFinished]);

  useEffect(() => {
    if (currentColor && introVideoFinished) {
      if (currentColor !== 1) {
        setCanAdvance(true);
      }
      idAnimation = requestAnimationFrame(() =>
        updateImage(
          lastFrame
            ? lastFrame
            : hotspot.reverse
            ? framesProps[currentColor - 1].count
            : 1
        )
      );
    }
  }, [currentColor]);

  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        'TextAndVideoModule'
      )}
    >
      <div className={Styles.container}>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.title]
              ? language[module.content.title]
              : replaceCopyTitleNotifier
          )}
        />
        <div
          className={`${Styles.text} ${
            languageID === 'JA_JP' ? Styles.left : null
          }`}
        >
          <div
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.text]
                ? language[module.content.text]
                : replaceCopyBodyNotifier
            )}
          />
          {module.content.textCTA && (
            <a
              className={clsx(
                'ctaButton',
                Styles.textCTA,
                module.id === 'summary' ? 'outroCta' : ''
              )}
              href={module.content.textCTA.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language[module.content.textCTA.label]
                ? language[module.content.textCTA.label]
                : replaceCopyBodyNotifier}
            </a>
          )}
        </div>

        {canInteract && (
          <DraggableSpot
            ref={draggableRef}
            videoContext={videoContext}
            hotspotInfo={hotspot}
            handleVideoState={handleVideoState}
            handleInteractionEnd={handleInteractionEnd}
          />
        )}

        {variant === 'leftAlign' && (
          <>
            <ReplayButton
              videoElement={module.content.video.videoElement}
              audioObject={module.content.voiceover}
              attachToGlobalCanAdvance
            />
          </>
        )}
        {module.content.disclaimer && (
          <div
            className={Styles.disclaimer}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}

        {colors && canInteract && (
          <div className={Styles.colorsPickerWrapper}>
            <ul
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ zIndex: '100' }}
            >
              {colors.map((color, i) => {
                return (
                  <li
                    className={currentColor === i + 1 ? 'active' : ''}
                    key={`colorType-${i}`}
                  >
                    <span
                      className={Styles.textColor}
                      style={{ color: module.theme === 'dark' && '#696868' }}
                    >
                      {language[color.text]}
                    </span>
                    <button onClick={() => setCurrentColor(i + 1)}>
                      {color.color.length &&
                        color.color.map((c, id) => (
                          <span
                            className={Styles.colorDot}
                            style={{ backgroundColor: c }}
                            key={`color-${id}`}
                          />
                        ))}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </BaseModule>
  );
}
