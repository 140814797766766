let setupComplete = false;
const audioContext = (window.audioContext =
  window.audioContext ||
  new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: 41000,
  }));

const activeAudioObjects = [];

export const playSound = (audioObject, muted) => {
  while (activeAudioObjects.length) {
    const a = activeAudioObjects.pop();

    if (a.bufferSource) {
      const s = a.bufferSource;
      try {
        s.stop();
        s.disconnect(audioContext.destination);
      } catch (e) {}
    }
  }
  const buffer = audioObject.buffer;
  if (buffer) {
    const bufferSource = audioContext.createBufferSource();
    bufferSource.buffer = buffer;
    audioObject.bufferSource = bufferSource;
    bufferSource.connect(audioContext.destination);
    activeAudioObjects.push(audioObject);
    if (!muted) {
      try {
        bufferSource.start(0);
      } catch (e) {
        console.log("REPLAY EXCEPTION");
      }
    }
  }
};

export const unloadSound = (audioObject) => {
  if (audioObject.bufferSource) {
    try {
      audioObject.bufferSource.stop();
      audioObject.bufferSource.disconnect(audioContext.destination);
    } catch (e) {}
    const index = activeAudioObjects.find((a) => a === audioObject);
    if (index > -1) {
      activeAudioObjects.splice(index, 1);
    }
  }
  audioObject.buffer = undefined;
  audioObject.bufferSource = undefined;
};

export const setupAudioContext = () => {
  if (!setupComplete) {
    // Create empty buffer
    const buffer = audioContext.createBuffer(1, 1, 44100);
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    // Connect to output (speakers)
    source.connect(audioContext.destination);
    // Play sound
    if (source.start) {
      source.start(0);
    } else if (source.play) {
      source.play(0);
    } else if (source.noteOn) {
      source.noteOn(0);
    }
  }
  setupComplete = true;
};

export const muteAudioContext = () => {
  activeAudioObjects.forEach((a) => {
    try {
      a.bufferSource.disconnect(audioContext.destination);
    } catch (e) {}
  });
};
export const unmuteAudioContext = () => {
  activeAudioObjects.forEach((a) => {
    try {
      a.bufferSource.connect(audioContext.destination);
    } catch (e) {}
  });
};
