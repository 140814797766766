import React, { useContext, useEffect, useState, useRef } from "react";

import PlayButton from "../../../PlayButton/PlayButton";

import clsx from "clsx";
import Context from "../../../../context/Context";
import { createMarkup } from "../../../../utils";
import Styles from "./UseCase.module.scss";
import { motion } from "framer-motion";
import * as SITEDATA from '../../../../constants';

export default function UseCase({
  useCase,
  activeCase,
  handleSetActiveCase,
  audioProgress,
	playedUseCases
}) {
	const [complete, setComplete] = useState(false)
  const isActive = activeCase === useCase.title;
  const disabled = activeCase !== null && !isActive;
  const context = useContext(Context);
  const { setForceDefaultCursor, language, mobileLayout, languageID, siteID } = context;
  const CUTOFF_LANG = siteID ? SITEDATA[`${siteID}_CUTOFF_LANG`] : SITEDATA[`studioBuds_CUTOFF_LANG`]

	const textWrapperRef = useRef(null)
  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };
	useEffect(() => {
		if (playedUseCases.includes(useCase.title) && !complete) {
			setComplete(true)
		}
	}, [playedUseCases])

	function determineHeight() {
		return textWrapperRef.current.getBoundingClientRect().height
	}

  return (
    <motion.div 
		className={Styles.wrapper}
		>
		<div 
			className={clsx(Styles.opacityWrapper, disabled ? "use-case-disabled" : "", languageID === "JA_JP" ? Styles.japan : '')}
			style={{opacity: disabled ? 0.2 : 1}}
			onClick={() => handleSetActiveCase(useCase)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			>
        	<PlayButton 
				audioProgress={audioProgress} 
				isActive={isActive} 
				complete={complete}
				languageID = {languageID}
				/>
        		<h1 className={`${Styles.useCaseTitle} ${CUTOFF_LANG.includes(languageID) ? Styles.useCaseTitleCutoff : ''}`}>
					<span className={`${CUTOFF_LANG.includes(languageID) ? Styles.cutoff : ''} ${languageID === "JA_JP" ? Styles.japan : ''}`}>
						{language[useCase.title]}
					</span>
				</h1>
      	</div>
		
		<div 
			className={Styles.animWrapper}
			style={{height: isActive && mobileLayout ? `${determineHeight()}px` : mobileLayout ? '0px' : 'auto'}}
			>
			<div 
				ref={textWrapperRef} 
				className={Styles.textWrapper}
				>
				<p
					className={clsx(Styles.description, isActive ? Styles.active : null)}
					dangerouslySetInnerHTML={createMarkup(language[useCase.description])}
					/>
			</div>
		</div>
    </motion.div>
  );
}
