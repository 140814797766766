import * as SITEDATA from '../constants';


export function getProgress(progress, siteID) {
	const CHAPTERS = siteID ? SITEDATA[`${siteID}_CHAPTERS`] : SITEDATA[`studioBuds_CHAPTERS`]
	// console.log("progress",progress)
	if (Array.isArray(progress)) {
		//this is kiosk version
		return Math.min(
			100,
			Math.round(
				progress && siteID
					? (100 * progress.length) / CHAPTERS.reduce((acc, element) => acc + element.modules.length, 0)
					: 0
			)
		);
	} else {
		return Math.min(
			100,
			Math.round(
				progress && siteID
					? (100 * progress[siteID].length) / CHAPTERS.reduce((acc, element) => acc + element.modules.length, 0)
					: 0
			)
		);
	}

}
