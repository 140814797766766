import React, { useContext } from "react";
import Styles from "./ReplayButton.module.scss";
import Context from "../../context/Context";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

const ReplayButton = (props) => {
  const {
    attachToGlobalCanAdvance,
    onClick,
    videoElement,
    audioObject,
    style,
    className,
  } = props;
  const context = useContext(Context);
  const { canAdvance, language, setForceDefaultCursor, muted, playSound, theme, overrideTheme } =
    context;

  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };

  const replay = () => {
    if (videoElement?.play) {
      videoElement.play();
    }
    if (audioObject && !muted) {
      playSound(audioObject);
    }
    if (onClick) onClick();
  };

  return (
    <AnimatePresence>
      {(!attachToGlobalCanAdvance ||
        (attachToGlobalCanAdvance && canAdvance)) && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.15 }}
          key="replayButton"
          style={style}
          className={clsx(Styles.root, className)}
          onClick={replay}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            className={Styles.icon}
            src={`${process.env.PUBLIC_URL}/assets/images/replay-icon-${overrideTheme ? 'light' : theme ? theme : 'light'}.svg`}
            alt="Replay"
          />
          <span className={Styles.label}>{language["Global_UI_Replay"]}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ReplayButton;
