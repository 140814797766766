/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
// import { ReactSVG } from 'react-svg';

import BaseModule from '../BaseModule';
import NowPlaying from './components/NowPlaying';
import Hotspot from './components/Hotspot';
import Carousel from './components/Carousel';
import Styles from './ControlsModule.module.scss';
import { createMarkup } from '../../../utils';
import Context from '../../../context/Context';
import {
  detectInteraction,
  clearDetectInteraction,
} from '../../../utils/detectInteractions';

export default function ControlsModule(props) {
  const { module, variant, videoContext, introAudioFinished } = props;
  const { content, hotspotCoordinates, staticProductImage } = module;
  const [holdingDuration, setHoldingDuration] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isHolding, setIsHolding] = useState(false);
  const [currentTrack, setCurrentTrack] = useState();
  const [sandBoxMode, setSandBoxMode] = useState(false);
  const [lastActionPerformed, setlastActionPerformed] = useState(false);
  const [tracksVol, setTracksVol] = useState(0.23);
  const rewindSfx =
    Object.keys(content.sfx).length && content.sfx.find(s => s.id === 'rewind');
  const forwardSfx =
    Object.keys(content.sfx).length &&
    content.sfx.find(s => s.id === 'forward');

  const detectDeviceRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  let actionTimeout;

  const hotspotRef = useRef();
  const imageRef = useRef();

  const context = useContext(Context);

  const { setCanAdvance, canAdvance, language, muted, setForceDefaultCursor } =
    context;


  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };

  const fadeInAudio = audio => {
    setTimeout(() => {
      if (!(audio.volume >= tracksVol)) {
        audio.volume = audio.volume + tracksVol * 0.01;
        fadeInAudio(audio);
      }
    }, 10);
  };

  const handleNextStep = () => {
    if (!(activeStep + 1 === content.controls.length) && !sandBoxMode) {
      setActiveStep(prev => prev + 1);
    } else {
      setSandBoxMode(true);
    }
  };

  const findTrackIndex = currTrack => {
    const filterAudio = content.tracks.find(
      t => t.audio.audioElement === currTrack
    );
    const currentAudioIndex = content.tracks.indexOf(filterAudio);

    return currentAudioIndex;
  };

  const findNextTrackIndex = currTrack => {
    const currentAudioIndex = findTrackIndex(currTrack);
    const nextAudioIndex =
      currentAudioIndex + 1 >= content.tracks.length
        ? 0
        : currentAudioIndex + 1;

    return nextAudioIndex;
  };

  const findPrevTrackIndex = currTrack => {
    const currentAudioIndex = findTrackIndex(currTrack);
    const prevAudioIndex =
      currentAudioIndex - 1 < 0
        ? content.tracks.length - 1
        : currentAudioIndex - 1;

    return prevAudioIndex;
  };

  const handleAudioEnd = () => {
    const nextAudioIndex = findNextTrackIndex(currentTrack);

    const audio = content.tracks[nextAudioIndex].audio.audioElement;
    audio.currentTime = 0;
    const promise = audio.play();

    setCurrentTrack(audio);

    if (promise !== undefined) {
      promise
        .then(_ => {
          // Autoplay started!
          audio.volume = tracksVol;
          audio.muted = false;
          setIsPlaying(true);
        })
        .catch(error => {
          // Autoplay not allowed!
          audio.muted = true;
        });
    }
  };

  const handleIconState = () => {
    setlastActionPerformed(activeStep);

    actionTimeout = setTimeout(() => setlastActionPerformed(false), 1000);
  };

  const oneClickRule = () => {
    handleIconState();

    if (muted) {
      handleNextStep();
      return;
    }

    if (currentTrack.paused && activeStep === 0) {
      handleNextStep();
    }

    if (!currentTrack.paused && activeStep === 0) {
      currentTrack.pause();
      handleNextStep();
    }

    if (currentTrack.paused && activeStep !== 0) {
      currentTrack.muted = false;
      currentTrack.volume = 0;

      currentTrack.play();
      fadeInAudio(currentTrack);
      setIsPlaying(true);
      handleNextStep();
    }
  };

  const doubleClickRule = () => {
    handleIconState();

    const nextTrackIndex = findNextTrackIndex(currentTrack);
    const nextTrack = content.tracks[nextTrackIndex].audio.audioElement;

    if (muted) {
      handleNextStep();
      return;
    }

    if (nextTrack) {
      currentTrack.pause();
      currentTrack.currentTime = 0;

      setCurrentTrack(nextTrack);
      nextTrack.muted = false;
      nextTrack.play();

      handleNextStep();
    }
  };

  const tripleClickRule = () => {
    handleIconState();
    const prevTrackIndex = findPrevTrackIndex(currentTrack);
    const prevTrack = content.tracks[prevTrackIndex].audio.audioElement;

    if (muted) {
      handleNextStep();
      return;
    }

    if (prevTrack) {
      currentTrack.pause();
      currentTrack.currentTime = 0;

      setCurrentTrack(prevTrack);
      prevTrack.muted = false;
      prevTrack.play();

      handleNextStep();
    }
  };

  const doubleAndHoldRule = () => {
    currentTrack.removeEventListener('ended', handleAudioEnd);

    if (muted) {
      handleNextStep();
      return;
    }

    if (currentTrack) {
      if (rewindSfx) {
        rewindSfx.audioElement.muted = false;
        rewindSfx.audioElement.loop = true;
        rewindSfx.audioElement.volume = tracksVol;
        rewindSfx.audioElement.play();

        currentTrack.muted = true;
        setIsScanning(true);
      }
    }
  };

  const tripleAndHoldRule = () => {
    currentTrack.removeEventListener('ended', handleAudioEnd);

    if (muted) {
      handleNextStep();
      return;
    }

    if (currentTrack) {
      if (forwardSfx) {
        forwardSfx.audioElement.muted = false;
        forwardSfx.audioElement.loop = true;
        forwardSfx.audioElement.volume = tracksVol;
        forwardSfx.audioElement.play();

        currentTrack.muted = true;

        setIsScanning(true);
      }
    }
  };

  const handleInteraction = () => {
    if (currentTrack) {
      switch (content.controls[activeStep].interactionType) {
        case 'oneClick':
          oneClickRule();
          break;
        case 'doubleClick':
          doubleClickRule();
          break;
        case 'tripleClick':
          tripleClickRule();
          break;
        case 'doubleAndHold':
          doubleAndHoldRule();
          break;
        case 'tripleAndHold':
          tripleAndHoldRule();
          break;
        default:
          break;
      }
    }
  };

  const handleHolding = e => {
    setHoldingDuration(Date.now());
    setIsHolding(true);
  };
  const handleRelease = () => {
    setHoldingDuration(prev => prev && Date.now() - prev);
    setIsHolding(false);
  };

  useEffect(() => {
    if (
      content.controls[activeStep]?.interactionType === 'doubleAndHold' ||
      content.controls[activeStep]?.interactionType === 'tripleAndHold'
    ) {
      if (!isHolding && isScanning) {
        forwardSfx.audioElement.pause();
        rewindSfx.audioElement.pause();

        if (content.controls[activeStep]?.interactionType === 'tripleAndHold') {
          currentTrack.currentTime =
            currentTrack.currentTime - (holdingDuration / 1000) * 2;
        }

        if (content.controls[activeStep]?.interactionType === 'doubleAndHold') {
          currentTrack.currentTime =
            currentTrack.currentTime + (holdingDuration / 1000) * 2;
        }

        // currentTrack.play();
        // currentTrack.volume = tracksVol;
        currentTrack.muted = false;

        setIsScanning(!isScanning);
        handleIconState();
        handleNextStep();
      }
    }
  }, [holdingDuration, isHolding]);

  useEffect(() => {
    window.addEventListener('mousedown', handleHolding);
    window.addEventListener('mouseup', handleRelease);
    window.addEventListener('touchstart', handleHolding);
    window.addEventListener('touchend', handleRelease);
    if (detectDeviceRegex.test(navigator.userAgent)) {
      setTracksVol(0.06);
      window.addEventListener('touchstart', handleHolding);
      window.addEventListener('touchend', handleRelease);
    } else {
      window.addEventListener('mousedown', handleHolding);
      window.addEventListener('mouseup', handleRelease);
    }

    return () => {
      window.removeEventListener('mousedown', handleHolding);
      window.removeEventListener('mouseup', handleRelease);
      window.addEventListener('touchstart', handleHolding);
      window.addEventListener('touchend', handleRelease);
      videoContext && videoContext.clearRect(0, 0, 2560, 2560);
    };
  }, []);

  useEffect(() => {
    if (hotspotRef.current) {
      detectInteraction(
        content.controls[activeStep].interactionType,
        hotspotRef.current,
        () => handleInteraction()
      );
    }

    return () => {
      clearDetectInteraction();
    };
  }, [hotspotRef.current, activeStep, introAudioFinished, currentTrack]);

  useEffect(() => {
    if (currentTrack && !isScanning) {
      currentTrack.volume = tracksVol;
      currentTrack.addEventListener('ended', handleAudioEnd);
    }

    return () => {
      if (currentTrack) {
        currentTrack.removeEventListener('ended', handleAudioEnd);
        currentTrack.pause();
      }
    };
  }, [currentTrack]);

  useEffect(() => {
    if (introAudioFinished && content.tracks.length) {
      // console.log(sandBoxMode);
      //MOSLEY 11/10/2021 :: TEMPORARILY FORCING CAN ADVANCE TO TRUE

      // if (!forceCanAdvanceTrue) setCanAdvance(false);
      // if (sandBoxMode) {
      setCanAdvance(true);
      // }

      const firstAudio = content.tracks[0].audio.audioElement;
      setCurrentTrack(firstAudio);

      if (!muted) {
        const promise = firstAudio.play();

        if (promise !== undefined) {
          promise
            .then(_ => {
              // Autoplay started!
              firstAudio.volume = 0;

              fadeInAudio(firstAudio);

              firstAudio.muted = false;
              setIsPlaying(true);
            })
            .catch(error => {
              // Autoplay not allowed!
              firstAudio.muted = true;
            });
        }
      }
    }

    if (!introAudioFinished && currentTrack) {
      currentTrack.pause();
    }
  }, [introAudioFinished]);

  useEffect(() => {
    //MOSLEY 11/10/2021 :: TEMPORARILY FORCING CAN ADVANCE TO TRUE
    // if (sandBoxMode) {
    setCanAdvance(true);
    // } else {
    //   setCanAdvance(false);
    // }
  }, [sandBoxMode, canAdvance]);

  useEffect(() => {
    if (muted && currentTrack) {
      currentTrack.pause();
    }
  }, [muted]);

  useEffect(() => {
    return () => {
      clearTimeout(actionTimeout);
    };
  }, [lastActionPerformed]);

  function renderSteps() {
    return (
      <Carousel
        items={content.controls}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    );
  }

  function renderContent() {
    return (
      <div className={Styles.container}>
        {isPlaying && content.tracks[findTrackIndex(currentTrack)] && (
          <NowPlaying
            track={content.tracks[findTrackIndex(currentTrack)].info}
          />
        )}

        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(language[content.title])}
        />
        <div
          className={Styles.text}
          dangerouslySetInnerHTML={createMarkup(language[content.text])}
        />
        <div
          className={Styles.stepCTA}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.instructions]
          )}
        />

        {staticProductImage && (
          <div className={Styles.bgImageWrapper}>
            <div className={Styles.bgContent}>
              <img
                alt="bg"
                className="bg-image"
                src={process.env.PUBLIC_URL + staticProductImage}
                ref={imageRef}
              />
              {content.controls.length &&
                content.controls.map((c, cIndex) => {
                  return (
                    <div
                      key={c.bgIcon + cIndex}
                      className={`${Styles.bgIcon} ${
                        lastActionPerformed === cIndex && Styles.active
                      } ${c.bgIcon.invert && Styles.invert}`}
                    >
                      <img
                        src={process.env.PUBLIC_URL + c.bgIcon.path}
                        alt="icon"
                        style={c.bgIcon.customCss}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {module.content.disclaimer && (
          <div
            className={Styles.disclaimer}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}

        <div
          className={Styles.stepContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={Styles.stepWrapper}>{renderSteps()}</div>
        </div>

        {hotspotCoordinates && introAudioFinished && (
          <Hotspot
            ref={hotspotRef}
            hotspotCoordinates={hotspotCoordinates}
            origin={videoContext?.canvas}
          />
        )}
      </div>
    );
  }

  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        'ControlsModule'
      )}
    >
      {renderContent()}
    </BaseModule>
  );
}
