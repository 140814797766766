import React, { useState, useEffect, useContext, useRef } from 'react';
import Context from '../../context/Context';
import { AuthUserContext } from '../Session';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { TrackEvent } from '../../utils/track';

import Pill from '../Pill';
import { getProgress, createMarkup } from '../../utils';
import * as SITEDATA from '../../constants';
import { Link } from 'react-router-dom';
import { SubUserInfo } from '../SignIn';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import './menu.scss';
const Menu = props => {
  const { firebase, visible, progress } = props;
  const history = useHistory();

  const context = useContext(Context);
  const {
    siteID,
    isKioskVersion,
    progressKiosk,
    mobileLayout,
    ga4,
    setMenuActive,
    setOverrideTheme,
    language,
    languageID,
    setSilenceBackgroundAudio,
    // setTheme,
    currentChapter,
  } = context;
  const moduleWrapperRefs = useRef([]);
  const moduleContainerAnimRefs = useRef([]);
  const [openedChapter, setOpenedChapter] = useState(null);
  const [moreDetailsOpened, setMoreDetailsOpened] = useState(false);
  const CHAPTERS = siteID
    ? SITEDATA[`${siteID}_CHAPTERS`]
    : SITEDATA[`studioBuds_CHAPTERS`];
  const CUTOFF_LANG = siteID
    ? SITEDATA[`${siteID}_CUTOFF_LANG`]
    : SITEDATA[`studioBuds_CUTOFF_LANG`];
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  useEffect(() => {
    const currentChapter = () => {
      return history.location.pathname.split('/')[1];
    };
    async function setCurrentChapter() {
      if (visible) {
        const chapterId = currentChapter();
        await delay(350);
        if (chapterId) {
          setOpenedChapter(chapterId);
        }
      }
    }
    setMoreDetailsOpened(false);
    setOpenedChapter(null);
    setCurrentChapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const expandChapter = chapter => {
    TrackEvent(
      ga4,
      openedChapter === chapter
        ? 'menu_chapter_collapse_' + chapter
        : 'menu_chapter_expand_' + chapter,
      'click',
      'user_action'
    );
    setOpenedChapter(openedChapter === chapter ? null : chapter);
  };
  const menuMotionVariants = {
    open: {
      y: '0%',
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
        staggerChildren: 0.5,
      },
    },
    closed: {
      y: '-100%',
      transition: {
        duration: moreDetailsOpened ? 0.4 : 0.4,
        ease: 'easeInOut',
        delay: moreDetailsOpened ? 0 : 0.0,
      },
    },
  };

  const subMenuMotionVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 100, velocity: -100 },
      },
    },
    closed: {
      display: visible ? 'flex' : 'none',
      y: 200,
      opacity: 0.5,
      transition: {
        y: { stiffness: 100 },
      },
      transitionEnd: { display: 'none' },
    },
  };

  const goToModule = (chapter, id) => {
    closeMenu();
    TrackEvent(
      ga4,
      `menu_module_clicked_${chapter}_${id}`,
      'click',
      'user_action'
    );
  };
  const closeMenu = () => {
    setOverrideTheme(false);
    setMenuActive(false);
  };

  const settingsMotionVariants = {
    open: {
      display: 'flex',
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.05,
      },
    },
    closed: {
      flexDirection: mobileLayout ? 'column' : 'row',
      opacity: 0,
      transition: {
        duration: 0.3,
      },
      transitionEnd: { display: 'none' },
    },
  };

  const middleLineVariant = {
    open: {
      height: '100%',
      transition: { delay: 0.3, duration: 0.4 },
    },
    closed: {
      height: '0%',
      transition: { delay: 0, duration: 0.4 },
    },
  };

  const titleVariant = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: -100,
      opacity: 0,
    },
  };

  const moduleTitleVariant = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: -40,
      opacity: 0,
    },
  };

  const titleWrapperVariant = {
    open: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    closed: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  function isCompletedChapter(chapterId) {
    if (currentChapter && currentChapter.id === chapterId) return true;

    if (siteID && progressKiosk && progress) {
      const menuLengths = {};

      CHAPTERS.filter(
        page => page.id !== 'intro' && page.id !== 'outro'
      ).forEach(({ id, modules }, i) => {
        modules.map(() => {
          menuLengths[id] = menuLengths[id] > 0 ? menuLengths[id] + 1 : 1;
        });
      });

      const menuProgress = isKioskVersion ? progressKiosk : progress[siteID];

      const chapterProgress = menuProgress.filter(
        item => item.chapter === chapterId
      );

      if (chapterProgress.length === menuLengths[chapterId]) return true;
      else return false;
    }

    return false;
  }

  function determineClass(chapter) {
    if (!openedChapter) return 'hide';
    if (openedChapter === chapter) {
      return 'show';
    } else {
      return 'hide';
    }
  }

  function determineHeight(title) {
    const animEl = moduleContainerAnimRefs.current[title];
    let animHeight = animEl.getBoundingClientRect().height;
    // animHeight = animHeight >= 300 ? 300 : animHeight
    return animHeight;
  }
  // console.log("language",language)
  return (
    <div className={!visible ? null : null}>
      <AuthUserContext.Consumer>
        {({ authUser, firstName }) => {
          const goToSettings = () => {
            TrackEvent(
              ga4,
              openedChapter ? 'close_settings' : 'open_settings',
              'click',
              'user_action'
            );
            if (openedChapter) {
              setOpenedChapter(null);
            }
            setMoreDetailsOpened(true);
          };
          const completionStr = language['Global_CompletionMessage'].replace(
            '<<number>>',
            getProgress(progress, siteID)
          );
          const hiNameStr = language['Global_Greeting'].replace(
            '<<name>>',
            firstName
          );
          const signOut = () => {
            TrackEvent(ga4, 'sign_out', 'click', 'user_action');
            setSilenceBackgroundAudio(true);
            setMenuActive(false);
            if (firebase) {
              firebase.doSignOut();
            }
          };
          const goToLanguage = () => {
            setMenuActive(false);
            history.push({
              pathname: ROUTES.LANGUAGE,
              search: history.location.search,
            });
          };
          let chapterCount = 0;
          return authUser || isKioskVersion ? (
            <motion.div
              animate={visible ? 'open' : 'closed'}
              id="menu"
              className={visible ? 'open' : null}
              initial={{ y: '-150%' }}
              variants={menuMotionVariants}
            >
              <div className={'gradient'}></div>
              <div className="menu__contact-us">
                <Link
                  to="#"
                  onClick={e => {
                    window.location = 'mailto:beats_training@apple.com';
                    e.preventDefault();
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={createMarkup(
                      language['Global_Menu_Help']
                    )}
                  />
                </Link>
              </div>
              <motion.div
                animate={!moreDetailsOpened && visible ? 'open' : 'closed'}
                variants={subMenuMotionVariants}
                style={{ height: '100%', width: '100%' }}
                className="menuGrid"
              >
                <div className="menucol hello">
                  {isKioskVersion ? (
                    <motion.div
                      animate={{ opacity: visible ? 1 : 0 }}
                      transition={{
                        delay: moreDetailsOpened ? 0 : 0.3,
                        duration: 0.5,
                      }}
                      style={{ width: '100%' }}
                    >
                      <div className="hello-content">
                        <div className="button-wrapper kiosk">
                          <Pill action={goToLanguage}>
                            {language['Global_Menu_Language']}
                          </Pill>
                        </div>
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      animate={{ opacity: visible ? 1 : 0 }}
                      transition={{
                        delay: moreDetailsOpened ? 0 : 0.3,
                        duration: 0.5,
                      }}
                      style={{ width: '100%' }}
                    >
                      <div className="hello-content">
                        <div id="salutation">{hiNameStr}</div>
                        <div id="percent">{completionStr}</div>
                        <div id="salutation-mobile">{`${hiNameStr} ${completionStr}`}</div>
                        <div className="button-wrapper">
                          <Pill style="outline" action={goToSettings}>
                            {language['Global_UI_Settings']}
                          </Pill>
                          <Pill action={signOut}>
                            {language['Global_UI_Signout']}
                          </Pill>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </div>
                <motion.div
                  className="menu__middle-line"
                  animate={visible ? 'open' : 'closed'}
                  variants={middleLineVariant}
                />
                <div
                  className={clsx(
                    'menucol chapters',
                    openedChapter ? 'scroll' : ''
                  )}
                >
                  <motion.div
                    className={clsx(
                      'chapter-wrapper',
                      openedChapter ? 'scroll' : ''
                    )}
                    animate={visible ? 'open' : 'closed'}
                    variants={titleWrapperVariant}
                  >
                    {CHAPTERS.filter(
                      page => page.id !== 'intro' && page.id !== 'outro'
                    ).map(({ title, id, modules }, i) => {
                      const chapter = id;
                      chapterCount++;
                      return (
                        <motion.li
                          key={id}
                          animate={visible ? 'open' : 'closed'}
                          variants={titleVariant}
                          transition={{
                            delay: visible ? 0.3 + i * 0.01 : 0.1,
                            stiffness: 100,
                          }}
                        >
                          <div
                            className={clsx(
                              'chapterTitleWrapper',
                              CUTOFF_LANG.includes(languageID) ? 'cutoff' : '',
                              determineClass(chapter),
                              isCompletedChapter(id) ? 'completed' : ''
                            )}
                            id={id}
                            onClick={() => expandChapter(id)}
                          >
                            <span className="chapterTitle-chapter">
                              {language['Global_Chapter'].replace(
                                '<<number>>',
                                chapterCount
                              )}
                            </span>
                            <span
                              className={clsx(
                                'chapterTitle',
                                CUTOFF_LANG.includes(languageID) ? 'cutoff' : ''
                              )}
                            >
                              {language[title]}
                            </span>
                          </div>
                          <ul
                            id={id}
                            // animate={determineClass(chapter) === 'show' ? 'open' : 'closed'}
                            // variants={moduleListVariant}
                            className={clsx(
                              'chapter-list-wrapper',
                              openedChapter === chapter ? 'show' : ''
                            )}
                            ref={el => (moduleWrapperRefs.current[title] = el)}
                            style={{
                              height:
                                determineClass(chapter) === 'show' &&
                                !moreDetailsOpened
                                  ? `${determineHeight(title)}px`
                                  : '0px',
                            }}
                          >
                            <div
                              className="chapter-list-wrapper__anim-container"
                              ref={el =>
                                (moduleContainerAnimRefs.current[title] = el)
                              }
                            >
                              {modules
                                .filter(page => page.id !== 'intro')
                                .map(({ title, id }) => {
                                  const clickable = isKioskVersion
                                    ? progressKiosk &&
                                      progressKiosk.some(
                                        e =>
                                          e.chapter.id === chapter &&
                                          e.moduleID === id
                                      )
                                    : progress &&
                                      siteID &&
                                      progress[siteID].some(
                                        e =>
                                          e.chapter === chapter &&
                                          e.moduleID === id
                                      );
                                  return (
                                    <motion.li
                                      key={id}
                                      className="moduleTitle"
                                      variants={moduleTitleVariant}
                                      animate={
                                        determineClass(chapter) === 'show'
                                          ? 'open'
                                          : 'closed'
                                      }
                                      transition={{
                                        duration: 0.2,
                                        delay: 0.07,
                                      }}
                                    >
                                      {(() => {
                                        if (clickable) {
                                          return (
                                            <Link
                                              to={`${process.env.PUBLIC_URL}/${chapter}/${id}`}
                                              onClick={() =>
                                                goToModule(chapter, id)
                                              }
                                              className="moduleTitle__link"
                                            >
                                              <span
                                                className="moduleTitle__text"
                                                dangerouslySetInnerHTML={
                                                  createMarkup(language[title]
                                                    ? language[title]
                                                    : 'title tbd')
                                                }
                                              />

                                              <img
                                                alt="arrow"
                                                className="module-title__arrow"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  '/assets/images/chevron-right-white.svg'
                                                }
                                              />
                                            </Link>
                                          );
                                        } else {
                                          return (
                                            <div className="moduleTitle__text moduleTitle__inactive">
                                              {language[title]
                                                ? language[title]
                                                : 'title tbd'}
                                            </div>
                                          );
                                        }
                                      })()}
                                    </motion.li>
                                  );
                                })}
                            </div>
                          </ul>
                        </motion.li>
                      );
                    })}
                    <motion.div
                      className="menu__restart-experience"
                      animate={{ opacity: visible ? 1 : 0 }}
                      initial={{ opacity: 0 }}
                    >
                      <ul>
                        <li>
                          <Link to={ROUTES.INTRO} onClick={() => closeMenu()}>
                            {language['Global_Menu_Button_Restart']
                              ? language['Global_Menu_Button_Restart']
                              : language['Global_Menu_Restart']
                              ? language['Global_Menu_Restart']
                              : 'Restart Experience'}
                          </Link>
                        </li>
                        {isKioskVersion ? null : (
                          <li>
                            <Link
                              to={ROUTES.PRODUCTSELECTOR}
                              onClick={() => closeMenu()}
                            >
                              {language['Global_Menu_Button_SelectProduct']
                                ? language['Global_Menu_Button_SelectProduct']
                                : 'Select Another Product'}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </motion.div>

                    <motion.div
                      animate={{ opacity: visible ? 1 : 0 }}
                      className="menu__contact-us--mobile"
                      initial={{ opacity: 0 }}
                    >
                      <Link
                        to="#"
                        onClick={e => {
                          window.location = 'mailto:beats_training@apple.com';
                          e.preventDefault();
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={createMarkup(
                            language['Global_Menu_Help']
                          )}
                        />
                      </Link>
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>
              {isKioskVersion ? null : (
                <motion.div
                  animate={moreDetailsOpened ? 'open' : 'closed'}
                  variants={settingsMotionVariants}
                  initial={{ display: 'none', opacity: 0 }}
                >
                  <SubUserInfo
                    {...props}
                    setMoreDetailsOpened={setMoreDetailsOpened}
                    settingsForm={true}
                    authUser={authUser}
                    language={language}
                  />
                </motion.div>
              )}
            </motion.div>
          ) : null;
        }}
      </AuthUserContext.Consumer>
    </div>
  );
};

export default Menu;
