import React, { useEffect, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import Context from '../../../context/Context';
import Styles from './BaseModule.module.scss';
import * as SITEDATA from '../../../constants';
import useCanvasSize from '../../../hooks/useCanvasSize';
import { TrackPage } from '../../../utils/track';
import {
  preloadModuleVideoAndAudio,
  unloadModuleVideoAndAudio,
} from '../../Chapter/ChapterPreloader';

let canvasInterval;

export default function BaseModule(props) {
  const context = useContext(Context);
  const CANVAS_SIZE = useCanvasSize();

  const {
    setCanAdvance,
    muted,
    setVideoInProgressPercent,
    setSilenceBackgroundAudio,
    isKioskVersion,
    setProgressKiosk,
    progressKiosk,
    ga4,
    playSound,
    languageID,
    siteID,
    setTheme,
  } = context;
  const {
    progress,
    className,
    chapter,
    module,
    firebase,
    videoContext,
    setIntroVideoFinished,
    setIntroAudioFinished,
    history,
    lastVisited,
  } = props;

  const CHAPTERS = siteID
    ? SITEDATA[`${siteID}_CHAPTERS`]
    : SITEDATA[`studioBuds_CHAPTERS`];

  const [preloadChanged, setPreloadChanged] = useState(module.preloaded);

  const { silenceBackgroundAudio } = module;

  const thisModuleIndex = chapter.modules ? chapter.modules.indexOf(module) : 0;
  const nextModuleIndex = thisModuleIndex + 1;
  let nextModule = chapter.modules
    ? chapter.modules.length > nextModuleIndex
      ? chapter.modules[nextModuleIndex]
      : undefined
    : undefined;

  const thisChapterIndex = CHAPTERS.indexOf(chapter);
  const nextChapterIndex = thisChapterIndex + 1;
  let nextChapter =
    CHAPTERS.length > nextChapterIndex ? CHAPTERS[nextChapterIndex] : undefined;

  const forceCanAdvanceTrue =
    progress &&
    progress[siteID].some(
      e => e.chapter === chapter.id && e.moduleID === module.id
    );

  const clearVideoFrame = () => {
    if (videoContext) {
      videoContext.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
    }
  };

  const drawVideoFrame = (video, isLoop) => {
    if (videoContext) {
      videoContext.drawImage(video, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
      if (!isLoop) {
        setVideoInProgressPercent(video.currentTime / video.duration);
      }
    }
  };

  useEffect(() => {
    if (module?.content?.video) {
      setIntroVideoFinished(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("BASEMODULE useEffect")
    if (module.preloadedLanguageID !== languageID) {
      unloadModuleVideoAndAudio(module);
      preloadModuleVideoAndAudio(
        module,
        languageID,
        CANVAS_SIZE <= 1280,
        () => {
          setPreloadChanged(true);
        }
      );
    }
    return () => {
      unloadModuleVideoAndAudio(module);
      clearVideoFrame();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module, languageID, CANVAS_SIZE]);

  useEffect(() => {
    let resizingTimeout;

    if (preloadChanged) {
      // timeout to capture when the resizing ended
      resizingTimeout = setTimeout(() => {
        setPreloadChanged(CANVAS_SIZE);
      }, 100);
    }

    return () => {
      clearTimeout(resizingTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CANVAS_SIZE]);

  useEffect(() => {
    setSilenceBackgroundAudio(Boolean(silenceBackgroundAudio));
  }, [silenceBackgroundAudio, setSilenceBackgroundAudio]);

  // Effect for Module Video and Video Loop

  useEffect(() => {
    setTheme(module?.theme ? module.theme : 'light');
    if (module.preloadedLanguageID === languageID) {
      // console.log ("should this happen yet?")
      if (
        !forceCanAdvanceTrue &&
        (module.content.video || (module.content.voiceover && !muted))
      ) {
        setCanAdvance(false);
      }
      if (module.content.video) {
        // console.log("module.content.video",module.content.video)
        const video = module.content.video.videoElement;
        const loop = module.content.loop
          ? module.content.loop.videoElement
          : null;
        if (video) {
          // console.log("if video true",video)
          video.onpause = () => {
            clearInterval(canvasInterval);
          };
          video.onended = () => {
            // console.log("video.onended ",loop)
            setIntroVideoFinished(true);
            setCanAdvance(true);
            clearInterval(canvasInterval);
            video.currentTime = 0;
            video.pause();

            if (loop) {
              loop.currentTime = 0;
              loop.pause();
              loop.play();
              // console.log('loop should start');
            }
            if (module.nextOnComplete) {
              history.push(
                nextModule
                  ? `${
                      process.env.PUBLIC_URL
                    }/${chapter.id.toLowerCase()}/${nextModule.id.toLowerCase()}`
                  : `${process.env.PUBLIC_URL}/${nextChapter.id.toLowerCase()}`
              );
            }
          };
          video.onplay = () => {
            setIntroVideoFinished(false);
            clearInterval(canvasInterval);
            canvasInterval = window.setInterval(() => {
              drawVideoFrame(video);
            }, 1000 / 30);
          };
          if (loop) {
            loop.onplay = () => {
              // console.log("looponplay")
              setCanAdvance(true); // HACK. This shouldn't be necessary as setCanAdvance(true) is called on video end, but getting mysterious advancement locks on iPhone
              clearInterval(canvasInterval);
              canvasInterval = window.setInterval(() => {
                drawVideoFrame(loop, true);
              }, 1000 / 30);
            };

            loop.onpause = () => {
              clearInterval(canvasInterval);
            };
          }

          video.play();
        }
      } else {
        if (module.content.videoFrames) {
          // do not clear canvas
        } else {
          clearVideoFrame();
        }
        if (!(module.content.voiceover && !muted)) {
          setCanAdvance(true);
        }
      }
      return () => {
        if (module.content.video) {
          setIntroVideoFinished(false);
        }
        if (module.preloadedLanguageID) {
          if (module.content.video) {
            if (module.content.video.videoElement) {
              module.content.video.videoElement.currentTime = 0;
              module.content.video.videoElement.pause();
            } else {
              //reload this module
              // console.log(`try this..... ${process.env.PUBLIC_URL}${chapter.id}/${module.id}`)

              window.location.replace(
                `${process.env.PUBLIC_URL}/${chapter.id}/${module.id}`
              );
            }
          }
          if (module.content.loop) {
            module.content.loop.videoElement.currentTime = 0;
            module.content.loop.videoElement.pause();
          }
        }
        clearInterval(canvasInterval);
        // clearVideoFrame();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module, preloadChanged, videoContext, CANVAS_SIZE, languageID]);

  // Effect for Module Audio must come after effect for video for proper canAdvance checking
  useEffect(() => {
    let progressInterval;
    let startedAt;

    const audioObject = module?.content?.voiceover;
    if (module.preloadedLanguageID === languageID && audioObject) {
      const buffer = audioObject?.buffer;

      const handleAudioFinished = () => {
        clearInterval(progressInterval);
        checkCanAdvance();
        setIntroAudioFinished(true);
      };

      const handleAudioProgress = () => {
        const elapsed = Date.now() - startedAt;
        const pct = Math.min(elapsed / (buffer.duration * 1000), 1);
        setVideoInProgressPercent(pct);
        if (elapsed >= buffer.duration * 1000) {
          handleAudioFinished();
        }
      };

      const checkCanAdvance = () => {
        if (!module?.content?.video && !module?.blockAudioAdvance) {
          setCanAdvance(true);
        }
      };

      if (!muted && audioObject) {
        playSound(audioObject);
        if (!module?.content?.video) {
          setIntroAudioFinished(false);
          startedAt = Date.now();
          progressInterval = window.setInterval(handleAudioProgress, 1000 / 30);
        } else {
          setIntroAudioFinished(true);
        }
      } else {
        setIntroAudioFinished(true);
        clearInterval(progressInterval);
        checkCanAdvance();
      }
    }

    return () => {
      startedAt = null;
      clearInterval(progressInterval);
      setVideoInProgressPercent(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muted, module, playSound, preloadChanged, languageID]);

  useEffect(() => {
    //iskioskversion
    if (chapter.id !== 'welcome') {
      if (isKioskVersion) {
        //NO AUTH IN THIS VERSION.  USE SESSION VALUES
        let currentprogress = progressKiosk;
        const progressObj = { chapter: chapter, moduleID: module.id };

        if (
          !currentprogress.some(
            e => e.chapter === chapter && e.moduleID === module.id
          )
        ) {
          currentprogress.push(progressObj);
        }
        setProgressKiosk(currentprogress);
      } else {
        // console.log('-------lastVisited', lastVisited);

        firebase.doUpdateProgress(
          chapter.id,
          module.id,
          progress,
          lastVisited,
          siteID
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter, firebase, module, progress]);

  useEffect(() => {
    TrackPage(ga4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.25, ease: 'easeOut' }}
      className={clsx(Styles.root, className)}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}${
          module.backgroundImage ? module.backgroundImage.path : null
        })`,
        backgroundSize: 'cover',
        backgroundColor: module.backgroundColor,
      }}
    >
      {props.children}
    </motion.div>
  );
}
