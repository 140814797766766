import React, {useContext} from "react";
import Context from '../../../../context/Context';
import ChevronRightLight from "../../../../img/chevron-right.svg"
import ChevronRightDark from "../../../../img/chevron-right-dark.svg"

export default function NextChapterButton(props) {
  const { language } = props;
  const context = useContext(Context)
  const { theme, overrideTheme } = context
  return (
    <button className={`ctaButton centerBottom ${overrideTheme ? "light" : theme ? theme : "light"}`}>
      {`${language["Global_UI_NextChapter"]}`} <img className="chevronRight" src={overrideTheme ? ChevronRightLight : theme && theme==="dark" ? ChevronRightDark : ChevronRightLight} alt="Chevron Right" />
    </button>
  );
}
