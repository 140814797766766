const COUNTRIES = [
	'Global_Country_ALGERIA',
	'Global_Country_ANDORRA',
	'Global_Country_ANGOLA',
	'Global_Country_ALBANIA',
	'Global_Country_ARGENTINA',
	'Global_Country_ARMENIA',
	'Global_Country_ARUBA',
	'Global_Country_AUSTRALIA',
	'Global_Country_AUSTRIA',
	'Global_Country_AZERBAIJAN',
	'Global_Country_BAHAMAS',
	'Global_Country_BAHRAIN',
	'Global_Country_BANGLADESH',
	'Global_Country_BARBADOS',
	'Global_Country_BELARUS',
	'Global_Country_BELGIUM',
	'Global_Country_BOLIVIA',
	'Global_Country_BOSNIAHERZRGOVINA',
	'Global_Country_BOTSWANA',
	'Global_Country_BRAZIL',
	'Global_Country_BRUNEI',
	'Global_Country_BULGARIA',
	'Global_Country_CAMBODIA',
	'Global_Country_CAMEROON',
	'Global_Country_CANADA',
	'Global_Country_CAYMANISLANDS',
	'Global_Country_CHILE',
	'Global_Country_CHINA',
	'Global_Country_COLOMBIA',
	'Global_Country_COOKISLANDS',
	'Global_Country_COSTARICA',
	'Global_Country_CROATIA',
	'Global_Country_CYPRUS',
	'Global_Country_CZECHREPUBLIC',
	'Global_Country_DENMARK',
	'Global_Country_DOMINICANREPUBLIC',
	'Global_Country_ECUADOR',
	'Global_Country_EGYPT',
	'Global_Country_ELSALVADOR',
	'Global_Country_ESTONIA',
	'Global_Country_FAROEISLANDS',
	'Global_Country_FIJI',
	'Global_Country_FINLAND',
	'Global_Country_FRANCE',
	'Global_Country_FRENCHGUIANA',
	'Global_Country_FRENCHPOLYNESIA',
	'Global_Country_GEORGIA',
	'Global_Country_GERMANY',
	'Global_Country_GHANA',
	'Global_Country_GIBRALTAR',
	'Global_Country_GREECE',
	'Global_Country_GUADELOUPE',
	'Global_Country_GUAM',
	'Global_Country_GUATEMALA',
	'Global_Country_GUERNSEY',
	'Global_Country_GUINEA',
	'Global_Country_GUYANA',
	'Global_Country_HONDURAS',
	'Global_Country_HONGKONG',
	'Global_Country_HUNGARY',
	'Global_Country_ICELAND',
	'Global_Country_INDIA',
	'Global_Country_INDONESIA',
	'Global_Country_IRAQ',
	'Global_Country_IRELAND',
	'Global_Country_ISLEOFMAN',
	'Global_Country_ISRAEL',
	'Global_Country_ITALY',
	'Global_Country_IVORYCOAST',
	'Global_Country_JAPAN',
	'Global_Country_JERSEY',
	'Global_Country_JORDAN',
	'Global_Country_KAZAKHSTAN',
	'Global_Country_KENYA',
	'Global_Country_KOREA',
	'Global_Country_KUWAIT',
	'Global_Country_KYRGYZSTAN',
	'Global_Country_LAOS',
	'Global_Country_LATVIA',
	'Global_Country_LEBANON',
	'Global_Country_LESOTHO',
	'Global_Country_LITHUANIA',
	'Global_Country_LUXEMBOURG',
	'Global_Country_MACAU',
	'Global_Country_MACEDONIA',
	'Global_Country_MADAGASCAR',
	'Global_Country_MALAWI',
	'Global_Country_MALAYSIA',
	'Global_Country_MALDIVES',
	'Global_Country_MALTA',
	'Global_Country_MARTINIQUE',
	'Global_Country_MAURITIUS',
	'Global_Country_MAYOTTE',
	'Global_Country_MEXICO',
	'Global_Country_MOLDOVA',
	'Global_Country_MONACO',
	'Global_Country_MONTENEGRO',
	'Global_Country_MOROCCO',
	'Global_Country_MOZAMBIQUE',
	'Global_Country_MYANMAR',
	'Global_Country_NAMIBIA',
	'Global_Country_NEPAL',
	'Global_Country_NETHERLANDS',
	'Global_Country_NEWCALEDONIA',
	'Global_Country_NEWZEALAND',
	'Global_Country_NICARAGUA',
	'Global_Country_NIGERIA',
	'Global_Country_NORWAY',
	'Global_Country_OMAN',
	'Global_Country_PAKISTAN',
	'Global_Country_PALESTINIANTERRITORY',
	'Global_Country_PANAMA',
	'Global_Country_PARAGUAY',
	'Global_Country_PERU',
	'Global_Country_PHILIPPINES',
	'Global_Country_POLAND',
	'Global_Country_PORTUGAL',
	'Global_Country_PUERTORICO',
	'Global_Country_QATAR',
	'Global_Country_REUNION',
	'Global_Country_ROMANIA',
	'Global_Country_RUSSIA',
	'Global_Country_SAINTMARTIN',
	'Global_Country_SANMARINO',
	'Global_Country_SAUDIARABIA',
	'Global_Country_SENEGAL',
	'Global_Country_SERBIA',
	'Global_Country_SINGAPORE',
	'Global_Country_SINTMAARTEN',
	'Global_Country_SLOVAKIA',
	'Global_Country_SLOVENIA',
	'Global_Country_SOUTHAFRICA',
	'Global_Country_SPAIN',
	'Global_Country_SRILANKA',
	'Global_Country_SWEDEN',
	'Global_Country_SWITZERLAND',
	'Global_Country_TAIWAN',
	'Global_Country_TAJIKISTAN',
	'Global_Country_TANZANIA',
	'Global_Country_THAILAND',
	'Global_Country_TRINIDADTOBAGO',
	'Global_Country_TUNISIA',
	'Global_Country_TURKEY',
	'Global_Country_TURKMENISTAN',
	'Global_Country_UGANDA',
	'Global_Country_UKRAINE',
	'Global_Country_UAE',
	'Global_Country_UK',
	'Global_Country_US',
	'Global_Country_URUGUAY',
	'Global_Country_UZBEKISTAN',
	'Global_Country_VENEZUELA',
	'Global_Country_VIETNAM',
	'Global_Country_ZAMBIA',
	'Global_Country_ZIMBABWE',
];
export default COUNTRIES;
