const copy = {
  Global_Menu_Confirm: 'Confirm',
  Global_UI_Next: 'Next',
  Global_UI_Begin: 'Begin',
  Global_UI_Back: 'Back',
  Global_UI_Settings: 'Settings',
  Global_UI_Signout: 'Sign Out',
  Global_UI_NextChapter: 'Next Chapter',
  Global_UI_PercentageComplete: '<<number>>% COMPLETE',
  Global_UI_Replay: 'Replay',
  Global_Languages_Select: 'CHOOSE YOUR LANGUAGE',
  Global_Field_EnterEmail: 'Enter email address',
  Global_Field_CreatePassword: 'Create Password',
  Global_Field_Password: 'Password',
  Global_Field_Password_Forgot: 'Forgot Password',
  Global_Password_Subtitle:
    'PASSWORD SHOULD BE 8+ CHARACTERS, WITH AT LEAST ONE UPPERCASE, ONE LOWERCASE LETTER, NUMBER, AND ONE SYMBOL (!@#$, ETC)',
  Global_Password_Change: 'CHANGE',
  Global_Password_ChangeConfirm: 'Password updated',
  Global_Password_Show: 'SHOW',
  Global_Password_Hide: 'HIDE',
  Global_Password_Reset_Headline: 'Reset password',
  Global_Password_Reset_Subtitle: 'Enter your email to receive instructions on how to reset your password.',
  Global_Password_Reset_Confirm: 'Sent! Check your email.',
  Global_Details_Title: 'A few more details',
  Global_Details_Field_FirstName: 'First name',
  Global_Details_Field_LastName: 'Last name',
  Global_Details_Field_EmployeeID: 'Employee ID (Optional)',
  Global_Details_Field_BusinessRole: 'Business Role',
  Global_Details_Field_BusinessRole1: 'Manager',
  Global_Details_Field_BusinessRole2: 'Sales Person',
  Global_Details_Field_BusinessRole3: 'Trainer',
  Global_Details_Field_CompanyName: 'Company Name:',
  Global_Details_Field_Country: 'Country',
  Global_Field_Password_Error: 'The password you entered is incorrect. Please try again.',
  Global_Field_Password_CreateError: 'Password does not meet minimal requirements.',
  Global_Field_Email_Error: 'You have entered an invalid email address. Please try again.',
  Global_Field_Details_Error: 'Please fill out all required fields.',
  Global_Greeting: 'Hi <<name>>,',
  Global_CompletionMessage: 'You are <<number>>% complete',
  Global_Chapter: 'CH <<number>>',
  Global_Chapter_Subtitle: 'CH <<number>> OF <<total>>',
  Global_Intro: 'Intro',
  Global_ChapterTitle_Sound: 'Sound',
  Global_ChapterTitle_ANC: 'ANC',
  Global_ChapterTitle_Transparency: 'Transparency',
  Global_ChapterTitle_Design: 'Design',
  Global_ChapterTitle_Compatibility: 'Compatibility',
  Global_ChapterTitle_Controls: 'Controls',
  Global_ChapterTitle_Battery: 'Battery',
  Global_Menu: 'Menu',
  CR_TRNR_Intro_Sorry: 'We’re sorry. Something went wrong. Please contact your supervisor for a new access link.',

  Intro_Welcome_ChapterText: 'Welcome to the Beats Fit Pro experience. Click or tap through and discover.',
  Intro_Intro_Subtitle:
    'Introducing Beats Fit Pro. True wireless, noise cancelling earbuds with studio quality sound. Click or tap through to explore.',
  Sound_Intro_Chapter: 'replace this comment',
  Sound_Intro_Title: 'Sound',
  Sound_Intro_VOSubtitle: 'Advanced engineering delivers powerful, balanced sound.',
  Sound_ChapterText1: 'Great sound starts with a <em>customized fit and comfortable acoustic seal.</em>',
  Sound_FindYourFit_Title: 'Find your fit. Feel the music.',
  Sound_FindYourFit_Subtitle:
    'Three silicone eartip sizes offer a personalized fit and acoustic seal for immersive audio.',
  Sound_FindYourFit_Medium: 'replace this comment',
  Sound_FindYourFit_Small: 'replace this comment',
  Sound_FindYourFit_Large: 'replace this comment',
  Sound_ChapterText2:
    'Beats Fit Pro are engineered to deliver <em>powerful, balanced sound</em> in a compact design, through an <em>entirely customized acoustic platform.</em>',
  Sound_DrivenByPremiumSound_Title: 'Driven by premium sound',
  Sound_DrivenByPremiumSound_Subtitle:
    'A custom acoustic platform featuring a proprietary, dual-element diaphragm driver produces clear, balanced sound.',
  Sound_MoreMusicLessDistortion_Title: 'Clear sound. Low distortion.',
  Sound_MoreMusicLessDistortion_Subtitle:
    'The flexible material of the driver diaphragm produces low vibrational distortion, resulting in clear, accurate sound across the entire spectrum—especially in the bass range.',
  Sound_MoreMusicLessDistortion_Diaphragm: 'DIAPHRAGM',
  Sound_FineTunedAudio_Title: 'Fine-tuned audio',
  Sound_FineTunedAudio_Subtitle:
    'A Digital Processor with playback correction responds in real-time to ensure that external sounds won’t affect the clarity or emotion of your music.',
  Sound_ChapterText3:
    'Phone calls sound clear with <em>high-quality call performance and elevated voice clarity.</em> ',
  Sound_SpeakingOfSound_Title: 'High-quality call performance',
  Sound_SpeakingOfSound_Subtitle:
    'A specially designed port isolates the microphone from external wind noise, while dual-beam forming microphones target your voice for elevated voice clarity.',
  Sound_Summary_Title: 'Engineered to deliver music as the artist intended',
  Sound_Summary1_ThreeEartip: 'Three eartip options offer a customized fit for great sound.',
  Sound_Summary2_CustomAcoustic: 'Custom acoustic platform delivers high-quality, powerful sound.',
  Sound_Summary3_FlexibleDriverDiaphragm: 'Flexible, driver diaphragm produces clear, accurate sound.',
  Sound_Summary4_FinetunedAudio: 'Fine-tuned audio preserves the clarity and emotion of your music.',
  Sound_Summary5_SpeakingOfSound: 'Dual-beam forming microphones target your voice for high-quality call performance.',
  ANC_Intro_Chapter: 'replace this comment',
  ANC_Intro_TITLE: 'Active Noise Cancelling',
  ANC_Intro_VOSubtitle: 'Stay focused and block out distractions with Active Noise Cancelling.',
  ANC_ChapterText1:
    '<em>A comfortable acoustic seal</em> helps block external noise for a truly immersive listening experience.',
  ANC_Seal_Title: 'Acoustic Seal',
  ANC_Seal_Subtitle:
    'Three eartip sizes let you choose a comfortable fit to create an acoustic seal that blocks unwanted noise.',
  ANC_ChapterText2:
    'ANC with adaptive technology <em>adjusts to your surroundings</em> while Playback Correction <em>preserves the clarity and emotion of your music.</em>',
  ANC_ANCThatAdapts_Title: 'ANC that adapts',
  ANC_ANCThatAdapts_Subtitle:
    'A finely-tuned filter dynamically adjusts to filter unwanted noise and optimize noise cancellation in real time.',
  ANC_ANCThatAdapts_AdaptiveANC: 'replace this comment',
  ANC_Playback_Title: 'Playback Correction',
  ANC_Playback_Subtitle:
    'When noise cancelling is applied, Playback Correction maps that audio file back to the original at 48,000 times a second, ensuring that ANC never interferes with your music.',
  ANC_Playback_ANCProcess: 'replace this comment',
  ANC_Playback_PlaybackCorrection: 'replace this comment',
  ANC_UseCases_Subtitle: 'Click or tap each use case to learn more. ',
  ANC_UseCases_Studying: 'Studying',
  ANC_UseCases_Studying_Subtitle: 'Block out distractions so you can stay focused on your work',
  ANC_UseCases_WorkingOut: 'Working Out',
  ANC_UseCases_WorkingOut_Subtitle: 'Immerse yourself in your workout without any distractions',
  ANC_UseCases_Airplane: 'Airplane',
  ANC_UseCases_Airplane_Subtitle: 'Quiet the sounds of passengers or a loud engine to better enjoy your flight',
  ANC_UseCases_Commute: 'Commute',
  ANC_UseCases_Commute_Subtitle: 'Commute with less of the city’s background noise',
  ANC_Summary_Title: 'Stay focused with Active Noise Cancelling.',
  ANC_Summary1_ImmerseSound: 'Immersive sound starts with a comfortable acoustic seal.',
  ANC_Summary2_AdaptiveTechnology: 'Adaptive technology continuously adjusts to your surroundings.',
  ANC_Summary3_PlaybackCorrection: 'Playback correction preserves the clarity of your sound.',
  ANC_Summary4_UseCases: 'Use ANC to focus while studying, working out, for airplane travel, or commuting.',
  Transparency_Intro_Chapter: 'replace this comment',
  Transparency_Intro_Title: 'TRANSPARENCY MODE',
  Transparency_Intro_VOSubtitle: 'Let the world in with Transparency mode.',
  Transparency_ChapterText1: 'Switch to Transparency mode when you need to <em>hear the world around you.</em>',
  Transparency_TransparencyMode_Title: 'Transparency mode',
  Transparency_TransparencyMode_Subtitle:
    'Transparency modes mixes the sounds of your surroundings back in with your music—for a natural, ‘open’ listening experience.',
  Transparency_TransparencyMode_PressHold: 'PRESS + HOLD',
  Transparency_ChapterText2: '<em>External microphones</em> listen for outside noise.',
  Transparency_FeedForwardMic_Title: 'Feed-forward microphones',
  Transparency_FeedForwardMic_Subtitle:
    'A feed-forward microphone on each earbud detects external noises in your environment.',
  Transparency_FeedForwardMic_FeedForwardMic: 'FEED-FORWARD MICROPHONE',
  Transparency_UseCases_Subtitle: 'Click or tap each use case to learn more.',
  Transparency_UseCases_Office: 'Office',
  Transparency_UseCases_Office_Subtitle: 'Stay collaborative at work and hear your coworkers while you listen to music',
  Transparency_UseCases_Travel: 'Travel',
  Transparency_UseCases_Travel_Subtitle: 'Listen for important announcements at an airport gate or train platform.',
  Transparency_UseCases_Street: 'Street',
  Transparency_UseCases_Street_Subtitle: 'Stay aware of surrounding traffic and busy intersections.',
  Transparency_Summary_Title: 'Let the world back in with Transparency mode.',
  Transparency_Summary1_MixTheSounds: 'Mix the sounds of your surroundings back in with your music',
  Transparency_Summary2_FeedForward: 'Feed-forward microphones detect external noises in your environment.',
  Transparency_Summary3_HearYourSurroundings:
    'Hear your surroundings at the office, while travelling, or on the street.',
  Design_Intro_Chapter: 'Replace this text',
  Design_Intro_Title: 'Design',
  Design_Intro_VOSubtitle: 'Beats Fit Pro are designed for truly wireless freedom.',
  Design_ChapterText1: 'The wireless, compact design <em>fits seamlessly into your life.</em>',
  Design_WirelessFreedom_Title: 'Wireless freedom',
  Design_WirelessFreedom_Subtitle: 'True wireless earbuds don’t hold you back.',
  Design_PocketSizedPortability_Titles: 'Pocket-sized portability',
  Design_PocketSizedPortability_Subtitles:
    'A slim, lightweight charging case offers superior portability and slips right into your pocket.',
  Design_WaterAndSweatResistant_Title: 'Sweat & water resistant earbuds<sup>*</sup> ',
  Design_WaterAndSweatResistant_Subtitle:
    'The earbuds are IPX-4 rated for sweat and water resistance to keep you going through the elements.',
  Design_WaterAndSweatResistant_Disclaimer:
    '<sup>*</sup> Beats Fit Pro are sweat and water resistant for non-water sports and exercise.',
  Design_ChapterText2:
    'Beats Fit Pro are tested across a wide audience to ensure a <em>comfortable, versatile fit.</em>',
  Design_Comfort_Title: 'Designed for comfort',
  Design_Comfort_Subtitle:
    'Soft, silicone earbuds are designed around the ear’s anatomy to provide comfort, stability, and the confidence of staying put.',
  Design_Comfort_Tragus: 'TRAGUS',
  Design_Comfort_Antitragus: 'ANTITRAGUS',
  Design_SizeMatters_Title: 'Compact Design',
  Design_SizeMatters_Subtitle:
    'The sleek, compact in-ear design is significantly lighter and smaller than most wireless earbuds today.',
  Design_SizeMatters_BeatsStudio: 'BEATS FLEX PRO',
  Design_SizeMatters_BeatsStudio_10_1G: '10.1G',
  Design_SizeMatters_Bose: 'BOSE QC EARBUDS',
  Design_SizeMatters_Bose_18_7G: '18.7G',
  Design_SizeMatters_Sony: 'Sony WF-1000XM3',
  Design_SizeMatters_Sony_16_5G: '16.5G',
  Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
  Design_SizeMatters_Samsung_12_5G: '12.5G',
  Design_ChapterText3:
    'Earbuds stay <em>secure and protected</em> in a convenient charging case designed to keep up with you all day.',
  Design_ChapterText4: 'Beats Fit Pro come in <em>eco-friendly, sustainable packaging</em>.',
  Design_PerfectMatch_Title: 'Magnetic positioning',
  Design_PerfectMatch_Subtitle:
    'Magnets inside the charging case are oriented so that the earbuds fall into the right place, every time.',
  Design_Contact_Title: 'Precise contact',
  Design_Contact_Subtitle:
    'Pogo pins within the case use a resilient leaf spring for precise contact between the earbuds and case—resulting in properly seated earbuds for charging.',
  Design_Contact_PogoPin: 'POGO PIN',
  Design_FindYourMatch_Title: 'Find your match',
  Design_FindYourMatch_Subtitle: 'Color matching charging case',
  Design_FindYourMatch_White: 'WHITE',
  Design_FindYourMatch_Black: 'BLACK',
  Design_FindYourMatch_BeatsRed: 'BEATS RED',
  Design_Summary_Title: 'Designed for truly wireless freedom',
  Design_Summary1_WirelessFreedom: 'True wireless earbuds don’t hold you back.',
  Design_Summary2_PocketSizedPortability: 'Lightweight charging case easily fits in your pocket.',
  Design_Summary3_WaterAndSweatResistant: 'IPX-4 rating for sweat and water resistant earbuds.',
  Design_Summary4_Comfort: 'Sleek, compact earbuds provide comfort and stability.',
  Design_Summary5_SizeMatters: 'In-ear design is significantly smaller and lighter than most wireless earbuds.',
  Design_Summary6_PerfectMatch: 'Magnets inside the charging case ensure earbuds fall into place every time.',
  Design_Summary7_Contact: 'Pogo pins within the case help properly seat earbuds for charging.',
  Design_Summary8_FindYourMatch: 'Color matching charging case comes in Black, White, and Beats Red.',
  Design_SustainablePackaging_Title: 'Sustainable packaging',
  Design_SustainablePackaging_Subtitle:
    'Beats Fit Pro packaging is made from 92% plant-based material sourced from a combination of recycled fiber and sustainable forests.',
  Compatibility_Intro_Chapter: 'replace this comment',
  Compatibility_Intro_Title: 'Compatibility',
  Compatibility_Intro_VOSubtitle: 'Compatible with both Apple and Android devices. ',
  Compatibility_ChapterText1: 'Easily use with <em>both Apple and Android devices.</em>',
  Compatibility_OneTouch_Title: 'One-touch pairing ',
  Compatibility_OneTouch_Subtitle:
    'Easily connect your Apple<sup>1</sup> or Android<sup>2</sup> device via Bluetooth with simple one-touch pairing. ',
  Compatibility_OneTouch_Disclaimer:
    '<sup>1 One-touch pairing requires iOS 14.6 or later.<br/>2 Fast Pair requires location enabled. Full access to features requires an Android 6.0 or newer device with Google Play Service enabled and Google Account.</sup>',
  Compatibility_VoiceAssistant_Title: 'Voice assistant',
  Compatibility_VoiceAssistant_Subtitle:
    'Beats Fit Pro are compatible with your preferred voice assistant, which can be activated with a customized “Press and Hold” function.',
  Compatibility_LocateDevice_Title: 'Locate your device',
  Compatibility_LocateDevice_Subtitle:
    'Use the “Find My” feature for iOS or “Find My Device” feature for Android to easily locate your earbuds.',
  Compatibility_USBCCharging_Title: 'USB-C charging',
  Compatibility_USBCCharging_Subtitle:
    'A USB-C charge connector works across Apple and Android so you can charge up anywhere.',
  Compatibility_ManageDevice_Title: 'Manage your device ',
  Compatibility_ManageDevice_Subtitle:
    'Manage features like "Press and Hold” customization, listening modes, renaming your product, and firmware updates through your Apple device or the Beats App for Android.',
  Compatibility_Summary_Title: 'Compatible with Apple and Android devices.',
  Compatibility_Summary1_OneTouch: 'One-touch pairing for both iOS and Android.',
  Compatibility_Summary2_VoiceAssistant: 'Activate preferred voice assistant to manage calls, music, and more.',
  Compatibility_Summary3_LocateDevice: 'Easily locate your earbuds using your device.',
  Compatibility_Summary4_USBCCharging: 'USB-C charge connector offers universal charging.',
  Compatibility_Summary5_ManageDevice: 'Manage your device through iOS or the Beats App for Android.',
  Control_Intro_Chapter: 'replace this comment',
  Control_Intro_Title: 'Controls',
  Control_Intro_VOSubtitle: 'Control your sound, and your day, with Beats Fit Pro.',
  Control_ChapterText1: 'Manage your sound with <em>easy access to controls.</em>',
  Control_OnEarControls_Title: 'On-ear controls',
  Control_OnEarControls_Instructions: 'Click on each control command:',
  Control_OnEarControls_Subtitle:
    'On-ear controls let you manage calls and music, toggle between listening modes and activate voice assistant.<sup>*</sup>',
  Control_OnEarControls_Disclaimer:
    '<sup>*</sup> Press and hold feature customization requires an iPhone running iOS 14.6 or later, or an Android device with the latest version of the Beats app installed.',
  Control_OnEarControls_Instruction: 'Click or tap through each control to continue.',
  Control_OnEarControls_PlayPause: 'Press to play, pause and answer/end calls ',
  Control_OnEarControls_Skip: 'Double press button to skip track',
  Control_OnEarControls_Previous: 'Triple press button to play previous track ',
  Control_OnEarControls_ScanForward: 'Double press and hold button to scan forward ',
  Control_OnEarControls_ScanBackward: 'Triple press and hold button to scan backwards ',
  Control_OnEarControls_ToggleModes:
    'Press and hold to toggle between and turn off listening modes or customize to activate voice assistant<sup>*</sup>',
  Control_PickASide_Title: 'Pick a side',
  Control_PickASide_Subtitle:
    'Use iOS or the Beats App for Android to customize "Press and Hold” on each earbud to activate ANC or your voice assistant.<sup>*</sup>',
  Control_PickASide_Disclaimer:
    '<sup>*</sup>Press and hold feature customization requires an iPhone running iOS 14.6 or later, or an Android device with the latest version of the Beats app installed.',
  Control_SayItWithSiri_Title: 'Say it with Siri',
  Control_SayItWithSiri_Subtitle:
    'Hands-free access to Siri lets you control music, manage calls, adjust volume, and more whenever you need it.<sup>*</sup>',
  Control_SayItWithSiri_Disclaimer:
    '<sup>*</sup>Siri may not be available in all languages or in all areas, and features may vary by area. Internet access is required. Cellular data charges may apply.',
  Control_SayItWithSiri_SiriCommand1: '“Hey Siri, play my study playlist.”',
  Control_SayItWithSiri_SiriCommand2: '“Hey Siri, show me female-run businesses nearby.”',
  Control_SayItWithSiri_SiriCommand3: '“Hey Siri, set a timer for 10 minutes.”',
  Control_Summary_Title: 'Manage your sound with on-ear controls.',
  Control_Summary1_OnEarControls: 'On-ear controls let you manage calls and music.',
  Control_Summary2_PickASide: 'Customize the “Press and Hold” function on the left and right earbud.',
  Control_Summary3_SayItWithSiri: 'Hands-free access to Siri.',
  Battery_Intro_Chapter: 'Battery',
  Battery_Intro_Title: 'Battery',
  Battery_Intro_VOSubtitle: 'Power your day with a battery that lasts all day.',
  Battery_ChapterText1: '<em>All-day battery</em> that keeps you moving.',
  Battery_FastFuel_Title: 'Up to 8 hours',
  Battery_FastFuel_Subtitle:
    'Powered by a highly efficient chip, each earbud provides up to 8 hours of listening time.<sup>*</sup>',
  Battery_FastFuel_Disclaimer: '<sup>*</sup> Battery life depends on device settings, environment, usage, and many other factors',
  Battery_20Hours_Title: 'Up to 24 hours',
  Battery_20Hours_Subtitle:
    'A pocket-sized charging case provides two additional charges, resulting in up to 24 hours of combined playback.<sup>*</sup>',
  Battery_20Hours_Disclaimer: '<sup>*</sup> Battery life depends on device settings, environment, usage, and many other factors',
  Battery_FuelYourDay_Title: 'Fuel your day',
  Battery_FuelYourDay_Subtitle:
    'When the battery is low, a 5 minute Fast Fuel charge provides 1 hour of playback.<sup>*</sup>',
  Battery_FuelYourDay_Disclaimer:
    '<sup>*</sup> Battery life depends on device settings, environment, usage, and many other factors',
  Battery_UseCases_Subtitle: 'Click or tap each use case to learn more. ',
  Battery_UseCases_Listen: 'Listen',
  Battery_UseCases_Listen_Subtitle: 'Listen to 12 weeks of your favorite radio shows.<sup>*</sup>',
  Battery_UseCases_Listen_Disclaimer: '<sup>*</sup>Based on 2 hour listening sessions',
  Battery_UseCases_Meditate: 'Meditate',
  Battery_UseCases_Meditate_Subtitle: 'Relax with 32 rain sound meditations.<sup>*</sup>',
  Battery_UseCases_Meditate_Disclaimer: '<sup>*</sup>Based on 45 minute sessions',
  Battery_UseCases_Game: 'Game',
  Battery_UseCases_Game_Subtitle: 'Play 24 sessions of your favorite game.<sup>*</sup>',
  Battery_UseCases_Game_Disclaimer: '<sup>*</sup>Based on 1 hour gaming sessions that do not require the use of microphones',
  Battery_UseCases_Commute: 'Commute',
  Battery_UseCases_Commute_Subtitle: 'Enjoy 24 days of commuting.<sup>*</sup>',
  Battery_UseCases_Commute_Disclaimer:
    '<sup>*</sup> Based on a 1 hour commute. Battery life depends on device settings, environment, usage, and many other factors.',
  Battery_WhatsInTheBox_Title: 'What’s in the box',
  Battery_WhatsInTheBox_Subtitle:
    'Beats Fit Pro comes with a pocket-sized charging case, a universal USB-C charge connector, and three eartip sizes to choose from.',
  Batter_Summary_Title: 'Battery that lasts all day.',
  Battery_Summary1_PowerYourDay: 'Earbuds provide up to 8 hours of playback.',
  Battery_Summary2_UpTo24: 'Up to 24 hours of combined playback.',
  Battery_Summary3_FastFuel: 'Fast Fuel charge provides 1 hour of playback.',
  Battery_Summary4_UseCases: 'Listen, meditate, and game with all-day battery.',
  Battery_Summary5_WhatsInTheBox:
    'Box includes a pocket-sized charging case, USB-C charge connector, and three eartip sizes.',
  Menu_Sound_Module1: 'SOUND INTRO',
  Menu_Sound_Module2: 'GREAT SOUND STARTS WITH A GREAT FIT',
  Menu_Sound_Module3: 'THREE EARTIPS',
  Menu_Sound_Module4: 'POWERFUL, BALANCED SOUND',
  Menu_Sound_Module5: 'CUSTOMIZED ACOUSTIC PLATFORM',
  Menu_Sound_Module6: 'CLEAR SOUND. LOW DISTORTION. ',
  Menu_Sound_Module7: 'FINE-TUNED AUDIO',
  Menu_Sound_Module8: 'PHONE CALLS ',
  Menu_Sound_Module9: 'HIGH-QUALITY CALL PERFORMANCE',
  Menu_Sound_Module10: 'SOUND SUMMARY',
  Menu_ANC_Module1: 'ANC INTRO',
  Menu_ANC_Module2: 'ACOUSTIC SEAL',
  Menu_ANC_Module3: 'START WITH THE SEAL',
  Menu_ANC_Module4: 'TECHNOLOGY',
  Menu_ANC_Module5: 'ANC THAT ADAPTS',
  Menu_ANC_Module6: 'PLAYBACK CORRECTION',
  Menu_ANC_Module7: 'ANC USE CASES',
  Menu_ANC_Module8: 'ANC SUMMARY',
  Menu_Transparency_Module1: 'TRANSPARENCY INTRO',
  Menu_Transparency_Module2: 'HEAR THE WORLD AROUND YOU',
  Menu_Transparency_Module3: 'TRANSPARENCY MODE',
  Menu_Transparency_Module4: 'EXTERNAL MICROPHONES',
  Menu_Transparency_Module5: 'FEED-FORWARD MICROPHONES',
  Menu_Transparency_Module6: 'TRANSPARENCY USE CASES',
  Menu_Transparency_Module7: 'TRANSPARENCY SUMMARY',
  Menu_Design_Module1: 'DESIGN INTRO',
  Menu_Design_Module2: 'WIRELESS COMPACT DESIGN',
  Menu_Design_Module3: 'WIRELESS FREEDOM',
  Menu_Design_Module4: 'POCKET-SIZED PORTABILITY',
  Menu_Design_Module5: 'SWEAT & WATER RESISTANCE',
  Menu_Design_Module6: 'COMFORTABLE & VERSATILE FIT',
  Menu_Design_Module7: 'DESIGNED FOR COMFORT',
  Menu_Design_Module8: 'COMPACT DESIGN',
  Menu_Design_Module9: 'CONVENIENT CHARGING CASE',
  Menu_Design_Module10: 'MAGNETIC POSITIONING',
  Menu_Design_Module11: 'PRECISE CONTACT',
  Menu_Design_Module12: 'COLORS',
  Menu_Design_Module13: 'DESIGN SUMMARY',
  Menu_Compatibility_Module1: 'COMPATIBILITY INTRO',
  Menu_Compatibility_Module2: 'COMPATIBLE WITH APPLE AND ANDROID',
  Menu_Compatibility_Module3: 'ONE-TOUCH PAIRING',
  Menu_Compatibility_Module4: 'VOICE ASSISTANT',
  Menu_Compatibility_Module5: 'LOCATE YOUR DEVICE',
  Menu_Compatibility_Module6: 'USB-C CHARGING',
  Menu_Compatibility_Module7: 'MANAGE YOUR DEVICE',
  Menu_Compatibility_Module8: 'COMPATIBILITY SUMMARY',
  Menu_Control_Module1: 'CONTROL INTRO',
  Menu_Control_Module2: 'EASY ACCESS',
  Menu_Control_Module3: 'ON-EAR CONTROLS',
  Menu_Control_Module4: 'PICK A SIDE',
  Menu_Control_Module5: 'SAY IT WITH SIRI',
  Menu_Control_Module6: 'CONTROL SUMMARY',
  Menu_Battery_Module1: 'BATTERY INTRO',
  Menu_Battery_Module2: 'ALL-DAY BATTERY',
  Menu_Battery_Module3: '8 HOURS OF LISTENING TIME',
  Menu_Battery_Module4: '24 HOURS OF COMBINED PLAYBACK',
  Menu_Battery_Module5: 'FAST FUEL',
  Menu_Battery_Module6: 'BATTERY USE CASES',
  Menu_Battery_Module7: "WHAT'S IN THE BOX",
  Menu_Battery_Module8: 'BATTERY SUMMARY',
  Outro_ChapterText1:
    'With Active Noise Cancelling, Transparency mode, premium sound, and a wireless, pocket-sized design, <em>Beats Fit Pro fit seamlessly into your life.</em>',
  IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
  Blank: ' ',
  Global_Country_ALBANIA: 'ALBANIA',
  Global_Country_ALGERIA: 'ALGERIA',
  Global_Country_ANDORRA: 'ANDORRA',
  Global_Country_ANGOLA: 'ANGOLA',
  Global_Country_ARGENTINA: 'ARGENTINA',
  Global_Country_ARMENIA: 'ARMENIA',
  Global_Country_ARUBA: 'ARUBA',
  Global_Country_AUSTRALIA: 'AUSTRALIA',
  Global_Country_AUSTRIA: 'AUSTRIA',
  Global_Country_AZERBAIJAN: 'AZERBAIJAN',
  Global_Country_BAHAMAS: 'BAHAMAS',
  Global_Country_BAHRAIN: 'BAHRAIN',
  Global_Country_BANGLADESH: 'BANGLADESH',
  Global_Country_BARBADOS: 'BARBADOS',
  Global_Country_BELARUS: 'BELARUS',
  Global_Country_BELGIUM: 'BELGIUM',
  Global_Country_BOLIVIA: 'BOLIVIA',
  Global_Country_BOSNIAHERZRGOVINA: 'BOSNIA & HERZRGOVINA',
  Global_Country_BOTSWANA: 'BOTSWANA',
  Global_Country_BRAZIL: 'BRAZIL',
  Global_Country_BRUNEI: 'BRUNEI DARUSSALAM',
  Global_Country_BULGARIA: 'BULGARIA',
  Global_Country_CAMBODIA: 'CAMBODIA',
  Global_Country_CAMEROON: 'CAMEROON',
  Global_Country_CANADA: 'CANADA',
  Global_Country_CAYMANISLANDS: 'CAYMAN ISLANDS',
  Global_Country_CHILE: 'CHILE',
  Global_Country_CHINA: 'CHINA (MAINLAND)',
  Global_Country_COLOMBIA: 'COLOMBIA',
  Global_Country_COOKISLANDS: 'COOK ISLANDS',
  Global_Country_COSTARICA: 'COSTA RICA',
  Global_Country_CROATIA: 'CROATIA',
  Global_Country_CYPRUS: 'CYPRUS',
  Global_Country_CZECHREPUBLIC: 'CZECH REPUBLIC',
  Global_Country_DENMARK: 'DENMARK',
  Global_Country_DOMINICANREPUBLIC: 'DOMINICAN REPUBLIC',
  Global_Country_ECUADOR: 'ECUADOR',
  Global_Country_EGYPT: 'EGYPT',
  Global_Country_ELSALVADOR: 'EL SALVADOR',
  Global_Country_ESTONIA: 'ESTONIA',
  Global_Country_FAROEISLANDS: 'FAROE ISLANDS',
  Global_Country_FIJI: 'FIJI',
  Global_Country_FINLAND: 'FINLAND',
  Global_Country_FRANCE: 'FRANCE',
  Global_Country_FRENCHGUIANA: 'FRENCH GUIANA',
  Global_Country_FRENCHPOLYNESIA: 'FRENCH POLYNESIA',
  Global_Country_GEORGIA: 'GEORGIA',
  Global_Country_GERMANY: 'GERMANY',
  Global_Country_GHANA: 'GHANA',
  Global_Country_GIBRALTAR: 'GIBRALTAR',
  Global_Country_GREECE: 'GREECE',
  Global_Country_GUADELOUPE: 'GUADELOUPE',
  Global_Country_GUAM: 'GUAM',
  Global_Country_GUATEMALA: 'GUATEMALA',
  Global_Country_GUERNSEY: 'GUERNSEY',
  Global_Country_GUINEA: 'GUINEA',
  Global_Country_GUYANA: 'GUYANA',
  Global_Country_HONDURAS: 'HONDURAS',
  Global_Country_HONGKONG: 'HONG KONG',
  Global_Country_HUNGARY: 'HUNGARY',
  Global_Country_ICELAND: 'ICELAND',
  Global_Country_INDIA: 'INDIA',
  Global_Country_INDONESIA: 'INDONESIA',
  Global_Country_IRAQ: 'IRAQ',
  Global_Country_IRELAND: 'IRELAND',
  Global_Country_ISLEOFMAN: 'ISLE OF MAN',
  Global_Country_ISRAEL: 'ISRAEL',
  Global_Country_ITALY: 'ITALY',
  Global_Country_IVORYCOAST: 'IVORY COAST',
  Global_Country_JAPAN: 'JAPAN',
  Global_Country_JERSEY: 'JERSEY',
  Global_Country_JORDAN: 'JORDAN',
  Global_Country_KAZAKHSTAN: 'KAZAKHSTAN',
  Global_Country_KENYA: 'KENYA',
  Global_Country_KOREA: 'KOREA, REPUBLIC OF',
  Global_Country_KUWAIT: 'KUWAIT',
  Global_Country_KYRGYZSTAN: 'KYRGYZSTAN',
  Global_Country_LAOS: 'LAOS',
  Global_Country_LATVIA: 'LATVIA',
  Global_Country_LEBANON: 'LEBANON',
  Global_Country_LESOTHO: 'LESOTHO',
  Global_Country_LITHUANIA: 'LITHUANIA',
  Global_Country_LUXEMBOURG: 'LUXEMBOURG',
  Global_Country_MACAU: 'MACAU',
  Global_Country_MACEDONIA: 'MACEDONIA',
  Global_Country_MADAGASCAR: 'MADAGASCAR',
  Global_Country_MALAWI: 'MALAWI',
  Global_Country_MALAYSIA: 'MALAYSIA',
  Global_Country_MALDIVES: 'MALDIVES',
  Global_Country_MALTA: 'MALTA',
  Global_Country_MARTINIQUE: 'MARTINIQUE',
  Global_Country_MAURITIUS: 'MAURITIUS',
  Global_Country_MAYOTTE: 'MAYOTTE',
  Global_Country_MEXICO: 'MEXICO',
  Global_Country_MOLDOVA: 'MOLDOVA',
  Global_Country_MONACO: 'MONACO',
  Global_Country_MONTENEGRO: 'MONTENEGRO',
  Global_Country_MOROCCO: 'MOROCCO',
  Global_Country_MOZAMBIQUE: 'MOZAMBIQUE',
  Global_Country_MYANMAR: 'MYANMAR',
  Global_Country_NAMIBIA: 'NAMIBIA',
  Global_Country_NEPAL: 'NEPAL',
  Global_Country_NETHERLANDS: 'NETHERLANDS',
  Global_Country_NEWCALEDONIA: 'NEW CALEDONIA',
  Global_Country_NEWZEALAND: 'NEW ZEALAND',
  Global_Country_NICARAGUA: 'NICARAGUA',
  Global_Country_NIGERIA: 'NIGERIA',
  Global_Country_NORWAY: 'NORWAY',
  Global_Country_OMAN: 'OMAN',
  Global_Country_PAKISTAN: 'PAKISTAN',
  Global_Country_PALESTINIANTERRITORY: 'PALESTINIAN TERRITORY',
  Global_Country_PANAMA: 'PANAMA',
  Global_Country_PARAGUAY: 'PARAGUAY',
  Global_Country_PERU: 'PERU',
  Global_Country_PHILIPPINES: 'PHILIPPINES',
  Global_Country_POLAND: 'POLAND',
  Global_Country_PORTUGAL: 'PORTUGAL',
  Global_Country_PUERTORICO: 'PUERTO RICO',
  Global_Country_QATAR: 'QATAR',
  Global_Country_REUNION: 'REUNION',
  Global_Country_ROMANIA: 'ROMANIA',
  Global_Country_RUSSIA: 'RUSSIA',
  Global_Country_SAINTMARTIN: 'SAINT MARTIN',
  Global_Country_SANMARINO: 'SAN MARINO',
  Global_Country_SAUDIARABIA: 'SAUDI ARABIA',
  Global_Country_SENEGAL: 'SENEGAL',
  Global_Country_SERBIA: 'SERBIA',
  Global_Country_SINGAPORE: 'SINGAPORE',
  Global_Country_SINTMAARTEN: 'SINT MAARTEN',
  Global_Country_SLOVAKIA: 'SLOVAKIA',
  Global_Country_SLOVENIA: 'SLOVENIA',
  Global_Country_SOUTHAFRICA: 'SOUTH AFRICA',
  Global_Country_SPAIN: 'SPAIN',
  Global_Country_SRILANKA: 'SRI LANKA',
  Global_Country_SWEDEN: 'SWEDEN',
  Global_Country_SWITZERLAND: 'SWITZERLAND',
  Global_Country_TAIWAN: 'TAIWAN',
  Global_Country_TAJIKISTAN: 'TAJIKISTAN',
  Global_Country_TANZANIA: 'TANZANIA',
  Global_Country_THAILAND: 'THAILAND',
  Global_Country_TRINIDADTOBAGO: 'TRINIDAD & TOBAGO',
  Global_Country_TUNISIA: 'TUNISIA',
  Global_Country_TURKEY: 'TURKEY',
  Global_Country_TURKMENISTAN: 'TURKMENISTAN',
  Global_Country_UGANDA: 'UGANDA',
  Global_Country_UKRAINE: 'UKRAINE',
  Global_Country_UAE: 'UNITED ARAB EMIRATES',
  Global_Country_UK: 'UNITED KINGDOM',
  Global_Country_US: 'UNITED STATES',
  Global_Country_URUGUAY: 'URUGUAY',
  Global_Country_UZBEKISTAN: 'UZBEKISTAN',
  Global_Country_VENEZUELA: 'VENEZUELA',
  Global_Country_VIETNAM: 'VIETNAM',
  Global_Country_ZAMBIA: 'ZAMBIA',
  Global_Country_ZIMBABWE: 'ZIMBABWE',
  IntroOutro_TestYourKnowledge: 'Test your Beats Fit Pro knowledge.',
  IntroOutro_CTA: 'Take The Quiz',
  Global_Menu_Language: 'LANGUAGE',
  Global_Menu_Help: 'Need help? <u>Contact Us</u>',
  Global_Menu_Restart: 'Restart Experience',
  Global_Field_Location: 'LOCATION',
  Global_Password_Rules_Header: 'Password must contain the following',
  Global_Password_Rule_Length: 'Minimum of 8 characters',
  Global_Password_Rule_Uppercase: '1 uppercase letter',
  Global_Password_Rule_Lowercase: '1 lowercase letter',
  Global_Password_Rule_Number: '1 number',
  Global_Password_Rule_Special: 'Special characters (!@#$%^&*)',



  
  Global_ProductSelector_Headline: 'Select a Beats TRNR experience:',
  Global_ProductSelector_Product1: 'Beats Studio Buds',
  Global_ProductSelector_Product2: 'Beats Fit Pro',
  Global_ProductSelector_CTA_1: 'Get Started',
  Global_ProductSelector_CTA_2: 'Resume',
  Global_ProductSelector_CTA_3: 'Restart',
  Global_WelcomeInstructions_Headline: 'Welcome to Beats TRNR, where Beats experts are made.',
  Global_WelcomeInstructions_Subhead: 'Here’s what to expect:',
  Global_WelcomeInstructions_Body1: 'Each training experience should take about 15 minutes to complete.',
  Global_WelcomeInstructions_Body2: 'Each training experience is meant to be heard. Make sure your sound is on.',
  Global_WelcomeInstructions_Body3: 'Within each training experience, remember to complete each section before moving on.',
  Global_WelcomeInstructions_Body4: 'Don’t worry if you need to step away. You can always pick up where you left off.',
  Intro_Welcome_ReturningUser: 'Ready to pick up where you left off? Click or tap to continue exploring Beats Fit Pro.',
  FitPro_Menu_Chapter1: 'CH 1',
  FitPro_Menu_Chapter2: 'CH 2',
  FitPro_Menu_Chapter3: 'CH 3',
  FitPro_Menu_Chapter4: 'CH 4',
  FitPro_Menu_Chapter5: 'CH 5',
  FitPro_Menu_Chapter6: 'CH 6',
  FitPro_Menu_Chapter7: 'CH 7',
  FitPro_Menu_ChapterTitle_Fit: 'Fit',
  FitPro_Menu_ChapterTitle_Sound: 'Sound',
  FitPro_Menu_ChapterTitle_ListeningModes: 'Listening Modes',
  FitPro_Menu_ChapterTitle_Controls: 'Controls',
  FitPro_Menu_ChapterTitle_AppleH1Chip: 'Apple H1 Chip',
  FitPro_Menu_ChapterTitle_Design: 'Design',
  FitPro_Menu_ChapterTitle_Battery: 'Battery',
  Global_Menu_Button_Restart: 'Restart Experience',
  Global_Menu_Button_SelectProduct: 'Select Another Product',
  Global_Menu_Button_Contact: 'Need help? Contact us',
  FitPro_Intro_Title: 'Beats<br/>Fit Pro',
  FitPro_Intro_Subtitle: 'Introducing Beats Fit Pro. True wireless noise cancelling earbuds engineered for all-day activity. Click or tap through to explore.',
  FitPro_Fit_Title: 'Fit',
  FitPro_Fit_Intro_VOSubtitle: 'Beats Fit Pro are designed for all-day comfort and stability.',
  FitPro_Fit_ChapterText1: 'Equipped with comfortable, secure-fit wingtips that <em>flex to fit your ear.</em>',
  FitPro_Fit_FlexibleWingtip_Title: 'Flexible, secure-fit wingtips',
  FitPro_Fit_FlexibleWingtip_Subtitle: 'Perfecting the wingtip design was a priority in engineering Beats Fit Pro. Our research determined that adjusting to each user’s unique ear anatomy was key in developing a wingtip that could feel locked in place in nearly all ear types.',
  FitPro_Fit_AllDay_Title: 'All-day comfort',
  FitPro_Fit_AllDay_Subtitle: 'The universal wingtip design was put to the ultimate test over the course of a year by active users of all kinds from everyday runners to professional athletes.',
  FitPro_Fit_Universal_Title: 'One wingtip fits all',
  FitPro_Fit_Universal_Subtitle: 'How the wingtip conforms to an ear depends on the size of the user’s ears. The wingtip bends to different angles to provide stability in a variety of ear sizes.',
  FitPro_Fit_Materials_Title: 'Soft and secure',
  FitPro_Fit_Materials_Subtitle: 'The unique wingtip was digitally modeled using measurements from thousands of ears to achieve the perfect combination of soft, pliable material around a rigid core for a comfortable and stable fit on any ear shape or size.',
  FitPro_Fit_ChapterText2: 'Great sound starts with a <em>great fit</em>. The right ear tip helps personalize your fit.',
  FitPro_Fit_EarTips_Title: 'Find your size',
  FitPro_Fit_EarTips_Subtitle: 'Three soft silicone ear tip options make it easy to find the right size for a personalized fit and acoustic seal.',
  FitPro_Fit_FitTest_Title: 'Put your fit to the test',
  FitPro_Fit_FitTest_Subtitle: 'The Fit Test setup for both Apple and Android helps ensure proper fit for a customized acoustic seal and optimal performance. The Fit Test analyzes noise leakage to confirm an optimal acoustic seal.<sup>*</sup>',
  FitPro_Fit_FitTest_Disclaimer: '<sup>*</sup>Ear tip fit test requires iOS 15.1 or later, or the Beats app for Android',
  FitPro_Fit_Seal_Title: 'Seal the deal',
  FitPro_Fit_Seal_Subtitle: 'With a proper fit, you get a personalized acoustic seal and optimal performance. A proper acoustic seal helps both Active Noise Cancelling and Transparency mode perform at their best.',
  FitPro_Fit_Summary_Title: 'Designed for comfort and stability',
  FitPro_Fit_Summary1_FlexibleWingtip: 'Perfecting the wingtip design was a priority in engineering Beats Fit Pro. ',
  FitPro_Fit_Summary2_AllDay: 'The universal wingtip design comfort-tested by athletes of all kinds.',
  FitPro_Fit_Summary3_Universal: 'How the wingtip conforms to an ear depends on the size of a user’s ears. ',
  FitPro_Fit_Summary4_Materials: 'Our wingtip material is soft to the touch, yet rigid.',
  FitPro_Fit_Summary5_EarTips: 'Three soft silicone ear tip options make it easy to find the right size.',
  FitPro_Fit_Summary6_FitTest: 'The Fit Test helps ensure proper fit for a customized acoustic seal and optimal performance.',
  FitPro_Fit_Summary7_Seal: 'A proper acoustic seal helps music sounds its best.',
  FitPro_Sound_Title: 'Sound',
  FitPro_Sound_Intro_VOSubtitle: 'Beats Fit Pro are engineered to deliver powerful, balanced sound.',
  FitPro_Sound_ChapterText1: 'Beats Fit Pro has a <em>custom driver</em> for dynamic range across the frequency curve.',
  FitPro_Sound_Driver_Title: 'Small and mighty',
  FitPro_Sound_Driver_Subtitle: 'This driver has a 9.5 mm diameter to remain small with exceptional bass, treble and mid-tones. That’s about half the diameter of a US penny.',
  FitPro_Sound_Driver_Label: '9.5 mm',
  FitPro_Sound_Pleated_Title: 'Pleated perfection',
  FitPro_Sound_Pleated_Subtitle: 'Our proprietary pleated driver adds flexibility while significantly reducing microvibrations. Pleated drivers are some of the most challenging to design and manufacture.',
  FitPro_Sound_Airflow_Title: 'Let it flow',
  FitPro_Sound_Airflow_Subtitle: 'Optimal air flow through the driver minimizes treble distortion and creates powerful bass reproduction.',
  FitPro_Sound_UniqueDriver_Title: 'Delivers our signature sound',
  FitPro_Sound_UniqueDriver_Subtitle: 'This unique driver design results in powerful, balanced sound with incredibly low distortion.',
  FitPro_Sound_ChapterText2: '<em>Enjoy exceptional sound quality</em> that fits the way you  listen.',
  FitPro_Sound_SpatialAudio_Title: 'Hear every dimension',
  FitPro_Sound_SpatialAudio_Subtitle: 'Spatial audio with dynamic head tracking delivers immersive sound that adjusts as you turn your head.<sup>*</sup>',
  FitPro_Sound_SpatialAudio_Disclaimer: '<sup>*</sup>Compatible hardware and software required. Works with compatible content in supported apps. Not all content available in Dolby Atmos. Head tracking feature requires iPhone or iPad.',
  FitPro_Sound_UseCases_Subtitle: 'Click or tap each use case to learn more.',
  FitPro_Sound_UseCases_Music: 'Music',
  FitPro_Sound_UseCases_Music_Subtitle: 'Listen to your favorite music as if you were in the studio with multidimensional sound.<sup>*</sup>',
  FitPro_Sound_UseCases_Movies: 'Movies',
  FitPro_Sound_UseCases_Movies_Subtitle: 'Surround yourself in sound and feel like you’re in the theatre when you watch your favorite movies.<sup>*</sup>',
  FitPro_Sound_UseCases_Games: 'Games',
  FitPro_Sound_UseCases_Games_Subtitle: 'Enjoy immersive gaming on a whole new level.<sup>*</sup>',
  FitPro_Sound_UseCases_Disclaimer_1: '<sup>*</sup>Compatible hardware and software required. Works with compatible content in supported apps. Not all content available in Dolby Atmos. Head tracking feature requires iPhone or iPad.',

  FitPro_Sound_Calls_Title: 'Loud and clear',
  FitPro_Sound_Calls_Subtitle: 'With a built-in accelerometer and Class 1 Bluetooth<sup>&reg;</sup> technology, Beats Fit Pro can detect when you’re speaking. The microphones pinpoint your voice while a digital processor cuts out external noise and wind, so your voice is clear and easy to hear.',
  FitPro_Sound_Summary_Title: 'Engineered to deliver powerful, balanced sound',
  FitPro_Sound_Summary1_Driver: 'This driver has a 9.5 mm diameter to remain small with exceptional bass, treble and mid-tones.',
  FitPro_Sound_Summary2_Pleated: 'Our proprietary pleated driver adds flexibility while eliminating microvibrations.',
  FitPro_Sound_Summary3_Airflow: 'Optimal air flow through the driver reduces treble distortion and ensures cleaner bass performance.',
  FitPro_Sound_Summary4_UniqueDriver: 'This unique driver design results in powerful, balanced sound with incredibly low distortion.',
  FitPro_Sound_Summary5_SpatialAudio: 'Spatial audio with dynamic head tracking delivers immersive sound that adjusts as you turn your head.',
  FitPro_Sound_Summary6_UseCases: 'Spatial audio with dynamic head tracking delivers an immersive, theatre-like experience.',
  FitPro_Sound_Summary7_Calls: 'Beats Fit Pro can detect when you’re speaking to help you sound your best.',
  FitPro_Modes_Title: 'Listening Modes',
  FitPro_Modes_Intro_VOSubtitle: 'Three listening modes ensure that you are in control of what you hear. ',
  FitPro_Modes_Toggle_Title: 'Hear what matters',
  FitPro_Modes_Toggle_Subtitle: 'With three distinct options in Active Noise Cancelling (ANC), Transparency mode and Adaptive EQ, you can always choose the listening mode that fits your needs.',
  FitPro_Modes_Toggle_Disclaimer: 'Created specifically for demonstration purposes.',
  FitPro_Modes_Toggle_Label1: 'Noise Cancellation',
  FitPro_Modes_Toggle_Label2: 'Adaptive<br/>EQ',
  FitPro_Modes_Toggle_Label3: 'Transparency',
  FitPro_Modes_Toggle_MusicLabel: 'Now Playing',
  FitPro_Modes_Toggle_SongTitle: 'Mood',
  FitPro_Modes_Toggle_SongArtist: '24kGoldn, ft. iann dior',
  FitPro_Modes_ChapterText1: '<em>Active Noise Cancelling</em> blocks out external noise so you can enjoy your music.',
  FitPro_Modes_ANCExternalMic_Title: 'Capture external noise',
  FitPro_Modes_ANCExternalMic_Subtitle: 'Active Noise Cancelling starts with an outward-facing microphone that detects environmental sound.',
  FitPro_Modes_ANCAntiNoise_Title: 'Neutralize unwanted sound',
  FitPro_Modes_ANCAntiNoise_Subtitle: 'Beats Fit Pro then counters it with equal anti-noise, constantly adapting to and cancelling the external sound before you hear it.',
  FitPro_Modes_ANCInwardMic_Title: 'Continuously adjusts',
  FitPro_Modes_ANCInwardMic_Subtitle: 'An inward-facing microphone helps analyze any compromises to fit and acoustic seal by listening for additional sounds, which are then also eliminated with anti-noise.',
  FitPro_Modes_ANCUseCases_Subtitle: 'Click or tap each use case to learn more.',
  FitPro_Modes_ANCUseCases_Commute: 'Commute',
  FitPro_Modes_ANCUseCases_Commute_Subtitle: 'Commute with less of the city’s background noise.',
  FitPro_Modes_ANCUseCases_Exercise: 'Exercise',
  FitPro_Modes_ANCUseCases_Exercise_Subtitle: 'Immerse yourself in your workout without any distractions.',
  FitPro_Modes_ANCUseCases_Travel: 'Travel',
  FitPro_Modes_ANCUseCases_Travel_Subtitle: 'Quiet the sounds of a loud engine or passengers to better enjoy your flight.',
  FitPro_Modes_ANCUseCases_Meditate: 'Meditate',
  FitPro_Modes_ANCUseCases_Meditate_Subtitle: 'Find inner peace by focusing on your breath and blocking out distractions.',
  FitPro_Modes_ChapterText2: 'Stay aware of your surroundings with <em>Transparency mode.</em>',
  FitPro_Modes_TMAware_Title: 'Hear the world around you',
  FitPro_Modes_TMAware_Subtitle: 'Transparency mode helps you stay aware of your environment by mixing external sounds with your music.',
  FitPro_Modes_TMExternalMic_Title: 'External mics pinpoint ambient noise',
  FitPro_Modes_TMExternalMic_Subtitle: 'External microphones capture external and ambient noises in your environment for a natural, ‘open’ listening experience. ',
  FitPro_Modes_TMUseCases_Subtitle: 'Click or tap each use case to learn more.',
  FitPro_Modes_TMUseCases_Run: 'Run',
  FitPro_Modes_TMUseCases_Run_Subtitle: 'Keep up the pace and hear your surroundings while you listen to music.',
  FitPro_Modes_TMUseCases_Travel: 'Travel',
  FitPro_Modes_TMUseCases_Travel_Subtitle: 'Listen for important announcements at an airport gate or train platform.',
  FitPro_Modes_TMUseCases_Walk: 'Walk',
  FitPro_Modes_TMUseCases_Walk_Subtitle: 'Stay aware of surrounding traffic and busy intersections.',
  FitPro_Modes_TMUseCases_StandBy: 'Stand by',
  FitPro_Modes_TMUseCases_StandBy_Subtitle: 'Hear your name clearly while you listen to music in a loud coffee shop or when you’re waiting to meet a friend.',
  FitPro_Modes_ChapterText3: '<em>Adaptive EQ</em> delivers personalized tuning tailored to individual fit.',
  FitPro_Modes_AdaptiveEQ_Title: 'Adapts to you',
  FitPro_Modes_AdaptiveEQ_Subtitle: 'When Active Noise Cancelling and Transparency mode are off, Adaptive EQ utilizes microphones to tune the sound automatically to the user’s unique ear shape.',
  FitPro_Modes_Summary_Title: 'Focus on your music or hear what surrounds you',
  FitPro_Modes_Summary1_Toggle: 'With three distinct options in Active Noise Cancelling, Transparency mode and Adaptive EQ, you can always choose the listening mode that fits your needs.',
  FitPro_Modes_Summary2_ANCExtermalMic: 'Active Noise Cancelling starts with an outward-facing microphone that detects environmental sound.',
  FitPro_Modes_Summary3_ANCAntiNoise: 'Beats Fit Pro then counters it equal anti-noise.',
  FitPro_Modes_Summary4_ANCInwardMic: 'An inward-facing microphone helps analyze any compromises to fit and acoustic seal by listening for additional sounds.',
  FitPro_Modes_Summary5_ANCUseCases: 'Use ANC to focus while commuting, working out, traveling, or meditating.',
  FitPro_Modes_Summary6_TMAware: 'Transparency mode helps you stay aware of your surroundings.',
  FitPro_Modes_Summary7_TMExternalMic: 'External microphones capture external and ambient noises in your environment.',
  FitPro_Modes_Summary8_TMUseCases: 'Hear your surroundings while running, traveling, walking, or standing by.',
  FitPro_Modes_Summary9_AdaptiveEQ: 'Adaptive EQ utilizes microphones tune the sound automatically to the user’s unique ear shape.',
  FitPro_Controls_Title: 'Controls',
  FitPro_Controls_Intro_VOSubtitle: 'Control your earbuds with just the push of a button.',
  FitPro_Controls_ChapterText1: 'Convenient <em>on-ear buttons</em> are easy to reach.',
  FitPro_Controls_PhysicalButton_Title: 'Just push to play',
  FitPro_Controls_PhysicalButton_Subtitle: 'Physical buttons make it easy to control what you hear.',
  FitPro_Controls_BothSides_Title: 'Independent earbud connectivity',
  FitPro_Controls_BothSides_Subtitle: 'Buttons on both earbuds allow you to control your music with either hand.',
  FitPro_Controls_ChapterText2: '<em>Control audio and listening modes</em> anytime, anywhere.',
  FitPro_Controls_PressHold_Title: 'Seamlessly switch listening modes',
  FitPro_Controls_PressHold_Subtitle: 'Press and hold to toggle between listening modes or customize to activate Siri.<sup>*</sup>',
  FitPro_Controls_PressHold_Disclaimer: '<sup>*</sup>Press and hold feature customization requires an iPhone running iOS 14.6 or later, or an Android device with the latest version of the Beats app installed. Siri may not be available in all languages or in all areas, and features may vary by area. Internet access is required. Cellular data charges may apply.',
  FitPro_Controls_PressHold_Label1: 'Noise Cancellation Mode',
  FitPro_Controls_PressHold_Label2: 'Transparency Mode',
  FitPro_Controls_Playback_Title: 'On-ear controls',
  FitPro_Controls_Playback_Subtitle: 'On-ear controls let you manage calls and music.',
  FitPro_Modes_Playback_MusicLabel: 'Now Playing',
  FitPro_Modes_Playback_SongTitle1: '',
  FitPro_Modes_Playback_SongArtist1: '',
  FitPro_Modes_Playback_SongTitle2: '',
  FitPro_Modes_Playback_SongArtist2: '',
  FitPro_Modes_Playback_SongTitle3: '',
  FitPro_Modes_Playback_SongArtist3: '',
  FitPro_Modes_Playback_SongTitle4: '',
  FitPro_Modes_Playback_SongArtist4: '',
  FitPro_Controls_Playback_Instruction: 'Click or tap the hot spot to practice each command.',
  FitPro_Controls_Playback_Play1:	'Press to pause music or answer calls',
FitPro_Controls_Playback_Play2:	'Press to play music or end calls',
  // FitPro_Controls_Playback_Play: 'Press to play, pause and answer/end calls',
  FitPro_Controls_Playback_Skip: 'Double press button to skip track',
  FitPro_Controls_Playback_Previous: 'Triple press button to play previous track',
  FitPro_Controls_Playback_ScanFwd: 'Double press and hold button to scan forward',
  FitPro_Controls_Playback_ScanBck: 'Triple press and hold button to scan backwards',
  FitPro_Controls_Customize_Title: 'Pick a side',
  FitPro_Controls_Customize_Subtitle: 'Customize the “Press and Hold” control function on each earbud to work for Active Noise Cancelling, Transparency mode, Adaptive EQ, volume control or voice assistant, through iOS or the Beats App for Android.<sup>*</sup>',
  FitPro_Controls_Customize_Disclaimer: '<sup>*</sup>Beats app requires Android 8.0 or later. App is available on the Google Play Store and on beatsbydre.com.',
  FitPro_Controls_Android_Title: 'Works with Android',
  FitPro_Controls_Android_Subtitle: 'With the Beats app for Android, you’ll get enhanced features like one-touch pairing, customized controls, battery levels, and firmware updates.<sup>*</sup>',
  FitPro_Controls_Android_Disclaimer: '<sup>*</sup>Beats app requires Android 8.0 or later. App is available on the Google Play Store and on beatsbydre.com.',
  FitPro_Controls_Summary_Title: 'Complete control at your fingertips',
  FitPro_Controls_Summary1_PhysicalButton: 'Physical buttons make it easy to control what you hear.',
  FitPro_Controls_Summary2_BothSides: 'Buttons on both earbuds allow you to control your music with either hand.',
  FitPro_Controls_Summary3_PressHold: 'Press and hold to toggle between listening modes.',
  FitPro_Controls_Summary4_Playback: 'On-ear controls let you manage calls and music.',
  FitPro_Controls_Summary5_Customize: 'Customize the “Press and Hold” control function on each earbud.',
  FitPro_Controls_Summary6_Android: 'With the Beats app for Android, you’ll get enhanced features.',
  FitPro_H1_Title: 'Apple H1 Chip',
  FitPro_H1_Intro_VOSubtitle: 'Beats Fit Pro are engineered to utilize the Apple H1 chip. ',
  FitPro_H1_AppleIntegration_Title: 'Seamless Apple integration',
  FitPro_H1_AppleIntegration_Subtitle: 'Equipped with the Apple H1 chip for enhanced features and seamless integration with Apple devices.',
  FitPro_H1_ChapterText1: 'Beats Fit Pro are engineered with the <em>Apple H1 chip</em> to enable popular features.',
  FitPro_H1_Pairing_Title: 'One-touch pairing',
  FitPro_H1_Pairing_Subtitle: 'Beats Fit Pro connect with your iOS devices right out of the box via industry-leading Class 1 Bluetooth<sup>&reg;</sup> with simple one-touch pairing.<sup>*</sup>',
  FitPro_H1_Pairing_Disclaimer: '<sup>*</sup>Requires iOS 15.1 or later.',
  FitPro_H1_HeySiri_Title: 'Hey Siri',
  FitPro_H1_HeySiri_Subtitle: 'Control your music, calls, directions and more by just saying “Hey Siri.”<sup>*</sup>',
  FitPro_H1_HeySiri_Disclaimer: '<sup>*</sup>Siri may not be available in all languages or in all areas, and features may vary by area. Internet access is required. Cellular data charges may apply.',
  FitPro_H1_AutoSwitch_Title: 'Automatic Switching',
  FitPro_H1_AutoSwitch_Subtitle: 'Beats Fit Pro allows sound to move seamlessly between devices with Automatic Switching.<sup>*</sup>',
  FitPro_H1_AutoSwitch_Disclaimer: '<sup>*</sup>Automatic Switching requires macOS 11.1, iOS 14.3, iPadOS 14.3, watchOS 7.2, or tvOS 14.3 or later.',
  FitPro_H1_AudioSharing_Title: 'Audio Sharing',
  FitPro_H1_AudioSharing_Subtitle: 'Share your favorite music and listen together with a friend who has AirPods or Beats headphones.<sup>*</sup>',
  FitPro_H1_AudioSharing_Disclaimer: '<sup>*</sup>Audio Sharing is compatible with supported wireless Beats headphones and all AirPods. Compatible Apple hardware and software required.',
  FitPro_H1_FindMy_Title: 'Find My devices',
  FitPro_H1_FindMy_Subtitle: 'The Find My feature makes it easy to keep track of your Beats Fit Pro wherever you go.<sup>*</sup>',
  FitPro_H1_FindMy_Disclaimer: '<sup>*</sup>Find My requires an iPhone or iPod touch with iOS 15.1 or later, iPad with iPadOS 15.1 or later, or Mac with macOS Monterey 12.0.1 or later. Customers must have an Apple ID and be signed into their iCloud account with Find My enabled.',
  FitPro_H1_Summary_Title: 'Equipped with the Apple H1 chip',
  FitPro_H1_Summary1_Seamless:	'Equipped with the Apple H1 chip for seamless integration with Apple devices.',
FitPro_H1_Summary2_Pairing:	'Easily connect via Bluetooth® with simple one-touch pairing.',
FitPro_H1_Summary3_HeySiri:	'Simply say “Hey Siri” to summon Siri and ask a question, hands free.',
FitPro_H1_Summary4_AutoSwitch:	'Beats Fit Pro moves seamlessly between devices with Automatic Switching.',
FitPro_H1_Summary5_AudioSharing:	'Share your music with a friend who has AirPods or Beats headphones.',
FitPro_H1_Summary6_FindMy:	'Use the Find My feature to easily locate your earbuds.',

  // FitPro_H1_Summary1_Pairing: 'Easily connect via Bluetooth® with simple one-touch pairing.',
  // FitPro_H1_Summary2_HeySiri: 'Simply say “Hey Siri” to summon Siri and ask a question, hands free.',
  // FitPro_H1_Summary3_AutoSwitch: 'Beats Fit Pro moves seamlessly between devices with Automatic Switching.',
  // FitPro_H1_Summary4_AudioSharing: 'Share your music with a friend who has AirPods or Beats headphones.',
  // FitPro_H1_Summary5_FindMy: 'Use the Find My feature to easily locate your earbuds.',
  FitPro_Design_Title: 'Design',
  FitPro_Design_Intro_VOSubtitle: 'Beats Fit Pro are designed for all-day activity. ',
  FitPro_Design_ChapterText1: 'The <em>charging case</em> is designed to fuel your earbuds on the go.',
  FitPro_Design_Pocket_Title: 'Fits in your pocket',
  FitPro_Design_Pocket_Subtitle: 'Take Beats Fit Pro on the go with the easy-to-carry matching charging case.',
  FitPro_Design_Size_Title: 'Over 50% smaller',
  FitPro_Design_Size_Subtitle: 'The Beats Fit Pro case is over 50% smaller than the Powerbeats Pro case.',
  FitPro_Design_Color_Title: 'Made to match',
  FitPro_Design_Color_Subtitle: 'With the choice of four unique earbud colors and matching cases, you can always find one that fits your style.',
  FitPro_Design_Color_Black: 'BEATS BLACK',
  FitPro_Design_Color_White: 'BEATS WHITE',
  FitPro_Design_Color_Purple: 'STONE PURPLE',
  FitPro_Design_Color_Gray: 'SAGE GRAY',
  FitPro_Design_ChapterText2: '<em>Beats Fit Pro earbuds</em> are designed to go wherever you do.',
  FitPro_Design_TrueWireless_Title: 'Wireless freedom',
  FitPro_Design_TrueWireless_Subtitle: 'Beats Fit Pro are true wireless earbuds that stay with you through your daily activities.',
  FitPro_Design_Independent_Title: 'One at a time',
  FitPro_Design_Independent_Subtitle: 'Independent earbud connectivity allows you to wear a single earbud at a time, while buttons on both sides let you keep complete control of your sound.',
  FitPro_Design_Sweat_Title: 'Rain, sweat or shine',
  FitPro_Design_Sweat_Subtitle: 'Beats Fit Pro earbuds with an IPX-4 rating for sweat and water resistance can withstand the elements and your toughest workouts.<sup>*</sup>',
  FitPro_Design_Sweat_Disclaimer: '<sup>*</sup>Beats Fit Pro is sweat and water resistant for non-water sports and exercise. Sweat and water resistance are not permanent conditions. The charging case is not sweat or water resistant.',
  FitPro_Design_Summary_Title: 'Designed for all-day activity',
  FitPro_Design_Summary1_Pocket: 'Beats Fit Pro are complete with a pocket-sized charging case to take with you on the go.',
  FitPro_Design_Summary2_Size: 'The Beats Fit Pro case is over 50% smaller than the Powerbeats Pro case.',
  FitPro_Design_Summary3_Color: 'Available in four colors so you can find one that matches your style.',
  FitPro_Design_Summary4_TrueWireless: 'Beats Fit Pro are true wireless earbuds.',
  FitPro_Design_Summary5_Independent: 'Independent earbud connectivity allows you to wear a single earbud at a time.',
  FitPro_Design_Summary6_Sweat: 'Beats Fit Pro earbuds offer an IPX-4 rating for sweat and water resistance.',
  FitPro_Battery_Title: 'Battery',
  FitPro_Battery_Intro_VOSubtitle: 'A battery life that lets you listen on and on and on. ',
  FitPro_Battery_6Hours_Title: 'Listen for hours',
  FitPro_Battery_6Hours_Subtitle: 'Listen to your favorite music for up to 6 hours with each earbud.* ',
  FitPro_Battery_6Hours_Disclaimer: '<sup>*</sup>Battery life depends on device settings, environment, usage, and many other factors.',
  FitPro_Battery_18Hours_Title: 'Up to 24 hours combined',
  FitPro_Battery_18Hours_Subtitle: 'Enjoy 18 hours of additional listening with the charging case.<sup>*</sup>',
  FitPro_Battery_18Hours_Disclaimer: '<sup>*</sup>Battery life depends on device settings, environment, usage, and many other factors.',
  FitPro_Battery_FastFuel_Title: 'Every hour is power hour',
  FitPro_Battery_FastFuel_Subtitle: 'Use Fast Fuel to add 1 more hour of listening time from a 5-minute charge.<sup>*</sup>',
  FitPro_Battery_FastFuel_Disclaimer: '<sup>*</sup>Battery life depends on device settings, environment, usage, and many other factors.',
  FitPro_Battery_USBC_Title: 'USB-C charging',
  FitPro_Battery_USBC_Subtitle: 'A USB-C charge connector works across Apple and Android so you can charge up anywhere. ',
  FitPro_Battery_WhatsInTheBox_Title: 'What’s in the Box',
  FitPro_Battery_WhatsInTheBox_Subtitle: 'Beats Fit Pro come complete with a pocket-sized charging case, a universal USB-C charge connector, and three eartip sizes to choose from.',
  FitPro_Battery_Summary_Title: 'Listen on and on and on',
  FitPro_Battery_Summary1_6Hours: 'Listen for 6 hours with each earbud.',
  FitPro_Battery_Summary2_18Hours: 'Enjoy 18 hours of additional listening with charging case.',
  FitPro_Battery_Summary3_FastFuel: 'Use Fast Fuel to add 1 more hour of listening time from a 5-minute charge.',
  FitPro_Battery_Summary4_USBC: 'A USB-C charge connector is included so you can charge up anywhere.',
  FitPro_Battery_Summary5_WhatsInTheBox: 'Box includes a pocket-sized charging case, USB-C charge connector, and three eartip sizes.',
  FitPro_Outro_ChapterText1: 'With flexible, secure-fit wingtips, three listening modes and a compact, pocket-sized design, <em>Beats Fit Pro put you in control of what you hear.</em>',
  FitPro_Outro_ChapterText1_Disclaimer: '<sup>*</sup>Compatible hardware and software required. Works with compatible content in supported apps. Not all content available in Dolby Atmos. Head tracking feature requires iPhone or iPad.',
  FitPro_Menu_ChapterTitle_Fit_1: 'FLEX TO FIT',
  FitPro_Menu_ChapterTitle_Fit_2: 'FLEXIBLE, SECURE-FIT WINGTIPS',
  FitPro_Menu_ChapterTitle_Fit_3: 'ALL-DAY COMFORT',
  FitPro_Menu_ChapterTitle_Fit_4: 'UNIVERAL FIT',
  FitPro_Menu_ChapterTitle_Fit_5: 'MATERIAL',
  FitPro_Menu_ChapterTitle_Fit_6: 'GREAT FIT',
  FitPro_Menu_ChapterTitle_Fit_7: '3 EAR TIPS',
  FitPro_Menu_ChapterTitle_Fit_8: 'FIT TEST',
  FitPro_Menu_ChapterTitle_Fit_9: 'ACOUSTIC SEAL',
  FitPro_Menu_ChapterTitle_Fit_10: 'FIT SUMMARY',
  FitPro_Menu_ChapterTitle_Sound_1: 'CUSTOM DRIVER',
  FitPro_Menu_ChapterTitle_Sound_2: 'DRIVER SIZE',
  FitPro_Menu_ChapterTitle_Sound_3: 'PLEATED DRIVER',
  FitPro_Menu_ChapterTitle_Sound_4: 'OPTIMAL AIR FLOW',
  FitPro_Menu_ChapterTitle_Sound_5: 'LOW DISTORTION',
  FitPro_Menu_ChapterTitle_Sound_6: 'EXCEPTIONAL SOUND',
  FitPro_Menu_ChapterTitle_Sound_7: 'SPATIAL AUDIO',
  FitPro_Menu_ChapterTitle_Sound_8: 'SPATIAL AUDIO USE CASES',
  FitPro_Menu_ChapterTitle_Sound_9: '3 MICROPHONES',
  FitPro_Menu_ChapterTitle_Sound_10: 'SOUND SUMMARY',
  FitPro_Menu_ChapterTitle_Modes_1: '3 LISTENING MODES',
  FitPro_Menu_ChapterTitle_Modes_2: 'ACTIVE NOISE CANCELLING',
  FitPro_Menu_ChapterTitle_Modes_3: 'OUTWARD-FACING MIC',
  FitPro_Menu_ChapterTitle_Modes_4: 'ANTI-NOISE',
  FitPro_Menu_ChapterTitle_Modes_5: 'INWARD-FACING MIC',
  FitPro_Menu_ChapterTitle_Modes_6: 'ACTIVE NOISE CANCELLING USE CASES',
  FitPro_Menu_ChapterTitle_Modes_7: 'TRANSPARENCY MODE',
  FitPro_Menu_ChapterTitle_Modes_8: 'STAY AWARE',
  FitPro_Menu_ChapterTitle_Modes_9: 'EXTERNAL MICS',
  FitPro_Menu_ChapterTitle_Modes_10: 'TRANSPARENCY MODE USE CASES',
  FitPro_Menu_ChapterTitle_Modes_11: 'ADAPTIVE EQ',
  FitPro_Menu_ChapterTitle_Modes_12: 'ADAPTS TO YOU',
  FitPro_Menu_ChapterTitle_Modes_13: 'LISTENING MODES SUMMARY',
  FitPro_Menu_ChapterTitle_Controls_1: 'ON-EAR BUTTONS',
  FitPro_Menu_ChapterTitle_Controls_2: 'PUSH TO PLAY',
  FitPro_Menu_ChapterTitle_Controls_3: 'INDEPENDENT EARBUD CONNECTIVITY',
  FitPro_Menu_ChapterTitle_Controls_4: 'CONTROL AUDIO AND LISTENING MODES',
  FitPro_Menu_ChapterTitle_Controls_5: 'SWITCH LISTENING MODES',
  FitPro_Menu_ChapterTitle_Controls_6: 'ON-EAR CONTROLS',
  FitPro_Menu_ChapterTitle_Controls_7: 'PICK A SIDE',
  FitPro_Menu_ChapterTitle_Controls_8: 'ANDROID COMPATIBILITY',
  FitPro_Menu_ChapterTitle_Controls_9: 'CONTROLS SUMMARY',
  FitPro_Menu_ChapterTitle_H1_1: 'SEAMLESS APPLE INTEGRATION',
  FitPro_Menu_ChapterTitle_H1_2: 'APPLE H1 CHIP',
  FitPro_Menu_ChapterTitle_H1_3: 'ONE-TOUCH PAIRING',
  FitPro_Menu_ChapterTitle_H1_4: 'HEY SIRI',
  FitPro_Menu_ChapterTitle_H1_5: 'AUTOMATIC SWITCHING',
  FitPro_Menu_ChapterTitle_H1_6: 'AUDIO SHARING',
  FitPro_Menu_ChapterTitle_H1_7: 'FIND MY DEVICES',
  FitPro_Menu_ChapterTitle_H1_8: 'APPLE H1 CHIP SUMMARY',
  FitPro_Menu_ChapterTitle_Design_1: 'CHARGING CASE',
  FitPro_Menu_ChapterTitle_Design_2: 'FITS IN YOUR POCKET',
  FitPro_Menu_ChapterTitle_Design_3: '50% SMALLER',
  FitPro_Menu_ChapterTitle_Design_4: 'MADE TO MATCH',
  FitPro_Menu_ChapterTitle_Design_5: 'DESIGNED TO GO',
  FitPro_Menu_ChapterTitle_Design_6: 'WIRELESS FREEDOM',
  FitPro_Menu_ChapterTitle_Design_7: 'INDEPENDENT EARBUD CONNECTIVITY',
  FitPro_Menu_ChapterTitle_Design_8: 'RAIN, SWEAT OR SHINE',
  FitPro_Menu_ChapterTitle_Design_9: 'DESIGN SUMMARY',
  FitPro_Menu_ChapterTitle_Battery_1: 'LISTEN FOR HOURS',
  FitPro_Menu_ChapterTitle_Battery_2: '24 HOURS COMBINED',
  FitPro_Menu_ChapterTitle_Battery_3: 'FAST FUEL',
  FitPro_Menu_ChapterTitle_Battery_4: 'USB-C CHARGING',
  FitPro_Menu_ChapterTitle_Battery_5: 'WHAT\'S IN THE BOX',
  FitPro_Menu_ChapterTitle_Battery_6: 'BATTERY SUMMARY',
  Global_ForgotEmail_NoUser:"There is no user record corresponding to this identifier. The user may have been deleted."

}

export default copy
