import React from 'react';
// import { Link } from 'react-router-dom';
// import SignOutButton from '../SignOut';
import { useContext } from 'react';
import Context from '../../context/Context';
import NavBurger from './navBurger';
import Menu from '../Menu';
import Header from '../Header';
import { useLocation } from 'react-router-dom';
import { TrackEvent } from '../../utils/track';

import * as ROUTES from '../../constants/routes';

import { AuthUserContext } from '../Session';

const Navigation = props => {
  const context = useContext(Context);
  const location = useLocation();
  const { setForceDefaultCursor, ga4, setOverrideTheme, isHeaderVisible } =
    context;

  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };

  return (
    <div>
      <AuthUserContext.Consumer>
        {({ authUser, firstName, progress }) => {
          return (
            <Context.Consumer>
              {({ menuActive, setMenuActive, isKioskVersion }) => {
                const navToggle = () => {
                  console.log("navToggle")
                  setOverrideTheme(!menuActive ? true : false);
                  setMenuActive(menuActive ? false : true);
                  TrackEvent(
                    ga4,
                    menuActive ? 'menu_close' : 'menu_open',
                    'click',
                    'user_action'
                  );
                };
                //  && isHeaderVisible
                return ((authUser && firstName) ||
                  (isKioskVersion && isHeaderVisible)) &&
                  location.pathname !== ROUTES.COMPANYREQUIRED ? (
                  <div
                    className="navigation"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Menu visible={menuActive} {...props} progress={progress} />
                    <Header />
                    <NavBurger onClick={navToggle} active={menuActive} />
                  </div>
                ) : (
                  <Header />
                );
              }}
            </Context.Consumer>
          );
        }}
      </AuthUserContext.Consumer>
    </div>
  );
};

export default Navigation;
