import React from 'react';
import './pill.scss'
import { withFirebase } from '../Firebase';
import clsx from 'clsx'
const Pill = (props) => {
	const { firebase, action, children, style } = props;
	return (
		<button className={clsx('pill', style === 'outline' ? 'outline' : null)} type="button" onClick={action ? action : firebase.doSignOut}>
			{children}
		</button>
	);
};

export default withFirebase(Pill);
