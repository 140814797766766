import React, { useState, useContext, useEffect, useRef } from 'react';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Styles from './signin.module.scss';
import { motion } from 'framer-motion';
import Context from '../../context/Context';
import PasswordForgetPage from '../PasswordForget';
import clsx from 'clsx';
import { TrackPage, TrackEvent } from '../../utils/track';
import CheckMark from '../../img/check.svg';
import CheckMarkInvalid from '../../img/check_invalid.svg';
// import { Link } from 'react-router-dom';

const SignInPage = (props) => {
	const inputPassword = useRef(null);
	const { firebase, language, history } = props;
	const context = useContext(Context);
	const { setShowCursor, ga4, siteID, setOverrideTheme } = context;
	setShowCursor(false);

	const variants = {
		open: {
			y: 0,
			opacity: 1,
			display: 'block',
			transition: {
				delay: 0.25,
				y: { stiffness: 100, delay: 0.25 },
			},
		},
		closed: {
			y: 100,
			opacity: 0,
			transition: {
				y: { stiffness: 100 },
			},
			transitionEnd: { position: 'absolute' },
		},
	};

	const drawerVariants = {
		open: {
			x: 0,
			transition: {
				x: { stiffness: 100, velocity: -100 },
			},
		},
		closed: {
			x: '100%',
			transition: {
				x: {
					stiffness: 100,
					velocity: -100,
					ease: 'easeOut',
					duration: 0.7,
				},
			},
		},
	};

	const validateEmail = (email) => {
		var re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	const validatePassword = (password) => {
		//RIGOROUS PASSWORD REQUIREMENTS
		var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
		return re.test(password);
	};
	const validatePasswordLength = (password) => {
		return password.length >= 8;
	};

	const validatePasswordUpper = (password) => {
		//RIGOROUS PASSWORD REQUIREMENTS
		var re = /^(?=.*?[A-Z]).{1,}$/;
		return re.test(password);
	};
	const validatePasswordLower = (password) => {
		//RIGOROUS PASSWORD REQUIREMENTS
		var re = /^(?=.*?[a-z]).{1,}$/;
		return re.test(password);
	};
	const validatePasswordNumber = (password) => {
		//RIGOROUS PASSWORD REQUIREMENTS
		var re = /^(?=.*?[0-9]).{1,}$/;
		return re.test(password);
	};
	const validatePasswordSpecial = (password) => {
		//RIGOROUS PASSWORD REQUIREMENTS
		var re = /^(?=.*?[#?!@$%^&*-]).{1,}$/;
		return re.test(password);
	};

	const goHome = (authUser) => {
		console.log("SIGNIN component !!!!!!!!!!!!!!        goHome            !!!!!!!!!!!!!!!!!")
		setOverrideTheme(false);
		setEmail('');
		setPassword('');
		setError(null);
		console.log("about to firebase")
		firebase
			.user(authUser.user.uid)
			.get()
			.then((snapshot) => {
				const lastVisited = snapshot.val().lastVisited;
				//ONLY GO TO USER INFO IF THEY HAVE NOT SET IT ALREADY. ELSE GO HOME
				const homePath = lastVisited
					? `${process.env.PUBLIC_URL}/${lastVisited[siteID].chapter}/${lastVisited[siteID].moduleID}`
					: ROUTES.INTRO;

				if (snapshot.exists() && snapshot.val().firstname) {
					snapshot.val().welcomeViewed
						? (() => history.push(homePath))()
						: (() => history.push({ pathname: ROUTES.WELCOME, search: history.location.search }))();
				} else {
					history.push({ pathname: ROUTES.USER_INFO, search: history.location.search });
				}
			})
			.catch((error) => {
				history.push({ pathname: ROUTES.USER_INFO, search: history.location.search });
			});
	};
	const [emailSubmitted, setEmailSubmitted] = useState(false);
	const [forgetPasswordOpen, setForgetPasswordOpen] = useState(false);
	// const [userData, setUserData] = useState(null);
	const [showPassword, setShowPassword] = useState(false);

	const [email, setEmail] = useState(''); //CAN BE EMAIL OR PHONE
	const progress = 0;
	const lastVisited = 0;

	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	useEffect(() => {
		console.log('emailSubmitted', emailSubmitted);
		if (emailSubmitted) {
			//FOCUS ON PASSWOrd INPUT
			console.log('inputPassword', inputPassword.current);
			inputPassword.current.focus();
		}
	}, [emailSubmitted]);
	useEffect(() => {
		TrackPage(ga4);
	}, []);
	const onSubmit = (event) => {
		if (validateEmail(email)) {
			setError(null);

			setEmailSubmitted(true);
		} else {
			//we dont let user submit without valid email.  this never happens
			setError(null);
		}

		event.preventDefault();
	};

	const signInExistingUser = () => {
		console.log("signInExistingUser")
		firebase
			.doSignInWithEmailAndPassword(email, password)
			.then((authUser) => {
				console.log("doSignInWithEmailAndPassword authUser",authUser)
				TrackEvent(ga4, `sign_in`, 'click', 'user_action');
				goHome(authUser);
			})
			.catch((error) => {
				console.log('sign in password error', error);
				if (error.code === 'auth/wrong-password') {
					setError({ message: language['Global_Field_Password_Error'] });
				} else {
					setError(error);
				}
			});
	};
	const onSubmitPassword = (event) => {
		console.log('--------onSubmitPassword');
		if (validatePassword(password)) {
			//TRY TO SIGN UP -- SEND TO ENTER REQUIRED USER INFO
			firebase
				.doCreateUserWithEmailAndPassword(email, password)
				.then((authUser) => {
					// Create a user in your Firebase realtime database
					firebase.user(authUser.user.uid).set({
						email,
						progress,
						lastVisited
					});
					TrackEvent(ga4, `new_user_registered`, 'click', 'user_action');
					console.log("this is a new user");
					goHome(authUser);
				})
				.catch((error) => {
					if (error.code === 'auth/email-already-in-use') {
						//USER EXISTS! SIGN IN
						signInExistingUser();
					} else {
						console.log('error is', error);
						setError(error);
					}
				});
		} else {
			//TO DO:  UPDATE COPY FOR THIS SO THAT IT REFERENCE THAT THE PASSWORD IS NOT IN A VALID FORMAT
			setError({ message: language['Global_Field_Password_CreateError'] });
		}

		event.preventDefault();
	};

	const togglePasswordVisible = () => {
		TrackEvent(ga4, `toggle_password_${showPassword ? 'hide' : 'show'}`, 'click', 'user_action');
		setShowPassword(showPassword ? false : true);
	};
	const onChangeEmail = (event) => {
		setError(null);
		setEmail(event.target.value);
		event.preventDefault();
	};
	const onChangePassword = (event) => {
		setError(null);
		setPassword(event.target.value);
		event.preventDefault();
	};
	const onSubmitForgot = () => {
		TrackEvent(ga4, `forgot_password_submit`, 'click', 'user_action');
		setForgetPasswordOpen(false);
	};

	const isInvalid = !validateEmail(email);
	//remove password validation to submit
	const isInvalidPassword = false; //!validatePassword(password);
	const isInvalidPasswordLength = !validatePasswordLength(password);
	const isInvalidPasswordLower = !validatePasswordLower(password);
	const isInvalidPasswordUpper = !validatePasswordUpper(password);
	const isInvalidPasswordNumber = !validatePasswordNumber(password);
	const isInvalidPasswordSpecial = !validatePasswordSpecial(password);
	return (
		<motion.div
			className={`${Styles.signinHolder}`}
			initial={{ opacity: 0, y: -200 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 100 }}
			transition={{ duration: 0.25, ease: 'easeOut' }}
		>
			<motion.div
				animate={!emailSubmitted ? 'open' : 'closed'}
				variants={variants}
				initial={{ opacity: 0, y: 100 }}
				// transition={{delay: 1.5}}
			>
				<div className={Styles.signInwrapper}>
					{/* <div id="recaptcha-container"> </div> */}
					<div className={Styles.emailWrapper}>
						<input
							name="email"
							value={email}
							onChange={onChangeEmail}
							type="email"
							className={clsx('email-input', error?.message ? 'error' : '')}
							placeholder={language['Global_Field_EnterEmail']}
							onKeyDown={(e) => e.keyCode === 13 && onSubmit(e)}
							autoFocus
						/>
						<div
							className={`${Styles.submitLogin} ${isInvalid ? Styles.inactive : ''}`}
							onClick={!isInvalid ? onSubmit : null}
						>
							<img
								src={process.env.PUBLIC_URL + '/assets/images/chevron-right-white.svg'}
								className={`${Styles.chevronImage}`}
								alt="chevron right"
							/>
						</div>
					</div>
					{error && <div className={Styles.errorMessage}>{error.message}</div>}
				</div>
				<div
					className={Styles.forgotPasswordWrapper}
					onClick={() => {
						setForgetPasswordOpen(true);
					}}
				>
					{language['Global_Field_Password_Forgot']}
				</div>
			</motion.div>

			<motion.div
				className={Styles.forgetDrawerHolder}
				animate={forgetPasswordOpen ? 'open' : 'closed'}
				variants={drawerVariants}
				initial={{ x: '100%' }}
			>
				<div
					className={Styles.forgetDrawer}
					style={{ background: `url('${process.env.PUBLIC_URL}/assets/images/bg_grey.png') center / cover` }}
				>
					{forgetPasswordOpen && (
						<React.Fragment>
							<div onClick={() => setForgetPasswordOpen(false)} className={Styles.exitDrawerButton}>
								<img src={process.env.PUBLIC_URL + '/assets/images/exit.svg'} />
							</div>
							<PasswordForgetPage onSubmitForgotDone={onSubmitForgot} />
						</React.Fragment>
					)}
				</div>
			</motion.div>
			<motion.div
				animate={emailSubmitted ? 'open' : 'closed'}
				variants={variants}
				initial={{ display: 'none', opacity: 0, y: 100 }}
				onAnimationStart={() => {
					console.log(inputPassword.current);
					inputPassword.current.focus();
				}}
			>
				<div className={Styles.signInwrapper}>
					<div className={Styles.passwordLogIndWrapper}>
						<div className={Styles.passwordInputWrapper}>
							<input
								id="password"
								name="password"
								value={password}
								ref={inputPassword}
								onChange={onChangePassword}
								type={showPassword ? 'text' : 'password'}
								placeholder={language['Global_Field_Password']}
								onKeyDown={(e) => e.keyCode === 13 && onSubmitPassword(e)}
							/>
							<div className={Styles.showHidePasswordWrapper}>
								<span className={Styles.showHidePasswordText} onClick={togglePasswordVisible}>
									{showPassword ? language['Global_Password_Hide'] : language['Global_Password_Show']}
								</span>
								<div
									className={`${Styles.submitPassword} ${isInvalidPassword ? Styles.inactive : ''}`}
									onClick={!isInvalidPassword ? onSubmitPassword : null}
								>
									<img
										src={process.env.PUBLIC_URL + '/assets/images/chevron-right-white.svg'}
										className={`${Styles.chevronImage}`}
										alt="chevron right"
									/>
								</div>
							</div>
						</div>
						{error && <p className={Styles.errorMessage}>{error.message}</p>}

						<div className={Styles.passwordRules}>{language['Global_Password_Rules_Header']}</div>
						<div className={`${Styles.passwordRules} ${!isInvalidPasswordLength ? Styles.ruleValid : ''}`}>
							<img
								className={Styles.checkMark}
								src={!isInvalidPasswordLength ? CheckMark : CheckMarkInvalid}
								alt="Check Mark"
							/>
							{language['Global_Password_Rule_Length']}
						</div>

						<div className={`${Styles.passwordRules} ${!isInvalidPasswordUpper ? Styles.ruleValid : ''}`}>
							<img
								className={Styles.checkMark}
								src={!isInvalidPasswordUpper ? CheckMark : CheckMarkInvalid}
								alt="Check Mark"
							/>
							{language['Global_Password_Rule_Uppercase']}
						</div>
						<div className={`${Styles.passwordRules} ${!isInvalidPasswordLower ? Styles.ruleValid : ''}`}>
							<img
								className={Styles.checkMark}
								src={!isInvalidPasswordLower ? CheckMark : CheckMarkInvalid}
								alt="Check Mark"
							/>
							{language['Global_Password_Rule_Lowercase']}
						</div>
						<div className={`${Styles.passwordRules} ${!isInvalidPasswordNumber ? Styles.ruleValid : ''}`}>
							<img
								className={Styles.checkMark}
								src={!isInvalidPasswordNumber ? CheckMark : CheckMarkInvalid}
								alt="Check Mark"
							/>
							{language['Global_Password_Rule_Number']}
						</div>
						<div className={`${Styles.passwordRules} ${!isInvalidPasswordSpecial ? Styles.ruleValid : ''}`}>
							<img
								className={Styles.checkMark}
								src={!isInvalidPasswordSpecial ? CheckMark : CheckMarkInvalid}
								alt="Check Mark"
							/>
							{language['Global_Password_Rule_Special']}
						</div>
					</div>
				</div>
			</motion.div>

			{/* 
						<SignUpLink /> */}
		</motion.div>
	);
};

export default withFirebase(SignInPage);
