import React from 'react'
import {ReactSVG} from 'react-svg'
import Styles from './PlayButton.module.scss'
import { motion } from "framer-motion"

export default function PlayButton({isActive, audioProgress, complete,languageID}) {
  const circumference = 18 * 2 * Math.PI
  const strokeDasharray = `${circumference} ${circumference}`
  const strokeDashoffset = setProgressAudio(audioProgress);
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  function setProgressAudio(progress) {
    return circumference - progress * circumference;
  }

  function renderContent() {
    if (isActive) {
      return (
        <motion.svg  
          initial="hidden"
          animate="visible"
          variants={variants} 
          className={Styles.image}  
          xmlns="http://www.w3.org/2000/svg" 
          width="37" 
          height="37" 
          viewBox="0 0 37 37"
        >
          <g id="Ellipse_701" data-name="Ellipse 701" fill="none" stroke="#fff" strokeWidth="1" opacity="0.7">
          <circle strokeDasharray={strokeDasharray} strokeDashoffset={strokeDashoffset} id="circle" cx="18.5" cy="18.5" r="18" fill="none"/>
        </g>
        <path id="pause" d="M6.161,10.4a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1H7.6a1,1,0,0,1,1,1V9.4a1,1,0,0,1-1,1ZM1,10.4a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H2.439a1,1,0,0,1,1,1V9.4a1,1,0,0,1-1,1Z" transform="translate(13.702 13.349)" fill="#fff" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
      </motion.svg> 
      ); 
    } 
    if (complete) {
      return (
        <motion.div
        initial="hidden"
        animate="visible"
        variants={variants} 
      >
        <ReactSVG 
          className={Styles.image} 
          src={process.env.PUBLIC_URL + '/assets/images/use-case-checkmark.svg'} 
        />
      </motion.div>
      )
    }
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants} 
      >
        <ReactSVG 
          className={Styles.image} 
          src={process.env.PUBLIC_URL + '/assets/images/play-btn.svg'} 
        />
      </motion.div>
    ) 
  }
  return (
    <div className={`${Styles.wrapper} ${languageID === "JA_JP" ? Styles.japan : null}`}>
      {renderContent()}
    </div>
  );
}