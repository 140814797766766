import React from 'react'

import Styles from './Hotspot.module.scss'

const Hotspot = () => {
    return (
        <div className={Styles.buttonContainer}>
            <div className={Styles.outterCircle} />
            <div className={Styles.middleCircle} />
            <div className={Styles.innerCircle} />
        </div>
    )
}

export default Hotspot
