import React, { useContext } from 'react'
// import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import Context from '../../../../../context/Context'
import Styles from './InteractiveStep.module.scss'

export default function InteractiveStep({ step, index, activeStep, onClick }) {
  const context = useContext(Context)
  const { language } = context

  function renderStep() {
    // const active = activeStep?.index === index

    return (
      <div className={`${Styles.interactiveStep}`} style={{ '--steps-main-color': '#000000' }} onClick={onClick}>
        <div className="iconWrapper">
          <ReactSVG className="icon" src={process.env.PUBLIC_URL + step.icon} />
        </div>
        <p className="text" dangerouslySetInnerHTML={{ __html: language[step.text] }} />
      </div>
    )
  }

  return renderStep()
}
