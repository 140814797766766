import React, { useContext, forwardRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import useCanvasSize from '../../../../../hooks/useCanvasSize';

import Context from '../../../../../context/Context';

import Styles from './Hotspot.module.scss';

const Hotspot = forwardRef(({ hotspotCoordinates, origin, theme }, ref) => {
  const context = useContext(Context);
  const [position, setPosition] = useState();
  const CANVAS_SIZE = useCanvasSize();
  const { setForceDefaultCursor } = context;

  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };

  const getHotspotPosition = ref => {
    const setY =
      window.innerHeight / 2 + (ref.clientHeight / 2) * hotspotCoordinates.y;
    const setX =
      window.innerWidth / 2 + (ref.clientWidth / 2) * hotspotCoordinates.x;

    return { y: `${setY}px`, x: `${setX}px` };
  };

  useEffect(() => {
    if (origin) {
      if (origin.clientWidth) {
        setPosition(getHotspotPosition(origin));
      } else {
        origin.onload = () => {
          setPosition(getHotspotPosition(origin));
        };
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, CANVAS_SIZE]);

  if (!position) return null;

  return (
    <div
      className={Styles.pressAndHoldContainerHolder}
      style={{
        '--poition-height': position.y,
        '--poition-width': position.x,
        '--themecurr-color': theme || '#FFF',
      }}
    >
      <motion.div
        className={Styles.pressAndHoldContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={ref}
      >
        <div className={Styles.buttonContainer}>
          <div className={Styles.outterCircle} />
          <div className={Styles.middleCircle} />
          <div className={Styles.innerCircle} />
        </div>
      </motion.div>
    </div>
  );
});

export default Hotspot;
