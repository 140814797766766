import { useContext } from "react";
import Context from "../../context/Context";

const NextButton = (props) => {
  const { filled, children } = props;
  const context = useContext(Context);

  const { theme, overrideTheme } = context
  const translateThemes = {"light" : "#fff", "dark" : "#464A4A"}
  const translateInverse = {"light" : "#000", "dark" : "#fff"}

  return filled ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
    >
      <circle
        cx="38"
        cy="38"
        r="38"
        transform="translate(0 76) rotate(-90)"
        fill={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
      />
      {children ? (
        <text
          x="38"
          y="42"
          textAnchor="middle"
          fontSize="12"
          style={{ textTransform: "uppercase" }}
        >
          {children}
        </text>
      ) : (
        <path
          d="M29.007,41.487v-12h12"
          transform="translate(85.218 38.34) rotate(135)"
          fill="none"
          stroke={overrideTheme ? "#000" : theme ? translateInverse[theme] : "#fff"}
          strokeLinecap="round"
          strokeWidth="1"
        />
      )}
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 59 59"
    >
      <g transform="translate(0.5 0.5)">
        <path
          d="M28,56A28,28,0,1,0,0,28,28,28,0,0,0,28,56Z"
          transform="translate(0 0)"
          fill="none"
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeWidth="1"
        />
        <path
          d="M0,9.871,5.175,4.936,0,0"
          transform="translate(26.32 23.66)"
          fill="none"
          stroke={overrideTheme ? "#fff" : theme ? translateThemes[theme] : "#fff"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
export default NextButton;
