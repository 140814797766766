import { useEffect, useState } from "react";
import useWindowSize from "../utils/UseWindowSize.js";

const CANVAS_SIZE_DESKTOP = 2560;

const useCanvasSize = (props) => {
  const windowSize = useWindowSize();
  const [canvasSize, setCanvasSize] = useState(
    !windowSize.width ?
    CANVAS_SIZE_DESKTOP :
    Math.min(CANVAS_SIZE_DESKTOP, windowSize.width * 2)
  );

  useEffect(() => {
    if (!windowSize.width) {
      setCanvasSize(CANVAS_SIZE_DESKTOP);
    } else {
      setCanvasSize(Math.min(CANVAS_SIZE_DESKTOP, windowSize.width * 2));
    }
  }, [windowSize]);

  return canvasSize;
};

export default useCanvasSize;
