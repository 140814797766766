import clsx from 'clsx';
import BaseModule from '../BaseModule';
import Styles from './TextModule.module.scss';
import Context from '../../../context/Context';
import { useContext, useEffect } from 'react';
import useCanvasSize from '../../../hooks/useCanvasSize';
import * as SITEDATA from '../../../constants';

export default function TextModule(props) {
	const { module, videoContext } = props;
	const { languageID, siteID } = useContext(Context);
	const CANVAS_SIZE = useCanvasSize();
	const replaceCopyNotifier = 'Be a dear... and <em>update the translation!</em>';

	const CUTOFF_LANG = siteID ? SITEDATA[`${siteID}_CUTOFF_LANG`] : SITEDATA[`studioBuds_CUTOFF_LANG`]

	useEffect(() => {
		const cvsTime = setTimeout(() => {
			if (videoContext) {
				videoContext.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
			}
		},10);
		return () => {
			clearTimeout(cvsTime);
		}
	}, [])

	return (
		<Context.Consumer>
			{({ language }) => {
				return (
					<BaseModule {...props} className={clsx(props.className, Styles.root, 'TextModule')}>
						<div className={Styles.container}>
							<div className={Styles.eyebrow}>{module.content.eyebrow ? module.content.eyebrow : ''}</div>
							<h1 className={Styles.text}>
								<span
									className={`${CUTOFF_LANG.includes(languageID) ? Styles.cutoff : ''}`}
									dangerouslySetInnerHTML={{
										__html: language[module.content.text]
											? language[module.content.text]
											: replaceCopyNotifier,
									}}
								/>
							</h1>
						</div>
					</BaseModule>
				);
			}}
		</Context.Consumer>
	);
}
