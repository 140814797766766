import React, { useEffect, useState, useContext } from 'react';
import Context from '../../context/Context';
import { AuthUserContext } from '../Session';
import { getProgress } from '../../utils';
import Styles from './header.module.scss';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { useLocation } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
const Header = props => {
  const { isHeaderVisible } = useContext(Context);
  const location = useLocation();
  const [cookieLanguage, setCookieLanguage] = useState(
    cookie.load('cr_trnr_lang')
  );
  useEffect(() => {
    setCookieLanguage(cookie.load('cr_trnr_lang'));
  }, [cookieLanguage]);

  // const getLogo = () => {
  // 	return (
  // 		<div className={`${Styles.logo}`}>
  // 			<img src={logo} alt="Logo" />
  // 		</div>
  // 	);
  // };

  return (
    <>
      <AuthUserContext.Consumer>
        {({ authUser, progress, firstName }) => {
          return (
            <Context.Consumer>
              {({
                language,
                currentChapter,
                overRideChapter,
                menuActive,
                isKioskVersion,
                progressKiosk,
                setMenuActive,
                siteID,
                theme,
                overrideTheme,
              }) => {
                const clickLogo = () => {
                  setMenuActive(false);
                };
                const chapterTitle = overRideChapter
                  ? language[overRideChapter]
                  : currentChapter
                  ? language[currentChapter.title]
                  : '';
                const progressText = language[
                  'Global_UI_PercentageComplete'
                ].replace(
                  '<<number>>',
                  isKioskVersion
                    ? getProgress(progressKiosk, siteID)
                    : getProgress(progress, siteID)
                );

                return ((authUser && firstName) ||
                  (isKioskVersion && isHeaderVisible)) &&
                  location.pathname !== ROUTES.COMPANYREQUIRED && 
                  siteID ? (
                  <div id={`${Styles.header}`}>
                    <div className={`${Styles.logoLockup}`}>
                      <Link to={ROUTES.INTRO} onClick={clickLogo}>
                        {/* {getLogo()} */}
                        <img
                          className={`${Styles.logo}`}
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/global/images/b-logo-${
                            overrideTheme ? 'light' : theme ? theme : 'light'
                          }.svg`}
                          alt="beats logo"
                        />
                      </Link>
                      <div
                        className={`${Styles.vl} ${
                          overrideTheme
                            ? Styles.light
                            : theme
                            ? Styles[theme]
                            : Styles.light
                        }`}
                      ></div>
                      <div
                        className={`${Styles.chapter} ${
                          overrideTheme
                            ? Styles.light
                            : theme
                            ? Styles[theme]
                            : Styles.light
                        }`}
                      >
                        <div>
                          {chapterTitle ? chapterTitle.toUpperCase() : null}
                        </div>
                      </div>
                    </div>
                    {/* {getLanguage(languageName)} */}
                    <div className={`${Styles.progress}`}>
                      {menuActive ? '' : progressText}
                    </div>
                  </div>
                ) : (
                  <div id={`${Styles.headerNotLoggedIn}`}>
                    <div className={Styles.logoMobileWrapper}>
                      <img
                        className={`${Styles.logo}`}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/global/images/b-logo-${
                          overrideTheme ? 'light' : theme ? theme : 'light'
                        }.svg`}
                        alt="beats logo"
                      />
                      {/* {getLogo()} */}
                      {/* {getLanguage(languageName)} */}
                    </div>
                  </div>
                );
              }}
            </Context.Consumer>
          );
        }}
      </AuthUserContext.Consumer>
    </>
  );
};

export default Header;
