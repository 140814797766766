const copy = {
	Global_UI_Next: 'SUIVANT',
Global_UI_Begin: 'COMMENCER',
Global_UI_Back: 'RETOUR',
Global_UI_Settings: 'Réglages',
Global_UI_Signout: 'Se déconnecter',
Global_UI_NextChapter: 'CHAPITRE SUIVANT',
Global_UI_PercentageComplete: 'TERMINÉ À <<number>>%',
Global_UI_Replay: 'REVOIR',
Global_Field_Location: 'EMPLACEMENT',
Global_Languages_Select: 'CHOISISSEZ VOTRE LANGUE :',
Global_Field_EnterEmail: 'Saisissez une adresse e-mail',
Global_Field_CreatePassword: 'Créez un mot de passe',
Global_Field_Password: 'Mot de passe',
Global_Field_Password_Forgot: 'MOT DE PASSE OUBLIÉ',
Global_Password_Subtitle: 'LE MOT DE PASSE DOIT CONTENIR AU MOINS 8 CARACTÈRES ET INCLURE DES LETTRES EN MAJUSCULES ET MINUSCULES, DES CHIFFRES ET DES SYMBOLES.',
Global_Password_Change: 'MODIFIER',
Global_Password_Reset_Headline: 'Réinitialiser le mot de passe',
Global_Password_Reset_Subtitle: 'Saisissez votre adresse e-mail afin de recevoir des instructions pour la réinitialisation de votre mot de passe.',
Global_Password_Reset_Confirm: 'Envoyé ! Merci de consulter vos e-mails.',
Global_Password_ChangeConfirm: 'Mot de passe mis à jour',
Global_Password_Show: 'AFFICHER',
Global_Password_Hide: 'MASQUER',
Global_Details_Title: 'Informations supplémentaires',
Global_Details_Field_FirstName: 'Prénom',
Global_Details_Field_LastName: 'Nom',
Global_Details_Field_EmployeeID: 'Identifiant d\'employé (facultatif)',
Global_Details_Field_BusinessRole: 'Rôle',
Global_Details_Field_BusinessRole1: 'Responsable',
Global_Details_Field_BusinessRole2: 'Commercial',
Global_Details_Field_BusinessRole3: 'Formateur',
Global_Details_Field_CompanyName: 'Nom de l\'entreprise :',
Global_Details_Field_Country: 'Pays',
Global_Field_Password_Error: 'Le mot de passe saisi est incorrect. Veuillez réessayer.',
Global_Field_Email_Error: 'L\'adresse e-mail saisie n\'est pas valide. Veuillez réessayer.',
Global_Field_Details_Error: 'Veuillez remplir tous les champs obligatoires.',
Global_Greeting: 'Bonjour <<name>>:',
Global_CompletionMessage: 'Vous avez terminé <<number>>% de la formation.',
Global_Chapter: 'CH. <<number>>',
 Global_Chapter_Subtitle: 'CH. <<number>> SUR <<total>>',
Global_Intro: 'Intro',
Global_ChapterTitle_Sound: 'Son',
Global_ChapterTitle_ANC: 'ANC',
Global_ChapterTitle_Transparency: 'Mode Transparence',
Global_ChapterTitle_Design: 'Design',
Global_ChapterTitle_Compatibility: 'Compatibilité',
Global_ChapterTitle_Controls: 'Commandes',
Global_ChapterTitle_Battery: 'Batterie',
Global_Menu: 'Menu',
Intro_Welcome_Label: 'EXPÉRIENCE',
Intro_Welcome_ChapterText: 'Bienvenue dans la formation « Expérience Beats Studio Buds ». Touchez ou cliquez sur les différents éléments pour les découvrir.',
CR_TRNR_Intro_Sorry: 'Nous sommes désolés. Une erreur s\'est produite. Veuillez contacter votre superviseur pour obtenir un nouveau lien d\'accès.',
Intro_Intro_Subtitle: 'Découvrez les Beats Studio Buds. Écouteurs totalement sans fil avec réduction du bruit et son de qualité studio. Touchez ou cliquez pour explorer.',
Sound_Intro_Title: 'Son',
Sound_Intro_VOSubtitle: 'Une ingénierie avancée pour un son puissant et équilibré.',
Sound_ChapterText1: 'Pour profiter d\'un son d\'excellente qualité,<em>vous avez besoin d\'un confort sur mesure et d\'une isolation acoustique.</em>',
Sound_FindYourFit_Title: 'Trouvez l\'embout qui vous correspond et laissez-vous emporter par la musique.',
Sound_FindYourFit_Subtitle: 'Trois tailles d\'embout en silicone pour un confort absolu, une isolation acoustique sur mesure et une immersion totale.',
Sound_FindYourFit_Medium: 'MOYEN (PAR DÉFAUT)',
Sound_FindYourFit_Small: 'PETIT',
Sound_FindYourFit_Large: 'GRAND',
Sound_ChapterText2: 'Les Beats Studio Buds ont été conçus pour offrir <em>un son puissant et équilibré</em> dans un format compact, au moyen d\'une <em>plateforme acoustique entièrement personnalisée.</em>',
Sound_DrivenByPremiumSound_Title: 'Tout pour un son Premium',
Sound_DrivenByPremiumSound_Subtitle: 'Une plateforme acoustique personnalisée, incluant un transducteur exclusif doté d\'un diaphragme à deux couches, offre un son pur et équilibré.',
Sound_MoreMusicLessDistortion_Title: 'Son pur. Faible distorsion.',
Sound_MoreMusicLessDistortion_Subtitle: 'Le matériau souple du diaphragme du transducteur réduit les distorsions liées aux vibrations, offrant un son pur et précis, sur l\'ensemble du spectre acoustique, notamment les basses.',
Sound_MoreMusicLessDistortion_Diaphragm: 'DIAPHRAGME',
Sound_FineTunedAudio_Title: 'Un son de grande précision',
Sound_FineTunedAudio_Subtitle: 'Un processeur numérique avec correction de lecture répond en temps réel pour s\'assurer que les sons extérieurs n\'affectent pas la clarté et l\'émotion de la musique.',
Sound_ChapterText3: 'Les appels téléphoniques sont clairs avec <em>des performances d\'appel haute qualité et une meilleure clarté de la voix.</em> ',
Sound_SpeakingOfSound_Title: 'Une qualité d\'appel remarquable ',
Sound_SpeakingOfSound_Subtitle: 'Un port spécialement conçu isole le micro du bruit du vent, tandis que les deux micros beamforming captent votre voix pour en améliorer la clarté.',
Sound_Summary_Title: 'Conçus pour restituer fidèlement l\'intention de l\'artiste',
Sound_Summary1_ThreeEartip: 'Trois tailles d\'embout vous offrent un confort sur mesure pour un son exceptionnel.',
Sound_Summary2_CustomAcoustic: 'La plateforme acoustique personnalisée vous offre un son puissant et de haute qualité.',
Sound_Summary3_FlexibleDriverDiaphragm: 'Le transducteur à diaphragme souple produit un son clair et précis.',
Sound_Summary4_FinetunedAudio: 'Un son de grande précision préserve la clarté et l\'émotion de la musique.',
Sound_Summary5_SpeakingOfSound: 'Les deux microphones beamforming captent votre voix pour des performances d\'appel de haute qualité.',
ANC_Intro_TITLE: 'Mode Réduction active du bruit ',
ANC_Intro_VOSubtitle: 'Restez concentré et bloquez les distractions grâce au mode Réduction active du bruit.',
ANC_ChapterText1: '<em>Une isolation acoustique confortable</em> permet de bloquer les bruits extérieurs pour une expérience d\'écoute vraiment immersive.',
ANC_Seal_Title: 'Isolation acoustique',
ANC_Seal_Subtitle: 'Trois tailles d\'embout vous permettent de choisir un ajustement optimal et de profiter d\'une isolation acoustique qui bloque les bruits indésirables.',
ANC_ChapterText2: 'Le mode Réduction active du bruit avec technologie adaptative<em> s\'ajuste à votre environnement,</em> tandis que la Correction de lecture <em>préserve la clarté et l\'émotion de la musique.</em>',
ANC_ANCThatAdapts_Title: 'Un mode Réduction active du bruit adaptatif',
ANC_ANCThatAdapts_Subtitle: 'Un filtre parfaitement optimisé s\'ajuste de façon dynamique pour filtrer les bruits indésirables et optimiser la réduction du bruit en temps réel.',
ANC_ANCThatAdapts_AdaptiveANC: 'Mode Réduction active du bruit adaptatif',
ANC_Playback_Title: 'Correction de lecture',
ANC_Playback_Subtitle: 'Lorsque la réduction du bruit est appliquée, la Correction de lecture compare le fichier audio au fichier audio d\'origine 48 000 fois par seconde et s\'assure que le mode Réduction active du bruit n\'interfère jamais avec votre musique.',
ANC_Playback_ANCProcess: 'PROCESSUS DU MODE RÉDUCTION ACTIVE DU BRUIT',
ANC_Playback_PlaybackCorrection: 'CORRECTION DE LECTURE',
ANC_UseCases_Subtitle: 'Touchez ou cliquez sur chaque cas d\'utilisation pour en savoir plus. ',
ANC_UseCases_Studying: 'Révision',
ANC_UseCases_Studying_Subtitle: 'Bloquez les distractions et restez concentré sur votre travail.',
ANC_UseCases_WorkingOut: 'Entraînement',
ANC_UseCases_WorkingOut_Subtitle: 'Immergez-vous dans votre entraînement sans aucune distraction.',
ANC_UseCases_Airplane: 'Avion',
ANC_UseCases_Airplane_Subtitle: 'Supprimez le bruit des passagers ou d\'un moteur bruyant et profitez au mieux de votre vol.',
ANC_UseCases_Commute: 'Trajets quotidiens',
ANC_UseCases_Commute_Subtitle: 'Effectuez vos trajets quotidiens en réduisant les bruits de la ville.',
ANC_Summary_Title: 'Restez concentré avec le mode Réduction active du bruit.',
ANC_Summary1_ImmerseSound: 'Un son immersif commence par une isolation acoustique et un confort optimaux.',
ANC_Summary2_AdaptiveTechnology: 'La technologie adaptative s\'adapte en permanence à votre environnement.',
ANC_Summary3_PlaybackCorrection: 'La Correction de lecture préserve la clarté du son.',
ANC_Summary4_UseCases: 'Servez-vous du mode Réduction active du bruit pendant vos révisions, vos entraînements, vos voyages en avion et vos trajets quotidiens.',
Transparency_Intro_Title: 'MODE TRANSPARENCE ',
Transparency_Intro_VOSubtitle: 'Restez à l\'écoute du monde avec le mode Transparence.',
Transparency_ChapterText1: 'Passez au mode Transparence pour rester <em>attentif à votre environnement.</em>',
Transparency_TransparencyMode_Title: 'Mode Transparence ',
Transparency_TransparencyMode_Subtitle: 'Le mode Transparence intègre les sons de votre environnement à votre musique, pour une expérience d\'écoute naturelle et pleinement « ouverte ».',
Transparency_TransparencyMode_PressHold: 'APPUI PROLONGÉ',
Transparency_ChapterText2: '<em>Les micros externes</em> captent le bruit extérieur.',
Transparency_FeedForwardMic_Title: 'Micros feed-forward',
Transparency_FeedForwardMic_Subtitle: 'Un micro feed-forward présent sur chaque écouteur détecte les bruits extérieurs dans votre environnement.',
Transparency_FeedForwardMic_FeedForwardMic: 'MICRO FEED-FORWARD',
Transparency_UseCases_Subtitle: 'Touchez ou cliquez sur chaque cas d\'utilisation pour en savoir plus. ',
Transparency_UseCases_Office: 'Au bureau',
Transparency_UseCases_Office_Subtitle: 'Continuez à travailler en collaboration et entendez vos collègues tout en écoutant de la musique.',
Transparency_UseCases_Travel: 'En voyage',
Transparency_UseCases_Travel_Subtitle: 'Écoutez les annonces importantes, par exemple, le numéro de porte d\'un aéroport ou du quai d\'une gare.',
Transparency_UseCases_Street: 'Dans la rue',
Transparency_UseCases_Street_Subtitle: 'Restez attentif au trafic environnant et aux intersections animées.',
Transparency_Summary_Title: 'Restez à l\'écoute du monde avec le mode Transparence.',
Transparency_Summary1_MixTheSounds: 'Intégrez les sons de votre environnement à votre musique.',
Transparency_Summary2_FeedForward: 'Les micros feed-forward détectent les bruits extérieurs dans votre environnement.',
Transparency_Summary3_HearYourSurroundings: 'Restez attentif à votre environnement au bureau, en voyage ou dans la rue.',
Design_Intro_Title: 'Design',
Design_Intro_VOSubtitle: 'Les Beats Studio Buds sont conçus pour vous faire profiter du sans-fil en toute liberté.',
Design_ChapterText1: 'Leur design compact et sans fil <em>s\'intègre parfaitement à votre vie.</em>',
Design_WirelessFreedom_Title: 'La liberté du sans-fil ',
Design_WirelessFreedom_Subtitle: 'Des écouteurs totalement sans fil pour une liberté de mouvement totale. ',
Design_PocketSizedPortability_Titles: 'Facilité de transport',
Design_PocketSizedPortability_Subtitles: 'Leur étui de charge fin et ultraléger se glisse dans votre poche et vous permet de les emmener partout.',
Design_WaterAndSweatResistant_Title: 'Des écouteurs résistants à l\'eau et à la transpiration*  ',
Design_WaterAndSweatResistant_Subtitle: 'Les écouteurs sont résistants à l\'eau et à la transpiration (indice IPX4) et vous permettent d\'affronter les éléments.',
Design_WaterAndSweatResistant_Disclaimer: '* Les Beats Studio Buds sont résistants à l’eau et à la transpiration pour les sports et les activités non aquatiques.',
Design_ChapterText2: 'Les Beats Studio Buds ont été testés sur un large public pour garantir <em>une ergonomie confortable et adaptable.</em>',
Design_Comfort_Title: 'Conçus pour votre confort',
Design_Comfort_Subtitle: 'Les écouteurs souples en silicone sont conçus selon l\'anatomie de l\'oreille pour garantir une stabilité: \' une ergonomie et un confort optimaux.',
Design_Comfort_Tragus: 'TRAGUS',
Design_Comfort_Antitragus: 'ANTITRAGUS',
Design_SizeMatters_Title: 'Design compact',
Design_SizeMatters_Subtitle: 'Ces écouteurs intra-auriculaires au design compact et épuré sont considérablement plus petits et plus légers que la plupart des écouteurs d\'aujourd\'hui.',
Design_SizeMatters_BeatsStudio: 'BEATS STUDIO BUDS',
Design_SizeMatters_BeatsStudio_10_1G: '10,1 g',
Design_SizeMatters_Bose: 'ÉCOUTEURS BOSE QC',
Design_SizeMatters_Bose_18_7G: '18,7 g',
Design_SizeMatters_Sony: 'Sony WF-1000XM3',
Design_SizeMatters_Sony_16_5G: '16,5 g',
Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
Design_SizeMatters_Samsung_12_5G: '12,5 g',
Design_ChapterText3: 'Les écouteurs sont <em>en sécurité et protégés</em> dans un étui de charge pratique, conçu pour vous accompagner toute la journée.',
Design_PerfectMatch_Title: 'Positionnement magnétique',
Design_PerfectMatch_Subtitle: 'Les aimants présents dans l\'étui de charge sont orientés afin que les écouteurs se positionnent parfaitement à chaque fois.',
Design_Contact_Title: 'Contact précis',
Design_Contact_Subtitle: 'Les broches Pogo situées au sein de l\'étui se servent d\'un ressort à lames pour garantir un contact précis entre les écouteurs et l\'étui. Les écouteurs sont donc correctement positionnés pour la charge.',
Design_Contact_PogoPin: 'BROCHE POGO',
Design_FindYourMatch_Title: 'Trouvez le modèle qui vous correspond ',
Design_FindYourMatch_Subtitle: 'Étui de charge assorti',
Design_FindYourMatch_White: 'BLANC',
Design_FindYourMatch_Black: 'NOIR',
Design_FindYourMatch_BeatsRed: 'ROUGE BEATS',
Design_SustainablePackaging_Title: 'Emballage durable',
Design_SustainablePackaging_Subtitle: 'L\'emballage des Beats Studio Buds est composé à 92 % de matières organiques provenant de forêts durables et/ou créées à partir de fibres recyclées.',
Design_Summary_Title: 'Conçus pour vous faire profiter du sans-fil en toute liberté',
Design_Summary1_WirelessFreedom: 'Des écouteurs totalement sans fil pour une liberté de mouvement totale. ',
Design_Summary2_PocketSizedPortability: 'Étui de charge ultraléger qui se range facilement dans votre poche.',
Design_Summary3_WaterAndSweatResistant: 'Écouteurs résistants à l\'eau et à la transpiration (indice IPX4).',
Design_Summary4_Comfort: 'Des écouteurs au design compact et épuré pour un confort et une stabilité d\'exception.',
Design_Summary5_SizeMatters: 'Design intra-auriculaire considérablement plus petit et plus léger que la plupart des écouteurs sans fil.',
Design_Summary6_PerfectMatch: 'Des aimants présents dans l\'étui garantissent le bon positionnement des écouteurs à chaque fois.',
Design_Summary7_Contact: 'Des broches Pogo au sein de l\'étui permettent de positionner parfaitement les écouteurs pour la charge.',
Design_Summary8_FindYourMatch: 'Un étui de charge assorti est disponible en noir, blanc ou rouge Beats.',
Compatibility_Intro_Title: 'Compatibilité',
Compatibility_Intro_VOSubtitle: 'Les Beats Studio Buds sont compatibles aussi bien avec les appareils Apple qu\'avec les appareils Android.',
Compatibility_ChapterText1: 'Utilisez-les facilement avec <em>des appareils Apple ou Android.</em>',
Compatibility_OneTouch_Title: 'Jumelage d\'un seul geste ',
Compatibility_OneTouch_Subtitle: 'Connectez facilement votre appareil Apple<sup>1</sup> ou Android<sup>2</sup> via Bluetooth grâce au jumelage d\'un seul geste. ',
Compatibility_OneTouch_Disclaimer: '1 Le jumelage d\'un seul geste nécessite iOS 14.6 ou version ultérieure. <br/>2 Vous devez activer la localisation pour utiliser l\'Association express. L\'accès complet aux fonctionnalités nécessite un appareil Android 6.0 ou modèle ultérieur avec les services Google Play activés ainsi qu\'un compte Google. ',
Compatibility_VoiceAssistant_Title: 'Assistant vocal ',
Compatibility_VoiceAssistant_Subtitle: 'Les Beats Studio Buds sont compatibles avec votre assistant vocal préféré, qui peut être activé grâce à une fonction « Appui prolongé » personnalisée.',
Compatibility_LocateDevice_Title: 'Trouver vos écouteurs ',
Compatibility_LocateDevice_Subtitle: 'Retrouvez facilement vos écouteurs grâce à la fonctionnalité « Localiser » pour iOS ou « Localiser mon appareil » pour Android. ',
Compatibility_USBCCharging_Title: 'Charge USB-C ',
Compatibility_USBCCharging_Subtitle: 'Le connecteur de charge USB-C fonctionne aussi bien avec les appareils Apple qu\'Android, vous pouvez donc charger vos écouteurs partout. ',
Compatibility_ManageDevice_Title: 'Gérer votre appareil ',
Compatibility_ManageDevice_Subtitle: 'Gérez des fonctionnalités comme la personnalisation « Appui prolongé », les modes d\'écoute, le renommage de votre produit et les mises à jour logicielles via votre appareil Apple ou via l\'app Beats pour Android.',
Compatibility_Summary_Title: 'Compatibles avec les appareils Apple et Android.',
Compatibility_Summary1_OneTouch: 'Jumelage d\'un seul geste pour iOS et Android.',
Compatibility_Summary2_VoiceAssistant: 'Activez votre assistant vocal préféré pour gérer vos appels, votre musique, et bien plus.',
Compatibility_Summary3_LocateDevice: 'Trouvez facilement vos écouteurs à l\'aide de votre appareil.',
Compatibility_Summary4_USBCCharging: 'Le connecteur de charge USB-C garantit une solution de charge universelle.',
Compatibility_Summary5_ManageDevice: 'Gérez votre appareil via iOS ou via l\'app Beats pour Android.',
Control_Intro_Title: 'Commande',
Control_Intro_VOSubtitle: 'Contrôlez votre son et votre journée avec les Beats Studio Buds.',
Control_ChapterText1: 'Gérez votre son avec <em>un accès simple aux commandes.</em>',
Control_OnEarControls_Title: 'Commandes intégrées aux écouteurs ',
Control_OnEarControls_Subtitle: 'Les commandes intégrées aux écouteurs vous permettent de gérer vos appels et votre musique, de passer d\'un mode d\'écoute à l\'autre et d\'activer l\'assistant vocal.<sup>*</sup>',
Control_OnEarControls_Disclaimer: '* Un iPhone doté d\'iOS 14.6 ou d\'une version ultérieure, ou un appareil Android doté de la dernière version de l\'app Beats est requis pour personnaliser la fonction « Appui prolongé ».',
Control_OnEarControls_Instruction: 'Touchez ou cliquez sur chaque commande pour continuer.',
Control_OnEarControls_PlayPause: 'Appuyez pour lire/mettre en pause, répondre/raccrocher. ',
Control_OnEarControls_Skip: 'Appuyez 2 fois pour passer au morceau suivant. ',
Control_OnEarControls_Previous: 'Appuyez 3 fois pour lire le morceau précédent. ',
Control_OnEarControls_ScanForward: 'Appuyez 2 fois et maintenez le bouton enfoncé pour avancer dans le morceau. ',
Control_OnEarControls_ScanBackward: 'Appuyez 3 fois et maintenez le bouton enfoncé pour reculer dans le morceau. ',
Control_OnEarControls_ToggleModes: 'Appuyez de façon prolongée pour passer d\'un mode d\'écoute à l\'autre ou les désactiver, ou pour activer l\'assistant vocal<sup>*</sup>',
Control_PickASide_Title: 'Choisissez votre écouteur',
Control_PickASide_Subtitle: 'À l\'aide d\'iOS ou de l\'app Beats pour Android, personnalisez la fonctionnalité « Appui prolongé » sur chaque écouteur afin qu\'elle active le mode Réduction active du bruit ou votre assistant vocal.<sup>*</sup>',
Control_PickASide_Disclaimer: '* Un iPhone doté d\'iOS 14.6 ou d\'une version ultérieure, ou un appareil Android doté de la dernière version de l\'app Beats est requis pour personnaliser la fonction « Maintenir enfoncé ».',
Control_SayItWithSiri_Title: 'Demandez à Siri ',
Control_SayItWithSiri_Subtitle: 'Gardez les mains libres et contrôlez à tout moment votre musique, vos appels, le volume et bien plus encore grâce à Siri.<sup>*</sup>',
Control_SayItWithSiri_Disclaimer: '* Siri n\'est pas disponible dans toutes les langues ni dans toutes les zones géographiques, et les fonctionnalités proposées peuvent varier selon les régions du monde. Accès à Internet requis. Des frais de données mobiles peuvent s\'appliquer. ',
Control_SayItWithSiri_SiriCommand1: '« Dis Siri, joue ma playlist de révision. »',
Control_SayItWithSiri_SiriCommand2: '« Dis Siri, montre-moi des entreprises dirigées par des femmes aux alentours. »',
Control_SayItWithSiri_SiriCommand3: '« Dis Siri, programme un minuteur pour 10 minutes. »',
Control_Summary_Title: 'Gérez votre son grâce aux commandes intégrées aux écouteurs.',
Control_Summary1_OnEarControls: 'Les commandes intégrées aux écouteurs vous permettent de gérer vos appels et votre musique.',
Control_Summary2_PickASide: 'Personnalisez la fonction « Appui prolongé » sur l\'écouteur gauche et l\'écouteur droit.',
Control_Summary3_SayItWithSiri: 'Accès mains libres à Siri.',
Battery_Intro_Title: 'Batterie',
Battery_Intro_VOSubtitle: 'Restez motivé grâce à une batterie qui tient toute la journée.',
Battery_ChapterText1: '<em>Avec une autonomie d\'une journée,</em> rien ne vous retient.',
Battery_FastFuel_Title: 'Jusqu\'à 8 heures',
Battery_FastFuel_Subtitle: 'Grâce à une puce exceptionnelle, chaque écouteur vous offre jusqu\'à 8 heures d\'écoute.<sup>*</sup>',
Battery_FastFuel_Disclaimer: '* L\'autonomie de la batterie dépend des réglages de l\'appareil, de son environnement, de son utilisation et de nombreux autres facteurs',
Battery_20Hours_Title: 'Jusqu\'à 24 heures',
Battery_20Hours_Subtitle: 'L\'étui de charge, qui tient dans la poche, offre deux charges supplémentaires. Vous obtenez ainsi jusqu\'à 24 heures d\'écoute combinée.<sup>*</sup>',
Battery_20Hours_Disclaimer: '* L\'autonomie de la batterie dépend des réglages de l\'appareil, de son environnement, de son utilisation et de nombreux autres facteurs',
Battery_FuelYourDay_Title: 'Boostés, toute la journée',
Battery_FuelYourDay_Subtitle: 'Si votre batterie est à plat, rechargez-la seulement 5 minutes avec Fast Fuel et profitez d\'1 heure d\'écoute.<sup>*</sup>',
Battery_FuelYourDay_Disclaimer: '* L\'autonomie de la batterie dépend des réglages de l\'appareil, de son environnement, de son utilisation et de nombreux autres facteurs',
Battery_UseCases_Subtitle: 'Touchez ou cliquez sur chaque cas d\'utilisation pour en savoir plus. ',
Battery_UseCases_Listen: 'Écouter',
Battery_UseCases_Listen_Subtitle: 'Écoutez 12 semaines de vos émissions radio préférées.<sup>*</sup>',
Battery_UseCases_Listen_Disclaimer: '* Basé sur des sessions d\'écoute de 2 heures',
Battery_UseCases_Meditate: 'Méditation',
Battery_UseCases_Meditate_Subtitle: 'Détendez-vous avec 32 méditations au son de la pluie.<sup>*</sup>',
Battery_UseCases_Meditate_Disclaimer: '* Basé sur des sessions de 45 minutes',
Battery_UseCases_Game: 'Jouer',
Battery_UseCases_Game_Subtitle: 'Jouez 24 sessions de votre jeu préféré.<sup>*</sup>',
Battery_UseCases_Game_Disclaimer: '* Basé sur des sessions de jeu d\'une heure, qui ne nécessitent pas l\'utilisation des micros',
Battery_UseCases_Commute: 'Trajets quotidiens',
Battery_UseCases_Commute_Subtitle: 'Profitez de vos trajets quotidiens pendant 24 jours.<sup>*</sup>',
Battery_UseCases_Commute_Disclaimer: '* Basé sur un trajet quotidien d\'une heure. L\'autonomie de la batterie dépend des réglages de l\'appareil, de son environnement, de son utilisation et de nombreux autres facteurs.',
Battery_WhatsInTheBox_Title: 'Contenu du coffret',
Battery_WhatsInTheBox_Subtitle: 'Les Beats Studio Buds s\'accompagnent d\'un étui de charge qui tient dans la poche, d\'un connecteur de charge universel USB-C, et d\'embouts de trois tailles différentes.',
Batter_Summary_Title: 'Une autonomie d\'une journée.',
Battery_Summary1_PowerYourDay: 'Les écouteurs offrent jusqu\'à 8 heures de lecture.',
Battery_Summary2_UpTo24: 'Jusqu\'à 24 heures de lecture combinée.',
Battery_Summary3_FastFuel: 'Une charge Fast Fuel vous offre une heure d\'écoute.',
Battery_Summary4_UseCases: 'Écoutez, méditez et jouez avec une autonomie d\'une journée.',
Battery_Summary5_WhatsInTheBox: 'Le coffret inclut un étui de charge qui tient dans la poche, un connecteur de charge USB-C et trois tailles d\'embout.',
Menu_Sound_Module1: 'SON – INTRODUCTION',
Menu_Sound_Module2: 'POUR PROFITER DU MEILLEUR SON, VOUS DEVEZ TROUVER L\'AJUSTEMENT IDÉAL',
Menu_Sound_Module3: 'TROIS EMBOUTS',
Menu_Sound_Module4: 'UN SON PUISSANT ET ÉQUILIBRÉ',
Menu_Sound_Module5: 'UNE PLATEFORME ACOUSTIQUE PERSONNALISÉE',
Menu_Sound_Module6: 'SON PUR. FAIBLE DISTORSION. ',
Menu_Sound_Module7: 'UN SON DE GRANDE PRÉCISION',
Menu_Sound_Module8: 'APPELS TÉLÉPHONIQUES ',
Menu_Sound_Module9: 'UNE QUALITÉ D\'APPEL REMARQUABLE',
Menu_Sound_Module10: 'SON – RÉSUMÉ',
Menu_ANC_Module1: 'MODE RÉDUCTION ACTIVE DU BRUIT – INTRODUCTION',
Menu_ANC_Module2: 'ISOLATION ACOUSTIQUE',
Menu_ANC_Module3: 'DÉMARRER AVEC L\'ISOLATION',
Menu_ANC_Module4: 'TECHNOLOGIE',
Menu_ANC_Module5: 'UN MODE RÉDUCTION ACTIVE DU BRUIT ADAPTATIF',
Menu_ANC_Module6: 'CORRECTION DE LECTURE',
Menu_ANC_Module7: 'CAS D\'UTILISATION DU MODE RÉDUCTION ACTIVE DU BRUIT',
Menu_ANC_Module8: 'MODE RÉDUCTION ACTIVE DU BRUIT – RÉSUMÉ',
Menu_Transparency_Module1: 'MODE TRANSPARENCE – INTRODUCTION',
Menu_Transparency_Module2: 'RESTER ATTENTIF À VOTRE ENVIRONNEMENT',
Menu_Transparency_Module3: 'Mode TRANSPARENCE',
Menu_Transparency_Module4: 'MICROS EXTERNES',
Menu_Transparency_Module5: 'MICROS FEED-FORWARD',
Menu_Transparency_Module6: 'CAS D\'UTILISATION DU MODE TRANSPARENCE',
Menu_Transparency_Module7: 'MODE TRANSPARENCE – RÉSUMÉ',
Menu_Design_Module1: 'DESIGN – INTRODUCTION',
Menu_Design_Module2: 'CONCEPTION SANS FIL COMPACTE',
Menu_Design_Module3: 'LIBERTÉ SANS FIL',
Menu_Design_Module4: 'FACILITÉ DE TRANSPORT',
Menu_Design_Module5: 'RÉSISTANCE À L\'EAU ET À LA TRANSPIRATION',
Menu_Design_Module6: 'ERGONOMIE CONFORTABLE ET ADAPTABLE',
Menu_Design_Module7: 'CONÇUS POUR VOTRE CONFORT',
Menu_Design_Module8: 'DESIGN COMPACT',
Menu_Design_Module9: 'ÉTUI DE CHARGE PRATIQUE',
Menu_Design_Module10: 'POSITIONNEMENT MAGNÉTIQUE',
Menu_Design_Module11: 'CONTACT PRÉCIS',
Menu_Design_Module12: 'COULEURS',
Menu_Design_Module13: 'DESIGN – RÉSUMÉ',
Menu_Compatibility_Module1: 'COMPATIBILITÉ – INTRODUCTION',
Menu_Compatibility_Module2: 'COMPATIBLES AVEC LES APPAREILS APPLE ET ANDROID',
Menu_Compatibility_Module3: 'JUMELAGE D\'UN SEUL GESTE',
Menu_Compatibility_Module4: 'ASSISTANT VOCAL',
Menu_Compatibility_Module5: 'TROUVER VOS ÉCOUTEURS',
Menu_Compatibility_Module6: 'CHARGE USB-C ',
Menu_Compatibility_Module7: 'GÉRER VOTRE APPAREIL',
Menu_Compatibility_Module8: 'COMPATIBILITÉ – RÉSUMÉ',
Menu_Control_Module1: 'CONTRÔLE – INTRODUCTION',
Menu_Control_Module2: 'ACCÈS SIMPLE',
Menu_Control_Module3: 'COMMANDES SUR ÉCOUTEUR',
Menu_Control_Module4: 'CHOISISSEZ VOTRE ÉCOUTEUR',
Menu_Control_Module5: 'DEMANDEZ À SIRI',
Menu_Control_Module6: 'CONTRÔLE – RÉSUMÉ',
Menu_Battery_Module1: 'BATTERIE – INTRODUCTION',
Menu_Battery_Module2: 'AUTONOMIE D\'UNE JOURNÉE',
Menu_Battery_Module3: '8 HEURES D\'ÉCOUTE',
Menu_Battery_Module4: '24 HEURES D\'ÉCOUTE COMBINÉE',
Menu_Battery_Module5: 'TECHNOLOGIE FAST FUEL',
Menu_Battery_Module6: 'CAS D\'UTILISATION DE LA BATTERIE',
Menu_Battery_Module7: 'CONTENU DU COFFRET',
Menu_Battery_Module8: 'BATTERIE – RÉSUMÉ',
Outro_ChapterText1: 'Avec les modes Réduction active du bruit et Transparence, un son d\'exception, et un design <em>sans fil qui tient dans la poche, les Beats Studio Buds s\'intègrent parfaitement à votre vie.</em>',
IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
Global_Country_ALBANIA: 'ALBANIE',
Global_Country_ALGERIA: 'ALGÉRIE',
Global_Country_ANDORRA: 'ANDORRE',
Global_Country_ANGOLA: 'ANGOLA',
Global_Country_ARGENTINA: 'ARGENTINE',
Global_Country_ARMENIA: 'ARMÉNIE',
Global_Country_ARUBA: 'ARUBA',
Global_Country_AUSTRALIA: 'AUSTRALIE',
Global_Country_AUSTRIA: 'AUTRICHE',
Global_Country_AZERBAIJAN: 'AZERBAÏDJAN',
Global_Country_BAHAMAS: 'BAHAMAS',
Global_Country_BAHRAIN: 'BAHREÏN',
Global_Country_BANGLADESH: 'BANGLADESH',
Global_Country_BARBADOS: 'BARBADE',
Global_Country_BELARUS: 'BIÉLORUSSIE',
Global_Country_BELGIUM: 'BELGIQUE',
Global_Country_BOLIVIA: 'BOLIVIE',
Global_Country_BOSNIAHERZRGOVINA: 'BOSNIE-HERZÉGOVINE',
Global_Country_BOTSWANA: 'BOTSWANA',
Global_Country_BRAZIL: 'BRÉSIL',
Global_Country_BRUNEI: 'BRUNÉI DARUSSALAM',
Global_Country_BULGARIA: 'BULGARIE',
Global_Country_CAMBODIA: 'CAMBODGE',
Global_Country_CAMEROON: 'CAMEROUN',
Global_Country_CANADA: 'CANADA',
Global_Country_CAYMANISLANDS: 'ÎLES CAÏMANS',
Global_Country_CHILE: 'CHILI',
Global_Country_CHINA : 'CHINE CONTINENTALE',
Global_Country_COLOMBIA: 'COLOMBIE',
Global_Country_COOKISLANDS: 'ÎLES COOK',
Global_Country_COSTARICA: 'COSTA RICA',
Global_Country_CROATIA: 'CROATIE',
Global_Country_CYPRUS: 'CHYPRE',
Global_Country_CZECHREPUBLIC: 'RÉPUBLIQUE TCHÈQUE',
Global_Country_DENMARK: 'DANEMARK',
Global_Country_DOMINICANREPUBLIC: 'RÉPUBLIQUE DOMINICAINE',
Global_Country_ECUADOR: 'ÉQUATEUR',
Global_Country_EGYPT: 'ÉGYPTE',
Global_Country_ELSALVADOR: 'SALVADOR',
Global_Country_ESTONIA: 'ESTONIE',
Global_Country_FAROEISLANDS: 'ÎLES FÉROÉ',
Global_Country_FIJI: 'FIDJI',
Global_Country_FINLAND: 'FINLANDE',
Global_Country_FRANCE: 'FRANCE',
Global_Country_FRENCHGUIANA: 'GUYANE',
Global_Country_FRENCHPOLYNESIA: 'POLYNÉSIE FRANÇAISE',
Global_Country_GEORGIA: 'GÉORGIE',
Global_Country_GERMANY: 'ALLEMAGNE',
Global_Country_GHANA: 'GHANA',
Global_Country_GIBRALTAR: 'GIBRALTAR',
Global_Country_GREECE: 'GRÈCE',
Global_Country_GUADELOUPE: 'GUADELOUPE',
Global_Country_GUAM: 'GUAM',
Global_Country_GUATEMALA: 'GUATEMALA',
Global_Country_GUERNSEY: 'GUERNESEY',
Global_Country_GUINEA: 'GUINÉE',
Global_Country_GUYANA: 'GUYANA',
Global_Country_HONDURAS: 'HONDURAS',
Global_Country_HONGKONG: 'HONG KONG',
Global_Country_HUNGARY: 'HONGRIE',
Global_Country_ICELAND: 'ISLANDE',
Global_Country_INDIA: 'INDE',
Global_Country_INDONESIA: 'INDONÉSIE',
Global_Country_IRAQ: 'IRAK',
Global_Country_IRELAND: 'IRLANDE',
Global_Country_ISLEOFMAN: 'ÎLE DE MAN',
Global_Country_ISRAEL: 'ISRAËL',
Global_Country_ITALY: 'ITALIE',
Global_Country_IVORYCOAST: 'CÔTE D\'IVOIRE',
Global_Country_JAPAN: 'JAPON',
Global_Country_JERSEY: 'JERSEY',
Global_Country_JORDAN: 'JORDANIE',
Global_Country_KAZAKHSTAN: 'KAZAKHSTAN',
Global_Country_KENYA: 'KENYA',
Global_Country_KOREA: 'RÉPUBLIQUE DE CORÉE',
Global_Country_KUWAIT: 'KOWEÏT',
Global_Country_KYRGYZSTAN: 'KIRGHIZISTAN',
Global_Country_LAOS: 'LAOS',
Global_Country_LATVIA: 'LETTONIE',
Global_Country_LEBANON: 'LIBAN',
Global_Country_LESOTHO: 'LESOTHO',
Global_Country_LITHUANIA: 'LITUANIE',
Global_Country_LUXEMBOURG: 'LUXEMBOURG',
Global_Country_MACAU: 'MACAO',
Global_Country_MACEDONIA: 'MACÉDOINE',
Global_Country_MADAGASCAR: 'MADAGASCAR',
Global_Country_MALAWI: 'MALAWI',
Global_Country_MALAYSIA: 'MALAISIE',
Global_Country_MALDIVES: 'MALDIVES',
Global_Country_MALTA: 'MALTE',
Global_Country_MARTINIQUE: 'MARTINIQUE',
Global_Country_MAURITIUS: 'MAURICE',
Global_Country_MAYOTTE: 'MAYOTTE',
Global_Country_MEXICO: 'MEXIQUE',
Global_Country_MOLDOVA: 'MOLDAVIE',
Global_Country_MONACO: 'MONACO',
Global_Country_MONTENEGRO: 'MONTÉNÉGRO',
Global_Country_MOROCCO: 'MAROC',
Global_Country_MOZAMBIQUE: 'MOZAMBIQUE',
Global_Country_MYANMAR: 'MYANMAR',
Global_Country_NAMIBIA: 'NAMIBIE',
Global_Country_NEPAL: 'NÉPAL',
Global_Country_NETHERLANDS: 'PAYS-BAS',
Global_Country_NEWCALEDONIA: 'NOUVELLE-CALÉDONIE',
Global_Country_NEWZEALAND: 'NOUVELLE-ZÉLANDE',
Global_Country_NICARAGUA: 'NICARAGUA',
Global_Country_NIGERIA: 'NIGÉRIA',
Global_Country_NORWAY: 'NORVÈGE',
Global_Country_OMAN: 'OMAN',
Global_Country_PAKISTAN: 'PAKISTAN',
Global_Country_PALESTINIANTERRITORY: 'TERRITOIRES PALESTINIENS',
Global_Country_PANAMA: 'PANAMA',
Global_Country_PARAGUAY: 'PARAGUAY',
Global_Country_PERU: 'PÉROU',
Global_Country_PHILIPPINES: 'PHILIPPINES',
Global_Country_POLAND: 'POLOGNE',
Global_Country_PORTUGAL: 'PORTUGAL',
Global_Country_PUERTORICO: 'PORTO RICO',
Global_Country_QATAR: 'QATAR',
Global_Country_REUNION: 'LA RÉUNION',
Global_Country_ROMANIA: 'ROUMANIE',
Global_Country_RUSSIA: 'RUSSIE',
Global_Country_SAINTMARTIN: 'SAINT-MARTIN',
Global_Country_SANMARINO: 'SAINT-MARIN',
Global_Country_SAUDIARABIA: 'ARABIE SAOUDITE',
Global_Country_SENEGAL: 'SÉNÉGAL',
Global_Country_SERBIA: 'SERBIE',
Global_Country_SINGAPORE: 'SINGAPOUR',
Global_Country_SINTMAARTEN: 'SAINT-MARTIN (ROYAUME DES PAYS-BAS)',
Global_Country_SLOVAKIA: 'SLOVAQUIE',
Global_Country_SLOVENIA: 'SLOVÉNIE',
Global_Country_SOUTHAFRICA: 'AFRIQUE DU SUD',
Global_Country_SPAIN: 'ESPAGNE',
Global_Country_SRILANKA: 'SRI LANKA',
Global_Country_SWEDEN: 'SUÈDE',
Global_Country_SWITZERLAND: 'SUISSE',
Global_Country_TAIWAN: 'TAÏWAN',
Global_Country_TAJIKISTAN: 'TADJIKISTAN',
Global_Country_TANZANIA: 'TANZANIE',
Global_Country_THAILAND: 'THAÏLANDE',
Global_Country_TRINIDADTOBAGO: 'TRINITÉ-ET-TOBAGO',
Global_Country_TUNISIA: 'TUNISIE',
Global_Country_TURKEY: 'TURQUIE',
Global_Country_TURKMENISTAN: 'TURKMÉNISTAN',
Global_Country_UGANDA: 'OUGANDA',
Global_Country_UKRAINE: 'UKRAINE',
Global_Country_UAE: 'ÉMIRATS ARABES UNIS',
Global_Country_UK: 'ROYAUME-UNI',
Global_Country_US: 'ÉTATS-UNIS',
Global_Country_URUGUAY: 'URUGUAY',
Global_Country_UZBEKISTAN: 'OUZBÉKISTAN',
Global_Country_VENEZUELA: 'VENEZUELA',
Global_Country_VIETNAM: 'VIÊT NAM',
Global_Country_ZAMBIA: 'ZAMBIE',
Global_Country_ZIMBABWE: 'ZIMBABWE',
Global_Menu_Confirm: 'Confirmer',
Global_Menu_Help: 'Besoin d\'aide ? <u>Nous contacter</u>',
Global_Menu_Restart: 'REDÉMARRER L\'EXPÉRIENCE',
IntroOutro_TestYourKnowledge: 'Testez vos connaissances sur les Beats Studio Buds.',
IntroOutro_CTA: 'Répondre au quiz',
Global_Menu_Language: 'LANGUE',
Global_Password_Rules_Header: 'Le mot de passe doit contenir',
Global_Password_Rule_Length: 'Au moins 8 caractères',
Global_Password_Rule_Uppercase: '1 lettre en majuscule',
Global_Password_Rule_Lowercase: '1 lettre en minuscule',
Global_Password_Rule_Number: '1 chiffre',
Global_Password_Rule_Special: 'Des caractères spéciaux (!@#$%^&*)',
Global_Field_Password_CreateError: 'Le mot de passe ne remplit pas les conditions requises.',


Global_ProductSelector_Headline: "Sélectionnez une expérience Beats TRNR :",
Global_ProductSelector_Product1: "Beats Studio Buds",
Global_ProductSelector_Product2: "Beats Fit Pro",
Global_ProductSelector_CTA_1: "Premiers pas",
Global_ProductSelector_CTA_2: "Reprendre",
Global_ProductSelector_CTA_3: "RECOMMENCER",
Global_WelcomeInstructions_Headline: "Bienvenue dans Beats TRNR, l'app où nos formons nos experts Beats.",
Global_WelcomeInstructions_Subhead: "Voici comment les choses vont se dérouler :",
Global_WelcomeInstructions_Body1: "Chaque formation dure une quinzaine de minutes environ.",
Global_WelcomeInstructions_Body2: "Les formations sont conçues pour être écoutées. Veillez à activer le son de votre appareil.",
Global_WelcomeInstructions_Body3: "N’oubliez pas de terminer chaque section avant de passer à la suivante.",
Global_WelcomeInstructions_Body4: "Si vous devez partir, pas de problème. Vous pourrez reprendre là où vous vous étiez arrêté.",
Intro_Welcome_ReturningUser: "Prêt à reprendre là où vous vous étiez arrêté ? Touchez ou cliquez pour continuer à explorer les Beats Fit Pro.",
FitPro_Menu_Chapter1: "CHAPITRE 1",
FitPro_Menu_Chapter2: "CHAPITRE 2",
FitPro_Menu_Chapter3: "CHAPITRE 3",
FitPro_Menu_Chapter4: "CHAPITRE 4",
FitPro_Menu_Chapter5: "CHAPITRE 5",
FitPro_Menu_Chapter6: "CHAPITRE 6",
FitPro_Menu_Chapter7: "CHAPITRE 7",
FitPro_Menu_ChapterTitle_Fit: "Ergonomie",
FitPro_Menu_ChapterTitle_Sound: "Son",
FitPro_Menu_ChapterTitle_ListeningModes: "Modes d’écoute",
FitPro_Menu_ChapterTitle_Controls: "Commandes",
FitPro_Menu_ChapterTitle_AppleH1Chip: "Puce Apple H1",
FitPro_Menu_ChapterTitle_Design: "Design",
FitPro_Menu_ChapterTitle_Battery: "Batterie",
Global_Menu_Button_Restart: "Redémarrer l’expérience",
Global_Menu_Button_SelectProduct: "Sélectionner un autre produit",
Global_Menu_Button_Contact: "Besoin d’aide ? Contactez-nous",
FitPro_Menu_ChapterTitle_Fit_1: "SOUPLES, POUR UN AJUSTEMENT PARFAIT",
FitPro_Menu_ChapterTitle_Fit_2: "MAINTIENS D’OREILLE SOUPLES ET PARFAITEMENT AJUSTÉS",
FitPro_Menu_ChapterTitle_Fit_3: "CONFORT TOUTE LA JOURNÉE",
FitPro_Menu_ChapterTitle_Fit_4: "ADAPTÉS À TOUTES LES OREILLES",
FitPro_Menu_ChapterTitle_Fit_5: "MATÉRIAU",
FitPro_Menu_ChapterTitle_Fit_6: "AJUSTEMENT IDÉAL",
FitPro_Menu_ChapterTitle_Fit_7: "3 EMBOUTS",
FitPro_Menu_ChapterTitle_Fit_8: "TEST D’AJUSTEMENT",
FitPro_Menu_ChapterTitle_Fit_9: "ISOLATION ACOUSTIQUE",
FitPro_Menu_ChapterTitle_Fit_10: "ERGONOMIE – RÉSUMÉ",
FitPro_Menu_ChapterTitle_Sound_1: "TRANSDUCTEUR EXCLUSIF",
FitPro_Menu_ChapterTitle_Sound_2: "TAILLE DU TRANSDUCTEUR",
FitPro_Menu_ChapterTitle_Sound_3: "TRANSDUCTEUR À MEMBRANE PLISSÉE",
FitPro_Menu_ChapterTitle_Sound_4: "FLUX D’AIR OPTIMAL",
FitPro_Menu_ChapterTitle_Sound_5: "FAIBLE DISTORSION",
FitPro_Menu_ChapterTitle_Sound_6: "SON D'EXCEPTION",
FitPro_Menu_ChapterTitle_Sound_7: "AUDIO SPATIAL",
FitPro_Menu_ChapterTitle_Sound_8: "AUDIO SPATIAL – CAS D’UTILISATION",
FitPro_Menu_ChapterTitle_Sound_9: "3 MICROS",
FitPro_Menu_ChapterTitle_Sound_10: "SON – RÉSUMÉ",
FitPro_Menu_ChapterTitle_Modes_1: "3 MODES D’ÉCOUTE",
FitPro_Menu_ChapterTitle_Modes_2: "RÉDUCTION ACTIVE DU BRUIT",
FitPro_Menu_ChapterTitle_Modes_3: "MICRO ORIENTÉ VERS L’EXTÉRIEUR",
FitPro_Menu_ChapterTitle_Modes_4: "ANTI-BRUIT",
FitPro_Menu_ChapterTitle_Modes_5: "MICRO ORIENTÉ VERS L’INTÉRIEUR",
FitPro_Menu_ChapterTitle_Modes_6: "RÉDUCTION ACTIVE DU BRUIT – CAS D’UTILISATION",
FitPro_Menu_ChapterTitle_Modes_7: "MODE TRANSPARENCE",
FitPro_Menu_ChapterTitle_Modes_8: "RESTER ATTENTIF",
FitPro_Menu_ChapterTitle_Modes_9: "MICROS EXTERNES",
FitPro_Menu_ChapterTitle_Modes_10: "MODE TRANSPARENCE – CAS D’UTILISATION",
FitPro_Menu_ChapterTitle_Modes_11: "ÉGALISATION ADAPTATIVE",
FitPro_Menu_ChapterTitle_Modes_12: "ADAPTÉS À VOS OREILLES",
FitPro_Menu_ChapterTitle_Modes_13: "MODES D’ÉCOUTE – RÉSUMÉ",
FitPro_Menu_ChapterTitle_Controls_1: "BOUTONS INTÉGRÉS AUX ÉCOUTEURS",
FitPro_Menu_ChapterTitle_Controls_2: "LECTURE SUR SIMPLE PRESSION",
FitPro_Menu_ChapterTitle_Controls_3: "CONNECTIVITÉ INDÉPENDANTE DE CHAQUE ÉCOUTEUR",
FitPro_Menu_ChapterTitle_Controls_4: "CONTRÔLE DE L’AUDIO ET DES MODES D’ÉCOUTE",
FitPro_Menu_ChapterTitle_Controls_5: "BASCULER ENTRE LES MODES D’ÉCOUTE",
FitPro_Menu_ChapterTitle_Controls_6: "COMMANDES INTÉGRÉES AUX ÉCOUTEURS",
FitPro_Menu_ChapterTitle_Controls_7: "D'UN CÔTÉ OU DE L'AUTRE",
FitPro_Menu_ChapterTitle_Controls_8: "COMPATIBILITÉ AVEC ANDROID",
FitPro_Menu_ChapterTitle_Controls_9: "COMMANDES – RÉSUMÉ",
FitPro_Menu_ChapterTitle_H1_1: "INTÉGRATION AUTOMATIQUE À L’ÉCOSYSTÈME APPLE",
FitPro_Menu_ChapterTitle_H1_2: "PUCE APPLE H1",
FitPro_Menu_ChapterTitle_H1_3: "JUMELAGE D’UN SEUL GESTE",
FitPro_Menu_ChapterTitle_H1_4: "DIS SIRI",
FitPro_Menu_ChapterTitle_H1_5: "BASCULE AUTOMATIQUE",
FitPro_Menu_ChapterTitle_H1_6: "PARTAGE AUDIO",
FitPro_Menu_ChapterTitle_H1_7: "LOCALISER MES APPAREILS",
FitPro_Menu_ChapterTitle_H1_8: "PUCE APPLE H1 – RÉSUMÉ",
FitPro_Menu_ChapterTitle_Design_1: "ÉTUI DE CHARGE",
FitPro_Menu_ChapterTitle_Design_2: "TIENT DANS VOTRE POCHE",
FitPro_Menu_ChapterTitle_Design_3: "50 % PLUS PETIT",
FitPro_Menu_ChapterTitle_Design_4: "DES ÉCOUTEURS QUI ONT DU STYLE",
FitPro_Menu_ChapterTitle_Design_5: "CONÇUS POUR VOS DÉPLACEMENTS",
FitPro_Menu_ChapterTitle_Design_6: "LA LIBERTÉ DU SANS-FIL",
FitPro_Menu_ChapterTitle_Design_7: "CONNECTIVITÉ INDÉPENDANTE DE CHAQUE ÉCOUTEUR",
FitPro_Menu_ChapterTitle_Design_8: "PAR TOUS LES TEMPS, DANS TOUTES LES CONDITIONS",
FitPro_Menu_ChapterTitle_Design_9: "DESIGN – RÉSUMÉ",
FitPro_Menu_ChapterTitle_Battery_1: "AUTONOMIE ÉLARGIE",
FitPro_Menu_ChapterTitle_Battery_2: "24 HEURES D’ÉCOUTE COMBINÉE",
FitPro_Menu_ChapterTitle_Battery_3: "FAST FUEL",
FitPro_Menu_ChapterTitle_Battery_4: "CHARGE PAR USB-C",
FitPro_Menu_ChapterTitle_Battery_5: "CONTENU DU COFFRET",
FitPro_Menu_ChapterTitle_Battery_6: "BATTERIE – RÉSUMÉ",
FitPro_Intro_Title: 'Beats<br/>Fit Pro',
FitPro_Intro_Subtitle: "Présentation des Beats Fit Pro. Des écouteurs totalement sans fils dotés de la réduction du bruit et conçus pour vos activités quotidiennes. Touchez ou cliquez pour explorer.",
FitPro_Fit_Title: "Ergonomie",
FitPro_Fit_Intro_VOSubtitle: "Les Beats Fit Pro offrent confort et stabilité tout au long de la journée.",
FitPro_Fit_ChapterText1: "Équipés de maintiens d’oreilles confortables et <em>si souples qu'ils s'ajustent parfaitement à vos oreilles.</em>",
FitPro_Fit_FlexibleWingtip_Title: "Maintiens d’oreille souples et parfaitement ajustés",
FitPro_Fit_FlexibleWingtip_Subtitle: "Lors de la conception des Beats Fit Pro, parfaire les maintiens d’oreille était notre priorité. D’après nos recherches, il était essentiel de s’adapter à l’anatomie particulière de chaque oreille pour développer des maintiens d’oreille qui restent en place, quel que soit l’utilisateur qui les porte.",
FitPro_Fit_AllDay_Title: "Confort toute la journée",
FitPro_Fit_AllDay_Subtitle: "Des coureurs réguliers aux athlètes professionnels, c’est à des utilisateurs actifs que nous avons confié le soin de tester la conception de nos maintiens d’oreille toute une année durant.",
FitPro_Fit_Universal_Title: "Des maintiens d’oreille universels",
FitPro_Fit_Universal_Subtitle: "Le maintien s’adapte à chaque utilisateur en fonction de la taille de son oreille. Il se plie selon différents angles afin de rester stable, quelle que soit la taille de l’oreille.",
FitPro_Fit_Materials_Title: "Doux et parfaitement ajustés",
FitPro_Fit_Materials_Subtitle: "Pour obtenir un maintien confortable et stable, quelle que soit la forme ou la taille de l’oreille, nous avons recherché un matériau à la fois doux et souple pour envelopper la partie rigide du maintien d’oreille. La modélisation numérique de celui-ci a été rendue possible grâce aux mesures de milliers d’oreilles différentes.",
FitPro_Fit_ChapterText2: "Pour profiter du meilleur son, vous devez trouver l’<em>ajustement idéal.</em> Utiliser le bon embout permet d’affiner cet ajustement.",
FitPro_Fit_EarTips_Title: "Trouvez votre taille",
FitPro_Fit_EarTips_Subtitle: "Trois embouts en silicone souple permettent de trouver facilement la taille qui vous convient pour une expérience sonore enveloppante.",
FitPro_Fit_FitTest_Title: "Testez votre ajustement",
FitPro_Fit_FitTest_Subtitle: "La configuration Test d’ajustement pour Apple et Android permet d’assurer une tenue parfaite pour une isolation acoustique personnalisée et des performances optimales. Le Test d’ajustement analyse les fuites de son pour confirmer que l'isolation acoustique est bien optimisée*.",
FitPro_Fit_FitTest_Disclaimer: "* Le test d’ajustement des embouts nécessite iOS 15.1 ou version ultérieure, ou l’application Beats pour Android",
FitPro_Fit_Seal_Title: "La bulle acoustique qui fait l’unanimité",
FitPro_Fit_Seal_Subtitle: "Avec un ajustement adéquat, vous profitez d’une isolation acoustique idéale et de performances optimales. Ainsi, les modes Réduction active du bruit et Transparence n’en seront que meilleurs.",
FitPro_Fit_Summary_Title: "Confort et stabilité avant tout",
FitPro_Fit_Summary1_FlexibleWingtip: "Lors de la conception des Beats Fit Pro, parfaire les maintiens d’oreille était notre priorité. ",
FitPro_Fit_Summary2_AllDay: "Le confort de nos maintiens d’oreille universels a été éprouvé par des athlètes de tous types.",
FitPro_Fit_Summary3_Universal: "Ceux-ci s’adaptent à chaque utilisateur en fonction de la taille de son oreille. ",
FitPro_Fit_Summary4_Materials: "Le matériel de nos maintiens d’oreille est à la fois doux au toucher et rigide.",
FitPro_Fit_Summary5_EarTips: "Avec trois tailles d’embouts souples, vous trouverez facilement la taille qui vous convient.",
FitPro_Fit_Summary6_FitTest: "La configuration Test d’ajustement permet d’assurer une tenue parfaite pour une isolation acoustique personnalisée et des performances optimales.",
FitPro_Fit_Summary7_Seal: "Avec une bonne isolation acoustique, la musique vous fait vibrer encore plus.",
FitPro_Fit_Summary7_Seal_ChineseAlt: "",
FitPro_Sound_Title: "Son",
FitPro_Sound_Intro_VOSubtitle: "Les Beats Fit Pro sont conçus pour vous offrir un son puissant et équilibré.",
FitPro_Sound_ChapterText1: "Les Beats Fit Pro sont équipés d’un <em>transducteur exclusif</em> pour profiter d’une plage dynamique sur l’ensemble de la courbe de fréquence. ",
FitPro_Sound_Driver_Title: "Petit, mais puissant",
FitPro_Sound_Driver_Subtitle: "Avec 9,5 mm de diamètre, ce transducteur reste compact tout en offrant des sons d’une qualité exceptionnelle, des graves aux aigus. Il est environ deux fois plus petit qu’une pièce de dix centimes d’euro.",
FitPro_Sound_Driver_Label: "9,5 mm",
FitPro_Sound_Pleated_Title: "Plissé à la perfection",
FitPro_Sound_Pleated_Subtitle: "Notre transducteur à membrane plissée propriétaire offre de la flexibilité tout en réduisant grandement les microvibrations. Les transducteurs à membrane plissée représentent un immense défi de conception et de fabrication.",
FitPro_Sound_Airflow_Title: "Un transducteur qui respire",
FitPro_Sound_Airflow_Subtitle: "En optimisant le flux d’air dans le transducteur, il est possible de réduire la distorsion des aigus tout en donnant de la puissance aux graves.",
FitPro_Sound_UniqueDriver_Title: "Un son caractéristique",
FitPro_Sound_UniqueDriver_Subtitle: "Cette conception unique du transducteur permet d’obtenir un son équilibré avec très peu de distorsion.",
FitPro_Sound_ChapterText2: "<em>Profitez d’un son d’exception</em> qui s’adapte à vos habitudes d’écoute.",
FitPro_Sound_SpatialAudio_Title: "Immersion sonore",
FitPro_Sound_SpatialAudio_Subtitle: "L’audio spatial avec suivi dynamique de la tête offre un son immersif qui s’ajuste quand vous tournez la tête*.",
FitPro_Sound_SpatialAudio_Disclaimer: "* Matériel et logiciels compatibles requis. Fonctionne avec le contenu compatible dans les apps prises en charge. Tous les contenus ne sont pas disponibles en Dolby Atmos. Le suivi dynamique de la tête nécessite un iPhone ou un iPad.",
FitPro_Sound_UseCases_Subtitle: "Touchez ou cliquez sur chaque cas d’utilisation pour en savoir plus.",
FitPro_Sound_UseCases_Music: "Musique",
FitPro_Sound_UseCases_Music_Subtitle: "Écoutez votre musique préférée comme si vous étiez en studio grâce à un son multidimensionnel*.",
FitPro_Sound_UseCases_Movies: "Films",
FitPro_Sound_UseCases_Movies_Subtitle: "Plongez-vous dans l’univers sonore de vos films préférés, et retrouvez les mêmes sensations qu’au cinéma*.",
FitPro_Sound_UseCases_Games: "Jeux",
FitPro_Sound_UseCases_Games_Subtitle: "Profitez d’une expérience de jeu encore plus immersive*.",
FitPro_Sound_UseCases_Disclaimer_1: "* Matériel et logiciels compatibles requis. Fonctionne avec le contenu compatible dans les apps prises en charge. Tous les contenus ne sont pas disponibles en Dolby Atmos. Le suivi dynamique de la tête nécessite un iPhone ou un iPad.",
FitPro_Sound_Calls_Title: "Clair et net",
FitPro_Sound_Calls_Subtitle: "Dotés d’un accéléromètre et de la technologie Bluetooth® de classe 1, les Beats Fit Pro peuvent détecter quand vous parlez. Les micros captent votre voix tandis qu’un processeur numérique filtre les sons extérieurs ainsi que le bruit du vent pour une meilleure clarté de la voix.",
FitPro_Sound_Summary_Title: "Conçu pour offrir un son puissant et équilibré",
FitPro_Sound_Summary1_Driver: "Avec 9,5 mm de diamètre, ce transducteur reste compact tout en offrant des sons d’une qualité exceptionnelle, des graves aux aigus.",
FitPro_Sound_Summary2_Pleated: "Notre transducteur à membrane plissée propriétaire offre de la flexibilité tout en éliminant les microvibrations.",
FitPro_Sound_Summary3_Airflow: "En optimisant le flux d’air dans le transducteur, il est possible de réduire la distorsion des aigus tout en garantissant la clarté des graves.",
FitPro_Sound_Summary4_UniqueDriver: "Cette conception unique du transducteur permet d’obtenir un son équilibré avec très peu de distorsion.",
FitPro_Sound_Summary5_SpatialAudio: "L’audio spatial avec suivi dynamique de la tête offre un son immersif qui s’ajuste quand vous tournez la tête.",
FitPro_Sound_Summary6_UseCases: "L’audio spatial avec suivi dynamique de la tête offre une expérience immersive, comme au cinéma.",
FitPro_Sound_Summary7_Calls: "Les Beats Fit Pro peuvent détecter quand vous parlez pour un rendu naturel et fluide de votre voix.",
FitPro_Modes_Title: "Modes d’écoute",
FitPro_Modes_Intro_VOSubtitle: "Les trois modes d’écoute vous permettent de garder le contrôle de ce que vous entendez. ",
FitPro_Modes_Toggle_Title: "N’écouter que l’essentiel",
FitPro_Modes_Toggle_Subtitle: "Avec les modes Réduction active du bruit, Transparence et Égalisation adaptative, vous pouvez adapter votre mode d’écoute à vos besoins.",
FitPro_Modes_Toggle_Disclaimer: "Créés spécialement à des fins de présentation.",
FitPro_Modes_Toggle_Label1: "Réduction du bruit",
FitPro_Modes_Toggle_Label2: "Égalisation adaptative",
FitPro_Modes_Toggle_Label3: "Transparence",
FitPro_Modes_Toggle_MusicLabel: "En cours de lecture",
FitPro_Modes_Toggle_SongTitle: "Mood",
FitPro_Modes_Toggle_SongArtist: "24kGoldn, ft. iann dior",
FitPro_Modes_ChapterText1: "La <em>Réduction active du bruit</em> bloque les sons extérieurs pour que vous profitiez pleinement de votre musique.",
FitPro_Modes_ANCExternalMic_Title: "Détecte les sons extérieurs",
FitPro_Modes_ANCExternalMic_Subtitle: "Le mode Réduction active du bruit utilise un micro orienté vers l’extérieur pour détecter l’environnement sonore.",
FitPro_Modes_ANCAntiNoise_Title: "Neutralise les sons indésirables",
FitPro_Modes_ANCAntiNoise_Subtitle: "Les Beats Fit Pro atténuent en permanence les sons extérieurs en appliquant un antibruit équivalent, et ce, avant même que vous ne les perceviez.",
FitPro_Modes_ANCInwardMic_Title: "S'ajuste en permanence",
FitPro_Modes_ANCInwardMic_Subtitle: "Un micro orienté vers l’intérieur permet d’analyser l’ajustement de l’écouteur et l’isolation acoustique pour détecter tout son parasite, qui est ensuite éliminé à son tour par un antibruit.",
FitPro_Modes_ANCUseCases_Subtitle: "Touchez ou cliquez sur chaque cas d’utilisation pour en savoir plus.",
FitPro_Modes_ANCUseCases_Commute: "Trajets quotidiens",
FitPro_Modes_ANCUseCases_Commute_Subtitle: "Effectuez vos trajets quotidiens en réduisant les bruits de la ville.",
FitPro_Modes_ANCUseCases_Exercise: "Entraînements",
FitPro_Modes_ANCUseCases_Exercise_Subtitle: "Immergez-vous dans votre entraînement sans aucune distraction.",
FitPro_Modes_ANCUseCases_Travel: "Voyages",
FitPro_Modes_ANCUseCases_Travel_Subtitle: "Atténuez le bruit du moteur ou des autres passagers pour mieux profiter de votre vol.",
FitPro_Modes_ANCUseCases_Meditate: "Méditation",
FitPro_Modes_ANCUseCases_Meditate_Subtitle: "Trouvez votre paix intérieure en vous concentrant sur votre respiration et en oubliant les distractions.",
FitPro_Modes_ChapterText2: "Avec le <em>mode Transparence</em>, ne vous fermez pas aux bruits qui vous entourent.",
FitPro_Modes_TMAware_Title: "Restez attentif à votre environnement",
FitPro_Modes_TMAware_Subtitle: "Pour garder le contact avec votre environnement, le mode Transparence permet de mêler les bruits extérieurs à votre musique.",
FitPro_Modes_TMExternalMic_Title: "Des micros externes qui captent les bruits ambiants",
FitPro_Modes_TMExternalMic_Subtitle: "Les micros externes captent les bruits ambiants de votre environnement pour favoriser une expérience d’écoute naturelle et « ouverte ». ",
FitPro_Modes_TMUseCases_Subtitle: "Touchez ou cliquez sur chaque cas d’utilisation pour en savoir plus.",
FitPro_Modes_TMUseCases_Run: "Course à pied",
FitPro_Modes_TMUseCases_Run_Subtitle: "Gardez le rythme grâce à votre musique, tout en restant attentif à votre environnement.",
FitPro_Modes_TMUseCases_Travel: "Voyages",
FitPro_Modes_TMUseCases_Travel_Subtitle: "Écoutez les annonces importantes, dans un aéroport ou dans une gare, par exemple.",
FitPro_Modes_TMUseCases_Walk: "Marche",
FitPro_Modes_TMUseCases_Walk_Subtitle: "Restez attentif au trafic environnant et aux intersections animées.",
FitPro_Modes_TMUseCases_StandBy: "Attente",
FitPro_Modes_TMUseCases_StandBy_Subtitle: "Entendez votre nom de façon claire tout en écoutant de la musique dans un café bondé ou en attendant vos amis.",
FitPro_Modes_ChapterText3: "L’<em>Égalisation adaptative</em> offre des réglages personnalisés selon vos habitudes d’écoute.",
FitPro_Modes_AdaptiveEQ_Title: "Adaptés à vos oreilles",
FitPro_Modes_AdaptiveEQ_Subtitle: "Lorsque les modes Réduction active du bruit et Transparence sont désactivés, l’Égalisation adaptative utilise les micros pour adapter automatiquement le son à la forme unique de votre oreille.",
FitPro_Modes_Summary_Title: "Concentrez-vous sur votre musique ou écoutez ce qui vous entoure",
FitPro_Modes_Summary1_Toggle: "Avec les modes Réduction active du bruit, Transparence et Égalisation adaptative, vous pouvez adapter votre mode d’écoute à vos besoins.",
FitPro_Modes_Summary2_ANCExtermalMic: "Le mode Réduction active du bruit utilise un micro orienté vers l’extérieur pour détecter l’environnement sonore.",
FitPro_Modes_Summary3_ANCAntiNoise: "Les Beats Fit Pro compensent avec un anti-bruit équivalent.",
FitPro_Modes_Summary4_ANCInwardMic: "Un micro orienté vers l’intérieur analyse toute défaillance de l’ajustement ou de l’isolation acoustique en détectant les sons parasites.",
FitPro_Modes_Summary5_ANCUseCases: "Utilisez la Réduction active du bruit dans les transports en commun, pendant vos entraînements ou déplacements, ou encore pendant vos séances de méditation.",
FitPro_Modes_Summary6_TMAware: "Le mode Transparence vous aide à rester attentif à ce qui se passe autour de vous.",
FitPro_Modes_Summary7_TMExternalMic: "Les micros externes captent les bruits ambiants de votre environnement.",
FitPro_Modes_Summary8_TMUseCases: "Gardez une oreille attentive pendant que vous courez, voyagez, marchez ou attendez.",
FitPro_Modes_Summary9_AdaptiveEQ: "L’égalisation adaptative tire parti des micros pour adapter automatiquement le son en fonction de la forme de votre oreille.",
FitPro_Controls_Title: "Commandes",
FitPro_Controls_Intro_VOSubtitle: "Contrôlez vos écouteurs d’une simple pression sur un bouton.",
FitPro_Controls_ChapterText1: "Pratiques, les <em>boutons des écouteurs</em> sont très accessibles.",
FitPro_Controls_PhysicalButton_Title: "Lecture d’une simple pression",
FitPro_Controls_PhysicalButton_Subtitle: "Les boutons physiques facilitent le contrôle de votre écoute.",
FitPro_Controls_BothSides_Title: "Connectivité indépendante de chaque écouteur",
FitPro_Controls_BothSides_Subtitle: "Contrôlez votre musique avec la main gauche ou la main droite grâce au bouton intégré sur chaque écouteur.",
FitPro_Controls_ChapterText2: "<em>Contrôlez l’audio et les modes d’écoute</em> à tout moment, où que vous soyez.",
FitPro_Controls_PressHold_Title: "Basculez facilement entre les modes d’écoute",
FitPro_Controls_PressHold_Subtitle: "Appuyez de façon prolongée pour basculer d’un mode d’écoute à l’autre ou personnalisez la fonction pour activer Siri*.",
FitPro_Controls_PressHold_Disclaimer: "* La personnalisation de la commande « Maintenir enfoncé » nécessite un iPhone doté d’iOS 14.6 ou version ultérieure, ou un appareil Android doté de la dernière version de l’app Beats. Siri n’est pas disponible dans toutes les langues ni dans toutes les zones géographiques, et les fonctionnalités proposées peuvent varier en fonction des zones géographiques. Accès à Internet requis. Des frais de données mobiles peuvent s’appliquer.",
FitPro_Controls_PressHold_Label1: "Mode Réduction du bruit",
FitPro_Controls_PressHold_Label2: "Mode Transparence",
FitPro_Controls_Playback_Title: "Commandes intégrées aux écouteurs",
FitPro_Controls_Playback_Subtitle: "Les commandes intégrées aux écouteurs vous permettent de gérer vos appels et votre musique.",
FitPro_Modes_Playback_MusicLabel: "En cours de lecture",
FitPro_Modes_Playback_SongTitle1: "5 Seconds of Summer",
FitPro_Modes_Playback_SongArtist1: "Best Years",
FitPro_Modes_Playback_SongTitle2: "Boyfriend",
FitPro_Modes_Playback_SongArtist2: "Selena Gomez",
FitPro_Modes_Playback_SongTitle3: "Lovesick",
FitPro_Modes_Playback_SongArtist3: "Trevor Daniel",
FitPro_Modes_Playback_SongTitle4: " Mood",
FitPro_Modes_Playback_SongArtist4: "24kGoldn, ft. iann dior",
FitPro_Modes_Playback_SongTitle5: "What If I Told You That I Love You",
FitPro_Modes_Playback_SongArtist5: "Ali Gatie",
FitPro_Controls_Playback_Instruction: "Appuyez ou cliquez sur la zone interactive pour vous exercer à chaque commande.",
FitPro_Controls_Playback_Play1: "Appuyez pour mettre en pause ou répondre",
FitPro_Controls_Playback_Play2: "Appuyez pour mettre en pause ou raccrocher",
FitPro_Controls_Playback_Skip: "Appuyez 2 fois pour passer au morceau suivant.",
FitPro_Controls_Playback_Previous: "Appuyez 3 fois pour lire le morceau précédent",
FitPro_Controls_Playback_ScanFwd: "Appuyez 2 fois et maintenez le bouton enfoncé pour avancer dans le morceau",
FitPro_Controls_Playback_ScanBck: "Appuyez 3 fois et maintenez le bouton enfoncé pour reculer dans le morceau",
FitPro_Controls_Customize_Title: "D'un côté ou de l'autre",
FitPro_Controls_Customize_Subtitle: "Personnalisez la commande « Maintenir enfoncé » de chaque écouteur pour activer la Réduction active du bruit, le mode Transparence ou l’Égalisation adaptative, pour contrôler le volume ou encore déclencher l’assistant vocal, par le biais d’iOS ou de l’app Beats pour Android*.",
FitPro_Controls_Customize_Disclaimer: "* L’app Beats nécessite Android 8.0 ou version ultérieure. L’app est disponible dans le Google Play Store et sur beatsbydre.com.",
FitPro_Controls_Android_Title: "Compatibilité avec Android",
FitPro_Controls_Android_Subtitle: "L’app Android pour Beats vous permet de bénéficier de fonctionnalités avancées comme le jumelage d’un seul geste, les commandes dédiées, l’affichage des niveaux de batterie et les mises à jour logiciel*.",
FitPro_Controls_Android_Disclaimer: "* L’app Beats nécessite Android 8.0 ou version ultérieure. L’app est disponible dans le Google Play Store et sur beatsbydre.com.",
FitPro_Controls_Summary_Title: "Une maîtrise totale, du bout des doigts",
FitPro_Controls_Summary1_PhysicalButton: "Les boutons physiques facilitent le contrôle de votre écoute.",
FitPro_Controls_Summary2_BothSides: "Contrôlez votre musique avec la main gauche ou la main droite grâce au bouton intégré sur chaque écouteur.",
FitPro_Controls_Summary3_PressHold: "Appuyez et maintenez le bouton enfoncé pour changer de modes d’écoute.",
FitPro_Controls_Summary4_Playback: "Les commandes intégrées aux écouteurs vous permettent de gérer vos appels et votre musique.",
FitPro_Controls_Summary5_Customize: "Personnalisez la commande « Maintenir enfoncé » de chaque écouteur.",
FitPro_Controls_Summary6_Android: "Avec l’app Beats pour Android, vous profiterez de fonctionnalités avancées.",
FitPro_H1_Title: "Puce Apple H1",
FitPro_H1_Intro_VOSubtitle: "La puce Apple H1 est au cœur de la conception des Beats Fit Pro. ",
FitPro_H1_AppleIntegration_Title: "Intégration automatique à l’écosystème Apple",
FitPro_H1_AppleIntegration_Subtitle: "La puce Apple H1 offre des fonctionnalités avancées et une intégration automatique aux appareils Apple.",
FitPro_H1_ChapterText1: "Les Beats Fit Pro embarquent la <em>puce Apple H1</em> pour fournir des fonctionnalités populaires.",
FitPro_H1_Pairing_Title: "Jumelage d’un seul geste",
FitPro_H1_Pairing_Subtitle: "Les Beats Fit Pro se connectent à vos appareils iOS dès le premier démarrage grâce à la meilleure technologie Bluetooth® du marché (le Bluetooth de classe 1), qui permet le jumelage d’un seul geste*.",
FitPro_H1_Pairing_Disclaimer: "* Nécessite iOS 15.1 ou version ultérieure.",
FitPro_H1_HeySiri_Title: "Dis Siri",
FitPro_H1_HeySiri_Subtitle: "Contrôlez votre musique, vos appels, vos itinéraires et plus encore avec « Dis Siri »*.",
FitPro_H1_HeySiri_Disclaimer: "* Siri n’est pas disponible dans toutes les langues ni dans toutes les zones géographiques, et les fonctionnalités proposées peuvent varier en fonction des zones géographiques. Accès à Internet requis. Des frais de données mobiles peuvent s’appliquer.",
FitPro_H1_AutoSwitch_Title: "Bascule automatique",
FitPro_H1_AutoSwitch_Subtitle: "Les Beats Fit Pro passent facilement d’un appareil à l’autre avec la bascule automatique*.",
FitPro_H1_AutoSwitch_Disclaimer: "* La bascule automatique nécessite macOS 11.1, iOS 14.3, iPadOS 14.3, watchOS 7.2, ou tvOS 14.3 ou version ultérieure.",
FitPro_H1_AudioSharing_Title: "Partage audio",
FitPro_H1_AudioSharing_Subtitle: "Partagez votre musique préférée avec un ami qui possède des AirPods, un casque ou des écouteurs Beats*.",
FitPro_H1_AudioSharing_Disclaimer: "* Le Partage audio est compatible avec les casques et écouteurs Beats sans fil pris en charge et tous les modèles d’AirPods. Matériel et logiciels Apple compatibles requis.",
FitPro_H1_FindMy_Title: "Localiser mes appareils",
FitPro_H1_FindMy_Subtitle: "La fonctionnalité Localiser vous permet de trouver facilement vos Beats Fit Pro, où que vous soyez*.",
FitPro_H1_FindMy_Disclaimer: "* L’app Localiser pour Beats nécessite un iPhone ou un iPod touch doté d’iOS 15.1 ou version ultérieure, un iPad doté d’iPadOS 15.1 ou version ultérieure, ou un Mac doté de macOS Monterey 12.0.1 ou version ultérieure. Les clients doivent posséder un identifiant Apple et être connectés à leur compte iCloud avec la fonctionnalité Localiser activée.",
FitPro_H1_Summary_Title: "Équipés de la puce Apple H1",
FitPro_H1_Summary1_Seamless: "La puce Apple H1 offre une intégration automatique aux appareils Apple.",
FitPro_H1_Summary2_Pairing: "Connectez vos appareils en toute simplicité par Bluetooth® grâce au jumelage d’un seul geste.",
FitPro_H1_Summary3_HeySiri: "Il suffit d’un « Dis Siri » pour activer Siri sans les mains et lui poser une question.",
FitPro_H1_Summary4_AutoSwitch: "Les Beats Fit Pro passent facilement d’un appareil à l’autre avec la bascule automatique.",
FitPro_H1_Summary5_AudioSharing: "Partagez votre musique avec un ami qui possède des AirPods, un casque ou des écouteurs Beats.",
FitPro_H1_Summary6_FindMy: "Utilisez la fonction Localiser pour trouver facilement vos écouteurs.",
FitPro_Design_Title: "Design",
FitPro_Design_Intro_VOSubtitle: "Les Beats Fit Pro sont conçus pour vous suivre dans vos activités quotidiennes. ",
FitPro_Design_ChapterText1: "L'<em>étui de charge</em> est conçu pour recharger vos écouteurs pendant vos déplacements.",
FitPro_Design_Pocket_Title: "Tient dans votre poche",
FitPro_Design_Pocket_Subtitle: "Emportez vos Beats Fit Pro partout avec vous grâce à l'étui de charge assorti et compact.",
FitPro_Design_Size_Title: "Plus de 50 % plus petit",
FitPro_Design_Size_Subtitle: "L'étui de charge des Beats Fit Pro est plus de 50 % plus petit que celui des Powerbeats Pro.",
FitPro_Design_Color_Title: "Des écouteurs qui ont du style",
FitPro_Design_Color_Subtitle: "Les écouteurs et leur étui assorti sont disponibles en quatre couleurs pour que vous trouviez ceux qui vous vont le mieux.",
FitPro_Design_Color_Black: "NOIR BEATS",
FitPro_Design_Color_White: "BLANC BEATS",
FitPro_Design_Color_Purple: "VIOLET POP",
FitPro_Design_Color_Gray: "GRIS CENDRÉ",
FitPro_Design_ChapterText2: "Les <em>écouteurs Beats Fit Pro</em> sont conçus pour vous suivre où que vous alliez.",
FitPro_Design_TrueWireless_Title: "La liberté du sans-fil",
FitPro_Design_TrueWireless_Subtitle: "Les Beats Fit Pro sont des écouteurs totalement sans fil qui vous accompagnent dans vos activités quotidiennes.",
FitPro_Design_Independent_Title: "Un seul à la fois",
FitPro_Design_Independent_Subtitle: "La connectivité indépendante des écouteurs vous permet de ne porter qu’un écouteur à la fois tout en conservant le parfait contrôle du son grâce au bouton qui équipe chacun d’eux.",
FitPro_Design_Sweat_Title: "Par tous les temps, dans toutes les conditions",
FitPro_Design_Sweat_Subtitle: "Les écouteurs Beats Fit Pro résistent à l’eau et à la transpiration selon la norme IPX-4. Ils défient les éléments pour vous soutenir dans vos entraînements les plus intenses*.",
FitPro_Design_Sweat_Disclaimer: "* Les Beats Fit Pro sont résistants à l’eau et à la transpiration pour le sport et les activités non aquatiques. La résistance à l’eau et à la transpiration peut diminuer au fil du temps. L’étui de charge n’est pas résistant à l’eau ni à la transpiration.",
FitPro_Design_Summary_Title: "Conçus pour vous suivre toute la journée",
FitPro_Design_Summary1_Pocket: "Les Beats Fit Pro sont livrés avec un étui de charge compact à emporter partout.",
FitPro_Design_Summary2_Size: "L'étui de charge des Beats Fit Pro est plus de 50 % plus petit que celui des Powerbeats Pro.",
FitPro_Design_Summary3_Color: "Disponibles en quatre couleurs pour convenir à votre style.",
FitPro_Design_Summary4_TrueWireless: "Les écouteurs Beats Fit Pro sont totalement sans fil.",
FitPro_Design_Summary5_Independent: "La connectivité indépendante de chaque écouteur vous permet de n’en porter qu’un seul.",
FitPro_Design_Summary6_Sweat: "Les Beats Fit Pro bénéficient d’une résistance IPX-4 à l’eau et à la transpiration.",
FitPro_Battery_Title: "Batterie",
FitPro_Battery_Intro_VOSubtitle: "Une batterie qui offre des heures et des heures d’écoute. ",
FitPro_Battery_6Hours_Title: "Autonomie élargie",
FitPro_Battery_6Hours_Subtitle: "Chaque écouteur vous offre jusqu’à 6 heures d’écoute*. ",
FitPro_Battery_6Hours_Disclaimer: "* L’autonomie de la batterie dépend des réglages de l’appareil, de son environnement, de son utilisation et de nombreux autres facteurs.",
FitPro_Battery_18Hours_Title: "Jusqu’à 24 heures d’écoute combinée",
FitPro_Battery_18Hours_Subtitle: "Profitez de 18 heures d’écoute supplémentaire avec l’étui de charge*.",
FitPro_Battery_18Hours_Disclaimer: "* L’autonomie de la batterie dépend des réglages de l’appareil, de son environnement, de son utilisation et de nombreux autres facteurs.",
FitPro_Battery_FastFuel_Title: "La puissance, en un rien de temps",
FitPro_Battery_FastFuel_Subtitle: "Utilisez la fonction Fast Fuel pour gagner 1 heure d’écoute en 5 minutes de charge*.",
FitPro_Battery_FastFuel_Disclaimer: "* L’autonomie de la batterie dépend des réglages de l’appareil, de son environnement, de son utilisation et de nombreux autres facteurs.",
FitPro_Battery_USBC_Title: "Charge par USB-C",
FitPro_Battery_USBC_Subtitle: "Le connecteur de charge USB-C fonctionne aussi bien avec les appareils Apple qu’Android, vous pouvez donc charger vos écouteurs partout. ",
FitPro_Battery_WhatsInTheBox_Title: "Contenu du coffret",
FitPro_Battery_WhatsInTheBox_Subtitle: "Les Beats Fit Pro s’accompagnent d’un étui de charge qui tient dans la poche, d’un connecteur de charge universel USB-C et d’embouts de trois tailles différentes.",
FitPro_Battery_Summary_Title: "Des heures et des heures d’écoute",
FitPro_Battery_Summary1_6Hours: "Chaque écouteur a une autonomie de 6 heures.",
FitPro_Battery_Summary2_18Hours: "Profitez de 18 heures d’écoute supplémentaire avec l’étui de charge.",
FitPro_Battery_Summary3_FastFuel: "Utilisez la fonction Fast Fuel pour gagner 1 heure d’écoute en 5 minutes de charge.",
FitPro_Battery_Summary4_USBC: "Le connecteur de charge USB-C est inclus pour que vous puissiez charger vos écouteurs partout.",
FitPro_Battery_Summary5_WhatsInTheBox: "Le coffret inclut un étui de charge qui tient dans la poche, un connecteur de charge USB-C et trois tailles d’embout.",
FitPro_Outro_ChapterText1: "Grâce aux maintiens d’oreille souples et parfaitement ajustés, aux trois modes d’écoute et à un design compact, les <em>Beats Fit Pro vous donnent le contrôle de votre écoute</em>.",
FitPro_TestKnowledge: "Testez vos connaissances sur les Beats Fit Pro.",
FitPro_TakeQuiz: "RÉPONDRE AU QUIZ",
Global_ForgotEmail_NoUser:"Aucun compte utilisateur ne correspond à cet identifiant. Le compte peut avoir été supprimé."

};

export default copy;