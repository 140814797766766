const copy = {
	Global_Menu_Button_SelectProduct: '选择其他产品',

	Global_UI_Next: '下一页',
	Global_UI_Begin: '开始',
	Global_UI_Back: '返回',
	Global_UI_Settings: '设置',
	Global_UI_Signout: '退出登录',
	Global_UI_NextChapter: '下一章',
	Global_UI_PercentageComplete: '<<number>>%  已完成',
	Global_UI_Replay: '重新播放',
	Global_Languages_Select: '选择你的语言：',
	Global_Field_EnterEmail: '输入电子邮件地址',
	Global_Field_CreatePassword: '创建密码',
	Global_Field_Password: '密码',
	Global_Field_Password_Forgot: '忘记密码',
	Global_Password_Subtitle: '密码的长度至少应为 8 个字符，需包含大写+小写字母、数字和符号。',
	Global_Password_Change: '更改',
	Global_Password_Reset_Headline: '重设密码',
	Global_Password_Reset_Subtitle: '输入电子邮件以接收有关如何重设密码的说明。',
	Global_Password_Reset_Confirm: '已发送！请查看你的电子邮件。',
	Global_Password_ChangeConfirm: '密码已更新',
	Global_Password_Show: '显示',
	Global_Password_Hide: '隐藏',
	Global_Details_Title: '还需要一些详细信息',
	Global_Details_Field_FirstName: '名',
	Global_Details_Field_LastName: '姓',
	Global_Details_Field_EmployeeID: '员工 ID (可选)',
	Global_Details_Field_BusinessRole: '业务角色',
	Global_Details_Field_BusinessRole1: '管理人员',
	Global_Details_Field_BusinessRole2: '销售人员',
	Global_Details_Field_BusinessRole3: '培训师',
	Global_Details_Field_CompanyName: '公司名称：',
	Global_Details_Field_Country: '国家或地区',
	Global_Field_Password_Error: '你输入的密码不正确。请再试一次。',
	Global_Field_Email_Error: '你输入的电子邮件地址无效。请再试一次。',
	Global_Field_Details_Error: '请填写所有必填字段。',
	Global_Greeting: '<<name>>，你好：',
	Global_CompletionMessage: '你已完成 <<number>>%。',
	Global_Chapter: '第 <<number>> 章',
	 Global_Chapter_Subtitle: '第 <<number>> 章，共 <<total>> 章',
	Global_Intro: '简介',
	Global_ChapterTitle_Sound: '音质',
	Global_ChapterTitle_ANC: '主动降噪 (ANC)',
	Global_ChapterTitle_Transparency: '通透模式',
	Global_ChapterTitle_Design: '设计',
	Global_ChapterTitle_Compatibility: '兼容性',
	Global_ChapterTitle_Controls: '控制按钮',
	Global_ChapterTitle_Battery: '电池',
	Global_Menu: '菜单',
	Intro_Welcome_Label: '体验',
	Intro_Welcome_ChapterText: '欢迎来到 Beats Studio Buds 体验。点按或轻点各项以进行探索。',
	Intro_Welcome_ReturningUser: '准备从上次中断的地方继续？点按或轻点以继续探索 Beats Studio Buds。',
	CR_TRNR_Intro_Sorry: '非常抱歉，出错了。请联系主管以获得一个新的访问链接。',
	Intro_Intro_Subtitle: '全新推出 Beats Studio Buds。真无线降噪耳塞，录音棚级别的音质。点按或轻点各项以进行探索。',
	Sound_Intro_Title: '音质',
	Sound_Intro_VOSubtitle: '先进工艺实现了强劲均衡的音效。',
	Sound_ChapterText1: '出色音质源于<em>个性化贴合体验和舒适的声学密封性能。</em>',
	Sound_FindYourFit_Title: '选择合适尺寸，用心感受音乐',
	Sound_FindYourFit_Subtitle: '三种硅胶耳塞尺寸，带来个性化的贴合体验和理想的隔音效果，营造令人沉醉的聆听体验。',
	Sound_FindYourFit_Medium: '中号 (默认)',
	Sound_FindYourFit_Small: '小号',
	Sound_FindYourFit_Large: '大号',
	Sound_ChapterText2: 'Beats Studio Buds 采用 <em>完全定制的声学平台</em>，小巧的机身迸发出<em>强劲均衡的音效。</em>',
	Sound_DrivenByPremiumSound_Title: '出色音质，启发灵感',
	Sound_DrivenByPremiumSound_Subtitle: '定制的声学平台采用专有的双元件振膜驱动单元，呈现清晰均衡的音效。',
	Sound_MoreMusicLessDistortion_Title: '清澈音质，低失真。',
	Sound_MoreMusicLessDistortion_Subtitle: '驱动单元振膜采用柔性材料，可以降低振动失真，从而在整个频谱中产生清澈、准确的声音，尤其是在低音范围内。',
	Sound_MoreMusicLessDistortion_Diaphragm: '振膜',
	Sound_FineTunedAudio_Title: '精湛的音频调制',
	Sound_FineTunedAudio_Subtitle: '带有播放校正功能的数字处理器能够实时响应，以确保外部声音不会影响音乐的清晰度或情感。',
	Sound_ChapterText3: '通话声音清澈，<em>提供高质量的通话性能，更高的语音清晰度。</em> ',
	Sound_SpeakingOfSound_Title: '高品质通话效果 ',
	Sound_SpeakingOfSound_Subtitle: '特别设计的端口可将麦克风与外界风声噪声隔离开来，而双波束成形麦克风能够锁定你的声音，以提高声音清晰度。',
	Sound_Summary_Title: '设计，让听者感受音乐人想传达的音乐',
	Sound_Summary1_ThreeEartip: '三种尺寸的耳塞，带来量身定制的贴合体验和出色音质。',
	Sound_Summary2_CustomAcoustic: '定制的声学平台提供高品质、强劲的音效。',
	Sound_Summary3_FlexibleDriverDiaphragm: '灵活的驱动单元振膜可产生清澈、准确的声音。',
	Sound_Summary4_FinetunedAudio: '精湛的音频调制，充分保留音乐的清晰度和情感。',
	Sound_Summary5_SpeakingOfSound: '双波束成形麦克风可以锁定你的声音，提供高质量的通话性能。',
	ANC_Intro_TITLE: '主动降噪 ',
	ANC_Intro_VOSubtitle: '通过主动降噪，时刻保持专注，排除干扰。',
	ANC_ChapterText1: '<em>佩戴舒适稳固，隔音效果理想，</em>有助于阻挡外界噪声，实现真正的沉浸式聆听体验。',
	ANC_Seal_Title: '隔音效果',
	ANC_Seal_Subtitle: '有三种耳塞尺寸可供选择，带来量身定制的佩戴舒适度，屏蔽不必要的噪音，营造理想的隔音效果。',
	ANC_ChapterText2: '搭载自适应技术的主动降噪，<em>可根据你的环境进行调节</em>，而播放校正功能<em>则保留了音乐的清晰度和情感。</em>',
	ANC_ANCThatAdapts_Title: '适应性主动降噪 (ANC)',
	ANC_ANCThatAdapts_Subtitle: '精确调整的滤波器动态进行调节，实时滤除多余噪音并优化降噪效果。',
	ANC_ANCThatAdapts_AdaptiveANC: '自适应主动降噪',
	ANC_Playback_Title: '播放校正',
	ANC_Playback_Subtitle: '应用降噪时，播放校正功能会以每秒 48000 次的速度将音频文件映射回原始文件，确保 ANC 不会干扰你的音乐。',
	ANC_Playback_ANCProcess: '主动降噪过程',
	ANC_Playback_PlaybackCorrection: '播放校正',
	ANC_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。 ',
	ANC_UseCases_Studying: '学习',
	ANC_UseCases_Studying_Subtitle: '排除干扰，让你时刻专注于工作',
	ANC_UseCases_WorkingOut: '健身',
	ANC_UseCases_WorkingOut_Subtitle: '让自己沉浸在健身过程中，不受任何干扰',
	ANC_UseCases_Airplane: '乘坐飞机',
	ANC_UseCases_Airplane_Subtitle: '隔绝乘客声音或发动机噪声，更好地享受飞行',
	ANC_UseCases_Commute: '通勤',
	ANC_UseCases_Commute_Subtitle: '通勤时减少城市的背景噪音',
	ANC_Summary_Title: '通过主动降噪，时刻保持专注。',
	ANC_Summary1_ImmerseSound: '身临其境般的音质源自舒适稳固的佩戴和理想的隔音效果。',
	ANC_Summary2_AdaptiveTechnology: '自适应技术会根据你的环境不断进行调整。',
	ANC_Summary3_PlaybackCorrection: '播放校正功能会保留声音的清晰度。',
	ANC_Summary4_UseCases: '在学习、健身、乘坐飞机旅行或通勤时使用主动降噪来时刻保持专注。',
	Transparency_Intro_Title: '通透模式 ',
	Transparency_Intro_VOSubtitle: '通透模式让你随时留意周围环境。',
	Transparency_ChapterText1: '需要听到周围声音时，<em>切换至通透模式即可。</em>',
	Transparency_TransparencyMode_Title: '通透模式 ',
	Transparency_TransparencyMode_Subtitle: '通透模式会对传回的周围声音和你聆听的音乐进行混音，营造出自然的“开放式”聆听体验。',
	Transparency_TransparencyMode_PressHold: '长按',
	Transparency_ChapterText2: '<em>外置麦克风</em>有助于听清外界噪声。',
	Transparency_FeedForwardMic_Title: '前馈麦克风',
	Transparency_FeedForwardMic_Subtitle: '每个耳塞上都有一个前馈麦克风，可以检测环境中的外界噪音。',
	Transparency_FeedForwardMic_FeedForwardMic: '前馈麦克风',
	Transparency_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。 ',
	Transparency_UseCases_Office: '办公室',
	Transparency_UseCases_Office_Subtitle: '在工作中保持协作，既能聆听音乐，又能听清同事的声音。',
	Transparency_UseCases_Travel: '旅行',
	Transparency_UseCases_Travel_Subtitle: '在机场登机口或火车站台听到重要的通告。',
	Transparency_UseCases_Street: '街头',
	Transparency_UseCases_Street_Subtitle: '随时注意周围的交通状况和车水马龙的十字路口。',
	Transparency_Summary_Title: '通透模式让你随时留意周围环境。',
	Transparency_Summary1_MixTheSounds: '对传回的周围声音和你聆听的音乐进行混音',
	Transparency_Summary2_FeedForward: '前馈麦克风可以检测环境中的外界噪音。',
	Transparency_Summary3_HearYourSurroundings: '在办公室、旅行时或在街头可以听到周围环境的声音。',
	Design_Intro_Title: '设计',
	Design_Intro_VOSubtitle: 'Beats Studio Buds 的设计让你畅享真无线带来的自由。',
	Design_ChapterText1: '无线、小巧的设计，<em>无缝融入你的生活。</em>',
	Design_WirelessFreedom_Title: '无线自由 ',
	Design_WirelessFreedom_Subtitle: '真无线耳塞，让你尽享无线自由。 ',
	Design_PocketSizedPortability_Titles: '袖珍尺寸，轻松便携',
	Design_PocketSizedPortability_Subtitles: '纤薄、轻巧的充电盒提供了卓越的便携性，可放入口袋。',
	Design_WaterAndSweatResistant_Title: '抗汗抗水耳塞*  ',
	Design_WaterAndSweatResistant_Subtitle: 'IPX-4 级抗汗抗水耳塞，助你从容应对各种环境。',
	Design_WaterAndSweatResistant_Disclaimer: '* Beats Studio Buds 可抗汗抗水，适合各种非水上运动和活动。',
	Design_ChapterText2: 'Beats Studio Buds 由多种不同用户进行了测试，确保<em>佩戴舒适、全方位贴合。</em>',
	Design_Comfort_Title: '精心设计，佩戴舒适',
	Design_Comfort_Subtitle: '软硅胶耳塞根据耳朵的解剖结构而设计，提供舒适、稳固的佩戴体验，完全不用担心掉落。',
	Design_Comfort_Tragus: '耳屏',
	Design_Comfort_Antitragus: '对耳屏',
	Design_SizeMatters_Title: '小巧设计',
	Design_SizeMatters_Subtitle: '时尚精巧的入耳式设计，相比当今大多数无线耳塞，明显重量更轻，体积更小。',
	Design_SizeMatters_BeatsStudio: 'BEATS FLEX PRO',
	Design_SizeMatters_BeatsStudio_10_1G: '10.1G',
	Design_SizeMatters_Bose: 'BOSE QC 耳塞',
	Design_SizeMatters_Bose_18_7G: '18.7G',
	Design_SizeMatters_Sony: 'Sony WF-1000XM3',
	Design_SizeMatters_Sony_16_5G: '16.5G',
	Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
	Design_SizeMatters_Samsung_12_5G: '12.5G',
	Design_ChapterText3: '耳塞被置于一个可以全天随身携带的便捷充电盒中，<em>不易丢失，还会受到保护</em>。',
	Design_PerfectMatch_Title: '磁力定位',
	Design_PerfectMatch_Subtitle: '充电盒内的磁力是定向的，这样每次耳塞都会正确吸附到位。',
	Design_Contact_Title: '精确接触',
	Design_Contact_Subtitle: '充电盒中的弹簧针脚使用弹性叶片弹簧，使耳塞和充电盒精确接触，确保耳塞正确就位以进行充电。',
	Design_Contact_PogoPin: '弹簧针脚',
	Design_FindYourMatch_Title: '选择心仪颜色 ',
	Design_FindYourMatch_Subtitle: '同色系充电盒',
	Design_FindYourMatch_White: '白色',
	Design_FindYourMatch_Black: '黑色',
	Design_FindYourMatch_BeatsRed: 'Beats 经典红色',
	Design_SustainablePackaging_Title: '可持续包装',
	Design_SustainablePackaging_Subtitle: 'Beats Studio Buds 的包装采用 92% 的植物基础混合原料，来自可循环的纤维和可持续的森林。',
	Design_Summary_Title: '畅享真无线带来的自由',
	Design_Summary1_WirelessFreedom: '真无线耳塞，让你尽享无线自由。 ',
	Design_Summary2_PocketSizedPortability: '轻巧的充电盒可轻松放入口袋。',
	Design_Summary3_WaterAndSweatResistant: 'IPX-4 级抗汗抗水耳塞。',
	Design_Summary4_Comfort: '时尚、小巧的耳塞，佩戴舒适稳固。',
	Design_Summary5_SizeMatters: '入耳式设计明显比大多数无线耳塞重量更轻，体积更小。',
	Design_Summary6_PerfectMatch: '充电盒内的磁力确保耳塞每次都吸附到位。',
	Design_Summary7_Contact: '充电盒内的弹簧针脚有助于耳塞正确安放，以便充电。',
	Design_Summary8_FindYourMatch: '同色系充电盒，有黑色、白色和 Beats 红色可选。',
	Compatibility_Intro_Title: '兼容性',
	Compatibility_Intro_VOSubtitle: 'Beats Studio Buds 同时兼容 Apple 和 Android 设备。',
	Compatibility_ChapterText1: '轻松与 <em>Apple 和 Android 设备搭配使用。</em>',
	Compatibility_OneTouch_Title: '一键配对 ',
	Compatibility_OneTouch_Subtitle: '利用简单的一键配对，通过蓝牙轻松连接 Apple<sup>1</sup> 或 Android<sup>2</sup> 设备。 ',
	Compatibility_OneTouch_Disclaimer: '1 一键配对需要使用 iOS 14.6 或更高版本<br/>2 快速配对需要启用位置功能。要使用全部功能，设备上需要运行 Android 6.0 或更新版本，启用 Google Play 服务并登录 Google 帐户。',
	Compatibility_VoiceAssistant_Title: '语音助手 ',
	Compatibility_VoiceAssistant_Subtitle: 'Beats Studio Buds 与你首选的语音助手兼容，可通过自定的“长按”功能进行激活。',
	Compatibility_LocateDevice_Title: '定位设备 ',
	Compatibility_LocateDevice_Subtitle: '使用 iOS 的“查找”功能或 Android 的“查找我的设备”功能可以轻松查找定位你的耳塞。 ',
	Compatibility_USBCCharging_Title: 'USB-C 充电 ',
	Compatibility_USBCCharging_Subtitle: 'USB-C 充电接口兼容 Apple 和 Android 设备，因此可以随时随地充电。 ',
	Compatibility_ManageDevice_Title: '管理设备 ',
	Compatibility_ManageDevice_Subtitle: '通过 Apple 设备或 Android 版 Beats App 管理各种功能，如“长按”自定、聆听模式、重命名产品和固件更新。',
	Compatibility_Summary_Title: '与 Apple 和 Android 设备兼容。',
	Compatibility_Summary1_OneTouch: 'iOS 和 Android 均可以一键配对。',
	Compatibility_Summary2_VoiceAssistant: '激活首选语音助手以管理通话、音乐等。',
	Compatibility_Summary3_LocateDevice: '使用你的设备轻松定位你的耳塞。',
	Compatibility_Summary4_USBCCharging: 'USB-C 充电接口支持通用充电。',
	Compatibility_Summary5_ManageDevice: '通过 iOS 或 Android 版 Beats App 管理设备。',
	Control_Intro_Title: '控制按钮',
	Control_Intro_VOSubtitle: '通过 Beats Studio Buds 控制声音和一天的节奏。',
	Control_ChapterText1: '<em>轻松使用控制键</em>来管理声音。',
	Control_OnEarControls_Title: '耳塞上集成控制按键 ',
	Control_OnEarControls_Subtitle: '贴耳式控制键可用于管理通话和音乐，切换聆听模式以及激活语音助手。<sup>*</sup>',
	Control_OnEarControls_Disclaimer: '* 长按功能自定需要使用运行 iOS 14.6 或更高版本的 iPhone，或安装了最新版 Beats app 的 Android 设备。',
	Control_OnEarControls_Instruction: '点按或轻点每个控制键以继续。',
	Control_OnEarControls_PlayPause: '按下可播放、暂停和接听/挂断电话 ',
	Control_OnEarControls_Skip: '连按两下按钮可跳过曲目 ',
	Control_OnEarControls_Previous: '连按三下按钮可播放上一曲 ',
	Control_OnEarControls_ScanForward: '连按两下并按住按钮可快进 ',
	Control_OnEarControls_ScanBackward: '连按三下并按住按钮可快退 ',
	Control_OnEarControls_ToggleModes: '长按可切换和关闭聆听模式，也可通过自定方式激活语音助手<sup>*</sup>',
	Control_PickASide_Title: '选择一侧',
	Control_PickASide_Subtitle: '使用 iOS 或 Android 版 Beats App 对每个耳塞上的“长按”按钮功能进行自定以激活主动降噪或激活语音助手。<sup>*</sup>',
	Control_PickASide_Disclaimer: '* 长按功能自定需要使用运行 iOS 14.6 或更高版本的 iPhone，或安装了最新版 Beats app 的 Android 设备。',
	Control_SayItWithSiri_Title: '用 Siri 实现语音控制 ',
	Control_SayItWithSiri_Subtitle: '无需双手，即可随时通过 Siri 控制音乐、管理通话、调节音量和更多操作。<sup>*</sup>',
	Control_SayItWithSiri_Disclaimer: '* Siri 可能仅支持部分语言或地区，并且功能可能因地区而异。需要接入互联网。可能需要支付蜂窝数据费用。 ',
	Control_SayItWithSiri_SiriCommand1: '“嘿 Siri，播放我的学习歌单。”',
	Control_SayItWithSiri_SiriCommand2: '“嘿 Siri，显示附近由女性运营的企业。”',
	Control_SayItWithSiri_SiriCommand3: '“嘿 Siri，计时 10 分钟。”',
	Control_Summary_Title: '使用贴耳式控制键管理声音。',
	Control_Summary1_OnEarControls: '你可以通过贴耳式控制键管理通话和音乐。',
	Control_Summary2_PickASide: '自定耳塞左右两侧的“长按”功能。',
	Control_Summary3_SayItWithSiri: '无需双手，随时可以访问 Siri.',
	Battery_Intro_Title: '电池',
	Battery_Intro_VOSubtitle: '电池续航长达一整天，让你一天都充满活力。',
	Battery_ChapterText1: '<em>电量满足一天所需，</em>助力你不断前进。',
	Battery_FastFuel_Title: '长达 8 小时',
	Battery_FastFuel_Subtitle: '配备高效芯片，每个耳塞可提供长达 8 小时的收听时间。<sup>*</sup>',
	Battery_FastFuel_Disclaimer: '* 电池续航时间取决于设备设置、环境、使用情况和诸多其他因素',
	Battery_20Hours_Title: '最长可达 24 小时',
	Battery_20Hours_Subtitle: '袖珍充电盒提供了两次额外充电，累计播放时间长达 24 小时。<sup>*</sup>',
	Battery_20Hours_Disclaimer: '* 电池续航时间取决于设备设置、环境、使用情况和诸多其他因素',
	Battery_FuelYourDay_Title: '持久续航，满足全天所需',
	Battery_FuelYourDay_Subtitle: '在电池电量不足时，采用 Fast Fuel 闪充技术充电 5 分钟，即可持续播放 1 小时。<sup>*</sup>',
	Battery_FuelYourDay_Disclaimer: '* 电池续航时间取决于设备设置、环境、使用情况和诸多其他因素',
	Battery_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。 ',
	Battery_UseCases_Listen: '聆听',
	Battery_UseCases_Listen_Subtitle: '可以收听 12 周你最喜欢的广播节目。<sup>*</sup>',
	Battery_UseCases_Listen_Disclaimer: '*基于 2 小时收听时长',
	Battery_UseCases_Meditate: '冥想',
	Battery_UseCases_Meditate_Subtitle: '有 32 种雨声冥想，帮你放松身心。<sup>*</sup>',
	Battery_UseCases_Meditate_Disclaimer: '*基于 45 分钟时长',
	Battery_UseCases_Game: '游戏',
	Battery_UseCases_Game_Subtitle: '玩 24 次你最喜欢的游戏。<sup>*</sup>',
	Battery_UseCases_Game_Disclaimer: '*基于 1 小时不需要使用麦克风的游戏时长',
	Battery_UseCases_Commute: '通勤',
	Battery_UseCases_Commute_Subtitle: '在 24 天通勤中使用。<sup>*</sup>',
	Battery_UseCases_Commute_Disclaimer: '* 基于 1 小时通勤时间。电池续航时间取决于设备设置、环境、使用情况及诸多其他因素。',
	Battery_WhatsInTheBox_Title: '包装内容',
	Battery_WhatsInTheBox_Subtitle: 'Beats Studio Buds 配有一个袖珍充电盒、一个通用 USB-C 充电接口，并有三种耳塞尺寸可供选择。',
	Batter_Summary_Title: '电池续航长达一整天。',
	Battery_Summary1_PowerYourDay: '耳塞提供长达 8 小时的播放时间。',
	Battery_Summary2_UpTo24: '累计播放最长可达 24 小时。',
	Battery_Summary3_FastFuel: 'Fast Fuel 闪电技术充电可提供 1 小时的播放时间。',
	Battery_Summary4_UseCases: '电池续航长达一整天，尽情听音乐、冥想和打游戏。',
	Battery_Summary5_WhatsInTheBox: '包装盒内附带一个袖珍充电盒、一个 USB-C 充电接口和三种尺寸的耳塞。',
	Menu_Sound_Module1: '音质简介',
	Menu_Sound_Module2: '出色音质始于紧密贴合。',
	Menu_Sound_Module3: '三个耳塞',
	Menu_Sound_Module4: '强劲均衡的音效',
	Menu_Sound_Module5: '定制声学平台',
	Menu_Sound_Module6: '清澈音质，低失真。 ',
	Menu_Sound_Module7: '精湛的音频调制',
	Menu_Sound_Module8: '电话来电 ',
	Menu_Sound_Module9: '高品质通话效果',
	Menu_Sound_Module10: '声音摘要',
	Menu_ANC_Module1: '主动降噪简介',
	Menu_ANC_Module2: '隔音效果',
	Menu_ANC_Module3: '源于密封性能',
	Menu_ANC_Module4: '技术',
	Menu_ANC_Module5: '适应性主动降噪 (ANC)',
	Menu_ANC_Module6: '播放校正',
	Menu_ANC_Module7: '主动降噪用例',
	Menu_ANC_Module8: '主动降噪摘要',
	Menu_Transparency_Module1: '通透模式简介',
	Menu_Transparency_Module2: '听到周围声音',
	Menu_Transparency_Module3: '通透模式 ',
	Menu_Transparency_Module4: '外部麦克风',
	Menu_Transparency_Module5: '前馈麦克风',
	Menu_Transparency_Module6: '通透模式用例',
	Menu_Transparency_Module7: '通透模式摘要',
	Menu_Design_Module1: '设计简介',
	Menu_Design_Module2: '无线连接，小巧设计',
	Menu_Design_Module3: '无线自由',
	Menu_Design_Module4: '袖珍尺寸，轻松便携',
	Menu_Design_Module5: '抗汗抗水',
	Menu_Design_Module6: '佩戴舒适，全方位贴合',
	Menu_Design_Module7: '精心设计，佩戴舒适',
	Menu_Design_Module8: '小巧设计',
	Menu_Design_Module9: '方便的充电盒',
	Menu_Design_Module10: '磁力定位',
	Menu_Design_Module11: '精确接触',
	Menu_Design_Module12: '颜色',
	Menu_Design_Module13: '设计摘要',
	Menu_Compatibility_Module1: '兼容性介绍',
	Menu_Compatibility_Module2: '与 Apple 和 Android 兼容',
	Menu_Compatibility_Module3: '一键配对',
	Menu_Compatibility_Module4: '语音助手',
	Menu_Compatibility_Module5: '定位设备 ',
	Menu_Compatibility_Module6: 'USB-C 充电 ',
	Menu_Compatibility_Module7: '管理设备',
	Menu_Compatibility_Module8: '兼容性摘要',
	Menu_Control_Module1: '控制简介',
	Menu_Control_Module2: '轻松访问',
	Menu_Control_Module3: '耳塞上的控制键',
	Menu_Control_Module4: '选择一侧',
	Menu_Control_Module5: '用 SIRI 实现语音控制 ',
	Menu_Control_Module6: '控制摘要',
	Menu_Battery_Module1: '电池简介',
	Menu_Battery_Module2: '电量满足一天所需',
	Menu_Battery_Module3: '聆听时间长达 8 小时',
	Menu_Battery_Module4: '24 小时累计播放时间',
	Menu_Battery_Module5: 'FAST FUEL 闪充技术',
	Menu_Battery_Module6: '电池用例',
	Menu_Battery_Module7: '包装内容',
	Menu_Battery_Module8: '电池摘要',
	Outro_ChapterText1: '借助主动降噪、通透模式、出众音质和无线连接、袖珍设计，<em>Beats Studio Buds 可以无缝融入你的生活。</em>',
	IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
	Global_Country_ALBANIA: '阿尔巴尼亚',
	Global_Country_ALGERIA: '阿尔及利亚',
	Global_Country_ANDORRA: '安道尔',
	Global_Country_ANGOLA: '安哥拉',
	Global_Country_ARGENTINA: '阿根廷',
	Global_Country_ARMENIA: '亚美尼亚',
	Global_Country_ARUBA: '阿鲁巴',
	Global_Country_AUSTRALIA: '澳大利亚',
	Global_Country_AUSTRIA: '奥地利',
	Global_Country_AZERBAIJAN: '阿塞拜疆',
	Global_Country_BAHAMAS: '巴哈马',
	Global_Country_BAHRAIN: '巴林',
	Global_Country_BANGLADESH: '孟加拉国',
	Global_Country_BARBADOS: '巴巴多斯',
	Global_Country_BELARUS: '白俄罗斯',
	Global_Country_BELGIUM: '比利时',
	Global_Country_BOLIVIA: '玻利维亚',
	Global_Country_BOSNIAHERZRGOVINA: '波斯尼亚和黑塞哥维纳',
	Global_Country_BOTSWANA: '博茨瓦纳',
	Global_Country_BRAZIL: '巴西',
	Global_Country_BRUNEI: '文莱达鲁萨兰国',
	Global_Country_BULGARIA: '保加利亚',
	Global_Country_CAMBODIA: '柬埔寨',
	Global_Country_CAMEROON: '喀麦隆',
	Global_Country_CANADA: '加拿大',
	Global_Country_CAYMANISLANDS: '开曼群岛',
	Global_Country_CHILE: '智利',
	Global_Country_CHINA : '中国大陆',
	Global_Country_COLOMBIA: '哥伦比亚',
	Global_Country_COOKISLANDS: '库克群岛',
	Global_Country_COSTARICA: '哥斯达黎加',
	Global_Country_CROATIA: '克罗地亚',
	Global_Country_CYPRUS: '塞浦路斯',
	Global_Country_CZECHREPUBLIC: '捷克',
	Global_Country_DENMARK: '丹麦',
	Global_Country_DOMINICANREPUBLIC: '多米尼加共和国',
	Global_Country_ECUADOR: '厄瓜多尔',
	Global_Country_EGYPT: '埃及',
	Global_Country_ELSALVADOR: '萨尔瓦多',
	Global_Country_ESTONIA: '爱沙尼亚',
	Global_Country_FAROEISLANDS: '法罗群岛',
	Global_Country_FIJI: '斐济',
	Global_Country_FINLAND: '芬兰',
	Global_Country_FRANCE: '法国',
	Global_Country_FRENCHGUIANA: '法属圭亚那',
	Global_Country_FRENCHPOLYNESIA: '法属玻利尼西亚',
	Global_Country_GEORGIA: '格鲁吉亚',
	Global_Country_GERMANY: '德国',
	Global_Country_GHANA: '加纳',
	Global_Country_GIBRALTAR: '直布罗陀',
	Global_Country_GREECE: '希腊',
	Global_Country_GUADELOUPE: '瓜德罗普岛',
	Global_Country_GUAM: '关岛',
	Global_Country_GUATEMALA: '危地马拉',
	Global_Country_GUERNSEY: '根西岛',
	Global_Country_GUINEA: '几内亚',
	Global_Country_GUYANA: '圭亚那',
	Global_Country_HONDURAS: '洪都拉斯',
	Global_Country_HONGKONG: '香港',
	Global_Country_HUNGARY: '匈牙利',
	Global_Country_ICELAND: '冰岛',
	Global_Country_INDIA: '印度',
	Global_Country_INDONESIA: '印度尼西亚',
	Global_Country_IRAQ: '伊拉克',
	Global_Country_IRELAND: '爱尔兰',
	Global_Country_ISLEOFMAN: '马恩岛',
	Global_Country_ISRAEL: '以色列',
	Global_Country_ITALY: '意大利',
	Global_Country_IVORYCOAST: '科特迪瓦',
	Global_Country_JAPAN: '日本',
	Global_Country_JERSEY: '泽西岛',
	Global_Country_JORDAN: '约旦',
	Global_Country_KAZAKHSTAN: '哈萨克斯坦',
	Global_Country_KENYA: '肯尼亚',
	Global_Country_KOREA: '韩国',
	Global_Country_KUWAIT: '科威特',
	Global_Country_KYRGYZSTAN: '吉尔吉斯斯坦',
	Global_Country_LAOS: '老挝',
	Global_Country_LATVIA: '拉脱维亚',
	Global_Country_LEBANON: '黎巴嫩',
	Global_Country_LESOTHO: '莱索托',
	Global_Country_LITHUANIA: '立陶宛',
	Global_Country_LUXEMBOURG: '卢森堡',
	Global_Country_MACAU: '澳门',
	Global_Country_MACEDONIA: '马其顿',
	Global_Country_MADAGASCAR: '马达加斯加',
	Global_Country_MALAWI: '马拉维',
	Global_Country_MALAYSIA: '马来西亚',
	Global_Country_MALDIVES: '马尔代夫',
	Global_Country_MALTA: '马耳他',
	Global_Country_MARTINIQUE: '马提尼克',
	Global_Country_MAURITIUS: '毛里求斯',
	Global_Country_MAYOTTE: '马约特',
	Global_Country_MEXICO: '墨西哥',
	Global_Country_MOLDOVA: '摩尔多瓦',
	Global_Country_MONACO: '摩纳哥',
	Global_Country_MONTENEGRO: '黑山共和国',
	Global_Country_MOROCCO: '摩洛哥',
	Global_Country_MOZAMBIQUE: '莫桑比克',
	Global_Country_MYANMAR: '缅甸',
	Global_Country_NAMIBIA: '纳米比亚',
	Global_Country_NEPAL: '尼泊尔',
	Global_Country_NETHERLANDS: '荷兰',
	Global_Country_NEWCALEDONIA: '新喀里多尼亚',
	Global_Country_NEWZEALAND: '新西兰',
	Global_Country_NICARAGUA: '尼加拉瓜',
	Global_Country_NIGERIA: '尼日利亚',
	Global_Country_NORWAY: '挪威',
	Global_Country_OMAN: '阿曼',
	Global_Country_PAKISTAN: '巴基斯坦',
	Global_Country_PALESTINIANTERRITORY: '巴勒斯坦领土',
	Global_Country_PANAMA: '巴拿马',
	Global_Country_PARAGUAY: '巴拉圭',
	Global_Country_PERU: '秘鲁',
	Global_Country_PHILIPPINES: '菲律宾',
	Global_Country_POLAND: '波兰',
	Global_Country_PORTUGAL: '葡萄牙',
	Global_Country_PUERTORICO: '波多黎各',
	Global_Country_QATAR: '卡塔尔',
	Global_Country_REUNION: '留尼汪',
	Global_Country_ROMANIA: '罗马尼亚',
	Global_Country_RUSSIA: '俄罗斯',
	Global_Country_SAINTMARTIN: '圣马丁岛',
	Global_Country_SANMARINO: '圣马力诺',
	Global_Country_SAUDIARABIA: '沙特阿拉伯',
	Global_Country_SENEGAL: '塞内加尔',
	Global_Country_SERBIA: '塞尔维亚',
	Global_Country_SINGAPORE: '新加坡',
	Global_Country_SINTMAARTEN: '荷属圣马丁',
	Global_Country_SLOVAKIA: '斯洛伐克',
	Global_Country_SLOVENIA: '斯洛文尼亚',
	Global_Country_SOUTHAFRICA: '南非',
	Global_Country_SPAIN: '西班牙',
	Global_Country_SRILANKA: '斯里兰卡',
	Global_Country_SWEDEN: '瑞典',
	Global_Country_SWITZERLAND: '瑞士',
	Global_Country_TAIWAN: '台湾',
	Global_Country_TAJIKISTAN: '塔吉克斯坦',
	Global_Country_TANZANIA: '坦桑尼亚',
	Global_Country_THAILAND: '泰国',
	Global_Country_TRINIDADTOBAGO: '特立尼达和多巴哥',
	Global_Country_TUNISIA: '突尼斯',
	Global_Country_TURKEY: '土耳其',
	Global_Country_TURKMENISTAN: '土库曼斯坦',
	Global_Country_UGANDA: '乌干达',
	Global_Country_UKRAINE: '乌克兰',
	Global_Country_UAE: '阿联酋',
	Global_Country_UK: '英国',
	Global_Country_US: '美国',
	Global_Country_URUGUAY: '乌拉圭',
	Global_Country_UZBEKISTAN: '乌兹别克斯坦',
	Global_Country_VENEZUELA: '委内瑞拉',
	Global_Country_VIETNAM: '越南',
	Global_Country_ZAMBIA: '赞比亚',
	Global_Country_ZIMBABWE: '津巴布韦',
	Global_Menu_Confirm: '确认',
	Global_Menu_Help: '需要帮助？<u>联系我们</u>',
	Global_Menu_Restart: '重新开始体验',
	Global_Menu_Button_Restart: '重新开始培训',
	Global_Menu_Button_Contact: '需要帮助？联系我们',
	IntroOutro_TestYourKnowledge: '测试你的 Beats Studio Buds 知识。',
	IntroOutro_CTA: '参加测验',
	Global_Menu_Language: '语言',
	Global_Field_Location: '地点',
	Global_Password_Rules_Header: '密码必须包含',
	Global_Password_Rule_Length: '至少 8 个字符',
	Global_Password_Rule_Uppercase: '1个大写字母',
	Global_Password_Rule_Lowercase: '1 个小写字母',
	Global_Password_Rule_Number: '1 个数字',
	Global_Password_Rule_Special: '特殊符号 (!@#$%^&*)',
	Global_Field_Password_CreateError: '密码不满足最低标准。',

	Global_ProductSelector_Headline: '选择 Beats TRNR 培训：',
	Global_ProductSelector_Product1: 'Beats Studio Buds',
	Global_ProductSelector_Product2: 'Beats Fit Pro',
	Global_ProductSelector_CTA_1: '立即开始',
	Global_ProductSelector_CTA_2: '继续',
	Global_ProductSelector_CTA_3: '重新开始',

	Global_WelcomeInstructions_Headline:
    '欢迎参加 Beats TRNR！通过培训成为 Beats 专家。',
  Global_WelcomeInstructions_Subhead: '请先了解培训前须知：',
  Global_WelcomeInstructions_Body1: '每次培训大约需要 15 分钟才能完成。',
  Global_WelcomeInstructions_Body2: '每次培训均需用到音频，请确保已开启声音。',
  Global_WelcomeInstructions_Body3:
    '在每次培训中，请切记完成每个部分才能进行下一步。',
  Global_WelcomeInstructions_Body4:
    '如果你需要离开，也不用担心。你可以随时从上次中断的地方继续。',
	Global_ForgotEmail_NoUser:"没有与此标识符对应的用户记录。该用户可能已删除。"

	
	
};

export default copy;
