const CONFIG = {
  oneClick: {
    clicks: 1,
    timing: 0,
    holdBased: false,
  },
  doubleClick: {
    clicks: 2,
    timing: 250,
    holdBased: false,
  },
  tripleClick: {
    clicks: 3,
    timing: 500,
    holdBased: false,
  },
  doubleAndHold: {
    clicks: 2,
    timing: 500,
    holdBased: true,
  },
  tripleAndHold: {
    clicks: 3,
    timing: 700,
    holdBased: true,
  },
  hold: {
    clicks: 1,
    timing: 700,
    holdBased: true,
  },
}

let type
let el
let playloadAction

let clicks = 0
let timer
let mouseIsDown

const handleAction = (action) => action && action(type)

const mouseUpFunc = () => {
  mouseIsDown = Date.now()
  clicks++

  timer = setTimeout(function () {
    if (clicks === 1 && type === 'oneClick') {
      // console.log('oneClick')

      handleAction(playloadAction)
    }

    if (clicks === 2 && type === 'doubleClick') {
      // console.log('doubleClick')

      handleAction(playloadAction)
    }

    if (clicks === 3 && type === 'tripleClick') {
      // console.log('tripleClick')

      handleAction(playloadAction)
    }

    if (clicks === 2 && Date.now() - mouseIsDown >= 300 && type === 'doubleAndHold') {
      // console.log('doubleAndHold')

      handleAction(playloadAction)
    }

    if (clicks === 3 && Date.now() - mouseIsDown >= 300 && type === 'tripleAndHold') {
      // console.log('tripleAndHold')

      handleAction(playloadAction)
    }

    if (clicks === 1 && Date.now() - mouseIsDown >= 300 && type === 'hold') {
      // console.log('hold')

      handleAction(playloadAction)
    }

    clicks = 0
  }, CONFIG[type].timing)
}

const mouseDownFunc = () => {
  if (CONFIG[type].holdBased) {
    clearTimeout(timer)
  }

  if (!CONFIG[type].holdBased && clicks === CONFIG[type].clicks) {
    clearTimeout(timer)
  }

  mouseIsDown = null
}

export const detectInteraction = (interacitionType, element, action) => {
  type = interacitionType
  playloadAction = action
  el = element

  element.addEventListener('pointerdown', mouseUpFunc)
  element.addEventListener('pointerup', mouseDownFunc)
}

export const clearDetectInteraction = () => {
  el && el.removeEventListener('pointerdown', mouseUpFunc)
  el && el.removeEventListener('pointerup', mouseDownFunc)
}
