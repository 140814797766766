import IntroModule from '../../components/Module/IntroModule';
import SummaryModule from '../../components/Module/SummaryModule';
import TextModule from '../../components/Module/TextModule';
import TextAndVideoModule from '../../components/Module/TextAndVideoModule';
import UseCaseModule from '../../components/Module/UseCaseModule';
import InteractiveModule from '../../components/Module/InteractiveModule';
import TransparencyModule from '../../components/Module/TransparencyModule'

const CHAPTERS = [
	{
		id: 'intro',
		title: 'Global_Intro',
		modules: [
			{
				id: 'intro',
				title: 'Global_Intro',
				component: TextAndVideoModule,
				replaceNextButtonContent: 'Global_UI_Begin',
				hideWithinChapterNav: true,
				content: {
					title: 'IntroOutro_BeatsStudioBuds',
					text: 'Intro_Intro_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/1_INTRO/VO_Intro_01_Intro.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Intro_V003.webm`,
						},
						hevc: {
							path: `/assets/studioBuds/video/CR_TRNR_Intro_V003.mp4`,
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'sound',
		number: 1,
		title: 'Global_ChapterTitle_Sound',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_grey.png`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_Sound_Module1',
				component: IntroModule,
				hideWithinChapterNav: true,
				silenceBackgroundAudio: true,
				nextOnComplete: true,
				content: {
					title: 'Sound_Intro_Title',
					subTitle: 'Sound_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_Sound_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: '/assets/studioBuds/video/CR_TRNR_Sound_Intro-1.webm',
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Sound_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'great-sound',
				title: 'Menu_Sound_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_02_ChapterText1.mp3`,
						buffer: undefined, // Set during preload
					},
					eyebrow: null,
					text: 'Sound_ChapterText1',
				},
			},
			{
				id: 'great-fit',
				title: 'Menu_Sound_Module3',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Sound_FindYourFit_Title',
					text: 'Sound_FindYourFit_Subtitle',

					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_03_FindYourFit.mp3`,
						buffer: undefined, // Set during preload
					},
					loop: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_FindYourFit_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Sound_FindYourFit_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_FindYourFit_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Sound_FindYourFit_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'balanced-sound',
				title: 'Menu_Sound_Module4',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_04_ChapterText2.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Sound_ChapterText2',
				},
			},
			{
				id: 'driven-by-premium-sound',
				title: 'Menu_Sound_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Sound_DrivenByPremiumSound_Title',
					text: 'Sound_DrivenByPremiumSound_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_05_DrivenByPremiumSound.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_DrivenByPremiumSound_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Sound_DrivenByPremiumSound_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'clear-sound-low-distortion',
				title: 'Menu_Sound_Module6',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Sound_MoreMusicLessDistortion_Title',
					text: 'Sound_MoreMusicLessDistortion_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_06_MoreMusicLessDistortion.mp3`,
						buffer: undefined, // Set during preload
					},
					loop: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_MoreMusicLessDistortion_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Sound_MoreMusicLessDistortion_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_MoreMusicLessDistortion_v01.webm`,
						},
						hevc: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_MoreMusicLessDistortion_v01.mp4`,
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'fine-tuned-audio',
				title: 'Menu_Sound_Module7',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Sound_FineTunedAudio_Title',
					text: 'Sound_FineTunedAudio_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_07_FineTunedAudio.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_FineTunedAudio_v01.webm`,
						},
						hevc: {
							path: `/assets/studioBuds/video/CR_TRNR_Sound_FineTunedAudio_v01.mp4`,
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'clear-phone-calls',
				title: 'Menu_Sound_Module8',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_08_ChapterText3.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Sound_ChapterText3',
				},
			},
			{
				id: 'high-quality-call-performance',
				title: 'Menu_Sound_Module9',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Sound_SpeakingOfSound_Title',
					text: 'Sound_SpeakingOfSound_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_09_SpeakingOfSound.mp3`,
						buffer: undefined, // Set during preload
					},
					loop: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_HighQualityCallPerformance_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_HighQualityCallPerformance_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_HighQualityCallPerformance_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_HighQualityCallPerformance_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'as-the-artist-intended',
				title: 'Menu_Sound_Module10',
				bgImage: '/assets/studioBuds/images/bg_grey.png',
				gradientStart: 'rgba(176, 176, 176, 0.9)',
				gradientEnd: 'rgba(176, 176, 176, 0)',
				nextChapter: '/anc',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				content: {
					title: 'Sound_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/sound/1.jpg',
							description: 'Sound_Summary1_ThreeEartip',
							link: '/great-sound',
						},
						{
							image: '/assets/studioBuds/images/summary/sound/2.jpg',
							description: 'Sound_Summary2_CustomAcoustic',
							link: '/driven-by-premium-sound',
						},
						{
							image: '/assets/studioBuds/images/summary/sound/3.jpg',
							description: 'Sound_Summary3_FlexibleDriverDiaphragm',
							link: '/clear-sound-low-distortion',
						},
						{
							image: '/assets/studioBuds/images/summary/sound/4.jpg',
							description: 'Sound_Summary4_FinetunedAudio',
							link: '/fine-tuned-audio',
						},
						{
							image: '/assets/studioBuds/images/summary/sound/5.jpg',
							description: 'Sound_Summary5_SpeakingOfSound',
							link: '/high-quality-call-performance',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/2_SOUND/VO_Sound_10_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'anc',
		number: 2,
		title: 'Global_ChapterTitle_ANC',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_red.jpg`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_ANC_Module1',
				component: IntroModule,
				silenceBackgroundAudio: true,
				hideWithinChapterNav: true,
				nextOnComplete: true,
				content: {
					title: 'ANC_Intro_TITLE',
					subTitle: 'ANC_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_ANC_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_ANC_Intro-1.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_ANC_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'acoustic-seal',
				title: 'Menu_ANC_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_02_ChapterText1.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'ANC_ChapterText1',
				},
			},
			{
				id: 'start-with-the-seal',
				title: 'Menu_ANC_Module3',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'ANC_Seal_Title',
					text: 'ANC_Seal_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_03_Seal.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_ANC_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_ANC_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'protects-your-sound',
				title: 'Menu_ANC_Module4',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_04_ChapterText2.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'ANC_ChapterText2',
				},
			},
			{
				id: 'anc-that-adapts',
				title: 'Menu_ANC_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'ANC_ANCThatAdapts_Title',
					text: 'ANC_ANCThatAdapts_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_05_ANCThatAdapts.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_ANC_ANCThatAdapts.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_ANC_ANCThatAdapts.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loop: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_ANC_ANCThatAdapts.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_ANC_ANCThatAdapts.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'play-that-back',
				title: 'Menu_ANC_Module6',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'ANC_Playback_Title',
					text: 'ANC_Playback_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_06_PlayBack.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_ANC_ANCPlayThatBack_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_ANC_ANCPlayThatBack_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loop: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_ANC_ANCPlayThatBack_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_ANC_ANCPlayThatBack_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'use-cases',
				title: 'Menu_ANC_Module7',
				bgImage: '/assets/studioBuds/images/anc-use-case-bg.jpg',
				component: UseCaseModule,
				blockAudioAdvance: true,
				content: {
					title: 'ANC_UseCases_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_07_UseCases.mp3`,
						buffer: undefined, // Set during preload
					},
					cases: [
						{
							title: 'ANC_UseCases_Studying',
							description: 'ANC_UseCases_Studying_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/3_ANC/VO_ANC_08_UseCases_Studying.mp3`,
								linkElement: undefined, // Set during preload
								audioElement: undefined, // Set during preload
							},
						},
						{
							title: 'ANC_UseCases_WorkingOut',
							description: 'ANC_UseCases_WorkingOut_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/3_ANC/VO_ANC_09_UseCases_WorkingOut.mp3`,
								linkElement: undefined, // Set during preload
								audioElement: undefined, // Set during preload
							},
						},
						{
							title: 'ANC_UseCases_Airplane',
							description: 'ANC_UseCases_Airplane_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/3_ANC/VO_ANC_10_UseCases_Airplane.mp3`,
								linkElement: undefined, // Set during preload
								audioElement: undefined, // Set during preload
							},
						},
						{
							title: 'ANC_UseCases_Commute',
							description: 'ANC_UseCases_Commute_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/3_ANC/VO_ANC_11_UseCases_Commute.mp3`,
								linkElement: undefined, // Set during preload
								audioElement: undefined, // Set during preload
							},
						},
					],
				},
			},
			{
				id: 'stay-focused-with-anc',
				title: 'Menu_ANC_Module8',
				bgImage: '/assets/studioBuds/images/bg_red.jpg',
				gradientStart: 'rgba(139, 21, 21, 1.0)',
				gradientEnd: 'rgba(139, 21, 21, 0)',
				nextChapter: '/transparency',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				content: {
					title: 'ANC_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/anc/1.jpg',
							description: 'ANC_Summary1_ImmerseSound',
							link: '/start-with-the-seal',
						},
						{
							image: '/assets/studioBuds/images/summary/anc/2.jpg',
							description: 'ANC_Summary2_AdaptiveTechnology',
							link: '/anc-that-adapts',
						},
						{
							image: '/assets/studioBuds/images/summary/anc/3.jpg',
							description: 'ANC_Summary3_PlaybackCorrection',
							link: '/play-that-back',
						},
						{
							image: '/assets/studioBuds/images/summary/anc/4.jpg',
							description: 'ANC_Summary4_UseCases',
							link: '/use-cases',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/3_ANC/VO_ANC_12_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'transparency',
		number: 3,
		title: 'Global_ChapterTitle_Transparency',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_grey.png`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_Transparency_Module1',
				component: IntroModule,
				hideWithinChapterNav: true,
				silenceBackgroundAudio: true,
				nextOnComplete: true,
				content: {
					title: 'Transparency_Intro_Title',
					subTitle: 'Transparency_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_Transparency_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Transparency_Intro-1.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Transparency_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'hear-the-world-around-you',
				title: 'Menu_Transparency_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_02_ChapterText1.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Transparency_ChapterText1',
				},
			},
			{
				id: 'transparency-mode-feature',
				title: 'Menu_Transparency_Module3',
				component: TransparencyModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Transparency_TransparencyMode_Title',
					text: 'Transparency_TransparencyMode_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_03_TransparencyMode.mp3`,
						buffer: undefined, // Set during preload
					},
					hotspotCoordinates: {
						x: -0.158,
						y: 0.128,
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_01_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_01_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loop: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_01_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_01_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					
					videoTransitionToDefault: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_01_v11.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_01_v11.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loopAlternate: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_02_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_02_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					videoTransitionToAlternate: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_02_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_TransparencyMode_TransparencyMode_02_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'listen-for-outside-noise',
				title: 'Menu_Transparency_Module4',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_04_ChapterText2.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Transparency_ChapterText2',
				},
			},
			{
				id: 'feed-forward-microphones',
				title: 'Menu_Transparency_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Transparency_FeedForwardMic_Title',
					text: 'Transparency_FeedForwardMic_Subtitle',

					voiceover: {
						path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_05_FeedForwardMic.mp3`,
						buffer: undefined, // Set during preload
					},
					loop: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Transparency_FeedForwardMic_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Transparency_FeedForwardMic_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					video: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Transparency_FeedForwardMic_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Transparency_FeedForwardMic_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'use-cases',
				title: 'Menu_Transparency_Module6',
				bgImage: '/assets/studioBuds/images/transparency-use-case-bg.jpg',
				component: UseCaseModule,
				blockAudioAdvance: true,
				content: {
					title: 'Transparency_UseCases_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_06_UseCases.mp3`,
						buffer: undefined, // Set during preload
					},
					cases: [
						{
							title: 'Transparency_UseCases_Office',
							description: 'Transparency_UseCases_Office_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_07_UseCases_Office.mp3`,
							},
						},
						{
							title: 'Transparency_UseCases_Travel',
							description: 'Transparency_UseCases_Travel_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_08_UseCases_Travel.mp3`,
							},
						},
						{
							title: 'Transparency_UseCases_Street',
							description: 'Transparency_UseCases_Street_Subtitle',
							audio: {
								path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_09_UseCases_Street.mp3`,
							},
						},
					],
				},
			},
			{
				id: 'let-the-world-in',
				title: 'Menu_Transparency_Module7',
				nextChapter: '/design',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				bgImage: '/assets/studioBuds/images/bg_grey.png',
				gradientStart: 'rgba(176, 176, 176, 0.9)',
				gradientEnd: 'rgba(176, 176, 176, 0)',
				content: {
					title: 'Transparency_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/transparency/1.jpg',
							description: 'Transparency_Summary1_MixTheSounds',
							link: '/transparency-mode-feature',
						},
						{
							image: '/assets/studioBuds/images/summary/transparency/2.jpg',
							description: 'Transparency_Summary2_FeedForward',
							link: '/feed-forward-microphones',
						},
						{
							image: '/assets/studioBuds/images/summary/transparency/3.jpg',
							description: 'Transparency_Summary3_HearYourSurroundings',
							link: '/use-cases',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/4_TRANSPARENCY/VO_Transparency_10_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'design',
		number: 4,
		title: 'Global_ChapterTitle_Design',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_red.jpg`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_Design_Module1',
				component: IntroModule,
				hideWithinChapterNav: true,
				silenceBackgroundAudio: true,
				nextOnComplete: true,
				content: {
					title: 'Design_Intro_Title',
					subTitle: 'Design_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_Design_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_Intro-1.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'fit-seamlessly',
				title: 'Menu_Design_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_02_ChapterText1.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Design_ChapterText1',
				},
			},
			{
				id: 'wireless-freedom',
				title: 'Menu_Design_Module3',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_WirelessFreedom_Title',
					text: 'Design_WirelessFreedom_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_03_WirelessFreedom.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_WirelessFreedom.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_WirelessFreedom.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'pocket-sized-portability',
				title: 'Menu_Design_Module4',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_PocketSizedPortability_Titles',
					text: 'Design_PocketSizedPortability_Subtitles',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_04_PocketSizedPortability.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/TRNR_Design_pocket-sized-portability_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/TRNR_Design_pocket-sized-portability_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'sweat-water-resistant',
				title: 'Menu_Design_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_WaterAndSweatResistant_Title',
					text: 'Design_WaterAndSweatResistant_Subtitle',
					disclaimer: 'Design_WaterAndSweatResistant_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_05_WaterAndSweatResistant.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_WaterAndSweatResistant.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_WaterAndSweatResistant.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'versatile-fit',
				title: 'Menu_Design_Module6',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_06_ChapterText2.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Design_ChapterText2',
				},
			},
			{
				id: 'designed-for-comfort',
				title: 'Menu_Design_Module7',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_Comfort_Title',
					text: 'Design_Comfort_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_07_Comfort.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_Comfort_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_Comfort_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loop: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_Comfort_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_Comfort_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'size-matters',
				title: 'Menu_Design_Module8',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_SizeMatters_Title',
					text: 'Design_SizeMatters_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_08_SizeMatters.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_SizeMatters_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_SizeMatters_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loop: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_SizeMatters_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_SizeMatters_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'secure-and-protected',
				title: 'Menu_Design_Module9',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_09_ChapterText3.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Design_ChapterText3',
				},
			},
			{
				id: 'perfect-match',
				title: 'Menu_Design_Module10',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_PerfectMatch_Title',
					text: 'Design_PerfectMatch_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_10_PerfectMatch.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_PerfectMatch.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_PerfectMatch.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'make-contact',
				title: 'Menu_Design_Module11',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_Contact_Title',
					text: 'Design_Contact_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_11_Contact.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_Contact_Intro.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_Contact_Intro.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					loop: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_Contact_Loop.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_Contact_Loop.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'find-your-match',
				title: 'Menu_Design_Module12',
				component: InteractiveModule,
				colorPicker: true,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Design_FindYourMatch_Title',
					text: 'Design_FindYourMatch_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_12_FindYourMatch.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchWhite.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchWhite.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					steps: [
						{
							text: 'Design_FindYourMatch_White',
							bgColor: '#D6D6D6',
					
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchWhite.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchWhite.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							text: 'Design_FindYourMatch_Black',
							bgColor: '#000000',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchBlack.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchBlack.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							text: 'Design_FindYourMatch_BeatsRed',
							bgColor: '#EE223E',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchRed.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Design_FindYourMatchRed.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
					],
				
				},
			},
			// {
			// 	id: 'eco-friendly',
			// 	title: 'something',
			// 	component: TextModule,
			// 	content: {
			// 		backgroundImage: {
			// 			path: `/assets/studioBuds/images/bg_red.jpg`,
			// 		},
			// 		voiceover: {
			// 			path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_13_ChapterText4.mp3`,
			// 			linkElement: undefined, // Set during preload
			// 			audioElement: undefined, // Set during preload
			// 		},
			// 		text: 'Design_ChapterText4',
			// 	},
			// },
			// {
			// 	id: 'sustainable-packaging',
			// 	title: 'something',
			// 	component: TextAndVideoModule,
			// 	componentProps: {
			// 		variant: 'leftAlign',
			// 	},
			// 	content: {
			// 		title: 'Design_SustainablePackaging_Title',
			// 		text: 'Design_SustainablePackaging_Subtitle',
			// 		voiceover: {
			// 			path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_14_SustainablePackaging.mp3`,
			// 			linkElement: undefined, // Set during preload
			// 			audioElement: undefined, // Set during preload
			// 		},
			// 		video: {
			// 			webm: {
			// 				path: `/assets/studioBuds/video/CR_TRNR_GreatSoundGreatFit_v01.webm`,
			// 			},
			// 			hevc: {
			// 				path: '/assets/studioBuds/video/CR_TRNR_GreatSoundGreatFit_v01.mp4',
			// 			},
			// 			linkElement: undefined, // Set during preload
			// 			videoElement: undefined, // Set during preload
			// 		},
			// 	},
			// },
			{
				id: 'truly-wireless-freedom',
				title: 'Menu_Design_Module13',
				nextChapter: '/compatibility',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				bgImage: '/assets/studioBuds/images/bg_red.jpg',
				gradientStart: 'rgba(139, 21, 21, 1.0)',
				gradientEnd: 'rgba(139, 21, 21, 0)',
				content: {
					title: 'Design_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/design/1.png',
							description: 'Design_Summary1_WirelessFreedom',
							link: '/wireless-freedom',
						},
						{
							image: '/assets/studioBuds/images/summary/design/2.png',
							description: 'Design_Summary2_PocketSizedPortability',
							link: '/pocket-sized-portability',
						},
						{
							image: '/assets/studioBuds/images/summary/design/3.png',
							description: 'Design_Summary3_WaterAndSweatResistant',
							link: '/sweat-water-resistant',
						},
						{
							image: '/assets/studioBuds/images/summary/design/4.png',
							description: 'Design_Summary4_Comfort',
							link: '/designed-for-comfort',
						},
						{
							image: '/assets/studioBuds/images/summary/design/5.png',
							description: 'Design_Summary5_SizeMatters',
							link: '/size-matters',
						},
						{
							image: '/assets/studioBuds/images/summary/design/6.png',
							description: 'Design_Summary6_PerfectMatch',
							link: '/perfect-match',
						},
						{
							image: '/assets/studioBuds/images/summary/design/7.png',
							description: 'Design_Summary7_Contact',
							link: '/make-contact',
						},
						{
							image: '/assets/studioBuds/images/summary/design/8.png',
							description: 'Design_Summary8_FindYourMatch',
							link: '/find-your-match',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/5_DESIGN/VO_Design_15_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'compatibility',
		number: 5,
		title: 'Global_ChapterTitle_Compatibility',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_grey.png`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_Compatibility_Module1',
				component: IntroModule,
				silenceBackgroundAudio: true,
				hideWithinChapterNav: true,
				nextOnComplete: true,
				content: {
					title: 'Compatibility_Intro_Title',
					subTitle: 'Compatibility_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_Compatibility_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Compatibility_Intro-1.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Compatibility_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'both-apple-and-android',
				title: 'Menu_Compatibility_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_02_ChapterText1.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Compatibility_ChapterText1',
				},
			},
			{
				id: 'one-touch-pairing',
				title: 'Menu_Compatibility_Module3',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Compatibility_OneTouch_Title',
					text: 'Compatibility_OneTouch_Subtitle',
					disclaimer: 'Compatibility_OneTouch_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_03_OneTouch.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Compatibility_OneTouch_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Compatibility_OneTouch_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'preferred-voice-assistant',
				title: 'Menu_Compatibility_Module4',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Compatibility_VoiceAssistant_Title',
					text: 'Compatibility_VoiceAssistant_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_04_VoiceAssistant.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Compatibility_VoiceAssistant_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Compatibility_VoiceAssistant_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'locate-your-device',
				title: 'Menu_Compatibility_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Compatibility_LocateDevice_Title',
					text: 'Compatibility_LocateDevice_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_05_LocateDevice.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Compatibility_LocateDevice_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Compatibility_LocateDevice_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'usb-c-charging',
				title: 'Menu_Compatibility_Module6',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Compatibility_USBCCharging_Title',
					text: 'Compatibility_USBCCharging_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_06_USBCCharging.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Compatibility_USBCCharging_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Compatibility_USBCCharging_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'manage-your-device',
				title: 'Menu_Compatibility_Module7',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Compatibility_ManageDevice_Title',
					text: 'Compatibility_ManageDevice_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_07_ManageDevice.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Compatibility_ManageDevice_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Compatibility_ManageDevice_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'compatible-with-apple-android',
				title: 'Menu_Compatibility_Module8',
				nextChapter: '/controls',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				bgImage: '/assets/studioBuds/images/bg_grey.png',
				gradientStart: 'rgba(176, 176, 176, 0.9)',
				gradientEnd: 'rgba(176, 176, 176, 0)',
				content: {
					title: 'Compatibility_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/compatibility/1.jpg',
							description: 'Compatibility_Summary1_OneTouch',
							link: '/one-touch-pairing',
						},
						{
							image: '/assets/studioBuds/images/summary/compatibility/2.jpg',
							description: 'Compatibility_Summary2_VoiceAssistant',
							link: '/preferred-voice-assistant',
						},
						{
							image: '/assets/studioBuds/images/summary/compatibility/3.jpg',
							description: 'Compatibility_Summary3_LocateDevice',
							link: '/locate-your-device',
						},
						{
							image: '/assets/studioBuds/images/summary/compatibility/4.jpg',
							description: 'Compatibility_Summary4_USBCCharging',
							link: '/usb-c-charging',
						},
						{
							image: '/assets/studioBuds/images/summary/compatibility/5.jpg',
							description: 'Compatibility_Summary5_ManageDevice',
							link: '/manage-your-device',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/6_COMPATIBILITY/VO_Compatibility_08_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'controls',
		number: 6,
		title: 'Global_ChapterTitle_Controls',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_red.jpg`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_Control_Module1',
				component: IntroModule,
				hideWithinChapterNav: true,
				silenceBackgroundAudio: true,
				nextOnComplete: true,
				content: {
					title: 'Control_Intro_Title',
					subTitle: 'Control_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_Controls_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Controls_Intro-1.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Controls_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'easy-to-access-controls',
				title: 'Menu_Control_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/7_CONTROLS/VO_Control_02_ChapterText.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Control_ChapterText1',
				},
			},
			{
				id: 'on-ear-controls',
				title: 'Menu_Control_Module3',
				component: InteractiveModule,
				silenceBackgroundAudio: true,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Control_OnEarControls_Title',
					text: 'Control_OnEarControls_Subtitle',
					disclaimer: 'Control_OnEarControls_Disclaimer',
					instructions: 'Control_OnEarControls_Instruction',
					voiceover: {
						path: `/assets/studioBuds/audio/7_CONTROLS/VO_Control_03_OnEarControls.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Control_OnEarControls_Intro_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Control_OnEarControls_Intro_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					steps: [
						{
							icon: '/assets/studioBuds/images/interactive/step-1.svg',
							text: 'Control_OnEarControls_PlayPause',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Controls_PlayPause_v01.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Controls_PlayPause_v01.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							icon: '/assets/studioBuds/images/interactive/step-2.svg',
							text: 'Control_OnEarControls_Skip',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Controls_SkipTrack_v01.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Controls_SkipTrack_v01.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							icon: '/assets/studioBuds/images/interactive/step-3.svg',
							text: 'Control_OnEarControls_Previous',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Controls_PreviousTrack_v01.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Controls_PreviousTrack_v01.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							icon: '/assets/studioBuds/images/interactive/step-4.svg',
							text: 'Control_OnEarControls_ScanForward',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Controls_ScanForward_v01.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Controls_ScanForward_v01.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							icon: '/assets/studioBuds/images/interactive/step-5.svg',
							text: 'Control_OnEarControls_ScanBackward',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Controls_ScanBackwards_v01.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Controls_ScanBackwards_v01.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
						{
							icon: '/assets/studioBuds/images/interactive/step-6.svg',
							text: 'Control_OnEarControls_ToggleModes',
							video: {
								webm: {
									path: `/assets/studioBuds/video/CR_TRNR_Controls_ToggleListeningMode_v01.webm`,
								},
								hevc: {
									path: '/assets/studioBuds/video/CR_TRNR_Controls_ToggleListeningMode_v01.mp4',
								},
								linkElement: undefined, // Set during preload
								videoElement: undefined, // Set during preload
							},
						},
					],
				},
			},
			{
				id: 'pick-a-side',
				title: 'Menu_Control_Module4',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Control_PickASide_Title',
					text: 'Control_PickASide_Subtitle',
					disclaimer: 'Control_PickASide_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/7_CONTROLS/VO_Control_04_PickASide.mp3`,
						buffer: undefined, // Set during preload
					},
					loop: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Control_PickASide_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Control_PickASide_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Control_PickASide_Intro_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Control_PickASide_Intro_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'say-it-with-siri',
				title: 'Menu_Control_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Control_SayItWithSiri_Title',
					text: 'Control_SayItWithSiri_Subtitle',
					disclaimer: 'Control_SayItWithSiri_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/7_CONTROLS/VO_Control_05_SayItWithSiri.mp3`,
						buffer: undefined, // Set during preload
					},
					loop: {
						multiLanguage: true,
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Control_SayItWithSiri_Loop_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Control_SayItWithSiri_Loop_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Control_SayItWithSiri_Intro_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Control_SayItWithSiri_Intro_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'manage-your-sound',
				title: 'Menu_Control_Module6',
				nextChapter: '/battery',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				bgImage: '/assets/studioBuds/images/bg_red.jpg',
				gradientStart: 'rgba(139, 21, 21, 1.0)',
				gradientEnd: 'rgba(139, 21, 21, 0)',
				content: {
					title: 'Control_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/controls/1.jpg',
							description: 'Control_Summary1_OnEarControls',
							link: '/on-ear-controls',
						},
						{
							image: '/assets/studioBuds/images/summary/controls/2.jpg',
							description: 'Control_Summary2_PickASide',
							link: '/pick-a-side',
						},
						{
							image: '/assets/studioBuds/images/summary/controls/3.jpg',
							description: 'Control_Summary3_SayItWithSiri',
							link: '/say-it-with-siri',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/7_CONTROLS/VO_Control_06_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},

	{
		id: 'battery',
		number: 7,
		title: 'Global_ChapterTitle_Battery',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_grey.png`,
		},
		modules: [
			{
				id: 'intro',
				title: 'Menu_Battery_Module1',
				component: IntroModule,
				hideWithinChapterNav: true,
				nextOnComplete: true,
				silenceBackgroundAudio: true,
				content: {
					title: 'Battery_Intro_Title',
					subTitle: 'Battery_Intro_VOSubtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/CR_TRNR_Battery_Intro-1.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Battery_Intro-1.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Battery_Intro-1.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'all-day-battery',
				title: 'Menu_Battery_Module2',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_02_ChapterText1.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Battery_ChapterText1',
				},
			},
			{
				id: 'up-to-8-hours',
				title: 'Menu_Battery_Module3',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Battery_FastFuel_Title',
					text: 'Battery_FastFuel_Subtitle',
					disclaimer: 'Battery_FastFuel_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_03_FastFuel.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Battery_FastFuel_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Battery_FastFuel_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'up-to-24-hours',
				title: 'Menu_Battery_Module4',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Battery_20Hours_Title',
					text: 'Battery_20Hours_Subtitle',
					disclaimer: 'Battery_20Hours_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_04_20Hours.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Battery_20Hours_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Battery_20Hours_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'fuel-your-day',
				title: 'Menu_Battery_Module5',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Battery_FuelYourDay_Title',
					text: 'Battery_FuelYourDay_Subtitle',
					disclaimer: 'Battery_FuelYourDay_Disclaimer',
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_05_FuelYourDay.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Battery_FuelYourDay_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Battery_FuelYourDay_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'use-cases',
				title: 'Menu_Battery_Module6',
				bgImage: '/assets/studioBuds/images/battery-use-case-bg.jpg',
				component: UseCaseModule,
				blockAudioAdvance: true,
				content: {
					title: 'Battery_UseCases_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_06_UseCases.mp3`,
						buffer: undefined, // Set during preload
					},
					cases: [
						{
							title: 'Battery_UseCases_Listen',
							description: 'Battery_UseCases_Listen_Subtitle',
							disclaimer: 'Battery_UseCases_Listen_Disclaimer',
							audio: {
								path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_07_UseCases_Listen.mp3`,
							},
						},
						{
							title: 'Battery_UseCases_Meditate',
							description: 'Battery_UseCases_Meditate_Subtitle',
							disclaimer: 'Battery_UseCases_Meditate_Disclaimer',
							audio: {
								path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_08_UseCases_Meditate.mp3`,
							},
						},
						{
							title: 'Battery_UseCases_Game',
							description: 'Battery_UseCases_Game_Subtitle',
							disclaimer: 'Battery_UseCases_Game_Disclaimer',
							audio: {
								path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_08_UseCases_Game.mp3`,
							},
						},
						{
							title: 'Battery_UseCases_Commute',
							description: 'Battery_UseCases_Commute_Subtitle',
							disclaimer: 'Battery_UseCases_Commute_Disclaimer',
							audio: {
								path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_09_UseCases_Commute.mp3`,
							},
						},
					],
				},
			},

			{
				id: 'whats-in-the-box',
				title: 'Menu_Battery_Module7',
				component: TextAndVideoModule,
				componentProps: {
					variant: 'leftAlign',
				},
				content: {
					title: 'Battery_WhatsInTheBox_Title',
					text: 'Battery_WhatsInTheBox_Subtitle',
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_10_WhatsInTheBox.mp3`,
						buffer: undefined, // Set during preload
					},
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Battery_WhatsInTheBox_v01.webm`,
						},
						hevc: {
							path: '/assets/studioBuds/video/CR_TRNR_Battery_WhatsInTheBox_v01.mp4',
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
			{
				id: 'battery-that-lasts-all-day',
				title: 'Menu_Battery_Module8',
				nextChapter: '/outro',
				component: SummaryModule,
				blockAudioAdvance: true,
				hideWithinChapterNav: true,
				bgImage: '/assets/studioBuds/images/bg_grey.png',
				gradientStart: 'rgba(176, 176, 176, 0.9)',
				gradientEnd: 'rgba(176, 176, 176, 0)',
				content: {
					title: 'Batter_Summary_Title',
					items: [
						{
							image: '/assets/studioBuds/images/summary/battery/1.jpg',
							description: 'Battery_Summary1_PowerYourDay',
							link: '/up-to-8-hours',
						},
						{
							image: '/assets/studioBuds/images/summary/battery/2.jpg',
							description: 'Battery_Summary2_UpTo24',
							link: '/up-to-24-hours',
						},
						{
							image: '/assets/studioBuds/images/summary/battery/3.jpg',
							description: 'Battery_Summary3_FastFuel',
							link: '/fuel-your-day',
						},
						{
							image: '/assets/studioBuds/images/summary/battery/4.png',
							description: 'Battery_Summary4_UseCases',
							link: '/use-cases',
						},
						{
							image: '/assets/studioBuds/images/summary/battery/5.jpg',
							description: 'Battery_Summary5_WhatsInTheBox',
							link: '/whats-in-the-box',
						},
					],
					voiceover: {
						path: `/assets/studioBuds/audio/8_BATTERY/VO_Battery_11_Summary.mp3`,
						buffer: undefined, // Set during preload
					},
				},
			},
		],
	},
	{
		id: 'outro',
		title: 'outro',
		backgroundImage: {
			path: `/assets/studioBuds/images/bg_red.jpg`,
		},
		modules: [
			{
				id: 'outro',
				title: 'Outro',
				component: TextModule,
				content: {
					voiceover: {
						path: `/assets/studioBuds/audio/9_OUTRO/VO_Outro_01_Outro.mp3`,
						buffer: undefined, // Set during preload
					},
					text: 'Outro_ChapterText1',
				},
			},
			{
				id: 'summary',
				title: 'summary',
				component: TextAndVideoModule,
				hideWithinChapterNav: true,
				content: {
					title: 'IntroOutro_BeatsStudioBuds',
					// text: 'IntroOutro_TestYourKnowledge',
					// textCTA: {
					// 	label: 'IntroOutro_CTA',
					// 	url: '/',
					// },
					video: {
						webm: {
							path: `/assets/studioBuds/video/CR_TRNR_Outro_Outro_v01.webm`,
						},
						hevc: {
							path: `/assets/studioBuds/video/CR_TRNR_Outro_Outro_v01.mp4`,
						},
						linkElement: undefined, // Set during preload
						videoElement: undefined, // Set during preload
					},
				},
			},
		],
	},
];
export default CHAPTERS;
