import React, { useState, useEffect } from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import SITES from '../../constants/sites';
import { compose } from 'recompose';

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const { firebase } = props;
    // const context = useContext(Context);
    // const { siteID } = context;
    const [authUser, setAuthUser] = useState('notset');
    const [progress, setProgress] = useState(null);
    const [lastVisited, setLastVisited] = useState(null);
    const [firstName, setFirstName] = useState('User');

    // console.log("withAUTH")
    // const [companyFromAuth, setCompanyFromAuth] = useState(null);
    const getProgress = user => {
      console.log('getProgress');
      setAuthUser(user);
      firebase.user(user.uid).on('value', snapshot => {
        console.log('getProgress>>>>snapshot', snapshot.val());
        if (snapshot.val()) {
          let snapProgress = snapshot.val().progress;
          let snapLastVisited = snapshot.val().lastVisited
            ? snapshot.val().lastVisited
            : {};

          if (snapLastVisited.chapter) {
            //THIS IS THE OLD FORMAT OF LASTVISITED.  WE ARE UPDATING TO NEW FORMAT
            let newSnapVisited = {};
            SITES.map(site => (newSnapVisited[site.id] = {}));
            newSnapVisited.studioBuds = snapLastVisited;
            setLastVisited(newSnapVisited);
          } else {
            setLastVisited(snapLastVisited);
          }
          // console.log("lastVisited",lastVisited)
          if (snapProgress && Array.isArray(snapProgress)) {
            // THIS IS AN ARRAY WHICH MEANS OLD DATA... NEED TO REFORMAT
            let newProgress = {};
            SITES.map(site => (newProgress[site.id] = []));
            newProgress['studioBuds'] = snapProgress;

            setProgress(newProgress);
          } else {
            let newProgress = {};
            // console.log("SITES",SITES)
            // console.log("newProgress ",newProgress)
            SITES.map(site => {
              // console.log("site is",site)
              // return (newProgress[site.id] = []);
              return (newProgress[site.id] =
                snapProgress && snapProgress[site.id]
                  ? snapProgress[site.id]
                  : []);
            });
            // console.log("newProgress now ",newProgress)
            setProgress(newProgress);
          }
          // console.log('progress',progress);
          setFirstName(snapshot.val().firstname);
        }
      });
      return () => {
        firebase.users().off();
      };
    };

    useEffect(() => {
      // console.log("useEffect",progress)
      if (firebase.auth.isSignInWithEmailLink(window.location.href)) {
        //SIGN IN WITH EMAIL LINK
        var email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        firebase.auth
          .signInWithEmailLink(email, window.location.href)
          .then(result => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch(error => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
      let listener = firebase.auth.onAuthStateChanged(authUser => {
        //TODO:  WE NEED TO WAIT UNTIL THIS HAPPENS TO RENDER THE ROUTE (ELSE WE GET A FLASH OF SIGN IN BEFORE GOING TO PAGE)
        authUser ? getProgress(authUser) : setAuthUser(null);
      });
      return () => {
        listener();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      // console.log("useEffect lastVisited",lastVisited)
    }, [lastVisited]);
    const contextValue = {
      authUser,
      progress,
      setProgress,
      firstName,
      lastVisited,
      setLastVisited,
      // companyFromAuth
    };
    return (
      <AuthUserContext.Provider value={contextValue}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
