import React, { useState, useContext } from 'react';
import Styles from './CarouselItem.module.scss';
import { useHistory } from 'react-router-dom';
import Context from '../../../../context/Context';
import { motion } from 'framer-motion';
import { createMarkup } from '../../../../utils';

export default function CarouselItem({ item, to, chapter, index }) {
  const [mouseMoved, setMouseMoved] = useState(false);
  let history = useHistory();
  const context = useContext(Context);
  const { language } = context;
  function handleClick() {
    if (!mouseMoved) {
      history.push(process.env.PUBLIC_URL + `/${chapter.id}${item.link}`);
    }
  }
  const variants = {
    visible: { y: 0 },
    hidden: { y: 40 },
  };

  return (
    <motion.div
      className={Styles.item}
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ delay: index * 0.02, stiffness: 0.3 }}
      onMouseMove={() => setMouseMoved(true)}
      onMouseDown={() => setMouseMoved(false)}
      onMouseUp={() => handleClick()}
    >
      <div>
        <img
          draggable={false}
          className={Styles.image}
          src={process.env.PUBLIC_URL + item.image}
          alt='item'
        />
      </div>

      <p
        dangerouslySetInnerHTML={createMarkup(
          language[item.description] ? language[item.description] : 'needs copy'
        )}
        className={Styles.description}
        onClick={() => handleClick()}
      />
    </motion.div>
  );
}
