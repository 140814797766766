const copy = {
	Global_Menu_Button_SelectProduct: "Seleziona un altro prodotto",

	Global_ProductSelector_Headline: "Seleziona un'esperienza Beats TRNR:",
Global_ProductSelector_Product1: "Beats Studio Buds",
Global_ProductSelector_Product2: "Beats Fit Pro",
Global_ProductSelector_CTA_1: "Per cominciare",
Global_ProductSelector_CTA_2: "Riprendi",
Global_ProductSelector_CTA_3: "RICOMINCIA",
Global_WelcomeInstructions_Headline: "Ti diamo il benvenuto su Beats TRNR, dove formiamo persone esperte di Beats.",
Global_WelcomeInstructions_Subhead: "Ecco il programma:",
Global_WelcomeInstructions_Body1: "Per completare ogni esperienza di training sono necessari circa 15 minuti.",
Global_WelcomeInstructions_Body2: "Ogni esperienza di training deve essere ascoltata. Verifica che l'audio del tuo dispositivo sia attivo.",
Global_WelcomeInstructions_Body3: "Ricordati di completare ogni sezione in ciascuna esperienza di training prima di procedere.",
Global_WelcomeInstructions_Body4: "Non preoccuparti nel caso tu debba interrompere. Puoi sempre riprendere da dove avevi interrotto.",
	Global_UI_Next: 'AVANTI',
Global_UI_Begin: 'INIZIA',
Global_UI_Back: 'INDIETRO',
Global_UI_Settings: 'Impostazioni',
Global_UI_Signout: 'Esci',
Global_UI_NextChapter: 'CAPITOLO SUCCESSIVO',
Global_UI_PercentageComplete: 'COMPLETAMENTO: <<number>>%',
Global_UI_Replay: 'RIVEDI',
Global_Field_Location: 'POSIZIONE',
Global_Languages_Select: 'SCEGLI LA LINGUA:',
Global_Field_EnterEmail: 'Inserisci l’indirizzo email',
Global_Field_CreatePassword: 'Crea password',
Global_Field_Password: 'Password',
Global_Field_Password_Forgot: 'PASSWORD DIMENTICATA',
Global_Password_Subtitle: 'LA PASSWORD DEVE CONTENERE ALMENO 8 CARATTERI, CON LETTERE MAIUSCOLE E MINUSCOLE, NUMERI E SIMBOLI.',
Global_Password_Change: 'MODIFICA',
Global_Password_Reset_Headline: 'Reimposta password',
Global_Password_Reset_Subtitle: 'Inserisci l’indirizzo email per ricevere istruzioni su come reimpostare la password.',
Global_Password_Reset_Confirm: 'Inviata. Controlla la tua casella di posta.',
Global_Password_ChangeConfirm: 'Password aggiornata',
Global_Password_Show: 'MOSTRA',
Global_Password_Hide: 'NASCONDI',
Global_Details_Title: 'Altri dettagli',
Global_Details_Field_FirstName: 'Nome',
Global_Details_Field_LastName: 'Cognome',
Global_Details_Field_EmployeeID: 'ID dipendente (opzionale)',
Global_Details_Field_BusinessRole: 'Ruolo aziendale',
Global_Details_Field_BusinessRole1: 'Responsabile',
Global_Details_Field_BusinessRole2: 'Commerciale',
Global_Details_Field_BusinessRole3: 'Trainer',
Global_Details_Field_CompanyName: 'Nome dell’azienda:',
Global_Details_Field_Country: 'Paese',
Global_Field_Password_Error: 'La password inserita non è corretta. Riprova.',
Global_Field_Email_Error: 'Hai inserito un indirizzo email non valido. Riprova.',
Global_Field_Details_Error: 'Compila tutti i campi obbligatori.',
Global_Greeting: 'Ciao <<name>>,',
Global_CompletionMessage: 'hai completato il <<number>>%.',
Global_Chapter: 'CAP. <<number>>',
 Global_Chapter_Subtitle: 'CAP. <<number>> DI <<total>>',
Global_Intro: 'Introduzione',
Global_ChapterTitle_Sound: 'Suono',
Global_ChapterTitle_ANC: 'ANC',
Global_ChapterTitle_Transparency: 'Modalità Trasparenza',
Global_ChapterTitle_Design: 'Design',
Global_ChapterTitle_Compatibility: 'Compatibilità',
Global_ChapterTitle_Controls: 'Controlli',
Global_ChapterTitle_Battery: 'Batteria',
Global_Menu: 'Menu',
Intro_Welcome_Label: 'ESPERIENZA',
Intro_Welcome_ChapterText: 'Ti diamo il benvenuto nell’esperienza dei Beats Studio Buds. Fai clic o tocca per scoprirli.',
Intro_Welcome_ReturningUser: 'Vuoi riprendere da dove hai interrotto? Fai clic o tocca per continuare a scoprire i Beats Studio Buds.',
CR_TRNR_Intro_Sorry: 'Siamo spiacenti. Qualcosa è andato storto. Contatta il tuo supervisore per ricevere un nuovo link di accesso.',
Intro_Intro_Subtitle: 'Nuovi Beats Studio Buds. Auricolari totalmente wireless con cancellazione del rumore e qualità del suono da studio di registrazione. Fai clic o tocca per scoprirli.',
Sound_Intro_Title: 'Suono',
Sound_Intro_VOSubtitle: 'La tecnologia evoluta garantisce un suono potente e bilanciato.',
Sound_ChapterText1: 'Un suono straordinario è possibile solo con una <em>vestibilità personalizzata e una comoda tenuta acustica.</em>',
Sound_FindYourFit_Title: 'Indossali come preferisci. Lasciati avvolgere dalla musica.',
Sound_FindYourFit_Subtitle: 'I copriauricolari in silicone, disponibili in tre misure, assicurano vestibilità personalizzata e tenuta acustica per un audio coinvolgente.',
Sound_FindYourFit_Medium: 'MEDIUM (MISURA PREDEFINITA)',
Sound_FindYourFit_Small: 'SMALL',
Sound_FindYourFit_Large: 'LARGE',
Sound_ChapterText2: 'I Beats Studio Buds sono progettati per offrire un <em>suono potente e bilanciato</em> con un design compatto e una <em>piattaforma acustica interamente personalizzata.</em>',
Sound_DrivenByPremiumSound_Title: 'La qualità del suono prima di tutto',
Sound_DrivenByPremiumSound_Subtitle: 'La piattaforma acustica personalizzata con driver proprietario a doppia membrana assicura un suono limpido e bilanciato.',
Sound_MoreMusicLessDistortion_Title: 'Suono limpido. Distorsione minima.',
Sound_MoreMusicLessDistortion_Subtitle: 'Il materiale flessibile di cui è costituita la membrana del driver produce una distorsione vibrazionale minima, assicurando così un suono nitido e preciso in tutto lo spettro, soprattutto nella gamma di bassi.',
Sound_MoreMusicLessDistortion_Diaphragm: 'MEMBRANA',
Sound_FineTunedAudio_Title: 'Audio ottimizzato',
Sound_FineTunedAudio_Subtitle: 'Un processore digitale con la funzione di correzione audio interviene in tempo reale per fare in modo che i suoni ambientali non compromettano la nitidezza e le emozioni della musica.',
Sound_ChapterText3: 'Il suono è limpido quando parli al telefono, per <em>chiamate di alta qualità e nitidezza ottimale della voce.</em> ',
Sound_SpeakingOfSound_Title: 'Chiamate di alta qualità ',
Sound_SpeakingOfSound_Subtitle: 'Una porta progettata appositamente isola il microfono dal rumore esterno del vento, mentre due microfoni beamforming riconoscono la tua voce garantendo la massima nitidezza.',
Sound_Summary_Title: 'Progettati per ascoltare la musica senza perdere neanche un beat',
Sound_Summary1_ThreeEartip: 'I copriauricolari in tre misure offrono una vestibilità personalizzata, per un suono eccezionale.',
Sound_Summary2_CustomAcoustic: 'La piattaforma acustica personalizzata garantisce un suono potente e di alta qualità.',
Sound_Summary3_FlexibleDriverDiaphragm: 'La membrana flessibile del driver assicura un suono nitido e preciso.',
Sound_Summary4_FinetunedAudio: 'L’audio calibrato con precisione preserva la nitidezza e le emozioni della musica.',
Sound_Summary5_SpeakingOfSound: 'Due microfoni beamforming riconoscono la tua voce, per chiamate di alta qualità.',
ANC_Intro_TITLE: 'Cancellazione attiva del rumore ',
ANC_Intro_VOSubtitle: 'Mantieni la concentrazione e allontana le distrazioni con la cancellazione attiva del rumore.',
ANC_ChapterText1: '<em>Una comoda tenuta acustica</em> contribuisce a bloccare i rumori esterni per un’esperienza di ascolto davvero coinvolgente.',
ANC_Seal_Title: 'Tenuta acustica',
ANC_Seal_Subtitle: 'Grazie ai copriauricolari disponibili in tre misure, ottieni la comodità perfetta per una tenuta acustica che blocca i rumori indesiderati.',
ANC_ChapterText2: 'La funzione ANC con tecnologia adattiva <em>si adatta all’ambiente</em>, mentre la correzione audio <em>preserva la nitidezza e le emozioni della musica.</em>',
ANC_ANCThatAdapts_Title: 'ANC flessibile',
ANC_ANCThatAdapts_Subtitle: 'Un filtro calibrato con precisione si adatta in modo dinamico per eliminare i rumori indesiderati e ottimizzare la cancellazione del rumore in tempo reale.',
ANC_ANCThatAdapts_AdaptiveANC: 'ANC ADATTIVA',
ANC_Playback_Title: 'Correzione audio',
ANC_Playback_Subtitle: 'Quando è attiva la cancellazione del rumore, la correzione audio confronta il file audio con l’originale 48.000 volte al secondo, così da assicurare che ANC non interferisca in alcun modo con la tua musica.',
ANC_Playback_ANCProcess: 'ELABORAZIONE ANC',
ANC_Playback_PlaybackCorrection: 'CORREZIONE AUDIO',
ANC_UseCases_Subtitle: 'Fai clic o tocca ogni caso d’uso per saperne di più. ',
ANC_UseCases_Studying: 'Studio',
ANC_UseCases_Studying_Subtitle: 'Elimina le distrazioni per mantenere la concentrazione sul lavoro',
ANC_UseCases_WorkingOut: 'Allenamento',
ANC_UseCases_WorkingOut_Subtitle: 'Immergiti nella sessione di allenamento senza distrazioni',
ANC_UseCases_Airplane: 'In aereo',
ANC_UseCases_Airplane_Subtitle: 'Attenua le voci dei passeggeri o il rumore del motore per un volo più piacevole',
ANC_UseCases_Commute: 'Tragitto casa-lavoro',
ANC_UseCases_Commute_Subtitle: 'Spostati riducendo i rumori della città',
ANC_Summary_Title: 'Mantieni la concentrazione con ANC.',
ANC_Summary1_ImmerseSound: 'Un suono avvolgente è possibile solo con una comoda tenuta acustica.',
ANC_Summary2_AdaptiveTechnology: 'La tecnologia adattiva si adegua continuamente all’ambiente circostante.',
ANC_Summary3_PlaybackCorrection: 'La correzione audio preserva la nitidezza del suono.',
ANC_Summary4_UseCases: 'Usa la modalità ANC per concentrarti mentre studi, ti alleni, viaggi in aereo o ti sposti tra casa e lavoro.',
Transparency_Intro_Title: 'MODALITÀ TRASPARENZA',
Transparency_Intro_VOSubtitle: 'Fai entrare il mondo che ti circonda con la modalità Trasparenza.',
Transparency_ChapterText1: 'Passa alla modalità Trasparenza quando desideri <em>sentire ciò che accade intorno a te.</em>',
Transparency_TransparencyMode_Title: 'Modalità Trasparenza ',
Transparency_TransparencyMode_Subtitle: 'La modalità Trasparenza mescola i suoni dell’ambiente circostante con la musica che ascolti, per offrirti un’esperienza “aperta” e naturale.',
Transparency_TransparencyMode_PressHold: 'PRESSIONE PROLUNGATA',
Transparency_ChapterText2: 'I <em>microfoni esterni</em> percepiscono i rumori ambientali.',
Transparency_FeedForwardMic_Title: 'Microfoni per il feed-forward',
Transparency_FeedForwardMic_Subtitle: 'Il microfono per il feed-forward presente su ogni auricolare rileva i rumori esterni dell’ambiente.',
Transparency_FeedForwardMic_FeedForwardMic: 'MICROFONO PER IL FEED-FORWARD',
Transparency_UseCases_Subtitle: 'Fai clic o tocca ogni caso d’uso per saperne di più. ',
Transparency_UseCases_Office: 'In ufficio',
Transparency_UseCases_Office_Subtitle: 'Collabora e senti cosa dicono colleghi e colleghe mentre ascolti la musica',
Transparency_UseCases_Travel: 'In viaggio',
Transparency_UseCases_Travel_Subtitle: 'Ascolta gli annunci importanti al gate in aeroporto o al binario del treno.',
Transparency_UseCases_Street: 'In strada',
Transparency_UseCases_Street_Subtitle: 'Presta attenzione al traffico e agli incroci affollati.',
Transparency_Summary_Title: 'Fai entrare il mondo che ti circonda con la modalità Trasparenza.',
Transparency_Summary1_MixTheSounds: 'Mescola i suoni dell’ambiente circostante con la musica che ascolti.',
Transparency_Summary2_FeedForward: 'I microfoni per il feed-forward rilevano i rumori esterni dell’ambiente.',
Transparency_Summary3_HearYourSurroundings: 'Percepisci ciò che accade intorno a te in ufficio, quando sei in viaggio o per strada.',
Design_Intro_Title: 'Design',
Design_Intro_VOSubtitle: 'I Beats Studio Buds sono pensati per una vera libertà senza fili.',
Design_ChapterText1: 'Il design compatto e senza fili <em>si adatta perfettamente al tuo stile di vita.</em>',
Design_WirelessFreedom_Title: 'Libertà senza fili ',
Design_WirelessFreedom_Subtitle: 'Auricolari totalmente senza fili per il massimo della libertà. ',
Design_PocketSizedPortability_Titles: 'Formato tascabile',
Design_PocketSizedPortability_Subtitles: 'La custodia di ricarica essenziale e leggera entra in tasca senza problemi: portarla con te sarà comodissimo.',
Design_WaterAndSweatResistant_Title: 'Resistenti all’acqua e al sudore*  ',
Design_WaterAndSweatResistant_Subtitle: 'Gli auricolari sono resistenti all’acqua e al sudore con un rating di grado IPX4 per permetterti di affrontare qualsiasi situazione.',
Design_WaterAndSweatResistant_Disclaimer: '* I Beats Studio Buds sono resistenti all’acqua e al sudore per attività non acquatiche.',
Design_ChapterText2: 'I Beats Studio Buds sono stati testati da un pubblico eterogeneo per garantire una <em>vestibilità comoda e versatile.</em>',
Design_Comfort_Title: 'Progettati per il massimo comfort',
Design_Comfort_Subtitle: 'I morbidi auricolari in silicone sono progettati secondo l’anatomia dell’orecchio per assicurare il massimo in termini di comfort e stabilità, oltre alla certezza che rimarranno sempre al loro posto.',
Design_Comfort_Tragus: 'TRAGO',
Design_Comfort_Antitragus: 'ANTITRAGO',
Design_SizeMatters_Title: 'Design compatto',
Design_SizeMatters_Subtitle: 'L’elegante design in-ear compatto è notevolmente più piccolo e leggero della maggior parte degli auricolari wireless attuali.',
Design_SizeMatters_BeatsStudio: 'BEATS FLEX PRO',
Design_SizeMatters_BeatsStudio_10_1G: '10,1 G',
Design_SizeMatters_Bose: 'AURICOLARI BOSE QC',
Design_SizeMatters_Bose_18_7G: '18,7 G',
Design_SizeMatters_Sony: 'Sony WF-1000XM3',
Design_SizeMatters_Sony_16_5G: '16,5 G',
Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
Design_SizeMatters_Samsung_12_5G: '12,5 G',
Design_ChapterText3: 'Gli auricolari sono sempre <em>protetti e al sicuro</em> nella comoda custodia di ricarica, pensata per stare con te tutto il giorno e ovunque.',
Design_PerfectMatch_Title: 'Posizionamento magnetico',
Design_PerfectMatch_Subtitle: 'I magneti all’interno della custodia di ricarica sono orientati in modo che gli auricolari vengano riposti sempre correttamente.',
Design_Contact_Title: 'Contatto preciso',
Design_Contact_Subtitle: 'I connettori pogo pin nella custodia utilizzano una resiliente molla a balestra per creare un contatto preciso tra la custodia e gli auricolari, così da essere collocati correttamente per la ricarica.',
Design_Contact_PogoPin: 'POGO PIN',
Design_FindYourMatch_Title: 'La coppia perfetta ',
Design_FindYourMatch_Subtitle: 'Custodia di ricarica dello stesso colore',
Design_FindYourMatch_White: 'BIANCO',
Design_FindYourMatch_Black: 'NERO',
Design_FindYourMatch_BeatsRed: 'ROSSO BEATS',
Design_SustainablePackaging_Title: 'Imballaggio sostenibile',
Design_SustainablePackaging_Subtitle: 'La confezione dei Beats Studio Buds è composta al 92% di materiale vegetale, ottenuto da una combinazione di fibre riciclate e foreste sostenibili.',
Design_Summary_Title: 'Progettati per una vera libertà senza fili',
Design_Summary1_WirelessFreedom: 'Auricolari totalmente senza fili per il massimo della libertà. ',
Design_Summary2_PocketSizedPortability: 'Custodia di ricarica leggera da portare comodamente in tasca.',
Design_Summary3_WaterAndSweatResistant: 'Auricolari resistenti all’acqua e al sudore con rating di grado IPX4.',
Design_Summary4_Comfort: 'Auricolari compatti ed eleganti che garantiscono comfort e stabilità.',
Design_Summary5_SizeMatters: 'Design in-ear notevolmente più piccolo e leggero rispetto alla maggior parte degli auricolari wireless.',
Design_Summary6_PerfectMatch: 'I magneti all’interno della custodia di ricarica assicurano che gli auricolari vengano riposti sempre correttamente.',
Design_Summary7_Contact: 'I connettori pogo pin nella custodia consentono la corretta collocazione degli auricolari per la ricarica.',
Design_Summary8_FindYourMatch: 'La custodia di ricarica abbinata è disponibile nei colori nero, bianco e rosso Beats.',
Compatibility_Intro_Title: 'Compatibilità',
Compatibility_Intro_VOSubtitle: 'I Beats Studio Buds sono compatibili con Apple e Android.',
Compatibility_ChapterText1: 'Usali facilmente con <em>dispositivi Apple e Android.</em>',
Compatibility_OneTouch_Title: 'Abbinamento con un tocco ',
Compatibility_OneTouch_Subtitle: 'Collega il tuo dispositivo Apple<sup>1</sup> o Android<sup>2</sup> via Bluetooth in tutta facilità grazie all’abbinamento facile con un tocco. ',
Compatibility_OneTouch_Disclaimer: '1 L’abbinamento con un tocco richiede iOS 14.6 o versioni successive. <br/>2 L’abbinamento rapido richiede l’accesso alla posizione. L’accesso completo alle funzionalità richiede Android 6.0 o versione successiva con Google Play Services attivato e un account Google. ',
Compatibility_VoiceAssistant_Title: 'Assistente vocale ',
Compatibility_VoiceAssistant_Subtitle: 'I Beats Studio Buds supportano il tuo assistente vocale preferito, che puoi attivare personalizzando la pressione prolungata.',
Compatibility_LocateDevice_Title: 'Localizza il tuo dispositivo ',
Compatibility_LocateDevice_Subtitle: 'Usa la funzionalità “Dov’è” per iOS o “Trova il mio dispositivo” per Android per individuare facilmente gli auricolari. ',
Compatibility_USBCCharging_Title: 'Ricarica USB-C ',
Compatibility_USBCCharging_Subtitle: 'Il connettore USB-C è compatibile con Apple e Android, così puoi ricaricare gli auricolari ovunque. ',
Compatibility_ManageDevice_Title: 'Gestisci il dispositivo ',
Compatibility_ManageDevice_Subtitle: 'Gestisci funzionalità come la personalizzazione della pressione prolungata, le modalità di ascolto, la modifica del nome del prodotto e gli aggiornamenti del firmware tramite il tuo dispositivo Apple o con l’app Beats per Android.',
Compatibility_Summary_Title: 'Compatibili con dispositivi Apple e Android.',
Compatibility_Summary1_OneTouch: 'Abbinamento con un tocco per iOS e Android.',
Compatibility_Summary2_VoiceAssistant: 'Attiva il tuo assistente vocale preferito per gestire le chiamate, la musica e non solo.',
Compatibility_Summary3_LocateDevice: 'Localizza facilmente gli auricolari tramite il dispositivo.',
Compatibility_Summary4_USBCCharging: 'Ricarica universale grazie al connettore di ricarica USB-C.',
Compatibility_Summary5_ManageDevice: 'Gestisci il dispositivo tramite iOS o con l’app Beats per Android.',
Control_Intro_Title: 'Controlli',
Control_Intro_VOSubtitle: 'Controlla il suono e dai una direzione alla tua giornata con i Beats Studio Buds.',
Control_ChapterText1: 'Gestisci il suono grazie al <em>semplice accesso ai controlli.</em>',
Control_OnEarControls_Title: 'Controlli integrati ',
Control_OnEarControls_Subtitle: 'I controlli integrati ti consentono di gestire le chiamate e la musica, cambiare modalità di ascolto e attivare l’assistente vocale.<sup>*</sup>',
Control_OnEarControls_Disclaimer: '* Per personalizzare la pressione prolungata è necessario un iPhone con iOS 14.6 o successivo, o un dispositivo Android con installata l’ultima versione dell’app Beats.',
Control_OnEarControls_Instruction: 'Fai clic o tocca ciascun controllo per continuare.',
Control_OnEarControls_PlayPause: 'Premi per far partire la musica, mettere in pausa, rispondere a una chiamata o terminarla ',
Control_OnEarControls_Skip: 'Premi 2 volte per saltare un brano ',
Control_OnEarControls_Previous: 'Premi 3 volte per tornare al brano precedente ',
Control_OnEarControls_ScanForward: 'Premi 2 volte e tieni premuto per mandare avanti veloce ',
Control_OnEarControls_ScanBackward: 'Premi 3 volte e tieni premuto per mandare indietro veloce ',
Control_OnEarControls_ToggleModes: 'Tieni premuto per passare da una modalità di ascolto all’altra o per disattivarle, oppure personalizza la pressione prolungata per attivare l’assistente vocale<sup>*</sup>',
Control_PickASide_Title: 'Scegli un lato',
Control_PickASide_Subtitle: 'Usa iOS o l’app Beats per Android per personalizzare la pressione prolungata su ciascun auricolare, per attivare ANC o l’assistente vocale.<sup>*</sup>',
Control_PickASide_Disclaimer: '* Per personalizzare la pressione prolungata è necessario un iPhone con iOS 14.6 o successivo, o un dispositivo Android con installata l’ultima versione dell’app Beats.',
Control_SayItWithSiri_Title: 'Dillo con Siri ',
Control_SayItWithSiri_Subtitle: 'Attivando Siri solo con la voce hai sempre il controllo su musica, chiamate, volume e altro ancora.<sup>*</sup>',
Control_SayItWithSiri_Disclaimer: '* Siri potrebbe non essere disponibile in tutte le lingue o in tutti i Paesi e le funzionalità potrebbero variare a seconda dell’area geografica. Richiede un accesso a internet. Potrebbero essere applicati costi per il traffico dati. ',
Control_SayItWithSiri_SiriCommand1: '“Ehi Siri, metti la playlist per lo studio.”',
Control_SayItWithSiri_SiriCommand2: '“Ehi Siri, mostrami le aziende gestite da donne nei dintorni.”',
Control_SayItWithSiri_SiriCommand3: '“Ehi Siri, imposta un timer di 10 minuti.”',
Control_Summary_Title: 'Gestisci il suono con i controlli integrati.',
Control_Summary1_OnEarControls: 'I controlli integrati ti consentono di gestire le chiamate e la musica.',
Control_Summary2_PickASide: 'Personalizza la pressione prolungata per l’auricolare destro e sinistro.',
Control_Summary3_SayItWithSiri: 'Attiva Siri solo con la voce',
Battery_Intro_Title: 'Batteria',
Battery_Intro_VOSubtitle: 'Dai più carica alle tue giornate con un’autonomia di una giornata intera.',
Battery_ChapterText1: '<em>Autonomia prolungata</em> per farti compagnia tutto il giorno.',
Battery_FastFuel_Title: 'Fino a 8 ore',
Battery_FastFuel_Subtitle: 'Ogni auricolare offre fino a 8 ore di ascolto grazie a un chip altamente efficiente.<sup>*</sup>',
Battery_FastFuel_Disclaimer: '* La durata della batteria dipende dalle impostazioni del dispositivo, dalle condizioni ambientali, dall’utilizzo e da molti altri fattori',
Battery_20Hours_Title: 'Fino a 24 ore',
Battery_20Hours_Subtitle: 'La custodia di ricarica tascabile offre due ricariche aggiuntive, per un totale di 24 ore di ascolto combinato.<sup>*</sup>',
Battery_20Hours_Disclaimer: '* La durata della batteria dipende dalle impostazioni del dispositivo, dalle condizioni ambientali, dall’utilizzo e da molti altri fattori',
Battery_FuelYourDay_Title: 'Dai carica alle tue giornate',
Battery_FuelYourDay_Subtitle: 'Quando la batteria è scarica, grazie a Fast Fuel, con 5 minuti di ricarica hai ancora 1 ora di ascolto.<sup>*</sup>',
Battery_FuelYourDay_Disclaimer: '* La durata della batteria dipende dalle impostazioni del dispositivo, dalle condizioni ambientali, dall’utilizzo e da molti altri fattori',
Battery_UseCases_Subtitle: 'Fai clic o tocca ogni caso d’uso per saperne di più. ',
Battery_UseCases_Listen: 'Ascolto',
Battery_UseCases_Listen_Subtitle: 'Ascolta 12 settimane delle tue trasmissioni radiofoniche preferite.<sup>*</sup>',
Battery_UseCases_Listen_Disclaimer: '*Dati basati su sessioni di ascolto di 2 ore',
Battery_UseCases_Meditate: 'Meditazione',
Battery_UseCases_Meditate_Subtitle: 'Rilassati con 32 meditazioni con il sottofondo della pioggia.<sup>*</sup>',
Battery_UseCases_Meditate_Disclaimer: '*Dati basati su sessioni di 45 minuti',
Battery_UseCases_Game: 'Gioco',
Battery_UseCases_Game_Subtitle: 'Immergiti in 24 sessioni del tuo gioco preferito.<sup>*</sup>',
Battery_UseCases_Game_Disclaimer: '*Dati basati su sessioni di gioco di 1 ora che non richiedono l’uso di microfoni',
Battery_UseCases_Commute: 'Tragitto casa-lavoro',
Battery_UseCases_Commute_Subtitle: 'Compi 24 giorni di tragitto casa-lavoro.<sup>*</sup>',
Battery_UseCases_Commute_Disclaimer: '*Dati basati su 1 ora di tragitto. La durata della batteria dipende dalle impostazioni del dispositivo, dalle condizioni ambientali: dall’utilizzo e da molti altri fattori.',
Battery_WhatsInTheBox_Title: 'Contenuto della confezione',
Battery_WhatsInTheBox_Subtitle: 'I Beats Studio Buds hanno in dotazione una custodia di ricarica tascabile, un connettore di ricarica USB-C universale e tre misure diverse di copriauricolari.',
Batter_Summary_Title: 'Autonomia di una giornata intera.',
Battery_Summary1_PowerYourDay: 'Gli auricolari offrono fino a 8 ore di ascolto.',
Battery_Summary2_UpTo24: 'Fino a 24 ore di ascolto combinato.',
Battery_Summary3_FastFuel: 'La ricarica Fast Fuel garantisce 1 ora di ascolto.',
Battery_Summary4_UseCases: 'Ascolta musica, medita e gioca, grazie a un’autonomia di una giornata intera.',
Battery_Summary5_WhatsInTheBox: 'Sono inclusi custodia di ricarica tascabile, connettore di ricarica USB-C e tre misure di copriauricolari.',
Menu_Sound_Module1: 'INTRODUZIONE SUONO',
Menu_Sound_Module2: 'SUONO STRAORDINARIO GRAZIE A UN’OTTIMA VESTIBILITÀ',
Menu_Sound_Module3: 'TRE COPRIAURICOLARI',
Menu_Sound_Module4: 'SUONO POTENTE E BILANCIATO',
Menu_Sound_Module5: 'PIATTAFORMA ACUSTICA PERSONALIZZATA',
Menu_Sound_Module6: 'SUONO LIMPIDO. DISTORSIONE MINIMA. ',
Menu_Sound_Module7: 'AUDIO CALIBRATO CON PRECISIONE',
Menu_Sound_Module8: 'CHIAMATE ',
Menu_Sound_Module9: 'CHIAMATE DI ALTA QUALITÀ',
Menu_Sound_Module10: 'RIEPILOGO SUONO',
Menu_ANC_Module1: 'INTRODUZIONE ANC',
Menu_ANC_Module2: 'TENUTA ACUSTICA',
Menu_ANC_Module3: 'TUTTO INIZIA DALLA TENUTA',
Menu_ANC_Module4: 'TECNOLOGIA',
Menu_ANC_Module5: 'ANC FLESSIBILE',
Menu_ANC_Module6: 'CORREZIONE AUDIO',
Menu_ANC_Module7: 'CASI D’USO ANC',
Menu_ANC_Module8: 'RIEPILOGO ANC',
Menu_Transparency_Module1: 'INTRODUZIONE MODALITÀ TRASPARENZA',
Menu_Transparency_Module2: 'SENTI CIÒ CHE ACCADE INTORNO A TE',
Menu_Transparency_Module3: 'MODALITÀ TRASPARENZA',
Menu_Transparency_Module4: 'MICROFONI ESTERNI',
Menu_Transparency_Module5: 'MICROFONI PER IL FEED-FORWARD',
Menu_Transparency_Module6: 'CASI D’USO MODALITÀ TRASPARENZA',
Menu_Transparency_Module7: 'RIEPILOGO MODALITÀ TRASPARENZA',
Menu_Design_Module1: 'INTRODUZIONE DESIGN',
Menu_Design_Module2: 'DESIGN COMPATTO SENZA FILI',
Menu_Design_Module3: 'LIBERTÀ SENZA FILI',
Menu_Design_Module4: 'FORMATO TASCABILE',
Menu_Design_Module5: 'RESISTENTI ALL’ACQUA E AL SUDORE',
Menu_Design_Module6: 'VESTIBILITÀ COMODA E VERSATILE',
Menu_Design_Module7: 'PROGETTATI PER IL MASSIMO COMFORT',
Menu_Design_Module8: 'DESIGN COMPATTO',
Menu_Design_Module9: 'COMODA CUSTODIA DI RICARICA',
Menu_Design_Module10: 'POSIZIONAMENTO MAGNETICO',
Menu_Design_Module11: 'CONTATTO PRECISO',
Menu_Design_Module12: 'COLORI',
Menu_Design_Module13: 'RIEPILOGO DESIGN',
Menu_Compatibility_Module1: 'INTRODUZIONE COMPATIBILITÀ',
Menu_Compatibility_Module2: 'COMPATIBILI CON DISPOSITIVI APPLE E ANDROID',
Menu_Compatibility_Module3: 'ABBINAMENTO CON UN TOCCO',
Menu_Compatibility_Module4: 'ASSISTENTE VOCALE',
Menu_Compatibility_Module5: 'Localizza il tuo dispositivo ',
Menu_Compatibility_Module6: 'RICARICA USB-C ',
Menu_Compatibility_Module7: 'GESTISCI IL DISPOSITIVO',
Menu_Compatibility_Module8: 'RIEPILOGO COMPATIBILITÀ',
Menu_Control_Module1: 'INTRODUZIONE CONTROLLI',
Menu_Control_Module2: 'FACILE ACCESSO',
Menu_Control_Module3: 'CONTROLLI INTEGRATI',
Menu_Control_Module4: 'SCEGLI UN LATO',
Menu_Control_Module5: 'DILLO CON SIRI',
Menu_Control_Module6: 'RIEPILOGO CONTROLLI',
Menu_Battery_Module1: 'INTRODUZIONE BATTERIA',
Menu_Battery_Module2: 'AUTONOMIA PROLUNGATA',
Menu_Battery_Module3: '8 ORE DI ASCOLTO',
Menu_Battery_Module4: '24 ORE DI ASCOLTO COMBINATO',
Menu_Battery_Module5: 'FAST FUEL',
Menu_Battery_Module6: 'CASI D’USO BATTERIA',
Menu_Battery_Module7: 'CONTENUTO DELLA CONFEZIONE',
Menu_Battery_Module8: 'RIEPILOGO BATTERIA',
Outro_ChapterText1: 'Con la cancellazione attiva del rumore, la modalità Trasparenza, un suono straordinario e un <em>design senza fili tascabile, i Beats Studio Buds si adattano perfettamente al tuo stile di vita.</em>',
IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
Global_Country_ALBANIA: 'ALBANIA',
Global_Country_ALGERIA: 'ALGERIA',
Global_Country_ANDORRA: 'ANDORRA',
Global_Country_ANGOLA: 'ANGOLA',
Global_Country_ARGENTINA: 'ARGENTINA',
Global_Country_ARMENIA: 'ARMENIA',
Global_Country_ARUBA: 'ARUBA',
Global_Country_AUSTRALIA: 'AUSTRALIA',
Global_Country_AUSTRIA: 'AUSTRIA',
Global_Country_AZERBAIJAN: 'AZERBAIGIAN',
Global_Country_BAHAMAS: 'BAHAMAS',
Global_Country_BAHRAIN: 'BAHRAIN',
Global_Country_BANGLADESH: 'BANGLADESH',
Global_Country_BARBADOS: 'BARBADOS',
Global_Country_BELARUS: 'BIELORUSSIA',
Global_Country_BELGIUM: 'BELGIO',
Global_Country_BOLIVIA: 'BOLIVIA',
Global_Country_BOSNIAHERZRGOVINA: 'BOSNIA ED ERZEGOVINA',
Global_Country_BOTSWANA: 'BOTSWANA',
Global_Country_BRAZIL: 'BRASILE',
Global_Country_BRUNEI: 'BRUNEI DARUSSALAM',
Global_Country_BULGARIA: 'BULGARIA',
Global_Country_CAMBODIA: 'CAMBOGIA',
Global_Country_CAMEROON: 'CAMERUN',
Global_Country_CANADA: 'CANADA',
Global_Country_CAYMANISLANDS: 'ISOLE CAYMAN',
Global_Country_CHILE: 'CILE',
Global_Country_CHINA : 'CINA CONTINENTALE',
Global_Country_COLOMBIA: 'COLOMBIA',
Global_Country_COOKISLANDS: 'ISOLE COOK',
Global_Country_COSTARICA: 'COSTA RICA',
Global_Country_CROATIA: 'CROAZIA',
Global_Country_CYPRUS: 'CIPRO',
Global_Country_CZECHREPUBLIC: 'REPUBBLICA CECA',
Global_Country_DENMARK: 'DANIMARCA',
Global_Country_DOMINICANREPUBLIC: 'REPUBBLICA DOMINICANA',
Global_Country_ECUADOR: 'ECUADOR',
Global_Country_EGYPT: 'EGITTO',
Global_Country_ELSALVADOR: 'EL SALVADOR',
Global_Country_ESTONIA: 'ESTONIA',
Global_Country_FAROEISLANDS: 'ISOLE FAROE',
Global_Country_FIJI: 'FIGI',
Global_Country_FINLAND: 'FINLANDIA',
Global_Country_FRANCE: 'FRANCIA',
Global_Country_FRENCHGUIANA: 'GUYANA FRANCESE',
Global_Country_FRENCHPOLYNESIA: 'POLINESIA FRANCESE',
Global_Country_GEORGIA: 'GEORGIA',
Global_Country_GERMANY: 'GERMANIA',
Global_Country_GHANA: 'GHANA',
Global_Country_GIBRALTAR: 'GIBILTERRA',
Global_Country_GREECE: 'GRECIA',
Global_Country_GUADELOUPE: 'GUADALUPA',
Global_Country_GUAM: 'GUAM',
Global_Country_GUATEMALA: 'GUATEMALA',
Global_Country_GUERNSEY: 'GUERNSEY',
Global_Country_GUINEA: 'GUINEA',
Global_Country_GUYANA: 'GUYANA',
Global_Country_HONDURAS: 'HONDURAS',
Global_Country_HONGKONG: 'HONG KONG',
Global_Country_HUNGARY: 'UNGHERIA',
Global_Country_ICELAND: 'ISLANDA',
Global_Country_INDIA: 'INDIA',
Global_Country_INDONESIA: 'INDONESIA',
Global_Country_IRAQ: 'IRAQ',
Global_Country_IRELAND: 'IRLANDA',
Global_Country_ISLEOFMAN: 'ISOLA DI MAN',
Global_Country_ISRAEL: 'ISRAELE',
Global_Country_ITALY: 'ITALIA',
Global_Country_IVORYCOAST: 'COSTA D’AVORIO',
Global_Country_JAPAN: 'GIAPPONE',
Global_Country_JERSEY: 'ISOLA DI JERSEY',
Global_Country_JORDAN: 'GIORDANIA',
Global_Country_KAZAKHSTAN: 'KAZAKISTAN',
Global_Country_KENYA: 'KENYA',
Global_Country_KOREA: 'REPUBBLICA DI COREA',
Global_Country_KUWAIT: 'KUWAIT',
Global_Country_KYRGYZSTAN: 'KIRGHIZISTAN',
Global_Country_LAOS: 'LAOS',
Global_Country_LATVIA: 'LETTONIA',
Global_Country_LEBANON: 'LIBANO',
Global_Country_LESOTHO: 'LESOTHO',
Global_Country_LITHUANIA: 'LITUANIA',
Global_Country_LUXEMBOURG: 'LUSSEMBURGO',
Global_Country_MACAU: 'MACAO',
Global_Country_MACEDONIA: 'MACEDONIA',
Global_Country_MADAGASCAR: 'MADAGASCAR',
Global_Country_MALAWI: 'MALAWI',
Global_Country_MALAYSIA: 'MALESIA',
Global_Country_MALDIVES: 'MALDIVE',
Global_Country_MALTA: 'MALTA',
Global_Country_MARTINIQUE: 'MARTINICA',
Global_Country_MAURITIUS: 'MAURITIUS',
Global_Country_MAYOTTE: 'MAYOTTE',
Global_Country_MEXICO: 'MESSICO',
Global_Country_MOLDOVA: 'MOLDOVA',
Global_Country_MONACO: 'MONACO',
Global_Country_MONTENEGRO: 'MONTENEGRO',
Global_Country_MOROCCO: 'MAROCCO',
Global_Country_MOZAMBIQUE: 'MOZAMBICO',
Global_Country_MYANMAR: 'MYANMAR',
Global_Country_NAMIBIA: 'NAMIBIA',
Global_Country_NEPAL: 'NEPAL',
Global_Country_NETHERLANDS: 'PAESI BASSI',
Global_Country_NEWCALEDONIA: 'NUOVA CALEDONIA',
Global_Country_NEWZEALAND: 'NUOVA ZELANDA',
Global_Country_NICARAGUA: 'NICARAGUA',
Global_Country_NIGERIA: 'NIGERIA',
Global_Country_NORWAY: 'NORVEGIA',
Global_Country_OMAN: 'OMAN',
Global_Country_PAKISTAN: 'PAKISTAN',
Global_Country_PALESTINIANTERRITORY: 'TERRITORIO PALESTINESE',
Global_Country_PANAMA: 'PANAMA',
Global_Country_PARAGUAY: 'PARAGUAY',
Global_Country_PERU: 'PERÙ',
Global_Country_PHILIPPINES: 'FILIPPINE',
Global_Country_POLAND: 'POLONIA',
Global_Country_PORTUGAL: 'PORTOGALLO',
Global_Country_PUERTORICO: 'PORTO RICO',
Global_Country_QATAR: 'QATAR',
Global_Country_REUNION: 'ISOLA DI RÉUNION',
Global_Country_ROMANIA: 'ROMANIA',
Global_Country_RUSSIA: 'RUSSIA',
Global_Country_SAINTMARTIN: 'SAINT MARTIN',
Global_Country_SANMARINO: 'SAN MARINO',
Global_Country_SAUDIARABIA: 'ARABIA SAUDITA',
Global_Country_SENEGAL: 'SENEGAL',
Global_Country_SERBIA: 'SERBIA',
Global_Country_SINGAPORE: 'SINGAPORE',
Global_Country_SINTMAARTEN: 'SINT MAARTEN',
Global_Country_SLOVAKIA: 'SLOVACCHIA',
Global_Country_SLOVENIA: 'SLOVENIA',
Global_Country_SOUTHAFRICA: 'SUDAFRICA',
Global_Country_SPAIN: 'SPAGNA',
Global_Country_SRILANKA: 'SRI LANKA',
Global_Country_SWEDEN: 'SVEZIA',
Global_Country_SWITZERLAND: 'SVIZZERA',
Global_Country_TAIWAN: 'TAIWAN',
Global_Country_TAJIKISTAN: 'TAGIKISTAN',
Global_Country_TANZANIA: 'TANZANIA',
Global_Country_THAILAND: 'THAILANDIA',
Global_Country_TRINIDADTOBAGO: 'TRINIDAD E TOBAGO',
Global_Country_TUNISIA: 'TUNISIA',
Global_Country_TURKEY: 'TURCHIA',
Global_Country_TURKMENISTAN: 'TURKMENISTAN',
Global_Country_UGANDA: 'UGANDA',
Global_Country_UKRAINE: 'UCRAINA',
Global_Country_UAE: 'EMIRATI ARABI UNITI',
Global_Country_UK: 'REGNO UNITO',
Global_Country_US: 'STATI UNITI',
Global_Country_URUGUAY: 'URUGUAY',
Global_Country_UZBEKISTAN: 'UZBEKISTAN',
Global_Country_VENEZUELA: 'VENEZUELA',
Global_Country_VIETNAM: 'VIETNAM',
Global_Country_ZAMBIA: 'ZAMBIA',
Global_Country_ZIMBABWE: 'ZIMBABWE',
Global_Menu_Confirm: 'Conferma',
Global_Menu_Help: 'Serve aiuto? <u>Contattaci</u>',
Global_Menu_Restart: 'RIAVVIA L’ESPERIENZA',
Global_Menu_Button_Restart: "Ricomincia l'esperienza",
Global_Menu_Button_Contact: "Serve aiuto? Contattaci",
IntroOutro_TestYourKnowledge: 'Verifica le tue conoscenze sui Beats Studio Buds.',
IntroOutro_CTA: 'Fai il quiz',
Global_Menu_Language: 'LINGUA',
Global_Password_Rules_Header: 'La password deve contenere',
Global_Password_Rule_Length: 'Almeno 8 caratteri',
Global_Password_Rule_Uppercase: '1 lettera maiuscola',
Global_Password_Rule_Lowercase: '1 lettera minuscola',
Global_Password_Rule_Number: '1 numero',
Global_Password_Rule_Special: 'Caratteri speciali (!@#$%^&*)',
Global_Field_Password_CreateError: 'La password non soddisfa i requisiti minimi.',
Global_ForgotEmail_NoUser:"Non esiste alcun utente con queste credenziali. Potrebbe essere stato cancellato."


};

export default copy;
