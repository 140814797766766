import React, {useContext, useEffect, useState}  from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import BaseModule from "../BaseModule";
import Carousel from "./components/Carousel";
import Styles from "./SummaryModule.module.scss";
import NextChapterButton from './components/NextChapterButton'
import Context from '../../../context/Context';
import CarouselItem from './components/CarouselItem';
import {AnimatePresence, motion} from 'framer-motion'

export default function SummaryModule(props) {
  const { module, chapter, mobile } = props;
  const {content, bgImage, gradientStart, gradientEnd} = module
  const {title} = content
  const context = useContext(Context)
  const { setShowCursor, language, theme, overrideTheme } = context
  const [currentSlide, setCurrentSlide] = useState(0)

  setShowCursor(false);
  useEffect(() => {
    return () => {
      setShowCursor(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderSummaryList() {
    return content.items.map((item, i) => {
      return (
        <CarouselItem 
          key={i} 
          item={item} 
          chapter={chapter} 
          mobile={true}
          index={i}
        />
      ) 
    })
  }

  function onChangeSlider(index) {
    setCurrentSlide(index)
  }

  return (
    <BaseModule
      {...props}
      className={clsx(props.className, Styles.root)}
    >
     
      <img 
        alt="bg"
        className="bg-image" 
        src={process.env.PUBLIC_URL + bgImage}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: '-1',
          objectFit: 'cover'
      }} 
      />
      <AnimatePresence>
        {!mobile && 
          <>
            <motion.h1 
              className={`${Styles.title} ${overrideTheme ? Styles.light : theme ? Styles[theme] : Styles.light}`}
              initial={{opacity: 0, y: 50}}
              animate={{opacity: 1, y: 0}}
              transition={{delay: 0.3, stiffness: 1, duration: 0.5}}
              key="title"
            >
              {language[title] ? language[title] : 'Replace this Title'}
            </motion.h1>
            <motion.div 
              initial={{width: 0}}  
              animate={{width: 150}}
              transition={{delay: 0.5, stiffness: 100}}
              className={`${Styles.underline} ${overrideTheme ? Styles.light : theme ? Styles[theme] : Styles.light}`}
              key="underline"
            />
            <div 
              className={Styles.gradientContainer}
            >
              <div 
                className={`${Styles.gradient} ${Styles.left} ${currentSlide > 0 ? '' : 'hide'}`} 
                key="gradient_left"
                style={{
                  background: `linear-gradient(90deg, ${gradientStart}, ${gradientEnd}) 75%`
                }}
              />
              <div 
                className={`${Styles.gradient} ${Styles.right}`} 
                key="gradient_right"
                style={{
                  background: `linear-gradient(90deg, ${gradientEnd}, ${gradientStart}) 75%`
                }}
              />
            </div>
            
            <Carousel key="carousel"  items={content.items} chapter={chapter} onChangeSlider={onChangeSlider} />
          </>
        }
        {mobile &&
          <>
            <div 
              className={Styles.mobileGradient} 
              key="gradient"
              style={{
                background: `linear-gradient(180deg, ${gradientEnd}, ${gradientStart}) 75%`
              }}
            />
            <div className={Styles.mobileList} key="mobileList">
              <h1 
                className={Styles.title}              
                key="titleMobile"
              >
                {language[title] ? language[title] : 'Replace this Title'}
              </h1>
              {renderSummaryList()}
            </div>
            <div 
              className={Styles.gradientTop} 
              key="gradientTop"
              style={{
                background: `linear-gradient(to bottom, ${gradientStart} 0%, ${gradientEnd}) 100%`
              }}
            />
          </>
        }
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 0.4, duration: 0.5}}
          key="motion-div"
        >
          <Link
            to={`${process.env.PUBLIC_URL}${module.nextChapter}`}
          >
            <NextChapterButton language={language}/>
          </Link>
        </motion.div>
      </AnimatePresence>
    </BaseModule>
  );
}
