import React, {useContext} from "react";
import Context from '../../../../context/Context';
import Slider from 'react-slick'
import CarouselItem from './CarouselItem'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styles from './Carousel.module.scss'
// import { motion } from 'framer-motion'

export default function Carousel({ items, chapter, onChangeSlider }) {
  const context = useContext(Context)
  const { theme, overrideTheme } = context
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    className: `carousel ${overrideTheme ? "light" : theme ? theme : "light"}`,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1301,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      onChangeSlider(newIndex)
    }
  }
  function renderItems() {
    return items.map((item, i) => {
      return <CarouselItem chapter={chapter} key={i} item={item} index={i} />
    })
  }
  return (
    <div className={Styles.carouselWrapper}>
      <Slider {...settings}>{renderItems()}</Slider>
    </div>
  )
}

function NextArrow(props) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}
