const LANGUAGES = [
	{ code: 'EN_US', name: 'English' },
	{ code: 'ZH_CN', name: '简体中文' },
	{ code: 'FR_FR', name: 'Français' },
    { code: 'ES_MX', name: 'Español' },
    { code: 'JA_JP', name: '日本語' },
    { code: 'DE_DE', name: 'Deutsch' },
    { code: 'KO_KR', name: '한국어' },
    { code: 'IT_IT', name: 'Italiano' },
];
export default LANGUAGES