import IntroModule from '../../components/Module/IntroModule';
import SummaryModule from '../../components/Module/SummaryModule';
import TextModule from '../../components/Module/TextModule';
import TextAndVideoModule from '../../components/Module/TextAndVideoModule';
import WingtipsModule from '../../components/Module/WingtipsModule';
import UseCaseModule from '../../components/Module/UseCaseModule';
// import InteractiveModule from '../../components/Module/InteractiveModule'
import InteractiveColorsModule from '../../components/Module/InteractiveColorsModule';
import MaskSliderModule from '../../components/Module/MaskSliderModule';
import ControlsModule from '../../components/Module/ControlsModule';
import TransparencyModule from '../../components/Module/TransparencyModule';
import ToggleModule from '../../components/Module/ToggleModule';

const CHAPTERS = [
  {
    id: 'intro',
    title: 'Global_Intro',
    modules: [
      {
        id: 'intro',
        title: 'Global_Intro',
        component: TextAndVideoModule,
        // theme: 'dark',
        replaceNextButtonContent: 'Global_UI_Begin',
        hideWithinChapterNav: true,
        content: {
          title: 'FitPro_Intro_Title',
          text: 'FitPro_Intro_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/00_INTRO/ys_trnr_intro.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/ys_trnr_intro.webm`,
            },
            hevc: {
              path: `/assets/fitPro/video/ys_trnr_intro.mp4`,
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
    ],
  },
  {
    id: 'fit',
    number: 1,
    title: 'FitPro_Menu_ChapterTitle_Fit',
    backgroundColor: '#E3E3E3',
    // backgroundColor: 'green',

    modules: [
      {
        id: 'intro',
        title: 'FitPro_Menu_ChapterTitle_Fit',
        component: IntroModule,
        hideWithinChapterNav: true,
        silenceBackgroundAudio: true,
        nextOnComplete: true,
        content: {
          title: 'FitPro_Menu_ChapterTitle_Fit',
          subTitle: 'FitPro_Fit_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_1_FIT.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: '/assets/fitPro/video/YR_TRNR_1_FIT.webm',
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_1_FIT.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'flex-to-fit-your-ear',
        title: 'FitPro_Menu_ChapterTitle_Fit_1',
        component: TextModule,
        backgroundColor: '#e3e3e3',
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_fit_chapter1.mp3`,
            buffer: undefined, // Set during preload
          },
          eyebrow: null,
          text: 'FitPro_Fit_ChapterText1',
        },
      },
      {
        id: 'flexible-wing-tips',
        title: 'FitPro_Menu_ChapterTitle_Fit_2',
        component: WingtipsModule,
        theme: 'dark',
        bgImage: '/assets/fitPro/images/bg_grey.png',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_FlexibleWingtip_Title',
          text: 'FitPro_Fit_FlexibleWingtip_Subtitle',
          hotspot: {
            theme: '#FF1535',
            coordinates: { x: 0.06, y: -0.4 },
          },
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_flexiblewingtips.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_FIT_FlexibleWingtips_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_FIT_FlexibleWingtips_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          videoFrames: {
            count: 16,
            reverse: true,
            path: '/assets/fitPro/frames/YS_TRNR_FIT_FlexibleWingtips',
          },
        },
      },
      // {
      //   id: 'flexible-wing-tips',
      //   title: 'Menu_Sound_Module3',
      //   component: TextAndVideoModule,
      //   theme: 'dark',
      //   componentProps: {
      //     variant: 'leftAlign',
      //   },
      //   content: {
      //     title: 'FitPro_Fit_FlexibleWingtip_Title',
      //     text: 'FitPro_Fit_FlexibleWingtip_Subtitle',

      //     voiceover: {
      //       path: `/assets/fitPro/audio/2_SOUND/VO_Sound_03_FindYourFit.mp3`,
      //       buffer: undefined, // Set during preload
      //     },
      //     loop: {
      //       multiLanguage: false,
      //       webm: {
      //         path: `/assets/fitPro/video/CR_TRNR_Sound_FindYourFit_Loop_v01.webm`,
      //       },
      //       hevc: {
      //         path: '/assets/fitPro/video/CR_TRNR_Sound_FindYourFit_Loop_v01.mp4',
      //       },
      //       linkElement: undefined, // Set during preload
      //       videoElement: undefined, // Set during preload
      //     },
      //     video: {
      //       webm: {
      //         path: `/assets/fitPro/video/CR_TRNR_Sound_FindYourFit_v01.webm`,
      //       },
      //       hevc: {
      //         path: '/assets/fitPro/video/CR_TRNR_Sound_FindYourFit_v01.mp4',
      //       },
      //       linkElement: undefined, // Set during preload
      //       videoElement: undefined, // Set during preload
      //     },
      //   },
      // },

      {
        id: 'all-day-comfort',
        title: 'FitPro_Menu_ChapterTitle_Fit_3',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_AllDay_Title',
          text: 'FitPro_Fit_AllDay_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_all_day_comfort.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_8_MONTAGE.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_8_MONTAGE.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_8_MONTAGE.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_8_MONTAGE.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'one-fits-all',
        title: 'FitPro_Menu_ChapterTitle_Fit_4',
        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_Universal_Title',
          text: 'FitPro_Fit_Universal_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_universal_fit.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Fit_Universal.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Fit_Universal.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Fit_Universal.webm`,
            },
            hevc: {
              path: `/assets/fitPro/video/YS_TRNR_Fit_Universal.mp4`,
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'soft-and-secure',
        title: 'FitPro_Menu_ChapterTitle_Fit_5',
        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_Materials_Title',
          text: 'FitPro_Fit_Materials_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_fit_materials.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_FIT_WingtipMaterial_v002.webm`,
            },
            hevc: {
              path: `/assets/fitPro/video/YS_TRNR_FIT_WingtipMaterial_v002.mp4`,
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_FIT_WingtipMaterial_v002.webm`,
            },
            hevc: {
              path: `/assets/fitPro/video/YS_TRNR_FIT_WingtipMaterial_v002.mp4`,
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'great-fit',
        title: 'FitPro_Menu_ChapterTitle_Fit_6',
        component: TextModule,
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_fit_chapter2.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Fit_ChapterText2',
        },
      },
      {
        id: 'find-your-size',
        title: 'FitPro_Menu_ChapterTitle_Fit_7',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_EarTips_Title',
          text: 'FitPro_Fit_EarTips_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_fit_eartips.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_FIT_EarTips_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_FIT_EarTips_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'put-to-the-test',
        title: 'FitPro_Menu_ChapterTitle_Fit_8',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_FitTest_Title',
          text: 'FitPro_Fit_FitTest_Subtitle',
          disclaimer: 'FitPro_Fit_FitTest_Disclaimer',

          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_fit_fittest.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Fit_FitTest.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Fit_FitTest.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'seal-the-deal',
        title: 'FitPro_Menu_ChapterTitle_Fit_9',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Fit_Seal_Title',
          text: 'FitPro_Fit_Seal_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_fit_seal.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Fit_Contour.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Fit_Contour.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Fit_Contour.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Fit_Contour.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'designed-for-comfort',
        title: 'FitPro_Menu_ChapterTitle_Fit_10',
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        nextChapter: '/sound',
        component: SummaryModule,
        theme: 'dark',
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        content: {
          title: 'FitPro_Fit_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary1_FlexibleWingtip.jpg',
              description: 'FitPro_Fit_Summary1_FlexibleWingtip',
              link: '/flex-to-fit-your-ear',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary2_AllDay.jpg',
              description: 'FitPro_Fit_Summary2_AllDay',
              link: '/all-day-comfort',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary3_Universal.jpg',
              description: 'FitPro_Fit_Summary3_Universal',
              link: '/one-fits-all',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary4_Materials.jpg',
              description: 'FitPro_Fit_Summary4_Materials',
              link: '/soft-and-secure',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary5_EarTips.jpg',
              description: 'FitPro_Fit_Summary5_EarTips',
              link: '/find-your-size',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary6_FitTest.jpg',
              description: 'FitPro_Fit_Summary6_FitTest',
              link: '/put-to-the-test',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Fit_Summary7_Seal.png',
              description: 'FitPro_Fit_Summary7_Seal',
              link: '/seal-the-deal',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/01_FIT/yr_trnr_fit_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },
  {
    id: 'sound',
    number: 2,
    title: 'FitPro_Sound_Title',
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'intro',
        title: 'FitPro_Sound_Title',
        component: IntroModule,
        theme: 'light',
        silenceBackgroundAudio: true,
        hideWithinChapterNav: true,
        nextOnComplete: true,
        content: {
          title: 'FitPro_Sound_Title',
          subTitle: 'FitPro_Sound_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_2_SOUND.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_2_SOUND.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_2_SOUND.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'custom-driver',
        title: 'FitPro_Menu_ChapterTitle_Sound_1',
        component: TextModule,
        theme: 'dark',
        backgroundColor: '#e3e3e3',

        content: {
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_chapter1.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Sound_ChapterText1',
        },
      },
      {
        id: 'small-and-mighty',
        title: 'FitPro_Menu_ChapterTitle_Sound_2',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Sound_Driver_Title',
          text: 'FitPro_Sound_Driver_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_driver.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_Driver_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_Driver_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'pleated-perfection',
        title: 'FitPro_Menu_ChapterTitle_Sound_3',
        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Sound_Pleated_Title',
          text: 'FitPro_Sound_Pleated_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/yr_trnr_sound_pleated.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_Pleated_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_Pleated_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_Pleated_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_Pleated_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'let-it-flow',
        title: 'FitPro_Menu_ChapterTitle_Sound_4',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Sound_Airflow_Title',
          text: 'FitPro_Sound_Airflow_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_airflow.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_Airflow_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_Airflow_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_Airflow_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_Airflow_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'signature-sound',
        title: 'FitPro_Menu_ChapterTitle_Sound_5',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Sound_UniqueDriver_Title',
          text: 'FitPro_Sound_UniqueDriver_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_unique_driver.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_UniqueDriver_Trans.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_UniqueDriver_Trans.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_UniqueDriver_Loop.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_UniqueDriver_Loop.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'exceptional-sound',
        title: 'FitPro_Menu_ChapterTitle_Sound_6',
        component: TextModule,
        backgroundColor: '#e3e3e3',

        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_chapter2.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Sound_ChapterText2',
        },
      },
      {
        id: 'hear-every-dimension',
        title: 'FitPro_Menu_ChapterTitle_Sound_7',
        component: TextAndVideoModule,
        theme: 'dark',

        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Sound_SpatialAudio_Title',
          text: 'FitPro_Sound_SpatialAudio_Subtitle',
          disclaimer: 'FitPro_Sound_SpatialAudio_Disclaimer',

          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_spatial_audio.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_SpatialAudio_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_SpatialAudio_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_SpatialAudio_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_SpatialAudio_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'use-cases',
        title: 'FitPro_Menu_ChapterTitle_Sound_8',
        bgImage: '/assets/fitPro/images/sound/ys_trnr_sound_use_case_bg.jpg',
        component: UseCaseModule,
        blockAudioAdvance: true,
        content: {
          title: 'FitPro_Sound_UseCases_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_use_case_1.mp3`,
            buffer: undefined, // Set during preload
          },
          cases: [
            {
              title: 'FitPro_Sound_UseCases_Music',
              description: 'FitPro_Sound_UseCases_Music_Subtitle',
              disclaimer: 'FitPro_Sound_UseCases_Disclaimer_1',
              audio: {
                path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_use_case_2.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Sound_UseCases_Movies',
              description: 'FitPro_Sound_UseCases_Movies_Subtitle',
              disclaimer: 'FitPro_Sound_UseCases_Disclaimer_1',
              audio: {
                path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_use_case_3.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Sound_UseCases_Games',
              description: 'FitPro_Sound_UseCases_Games_Subtitle',
              // disclaimer: [
              //   'FitPro_Sound_UseCases_Games_Disclaimer_1',
              //   'FitPro_Sound_UseCases_Games_Disclaimer_2'
              // ],
              disclaimer: 'FitPro_Sound_UseCases_Disclaimer_1',

              audio: {
                path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_use_case_4.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
          ],
        },
      },
      {
        id: 'loud-and-clear',
        title: 'FitPro_Menu_ChapterTitle_Sound_9',
        component: TextAndVideoModule,
        theme: 'dark',

        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Sound_Calls_Title',
          text: 'FitPro_Sound_Calls_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_call_quality.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_CallQuality_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_CallQuality_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Sound_CallQuality_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Sound_CallQuality_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'powerful-balanced-sound',
        title: 'FitPro_Menu_ChapterTitle_Sound_10',
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        nextChapter: '/listening-modes',
        component: SummaryModule,
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        theme: 'dark',
        backgroundColor: '#e3e3e3',

        content: {
          title: 'FitPro_Sound_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary1_Driver.jpg',
              description: 'FitPro_Sound_Summary1_Driver',
              link: '/small-and-mighty',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary2_Pleated.jpg',
              description: 'FitPro_Sound_Summary2_Pleated',
              link: '/pleated-perfection',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary3_Airflow.jpg',
              description: 'FitPro_Sound_Summary3_Airflow',
              link: '/let-it-flow',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary4_UniqueDriver.jpg',
              description: 'FitPro_Sound_Summary4_UniqueDriver',
              link: '/signature-sound',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary5_SpatialAudio.jpg',
              description: 'FitPro_Sound_Summary5_SpatialAudio',
              link: '/hear-every-dimension',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary6_UseCases.jpg',
              description: 'FitPro_Sound_Summary6_UseCases',
              link: '/use-cases',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Sound_Summary7_Calls.jpg',
              description: 'FitPro_Sound_Summary7_Calls',
              link: '/loud-and-clear',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/02_SOUND/ys_trnr_sound_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },
  {
    id: 'listening-modes',
    number: 3,
    title: 'FitPro_Modes_Title',
    // backgroundImage: {
    //   path: `/assets/fitPro/images/bg_grey.png`,
    // },
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'intro',
        title: 'FitPro_Modes_Title',
        component: IntroModule,
        hideWithinChapterNav: true,
        silenceBackgroundAudio: true,
        nextOnComplete: true,
        content: {
          title: 'FitPro_Modes_Title',
          subTitle: 'FitPro_Modes_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_3_LISTENING_MODES.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            // multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_3_LISTENING_MODES.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_3_LISTENING_MODES.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'hear-what-matters',
        title: 'FitPro_Menu_ChapterTitle_Modes_1',
        component: ToggleModule,
        silenceBackgroundAudio: true,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Modes_Toggle_Title',
          text: 'FitPro_Modes_Toggle_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_modes_toggle.mp3`,
            buffer: undefined, // Set during preload
          },
          productFrames: {
            count: 31,
            path: `/assets/fitPro/frames/YS_TRNR_ListeningMode_HearWhatMatters`,
          },
          albumArt: `/assets/fitPro/images/toggleModule/album.png`,
          track: {
            name: 'Mood (feat. iann dior)',
            artist: '24K Goldn',
            albumCover: `/assets/fitPro/images/toggleModule/album.png`,
          },
          modes: [
            {
              bgImage: `/assets/fitPro/images/toggleModule/bg_anc.png`,
              theme: 'light',
              'icon-active': `/assets/fitPro/images/toggleModule/mode_0_active.svg`,
              'icon-nonactive-light': `/assets/fitPro/images/toggleModule/mode_0_active.svg`,
              'icon-nonactive-dark': `/assets/fitPro/images/toggleModule/mode_0_active.svg`,
            },
            {
              bgImage: `/assets/fitPro/images/toggleModule/bg_eq.png`,
              theme: 'dark',
              'icon-active': `/assets/fitPro/images/toggleModule/mode_1_active.svg`,
              'icon-nonactive-light': `/assets/fitPro/images/toggleModule/mode_1_active.svg`,
              'icon-nonactive-dark': `/assets/fitPro/images/toggleModule/mode_1_nonactive_dark.svg`,
            },
            {
              bgImage: `/assets/fitPro/images/toggleModule/bg_transparency.png`,
              theme: 'dark',
              'icon-active': `/assets/fitPro/images/toggleModule/mode_2_active.svg`,
              'icon-nonactive-light': `/assets/fitPro/images/toggleModule/mode_2_active.svg`,
              'icon-nonactive-dark': `/assets/fitPro/images/toggleModule/mode_2_nonactive_dark.svg`,
            },
          ],
          tracks: [
            {
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_modes_anc.mp3`,
              },
            },
            {
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_modes_adaptiveEQ.mp3`,
              },
            },
            {
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_modes_transparency.mp3`,
              },
            },
          ],
        },
      },
      {
        id: 'active-noise-cancelling',
        title: 'FitPro_Menu_ChapterTitle_Modes_2',
        component: TextModule,
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/yr_trnr_listening_chapter1.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Modes_ChapterText1',
        },
      },
      {
        id: 'capture-external-noise',
        title: 'FitPro_Menu_ChapterTitle_Modes_3',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'light',
        content: {
          title: 'FitPro_Modes_ANCExternalMic_Title',
          text: 'FitPro_Modes_ANCExternalMic_Subtitle',

          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_externalmics.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            //multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_ListeningModes_ExternalMic_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_ListeningModes_ExternalMic_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            //multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_ListeningModes_ExternalMic_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_ListeningModes_ExternalMic_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'neutralize-unwanted-sound',
        title: 'FitPro_Menu_ChapterTitle_Modes_4',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'light',
        content: {
          title: 'FitPro_Modes_ANCAntiNoise_Title',
          text: 'FitPro_Modes_ANCAntiNoise_Subtitle',

          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_anti_noise.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            //multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_ListeningModes_AntiNoise_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_ListeningModes_AntiNoise_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            //multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_ListeningModes_AntiNoise_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_ListeningModes_AntiNoise_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'continuously-adjusts',
        title: 'FitPro_Menu_ChapterTitle_Modes_5',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'light',
        content: {
          title: 'FitPro_Modes_ANCInwardMic_Title',
          text: 'FitPro_Modes_ANCInwardMic_Subtitle',

          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_inward_mic.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            //multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_ListeningModes_InternalMic_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_ListeningModes_InternalMic_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            //multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_ListeningModes_InternalMic_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_ListeningModes_InternalMic_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'use-cases',
        title: 'FitPro_Menu_ChapterTitle_Modes_6',
        bgImage: '/assets/fitPro/images/YS_TRNR_Modes_ANCUseCases_bg.jpg',
        component: UseCaseModule,
        blockAudioAdvance: true,
        content: {
          title: 'FitPro_Modes_ANCUseCases_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_anc_use_cases_1.mp3`,
            buffer: undefined, // Set during preload
          },
          cases: [
            {
              title: 'FitPro_Modes_ANCUseCases_Commute',
              description: 'FitPro_Modes_ANCUseCases_Commute_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_anc_use_cases_2.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Modes_ANCUseCases_Exercise',
              description: 'FitPro_Modes_ANCUseCases_Exercise_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_anc_use_cases_3.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Modes_ANCUseCases_Travel',
              description: 'FitPro_Modes_ANCUseCases_Travel_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_anc_use_cases_4.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Modes_ANCUseCases_Meditate',
              description: 'FitPro_Modes_ANCUseCases_Meditate_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_anc_use_cases_5.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
          ],
        },
      },
      {
        id: 'transparency-mode',
        title: 'FitPro_Menu_ChapterTitle_Modes_7',
        component: TextModule,
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_chapter2.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Modes_ChapterText2',
        },
      },
      {
        id: 'hear-the-world',
        title: 'FitPro_Menu_ChapterTitle_Modes_8',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'light',
        content: {
          title: 'FitPro_Modes_TMAware_Title',
          text: 'FitPro_Modes_TMAware_Subtitle',

          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_aware.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Modes_TMAware_White_Lifestyle_Timothy_0014.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Modes_TMAware_White_Lifestyle_Timothy_0014.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'pinpoint-ambient-noise',
        title: 'FitPro_Menu_ChapterTitle_Modes_9',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'dark',
        content: {
          title: 'FitPro_Modes_TMExternalMic_Title',
          text: 'FitPro_Modes_TMExternalMic_Subtitle',

          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_external_mic.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Modes_TMExtermalMics_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Modes_TMExtermalMics_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Modes_TMExtermalMics_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Modes_TMExtermalMics_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'use-cases-2',
        title: 'FitPro_Menu_ChapterTitle_Modes_10',
        bgImage: '/assets/fitPro/images/YS_TRNR_Modes_TMUseCases_bg.jpg',
        component: UseCaseModule,
        blockAudioAdvance: true,
        content: {
          title: 'FitPro_Modes_TMUseCases_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_trans_use_case_1.mp3`,
            buffer: undefined, // Set during preload
          },
          cases: [
            {
              title: 'FitPro_Modes_TMUseCases_Run',
              description: 'FitPro_Modes_TMUseCases_Run_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_trans_use_case_2.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Modes_TMUseCases_Travel',
              description: 'FitPro_Modes_TMUseCases_Travel_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_trans_use_case_3.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Modes_TMUseCases_Walk',
              description: 'FitPro_Modes_TMUseCases_Walk_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_trans_use_case_4.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
            {
              title: 'FitPro_Modes_TMUseCases_StandBy',
              description: 'FitPro_Modes_TMUseCases_StandBy_Subtitle',
              audio: {
                path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_trans_use_case_5.mp3`,
                linkElement: undefined, // Set during preload
                audioElement: undefined, // Set during preload
              },
            },
          ],
        },
      },
      {
        id: 'adaptive-eq',
        title: 'FitPro_Menu_ChapterTitle_Modes_11',
        component: TextModule,
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_chapter3.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Modes_ChapterText3',
        },
      },
      {
        id: 'adapts-to-you',
        title: 'FitPro_Menu_ChapterTitle_Modes_12',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'dark',
        content: {
          title: 'FitPro_Modes_AdaptiveEQ_Title',
          text: 'FitPro_Modes_AdaptiveEQ_Subtitle',

          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_adaptive_eq.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Modes_EQ2_Loop.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Modes_EQ2_Loop.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Modes_AdaptiveEQ_Static.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Modes_AdaptiveEQ_Static.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'focus-on-your-music',
        title: 'FitPro_Menu_ChapterTitle_Modes_13',
        nextChapter: '/controls',
        theme: 'dark',
        component: SummaryModule,
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        content: {
          title: 'FitPro_Modes_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary1_Toggle.jpg',
              description: 'FitPro_Modes_Summary1_Toggle',
              link: '/hear-what-matters',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary2_ANCExtermalMic.jpg',
              description: 'FitPro_Modes_Summary2_ANCExtermalMic',
              link: '/capture-external-noise',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary3_ANCAntiNoise.jpg',
              description: 'FitPro_Modes_Summary3_ANCAntiNoise',
              link: '/neutralize-unwanted-sound',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary4_ANCInwardMic.jpg',
              description: 'FitPro_Modes_Summary4_ANCInwardMic',
              link: '/continuously-adjusts',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary5_ANCUseCases.jpg',
              description: 'FitPro_Modes_Summary5_ANCUseCases',
              link: '/use-cases',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary6_TMAware.jpg',
              description: 'FitPro_Modes_Summary6_TMAware',
              link: '/hear-the-world',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary7_TMExternalMic.png',
              description: 'FitPro_Modes_Summary7_TMExternalMic',
              link: '/pinpoint-ambient-noise',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary8_TMUseCases.jpg',
              description: 'FitPro_Modes_Summary8_TMUseCases',
              link: '/use-cases-2',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Modes_Summary9_AdaptiveEQ.jpg',
              description: 'FitPro_Modes_Summary9_AdaptiveEQ',
              link: '/adapts-to-you',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/03_LISTENING/ys_trnr_listening_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },
  {
    id: 'controls',
    number: 4,
    title: 'FitPro_Menu_ChapterTitle_Controls',
    // backgroundImage: {
    //   path: `/assets/fitPro/images/bg_grey.png`,
    // },
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'intro',
        title: 'FitPro_Menu_ChapterTitle_Controls',
        component: IntroModule,
        hideWithinChapterNav: true,
        silenceBackgroundAudio: true,
        nextOnComplete: true,
        content: {
          title: 'FitPro_Controls_Title',
          subTitle: 'FitPro_Controls_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_4_CONTROLS_V6_CONFORM.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_4_CONTROLS.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_4_CONTROLS.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'on-ear-buttons',
        title: 'FitPro_Menu_ChapterTitle_Controls_1',
        component: TextModule,
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_chapter1.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Controls_ChapterText1',
        },
      },
      {
        id: 'push-to-play',
        title: 'FitPro_Menu_ChapterTitle_Controls_2',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Controls_PhysicalButton_Title',
          text: 'FitPro_Controls_PhysicalButton_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_physical_buttons.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_Buttons_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_Buttons_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_Buttons_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_Buttons_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'independent-connectivity',
        title: 'FitPro_Menu_ChapterTitle_Controls_3',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Controls_BothSides_Title',
          text: 'FitPro_Controls_BothSides_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_both_sides.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_BothSides_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_BothSides_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_BothSides_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_BothSides_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'control-audio-modes',
        title: 'FitPro_Menu_ChapterTitle_Controls_4',
        component: TextModule,
        theme: 'dark',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_chapter2.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Controls_ChapterText2',
        },
      },
      {
        id: 'switch-listening-modes',
        title: 'FitPro_Menu_ChapterTitle_Controls_5',
        component: TransparencyModule,
        theme: 'light',
        alternateTheme: true,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Controls_PressHold_Title',
          text: 'FitPro_Controls_PressHold_Subtitle',
          disclaimer: 'FitPro_Controls_PressHold_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_press_hold.mp3`,
            buffer: undefined, // Set during preload
          },
          hotspotCoordinates: {
            x: -0.12,
            y: 0.13,
          },
          labelMode: {
            default: 'FitPro_Controls_PressHold_Label1',
            alternate: 'FitPro_Controls_PressHold_Label2',
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_PressAndHold_Gray_v002.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_PressAndHold_Gray_v002.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_PressAndHold_Gray_v002.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_PressAndHold_Gray_v002.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },

          videoTransitionToDefault: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_PressAndHold_WhitetoGray_v002.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_PressAndHold_WhitetoGray_v002.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loopAlternate: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_PressAndHold_White_v002.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_PressAndHold_White_v002.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          videoTransitionToAlternate: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Control_PressAndHold_popTrans_v002.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Control_PressAndHold_popTrans_v002.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'on-ear-controls',
        title: 'FitPro_Menu_ChapterTitle_Controls_6',
        component: ControlsModule,
        theme: 'dark',
        silenceBackgroundAudio: true,
        staticProductImage:
          '/assets/fitPro/images/YS_TRNR_Control_Playback.png',
        hotspotCoordinates: {
          x: -0.12,
          y: 0.13,
        },
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Controls_Playback_Title',
          text: 'FitPro_Controls_Playback_Subtitle',
          // disclaimer: 'Control_OnEarControls_Disclaimer',
          instructions: 'FitPro_Controls_Playback_Instruction',
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_playback1.mp3`,
            buffer: undefined, // Set during preload
          },
          sfx: [
            {
              id: 'rewind',
              path: '/assets/fitPro/audio/sfx/scan-rewind-sfx.mp3',
              audioElement: undefined, // Set during preload
            },
            {
              id: 'forward',
              path: '/assets/fitPro/audio/sfx/scan-forward-sfx.mp3',
              audioElement: undefined, // Set during preload
            },
          ],
          tracks: [
            {
              info: {
                name: 'Mood',
                artist: '24K Goldn, ft Iann Dior',
                albumCover: {
                  path: `/assets/fitPro/images/CR_TRNR_MusicAlbum_02.jpeg`,
                },
              },
              audio: {
                path: `/assets/fitPro/audio/CR_TRNR_MusicTrack_01.mp3`,
              },
            },
            {
              info: {
                name: 'Best years',
                artist: '5 Seconds of Summer',
                albumCover: {
                  path: `/assets/fitPro/images/CR_TRNR_MusicAlbum_01.jpeg`,
                },
              },
              audio: {
                path: `/assets/fitPro/audio/CR_TRNR_MusicTrack_02.mp3`,
              },
            },
            {
              info: {
                name: 'What If I Told You That I Love You',
                artist: 'Ali Gatie',
                albumCover: {
                  path: `/assets/fitPro/images/CR_TRNR_MusicAlbum_03.jpeg`,
                },
              },
              audio: {
                path: `/assets/fitPro/audio/CR_TRNR_MusicTrack_03.mp3`,
              },
            },
            {
              info: {
                name: 'Boyfriend',
                artist: 'Selena Gomez',
                albumCover: {
                  path: `/assets/fitPro/images/CR_TRNR_MusicAlbum_04.jpeg`,
                },
              },
              audio: {
                path: `/assets/fitPro/audio/CR_TRNR_MusicTrack_04.mp3`,
              },
            },
            {
              info: {
                name: 'Lovesick',
                artist: 'Trevor Daniel',
                albumCover: {
                  path: `/assets/fitPro/images/CR_TRNR_MusicAlbum_05.jpeg`,
                },
              },
              audio: {
                path: `/assets/fitPro/audio/CR_TRNR_MusicTrack_05.mp3`,
              },
            },
          ],
          controls: [
            {
              icon: '/assets/fitPro/images/interactive/step-0.svg',
              interactionType: 'oneClick',
              text: 'FitPro_Controls_Playback_Play1',
              bgIcon: {
                path: '/assets/images/interactive/controls-icon-pause.svg',
                invert: false, //invert icon horizontally
                customCss: { transform: 'translate(0, -5%)' },
              },
            },
            {
              icon: '/assets/fitPro/images/interactive/step-1.svg',
              interactionType: 'oneClick',
              text: 'FitPro_Controls_Playback_Play2',
              bgIcon: {
                path: '/assets/images/interactive/controls-icon-play.svg',
                invert: false, //invert icon horizontally
                customCss: { transform: 'translate(5%, 0)' },
              },
            },
            {
              icon: '/assets/fitPro/images/interactive/step-2.svg',
              interactionType: 'doubleClick',
              text: 'FitPro_Controls_Playback_Skip',
              bgIcon: {
                path: '/assets/images/interactive/controls-icon-arrow.svg',
                invert: false, //invert icon horizontally
              },
            },
            {
              icon: '/assets/fitPro/images/interactive/step-3.svg',
              interactionType: 'tripleClick',
              text: 'FitPro_Controls_Playback_Previous',
              bgIcon: {
                path: '/assets/images/interactive/controls-icon-arrow.svg',
                invert: true, //invert icon horizontally
              },
            },
            {
              icon: '/assets/fitPro/images/interactive/step-4.svg',
              interactionType: 'doubleAndHold',
              text: 'FitPro_Controls_Playback_ScanFwd',
              bgIcon: {
                path: '/assets/images/interactive/controls-icon-loop.svg',
                invert: false, //invert icon horizontally
                customCss: { transform: 'translate(8%, 0)' },
              },
            },
            {
              icon: '/assets/fitPro/images/interactive/step-5.svg',
              interactionType: 'tripleAndHold',
              text: 'FitPro_Controls_Playback_ScanBck',
              bgIcon: {
                path: '/assets/images/interactive/controls-icon-loop.svg',
                invert: true, //invert icon horizontally
                customCss: { transform: 'translate(-8%, 0)' },
              },
            },
            // {
            //   icon: '/assets/fitPro/images/interactive/step-6.svg',
            //   interactionType: 'hold',
            //   text: 'Control_OnEarControls_ToggleModes',
            // },
          ],
        },
      },

      // {
      //   id: 'on-ear-controls',
      //   title: 'Menu_Control_Module3',
      //   component: ControlsModule,
      //   silenceBackgroundAudio: true,
      //   hotspotCoordinates: {
      //     x: 0.24,
      //     y: -0.2,
      //   },
      //   componentProps: {
      //     variant: 'leftAlign',
      //   },
      //   content: {
      //     title: 'Control_OnEarControls_Title',
      //     text: 'Control_OnEarControls_Subtitle',
      //     disclaimer: 'Control_OnEarControls_Disclaimer',
      //     instructions: 'Control_OnEarControls_Instruction',
      //     voiceover: {
      //       path: `/assets/fitPro/audio/7_CONTROLS/VO_Control_03_OnEarControls.mp3`,
      //       buffer: undefined, // Set during preload
      //     },
      //     video: {
      //       webm: {
      //         path: `/assets/fitPro/video/CR_TRNR_Control_OnEarControls_Intro_v01.webm`,
      //       },
      //       hevc: {
      //         path: '/assets/fitPro/video/CR_TRNR_Control_OnEarControls_Intro_v01.mp4',
      //       },
      //       linkElement: undefined, // Set during preload
      //       videoElement: undefined, // Set during preload
      //     },
      //     steps: [
      //       {
      //         icon: '/assets/fitPro/images/interactive/step-1.svg',
      //         interactionType: 'oneClick',
      //         text: 'Control_OnEarControls_PlayPause',
      //         video: {
      //           webm: {
      //             path: `/assets/fitPro/video/CR_TRNR_Controls_PlayPause_v01.webm`,
      //           },
      //           hevc: {
      //             path: '/assets/fitPro/video/CR_TRNR_Controls_PlayPause_v01.mp4',
      //           },
      //           linkElement: undefined, // Set during preload
      //           videoElement: undefined, // Set during preload
      //         },
      //       },
      //       {
      //         icon: '/assets/fitPro/images/interactive/step-2.svg',
      //         interactionType: 'doubleClick',
      //         text: 'Control_OnEarControls_Skip',
      //         video: {
      //           webm: {
      //             path: `/assets/fitPro/video/CR_TRNR_Controls_SkipTrack_v01.webm`,
      //           },
      //           hevc: {
      //             path: '/assets/fitPro/video/CR_TRNR_Controls_SkipTrack_v01.mp4',
      //           },
      //           linkElement: undefined, // Set during preload
      //           videoElement: undefined, // Set during preload
      //         },
      //       },
      //       {
      //         icon: '/assets/fitPro/images/interactive/step-3.svg',
      //         interactionType: 'trippleClick',
      //         text: 'Control_OnEarControls_Previous',
      //         video: {
      //           webm: {
      //             path: `/assets/fitPro/video/CR_TRNR_Controls_PreviousTrack_v01.webm`,
      //           },
      //           hevc: {
      //             path: '/assets/fitPro/video/CR_TRNR_Controls_PreviousTrack_v01.mp4',
      //           },
      //           linkElement: undefined, // Set during preload
      //           videoElement: undefined, // Set during preload
      //         },
      //       },
      //       {
      //         icon: '/assets/fitPro/images/interactive/step-4.svg',
      //         interactionType: 'doubleAndHold',
      //         text: 'Control_OnEarControls_ScanForward',
      //         video: {
      //           webm: {
      //             path: `/assets/fitPro/video/CR_TRNR_Controls_ScanForward_v01.webm`,
      //           },
      //           hevc: {
      //             path: '/assets/fitPro/video/CR_TRNR_Controls_ScanForward_v01.mp4',
      //           },
      //           linkElement: undefined, // Set during preload
      //           videoElement: undefined, // Set during preload
      //         },
      //       },
      //       {
      //         icon: '/assets/fitPro/images/interactive/step-5.svg',
      //         interactionType: 'trippleAndHold',
      //         text: 'Control_OnEarControls_ScanBackward',
      //         video: {
      //           webm: {
      //             path: `/assets/fitPro/video/CR_TRNR_Controls_ScanBackwards_v01.webm`,
      //           },
      //           hevc: {
      //             path: '/assets/fitPro/video/CR_TRNR_Controls_ScanBackwards_v01.mp4',
      //           },
      //           linkElement: undefined, // Set during preload
      //           videoElement: undefined, // Set during preload
      //         },
      //       },
      //       {
      //         icon: '/assets/fitPro/images/interactive/step-6.svg',
      //         interactionType: 'hold',
      //         text: 'Control_OnEarControls_ToggleModes',
      //         video: {
      //           webm: {
      //             path: `/assets/fitPro/video/CR_TRNR_Controls_ToggleListeningMode_v01.webm`,
      //           },
      //           hevc: {
      //             path: '/assets/fitPro/video/CR_TRNR_Controls_ToggleListeningMode_v01.mp4',
      //           },
      //           linkElement: undefined, // Set during preload
      //           videoElement: undefined, // Set during preload
      //         },
      //       },
      //     ],
      //   },
      // },

      {
        id: 'pick-a-side',
        title: 'FitPro_Menu_ChapterTitle_Controls_7',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        theme: 'dark',
        content: {
          title: 'FitPro_Controls_Customize_Title',
          text: 'FitPro_Controls_Customize_Subtitle',
          disclaimer: 'FitPro_Controls_Customize_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_customize.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Controls_Customize.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Controls_Customize.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          loop: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Controls_Customize.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Controls_Customize.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'works-with-android',
        title: 'FitPro_Menu_ChapterTitle_Controls_8',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Controls_Android_Title',
          text: 'FitPro_Controls_Android_Subtitle',
          disclaimer: 'FitPro_Controls_Android_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_android.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            // multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Controls_Android.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Controls_Android.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      // {
      // 	id: 'eco-friendly',
      // 	title: 'something',
      // 	component: TextModule,
      // 	content: {
      // 		backgroundImage: {
      // 			path: `/assets/fitPro/images/bg_red.jpg`,
      // 		},
      // 		voiceover: {
      // 			path: `/assets/fitPro/audio/5_DESIGN/VO_Design_13_ChapterText4.mp3`,
      // 			linkElement: undefined, // Set during preload
      // 			audioElement: undefined, // Set during preload
      // 		},
      // 		text: 'Design_ChapterText4',
      // 	},
      // },
      // {
      // 	id: 'sustainable-packaging',
      // 	title: 'something',
      // 	component: TextAndVideoModule,
      // 	componentProps: {
      // 		variant: 'leftAlign',
      // 	},
      // 	content: {
      // 		title: 'Design_SustainablePackaging_Title',
      // 		text: 'Design_SustainablePackaging_Subtitle',
      // 		voiceover: {
      // 			path: `/assets/fitPro/audio/5_DESIGN/VO_Design_14_SustainablePackaging.mp3`,
      // 			linkElement: undefined, // Set during preload
      // 			audioElement: undefined, // Set during preload
      // 		},
      // 		video: {
      // 			webm: {
      // 				path: `/assets/fitPro/video/CR_TRNR_GreatSoundGreatFit_v01.webm`,
      // 			},
      // 			hevc: {
      // 				path: '/assets/fitPro/video/CR_TRNR_GreatSoundGreatFit_v01.mp4',
      // 			},
      // 			linkElement: undefined, // Set during preload
      // 			videoElement: undefined, // Set during preload
      // 		},
      // 	},
      // },
      {
        id: 'control-at-your-fingertips',
        title: 'FitPro_Menu_ChapterTitle_Controls_9',
        nextChapter: '/apple-h1',
        theme: 'dark',
        component: SummaryModule,
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        content: {
          title: 'FitPro_Controls_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Controls_Summary1_PhysicalButton.jpg',
              description: 'FitPro_Controls_Summary1_PhysicalButton',
              link: '/push-to-play',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Controls_Summary2_BothSides.jpg',
              description: 'FitPro_Controls_Summary2_BothSides',
              link: '/independent-connectivity',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Controls_Summary3_PressHold.jpg',
              description: 'FitPro_Controls_Summary3_PressHold',
              link: '/switch-listening-modes',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Controls_Summary4_Playback.jpg',
              description: 'FitPro_Controls_Summary4_Playback',
              link: '/on-ear-controls',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Controls_Summary5_Customize.jpg',
              description: 'FitPro_Controls_Summary5_Customize',
              link: '/pick-a-side',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Controls_Summary6_Android.jpg',
              description: 'FitPro_Controls_Summary6_Android',
              link: '/works-with-android',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/04_CONTROLS/ys_trnr_controls_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },
  {
    id: 'apple-h1',
    number: 5,
    title: 'FitPro_H1_Title',
    // backgroundImage: {
    //   path: `/assets/fitPro/images/bg_grey.png`,
    // },
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'intro',
        title: 'FitPro_H1_Title',
        component: IntroModule,
        silenceBackgroundAudio: true,
        hideWithinChapterNav: true,
        nextOnComplete: true,
        content: {
          title: 'FitPro_H1_Title',
          subTitle: 'FitPro_H1_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_5_APPLEH1.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_5_APPLEH1.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_5_APPLEH1.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'apple-integration',
        title: 'FitPro_Menu_ChapterTitle_H1_1',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_H1_AppleIntegration_Title',
          text: 'FitPro_H1_AppleIntegration_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_seamless.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_APPL_H1_Chip_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_APPL_H1_Chip_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'apple-h1-chip',
        title: 'FitPro_Menu_ChapterTitle_H1_2',
        theme: 'dark',
        component: TextModule,
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_chapter1.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_H1_ChapterText1',
        },
      },
      {
        id: 'one-touch-pairing',
        title: 'FitPro_Menu_ChapterTitle_H1_3',

        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_H1_Pairing_Title',
          text: 'FitPro_H1_Pairing_Subtitle',
          disclaimer: 'FitPro_H1_Pairing_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_pairing.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_APPL_H1_OneTouch_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_APPL_H1_OneTouch_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'hey-siri',
        title: 'FitPro_Menu_ChapterTitle_H1_4',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_H1_HeySiri_Title',
          text: 'FitPro_H1_HeySiri_Subtitle',
          disclaimer: 'FitPro_H1_HeySiri_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_hey_siri.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_APPL_H1_HeySiri_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_APPL_H1_HeySiri_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'automatic-switching',
        title: 'FitPro_Menu_ChapterTitle_H1_5',
        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_H1_AutoSwitch_Title',
          text: 'FitPro_H1_AutoSwitch_Subtitle',
          disclaimer: 'FitPro_H1_AutoSwitch_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_auto_switching.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_APPL_H1_DeviceSwitch_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_APPL_H1_DeviceSwitch_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'audio-sharing',
        title: 'FitPro_Menu_ChapterTitle_H1_6',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_H1_AudioSharing_Title',
          text: 'FitPro_H1_AudioSharing_Subtitle',
          disclaimer: 'FitPro_H1_AudioSharing_Disclaimer',

          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_sharing.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_APPL_H1_AudioShare_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_APPL_H1_AudioShare_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'find-my-devices',
        title: 'FitPro_Menu_ChapterTitle_H1_7',
        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_H1_FindMy_Title',
          text: 'FitPro_H1_FindMy_Subtitle',
          disclaimer: 'FitPro_H1_FindMy_Disclaimer',

          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_find_my.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_APPL_H1_FindMy_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_APPL_H1_FindMy_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'equipped-with-apple-h1-chip',
        title: 'FitPro_Menu_ChapterTitle_H1_8',
        theme: 'dark',
        nextChapter: '/design',
        component: SummaryModule,
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        content: {
          title: 'FitPro_H1_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_H1_Summary1_Seamless.jpg',
              description: 'FitPro_H1_Summary1_Seamless',
              link: '/apple-integration',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_H1_Summary2_Pairing.jpg',
              description: 'FitPro_H1_Summary2_Pairing',
              link: '/one-touch-pairing',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_H1_Summary3_HeySiri.jpg',
              description: 'FitPro_H1_Summary3_HeySiri',
              link: '/hey-siri',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_H1_Summary4_AutoSwitch.jpg',
              description: 'FitPro_H1_Summary4_AutoSwitch',
              link: '/automatic-switching',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_H1_Summary5_AudioSharing.jpg',
              description: 'FitPro_H1_Summary5_AudioSharing',
              link: '/audio-sharing',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_H1_Summary6_FindMy.jpg',
              description: 'FitPro_H1_Summary6_FindMy',
              link: '/find-my-devices',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/05_APPLE_H1/ys_trnr_apple_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },
  {
    id: 'design',
    number: 6,
    title: 'FitPro_Menu_ChapterTitle_Design',
    // backgroundImage: {
    //   path: `/assets/fitPro/images/bg_grey.png`,
    // },
    theme: 'dark',
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'intro',
        title: 'FitPro_Design_Title',
        component: IntroModule,
        hideWithinChapterNav: true,
        silenceBackgroundAudio: true,
        nextOnComplete: true,
        content: {
          title: 'FitPro_Design_Title',
          subTitle: 'FitPro_Design_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_6_DESIGN.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_6_DESIGN.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_6_DESIGN.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'charging-case',
        title: 'FitPro_Menu_ChapterTitle_Design_1',
        component: TextModule,
        theme: 'dark',
        backgroundColor: '#E3E3E3',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_chapter1.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Design_ChapterText1',
        },
      },
      {
        id: 'fits-in-your-pocket',
        title: 'FitPro_Menu_ChapterTitle_Design_2',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Design_Pocket_Title',
          text: 'FitPro_Design_Pocket_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_pocket.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_Pocket_CulverSage15427.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_Pocket_CulverSage15427.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'size-compare',
        title: 'FitPro_Menu_ChapterTitle_Design_3',
        component: MaskSliderModule,
        theme: 'dark',
        backgroundColor: '#E3E3E3',
        blockAudioAdvance: true,
        content: {
          title: 'FitPro_Design_Size_Title',
          text: 'FitPro_Design_Size_Subtitle',
          disclaimer: 'Control_SayItWithSiri_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_size_compare.mp3`,
            buffer: undefined, // Set during preload
          },
          maskSlider: {
            over: {
              img: `/assets/fitPro/images/case-power-beats-pro.png`,
              mask: `/assets/fitPro/images/case-beats-fit-pro-mask.png`,
            },
            under: {
              img: `/assets/fitPro/images/case-beats-fit-pro.png`,
              mask: `/assets/fitPro/images/case-power-beats-pro-mask.png`,
            },
          },
        },
      },
      {
        id: 'made-to-match',
        title: 'FitPro_Menu_ChapterTitle_Design_4',
        component: InteractiveColorsModule,
        theme: 'dark',
        colorPicker: true,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Design_Color_Title',
          text: 'FitPro_Design_Color_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_color_picker.mp3`,
            buffer: undefined, // Set during preload
          },
          hotspot: {
            theme: '#FF1535',
            coordinates: { x: 0, y: 0.2 },
            reverse: false,
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_ColorPickBlack_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_ColorPickBlack_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          videoTransitionToDefault: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_ColorPickBlack_rev.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_ColorPickBlack_rev.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          colors: [
            {
              text: 'FitPro_Design_Color_Black',
              color: ['#38393A'], //more than one color can be added to the color button

              videoFrames: {
                count: 45,
                path: '/assets/fitPro/frames/YS_TRNR_Design_ColorPick_2560_Black',
              },
            },
            {
              text: 'FitPro_Design_Color_White',
              color: ['#FFFFFF'], //more than one color can be added to the color button
              videoFrames: {
                count: 45,
                path: '/assets/fitPro/frames/YS_TRNR_Design_ColorPick_2560_White',
              },
            },
            {
              text: 'FitPro_Design_Color_Purple',
              color: ['#3B3B3B', '#EBB3FD'], //more than one color can be added to the color button
              videoFrames: {
                count: 45,
                path: '/assets/fitPro/frames/YS_TRNR_Design_ColorPick_2560_Purple',
              },
            },
            {
              text: 'FitPro_Design_Color_Gray',
              color: ['#83C6A6', '#505050'], //more than one color can be added to the color button
              videoFrames: {
                count: 45,
                path: '/assets/fitPro/frames/YS_TRNR_Design_ColorPick_2560_Sage',
              },
            },
          ],
        },
      },
      {
        id: 'go-whereever-you-do',
        title: 'FitPro_Menu_ChapterTitle_Design_5',
        component: TextModule,
        theme: 'dark',
        backgroundColor: '#E3E3E3',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_chapter2.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Design_ChapterText2',
        },
      },
      {
        id: 'wireless-freedom',
        title: 'FitPro_Menu_ChapterTitle_Design_6',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Design_TrueWireless_Title',
          text: 'FitPro_Design_TrueWireless_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_true_wireless.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_TrueWireless.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_TrueWireless.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_TrueWireless.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_TrueWireless.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'one-at-a-time',
        title: 'FitPro_Menu_ChapterTitle_Design_7',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Design_Independent_Title',
          text: 'FitPro_Design_Independent_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_independent.mp3`,
            buffer: undefined, // Set during preload
          },
          loop: {
            multiLanguage: false,
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_Ambi.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_Ambi.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_Ambi.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_Ambi.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'rain-sweat-or-shine',
        title: 'FitPro_Menu_ChapterTitle_Design_8',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Design_Sweat_Title',
          text: 'FitPro_Design_Sweat_Subtitle',
          disclaimer: 'FitPro_Design_Sweat_Disclaimer',

          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_sweat.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Design_SweatWater.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Design_SweatWater.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'all-day-activity',
        title: 'FitPro_Menu_ChapterTitle_Design_9',
        nextChapter: '/battery',
        component: SummaryModule,
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        theme: 'dark',
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        content: {
          title: 'FitPro_Design_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Design_Summary1_Pocket.jpg',
              description: 'FitPro_Design_Summary1_Pocket',
              link: '/fits-in-your-pocket',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Design_Summary2_Size.jpg',
              description: 'FitPro_Design_Summary2_Size',
              link: '/size-compare',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Design_Summary3_Color.jpg',
              description: 'FitPro_Design_Summary3_Color',
              link: '/made-to-match',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Design_Summary4_TrueWireless.jpg',
              description: 'FitPro_Design_Summary4_TrueWireless',
              link: '/wireless-freedom',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Design_Summary5_Independent.jpg',
              description: 'FitPro_Design_Summary5_Independent',
              link: '/one-at-a-time',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Design_Summary6_Sweat.jpg',
              description: 'FitPro_Design_Summary6_Sweat',
              link: '/rain-sweat-or-shine',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/06_DESIGN/ys_trnr_design_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },

  {
    id: 'battery',
    number: 7,
    title: 'FitPro_Menu_ChapterTitle_Battery',
    // backgroundImage: {
    //   path: `/assets/fitPro/images/bg_grey.png`,
    // },
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'intro',
        title: 'FitPro_Battery_Title',
        component: IntroModule,
        hideWithinChapterNav: true,
        nextOnComplete: true,
        silenceBackgroundAudio: true,
        content: {
          title: 'FitPro_Battery_Title',
          subTitle: 'FitPro_Battery_Intro_VOSubtitle',
          voiceover: {
            path: `/assets/fitPro/audio/YR_TRNR_7_BATTERY.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YR_TRNR_7_BATTERY.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YR_TRNR_7_BATTERY.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      // {
      //   id: 'all-day-battery',
      //   title: 'Menu_Battery_Module2',
      //   component: TextModule,
      //   content: {
      //     voiceover: {
      //       path: `/assets/fitPro/audio/8_BATTERY/VO_Battery_02_ChapterText1.mp3`,
      //       buffer: undefined, // Set during preload
      //     },
      //     text: 'Battery_ChapterText1',
      //   },
      // },
      {
        id: 'listen-for-hours',
        title: 'FitPro_Menu_ChapterTitle_Battery_1',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Battery_6Hours_Title',
          text: 'FitPro_Battery_6Hours_Subtitle',
          disclaimer: 'FitPro_Battery_6Hours_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/07_BATTERY/ys_trnr_battery_6_hrs.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Battery_6Hrs.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Battery_6Hrs.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'up-to-24-hours',
        title: 'FitPro_Menu_ChapterTitle_Battery_2',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Battery_18Hours_Title',
          text: 'FitPro_Battery_18Hours_Subtitle',
          disclaimer: 'FitPro_Battery_18Hours_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/07_BATTERY/ys_trnr_battery_18_hrs.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Battery_18Hrs.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Battery_18Hrs.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'power-hour',
        title: 'FitPro_Menu_ChapterTitle_Battery_3',
        component: TextAndVideoModule,
        theme: 'dark',
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Battery_FastFuel_Title',
          text: 'FitPro_Battery_FastFuel_Subtitle',
          disclaimer: 'FitPro_Battery_FastFuel_Disclaimer',
          voiceover: {
            path: `/assets/fitPro/audio/07_BATTERY/ys_trnr_battery_fast_fuel.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Battery_FastFuel_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Battery_FastFuel_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'usb-c-charging',
        title: 'FitPro_Menu_ChapterTitle_Battery_4',
        theme: 'light',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Battery_USBC_Title',
          text: 'FitPro_Battery_USBC_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/07_BATTERY/ys_trnr_battery_usb_c.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Battery_USBC_HouseSageGray0067.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Battery_USBC_HouseSageGray0067.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
      {
        id: 'whats-in-the-box',
        title: 'FitPro_Menu_ChapterTitle_Battery_5',
        theme: 'dark',
        component: TextAndVideoModule,
        componentProps: {
          variant: 'leftAlign',
        },
        content: {
          title: 'FitPro_Battery_WhatsInTheBox_Title',
          text: 'FitPro_Battery_WhatsInTheBox_Subtitle',
          voiceover: {
            path: `/assets/fitPro/audio/07_BATTERY/ys_trnr_battery_whats_in_box.mp3`,
            buffer: undefined, // Set during preload
          },
          video: {
            webm: {
              path: `/assets/fitPro/video/YS_TRNR_Battery_WhatsInTheBox_v001.webm`,
            },
            hevc: {
              path: '/assets/fitPro/video/YS_TRNR_Battery_WhatsInTheBox_v001.mp4',
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },

      {
        id: 'listen-on-and-on',
        title: 'FitPro_Menu_ChapterTitle_Battery_6',
        theme: 'dark',
        nextChapter: '/outro',
        component: SummaryModule,
        blockAudioAdvance: true,
        hideWithinChapterNav: true,
        gradientStart: 'rgba(227,227,227, 0.9)',
        gradientEnd: 'rgba(227,227,227, 0)', //clear
        content: {
          title: 'FitPro_Battery_Summary_Title',
          items: [
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Battery_Summary1_6Hours.jpg',
              description: 'FitPro_Battery_Summary1_6Hours',
              link: '/listen-for-hours',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Battery_Summary2_18Hours.jpg',
              description: 'FitPro_Battery_Summary2_18Hours',
              link: '/up-to-24-hours',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Battery_Summary3_FastFuel.jpg',
              description: 'FitPro_Battery_Summary3_FastFuel',
              link: '/power-hour',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Battery_Summary4_USBC.jpg',
              description: 'FitPro_Battery_Summary4_USBC',
              link: '/usb-c-charging',
            },
            {
              image:
                '/assets/fitPro/images/summary/FitPro_Battery_Summary5_WhatsInTheBox.jpg',
              description: 'FitPro_Battery_Summary5_WhatsInTheBox',
              link: '/whats-in-the-box',
            },
          ],
          voiceover: {
            path: `/assets/fitPro/audio/07_BATTERY/ys_trnr_battery_summary.mp3`,
            buffer: undefined, // Set during preload
          },
        },
      },
    ],
  },

  {
    id: 'outro',
    title: 'outro',
    // backgroundImage: {
    //   path: `/assets/fitPro/images/bg_grey.png`,
    // },
    theme: 'dark',
    backgroundColor: '#E3E3E3',
    modules: [
      {
        id: 'outro',
        title: 'Outro',
        component: TextModule,
        theme: 'dark',
        backgroundColor: '#E3E3E3',
        content: {
          voiceover: {
            path: `/assets/fitPro/audio/08_OUTRO/yr_trnr_outro.mp3`,
            buffer: undefined, // Set during preload
          },
          text: 'FitPro_Outro_ChapterText1',
          disclaimer: 'FitPro_Outro_ChapterText1_Disclaimer',
        },
      },
      {
        id: 'summary',
        title: 'summary',
        component: TextAndVideoModule,
        hideWithinChapterNav: true,
        content: {
          title: 'FitPro_Intro_Title',
          // text: 'FitPro_Intro_Subtitle',
          video: {
            webm: {
              path: `/assets/fitPro/video-mobile/yr_trnr_outro.webm`,
            },
            hevc: {
              path: `/assets/fitPro/video-mobile/yr_trnr_outro.mp4`,
            },
            linkElement: undefined, // Set during preload
            videoElement: undefined, // Set during preload
          },
        },
      },
    ],
  },
];

export default CHAPTERS;
