import { unloadSound } from '../AudioContext';

const audioContext = (window.audioContext =
  window.audioContext ||
  new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: 41000,
  }));

const linkElementPool = [];
const videoElementPool = [];
const audioElementPool = [];

const getVideoElementFromPool = () => {
  let el = videoElementPool.shift();
  if (!el) {
    el = document.createElement('video');
    el.setAttribute('playsinline', null);
    el.muted = true;
    el.loop = false;
  }
  return el;
};
const getLinkElementFromPool = () => {
  // Use Link element to force browser to preload so that media is immediately
  // playable once we try to play it
  // Reference: https://web.dev/fast-playback-with-preload/#link_preload
  let el = linkElementPool.shift();
  if (!el) {
    el = document.createElement('link');
    el.rel = 'preload';
  }
  return el;
};
const getAudioElementFromPool = () => {
  let el = audioElementPool.shift();
  if (!el) {
    el = document.createElement('audio');
    el.muted = true;
    el.loop = false;
  }
  return el;
};
const releaseVideoElement = videoData => {
  const el = videoData.videoElement;
  if (!el) {
    return;
  }
  el.onplay = undefined;
  el.onpause = undefined;
  el.onended = undefined;
  el.currentTime = 0;
  el.pause();
  el.loop = false;
  el.muted = true;
  el.src = '';
  videoElementPool.push(el);
  videoData.videoElement = undefined;
};
const releaseAudioElement = audioData => {
  const el = audioData.audioElement;
  if (!el) {
    return;
  }
  el.onplay = undefined;
  el.onpause = undefined;
  el.onended = undefined;
  el.currentTime = 0;
  el.pause();
  el.loop = false;
  el.muted = true;
  el.src = '';
  audioElementPool.push(el);
  audioData.audioElement = undefined;
};
const releaseLinkElement = videoData => {
  const el = videoData.linkElement;
  if (!el) {
    return;
  }
  el.onload = undefined;
  el.href = '';
  linkElementPool.push(el);
  videoData.linkElement = undefined;
};

const setVideoPath = ({
  videoLinkElement,
  videoElement,
  videoData,
  addLang = '',
  useMobileVideos,
}) => {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (videoElement.canPlayType('video/webm; codecs=vp9') !== '' && !isSafari) {
    // All modern browsers except Safari support webm
    videoElement.src = getResponsiveVideoPath(
      `${process.env.PUBLIC_URL}${
        videoData.webm.path.split('.webm')[0]
      }${addLang}.webm`,
      useMobileVideos
    );
  } else {
    // Else, must be Safari
    videoElement.type = 'video/mp4';
    videoElement.src = getResponsiveVideoPath(
      `${process.env.PUBLIC_URL}${
        videoData.hevc.path.split('.mp4')[0]
      }${addLang}.mp4`,
      useMobileVideos
    );
  }
  if (videoLinkElement) {
    videoLinkElement.href = videoElement.src;
  }
};

const getResponsiveVideoPath = (path, useMobileVideos) => {
  if (!useMobileVideos) {
    return path;
  }
  return path.replace('/video/', '/video-mobile/');
};

/**
 *
 * @param {*} videoData : Video JSON object from chapters.js
 */
const loadVideo = (videoData, isLoop = false, languageID, useMobileVideos) => {
  // console.log("---------------------------loadVideo",videoData.videoElement)
  const videoLinkElement = getLinkElementFromPool();
  videoLinkElement.as = 'video';
  // console.log("gettting video element from loadvideo")

  const videoElement = getVideoElementFromPool();
  // console.log("++++++++videoElement",videoElement)

  const addLang = videoData.multiLanguage ? `_${languageID}` : '';

  setVideoPath({
    videoLinkElement,
    videoElement,
    videoData,
    addLang,
    useMobileVideos,
  });
  videoElement.muted = true;
  videoElement.loop = isLoop;

  videoData.linkElement = videoLinkElement;
  videoData.videoElement = videoElement;
};

function loadAudioBuffer(audioObject, onComplete) {
  const request = new XMLHttpRequest();
  request.open('GET', `${process.env.PUBLIC_URL}${audioObject.path}`, true);
  request.responseType = 'arraybuffer';
  request.onload = function () {
    if (audioContext) {
      audioContext.decodeAudioData(
        request.response,
        buffer => {
          audioObject.buffer = buffer;
          onComplete();
        },
        error => {
          console.log('ChapterPreloader exception decoding audio data', error);
        }
      );
    }
  };
  request.send();
}

export function unloadModuleVideoAndAudio(module) {
  if (module.content.voiceover) {
    unloadSound(module.content.voiceover);
  }
  const {
    video,
    loop,
    videoTransitionToAlternate,
    loopAlternate,
    videoTransitionToDefault,
  } = module.content;
  [
    video,
    loop,
    videoTransitionToAlternate,
    loopAlternate,
    videoTransitionToDefault,
  ].forEach(videoData => {
    if (videoData) {
      releaseVideoElement(videoData);
      releaseLinkElement(videoData);
    }
  });

  if (module.content.steps || module.content.cases || module.content.tracks) {
    const list = module.content.steps
      ? module.content.steps
      : module.content.cases
      ? module.content.cases
      : module.content.tracks;
    list.forEach(step => {
      if (step.video) {
        releaseVideoElement(step.video);
      }
      if (step.audio) {
        releaseAudioElement(step.audio);
      }
    });
  }
  module.preloadedLanguageID = undefined;
}

export async function preloadModuleVideoAndAudio(
  module,
  languageID,
  useMobileVideos,
  onComplete
) {
  if (module.preloadedLanguageID === languageID) {
    return;
  }
  unloadModuleVideoAndAudio(module);

  if (module.content.steps || module.content.cases || module.content.tracks) {
    const list = module.content.steps
      ? module.content.steps
      : module.content.cases
      ? module.content.cases
      : module.content.tracks;

    const sfx = module.content.sfx;

    if (sfx && Object.keys(sfx).length) {
      sfx.forEach(effect => {
        const audioElement = getAudioElementFromPool();
        audioElement.src = `${process.env.PUBLIC_URL}${effect.path}`;
        effect.audioElement = audioElement;
      });
    }

    list.forEach(step => {
      if (step.audio) {
        const audioElement = getAudioElementFromPool();
        audioElement.src = `${process.env.PUBLIC_URL}${step.audio.path}`;
        step.audio.audioElement = audioElement;
      }

      // Set video elements
      if (module.content.cases || module.content.tracks) return;
      // console.log("gettting video element from list")
      const videoElement = getVideoElementFromPool();
      videoElement.muted = true;
      setVideoPath({ videoElement, videoData: step.video, useMobileVideos });
      step.video.videoElement = videoElement;
    });
  }
  // console.log("module content video is ",module.content.video ? module.content.video.hvec : 'none')
  if (module.content.video) {
    loadVideo(module.content.video, false, languageID, useMobileVideos);
  }
  if (module.content.loop) {
    loadVideo(module.content.loop, true, languageID, useMobileVideos);
  }
  if (module.content.videoTransitionToAlternate) {
    loadVideo(
      module.content.videoTransitionToAlternate,
      false,
      languageID,
      useMobileVideos
    );
  }
  if (module.content.loopAlternate) {
    loadVideo(module.content.loopAlternate, true, languageID, useMobileVideos);
  }
  if (module.content.videoTransitionToDefault) {
    loadVideo(
      module.content.videoTransitionToDefault,
      false,
      languageID,
      useMobileVideos
    );
  }

  // color picker module
  if (module.content.colors) {
    const framesProps = module.content.colors.map(color => color.videoFrames);
    window.colorPickFrames = {};
    const currentFrame = (index, colorIndex) =>
      `${process.env.PUBLIC_URL}${framesProps[colorIndex].path}/frame${index
        .toString()
        .padStart(4, '0')}.jpg`;

    framesProps.forEach((frames, colorIndex) => {
      for (let i = 1; i <= frames.count; i++) {
        const img = new Image();
        img.src = currentFrame(i, colorIndex);
        window.colorPickFrames[currentFrame(i, colorIndex)] = img;
      }
    });
  }

  const setPreloadComplete = () => {
    module.preloadedLanguageID = languageID;
    if (onComplete) {
      onComplete();
    }
  };

  if (module.content.voiceover) {
    loadAudioBuffer(module.content.voiceover, setPreloadComplete);
  } else {
    setPreloadComplete();
  }
}

export function unloadChapterVideoAndAudio(chapter) {
  chapter.modules?.forEach(m => unloadModuleVideoAndAudio(chapter.id, m));
}
