/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import BaseModule from '../BaseModule';
import Styles from './WingtipsModule.module.scss';
import { createMarkup } from '../../../utils';
import Context from '../../../context/Context';
import ReplayButton from '../../ReplayButton';
import useCanvasSize from '../../../hooks/useCanvasSize';
import DraggableSpot from './components/DraggableSpot';

const img = new Image();

let lastPercentage;
let idTimeout;
let idAnimation;

export default function WingtipsModule(props) {
  const [isRewinding, setIsRewinding] = useState(false);
  const {
    module,
    variant,
    videoContext,
    introVideoFinished,
    progress,
    chapter,
  } = props;
  const { content } = module;
  const { videoFrames, hotspot } = content;
  const context = useContext(Context);
  const { language, languageID, setCanAdvance, siteID } = context;
  const replaceCopyTitleNotifier = '';
  const replaceCopyBodyNotifier = '';
  const CANVAS_SIZE = useCanvasSize();
  const draggableRef = useRef();
  const [hasInteraction, setHasInteraction] = useState(false)

  const forceCanAdvanceTrue =
    progress &&
    progress[siteID].some(
      e => e.chapter === chapter.id && e.moduleID === module.id
    );

  let currFrame;

  const frameCount = videoFrames.count;

  const clearVideoFrame = () => {
    if (videoContext) {
      videoContext.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
    }
  };

  const drawFrame = frame => {
    if (videoContext) {
      videoContext.drawImage(frame, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
    }
  };

  const currentFrame = index =>
    `${process.env.PUBLIC_URL}${videoFrames.path}/frame${index
      .toString()
      .padStart(4, '0')}.jpg`;

  const updateImage = index => {
    const img = window.preloadedImages[currentFrame(index)];
    img && drawFrame(window.preloadedImages[currentFrame(index)]);
  };

  const calcFrames = (frcount, amount) => {
    if (videoFrames.reverse) {
      return frcount - frcount * amount;
    }

    return frcount * amount;
  };

  const handleVideoState = percentage => {
    setHasInteraction(true)

    if (percentage >= 0 && percentage <= 1) {
      if (lastPercentage !== Math.floor(calcFrames(frameCount, percentage))) {
        requestAnimationFrame(() =>
          updateImage(Math.floor(calcFrames(frameCount, percentage)))
        );
      }

      lastPercentage = Math.floor(calcFrames(frameCount, percentage));
    }
  };

  const rewind = () => {
    if (
      (!videoFrames.reverse && currFrame >= videoFrames.count) ||
      (videoFrames.reverse && currFrame === 0)
    ) {
      window.clearTimeout(idTimeout);
      setIsRewinding(false);
      setCanAdvance(true);
    } else {
      setIsRewinding(true);
      idTimeout = window.setTimeout(() => {
        currFrame = videoFrames.reverse ? currFrame - 1 : currFrame + 1;
        idAnimation = requestAnimationFrame(() => updateImage(currFrame));
        rewind();
      }, 1000 / 33);
    }
  };
  const handleInteractionEnd = lastPercentage => {
    lastPercentage = lastPercentage < 0 ? 0 : lastPercentage;
    currFrame = Math.floor(calcFrames(frameCount, lastPercentage));

    rewind();
  };

  useEffect(() => {
    window.preloadedImages = {};

    const preloadImages = () => {
      for (let i = 1; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        window.preloadedImages[currentFrame(i)] = img;
      }
    };

    preloadImages();

    return () => {
      window.clearTimeout(idTimeout);
      window.cancelAnimationFrame(idAnimation);
      clearVideoFrame();
    };
  }, []);

  useEffect(() => {
    if (!forceCanAdvanceTrue) setCanAdvance(false);

    if ((introVideoFinished || !content.video) && videoContext) {
      img.src = currentFrame(videoFrames.reverse ? 1 : frameCount);
      img.onload = function () {
        drawFrame(img);
      };
    }
  }, [introVideoFinished, videoContext, CANVAS_SIZE]);

  useEffect(() => {
    if (!hasInteraction || !introVideoFinished) setCanAdvance(false);
    else if (hasInteraction && introVideoFinished) setCanAdvance(true);
  }, [hasInteraction, introVideoFinished])

  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        'TextAndVideoModule'
      )}
    >
      <div className={Styles.container}>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.title]
              ? language[module.content.title]
              : replaceCopyTitleNotifier
          )}
        />
        <div
          className={`${Styles.text} ${
            languageID === 'JA_JP' ? Styles.left : null
          }`}
        >
          <div
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.text]
                ? language[module.content.text]
                : replaceCopyBodyNotifier
            )}
          />
          {module.content.textCTA && (
            <a
              className={clsx(
                'ctaButton',
                Styles.textCTA,
                module.id === 'summary' ? 'outroCta' : ''
              )}
              href={module.content.textCTA.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language[module.content.textCTA.label]
                ? language[module.content.textCTA.label]
                : replaceCopyBodyNotifier}
            </a>
          )}
        </div>

        {(introVideoFinished || !content.video) && (
          <DraggableSpot
            ref={draggableRef}
            videoContext={videoContext}
            hotspotInfo={hotspot}
            handleVideoState={handleVideoState}
            handleInteractionEnd={handleInteractionEnd}
            isRewinding={isRewinding}
          />
        )}

        {variant === 'leftAlign' && module.content.video && (
          <>
            <ReplayButton
              videoElement={module.content.video.videoElement}
              audioObject={module.content.voiceover}
              attachToGlobalCanAdvance
            />
          </>
        )}
        {module.content.disclaimer && (
          <div
            className={Styles.disclaimer}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}
      </div>
    </BaseModule>
  );
}
