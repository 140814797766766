import React from 'react';
import * as ROUTES from '../../constants/routes';
import { useContext } from 'react';
import Context from '../../context/Context';
import { Redirect } from 'react-router-dom'
import { withAuthorization } from '../Session';

const HomePage = props => {
  const { firebase, history, authUser } = props;
  const context = useContext(Context);
  const { isKioskVersion, siteID } = context;
  // const location = useLocation();

  if (isKioskVersion) {
    return (
      <Redirect to={ROUTES.WELCOME} /> 
    )
  } else {
    firebase
      .user(authUser.uid)
      .get()
      .then(snapshot => {

        //CHECK IF USER HAS SEEN WELCOME OR THERE IS PROGRESS AND REDIRECT ACCORDINGLY
        history.push({
          pathname:

			!snapshot.val().welcomeViewed 
              ? ROUTES.WELCOME
              : snapshot.val().progress
              ? ROUTES.WELCOMEBACK
              : ROUTES.INTRO,
          search: history.location.search,
        });
      })
      .catch(error => {
        history.push({pathname: ROUTES.INTRO, search: history.location.search});
      });
  }
  return <div></div>;
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
