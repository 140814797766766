import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import SITES from "../../constants/sites";

const config = {
  apiKey: window.location.origin.toLowerCase().includes('beatstrnr.com') || window.location.origin.toLowerCase().includes('wizardly') ? process.env.REACT_APP_PROD_API_KEY : process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: window.location.origin.toLowerCase().includes('beatstrnr.com') || window.location.origin.toLowerCase().includes('wizardly') ? process.env.REACT_APP_PROD_PROJECT_ID : process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    // window.recaptchaVerifier = new app.auth.RecaptchaVerifier('recaptcha-container', {
    // 	'size': 'invisible'
    // });
  }

  // *** Auth API ***

  signInWithPhoneNumber = (phone, verifier) =>
    this.auth.signInWithPhoneNumber(phone, verifier);

  SignInWithCredential = (credential) =>
    this.auth.SignInWithCredential(credential);

  PhoneAuthProvider = (id, code) => this.auth.PhoneAuthProvider(id, code);

  signInWithEmailLink = (email, url) =>
    this.auth.signInWithEmailLink(email, url);

  isSignInWithEmailLink = (url) => this.auth.isSignInWithEmailLink(url);

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInLinkToEmail = (email, actionCodeSettings) =>
    this.auth.sendSignInLinkToEmail(email, actionCodeSettings);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");
  doUpdateProgress = (chapter, moduleID, progress, lastVisited, siteID) => {
    // console.log("progress------------", progress)
    if (progress != null && lastVisited != null) {

      if (progress === 0) {
        progress = {};
        SITES.map((site, i) => (progress[site.id] = []));
      }

      const progressObj = { chapter: chapter, moduleID: moduleID };
      // console.log("progressObj", progressObj);
      if (lastVisited != null) {
        if (lastVisited === 0) {
          SITES.map((site, i) => (lastVisited[site.id] = []));
        }
        lastVisited[siteID] = progressObj;
      }

      if (
        !progress[siteID].some(
          (e) => e.chapter === chapter && e.moduleID === moduleID
        )
      ) {
        progress[siteID].push(progressObj);
      }
      let authUser = this.auth.currentUser;

      if (authUser) {
        this.user(authUser.uid)
          .update({
            progress: progress,
            lastVisited: lastVisited,
          })
          .then(() => {
            //console.log('update progress done');
          })
          .catch((error) => {
            console.log("error is", error);
          });
      }
    }
  };

  doSetParameter = (param, value) => {
    if (value != null) {
      let authUser = this.auth.currentUser;

      if (authUser) {
        this.user(authUser.uid)
          .update({
            [param]: value,
          })
          .then(() => {
            console.log("update parameter done");
          })
          .catch((error) => {
            console.log("error is", error);
          });
      }
    }
  };
}

export default Firebase;
export { app };
