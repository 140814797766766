import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import useWindowSize from '../../utils/UseWindowSize.js';
import Styles from './index.module.scss';
import Navigation from '../Navigation';
import AccountPage from '../../pages/Account';
import AdminPage from '../../pages/Admin';
import LanguagePage from '../../pages/Language';
import ProductSelector from '../../pages/ProductSelector';
// import SITES from "../../constants/sites";

import {
  SignInPage,
  UserInfoPage,
  WelcomePage,
  WelcomeBackPage,
  CompanyRequiredPage,
} from '../SignIn';
import SignOutButton from '../SignOut';

import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';

import * as DATA from '../../data';
import { BREAKPOINTS } from '../../constants/mediaQueries';
import Context from '../../context/Context';
import { TitleComponent } from '../../utils/title';
import cookie from 'react-cookies';
import * as ROUTES from '../../constants/routes';
import * as SITEDATA from '../../constants';

import { AuthUserContext } from '../Session';

import { withAuthentication } from '../Session';
import BaseChapter from '../Chapter/BaseChapter';
import BackgroundAudio from '../BackgroundAudio';
import * as AudioContext from '../AudioContext';
import Cursor from '../Cursor';

const App = props => {
  const { ga4 } = props;
  const hasMouseInput = window.matchMedia('(any-hover: hover)').matches;

  const isKioskVersion = process.env.REACT_APP_KIOSK_MODE;
  const cookieLanguage = cookie.load('cr_trnr_lang');
  const cookieSite = cookie.load('cr_trnr_site');

  // const cookieCompany = cookie.load('cr_trnr_company');

  const getQueryStringValue = (key, queryString = window.location.search) => {
    let params = new URLSearchParams(queryString);
    if (key === 'site') {
      if (params.get(key)) {
        cookie.save('cr_trnr_' + key, params.get(key), {
          path: '/',
        });
      }

      return params.get(key) ? params.get(key) : null;
    } else {
      if (params.get(key)) {
        cookie.save('cr_trnr_' + key, params.get(key).toUpperCase(), {
          path: '/',
        });
      }

      return params.get(key) ? params.get(key).toUpperCase() : null;
    }
  };

  //OVERRIDE CHAPTER TITLE IN HEADER
  const [overRideChapter, setOverRideChapter] = useState(null);

  //IF LANGUAGE PARAM IS PASSED IN URL, USE THAT.  ELSE CHECK FOR COOKIE LANGUAGE.  ELSE EN_US AND GO TO LANGUAGE SELECTOR

  const companyID = getQueryStringValue('company')
    ? getQueryStringValue('company')
    : null;
  const company = useState('');

  const [siteID, setSiteID] = useState(
    getQueryStringValue('site')
      ? getQueryStringValue('site')
      : cookieSite
      ? cookieSite
      : null
  );

  const [languageID, setLanguageID] = useState(
    getQueryStringValue('lang') || cookieLanguage
        ? cookieLanguage
        : 'EN_US'
 
  );

  const [language, setLanguage] = useState(
    DATA[`studioBuds_EN_US`]
  );

  const [currentChapter, setCurrentChapter] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const [progressKiosk, setProgressKiosk] = useState([]);
  const [menuActive, setMenuActive] = useState(false);
  const [muted, setMuted] = useState(true);
  const [silenceBackgroundAudio, setSilenceBackgroundAudio] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const [theme, setTheme] = useState('light');
  const [overrideTheme, setOverrideTheme] = useState(false);
  const [overRideNextButton, setOverRideNextButton] = useState(null);
  const [unmuteOnNextButtonClick, setUnmuteOnNextButtonClick] = useState(false);
  const CHAPTERS = siteID
    ? SITEDATA[`${siteID}_CHAPTERS`]
    : SITEDATA[`studioBuds_CHAPTERS`];
  const SETTINGS = siteID
    ? SITEDATA[`${siteID}_SETTINGS`]
    : SITEDATA[`studioBuds_SETTINGS`];
  const bgAudio = SETTINGS.backgroundAudio;
  // The next two states are for the custom Cursor
  // BaseChapter controls canAdvance
  const [canAdvance, setCanAdvance] = useState(true);
  // forceDefaultCursor is set by components like Menu and AudioControl to hide custom Cursor while mouse is inside
  const [forceDefaultCursor, setForceDefaultCursor] = useState(false);
  const [videoInProgressPercent, setVideoInProgressPercent] = useState(0);
  // Hide bottom dots
  const [hideDots, setHideDots] = useState(false);
  // IS MOBILE WIDTH
  const [mobileLayout, setMobileLayout] = useState(false);
  const windowSize = useWindowSize();
  if (windowSize.width <= BREAKPOINTS.MOBILE_DOWN && !mobileLayout) {
    setMobileLayout(true);
  } else if (windowSize.width > BREAKPOINTS.MOBILE_DOWN && mobileLayout) {
    setMobileLayout(false);
  }

  useEffect(() => {
    if (muted) {
      AudioContext.muteAudioContext();
    } else {
      AudioContext.setupAudioContext();
      AudioContext.unmuteAudioContext();
    }
  }, [muted]);

  useEffect(() => {
    setOverRideChapter(!menuActive ? null : 'Global_Menu');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuActive]);

  const setCSSViewHeight = () => {
    document.documentElement.style.setProperty(
      '--vh',
      `${document.documentElement.clientHeight / 100}px`
    );
  };

  useEffect(() => {
    setCSSViewHeight();
    window.addEventListener('resize', setCSSViewHeight);
  }, []);

  useEffect(() => {
    console.log("useeffect setLanguage")
    siteID
      ? setLanguage(DATA[`${siteID}_${languageID}`])
      : setLanguage(DATA[`studioBuds_${languageID}`]);
  }, [languageID, siteID]);

  //clear language cookie
  const clearLanguage = props => {
    cookie.remove('cr_trnr_lang', { path: '/' });
    return <LanguagePage {...props} />;
  };
  //clear site cookie
  const clearProduct = (authUser, Component, props, isSignIn) => {
    cookie.remove('cr_trnr_site', { path: '/' });
    return validateAuth(authUser, Component, props, isSignIn);
  };
  const validateAuth = (authUser, Component, props, isSignIn) => {
    const { firstName } = props;
    const needLanguage =
      !cookie.load('cr_trnr_lang') &&
      !window.location.pathname.includes('/language');

    const needSite =
      !cookie.load('cr_trnr_site') &&
      !window.location.pathname.includes('/sites');

    if (isKioskVersion) {

      return needLanguage ? (
        <LanguagePage {...props} language={language} />
      ) : 
      isSignIn ? (
        <WelcomePage {...props} authUser={authUser} language={language} />
      ) : needSite ? (
        <ProductSelector {...props} language={language} />
      ) : (
        <Component {...props} authUser={authUser} language={language} />
      );
    } else {
      // console.log('is not kiosk authUser', authUser);
      // console.log('is not kiosk needLanguage', needLanguage);

      // console.log('is not kiosk firstName', firstName);

      // console.log('is not kiosk needSite', needSite);

      // console.log('is not kiosk progress', progress);

      return needLanguage ? (
        <LanguagePage {...props} language={language} />
      ) : authUser && authUser !== 'notset' ? (
        isSignIn ? (
          <WelcomeBackPage {...props} authUser={authUser} language={language} />
        ) : firstName === undefined ? (
          <UserInfoPage {...props} authUser={authUser} language={language} />
        ) : needSite ? (
          <ProductSelector {...props} language={language} />
        ) : (
          //         ) : progress && progress[siteID].length === 0 ? (
          // <WelcomePage {...props} authUser={authUser} language={language} />
          <Component {...props} authUser={authUser} language={language} />
        )
      ) : !authUser ? (
        <SignInPage {...props} language={language} />
      ) : null;
    }
  };
  const validateLanguage = (authUser, Component, props) => {
    console.log('validateLanguage');
    const needLanguage =
      !cookie.load('cr_trnr_lang') &&
      !window.location.pathname.includes('/language');
    console.log('needLanguage', needLanguage);
    return needLanguage ? (
      <LanguagePage {...props} language={language} />
    ) : (
      <Component {...props} authUser={authUser} language={language} />
    );
  };
 console.log("!!!!!!!!!!!!!!!!!!!!!!!!language",languageID)
  return (
    <Router basename={process.env.ROUTE_BASENAME}>
      <Context.Provider
        value={{
          language: language,
          languageID: languageID,
          companyID: companyID,
          setLanguageID: setLanguageID,
          setLanguage,
          menuActive: menuActive,
          setMenuActive: setMenuActive,
          setCurrentChapter: setCurrentChapter,
          currentChapter: currentChapter,
          muted: muted,
          setMuted: setMuted,
          overRideChapter: overRideChapter,
          setOverRideChapter: setOverRideChapter,
          canAdvance,
          setCanAdvance,
          forceDefaultCursor,
          setForceDefaultCursor,
          setShowCursor,
          showCursor,
          hideDots,
          setHideDots,
          videoInProgressPercent,
          setVideoInProgressPercent,
          silenceBackgroundAudio,
          setSilenceBackgroundAudio,
          mobileLayout,
          setOverRideNextButton,
          overRideNextButton,
          unmuteOnNextButtonClick,
          setUnmuteOnNextButtonClick,
          company,
          isKioskVersion,
          progressKiosk,
          setProgressKiosk,
          ga4,
          setSiteID,
          siteID,
          theme,
          setTheme,
          playSound: AudioContext.playSound,
          setOverrideTheme,
          overrideTheme,
          bgAudio,
          isHeaderVisible,
          setIsHeaderVisible,
        }}
      >
        <Context.Consumer>
          {({ theme, overrideTheme }) => {
            setShowCursor(true);
            return (
              <AuthUserContext.Consumer>
                {({
                  authUser,
                  progress,
                  setProgress,
                  firstName,
                  setLastVisited,
                  lastVisited,
                }) => {
                  //TO FIX OLDER VERSIONS OF APP THAT HAD ONLY ONE ARRAY IN PROGRESS..
                  //CHECK IF PROGRESS IS AN OBJECT OR AN ARRAY.  IF IT IS AN ARRAY, CONVERT TO OBJECT

                  const pageArray = window.location.pathname
                    .toUpperCase()
                    .split('/');
                  pageArray.shift();
                  // console.log("THEMIS ISS",theme)
                  return (
                    <div
                      className={` ${languageID === "KO_KR" ? Styles.korean : null} ${languageID === "JA_JP" ? Styles.japan : null} ${Styles.siteholder} ${
                        overrideTheme
                          ? Styles.light
                          : theme
                          ? Styles[theme]
                          : Styles.light
                      }`}
                    >
                      <TitleComponent title={`${pageArray.join(' : ')}`} />

                      <AnimatePresence
                        exitBeforeEnter
                        initial={false}
                        type="crossfade"
                      >
                        <Switch>
                          {CHAPTERS
                            ? CHAPTERS.map(chapter => (
                                <Route
                                  key={chapter.id}
                                  path={`${process.env.PUBLIC_URL}/${chapter.id}`}
                                  render={pageProps => {
                                    return validateAuth(authUser, BaseChapter, {
                                      ...props,
                                      ...pageProps,
                                      chapter: chapter,
                                      progress: progress,
                                      setProgress: setProgress,
                                      setLastVisited: setLastVisited,
                                      lastVisited: lastVisited,
                                      setCurrentChapter: setCurrentChapter,
                                      firstName: firstName,
                                    });
                                  }}
                                />
                              ))
                            : null}

                          <Route
                            path={ROUTES.SIGN_IN}
                            render={props =>
                              validateAuth(
                                authUser,
                                SignInPage,
                                {
                                  ...props,
                                  progress: progress,
                                  setProgress: setProgress,
                                  setLastVisited: setLastVisited,
                                  lastVisited: lastVisited,
                                  setCurrentChapter: setCurrentChapter,
                                  firstName: firstName,
                                },
                                true
                              )
                            }
                          />

                          <Route
                            path={ROUTES.SIGN_OUT}
                            render={props => <SignOutButton />}
                          />
                          <Route
                            path={ROUTES.PASSWORD_FORGET}
                            render={props =>
                              validateLanguage(
                                authUser,
                                PasswordForgetPage,
                                props
                              )
                            }
                          />

                          <Route
                            path={ROUTES.ACCOUNT}
                            render={props =>
                              validateAuth(authUser, AccountPage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />
                          <Route
                            path={ROUTES.ADMIN}
                            render={props =>
                              validateAuth(authUser, AdminPage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />

                          <Route
                            exact
                            path={ROUTES.USER_INFO}
                            render={props =>
                              validateAuth(authUser, UserInfoPage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />
                          <Route
                            exact
                            path={ROUTES.WELCOME}
                            render={props =>
                              validateAuth(authUser, WelcomePage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />
                          <Route
                            exact
                            path={ROUTES.COMPANYREQUIRED}
                            render={props =>
                              validateLanguage(
                                authUser,
                                CompanyRequiredPage,
                                props
                              )
                            }
                          />
                          <Route
                            exact
                            path={ROUTES.WELCOMEBACK}
                            render={props =>
                              validateAuth(authUser, WelcomeBackPage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />

                          <Route
                            path={ROUTES.LANGUAGE}
                            render={props => clearLanguage(props)}
                          />
                          <Route
                            path={ROUTES.PRODUCTSELECTOR}
                            render={props =>
                              clearProduct(authUser, HomePage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />
                          <Route
                            exact
                            path={ROUTES.HOME}
                            render={props =>
                              validateAuth(authUser, HomePage, {
                                ...props,
                                progress: progress,
                                setProgress: setProgress,
                                setLastVisited: setLastVisited,
                                lastVisited: lastVisited,
                                setCurrentChapter: setCurrentChapter,
                                firstName: firstName,
                              })
                            }
                          />
                          <Route component={WelcomePage} />
                        </Switch>
                      </AnimatePresence>
                      <Navigation {...props} />
                      <BackgroundAudio />
                      {hasMouseInput ? <Cursor /> : null}
                    </div>
                  );
                }}
              </AuthUserContext.Consumer>
            );
          }}
        </Context.Consumer>
      </Context.Provider>
    </Router>
  );
};

export default withAuthentication(App);
