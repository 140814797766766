import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import BaseModule from '../BaseModule';
import Styles from './UseCaseModule.module.scss';
import UseCase from './components/UseCase';
import Context from '../../../context/Context';
import { AnimatePresence, motion } from 'framer-motion';
import { createMarkup } from '../../../utils';

export default function UseCaseModule(props) {
  const { module, introAudioFinished, progress, chapter } = props;
  const { content, bgImage } = module;
  const { title, cases } = content;
  const [activeCase, setActiveCase] = useState(null);
  const [blockNewMedia, setBlockNewMedia] = useState(false);
  const [activeAudio, setActiveAudio] = useState(null);
  const [playedUseCases, setPlayedUseCases] = useState([]);
  const [audioProgress, setAudioProgress] = useState(null);
  const context = useContext(Context);
  const { muted, language, setCanAdvance, setVideoInProgressPercent, siteID, languageID } =
    context;
  const forceCanAdvanceTrue =
    progress &&
    progress[siteID].some(
      e => e.chapter === chapter.id && e.moduleID === module.id
    );
  const bgImageRef = useRef(null);
  let progressInterval;

  useEffect(() => {
    setVideoInProgressPercent(0);
    return () => {
      clearInterval(progressInterval);
      setVideoInProgressPercent(0);
    };
  }, []);

  useEffect(() => {
    if (!forceCanAdvanceTrue) {
      setCanAdvance(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCanAdvance]);

  useEffect(() => {
    if (introAudioFinished) {
      setVideoInProgressPercent(0);
    }
  }, [introAudioFinished]);

  useEffect(() => {
    if (playedUseCases.length >= cases.length) {
      setCanAdvance(true);
    }
  }, [playedUseCases]);

  const handleAudioProgress = (audio, title) => {
    if (!playedUseCases.includes(title)) {
      const numPlayedSteps = playedUseCases.length ? playedUseCases.length : 0;
      const sumProgress = numPlayedSteps / cases.length;
      setVideoInProgressPercent(
        sumProgress + audio.currentTime / audio.duration / cases.length
      );
    }
    setAudioProgress(audio.currentTime / audio.duration);
  };

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  function handleSetActiveCase(selectedCase) {
    if (!muted && !introAudioFinished) return;
    if (blockNewMedia) return;

    if (activeAudio) {
      activeAudio.pause();
    }

    setActiveCase(selectedCase.title);

    const audio = selectedCase.audio.audioElement;
    audio.volume = 1;

    setActiveAudio(audio);

    audio.onplay = () => {
      progressInterval = window.setInterval(
        () => handleAudioProgress(audio, selectedCase.title),
        1000 / 30
      );
      setBlockNewMedia(true);
    };

    audio.onended = () => {
      if (!playedUseCases.includes(selectedCase.title)) {
        setPlayedUseCases(playedUseCases => [
          ...playedUseCases,
          selectedCase.title,
        ]);
      }

      audio.currentTime = 0;
      clearInterval(progressInterval);
      setBlockNewMedia(false);
      setActiveAudio(null);
      setActiveCase(null);
      setAudioProgress(null);
    };

    if (!muted) {
      audio.muted = false;
      audio.play();
    } else {
      setPlayedUseCases(playedUseCases => [
        ...playedUseCases,
        selectedCase.title,
      ]);
      if (playedUseCases.length + 1 >= cases.length) {
        setCanAdvance(true);
      }
    }
  }

  function renderCases() {
    return cases.map((useCase, i) => {
      return (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          key={`${useCase.title}-${i}`}
          transition={{ delay: i * 0.05 + 0.2, stiffness: 5 }}
        >
          <UseCase
            useCase={useCase}
            activeCase={activeCase}
            audioProgress={audioProgress}
            handleSetActiveCase={handleSetActiveCase}
            playedUseCases={playedUseCases}
          />
        </motion.div>
      );
    });
  }

  function renderSuperscripts() {
    return cases.map((useCase, i) => {
      if (useCase.disclaimer === undefined) return null;

      let superscripts = useCase.disclaimer;

      if (!Array.isArray(superscripts)) superscripts = [superscripts];

      return (
        <div key={`${useCase.title}-${i}`}>
          {activeCase === useCase.title && (
            <div className={Styles.superscript}>
              {superscripts.map(disclaimer => (
                <p
                  className={Styles.disclaimer}
                  dangerouslySetInnerHTML={createMarkup(language[disclaimer])}
                />
              ))}
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <BaseModule {...props} className={clsx(props.className, Styles.root)}>
      <AnimatePresence>
        <motion.img
          alt="background"
          className="bg-image"
          key="bg-image"
          src={process.env.PUBLIC_URL + bgImage}
          ref={bgImageRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: activeCase ? 0.5 : 0.75 }}
          style={{
            position: 'absolute',
            height: '100vh',
            width: '100%',
            top: '0',
            left: '0',
            zIndex: '1',
            objectFit: 'cover',
          }}
        />
        <link
          key="bg-image-link"
          rel="preload"
          src={process.env.PUBLIC_URL + bgImage}
          as="image"
          href={process.env.PUBLIC_URL + bgImage}
        />
        <div key="blackBackground" className={Styles.blackBackground} />
        <motion.div
          variants={list}
          key="rendercaseswrapper"
          className={Styles.renderCasesWrapper}
        >
          <motion.h1
            key="title"
            className={`${Styles.title} ${languageID === "JA_JP" ? Styles.japan : null}`}
            style={{ zIndex: 1 }}
            initial={{ opacity: activeCase ? 0.2 : 1 }}
            animate={{ opacity: 1 }}
            dangerouslySetInnerHTML={createMarkup(language[title])}
          />

          {renderCases()}
        </motion.div>
        {renderSuperscripts()}
      </AnimatePresence>
    </BaseModule>
  );
}
