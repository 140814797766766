import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import BaseModule from "../BaseModule";
import Styles from "./InteractiveModule.module.scss";
import InteractiveStep from "./components/InteractiveStep.js";
import { createMarkup } from "../../../utils";
import Context from "../../../context/Context";
import ReplayButton from "../../ReplayButton";
import useCanvasSize from '../../../hooks/useCanvasSize';
import { muteAudioContext, unmuteAudioContext } from "../../AudioContext";

export default function InteractiveModule(props) {
  const { module, variant, introVideoFinished, videoContext, introAudioFinished, progress, chapter } =
    props;
  const { content, colorPicker } = module;
  const [playedSteps, setPlayedSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  const context = useContext(Context);
	const CANVAS_SIZE = useCanvasSize();

  const {
    setCanAdvance,
    setVideoInProgressPercent,
    language,
    mobileLayout,
    muted,
    setForceDefaultCursor,
    siteID
  } = context;
  const forceCanAdvanceTrue = progress && progress[siteID].some((e) => e.chapter === chapter.id && e.moduleID === module.id);
  let interactiveInterval;
  
  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
	};
	const handleMouseLeave = () => {
    setForceDefaultCursor(false);
	};

  useEffect(() => {
    const numPlayedSteps = playedSteps.length ? playedSteps.length + 1 : 0;
    const sumProgress = numPlayedSteps / content.steps.length;
    setVideoInProgressPercent(sumProgress);
  }, [
    introVideoFinished,
    playedSteps,
    content.steps.length,
    setVideoInProgressPercent,
  ]);

  useEffect(() => {
    // If colorpicker, act like WHITE video is initially selected
    if (colorPicker) {
      setActiveStep(content.steps[0])
      setPlayedSteps([...playedSteps, 0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorPicker]);

  useEffect(() => {
    setCanAdvance(true);
    return () => {
      content.steps.forEach(step => {
        if (step.video) step.video.videoElement.pause();
      })
      clearInterval(interactiveInterval);
      clearVideoFrame();
      if (!muted){
        unmuteAudioContext();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (activeStep?.video?.videoElement){
      activeStep.video.videoElement.muted = muted;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muted])

  const clearVideoFrame = () => {
		if (videoContext) {
			videoContext.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
		}
	};

  const drawVideoFrame = (video) => {
    const numPlayedSteps = playedSteps.length ? playedSteps.length : 0;
    const sumProgress = numPlayedSteps / content.steps.length;
    if (sumProgress < 1 && !forceCanAdvanceTrue) {
      setVideoInProgressPercent(
        sumProgress + video.currentTime / video.duration / content.steps.length
      );
    } else {
      setVideoInProgressPercent(1)
    }
    videoContext.drawImage(video, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
  };

  function playStepVideo(step) {
    // TrackEvent(ga4, `interactive_module_${step.text}`, 'click', 'user_action');

    if (!introVideoFinished) return;

    if (content.video) {
      const intro = content.video.videoElement;
      intro.pause();
      intro.currentTime = 0;
    }    
    content.steps.forEach(step => {
      const vid = step.video.videoElement;
      vid.pause();
      vid.currentTime = 0;
      clearInterval(interactiveInterval);
    })        

    setActiveStep(step);

    const video = step.video.videoElement;
    video.muted = muted;

    const handlePlay = () => {
      clearInterval(interactiveInterval)
      interactiveInterval = window.setInterval(() => {
        drawVideoFrame(video);
      }, 1000 / 30);
    };
    const handlePause = () => {
      clearInterval(interactiveInterval);
    }
    const handleEnded = () => {
      video.pause();
      video.onplay = null;
      setVideoInProgressPercent(
        (playedSteps.length + 1) / content.steps.length
      );
      clearInterval(interactiveInterval);
    };
    video.onplay = handlePlay;
    video.onpause = handlePause;
    video.onended = handleEnded;

    const audio = step.audio?.audioElement;

    if (audio && !muted) {
      audio.muted = false;
      audio.play();
    }

    muteAudioContext();
    video.play();
  }

  function renderSteps() {
    if (!setPlayedSteps) return;
    return content.steps.map((step, i) => {
      step.index = i;
      const played = playedSteps.includes(i);
      return (
        <InteractiveStep
          index={i}
          key={i}
          step={step}
          setPlayedSteps={setPlayedSteps}
          playedSteps={playedSteps}
          played={played}
          playMedia={playStepVideo}
          introVideoFinished={introVideoFinished}
          activeStep={activeStep}
          colorPicker={colorPicker}
          muted={muted}
          introAudioFinished={introAudioFinished}
        />
      );
    });
  }

  function handleReplay() {
    // TrackEvent(ga4, `replay`, 'click', 'user_action');

    if (activeStep) {
      const video = activeStep.video.videoElement;
      video.pause();
      video.currentTime = 0;
      const audio = activeStep.audio?.audioElement;
      if (audio){
        audio.pause();
        audio.currentTime = 0;
      }
    }
  }

  function renderContent() {
    if (!mobileLayout) {
      return (
        <div className={Styles.container}>
          <h1
            className={Styles.title}
            dangerouslySetInnerHTML={createMarkup(language[content.title])}
          />
          <div
            className={Styles.text}
            dangerouslySetInnerHTML={createMarkup(language[content.text])}
          />
          { !colorPicker &&
            <ReplayButton
              videoElement={module.content.video.videoElement}
              audioObject={module.content.voiceover}
              onClick={handleReplay}
            />}
          {module.content.disclaimer && (
            <div
              className={Styles.disclaimer}
              dangerouslySetInnerHTML={createMarkup(
                language[module.content.disclaimer]
              )}
            />
          )}
          <div
            className={clsx(Styles.stepContainer, colorPicker ? Styles.stepContainerColorPicker : '' )}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {!colorPicker && (
              <React.Fragment>
                <p className={Styles.stepCTA}>
                  {language[module.content.instructions]}
                </p>
              </React.Fragment>
            )}
            <div
              className={
                colorPicker ? Styles.colorPickerStepWrapper : Styles.stepWrapper
              }
            >
              {renderSteps()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={Styles.mobileContainer}>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(language[content.title])}
        />
        <div 
          className={Styles.text}
          dangerouslySetInnerHTML={createMarkup(language[content.text])}
        />
        { !colorPicker &&
          <ReplayButton
            videoElement={module.content.video.videoElement}
            audioObject={module.content.voiceover}
            onClick={handleReplay}
          />
        }
    
        <div className={clsx(Styles.bottomContainer, colorPicker ? Styles.colorPickerBottomContainer : '')}>
          <div
            className={
              colorPicker ? Styles.colorPickerStepWrapper : Styles.stepWrapper
            }
          >
            {renderSteps()}
          </div>
          {module.content.disclaimer && (
          <div
            className={Styles.disclaimer}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}
        </div>
      </div>
    );
  }

  

  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        "InteractiveModule"
      )}
    >
      {renderContent()}
    </BaseModule>
  );
}
