import React, {useContext} from 'react';
import { css } from '../../utils';
import Styles from './navBurger.module.scss';
import Context from '../../context/Context';

const NavBurger = (props) => {
	const { onClick, onKeyDown, outline, active } = props;
	const context = useContext(Context);
	const { siteID, theme, overrideTheme } = context;
	// console.log("THEME!!!!",theme)

	const onMenuClick = () => {
		// setActive(active ? false : true);
		onClick();
	};

	let _activeClass = active ? 'open' : 'closed';

	return siteID ? (
		<div
			className={css(Styles.component, Styles[_activeClass], outline && `no-focus-outline`)}
			id='navburger'
			onClick={onMenuClick}
			tabIndex={0}
			onKeyDown={onKeyDown}
		>
			<div className={`${Styles.wrapper}`}>
				<div className={`${Styles.line} ${overrideTheme ? Styles.light : theme ? Styles[theme] : Styles.light} ${Styles[_activeClass]}`} />
				<div className={`${Styles.line} ${overrideTheme ? Styles.light : theme ? Styles[theme] : Styles.light} ${Styles[_activeClass]}`} />
				<div className={`${Styles.line} ${overrideTheme ? Styles.light : theme ? Styles[theme] : Styles.light} ${Styles[_activeClass]}`} />
			</div>
		</div>
	) : null;
};

export default NavBurger;
