import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import { AnimatePresence, motion, AnimateSharedLayout } from 'framer-motion';
import BaseModule from '../BaseModule';
import { createMarkup } from '../../../utils';
import ReplayButton from '../../ReplayButton';
import Context from '../../../context/Context';
import Styles from './ToggleModule.module.scss';

// png sequence animation
let seqFrames = [];
const SeqAnimation = ({ productFrames, reset }) => {
  const canvasRef = useRef();
  const [currentFrame, setCurrentFrame] = useState(0);
  const [shouldPlay, setShouldPlay] = useState(false);

  // get filename
  const getFrame = index =>
    `${process.env.PUBLIC_URL}${productFrames.path}/frame_${index
      .toString()
      .padStart(5, '0')}.png`;

  useLayoutEffect(() => {
    if (shouldPlay) {
      // fps - 30
      let animateID = setInterval(() => {
        setCurrentFrame(frame => frame + 1);
      }, 1000 / 30);
      return () => {
        setCurrentFrame(0);
        clearInterval(animateID);
      };
    }
  }, [shouldPlay]);

  useEffect(() => {
    if (shouldPlay) {
      // play once
      if (currentFrame < productFrames.count) {
        const context = canvasRef.current.getContext('2d');
        context.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        if (seqFrames.length > 0) {
          context.drawImage(seqFrames[currentFrame], 0, 0);
        }
      } else {
        setShouldPlay(false);
      }
    }
  }, [currentFrame, shouldPlay]);

  useEffect(() => {
    // load images
    let loaded = 0;
    for (let i = 0; i < productFrames.count; i++) {
      const img = new Image();
      img.src = getFrame(i);
      seqFrames[i] = img;
      seqFrames[i].onload = () => {
        loaded++;
        if (loaded >= productFrames.count) {
          // console.log('seqFrames loaded!');
          setShouldPlay(true);
        }
      };
    }
  }, []);

  useEffect(() => {
    // replay animation after switching modes
    setShouldPlay(true);
    setCurrentFrame(0);
  }, [reset]);

  return (
    <canvas
      ref={canvasRef}
      className={Styles.productCanvas}
      width="1200"
      height="1200"
    />
  );
};

// background images
let toggleBgImgs = [];
const ToggleModule = props => {
  const { module, variant, introAudioFinished, setIntroAudioFinished } = props;
  const context = useContext(Context);
  const {
    language,
    languageID,
    setTheme,
    setForceDefaultCursor,
    muted,
    theme,
  } = context;
  const replaceCopyTitleNotifier = '';
  const replaceCopyBodyNotifier = '';
  const { content } = module;
  const { modes, productFrames, track, tracks } = content;
  const [mode, setMode] = useState(0);
  const [trackLoaded, setTrackLoaded] = useState(false);
  const [playTime, setPlayTime] = useState(0);
  const [modeTracks, setModeTracks] = useState([]);
  const audioRef = useRef();

  // change theme based on mode settings
  useEffect(() => {
    setTheme(modes[mode].theme);
  }, [mode, theme]);

  const trackVolume = 0.5;
  const fadeInAudio = audio => {
    setTimeout(() => {
      if (audio.volume < trackVolume) {
        audio.volume += trackVolume * 0.01;
        fadeInAudio(audio);
      }
    }, 10);
  };

  useEffect(() => {
    // load tracks locally and add evt handlers
    const cloneTracks = tracks.map(item => {
      const el = item.audio.audioElement;
      if (el) {
        el.ontimeupdate = evt => setPlayTime(evt.target.currentTime);
        el.volume = 0;
      }
      return el;
    });
    if (cloneTracks.length > 0) {
      setModeTracks(cloneTracks);
      setTrackLoaded(true);
    }

    // load backgroundimages
    for (let i = 0; i < 3; i++) {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${modes[i].bgImage}`;
      toggleBgImgs[i] = img;
    }
  }, []);

  // set initial track
  useEffect(() => {
    if (trackLoaded) {
      audioRef.current = modeTracks[mode];
    }
  }, [trackLoaded]);

  // handle mode switching
  useEffect(() => {
    if (trackLoaded && audioRef.current) {
      if (introAudioFinished) {
        audioRef.current.pause();
        audioRef.current.volume = 0;

        audioRef.current = modeTracks[mode];
        fadeInAudio(audioRef.current);
        audioRef.current.muted = muted;
        audioRef.current.play();
        audioRef.current.currentTime = playTime + 0.1;
      } else {
        audioRef.current = modeTracks[mode];
      }
    }
  }, [mode]);

  // handle mute and vo interactions
  useEffect(() => {
    if (trackLoaded && audioRef.current) {
      audioRef.current.muted = muted;
      if (!muted && introAudioFinished) {
        fadeInAudio(audioRef.current);
        audioRef.current.play();
        audioRef.current.currentTime = playTime;
      } else {
        setIntroAudioFinished(false);
        audioRef.current.pause();
        audioRef.current.volume = 0;
        audioRef.current.currentTime = 0;
      }
    }
  }, [introAudioFinished, muted]);

  // reset mouse cursor
  const handleMouseEnter = () => {
    setForceDefaultCursor(true);
  };
  const handleMouseLeave = () => {
    setForceDefaultCursor(false);
  };

  return (
    <BaseModule
      {...props}
      className={clsx(
        props.className,
        Styles.root,
        Styles[variant],
        'ToggleModule'
      )}
    >
      {toggleBgImgs.length > 0 && (
        <AnimatePresence>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            key={modes[mode].bgImage}
            alt={`bgImg-${mode}`}
            className={Styles.bgImg}
            src={toggleBgImgs[mode].src}
          />
        </AnimatePresence>
      )}
      <div className={Styles.container}>
        <div className={Styles.mainWrapper}>
          <div className={Styles.mainContent}>
            <div className={Styles.productWrapper}>
              <SeqAnimation reset={mode} productFrames={productFrames} />
            </div>
          </div>
        </div>
        <div
          className={Styles.modesWrapper}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={clsx(Styles.iconGroup, Styles[modes[mode].theme])}>
            <AnimateSharedLayout>
              {modes.map((m, idx) => (
                <div
                  key={m.bgImage}
                  className={Styles.iconCircle}
                  onClick={() => setMode(idx)}
                >
                  {mode === idx && (
                    <motion.div
                      key={mode}
                      layoutId="bgcircle"
                      className={clsx(
                        Styles.circleBg,
                        Styles[modes[mode].theme]
                      )}
                    ></motion.div>
                  )}
                  {mode === idx ? (
                    <ReactSVG
                      className={Styles.icon}
                      src={`${process.env.PUBLIC_URL}${modes[idx]['icon-active']}`}
                      alt={`icon-${mode}-${idx}`}
                    />
                  ) : (
                    <ReactSVG
                      className={Styles.icon}
                      src={`${process.env.PUBLIC_URL}${
                        modes[idx][`icon-nonactive-${modes[mode].theme}`]
                      }`}
                      alt={`icon-${mode}-${idx}`}
                    />
                  )}
                </div>
              ))}
            </AnimateSharedLayout>
          </div>
          <div className={Styles.textGroup}>
            <div
              className={Styles.modeText}
              onClick={() => setMode(0)}
              dangerouslySetInnerHTML={createMarkup(
                language['FitPro_Modes_Toggle_Label1']
              )}
            />
            <div
              className={Styles.modeText}
              onClick={() => setMode(1)}
              dangerouslySetInnerHTML={createMarkup(
                language['FitPro_Modes_Toggle_Label2']
              )}
            />
            <div
              className={Styles.modeText}
              onClick={() => setMode(2)}
              dangerouslySetInnerHTML={createMarkup(
                language['FitPro_Modes_Toggle_Label3']
              )}
            />
          </div>
        </div>
        <aside className={Styles.soundtrack}>
          <div>
            <b>{language['FitPro_Modes_Toggle_MusicLabel']}</b>
            <p>{track.name}</p>
            <p>{track.artist}</p>
          </div>
          <img
            src={process.env.PUBLIC_URL + track.albumCover}
            alt="album-cover"
          />
        </aside>
        <h1
          className={Styles.title}
          dangerouslySetInnerHTML={createMarkup(
            language[module.content.title]
              ? language[module.content.title]
              : replaceCopyTitleNotifier
          )}
        />
        <div
          className={`${Styles.text} ${
            languageID === 'JA_JP' ? Styles.left : null
          }`}
        >
          <div
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.text]
                ? language[module.content.text]
                : replaceCopyBodyNotifier
            )}
          />
          {module.content.textCTA && (
            <a
              className={clsx(
                'ctaButton',
                Styles.textCTA,
                module.id === 'summary' ? 'outroCta' : ''
              )}
              href={module.content.textCTA.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language[module.content.textCTA.label]
                ? language[module.content.textCTA.label]
                : replaceCopyBodyNotifier}
            </a>
          )}
        </div>

        {variant === 'leftAlign' && (
          <ReplayButton
            // videoElement={module.content.video.videoElement}
            audioObject={module.content.voiceover}
            attachToGlobalCanAdvance
          />
        )}
        {module.content.disclaimer && (
          <div
            className={Styles.disclaimer}
            dangerouslySetInnerHTML={createMarkup(
              language[module.content.disclaimer]
            )}
          />
        )}
      </div>
    </BaseModule>
  );
};

export default ToggleModule;
