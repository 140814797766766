const copy = {
  Global_UI_Next: '下一页',
  Global_UI_Begin: '开始',
  Global_UI_Back: '返回',
  Global_UI_Settings: '设置',
  Global_UI_Signout: '退出登录',
  Global_UI_NextChapter: '下一章',
  Global_UI_PercentageComplete: '<<number>>%  已完成',
  Global_UI_Replay: '重新播放',
  Global_Languages_Select: '选择你的语言：',
  Global_Field_EnterEmail: '输入电子邮件地址',
  Global_Field_CreatePassword: '创建密码',
  Global_Field_Password: '密码',
  Global_Field_Password_Forgot: '忘记密码',
  Global_Password_Subtitle:
    '密码的长度至少应为 8 个字符，需包含大写+小写字母、数字和符号。',
  Global_Password_Change: '更改',
  Global_Password_Reset_Headline: '重设密码',
  Global_Password_Reset_Subtitle: '输入电子邮件以接收有关如何重设密码的说明。',
  Global_Password_Reset_Confirm: '已发送！请查看你的电子邮件。',
  Global_Password_ChangeConfirm: '密码已更新',
  Global_Password_Show: '显示',
  Global_Password_Hide: '隐藏',
  Global_Details_Title: '还需要一些详细信息',
  Global_Details_Field_FirstName: '名',
  Global_Details_Field_LastName: '姓',
  Global_Details_Field_EmployeeID: '员工 ID (可选)',
  Global_Details_Field_BusinessRole: '业务角色',
  Global_Details_Field_BusinessRole1: '管理人员',
  Global_Details_Field_BusinessRole2: '销售人员',
  Global_Details_Field_BusinessRole3: '培训师',
  Global_Details_Field_CompanyName: '公司名称：',
  Global_Details_Field_Country: '国家或地区',
  Global_Field_Password_Error: '你输入的密码不正确。请再试一次。',
  Global_Field_Email_Error: '你输入的电子邮件地址无效。请再试一次。',
  Global_Field_Details_Error: '请填写所有必填字段。',
  Global_Greeting: '<<name>>，你好：',
  Global_CompletionMessage: '你已完成 <<number>>%。',
  Global_Chapter: '第 <<number>> 章',
  Global_Chapter_Subtitle: '第 <<number>> 章，共 <<total>> 章',
  Global_Intro: '简介',
  Global_ChapterTitle_Sound: '音质',
  Global_ChapterTitle_ANC: '主动降噪 (ANC)',
  Global_ChapterTitle_Transparency: '通透模式',
  Global_ChapterTitle_Design: '设计',
  Global_ChapterTitle_Compatibility: '兼容性',
  Global_ChapterTitle_Controls: '控制按钮',
  Global_ChapterTitle_Battery: '电池',
  Global_Menu: '菜单',
  Intro_Welcome_Label: '体验',
  Intro_Welcome_ChapterText:
    '欢迎来到 Beats Studio Buds 体验。点按或轻点各项以进行探索。',
  CR_TRNR_Intro_Sorry: '非常抱歉，出错了。请联系主管以获得一个新的访问链接。',
  Intro_Intro_Subtitle:
    '全新推出 Beats Studio Buds。真无线降噪耳塞，录音棚级别的音质。点按或轻点各项以进行探索。',
  Sound_Intro_Title: '音质',
  Sound_Intro_VOSubtitle: '先进工艺实现了强劲均衡的音效。',
  Sound_ChapterText1:
    '出色音质源于<em>个性化贴合体验和舒适的声学密封性能。</em>',
  Sound_FindYourFit_Title: '选择合适尺寸，用心感受音乐',
  Sound_FindYourFit_Subtitle:
    '三种硅胶耳塞尺寸，带来个性化的贴合体验和理想的隔音效果，营造令人沉醉的聆听体验。',
  Sound_FindYourFit_Medium: '中号 (默认)',
  Sound_FindYourFit_Small: '小号',
  Sound_FindYourFit_Large: '大号',
  Sound_ChapterText2:
    'Beats Studio Buds 采用 <em>完全定制的声学平台</em>，小巧的机身迸发出<em>强劲均衡的音效。</em>',
  Sound_DrivenByPremiumSound_Title: '出色音质，启发灵感',
  Sound_DrivenByPremiumSound_Subtitle:
    '定制的声学平台采用专有的双元件振膜驱动单元，呈现清晰均衡的音效。',
  Sound_MoreMusicLessDistortion_Title: '清澈音质，低失真。',
  Sound_MoreMusicLessDistortion_Subtitle:
    '驱动单元振膜采用柔性材料，可以降低振动失真，从而在整个频谱中产生清澈、准确的声音，尤其是在低音范围内。',
  Sound_MoreMusicLessDistortion_Diaphragm: '振膜',
  Sound_FineTunedAudio_Title: '精湛的音频调制',
  Sound_FineTunedAudio_Subtitle:
    '带有播放校正功能的数字处理器能够实时响应，以确保外部声音不会影响音乐的清晰度或情感。',
  Sound_ChapterText3:
    '通话声音清澈，<em>提供高质量的通话性能，更高的语音清晰度。</em> ',
  Sound_SpeakingOfSound_Title: '高品质通话效果 ',
  Sound_SpeakingOfSound_Subtitle:
    '特别设计的端口可将麦克风与外界风声噪声隔离开来，而双波束成形麦克风能够锁定你的声音，以提高声音清晰度。',
  Sound_Summary_Title: '设计，让听者感受音乐人想传达的音乐',
  Sound_Summary1_ThreeEartip:
    '三种尺寸的耳塞，带来量身定制的贴合体验和出色音质。',
  Sound_Summary2_CustomAcoustic: '定制的声学平台提供高品质、强劲的音效。',
  Sound_Summary3_FlexibleDriverDiaphragm:
    '灵活的驱动单元振膜可产生清澈、准确的声音。',
  Sound_Summary4_FinetunedAudio: '精湛的音频调制，充分保留音乐的清晰度和情感。',
  Sound_Summary5_SpeakingOfSound:
    '双波束成形麦克风可以锁定你的声音，提供高质量的通话性能。',
  ANC_Intro_TITLE: '主动降噪 ',
  ANC_Intro_VOSubtitle: '通过主动降噪，时刻保持专注，排除干扰。',
  ANC_ChapterText1:
    '<em>佩戴舒适稳固，隔音效果理想，</em>有助于阻挡外界噪声，实现真正的沉浸式聆听体验。',
  ANC_Seal_Title: '隔音效果',
  ANC_Seal_Subtitle:
    '有三种耳塞尺寸可供选择，带来量身定制的佩戴舒适度，屏蔽不必要的噪音，营造理想的隔音效果。',
  ANC_ChapterText2:
    '搭载自适应技术的主动降噪，<em>可根据你的环境进行调节</em>，而播放校正功能<em>则保留了音乐的清晰度和情感。</em>',
  ANC_ANCThatAdapts_Title: '适应性主动降噪 (ANC)',
  ANC_ANCThatAdapts_Subtitle:
    '精确调整的滤波器动态进行调节，实时滤除多余噪音并优化降噪效果。',
  ANC_ANCThatAdapts_AdaptiveANC: '自适应主动降噪',
  ANC_Playback_Title: '播放校正',
  ANC_Playback_Subtitle:
    '应用降噪时，播放校正功能会以每秒 48000 次的速度将音频文件映射回原始文件，确保 ANC 不会干扰你的音乐。',
  ANC_Playback_ANCProcess: '主动降噪过程',
  ANC_Playback_PlaybackCorrection: '播放校正',
  ANC_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。 ',
  ANC_UseCases_Studying: '学习',
  ANC_UseCases_Studying_Subtitle: '排除干扰，让你时刻专注于工作',
  ANC_UseCases_WorkingOut: '健身',
  ANC_UseCases_WorkingOut_Subtitle: '让自己沉浸在健身过程中，不受任何干扰',
  ANC_UseCases_Airplane: '乘坐飞机',
  ANC_UseCases_Airplane_Subtitle: '隔绝乘客声音或发动机噪声，更好地享受飞行',
  ANC_UseCases_Commute: '通勤',
  ANC_UseCases_Commute_Subtitle: '通勤时减少城市的背景噪音',
  ANC_Summary_Title: '通过主动降噪，时刻保持专注。',
  ANC_Summary1_ImmerseSound:
    '身临其境般的音质源自舒适稳固的佩戴和理想的隔音效果。',
  ANC_Summary2_AdaptiveTechnology: '自适应技术会根据你的环境不断进行调整。',
  ANC_Summary3_PlaybackCorrection: '播放校正功能会保留声音的清晰度。',
  ANC_Summary4_UseCases:
    '在学习、健身、乘坐飞机旅行或通勤时使用主动降噪来时刻保持专注。',
  Transparency_Intro_Title: '通透模式 ',
  Transparency_Intro_VOSubtitle: '通透模式让你随时留意周围环境。',
  Transparency_ChapterText1:
    '需要听到周围声音时，<em>切换至通透模式即可。</em>',
  Transparency_TransparencyMode_Title: '通透模式 ',
  Transparency_TransparencyMode_Subtitle:
    '通透模式会对传回的周围声音和你聆听的音乐进行混音，营造出自然的“开放式”聆听体验。',
  Transparency_TransparencyMode_PressHold: '长按',
  Transparency_ChapterText2: '<em>外置麦克风</em>有助于听清外界噪声。',
  Transparency_FeedForwardMic_Title: '前馈麦克风',
  Transparency_FeedForwardMic_Subtitle:
    '每个耳塞上都有一个前馈麦克风，可以检测环境中的外界噪音。',
  Transparency_FeedForwardMic_FeedForwardMic: '前馈麦克风',
  Transparency_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。 ',
  Transparency_UseCases_Office: '办公室',
  Transparency_UseCases_Office_Subtitle:
    '在工作中保持协作，既能聆听音乐，又能听清同事的声音。',
  Transparency_UseCases_Travel: '旅行',
  Transparency_UseCases_Travel_Subtitle:
    '在机场登机口或火车站台听到重要的通告。',
  Transparency_UseCases_Street: '街头',
  Transparency_UseCases_Street_Subtitle:
    '随时注意周围的交通状况和车水马龙的十字路口。',
  Transparency_Summary_Title: '通透模式让你随时留意周围环境。',
  Transparency_Summary1_MixTheSounds: '对传回的周围声音和你聆听的音乐进行混音',
  Transparency_Summary2_FeedForward: '前馈麦克风可以检测环境中的外界噪音。',
  Transparency_Summary3_HearYourSurroundings:
    '在办公室、旅行时或在街头可以听到周围环境的声音。',
  Design_Intro_Title: '设计',
  Design_Intro_VOSubtitle: 'Beats Studio Buds 的设计让你畅享真无线带来的自由。',
  Design_ChapterText1: '无线、小巧的设计，<em>无缝融入你的生活。</em>',
  Design_WirelessFreedom_Title: '无线自由 ',
  Design_WirelessFreedom_Subtitle: '真无线耳塞，让你尽享无线自由。 ',
  Design_PocketSizedPortability_Titles: '袖珍尺寸，轻松便携',
  Design_PocketSizedPortability_Subtitles:
    '纤薄、轻巧的充电盒提供了卓越的便携性，可放入口袋。',
  Design_WaterAndSweatResistant_Title: '抗汗抗水耳塞*  ',
  Design_WaterAndSweatResistant_Subtitle:
    'IPX-4 级抗汗抗水耳塞，助你从容应对各种环境。',
  Design_WaterAndSweatResistant_Disclaimer:
    '* Beats Studio Buds 可抗汗抗水，适合各种非水上运动和活动。',
  Design_ChapterText2:
    'Beats Studio Buds 由多种不同用户进行了测试，确保<em>佩戴舒适、全方位贴合。</em>',
  Design_Comfort_Title: '精心设计，佩戴舒适',
  Design_Comfort_Subtitle:
    '软硅胶耳塞根据耳朵的解剖结构而设计，提供舒适、稳固的佩戴体验，完全不用担心掉落。',
  Design_Comfort_Tragus: '耳屏',
  Design_Comfort_Antitragus: '对耳屏',
  Design_SizeMatters_Title: '小巧设计',
  Design_SizeMatters_Subtitle:
    '时尚精巧的入耳式设计，相比当今大多数无线耳塞，明显重量更轻，体积更小。',
  Design_SizeMatters_BeatsStudio: 'BEATS STUDIO BUDS',
  Design_SizeMatters_BeatsStudio_10_1G: '10.1G',
  Design_SizeMatters_Bose: 'BOSE QC 耳塞',
  Design_SizeMatters_Bose_18_7G: '18.7G',
  Design_SizeMatters_Sony: 'Sony WF-1000XM3',
  Design_SizeMatters_Sony_16_5G: '16.5G',
  Design_SizeMatters_Samsung: 'SAMSUNG GB PRO',
  Design_SizeMatters_Samsung_12_5G: '12.5G',
  Design_ChapterText3:
    '耳塞被置于一个可以全天随身携带的便捷充电盒中，<em>不易丢失，还会受到保护</em>。',
  Design_PerfectMatch_Title: '磁力定位',
  Design_PerfectMatch_Subtitle:
    '充电盒内的磁力是定向的，这样每次耳塞都会正确吸附到位。',
  Design_Contact_Title: '精确接触',
  Design_Contact_Subtitle:
    '充电盒中的弹簧针脚使用弹性叶片弹簧，使耳塞和充电盒精确接触，确保耳塞正确就位以进行充电。',
  Design_Contact_PogoPin: '弹簧针脚',
  Design_FindYourMatch_Title: '选择心仪颜色 ',
  Design_FindYourMatch_Subtitle: '同色系充电盒',
  Design_FindYourMatch_White: '白色',
  Design_FindYourMatch_Black: '黑色',
  Design_FindYourMatch_BeatsRed: 'Beats 经典红色',
  Design_SustainablePackaging_Title: '可持续包装',
  Design_SustainablePackaging_Subtitle:
    'Beats Studio Buds 的包装采用 92% 的植物基础混合原料，来自可循环的纤维和可持续的森林。',
  Design_Summary_Title: '畅享真无线带来的自由',
  Design_Summary1_WirelessFreedom: '真无线耳塞，让你尽享无线自由。 ',
  Design_Summary2_PocketSizedPortability: '轻巧的充电盒可轻松放入口袋。',
  Design_Summary3_WaterAndSweatResistant: 'IPX-4 级抗汗抗水耳塞。',
  Design_Summary4_Comfort: '时尚、小巧的耳塞，佩戴舒适稳固。',
  Design_Summary5_SizeMatters:
    '入耳式设计明显比大多数无线耳塞重量更轻，体积更小。',
  Design_Summary6_PerfectMatch: '充电盒内的磁力确保耳塞每次都吸附到位。',
  Design_Summary7_Contact: '充电盒内的弹簧针脚有助于耳塞正确安放，以便充电。',
  Design_Summary8_FindYourMatch:
    '同色系充电盒，有黑色、白色和 Beats 红色可选。',
  Compatibility_Intro_Title: '兼容性',
  Compatibility_Intro_VOSubtitle:
    'Beats Studio Buds 同时兼容 Apple 和 Android 设备。',
  Compatibility_ChapterText1: '轻松与 <em>Apple 和 Android 设备搭配使用。</em>',
  Compatibility_OneTouch_Title: '一键配对 ',
  Compatibility_OneTouch_Subtitle:
    '利用简单的一键配对，通过蓝牙轻松连接 Apple<sup>1</sup> 或 Android<sup>2</sup> 设备。 ',
  Compatibility_OneTouch_Disclaimer:
    '1 一键配对需要使用 iOS 14.6 或更高版本<br/>2 快速配对需要启用位置功能。要使用全部功能，设备上需要运行 Android 6.0 或更新版本，启用 Google Play 服务并登录 Google 帐户。',
  Compatibility_VoiceAssistant_Title: '语音助手 ',
  Compatibility_VoiceAssistant_Subtitle:
    'Beats Studio Buds 与你首选的语音助手兼容，可通过自定的“长按”功能进行激活。',
  Compatibility_LocateDevice_Title: '定位设备 ',
  Compatibility_LocateDevice_Subtitle:
    '使用 iOS 的“查找”功能或 Android 的“查找我的设备”功能可以轻松查找定位你的耳塞。 ',
  Compatibility_USBCCharging_Title: 'USB-C 充电 ',
  Compatibility_USBCCharging_Subtitle:
    'USB-C 充电接口兼容 Apple 和 Android 设备，因此可以随时随地充电。 ',
  Compatibility_ManageDevice_Title: '管理设备 ',
  Compatibility_ManageDevice_Subtitle:
    '通过 Apple 设备或 Android 版 Beats App 管理各种功能，如“长按”自定、聆听模式、重命名产品和固件更新。',
  Compatibility_Summary_Title: '与 Apple 和 Android 设备兼容。',
  Compatibility_Summary1_OneTouch: 'iOS 和 Android 均可以一键配对。',
  Compatibility_Summary2_VoiceAssistant: '激活首选语音助手以管理通话、音乐等。',
  Compatibility_Summary3_LocateDevice: '使用你的设备轻松定位你的耳塞。',
  Compatibility_Summary4_USBCCharging: 'USB-C 充电接口支持通用充电。',
  Compatibility_Summary5_ManageDevice:
    '通过 iOS 或 Android 版 Beats App 管理设备。',
  Control_Intro_Title: '控制按钮',
  Control_Intro_VOSubtitle: '通过 Beats Studio Buds 控制声音和一天的节奏。',
  Control_ChapterText1: '<em>轻松使用控制键</em>来管理声音。',
  Control_OnEarControls_Title: '耳塞上集成控制按键 ',
  Control_OnEarControls_Subtitle:
    '贴耳式控制键可用于管理通话和音乐，切换聆听模式以及激活语音助手。<sup>*</sup>',
  Control_OnEarControls_Disclaimer:
    '* 长按功能自定需要使用运行 iOS 14.6 或更高版本的 iPhone，或安装了最新版 Beats app 的 Android 设备。',
  Control_OnEarControls_Instruction: '点按或轻点每个控制键以继续。',
  Control_OnEarControls_PlayPause: '按下可播放、暂停和接听/挂断电话 ',
  Control_OnEarControls_Skip: '连按两下按钮可跳过曲目 ',
  Control_OnEarControls_Previous: '连按三下按钮可播放上一曲 ',
  Control_OnEarControls_ScanForward: '连按两下并按住按钮可快进 ',
  Control_OnEarControls_ScanBackward: '连按三下并按住按钮可快退 ',
  Control_OnEarControls_ToggleModes:
    '长按可切换和关闭聆听模式，也可通过自定方式激活语音助手<sup>*</sup>',
  Control_PickASide_Title: '选择一侧',
  Control_PickASide_Subtitle:
    '使用 iOS 或 Android 版 Beats App 对每个耳塞上的“长按”按钮功能进行自定以激活主动降噪或激活语音助手。<sup>*</sup>',
  Control_PickASide_Disclaimer:
    '* 长按功能自定需要使用运行 iOS 14.6 或更高版本的 iPhone，或安装了最新版 Beats app 的 Android 设备。',
  Control_SayItWithSiri_Title: '用 Siri 实现语音控制 ',
  Control_SayItWithSiri_Subtitle:
    '无需双手，即可随时通过 Siri 控制音乐、管理通话、调节音量和更多操作。<sup>*</sup>',
  Control_SayItWithSiri_Disclaimer:
    '* Siri 可能仅支持部分语言或地区，并且功能可能因地区而异。需要接入互联网。可能需要支付蜂窝数据费用。 ',
  Control_SayItWithSiri_SiriCommand1: '“嘿 Siri，播放我的学习歌单。”',
  Control_SayItWithSiri_SiriCommand2: '“嘿 Siri，显示附近由女性运营的企业。”',
  Control_SayItWithSiri_SiriCommand3: '“嘿 Siri，计时 10 分钟。”',
  Control_Summary_Title: '使用贴耳式控制键管理声音。',
  Control_Summary1_OnEarControls: '你可以通过贴耳式控制键管理通话和音乐。',
  Control_Summary2_PickASide: '自定耳塞左右两侧的“长按”功能。',
  Control_Summary3_SayItWithSiri: '无需双手，随时可以访问 Siri.',
  Battery_Intro_Title: '电池',
  Battery_Intro_VOSubtitle: '电池续航长达一整天，让你一天都充满活力。',
  Battery_ChapterText1: '<em>电量满足一天所需，</em>助力你不断前进。',
  Battery_FastFuel_Title: '长达 8 小时',
  Battery_FastFuel_Subtitle:
    '配备高效芯片，每个耳塞可提供长达 8 小时的收听时间。<sup>*</sup>',
  Battery_FastFuel_Disclaimer:
    '* 电池续航时间取决于设备设置、环境、使用情况和诸多其他因素',
  Battery_20Hours_Title: '最长可达 24 小时',
  Battery_20Hours_Subtitle:
    '袖珍充电盒提供了两次额外充电，累计播放时间长达 24 小时。<sup>*</sup>',
  Battery_20Hours_Disclaimer:
    '* 电池续航时间取决于设备设置、环境、使用情况和诸多其他因素',
  Battery_FuelYourDay_Title: '持久续航，满足全天所需',
  Battery_FuelYourDay_Subtitle:
    '在电池电量不足时，采用 Fast Fuel 闪充技术充电 5 分钟，即可持续播放 1 小时。<sup>*</sup>',
  Battery_FuelYourDay_Disclaimer:
    '* 电池续航时间取决于设备设置、环境、使用情况和诸多其他因素',
  Battery_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。 ',
  Battery_UseCases_Listen: '聆听',
  Battery_UseCases_Listen_Subtitle:
    '可以收听 12 周你最喜欢的广播节目。<sup>*</sup>',
  Battery_UseCases_Listen_Disclaimer: '*基于 2 小时收听时长',
  Battery_UseCases_Meditate: '冥想',
  Battery_UseCases_Meditate_Subtitle:
    '有 32 种雨声冥想，帮你放松身心。<sup>*</sup>',
  Battery_UseCases_Meditate_Disclaimer: '*基于 45 分钟时长',
  Battery_UseCases_Game: '游戏',
  Battery_UseCases_Game_Subtitle: '玩 24 次你最喜欢的游戏。<sup>*</sup>',
  Battery_UseCases_Game_Disclaimer: '*基于 1 小时不需要使用麦克风的游戏时长',
  Battery_UseCases_Commute: '通勤',
  Battery_UseCases_Commute_Subtitle: '在 24 天通勤中使用。<sup>*</sup>',
  Battery_UseCases_Commute_Disclaimer:
    '* 基于 1 小时通勤时间。电池续航时间取决于设备设置、环境、使用情况及诸多其他因素。',
  Battery_WhatsInTheBox_Title: '包装内容',
  Battery_WhatsInTheBox_Subtitle:
    'Beats Studio Buds 配有一个袖珍充电盒、一个通用 USB-C 充电接口，并有三种耳塞尺寸可供选择。',
  Batter_Summary_Title: '电池续航长达一整天。',
  Battery_Summary1_PowerYourDay: '耳塞提供长达 8 小时的播放时间。',
  Battery_Summary2_UpTo24: '累计播放最长可达 24 小时。',
  Battery_Summary3_FastFuel: 'Fast Fuel 闪电技术充电可提供 1 小时的播放时间。',
  Battery_Summary4_UseCases: '电池续航长达一整天，尽情听音乐、冥想和打游戏。',
  Battery_Summary5_WhatsInTheBox:
    '包装盒内附带一个袖珍充电盒、一个 USB-C 充电接口和三种尺寸的耳塞。',
  Menu_Sound_Module1: '音质简介',
  Menu_Sound_Module2: '出色音质始于紧密贴合。',
  Menu_Sound_Module3: '三个耳塞',
  Menu_Sound_Module4: '强劲均衡的音效',
  Menu_Sound_Module5: '定制声学平台',
  Menu_Sound_Module6: '清澈音质，低失真。 ',
  Menu_Sound_Module7: '精湛的音频调制',
  Menu_Sound_Module8: '电话来电 ',
  Menu_Sound_Module9: '高品质通话效果',
  Menu_Sound_Module10: '声音摘要',
  Menu_ANC_Module1: '主动降噪简介',
  Menu_ANC_Module2: '隔音效果',
  Menu_ANC_Module3: '源于密封性能',
  Menu_ANC_Module4: '技术',
  Menu_ANC_Module5: '适应性主动降噪 (ANC)',
  Menu_ANC_Module6: '播放校正',
  Menu_ANC_Module7: '主动降噪用例',
  Menu_ANC_Module8: '主动降噪摘要',
  Menu_Transparency_Module1: '通透模式简介',
  Menu_Transparency_Module2: '听到周围声音',
  Menu_Transparency_Module3: '通透模式 ',
  Menu_Transparency_Module4: '外部麦克风',
  Menu_Transparency_Module5: '前馈麦克风',
  Menu_Transparency_Module6: '通透模式用例',
  Menu_Transparency_Module7: '通透模式摘要',
  Menu_Design_Module1: '设计简介',
  Menu_Design_Module2: '无线连接，小巧设计',
  Menu_Design_Module3: '无线自由',
  Menu_Design_Module4: '袖珍尺寸，轻松便携',
  Menu_Design_Module5: '抗汗抗水',
  Menu_Design_Module6: '佩戴舒适，全方位贴合',
  Menu_Design_Module7: '精心设计，佩戴舒适',
  Menu_Design_Module8: '小巧设计',
  Menu_Design_Module9: '方便的充电盒',
  Menu_Design_Module10: '磁力定位',
  Menu_Design_Module11: '精确接触',
  Menu_Design_Module12: '颜色',
  Menu_Design_Module13: '设计摘要',
  Menu_Compatibility_Module1: '兼容性介绍',
  Menu_Compatibility_Module2: '与 Apple 和 Android 兼容',
  Menu_Compatibility_Module3: '一键配对',
  Menu_Compatibility_Module4: '语音助手',
  Menu_Compatibility_Module5: '定位设备 ',
  Menu_Compatibility_Module6: 'USB-C 充电 ',
  Menu_Compatibility_Module7: '管理设备',
  Menu_Compatibility_Module8: '兼容性摘要',
  Menu_Control_Module1: '控制简介',
  Menu_Control_Module2: '轻松访问',
  Menu_Control_Module3: '耳塞上的控制键',
  Menu_Control_Module4: '选择一侧',
  Menu_Control_Module5: '用 SIRI 实现语音控制 ',
  Menu_Control_Module6: '控制摘要',
  Menu_Battery_Module1: '电池简介',
  Menu_Battery_Module2: '电量满足一天所需',
  Menu_Battery_Module3: '聆听时间长达 8 小时',
  Menu_Battery_Module4: '24 小时累计播放时间',
  Menu_Battery_Module5: 'FAST FUEL 闪充技术',
  Menu_Battery_Module6: '电池用例',
  Menu_Battery_Module7: '包装内容',
  Menu_Battery_Module8: '电池摘要',
  Outro_ChapterText1:
    '借助主动降噪、通透模式、出众音质和无线连接、袖珍设计，<em>Beats Studio Buds 可以无缝融入你的生活。</em>',
  IntroOutro_BeatsStudioBuds: 'Beats<br/>Studio Buds',
  Global_Country_ALBANIA: '阿尔巴尼亚',
  Global_Country_ALGERIA: '阿尔及利亚',
  Global_Country_ANDORRA: '安道尔',
  Global_Country_ANGOLA: '安哥拉',
  Global_Country_ARGENTINA: '阿根廷',
  Global_Country_ARMENIA: '亚美尼亚',
  Global_Country_ARUBA: '阿鲁巴',
  Global_Country_AUSTRALIA: '澳大利亚',
  Global_Country_AUSTRIA: '奥地利',
  Global_Country_AZERBAIJAN: '阿塞拜疆',
  Global_Country_BAHAMAS: '巴哈马',
  Global_Country_BAHRAIN: '巴林',
  Global_Country_BANGLADESH: '孟加拉国',
  Global_Country_BARBADOS: '巴巴多斯',
  Global_Country_BELARUS: '白俄罗斯',
  Global_Country_BELGIUM: '比利时',
  Global_Country_BOLIVIA: '玻利维亚',
  Global_Country_BOSNIAHERZRGOVINA: '波斯尼亚和黑塞哥维纳',
  Global_Country_BOTSWANA: '博茨瓦纳',
  Global_Country_BRAZIL: '巴西',
  Global_Country_BRUNEI: '文莱达鲁萨兰国',
  Global_Country_BULGARIA: '保加利亚',
  Global_Country_CAMBODIA: '柬埔寨',
  Global_Country_CAMEROON: '喀麦隆',
  Global_Country_CANADA: '加拿大',
  Global_Country_CAYMANISLANDS: '开曼群岛',
  Global_Country_CHILE: '智利',
  Global_Country_CHINA: '中国大陆',
  Global_Country_COLOMBIA: '哥伦比亚',
  Global_Country_COOKISLANDS: '库克群岛',
  Global_Country_COSTARICA: '哥斯达黎加',
  Global_Country_CROATIA: '克罗地亚',
  Global_Country_CYPRUS: '塞浦路斯',
  Global_Country_CZECHREPUBLIC: '捷克',
  Global_Country_DENMARK: '丹麦',
  Global_Country_DOMINICANREPUBLIC: '多米尼加共和国',
  Global_Country_ECUADOR: '厄瓜多尔',
  Global_Country_EGYPT: '埃及',
  Global_Country_ELSALVADOR: '萨尔瓦多',
  Global_Country_ESTONIA: '爱沙尼亚',
  Global_Country_FAROEISLANDS: '法罗群岛',
  Global_Country_FIJI: '斐济',
  Global_Country_FINLAND: '芬兰',
  Global_Country_FRANCE: '法国',
  Global_Country_FRENCHGUIANA: '法属圭亚那',
  Global_Country_FRENCHPOLYNESIA: '法属玻利尼西亚',
  Global_Country_GEORGIA: '格鲁吉亚',
  Global_Country_GERMANY: '德国',
  Global_Country_GHANA: '加纳',
  Global_Country_GIBRALTAR: '直布罗陀',
  Global_Country_GREECE: '希腊',
  Global_Country_GUADELOUPE: '瓜德罗普岛',
  Global_Country_GUAM: '关岛',
  Global_Country_GUATEMALA: '危地马拉',
  Global_Country_GUERNSEY: '根西岛',
  Global_Country_GUINEA: '几内亚',
  Global_Country_GUYANA: '圭亚那',
  Global_Country_HONDURAS: '洪都拉斯',
  Global_Country_HONGKONG: '香港',
  Global_Country_HUNGARY: '匈牙利',
  Global_Country_ICELAND: '冰岛',
  Global_Country_INDIA: '印度',
  Global_Country_INDONESIA: '印度尼西亚',
  Global_Country_IRAQ: '伊拉克',
  Global_Country_IRELAND: '爱尔兰',
  Global_Country_ISLEOFMAN: '马恩岛',
  Global_Country_ISRAEL: '以色列',
  Global_Country_ITALY: '意大利',
  Global_Country_IVORYCOAST: '科特迪瓦',
  Global_Country_JAPAN: '日本',
  Global_Country_JERSEY: '泽西岛',
  Global_Country_JORDAN: '约旦',
  Global_Country_KAZAKHSTAN: '哈萨克斯坦',
  Global_Country_KENYA: '肯尼亚',
  Global_Country_KOREA: '韩国',
  Global_Country_KUWAIT: '科威特',
  Global_Country_KYRGYZSTAN: '吉尔吉斯斯坦',
  Global_Country_LAOS: '老挝',
  Global_Country_LATVIA: '拉脱维亚',
  Global_Country_LEBANON: '黎巴嫩',
  Global_Country_LESOTHO: '莱索托',
  Global_Country_LITHUANIA: '立陶宛',
  Global_Country_LUXEMBOURG: '卢森堡',
  Global_Country_MACAU: '澳门',
  Global_Country_MACEDONIA: '马其顿',
  Global_Country_MADAGASCAR: '马达加斯加',
  Global_Country_MALAWI: '马拉维',
  Global_Country_MALAYSIA: '马来西亚',
  Global_Country_MALDIVES: '马尔代夫',
  Global_Country_MALTA: '马耳他',
  Global_Country_MARTINIQUE: '马提尼克',
  Global_Country_MAURITIUS: '毛里求斯',
  Global_Country_MAYOTTE: '马约特',
  Global_Country_MEXICO: '墨西哥',
  Global_Country_MOLDOVA: '摩尔多瓦',
  Global_Country_MONACO: '摩纳哥',
  Global_Country_MONTENEGRO: '黑山共和国',
  Global_Country_MOROCCO: '摩洛哥',
  Global_Country_MOZAMBIQUE: '莫桑比克',
  Global_Country_MYANMAR: '缅甸',
  Global_Country_NAMIBIA: '纳米比亚',
  Global_Country_NEPAL: '尼泊尔',
  Global_Country_NETHERLANDS: '荷兰',
  Global_Country_NEWCALEDONIA: '新喀里多尼亚',
  Global_Country_NEWZEALAND: '新西兰',
  Global_Country_NICARAGUA: '尼加拉瓜',
  Global_Country_NIGERIA: '尼日利亚',
  Global_Country_NORWAY: '挪威',
  Global_Country_OMAN: '阿曼',
  Global_Country_PAKISTAN: '巴基斯坦',
  Global_Country_PALESTINIANTERRITORY: '巴勒斯坦领土',
  Global_Country_PANAMA: '巴拿马',
  Global_Country_PARAGUAY: '巴拉圭',
  Global_Country_PERU: '秘鲁',
  Global_Country_PHILIPPINES: '菲律宾',
  Global_Country_POLAND: '波兰',
  Global_Country_PORTUGAL: '葡萄牙',
  Global_Country_PUERTORICO: '波多黎各',
  Global_Country_QATAR: '卡塔尔',
  Global_Country_REUNION: '留尼汪',
  Global_Country_ROMANIA: '罗马尼亚',
  Global_Country_RUSSIA: '俄罗斯',
  Global_Country_SAINTMARTIN: '圣马丁岛',
  Global_Country_SANMARINO: '圣马力诺',
  Global_Country_SAUDIARABIA: '沙特阿拉伯',
  Global_Country_SENEGAL: '塞内加尔',
  Global_Country_SERBIA: '塞尔维亚',
  Global_Country_SINGAPORE: '新加坡',
  Global_Country_SINTMAARTEN: '荷属圣马丁',
  Global_Country_SLOVAKIA: '斯洛伐克',
  Global_Country_SLOVENIA: '斯洛文尼亚',
  Global_Country_SOUTHAFRICA: '南非',
  Global_Country_SPAIN: '西班牙',
  Global_Country_SRILANKA: '斯里兰卡',
  Global_Country_SWEDEN: '瑞典',
  Global_Country_SWITZERLAND: '瑞士',
  Global_Country_TAIWAN: '台湾',
  Global_Country_TAJIKISTAN: '塔吉克斯坦',
  Global_Country_TANZANIA: '坦桑尼亚',
  Global_Country_THAILAND: '泰国',
  Global_Country_TRINIDADTOBAGO: '特立尼达和多巴哥',
  Global_Country_TUNISIA: '突尼斯',
  Global_Country_TURKEY: '土耳其',
  Global_Country_TURKMENISTAN: '土库曼斯坦',
  Global_Country_UGANDA: '乌干达',
  Global_Country_UKRAINE: '乌克兰',
  Global_Country_UAE: '阿联酋',
  Global_Country_UK: '英国',
  Global_Country_US: '美国',
  Global_Country_URUGUAY: '乌拉圭',
  Global_Country_UZBEKISTAN: '乌兹别克斯坦',
  Global_Country_VENEZUELA: '委内瑞拉',
  Global_Country_VIETNAM: '越南',
  Global_Country_ZAMBIA: '赞比亚',
  Global_Country_ZIMBABWE: '津巴布韦',
  Global_Menu_Confirm: '确认',
  Global_Menu_Help: '需要帮助？<u>联系我们</u>',
  Global_Menu_Restart: '重新开始体验',
  IntroOutro_TestYourKnowledge: '测试你的 Beats Studio Buds 知识。',
  IntroOutro_CTA: '参加测验',
  Global_Menu_Language: '语言',
  Global_Field_Location: '地点',
  Global_Password_Rules_Header: '密码必须包含',
  Global_Password_Rule_Length: '至少 8 个字符',
  Global_Password_Rule_Uppercase: '1个大写字母',
  Global_Password_Rule_Lowercase: '1 个小写字母',
  Global_Password_Rule_Number: '1 个数字',
  Global_Password_Rule_Special: '特殊符号 (!@#$%^&*)',
  Global_Field_Password_CreateError: '密码不满足最低标准。',

  Global_ProductSelector_Headline: '选择 Beats TRNR 培训：',
  Global_ProductSelector_Product1: 'Beats Studio Buds',
  Global_ProductSelector_Product2: 'Beats Fit Pro',
  Global_ProductSelector_CTA_1: '立即开始',
  Global_ProductSelector_CTA_2: '继续',
  Global_ProductSelector_CTA_3: '重新开始',
  Global_WelcomeInstructions_Headline:
    '欢迎参加 Beats TRNR, 通过培训成为 Beats 专家。',
  Global_WelcomeInstructions_Subhead: '请先了解培训前须知：',
  Global_WelcomeInstructions_Body1: '每次培训大约需要 15 分钟才能完成。',
  Global_WelcomeInstructions_Body2: '每次培训均需用到音频，请确保已开启声音。',
  Global_WelcomeInstructions_Body3:
    '在每次培训中，请切记完成每个部分才能进行下一步。',
  Global_WelcomeInstructions_Body4:
    '如果你需要离开，也不用担心。你可以随时从上次中断的地方继续。',
    Intro_Welcome_ReturningUser:
    '准备从上次中断的地方继续？点按或轻点以继续了解 Beats Fit Pro。',
  FitPro_Menu_Chapter1: '第 1 章',
  FitPro_Menu_Chapter2: '第 2 章',
  FitPro_Menu_Chapter3: '第 3 章',
  FitPro_Menu_Chapter4: '第 4 章',
  FitPro_Menu_Chapter5: '第 5 章',
  FitPro_Menu_Chapter6: '第 6 章',
  FitPro_Menu_Chapter7: '第 7 章',
  FitPro_Menu_ChapterTitle_Fit: '贴合度',
  FitPro_Menu_ChapterTitle_Sound: '音质',
  FitPro_Menu_ChapterTitle_ListeningModes: '聆听模式',
  FitPro_Menu_ChapterTitle_Controls: '控制按键',
  FitPro_Menu_ChapterTitle_AppleH1Chip: 'Apple H1 芯片',
  FitPro_Menu_ChapterTitle_Design: '设计',
  FitPro_Menu_ChapterTitle_Battery: '电池',
  Global_Menu_Button_Restart: '重新开始培训',
  Global_Menu_Button_SelectProduct: '选择其他产品',
  Global_Menu_Button_Contact: '需要帮助？联系我们',
  FitPro_Menu_ChapterTitle_Fit_1: '灵活调整贴合度',
  FitPro_Menu_ChapterTitle_Fit_2: '柔韧贴合的耳翼',
  FitPro_Menu_ChapterTitle_Fit_3: '全天舒适佩戴',
  FitPro_Menu_ChapterTitle_Fit_4: '通用贴合',
  FitPro_Menu_ChapterTitle_Fit_5: '材质',
  FitPro_Menu_ChapterTitle_Fit_6: '紧密贴合',
  FitPro_Menu_ChapterTitle_Fit_7: '3 种不同尺寸的耳塞',
  FitPro_Menu_ChapterTitle_Fit_8: '贴合度测试',
  FitPro_Menu_ChapterTitle_Fit_9: '隔音效果',
  FitPro_Menu_ChapterTitle_Fit_10: '贴合度摘要',
  FitPro_Menu_ChapterTitle_Sound_1: '定制驱动单元',
  FitPro_Menu_ChapterTitle_Sound_2: '驱动单元尺寸',
  FitPro_Menu_ChapterTitle_Sound_3: '折环驱动单元',
  FitPro_Menu_ChapterTitle_Sound_4: '优化气流',
  FitPro_Menu_ChapterTitle_Sound_5: '低失真',
  FitPro_Menu_ChapterTitle_Sound_6: '出众音质',
  FitPro_Menu_ChapterTitle_Sound_7: '空间音频',
  FitPro_Menu_ChapterTitle_Sound_8: '空间音频用例',
  FitPro_Menu_ChapterTitle_Sound_9: '3 个麦克风',
  FitPro_Menu_ChapterTitle_Sound_10: '音质摘要',
  FitPro_Menu_ChapterTitle_Modes_1: '3 种聆听模式',
  FitPro_Menu_ChapterTitle_Modes_2: '主动降噪',
  FitPro_Menu_ChapterTitle_Modes_3: '外向式麦克风',
  FitPro_Menu_ChapterTitle_Modes_4: '抗噪',
  FitPro_Menu_ChapterTitle_Modes_5: '内向式麦克风',
  FitPro_Menu_ChapterTitle_Modes_6: '主动降噪用例',
  FitPro_Menu_ChapterTitle_Modes_7: '通透模式',
  FitPro_Menu_ChapterTitle_Modes_8: '随时留意周围环境',
  FitPro_Menu_ChapterTitle_Modes_9: '外向麦克风',
  FitPro_Menu_ChapterTitle_Modes_10: '通透模式用例',
  FitPro_Menu_ChapterTitle_Modes_11: '自适应均衡',
  FitPro_Menu_ChapterTitle_Modes_12: '声随你动',
  FitPro_Menu_ChapterTitle_Modes_13: '聆听模式摘要',
  FitPro_Menu_ChapterTitle_Controls_1: '耳塞自带按键',
  FitPro_Menu_ChapterTitle_Controls_2: '一键播放',
  FitPro_Menu_ChapterTitle_Controls_3: '独立耳塞连接',
  FitPro_Menu_ChapterTitle_Controls_4: '掌控音频和聆听模式',
  FitPro_Menu_ChapterTitle_Controls_5: '切换聆听模式',
  FitPro_Menu_ChapterTitle_Controls_6: '耳塞上的控制键',
  FitPro_Menu_ChapterTitle_Controls_7: '选择一侧',
  FitPro_Menu_ChapterTitle_Controls_8: 'ANDROID 兼容性',
  FitPro_Menu_ChapterTitle_Controls_9: '控制按键简介',
  FitPro_Menu_ChapterTitle_H1_1: '完美集成 APPLE',
  FitPro_Menu_ChapterTitle_H1_2: 'APPLE H1 芯片',
  FitPro_Menu_ChapterTitle_H1_3: '一键配对',
  FitPro_Menu_ChapterTitle_H1_4: '嘿 Siri',
  FitPro_Menu_ChapterTitle_H1_5: '自动切换',
  FitPro_Menu_ChapterTitle_H1_6: '音频共享',
  FitPro_Menu_ChapterTitle_H1_7: '查找设备',
  FitPro_Menu_ChapterTitle_H1_8: 'APPLE H1 芯片摘要',
  FitPro_Menu_ChapterTitle_Design_1: '充电盒',
  FitPro_Menu_ChapterTitle_Design_2: '随身携带',
  FitPro_Menu_ChapterTitle_Design_3: '体积缩小一半',
  FitPro_Menu_ChapterTitle_Design_4: '百搭配色',
  FitPro_Menu_ChapterTitle_Design_5: '便携式设计',
  FitPro_Menu_ChapterTitle_Design_6: '无线自由',
  FitPro_Menu_ChapterTitle_Design_7: '独立耳塞连接',
  FitPro_Menu_ChapterTitle_Design_8: '无惧雨水、汗水和阳光',
  FitPro_Menu_ChapterTitle_Design_9: '设计摘要',
  FitPro_Menu_ChapterTitle_Battery_1: '长时间聆听',
  FitPro_Menu_ChapterTitle_Battery_2: '最长可达 24 小时',
  FitPro_Menu_ChapterTitle_Battery_3: 'FAST FUEL 闪充技术',
  FitPro_Menu_ChapterTitle_Battery_4: 'USB-C 充电',
  FitPro_Menu_ChapterTitle_Battery_5: '包装内容',
  FitPro_Menu_ChapterTitle_Battery_6: '电池摘要',
  FitPro_Intro_Title: 'Beats<br/>Fit Pro',
  FitPro_Intro_Subtitle:
    'Beats Fit Pro 简介。真无线降噪耳机，适合全天使用。点按或轻点各项以进行探索。',
  FitPro_Fit_Title: '贴合度',
  FitPro_Fit_Intro_VOSubtitle: 'Beats Fit Pro 全天舒佩戴也能畅享舒适稳固。',
  FitPro_Fit_ChapterText1: '配备舒适贴合的耳翼，可随耳形灵活调整。',
  FitPro_Fit_FlexibleWingtip_Title: '柔韧贴合的耳翼',
  FitPro_Fit_FlexibleWingtip_Subtitle:
    '完善耳翼设计是打造 Beats Fit Pro 时的重中之重。我们经研究确定，耳翼能否在几乎所有耳形实现稳固贴合，关键是调节适应每个用户独特的耳形构造。',
  FitPro_Fit_AllDay_Title: '全天舒适佩戴',
  FitPro_Fit_AllDay_Subtitle:
    '从日常跑步者到专业运动员，各种活跃用户在一年的时间里对通用耳翼设计进行了终极测试。',
  FitPro_Fit_Universal_Title: '耳翼适配所有耳形',
  FitPro_Fit_Universal_Subtitle:
    '耳翼贴合程度取决于用户耳形。耳翼可弯曲到不同的角度，以针对各种耳形提供稳固佩戴体验。 ',
  FitPro_Fit_Materials_Title: '柔软稳固',
  FitPro_Fit_Materials_Subtitle:
    '设计独特的耳翼基于数千只耳朵的测量数据进行数字建模，刚性核心覆以柔性材料实现外柔内刚的完美结合，确保不同大小的耳形均能舒适贴合。',
  FitPro_Fit_ChapterText2:
    '出色音质始于<em>紧密贴合</em>。合适的耳塞有助于实现个性化贴合度。',
  FitPro_Fit_EarTips_Title: '找到合适尺寸',
  FitPro_Fit_EarTips_Subtitle:
    '三种不同尺寸的柔软硅胶耳塞帮助用户轻松找到合适尺寸，实现个性化紧密贴合和隔音效果。',
  FitPro_Fit_FitTest_Title: '自行测试贴合度',
  FitPro_Fit_FitTest_Subtitle:
    '专为 Apple 和 Android 设计的贴合度测试可帮助用户确保耳机贴合自己耳形，兼顾隔音效果和更优性能。贴合度测试会分析噪音泄漏以确认最佳的隔音效果。* ',
  FitPro_Fit_FitTest_Disclaimer:
    '*耳塞贴合度测试需要运行 iOS 15.1 或更高版本，或 Android 版 Beats app',
  FitPro_Fit_Seal_Title: '紧密贴合',
  FitPro_Fit_Seal_Subtitle:
    '借助合适的贴合度，你可以获得个性化隔音效果和最佳音效。适当的隔音效果有助于主动降噪功能和通透模式呈现最佳效果。',
  FitPro_Fit_Summary_Title: '专为舒适稳固的佩戴体验而设计',
  FitPro_Fit_Summary1_FlexibleWingtip:
    '完善耳翼设计是打造 Beats Fit Pro 时的重中之重。 ',
  FitPro_Fit_Summary2_AllDay: '通用耳翼设计已经过各类运动员的舒适度测试。',
  FitPro_Fit_Summary3_Universal: '耳翼贴合程度取决于用户耳形。 ',
  FitPro_Fit_Summary4_Materials: '我们的耳翼材料触感柔软，又具有一定的刚性。',
  FitPro_Fit_Summary5_EarTips:
    '三种不同尺寸的柔软硅胶耳塞，帮助用户轻松找到合适尺寸。',
  FitPro_Fit_Summary6_FitTest:
    '贴合度测试有助于确保合适的贴合度，以实现自定隔音效果和最佳音效。',
  FitPro_Fit_Summary7_Seal: '适当的隔音效果有助于畅享出色的聆听体验。',
  FitPro_Fit_Summary7_Seal_ChineseAlt:
    '适当的隔音效果有助于畅享出色的聆听体验。',
  FitPro_Sound_Title: '音质',
  FitPro_Sound_Intro_VOSubtitle:
    'Beats Fit Pro 采用声学设计，可带来强劲均衡的音效。',
  FitPro_Sound_ChapterText1:
    'Beats Fit Pro 的<em>定制驱动单元</em>可在整个频响曲线范围内提供令人赞叹的动态音域。 ',
  FitPro_Sound_Driver_Title: '驱动小巧，动力澎湃',
  FitPro_Sound_Driver_Subtitle:
    '该驱动单元的直径为 9.5 毫米，在保持小巧体型的同时提供出色的低音、高音和中音效果。长度约为一美分硬币直径的一半。',
  FitPro_Sound_Driver_Label: '9.5 毫米',
  FitPro_Sound_Pleated_Title: '出色的折环设计',
  FitPro_Sound_Pleated_Subtitle:
    '专有的折环驱动单元增加了灵活性，同时显著减少了微振动。折环驱动单元是设计和制造环节的一个难点。',
  FitPro_Sound_Airflow_Title: '优化空气流向',
  FitPro_Sound_Airflow_Subtitle:
    '通过驱动单元优化空气流向可最大限度地减少高音失真，实现强劲低音。',
  FitPro_Sound_UniqueDriver_Title: '打造独特音效',
  FitPro_Sound_UniqueDriver_Subtitle:
    '这种独特的驱动单元设计以极低失真呈现强劲平衡音效。 ',
  FitPro_Sound_ChapterText2: '<em>按你喜好的音效</em>享受非凡音质。',
  FitPro_Sound_SpatialAudio_Title: '立体声场',
  FitPro_Sound_SpatialAudio_Subtitle:
    '带有动态头部跟踪功能的空间音频根据头部运动进行调整，提供如临其境的沉浸式音效。* ',
  FitPro_Sound_SpatialAudio_Disclaimer:
    '*需要使用兼容的硬件和软件。适用于支持此功能的 app 中的兼容内容。并非所有内容都支持杜比全景声。头部追踪功能需要使用 iPhone 或 iPad。',
  FitPro_Sound_UseCases_Subtitle: '点按或轻点每个用例以进一步了解。',
  FitPro_Sound_UseCases_Music: '音乐',
  FitPro_Sound_UseCases_Music_Subtitle:
    '聆听你最喜爱的音乐，享受录音室级别的多维音效。* ',
  FitPro_Sound_UseCases_Movies: '电影',
  FitPro_Sound_UseCases_Movies_Subtitle:
    '观赏自己喜爱的电影时畅享剧场般的环绕立体声。* ',
  FitPro_Sound_UseCases_Games: '游戏',
  FitPro_Sound_UseCases_Games_Subtitle: '尽享沉浸式游戏体验。* ',
  FitPro_Sound_UseCases_Disclaimer_1:
    '*需要使用兼容的硬件和软件。适用于支持此功能的 app 中的兼容内容。并非所有内容都支持杜比全景声。头部追踪功能需要使用 iPhone 或 iPad。',
  FitPro_Sound_Calls_Title: '清晰音质，声声入耳',
  FitPro_Sound_Calls_Subtitle:
    '借助内置加速感应器和 1 类蓝牙® 技术，Beats Fit Pro 可在你说话时检测语音信息。麦克风可精准定位你的声音，而数字处理器可滤除外界噪音和风声，让你的声音清晰可闻。',
  FitPro_Sound_Summary_Title: '均衡澎湃与声俱来',
  FitPro_Sound_Summary1_Driver:
    '该驱动单元的直径为 9.5 毫米，在保持小巧体型的同时提供出色的低音、高音和中音效果。',
  FitPro_Sound_Summary2_Pleated:
    '专有的折环驱动单元增加了灵活性，同时消除微振动。',
  FitPro_Sound_Summary3_Airflow:
    '通过驱动单元优化空气流向可减少高音失真，带来更为清透的低音。',
  FitPro_Sound_Summary4_UniqueDriver:
    '这种独特的驱动单元设计以极低失真呈现强劲平衡音效。',
  FitPro_Sound_Summary5_SpatialAudio:
    '带有动态头部跟踪功能的空间音频根据头部运动进行调整，提供如临其境的沉浸式音效。',
  FitPro_Sound_Summary6_UseCases:
    '带有动态头部跟踪的空间音频可提供剧院般的沉浸式聆听体验。',
  FitPro_Sound_Summary7_Calls:
    'Beats Fit Pro 可在你说话时监测语音信息，帮助你实现理想通话效果。',
  FitPro_Modes_Title: '聆听模式',
  FitPro_Modes_Intro_VOSubtitle: '三种独特聆听模式，随意切换随心听。 ',
  FitPro_Modes_Toggle_Title: '听你所想',
  FitPro_Modes_Toggle_Subtitle:
    '提供三种不同的聆听模式：主动降噪 (ANC)、通透模式和自适应均衡，让你可以随时根据自己的需求进行选择。',
  FitPro_Modes_Toggle_Disclaimer: '仅供演示之用。',
  FitPro_Modes_Toggle_Label1: '降噪',
  FitPro_Modes_Toggle_Label2: '自适应均衡',
  FitPro_Modes_Toggle_Label3: '通透模式',
  FitPro_Modes_Toggle_MusicLabel: '正在播放',
  FitPro_Modes_Toggle_SongTitle: 'Mood',
  FitPro_Modes_Toggle_SongArtist: '24kGoldn, ft. iann dior',
  FitPro_Modes_ChapterText1: '<em>主动降噪</em>屏蔽外界噪音，让你静享音乐。',
  FitPro_Modes_ANCExternalMic_Title: '捕捉外界噪音',
  FitPro_Modes_ANCExternalMic_Subtitle:
    '主动降噪功能首先通过外向式设计麦克风检测环境噪音。',
  FitPro_Modes_ANCAntiNoise_Title: '消除外部噪音',
  FitPro_Modes_ANCAntiNoise_Subtitle:
    'Beats Fit Pro 以对等抗噪声波来持续抵消外部声波，让你能浑然沉浸在听觉享受中。',
  FitPro_Modes_ANCInwardMic_Title: '动态调整',
  FitPro_Modes_ANCInwardMic_Subtitle:
    '向内式麦克风会拾取并分析在贴合佩戴和隔绝噪音后的残留噪声，并会通过抗噪声波予以消除。',
  FitPro_Modes_ANCUseCases_Subtitle: '点按或轻点每个用例以进一步了解。',
  FitPro_Modes_ANCUseCases_Commute: '通勤',
  FitPro_Modes_ANCUseCases_Commute_Subtitle: '通勤时减少城市的背景噪音。',
  FitPro_Modes_ANCUseCases_Exercise: '锻炼',
  FitPro_Modes_ANCUseCases_Exercise_Subtitle: '专注锻炼，不受任何干扰.',
  FitPro_Modes_ANCUseCases_Travel: '旅行',
  FitPro_Modes_ANCUseCases_Travel_Subtitle:
    '消除引擎和旅客嘈杂声，安享飞行静界。',
  FitPro_Modes_ANCUseCases_Meditate: '冥想',
  FitPro_Modes_ANCUseCases_Meditate_Subtitle:
    '专注呼吸，消除干扰，找回内在安宁。',
  FitPro_Modes_ChapterText2: '<em>通透模式</em>让你可随时留意周围环境。',
  FitPro_Modes_TMAware_Title: '周围环境听通透',
  FitPro_Modes_TMAware_Subtitle:
    '通透模式通过将外部声音混入音乐，让你可随时留意周围环境。',
  FitPro_Modes_TMExternalMic_Title: '外向麦克风精准定位环境噪音',
  FitPro_Modes_TMExternalMic_Subtitle:
    '外向麦克风可捕获外部声音和环境噪音，营造出自然“开放”的聆听体验。 ',
  FitPro_Modes_TMUseCases_Subtitle: '点按或轻点每个用例以进一步了解。',
  FitPro_Modes_TMUseCases_Run: '跑步',
  FitPro_Modes_TMUseCases_Run_Subtitle:
    '在聆听音乐时可听周围环境的声音，留意外界，步伐不减。',
  FitPro_Modes_TMUseCases_Travel: '旅行',
  FitPro_Modes_TMUseCases_Travel_Subtitle:
    '在机场登机口或火车站台听到重要的通告。',
  FitPro_Modes_TMUseCases_Walk: '步行',
  FitPro_Modes_TMUseCases_Walk_Subtitle:
    '随时注意周围的交通状况和车水马龙的十字路口。',
  FitPro_Modes_TMUseCases_StandBy: '站立',
  FitPro_Modes_TMUseCases_StandBy_Subtitle:
    '在嘈杂的咖啡店听音乐或等待与朋友见面时，随时可清楚别人喊你的名字。 ',
  FitPro_Modes_ChapterText3:
    '<em>自适应均衡功能</em>针对每个用户提供相应的个性化调整。',
  FitPro_Modes_AdaptiveEQ_Title: '声随你动',
  FitPro_Modes_AdaptiveEQ_Subtitle:
    '当主动降噪和通透模式关闭时，自适应均衡功能会根据用户的独特耳形利用麦克风自动调音。',
  FitPro_Modes_Summary_Title: '静享音乐或听清外界声音',
  FitPro_Modes_Summary1_Toggle:
    '三种不同的聆听模式：主动降噪 、通透模式和自适应均衡，让你可以随时根据自己的需求进行选择。',
  FitPro_Modes_Summary2_ANCExtermalMic:
    '主动降噪功能首先通过外向式设计麦克风检测环境噪音。',
  FitPro_Modes_Summary3_ANCAntiNoise:
    '然后 Beats Fit Pro 以对等抗噪声波来抵消外部声波。',
  FitPro_Modes_Summary4_ANCInwardMic:
    '向内式麦克风会拾取并分析在贴合佩戴和隔绝噪音后的残留噪声。',
  FitPro_Modes_Summary5_ANCUseCases:
    '在通勤、健身、旅行或冥想时使用主动降噪来保持专注。',
  FitPro_Modes_Summary6_TMAware: '通透模式让你可以随时留意周围环境。',
  FitPro_Modes_Summary7_TMExternalMic: '外向麦克风可捕获外部声音和环境噪音。',
  FitPro_Modes_Summary8_TMUseCases:
    '在跑步、旅行、步行或站立时听清周围环境的声音',
  FitPro_Modes_Summary9_AdaptiveEQ:
    '自适应均衡功能会根据用户独特耳形利用麦克风自动调音。',
  FitPro_Controls_Title: '控制按键',
  FitPro_Controls_Intro_VOSubtitle: '只需按一下按键即可控制耳塞。',
  FitPro_Controls_ChapterText1:
    '<em>耳塞自带按键</em>，实现近在指边的便捷操控。',
  FitPro_Controls_PhysicalButton_Title: '一键播放',
  FitPro_Controls_PhysicalButton_Subtitle: '通过实体按键轻松掌控聆听体验。',
  FitPro_Controls_BothSides_Title: '独立耳塞连接',
  FitPro_Controls_BothSides_Subtitle:
    '左右耳塞均配有按键，让你可以左右两边随心控制音乐。',
  FitPro_Controls_ChapterText2: '随时随地<em>掌控音频和聆听模式</em>。',
  FitPro_Controls_PressHold_Title: '无缝切换聆听模式',
  FitPro_Controls_PressHold_Subtitle:
    '按住可在不同聆听模式间切换，也可通过自定用于激活 Siri.*',
  FitPro_Controls_PressHold_Disclaimer:
    '*长按功能自定义需要使用运行 iOS 14.6 或更高版本的 iPhone，或安装了最新版 Beats app 的 Android 设备。Siri 可能仅支持部分语言或地区，并且功能可能因地区而异。需要接入互联网。可能需要支付蜂窝网络数据费用。',
  FitPro_Controls_PressHold_Label1: '降噪模式',
  FitPro_Controls_PressHold_Label2: '通透模式',
  FitPro_Controls_Playback_Title: '耳塞上集成控制按键',
  FitPro_Controls_Playback_Subtitle: '你可以通过贴耳式控制键管理通话和音乐。',
  FitPro_Modes_Playback_MusicLabel: '正在播放',
  FitPro_Modes_Playback_SongTitle1: '5 Seconds of Summer',
  FitPro_Modes_Playback_SongArtist1: 'Best Years',
  FitPro_Modes_Playback_SongTitle2: 'Boyfriend',
  FitPro_Modes_Playback_SongArtist2: 'Selena Gomez',
  FitPro_Modes_Playback_SongTitle3: 'Lovesick',
  FitPro_Modes_Playback_SongArtist3: 'Trevor Daniel',
  FitPro_Modes_Playback_SongTitle4: ' Mood',
  FitPro_Modes_Playback_SongArtist4: '24kGoldn, ft. iann dior',
  FitPro_Modes_Playback_SongTitle5: 'What If I Told You That I Love You',
  FitPro_Modes_Playback_SongArtist5: 'Ali Gatie',
  FitPro_Controls_Playback_Instruction: '单按或轻点热点，练习每个命令。',
  FitPro_Controls_Playback_Play1: '按下可暂停音乐或接听电话',
  FitPro_Controls_Playback_Play2: '按下可播放音乐或挂断电话',
  FitPro_Controls_Playback_Skip: '连按两下按键可跳过曲目',
  FitPro_Controls_Playback_Previous: '连按三下按键可播放上一曲',
  FitPro_Controls_Playback_ScanFwd: '连按两下并按住按键可快进',
  FitPro_Controls_Playback_ScanBck: '连按三下并按住按键可快退',
  FitPro_Controls_Customize_Title: '选择一侧',
  FitPro_Controls_Customize_Subtitle:
    '自定义单只耳塞上的“按住”功能，即可通过 iOS 或 Android 版 Beats App，针对主动降噪、通透模式、自适应均衡、音量或语音助手进行操控。',
  FitPro_Controls_Customize_Disclaimer:
    '*Beats app 要求运行 Android 8.0 或更高版本。App 可通过 Google Play Store 和 beatsbydre.com 获取。',
  FitPro_Controls_Android_Title: '兼容 Android 设备',
  FitPro_Controls_Android_Subtitle:
    '借助 Android 版 Beats app，你可以获得一键配对、自定义控制、电池电量以及固件更新等增强功能。*',
  FitPro_Controls_Android_Disclaimer:
    '*Beats app 要求运行 Android 8.0 或更高版本。App 可通过 Google Play Store 和 beatsbydre.com 获取。',
  FitPro_Controls_Summary_Title: '指尖自由触控',
  FitPro_Controls_Summary1_PhysicalButton: '通过实体按键轻松掌控聆听体验。',
  FitPro_Controls_Summary2_BothSides:
    '左右耳塞均配有按键，让你可以左右两边随心控制音乐。',
  FitPro_Controls_Summary3_PressHold: '按住可在不同聆听模式间切换。',
  FitPro_Controls_Summary4_Playback: '你可以通过贴耳式控制键管理通话和音乐。',
  FitPro_Controls_Summary5_Customize: '自定耳塞左右两侧的“按住”功能。',
  FitPro_Controls_Summary6_Android:
    '利用 Android 版 Beats app，你可以使用增强功能。',
  FitPro_H1_Title: 'Apple H1 芯片',
  FitPro_H1_Intro_VOSubtitle: 'Beats Fit Pro 专门搭载 Apple H1 芯。 ',
  FitPro_H1_AppleIntegration_Title: '完美集成 Apple',
  FitPro_H1_AppleIntegration_Subtitle:
    '搭载 Apple H1 芯片增强功能，与 Apple 完美集成。',
  FitPro_H1_ChapterText1:
    'Beats Fit Pro 专门搭载 <em>Apple H1 芯片</em>，各种好用功能招之即来。',
  FitPro_H1_Pairing_Title: '一键配对',
  FitPro_H1_Pairing_Subtitle:
    '使用一键配对功能，Beats Fit Pro 开箱即可通过先进的 1 类蓝牙® 技术连接至 iOS 设备。*',
  FitPro_H1_Pairing_Disclaimer: '*需要运行 iOS 15.1 或更高版本。',
  FitPro_H1_HeySiri_Title: '嘿 Siri',
  FitPro_H1_HeySiri_Subtitle:
    '只需说一声“嘿 Siri”，就能轻松掌控音乐、通话、路线导航。*',
  FitPro_H1_HeySiri_Disclaimer:
    '*Siri 可能仅支持部分语言或地区，并且功能可能因地区而异。需要接入互联网。可能需要支付蜂窝网络数据费用。',
  FitPro_H1_AutoSwitch_Title: '自动切换',
  FitPro_H1_AutoSwitch_Subtitle:
    '借助自动切换功能，Beats Fit Pro 支持音频在设备间顺畅切换。* ',
  FitPro_H1_AutoSwitch_Disclaimer:
    '*自动切换需要运行 macOS 11.1、iOS 14.3、iPadOS 14.3、watchOS 7.2 或 Apple tvOS 14.3 或更高版本。',
  FitPro_H1_AudioSharing_Title: '音频共享',
  FitPro_H1_AudioSharing_Subtitle:
    '与拥有 AirPods 或 Beats 耳机的朋友一起聆听，共享喜爱的音乐。',
  FitPro_H1_AudioSharing_Disclaimer:
    '*音频共享与支持此功能的无线 Beats 耳机和所有 AirPods 兼容。  需要使用兼容的 Apple 硬件和软件。',
  FitPro_H1_FindMy_Title: '查找设备',
  FitPro_H1_FindMy_Subtitle:
    '借助“查找”功能，出行时即可更便捷地追踪 Beats Fit Pro 的去向。*',
  FitPro_H1_FindMy_Disclaimer:
    '*适用于 Beats 的“查找”功能要求使用运行 iOS 15.1 或更高版本的 iPhone 或 iPod，运行 iPadOS 15.1 或更高版本的 iPad，或运行 macOS Monterey 12.0.1 或更高版本的 Mac。顾客必须拥有 Apple ID，登录自己的 iCloud 帐户，并启用“查找”功能。',
  FitPro_H1_Summary_Title: '搭载 Apple H1 芯片',
  FitPro_H1_Summary1_Seamless: '搭载 Apple H1 芯片，与 Apple 设备完美集成。',
  FitPro_H1_Summary2_Pairing:
    '使用便捷的一键配对功能，即可通过蓝牙® 实现轻松连接。 ',
  FitPro_H1_Summary3_HeySiri:
    '只需说声“嘿 Siri”，即可召唤 Siri 并随心提问，解放双手。 ',
  FitPro_H1_Summary4_AutoSwitch:
    'Beats Fit Pro 可通过自动切换功能在设备之间顺畅切换。',
  FitPro_H1_Summary5_AudioSharing:
    '与拥有 AirPods 或 Beats 耳机的朋友共享音乐。',
  FitPro_H1_Summary6_FindMy: '使用“查找”功能轻松定位耳塞。',
  FitPro_Design_Title: '设计',
  FitPro_Design_Intro_VOSubtitle: 'Beats Fit Pro 专为全天使用而打造。 ',
  FitPro_Design_ChapterText1: '<em>充电盒</em>用来在外出时为耳塞充电。 ',
  FitPro_Design_Pocket_Title: '随身携带',
  FitPro_Design_Pocket_Subtitle:
    '同色系充电盒易于携带，让 Beats Fit Pro 随时陪伴你左右。 ',
  FitPro_Design_Size_Title: '体积缩小一半多',
  FitPro_Design_Size_Subtitle:
    'Beats Fit Pro 充电盒比 Powerbeats Pro 充电盒小一半以上。 ',
  FitPro_Design_Color_Title: '百搭配色',
  FitPro_Design_Color_Subtitle:
    '四种独特的耳塞颜色和同色系充电盒可供选择，总有一款合你风格。',
  FitPro_Design_Color_Black: 'Beats 黑色',
  FitPro_Design_Color_White: 'Beats 白色',
  FitPro_Design_Color_Purple: '莹石紫',
  FitPro_Design_Color_Gray: '鼠尾草灰',
  FitPro_Design_ChapterText2:
    '无论你身在何方，<em>Beats Fit Pro 耳塞</em>都会时刻陪伴左右。',
  FitPro_Design_TrueWireless_Title: '无线自由',
  FitPro_Design_TrueWireless_Subtitle:
    '真无线耳塞 Beats Fit Pro 日常活动伴你左右。',
  FitPro_Design_Independent_Title: '单只耳塞，全面掌控',
  FitPro_Design_Independent_Subtitle:
    '独立耳塞连接让你可一次佩戴一只耳塞，左右耳塞均配有控制按键，助你全面掌控聆听体验。',
  FitPro_Design_Sweat_Title: '无惧雨水、汗水和阳光',
  FitPro_Design_Sweat_Subtitle:
    'Beats Fit Pro 耳塞具备 IPX4 级别抗汗抗水功能，能够承受风吹雨打或剧烈运动。*',
  FitPro_Design_Sweat_Disclaimer:
    '*Beats Fit Pro 抗汗抗水，适合各种非水上运动和活动。抗汗抗水功能并非永久有效。充电盒不具备抗汗抗水功能。',
  FitPro_Design_Summary_Title: '专为全天使用打造',
  FitPro_Design_Summary1_Pocket: 'Beats Fit Pro 配有袖珍充电盒，便于随身携带。',
  FitPro_Design_Summary2_Size:
    'Beats Fit Pro 充电盒比 Powerbeats Pro 充电盒小一半以上。 ',
  FitPro_Design_Summary3_Color: '四种配色可选，总有一款合你风格。',
  FitPro_Design_Summary4_TrueWireless: 'Beats Fit Pro 打造真正的无线体验。',
  FitPro_Design_Summary5_Independent: '独立耳塞连接让你一次可佩戴一只耳塞。',
  FitPro_Design_Summary6_Sweat: 'Beats Fit Pro 耳塞提供 IPX4 级抗汗抗水功能。',
  FitPro_Battery_Title: '电池',
  FitPro_Battery_Intro_VOSubtitle: '出色电池续航长时不停歇。 ',
  FitPro_Battery_6Hours_Title: '长时间聆听',
  FitPro_Battery_6Hours_Subtitle: '单只耳塞可提供长达 6 小时的聆听时间 。*',
  FitPro_Battery_6Hours_Disclaimer:
    '*电池续航时间依设备设置、环境、使用情况及诸多其他因素可能有所差异。',
  FitPro_Battery_18Hours_Title: '最长可达 24 小时',
  FitPro_Battery_18Hours_Subtitle: '搭配充电盒可享受 18 小时的额外聆听时间。* ',
  FitPro_Battery_18Hours_Disclaimer:
    '*电池续航时间依设备设置、环境、使用情况及诸多其他因素可能有所差异。',
  FitPro_Battery_FastFuel_Title: '电量充沛',
  FitPro_Battery_FastFuel_Subtitle:
    '使用 Fast Fuel 闪充技术，充电 5 分钟即可持续播放 1 小时。* ',
  FitPro_Battery_FastFuel_Disclaimer:
    '*电池续航时间依设备设置、环境、使用情况及诸多其他因素可能有所差异。',
  FitPro_Battery_USBC_Title: 'USB-C 充电',
  FitPro_Battery_USBC_Subtitle:
    'USB-C 充电接头兼容 Apple 和 Android 设备，因此可以随时随地充电。 ',
  FitPro_Battery_WhatsInTheBox_Title: '包装内容',
  FitPro_Battery_WhatsInTheBox_Subtitle:
    'Beats Fit Pro 配有一个袖珍充电盒、一个通用 USB-C 充电接头，并有三种耳塞尺寸可供选择。',
  FitPro_Battery_Summary_Title: '长时聆听不停歇',
  FitPro_Battery_Summary1_6Hours: '每只耳塞提供 6 小时的聆听时间。',
  FitPro_Battery_Summary2_18Hours: '搭配充电盒可享受 18 小时的额外聆听时间。',
  FitPro_Battery_Summary3_FastFuel:
    '使用 Fast Fuel 闪充技术，充电 5 分钟即可持续播放 1 小时。',
  FitPro_Battery_Summary4_USBC: '随附 USB-C 充电接头，实现随时随地充电。',
  FitPro_Battery_Summary5_WhatsInTheBox:
    '包装盒内附带一个袖珍充电盒、一个 USB-C 充电接头和三种尺寸的耳塞。',
  FitPro_Outro_ChapterText1:
    '凭借柔韧贴合的耳翼、三种聆听模式和紧凑的袖珍设计，<em>Beats Fit Pro 让你自主掌控聆听体验。</em> ',
  FitPro_TestKnowledge: '考察 Beats Fit Pro 知识的掌握情况。',
  FitPro_TakeQuiz: '参加测验',
  Global_ForgotEmail_NoUser:"没有与此标识符对应的用户记录。该用户可能已删除。"

};

export default copy;
