import React, { useContext, forwardRef, useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { ReactSVG } from 'react-svg'

import Context from '../../../../../context/Context'

import Styles from './DraggableSpot.module.scss'

const DraggableSpot = forwardRef(
  ({ videoContext, hotspotInfo, handleVideoState, handleInteractionEnd, isRewinding, themeColor }, ref) => {
    const context = useContext(Context)
    const [mountDone, setAmountDone] = useState(1)
    const [visible, setVisible] = useState(true)
    const constraintsRef = useRef(null)
    const { setForceDefaultCursor } = context

    let circleX = 0
    let circleY = 0
    const draggableArea = 160 // 200px for pressAndHoldContainer

    function circleAnimation(angle, width, height) {
      circleX = width + width * Math.cos((angle * Math.PI) / 180)
      circleY = height + height * Math.sin((angle * Math.PI) / 180)

      return { x: circleX, y: circleY }
    }

    const handleMouseEnter = () => {
      setForceDefaultCursor(true)
    }
    const handleMouseLeave = () => {
      setForceDefaultCursor(false)
    }

    const getHotspotPosition = () => {
      const setY = window.innerHeight / 2 + (videoContext?.canvas?.clientHeight / 2) * hotspotInfo.coordinates.y
      const setX = window.innerWidth / 2 + (videoContext?.canvas?.clientWidth / 2) * hotspotInfo.coordinates.x

      return { y: `${setY}px`, x: `${setX}px` }
    }

    const calcPercentDone = (info) => {
      const el = constraintsRef.current
      return (el.getBoundingClientRect().top + el.clientHeight - info.point.y) / el.clientHeight
    }

    const { x, y } = getHotspotPosition()

    // circle animation percentage
    const circlePos = circleAnimation((360 / 4) * mountDone + 360 / 2, draggableArea / 4, draggableArea * 0.4)

    useEffect(() => {
      if (!isRewinding) setVisible(true)
    }, [isRewinding])

    const onDrag = (info) => {
      const amountDone = calcPercentDone(info)
      if (amountDone >= 0 && amountDone <= 1) setAmountDone(amountDone)
      handleVideoState(amountDone)
    }

    const onDragEnd = (info) => {
      const lastPointPercentage = calcPercentDone(info)
      setAmountDone(1)
      handleInteractionEnd(lastPointPercentage)
    }

    return (
      <div
        className={Styles.pressAndHoldContainerHolder}
        style={{
          '--poition-height': y,
          '--poition-width': x,
          '--themecurr-color': hotspotInfo.theme || '#FFF',
          opacity: visible ? '1' : '0',
          transition: '.3s',
        }}
      >
        <motion.div
          className={Styles.pressAndHoldContainer}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          ref={constraintsRef}
          transition={{ duration: 0.3 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ height: `${draggableArea}px`, width: `${draggableArea / 2.5}px` }}
        >
          <ReactSVG
            className={Styles.directionalIcon}
            src={process.env.PUBLIC_URL + '/assets/images/directional-arrows.svg'}
          />
          <div
            className={Styles.buttonContainer}
            ref={ref}
            style={{
              top: `${circlePos.y}%`,
              left: `${circlePos.x}%`,
            }}
          >
            <div className={Styles.outterCircle} />
            <div className={Styles.middleCircle} />
            <div className={Styles.innerCircle} />
          </div>
          <motion.div
            className={`${Styles.drag} ${isRewinding && Styles.dragNoEvent}`}
            drag="y"
            dragConstraints={constraintsRef}
            dragElastic={false}
            dragMomentum={false}
            dragTransition={{ from: 0 }}
            onDragStart={() => setVisible(false)}
            onDragEnd={(e, info) => { onDragEnd(info) }}
            onDrag={(event, info) => { onDrag(info) }}
            onTouchStart={() => setVisible(false)}
            onPointerDown={() => setVisible(false)}
            onPointerUp={(event) => {
              if (!visible) {
                const info = {
                  point: {
                    y: event.clientY
                  }
                }

                onDragEnd(info)
              }
            }}
            onTouchEnd={(event) => {
              if (!visible) {
                const info = {
                  point: {
                    y: event.changedTouches[0].clientY
                  }
                }

                onDragEnd(info)
              }
            }}
            onTouchMove={(event) => {
              if (!visible) {
                const info = {
                  point: {
                    y: event.touches[0].clientY
                  }
                }

                onDrag(info)
              }
            }}
          ></motion.div>
        </motion.div>
      </div>
    )
  }
)

export default DraggableSpot
