import React, { useState, useEffect, useContext } from 'react';
import Context from '../../context/Context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as SITEDATA from '../../constants';

import COUNTRIES from '../../constants/countries';
import { useHistory, useLocation } from 'react-router-dom';
import cookie from 'react-cookies';
import clsx from 'clsx';
import Styles from './signin.module.scss';
import { motion } from 'framer-motion';
// import { render } from '@testing-library/react';
import { TrackEvent } from '../../utils/track';

const SubUserInfo = (props) => {
	const { authUser, firebase, settingsForm, setMoreDetailsOpened, ga4, progress } = props;
	const history = useHistory();
	const location = useLocation();
	const context = useContext(Context);
	const { language, companyID, languageID, setLanguageID, setMenuActive, siteID, setOverrideTheme, menuActive } = context;
	const BUSINESS_ROLES = siteID ? SITEDATA[`${siteID}_BUSINESS_ROLES`] : SITEDATA[`studioBuds_BUSINESS_ROLES`]
	const COMPANIES = siteID ? SITEDATA[`${siteID}_COMPANIES`] : SITEDATA[`studioBuds_COMPANIES`]
	const LANGUAGES = siteID ? SITEDATA[`${siteID}_LANGUAGES`] : SITEDATA[`studioBuds_LANGUAGES`]
	//PREPOPULATE IF LOGGED IN! (ALSO NEED TO ADD PASSWORD AND LANGUAGE FIELDS)
	const [email, setEmail] = useState('');

	const [first, setFirst] = useState('');
	const [last, setLast] = useState('');
	const [company, setCompany] = useState('notset');
	const [employeeID, setEmployeeID] = useState('');
	const [businessRole, setBusinessRole] = useState('');
	const [country, setCountry] = useState('');
	const [password, setPassword] = useState('');
	const [showExtraFields, setShowExtraFields] = useState(false);
	const [error, setError] = useState(null);
	// const getQueryStringValue = (key, queryString = window.location.search) => {
	// 	let params = new URLSearchParams(queryString);
	// 	if (key === 'site') {
	// 	  if (params.get(key)) {
	// 		cookie.save('cr_trnr_' + key, params.get(key), {
	// 		  path: '/',
	// 		});
	// 	  }
	
	// 	  return params.get(key) ? params.get(key) : null;
	// 	} else {
	// 	  if (params.get(key)) {
	// 		cookie.save('cr_trnr_' + key, params.get(key).toUpperCase(), {
	// 		  path: '/',
	// 		});
	// 	  }
	
	// 	  return params.get(key) ? params.get(key).toUpperCase() : null;
	// 	}
	//   };
	useEffect(() => {
		console.log("Ruizpalacios UseEffect",authUser.uid)
		if (authUser.uid) {
			console.log("company",company)
			// if (company === 'notset') {
				firebase.user(authUser.uid).on('value', (snapshot) => {
					const snap = snapshot.val();
					console.log("?????snapshot",snap)
					if (snap) {
						setFirst(snap.firstname);
						setLast(snap.lastname);
						setEmployeeID(snap.employeeID);
						setEmail(snap.email);
						setBusinessRole(snap.businessRole);
						setCompany(snap.company);
						setCountry(snap.country);
						if (snap.firstname && snap.welcomeViewed) {
							setShowExtraFields(true);
						}
	
						if (
							(!companyID || !COMPANIES.find((o) => o.code === companyID)) &&
							location.pathname !== ROUTES.COMPANYREQUIRED &&
							(!snap.company || snap.company === 'notset' || snap.company === '')
						) {
							console.log("push to company required for some reason")
							history.push(ROUTES.COMPANYREQUIRED);
						}
					}
					
				});
			// }
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser]);

	useEffect(() => {
		// console.log("setError menuactive")
		setError(null)
	}, [menuActive])

	const onChange = (event) => {
		console.log("onChange",event.target.name)
		switch (event.target.name) {
			case 'first':
				setFirst(event.target.value);
				break;
			case 'last':
				setLast(event.target.value);
				break;
			case 'employeeID':
				setEmployeeID(event.target.value);
				break;
			case 'businessRole':
				setBusinessRole(event.target.value);
				break;
			case 'password':
				setError({ message: ''});
				setPassword(event.target.value);
				break;
			case 'country':
				setCountry(event.target.value);
				break;
			default:
		}
	};

	const goBackToMenu = (val) => {
		TrackEvent(ga4, 'close_settings', 'click', 'user_action');
		setMoreDetailsOpened(val);
		setError(null)
	};

	const isInvalid = !first || !last || !businessRole || !country;
	const showForPreexistingUser = (language, languageID, setLanguageID) => {
		const onChangePassword = (event) => {
			console.log("onChangePassword",event)
			
			
			const promptForCurrentPassword = () => {
				// alert('need to somehow prompt user to log back in');
				var code = window.prompt('Please Enter Current Password');
				firebase
					.doSignInWithEmailAndPassword(email, code)
					.then((authUser) => {
						console.log("showForPreexistingUser authUser",authUser)
						onChangePassword(event);
					})
					.catch((error) => {
						console.log('error is ', error);
						setError(error);
					});
			};
			const validatePassword = (password) => {
				//RIGOROUS PASSWORD REQUIREMENTS
				var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
				return re.test(password);
			};

			if (validatePassword(password)) {
				firebase
					.doPasswordUpdate(password)
					.then(() => {
						setError({ message: language['Global_Password_ChangeConfirm'] });
					})
					.catch((error) => {
						console.log('error', error);
						if (error.code && error.code === 'auth/requires-recent-login') {
							promptForCurrentPassword();
						} else {
							console.log('error is now', error);
							setError(error);
						}
					});
			} else {
				setError({ message: language['Global_Field_Password_CreateError'] });
			}
			event.preventDefault();
		};
		const onChangeLanguage = (event) => {
			TrackEvent(ga4, `language_select_${event.target.value}`, 'click', 'user_action');
			setLanguageID(event.target.value);
			//UPDATE COOKIE
			cookie.save('cr_trnr_lang', event.target.value, { path: '/' });
		};
		const defaultLanguage = LANGUAGES.find((lang) => languageID === lang.code);
		if (showExtraFields) {
			const validatePassword = (password) => {
				//RIGOROUS PASSWORD REQUIREMENTS
				var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
				return re.test(password);
			};
			const isInvalidPassword = !validatePassword(password);

			return (
				<>
					<div className={clsx(Styles.inputWrapper, Styles.inputWrapperFull)}>
						<label>{language['Global_Menu_Language']}</label>
						<select onChange={onChangeLanguage} name="language" id="language" defaultValue={defaultLanguage ? defaultLanguage.code: null}>
							{LANGUAGES.map((lang, i) => (
								<option key={lang.code} value={lang.code}>
									{lang.name}
								</option>
							))}
						</select>
					</div>
					<div className={Styles.passwordWrapper}>
						<div className={clsx(Styles.inputWrapper, Styles.inputWrapperFull)}>
							<label>{language['Global_Field_Password']}</label>
							<input
								name="password"
								value={password}
								onChange={onChange}
								type="password"
								placeholder={language['Global_Field_Password']}
							/>
							<p className={clsx(Styles.errorMessage, Styles.settingsErrorMsg)}> {error && error.message}</p>
							<button
								className={`${isInvalidPassword ? Styles.inactive : ''}`}
								onClick={onChangePassword}
							>
								{language['Global_Password_Change']}
							</button>
						</div>
					</div>
				</>
			);
		}
		return null;
	};
	return (
		<Context.Consumer>
			{() => {
				if (companyID) {
					if (COMPANIES.find((o) => o.code === companyID)) {
						setCompany(COMPANIES.find((o) => o.code === companyID).name);
					}
				}

				const onSubmit = (event) => {

					firebase
						.user(authUser.uid)
						.update({
							firstname: first,
							lastname: last,
							company: company ? company : '',
							employeeID: employeeID ? employeeID : '',
							businessRole: businessRole ? businessRole : BUSINESS_ROLES[0],
							country: country ? country : '',
						})
						.then(() => {
							TrackEvent(ga4, `user_info_updated`, 'click', 'user_action');

							goHome();
						})
						.catch((error) => {

							console.log("onsubmiterror")
						});

					event.preventDefault();
				};

				function renderSignInform() {
					return (
						<div className={Styles.userInfoWrapper}>
							<h1 className={Styles.userInfoTitle}>{language['Global_Details_Title']}</h1>
							<form className={Styles.infoForm} autoComplete="new-password">
								<div className={Styles.nameWrapper}>
									<input
										name="first"
										value={first}
										onChange={onChange}
										type="text"
										placeholder={language['Global_Details_Field_FirstName']}
									/>
									<input
										name="last"
										value={last}
										onChange={onChange}
										type="text"
										placeholder={language['Global_Details_Field_LastName']}
									/>
								</div>
								<div className="">
									<input
										name="employeeID"
										className={Styles.inputFullWidth}
										value={employeeID}
										onChange={onChange}
										type="text"
										placeholder={`${language['Global_Details_Field_EmployeeID']}`}
									/>
								</div>
								<select name="businessRole" onChange={onChange} defaultValue={businessRole}>
									<option disabled selected value="">
										{language['Global_Details_Field_BusinessRole']}
									</option>
									{BUSINESS_ROLES.map((role, i) => (
										<option key={role} value={role}>
											{language[role]}
										</option>
									))}
								</select>
								<input
									name="company"
									value={company}
									onChange={onChange}
									type="text"
									readOnly
									placeholder={`${language['Global_Details_Field_CompanyName']}`}
								/>
								{/* COUNTRY DROPDOWN */}
								<select name="country" id="country" onChange={onChange} defaultValue={country} autoComplete="new-password">
								<option className={Styles.inactive} disabled selected value="">
										{language['Global_Details_Field_Country']}
									</option>
									{COUNTRIES.map((country, i) => (
										<option key={i} value={country}>
											{language[country]}
										</option>
									))}
								</select>
								{showForPreexistingUser(language, languageID, setLanguageID)}

								<div
									className={`${Styles.submit} ${isInvalid ? Styles.inactive : null}`}
									onClick={!isInvalid ? onSubmit : null}
								>
									{showExtraFields ? language['Global_Menu_Confirm'] : language['Global_UI_Next']}
								</div>
								{error && <p>{error.message}</p>}
							</form>
						</div>
					);
				}

				function renderBackButton() {
					return (
						<div role="button" className={Styles.backButton} onClick={() => goBackToMenu(false)}>
							<img alt="back" src={process.env.PUBLIC_URL + '/assets/images/chevron-right-white.svg'} />
							<span>{language['Global_UI_Back'].toUpperCase()}</span>
						</div>
					);
				}

				function renderSettingsForm() {
					return (
						<div className={Styles.settingsWrapper}>
							{renderBackButton()}
							<form className={Styles.settingsForm}>
								<div className={Styles.settingsNameWrapper}>
									<div className={Styles.inputWrapper}>
										<label>{language['Global_Details_Field_FirstName']}</label>
										<input
											name="first"
											value={first}
											onChange={onChange}
											type="text"
											placeholder={language['Global_Details_Field_FirstName']}
										/>
									</div>
									<div className={Styles.inputWrapper}>
										<label>{language['Global_Details_Field_LastName']}</label>
										<input
											name="last"
											value={last}
											onChange={onChange}
											type="text"
											placeholder={language['Global_Details_Field_LastName']}
										/>
									</div>
									<div className={Styles.inputWrapper}>
										<label>{language['Global_Details_Field_EmployeeID']}</label>
										<input
											name="employeeID"
											className={Styles.inputFullWidth}
											value={employeeID}
											onChange={onChange}
											type="text"
											placeholder={`${language['Global_Details_Field_EmployeeID']}`}
										/>
									</div>
								</div>
								<div className={Styles.businessDropdownWrapper}>
									<div className={clsx(Styles.inputWrapper, Styles.inputWrapperHalf)}>
										<label>{language['Global_Details_Field_BusinessRole']}</label>
										<select name="businessRole" onChange={onChange} defaultValue={businessRole}>
											<option disabled value>
												{language['Global_Details_Field_BusinessRole']}
											</option>
											{BUSINESS_ROLES.map((role, i) => (
												<option key={role} value={role} selected={businessRole===role}>
													{language[role]}
												</option>
											))}
										</select>
									</div>
									<div className={clsx(Styles.inputWrapper, Styles.inputWrapperHalf)}>
										<label>{language['Global_Details_Field_CompanyName']}</label>

										<input
											name="company"
											value={company}
											onChange={onChange}
											type="text"
											readOnly
											placeholder={`${language['Global_Details_Field_CompanyName']}`}
										/>
									</div>
								</div>
								<div className={Styles.locationWrapper}>
									<div className={clsx(Styles.inputWrapper, Styles.inputWrapperFull)}>
										<label>{language['Global_Field_Location']}</label>
										<select name="country" id="country" onChange={onChange} defaultValue={country}>
											{COUNTRIES.map((countryoption, i) => {
												return (
													<option key={i} value={countryoption} selected={country===countryoption}>
														{language[countryoption]}
													</option>
												);
											})}
										</select>
									</div>
									{showForPreexistingUser(language, languageID, setLanguageID)}
								</div>
								<div
									className={`${Styles.settingsSubmit} ${isInvalid ? Styles.inactive : null}`}
									onClick={isInvalid ? null : onSubmit}
								>
									{showExtraFields ? language['Global_Menu_Confirm'] : language['Global_UI_Next']}
								</div>
							</form>
						</div>
					);
				}

				const goHome = () => {
					//UNSET THEMEOVERRIDE
					setOverrideTheme(false);
					console.log('sub gohome ', history.location);
					console.log('sub progress ', progress);
					//IF THIS IS THE INITIAL TIME (AFTER SIGN UP), GO HOME
					//ELSE, CLOSE MENU?
					if (showExtraFields) {
						setMenuActive(false);
					} else {
						console.log('goHome', ROUTES.HOME);
						//history.push({ pathname: ROUTES.HOME, search: history.location.search });
					}
				};
				return (
					<motion.div
						className={`${Styles.signinHolder}`}
						initial={{ opacity: 0, y: -200 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 100 }}
						transition={{ duration: 0.25, ease: 'easeOut' }}
					>
						{!settingsForm && renderSignInform()}
						{settingsForm && renderSettingsForm()}
					</motion.div>
				);
			}}
		</Context.Consumer>
	);
};

// const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);
export default withFirebase(SubUserInfo);
// export default SignInPage;

// export { SignInForm };
