import { useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Context from '../../../context/Context';
import Styles from './WithinChapterNav.module.scss';
import NextButton from '../../NextButton';
import ProgressButton from '../../ProgressButton';

const WithinChapterNav = (props) => {
	const { chapter, activeModule, prevModule, nextModule, thisModuleIndex, prevLinkTo, nextLinkTo, progress } = props;
	const context = useContext(Context);
	const { canAdvance,siteID, theme, overrideTheme } = context;
	const hasMouseInput = window.matchMedia('(any-hover: hover)').matches;
	
	return hasMouseInput ? (
		<ul className={Styles.moduleDots}>
			{chapter.id !== 'outro' && chapter.modules.map((module) => {
				const seenBoolean =
					progress && progress[siteID].some((e) => e.chapter === chapter.id && e.moduleID === module.id);
				return (
					<li key={module.id} className={clsx(Styles.moduleDot, overrideTheme ? Styles.light : theme ? Styles[theme] : Styles.light, module === activeModule && Styles.active, seenBoolean && Styles.seen)} />
				);
			})}
		</ul>
		//
	) : (
		<div className={Styles.touchNav}>
			{prevModule && (
				<Link to={prevLinkTo} className={clsx(Styles.touchNavButton, Styles.prev)}>
					<NextButton />
				</Link>
			)}
			{chapter.id !== 'outro' && <span className={Styles.pageCount}>
				{thisModuleIndex + 1}
				<span className={Styles.slash}>/</span>
				{chapter.modules.length}
			</span>}
			{nextModule && (
				<AnimatePresence exitBeforeEnter>
					{canAdvance ? (
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							exit={{ scale: 0 }}
							className={clsx(Styles.touchNavButton)}
							key="nextButton"
							transition={{ duration: 0.15 }}
						>
							<Link to={nextLinkTo}>
								<NextButton />
							</Link>
						</motion.div>
					) : (
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							exit={{ scale: 0 }}
							className={clsx(Styles.touchNavButton)}
							key="progressButton"
							transition={{ duration: 0.15 }}
						>
							<ProgressButton size='100%' />
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</div>
	);
};

export default WithinChapterNav;
