import React, { useContext, useEffect } from 'react';
import SubUserInfo from './subUserInfo';
import Context from '../../context/Context';
import { TrackPage } from '../../utils/track';

const UserInfoPage = (props) => {
	const context = useContext(Context);
	const { setShowCursor, ga4 } = context;
	setShowCursor(false);

	useEffect(() => {
		TrackPage(ga4);
		//TO DO:  IF USER HAS NAME, COUNTRY, ETC.... SHOULD NEVER SEE THIS
		return setShowCursor(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <SubUserInfo {...props} />;
};

export default UserInfoPage;
