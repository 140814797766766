import React, { useContext, forwardRef, useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ReactSVG } from 'react-svg';

import Context from '../../../../../context/Context';

import Styles from './DraggableSpot.module.scss';

const DraggableSpot = forwardRef(
  (
    {
      videoContext,
      hotspotInfo,
      handleVideoState,
      handleInteractionEnd,
      themeColor,
    },
    ref
  ) => {
    const context = useContext(Context);
    const [mountDone, setAmountDone] = useState(1);
    const constraintsRef = useRef(null);
    const { setForceDefaultCursor } = context;

    let circleX = 0;
    let circleY = 0;
    const draggableArea = 320;

    function circleAnimation(angle, width, height) {
      circleX = width + width * Math.cos((angle * Math.PI) / 180);
      circleY = height + height * Math.sin((angle * Math.PI) / 180);

      return { x: circleX, y: circleY };
    }

    const handleMouseEnter = () => {
      setForceDefaultCursor(true);
    };
    const handleMouseLeave = () => {
      setForceDefaultCursor(false);
    };

    const getHotspotPosition = () => {
      const setY = window.innerHeight < 620 || window.innerWidth <= 768 ? (
        window.innerHeight / 2 +
        (videoContext?.canvas?.clientHeight / 2) * hotspotInfo.coordinates.y
      )
      : window.innerHeight * hotspotInfo.coordinates.y * 4.1;
      const setX =
        window.innerWidth / 2 +
        (videoContext?.canvas?.clientWidth / 2) * hotspotInfo.coordinates.x;

      return { y: `${setY}px`, x: `${setX}px` };
    };

    const calcPercentDone = info => {
      const el = constraintsRef.current;

      if (hotspotInfo.reverse) {
        return (
          1 -
          (el.getBoundingClientRect().left + el.clientWidth - info.point.x) /
            el.clientWidth
        );
      }

      return (
        (el.getBoundingClientRect().left + el.clientWidth - info.point.x) /
        el.clientWidth
      );
    };

    const { x, y } = getHotspotPosition();

    const calcProgress = amount => {
      if (hotspotInfo.reverse) {
        return (360 / 2) * (1 - amount);
      }

      return (360 / 2) * amount;
    };

    // circle animation percentage
    const circlePos = circleAnimation(
      calcProgress(mountDone),
      draggableArea / 2.3,
      draggableArea / 10
    );

    return (
      <div
        className={Styles.pressAndHoldContainerHolder}
        style={{
          '--poition-height': y,
          '--poition-width': x,
          '--themecurr-color': hotspotInfo.theme || '#FFF',
        }}
      >
        <motion.div
          className={Styles.pressAndHoldContainer}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          ref={constraintsRef}
          transition={{ duration: 0.3 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            height: `${draggableArea / 4}px`,
            width: `${draggableArea}px`,
          }}
        >
          <ReactSVG
            className={Styles.directionalIcon}
            src={process.env.PUBLIC_URL + '/assets/images/directional-path.svg'}
          />
          <div
            className={Styles.buttonContainer}
            ref={ref}
            style={{
              top: `calc(${circlePos.y}px)`,
              left: `calc(${circlePos.x}px + 20px)`,
            }}
          >
            <div className={Styles.outterCircle} />
            <div className={Styles.middleCircle} />
            <div className={Styles.innerCircle} />
          </div>
          <motion.div
            className={`${Styles.drag} ${
              hotspotInfo.reverse ? Styles.rev : Styles.def
            }`}
            drag="x"
            dragConstraints={constraintsRef}
            dragElastic={false}
            dragMomentum={false}
            dragTransition={{ x: 0 }}
            onDragEnd={(e, info) => {
              const lastPointPercentage = calcPercentDone(info);
              handleInteractionEnd(lastPointPercentage);
            }}
            onDrag={(event, info) => {
              const amountDone = calcPercentDone(info);
              if (amountDone >= 0 && amountDone <= 1) setAmountDone(amountDone);
              handleVideoState(amountDone);
            }}
          ></motion.div>
        </motion.div>
      </div>
    );
  }
);

export default DraggableSpot;
