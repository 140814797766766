import React, { useEffect, useContext } from 'react';
import Context from '../../context/Context';

import { motion } from 'framer-motion';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import TextModule from '../../components/Module/TextModule';
// import AudioControl from '../AudioControl';
import Styles from './welcome.module.scss';

const CompanyRequired = (props) => {
	const { firebase } = props;

	const context = useContext(Context);
	const { setShowCursor, setOverRideChapter, setUnmuteOnNextButtonClick, setOverRideNextButton } = context;
	setOverRideChapter('Global_Intro');
	setOverRideNextButton(ROUTES.COMPANYREQUIRED);
	setShowCursor(false);
	useEffect(() => {
		
		setUnmuteOnNextButtonClick(true);
		return () => {
			setUnmuteOnNextButtonClick(false);
		};
	}, [setUnmuteOnNextButtonClick]);

	useEffect(() => {
			//TODO :: FIND OUT WHY WELCOMEVIEWED IS SET TO TRUE RIGHT HERE. DOESNT MAKE SENSE.
		// firebase.doSetParameter('welcomeViewed', true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firebase]);
	useEffect(() => {
		return setOverRideChapter(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chapter = {
		id: 'welcome',
		title: 'welcome',
	};
	const module = {
		id: 'welcome',
		title: 'welcome',
		nextChapter: '/company-required',
		content: {
			backgroundImage: {
				path: `/assets/images/bg_red.jpg`,
			},
			eyebrow: null,
			text: 'CR_TRNR_Intro_Sorry',
		},
	};
	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.25, ease: 'easeOut' }}
			className={Styles.base}
		>
			<div className={Styles.welcome} />
			<div>
				<TextModule chapter={chapter} module={module} {...props} />
			</div>
		</motion.div>
	);
};

export default withFirebase(CompanyRequired);
