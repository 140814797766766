/**
 * Intended for use intros with full-screen video and overlaid screens of text
 */
import React, { useContext, useEffect, useState } from "react";
import Context from "../../../context/Context";
import clsx from "clsx";
import BaseModule from "../BaseModule";
import Styles from "./IntroModule.module.scss";
import { AnimatePresence, motion } from "framer-motion";

export default function IntroModule(props) {
  const { module, chapter } = props;
  const { title, subTitle } = module.content;
  const context = useContext(Context);
  const { setOverRideChapter, language } = context;
  const [visibleText, setVisibleText] = useState("title");
  setOverRideChapter(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisibleText("subTitle");
    }, 2500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const chapterXofYStr = language["Global_Chapter_Subtitle"]
    .replace("<<number>>", chapter.number)
    .replace("<<total>>", 7);
  return (
    <BaseModule {...props} className={clsx(props.className, Styles.root)}>
      <div className={Styles.container}>
        <AnimatePresence exitBeforeEnter type="crossfade">
          {visibleText === "title" ? (
            <motion.div
              key="title"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.75 }}
            >
              <div className={Styles.eyebrow}>{chapterXofYStr}</div>
              <div
                className={clsx(
                  Styles.title,
                  chapter.number === 3 ? Styles.title3 : null,
                  chapter.number === 5 ? Styles.title5 : null
                )}
              >
                <span>{language[title]}</span>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="subTitle"
              className={Styles.subTitle}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.75 }}
            >
              {language[subTitle]}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </BaseModule>
  );
}
