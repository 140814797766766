import React, {useContext} from 'react'
import Styles from './NowPlaying.module.scss'
import Context from '../../../../../context/Context';

const NowPlaying = ({ track }) => {
  const context = useContext(Context);

  const {
    language
  } = context;
  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.text}>
          <h4>{language['FitPro_Modes_Playback_MusicLabel']}</h4>
          <p>{track.name}</p>
          <p>{track.artist}</p>
        </div>
        <div className={Styles.albumCover}>
          <img src={process.env.PUBLIC_URL + track.albumCover.path} alt={`${track.name} - ${track.artist}`} />
        </div>
      </div>
    </div>
  )
}

export default NowPlaying
