import React, { useEffect, useContext } from 'react';
import Context from '../../context/Context';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { randomInt } from '../../utils';
import * as ROUTES from '../../constants/routes';

import { AnimatePresence, motion } from 'framer-motion';

import { withFirebase } from '../Firebase';
// import NextButton from '../../components/NextButton';

// import AudioControl from '../AudioControl';
import Styles from './welcome.module.scss';

const Welcome = (props) => {
	const { firebase, authUser, history } = props;
	console.log("welcome authuser",authUser)
	const context = useContext(Context);
	const { setOverRideChapter, setUnmuteOnNextButtonClick,language } = context;
	const hasMouseInput = window.matchMedia("(any-hover: hover)").matches;
	
	setOverRideChapter('Global_Intro');

	useEffect(() => {
		setUnmuteOnNextButtonClick(true);
		return () => {
			setUnmuteOnNextButtonClick(false);
		};
	}, [setUnmuteOnNextButtonClick]);

	useEffect(() => {
		if (authUser) {
			firebase
			.user(authUser.uid)
			.get()
			.then((snapshot) => {
				console.log("welcome  snapshot.val()", snapshot.val())
				if (!snapshot.val().firstname) {
					//THIS IS A NEW USER, GET HER OUT OF HERE.  SHE SHOULDNT SEE welcome yet!
					console.log("push user to home")
	
					// history.push(ROUTES.HOME);
				}
				console.log("about to doSetParameter welcomeViewed")
				firebase.doSetParameter('welcomeViewed', true);
			})
			.catch((error) => {
				console.log("welcome error",error)
				// history.push(ROUTES.HOME);
			});
		}


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firebase]);

	useEffect(() => {
		return setOverRideChapter(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const module = {
		id: 'welcome',
		title: 'welcome',
		nextChapter: '/intro',
		content: {
			title: language["Global_WelcomeInstructions_Headline"] ? language["Global_WelcomeInstructions_Headline"] : 'Welcome to Beats TRNR, where Beats experts are made.',
			subtitle: language["Global_WelcomeInstructions_Subhead"] ? language["Global_WelcomeInstructions_Subhead"] : 'Here’s what to expect:',
			items: [
				{ icon: '/assets/images/clock.svg', text: language["Global_WelcomeInstructions_Body1"] ? language["Global_WelcomeInstructions_Body1"] : 'Each training experience should take about 15 minutes to complete.' },
				{ icon: '/assets/images/sound.svg', text: language["Global_WelcomeInstructions_Body2"] ? language["Global_WelcomeInstructions_Body2"] : 'Each training experience is meant to be heard. Make sure your sound is on.' },
				{ icon: '/assets/images/circle-check.svg', text: language["Global_WelcomeInstructions_Body3"] ? language["Global_WelcomeInstructions_Body3"] : 'Within each training experience, remember to complete each section before moving on.' },
				{ icon: '/assets/images/flag.svg', text: language["Global_WelcomeInstructions_Body4"] ?  language["Global_WelcomeInstructions_Body4"] : 'Don’t worry if you need to step away. You can always pick up where you left off.' },
			],
		},
	};
	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.25, ease: 'easeOut' }}
			className={Styles.base}
		>
			<div className={Styles.welcome}>
				<div className={Styles.welcomeContent}>
					<h1 dangerouslySetInnerHTML={{
						__html: module.content.title
					}}/>
					
					<p 
						className={Styles.subTitle}
						dangerouslySetInnerHTML={{
							__html: module.content.subtitle
						}}
					/>
					
					<ul>
						{module.content.items.map( item => 
							<li key={randomInt(0, Date.now())}>
								<div className={Styles.icon}>
									<img
										alt="clock"
										src={
											process.env
												.PUBLIC_URL +
											item.icon
										}
									/>
								</div>

								<span dangerouslySetInnerHTML={{
									__html: item.text
								}}/>
							</li>
						)}
					</ul>
				</div>

				{!hasMouseInput && (
					<div className={Styles.touchNav}>
						<AnimatePresence exitBeforeEnter>
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								exit={{ scale: 0 }}
								className={clsx(Styles.touchNavButton)}
								key="nextButton"
								transition={{ duration: 0.15 }}
							>
								<Link to={ROUTES.INTRO}>
									<span> NEXT </span>
								</Link>
							</motion.div>
						</AnimatePresence>
					</div>
				)}
			</div>
		</motion.div>
	);
};

export default withFirebase(Welcome);
