import React  from 'react';

import { PasswordForgetForm } from '../../components/PasswordForget';
import PasswordChangeForm from '../../components/PasswordChange';
import { AuthUserContext, withAuthorization } from '../../components/Session';
// import Context from '../../context/Context';

const AccountPage = () => {

	// const context = useContext(Context);
	// const { setShowCursor } = context;
	// setShowCursor(false);
	return(
	<AuthUserContext.Consumer>
		{(authUser) => { 
			console.log("authUser",authUser)
			return (
			<div>
				<h1>Account: {authUser.email}</h1>
				<p>Forgot Password?</p>
				<PasswordForgetForm />
				<p>Change Password?</p>
				<PasswordChangeForm />
			</div>
		)}}
	</AuthUserContext.Consumer>
)
			};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccountPage);
